<template>
  <div :class="['editable-tag-item', { highlighted: highlighted }]">
    {{ title }}
    <template v-if="isEditMode">
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="editable-tag-trigger">
          <dots-vertical-icon />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="edit"
            ><pencil-icon class="mr-2" />{{
              $t("src.components.uicomponents.editabletag.bearbeiten")
            }}</el-dropdown-item
          >
          <el-dropdown-item command="view"
            ><eye-outline-icon class="mr-2" />{{
              $t("src.components.uicomponents.editabletag.ansehen")
            }}</el-dropdown-item
          >
          <el-dropdown-item command="remove"
            ><trash-can-outline-icon class="mr-2" />{{
              $t("src.components.uicomponents.editabletag.lschen")
            }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-else>
      <div class="editable-tag-trigger" @click="handleView">
        <chevron-right-icon />
      </div>
    </template>
  </div>
</template>

<script>
import Pencil from "vue-material-design-icons/Pencil";
import ChevronRight from "vue-material-design-icons/ChevronRight";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import DotsVertical from "vue-material-design-icons/DotsVertical";

export default {
  name: "editable-tag",
  props: {
    highlighted: Boolean,
    isEditMode: Boolean,
    title: String,
    record: Object,
  },
  components: {
    [Pencil.name]: Pencil,
    [ChevronRight.name]: ChevronRight,
    [TrashCanOutline.name]: TrashCanOutline,
    [EyeOutline.name]: EyeOutline,
    [DotsVertical.name]: DotsVertical,
  },
  methods: {
    handleView() {
      this.$emit("view");
    },
    handleCommand(command) {
      switch (command) {
        case "edit":
          this.$emit("edit");
          break;
        case "view":
          this.$emit("view");
          break;
        case "remove":
          this.$emit("remove");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
