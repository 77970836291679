<template>
  <div class="card">
    <div class="card-body">
      <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
        <el-row :gutter="20">
          <el-col :md="12">
            <type-settings
              modelType="project"
              modelid="coreServices"
              type="Kernleistungen"
              :isEditMode="$can('update', 'project')"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </el-col>
          <el-col :md="12">
            <project-type-settings :isEditMode="$can('update', 'project')" />
          </el-col>
          <el-col :md="12">
            <project-doc-type-settings :isEditMode="$can('update', 'project')" />
          </el-col>
          <el-col :md="12">
            <checklist-settings :isEditMode="$can('update', 'project')" />
          </el-col>
        </el-row>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { TypeSettings } from "src/components/UIComponents";
import ProjectTypeSettings from "./ProjectTypeSettings";
import ProjectDocTypeSettings from "./ProjectDocTypeSettings";
import ChecklistSettings from "./ChecklistSettings.vue/ChecklistSettings.vue";

export default {
  name: "project-settings",
  components: {
    ProjectDocTypeSettings,
    ProjectTypeSettings,
    PerfectScrollbar,
    TypeSettings,
    ChecklistSettings,
  },
};
</script>

<style></style>
