<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.serviceprovider.index.einkauf")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.serviceprovider.index.dienstleister") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="21">
        <div class="n-view-title">{{ $t("src.components.serviceprovider.index.dienstleister") }}</div>
      </el-col>

      <el-col :span="3" class="text-right">
        <new-listitem-button v-if="$can('create', 'service_provider')" to="/purchasing/serviceProvider/profile/new" data-testid="new_serviceProvider">{{
          $t("src.components.serviceprovider.index.neuerDienstleister")
        }}</new-listitem-button>
      </el-col>
    </el-row>

    <index-table
      v-if="render"
      :propsToSearch="propsToSearch"
      authSubject="service_provider"
      modelType="serviceProvider"
      :extendedColumns="extendedColumns"
      :supressColumns="supressColumns"
      :multiSelectProps="multiSelectProps"
      :defaultSort="{ prop: 'name', order: 'ascending' }"
      :rowClassName="rowClassName"
    >
      <!-- NAME and PICTURE COLUMN -->
      <template v-slot:Name="props">
        <el-row type="flex" align="middle">
          <el-col :span="24">
            <router-link
              v-if="$can('read', 'service_provider')"
              :to="`/purchasing/serviceProvider/profile/view/${props.data.row.id}`"
            >
              {{ props.data.row.name }}
            </router-link>
            <span v-else>{{ props.data.row.name }}</span>
            <el-badge
              :type="props.data.row.active ? 'success' : 'danger'"
              style="padding-top: 9px; padding-left: 6px"
              is-dot
            />
          </el-col>
        </el-row>
      </template>

      <!-- PLZ COLUMN -->
      <template v-slot:Plz="props">
        {{ props.data.row.hrContact && props.data.row.hrContact.zip }}
      </template>
      <template v-slot:Stadt="props">
        {{ props.data.row.hrContact && props.data.row.hrContact.city }}
      </template>

      <!-- BEWERTUNG COLUMN -->
      <template v-slot:Bewertung="props">
        <div v-if="props.data.row.extendedProps && props.data.row.extendedProps.stars">
          <el-rate :max="4" disabled :value="props.data.row.extendedProps && props.data.row.extendedProps.stars" />
        </div>
        <div v-else-if="isNotRated(props.data.row)" class="text-left">
          <span>{{ $t("src.components.serviceprovider.index.nochNichtBewertet") }}</span>
        </div>
      </template>
    </index-table>
  </div>
</template>

<script>
import { IndexTable, NewListItemButton } from "src/components/UIComponents";
import { Rate } from "element-ui";

export default {
  data() {
    return {
      render: true,
      extendedColumns: [
        {
          label: "Name",
          key: "name",
          minWidth: 300,
        },
        {
          label: "Plz",
          key: "hrContact.zip",
          minWidth: 120,
        },
        {
          label: "Stadt",
          key: "hrContact.city",
          minWidth: 120,
        },
        {
          label: "Bewertung",
          key: "extendedProps.stars",
          minWidth: 130,
        },
      ],
      supressColumns: ["status", "dispo"],
      propsToSearch: ["name", ["hrContact", "zip"], "hrContact.contactPerson", "hrContact.city"],
      multiSelectProps: {
        tabMode: true,
        label: "Dienstleister Typen:",
        propToFilter: "serviceProviderType",
        filterOptions: [
          { value: "all", label: "Alle", default: true }, //default = true, not used in search, just display when nothing is selected
          { value: "HOTEL", label: "Hotel", default: false }, //type=0 is option
        ],
      },
    };
  },
  mounted() {
    this.fetchTypes();
  },
  methods: {
    isNotRated(entity) {
      return entity.serviceProviderType === "HOTEL" && (!entity.extendedProps || !entity.extendedProps.stars);
    },
    rowClassName({ row }) {
      if (this.isNotRated(row)) {
        return "warning-row";
      }
      return "";
    },
    // this hook is used to provide fetched types to IndexTable
    async reload() {
      this.render = false;
      await this.$nextTick();
      this.render = true;
    },
    async fetchTypes() {
      try {
        const response = await this.axios.get("/api/model-type", {
          params: {
            modelType: "serviceProvider",
            modelId: "serviceProviderType",
          },
        });
        const normalized = response.data.map((item) => ({
          value: item._id,
          label: item.label,
        }));
        this.multiSelectProps.filterOptions = this.multiSelectProps.filterOptions.concat(normalized);
        this.reload();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
  },
  components: {
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
    [Rate.name]: Rate,
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace !important;
}
</style>
