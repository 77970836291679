<template>
  <div v-loading="loading">
    <el-row justify="space-between" class="mb-3" style="padding-right: 10px">
      <el-col class="text-right" :span="12">
        <h4 class="n-profile-subtitle" style="margin-left: 0px; text-align: left">
          {{ $t("src.components.project.downloadcenter.employeedocumentscenter.mitarbeiterdokumente") }}
        </h4>
      </el-col>
      <el-col class="text-right" :span="12">
        <div style="display: inline-block; margin-right: 10px">
          <pr-date-picker
            v-if="!allEmployees"
            v-model="filterDateRange"
            is-range
            :clearable="false"
            :projectDateRange="projectDateRange"
          />
        </div>
        <el-switch
          v-model="allEmployees"
          :active-value="true"
          :inactive-value="false"
          active-color="#3c4b61"
          inactive-color="#3c4b61"
          :active-text="$t('All employees')"
          :inactive-text="$t('src.components.project.downloadcenter.employeedocumentscenter.mitarbeiterImProjekt')"
          class="mr-2"
        ></el-switch>
        <el-button class="mr-2" @click="handleDownload('ZIP')" :loading="downloadLoading"
          ><download-icon />
          {{ $t("src.components.project.downloadcenter.employeedocumentscenter.herunterladen") }}
        </el-button>
        <el-button @click="handleDownload('PRINT')" :loading="printLoading"
          ><printer-icon /> {{ $t("src.components.project.downloadcenter.employeedocumentscenter.print") }}
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :md="12">
        <p class="table-title pickpack-title">
          {{ $t("src.components.project.downloadcenter.employeedocumentscenter.dokumenttypen")
          }}<span class="table-title__badge">{{ employeeDocumentTypes.length }}</span>
          <span v-if="employeeDocumentsMissing" style="color: #ff2020; font-size: 13px">{{
            $t("src.components.project.downloadcenter.employeedocumentscenter.bitteWhlenSie")
          }}</span>
        </p>
        <el-table
          :data="employeeDocumentTypes"
          size="small"
          ref="table"
          @selection-change="handleDoctypeSelectionChange"
          style="width: 100%"
          :default-sort="{ prop: 'label', order: 'ascending' }"
        >
          <el-table-column type="selection" width="55" label-class-name="pl-15"> </el-table-column>
          <el-table-column
            sortable
            show-overflow-tooltip
            prop="label"
            :label="$t('src.components.project.downloadcenter.employeedocumentscenter.name')"
          ></el-table-column>
        </el-table>
      </el-col>
      <el-col :md="12">
        <p class="table-title pickpack-title">
          {{
            allEmployees
              ? $t("All employees")
              : $t("src.components.project.downloadcenter.employeedocumentscenter.mitarbeiterImProjekt")
          }}<span class="table-title__badge">{{ employeesInProject.length }}</span>
          <span v-if="employeesMissing" style="color: #ff2020; font-size: 13px">{{
            $t("src.components.project.downloadcenter.employeedocumentscenter.bitteWhlenSie")
          }}</span>
        </p>
        <el-table
          :data="employeesInProject"
          size="small"
          ref="table"
          @selection-change="handleEmployeeSelectionChange"
          style="width: 100%"
          :default-sort="{ prop: 'name', order: 'ascending' }"
        >
          <el-table-column type="selection" width="55" label-class-name="pl-15"> </el-table-column>
          <el-table-column
            sortable
            show-overflow-tooltip
            prop="name"
            :label="$t('src.components.project.downloadcenter.employeedocumentscenter.name')"
          >
            <template v-slot="props">
              <el-row type="flex" align="middle">
                <div class="mr-10" style="flex-shrink: 0">
                  <a style="display: inline-block; height: 50px; width: 50px; line-height: 50px">
                    <lazy-image
                      :src="getThumbnail(props)"
                      fit="cover"
                      style="width: 50px; height: 50px; border-radius: 2px"
                      :scroll-container="getScrollContainer()"
                    >
                      <template v-slot:placeholder>
                        <div class="image-slot">
                          <span style="font-size: 12px"
                            >{{ $t("src.components.project.downloadcenter.employeedocumentscenter.lade")
                            }}<span class="dot">{{
                              $t("src.components.project.downloadcenter.employeedocumentscenter.8748")
                            }}</span>
                          </span>
                        </div>
                      </template>
                      <template v-slot:error>
                        <div class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </template>
                    </lazy-image>
                  </a>
                </div>
                <div style="flex-grow: 1">
                  <router-link
                    v-if="$can('read', 'employee')"
                    :to="`/employee/profile/view/${props.row._id || props.row.id}`"
                  >
                    {{ props.row.name }}
                  </router-link>
                  <span v-else>{{ props.data.name }}</span>
                </div>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Message, Table, TableColumn, Switch } from "element-ui";
import PrinterIcon from "vue-material-design-icons/Printer";
import DownloadIcon from "vue-material-design-icons/Download";
import printJs from "print-js";
import { saveAs } from "file-saver";
import { sortBy } from "lodash";
import { getFilenameFromResponse } from "src/utils/getFilenameFromResponse";
import { LazyImage } from "src/components/UIComponents";
import { moment } from "src/config/moment";

export default {
  name: "employee-documents-center",
  components: {
    PrinterIcon,
    DownloadIcon,
    [Message.name]: Message,
    [Switch.name]: Switch,
    [LazyImage.name]: LazyImage,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    projectDateRange: { type: Array },
    noDateRange: { type: Boolean }, // used in workshop projects
    projectId: { type: String },
  },
  data() {
    return {
      employeesInProject: [],
      employeeDocumentTypes: [],
      employeesSelection: [],
      docTypesSelection: [],
      allEmployees: false,
      loading: false,
      filterDateRange: this.projectDateRange ? this.projectDateRange.slice() : null,
      employeeDocumentsMissing: false,
      employeesMissing: false,
      printLoading: false,
      downloadLoading: false,
    };
  },
  mounted() {
    this.fetchEmployeesInProject();
    this.fetchEmployeeDocumentTypes();
  },
  methods: {
    async fetchEmployeesInProject() {
      try {
        let response;
        const copy = this.employeesSelection.slice();
        if (this.allEmployees) {
          const employeesMeta = await this.axios.get(`/api/employees/meta`);
          response = employeesMeta.data
            .filter((item) => item.active && item.companyRole === "Gewerblich")
            .map((item) => ({
              // ...item,
              _id: item.id || item._id,
              name: `${item.lastName}, ${item.firstName}`,
              thumbnail: item.picture && item.picture.thumbnail,
            }));
        } else {
          const query = [
            "start=" + moment(this.filterDateRange[0]).toISOString(),
            "end=" + moment(this.filterDateRange[1]).toISOString(),
          ].join("&");
          response = await this.axios.get(`/api/employees/project/${this.projectId}?${query}`).then((res) => res.data);
        }
        this.employeesInProject = response;
        if (copy) {
          const selection = [];
          copy.forEach((row) => {
            const found = this.employeesInProject.find((item) => item._id === row._id);
            if (found) {
              selection.push(found);
            }
          });
          if (selection.length) {
            this.$refs.table.clearSelection();
            this.employeesSelection = selection;
            this.$nextTick(() => {
              selection.forEach((row) => this.$refs.table.toggleRowSelection(row));
            });
          }
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async fetchEmployeeDocumentTypes() {
      try {
        const params = { modelId: "employee" };
        const response = await this.axios.get(`/api/documents/types`, { params });
        this.employeeDocumentTypes = sortBy(response.data, "label");
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    handleEmployeeSelectionChange(val) {
      this.employeesSelection = val;
      this.employeesMissing = false;
    },
    handleDoctypeSelectionChange(val) {
      this.docTypesSelection = val;
      this.employeeDocumentsMissing = false;
    },
    getScrollContainer() {
      return document.querySelector(".table-wrapper .el-table__body-wrapper");
    },
    getThumbnail(props) {
      if (!props.row.thumbnail) {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      } else {
        return this.axios.defaults.baseURL + props.row.thumbnail;
      }
    },
    async handleDownload(actionType) {
      if (!this.employeesSelection.length) {
        this.employeesMissing = true;
      }
      if (!this.docTypesSelection.length) {
        this.employeeDocumentsMissing = true;
      }
      if (this.employeesMissing || this.employeeDocumentsMissing) {
        return;
      }
      const formData = {
        resourceType: "employee",
        resources: this.employeesSelection.map((i) => ({ name: i.name, id: i._id || i.id })),
        modelTypeIds: this.docTypesSelection.map((i) => i._id),
        actionType: actionType,
      };
      if (actionType === "ZIP") {
        try {
          this.downloadLoading = true;
          const response = await this.axios.request({
            method: "POST",
            url: "/api/documents/bulk-download",
            data: formData,
            responseType: "blob",
          });
          const filename = getFilenameFromResponse(response);
          saveAs(response.data, filename);
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            Message.error(error.response.data.message);
          } else {
            Message.error(error.message);
          }
          throw error;
        } finally {
          this.downloadLoading = false;
        }
      } else if (actionType === "PRINT") {
        try {
          this.printLoading = true;
          const response = await this.axios.post("/api/documents/bulk-download", formData);
          // const file = new File([response.data], filename, { type: "application/octet-stream" });
          // const objectUrl = window.URL.createObjectURL(file);
          this.$message({
            type: null,
            message: "Dokument erstellt - Druck gestartet",
            duration: 3000,
          });
          printJs({
            printable: response.data,
            type: "raw-html",
            onError: (err) => {
              throw err;
            },
          });
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            Message.error(error.response.data.message);
          } else {
            Message.error(error.message);
          }
          throw error;
        } finally {
          this.printLoading = false;
        }
      }
    },
  },
  watch: {
    allEmployees(newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.$refs.table.clearSelection();
        this.fetchEmployeesInProject();
      }
    },
    filterDateRange() {
      this.$refs.table.clearSelection();
      this.fetchEmployeesInProject();
    },
  },
};
</script>

<style></style>
