<template>
  <div>
    <el-dialog
      :visible="visible"
      @close="dismiss"
      width="500px"
      :title="$t('src.components.project.projectnotes.abmerkung')"
    >
      <el-form :model="recordData" ref="form" :rules="formRules">
        <el-form-item :label="$t('src.components.project.projectnotes.titel')" prop="title">
          <el-input v-model="recordData.title" rows="4" :editMode="isEditMode" />
        </el-form-item>
        <el-form-item :label="$t('src.components.project.projectnotes.text')" prop="text">
          <el-input type="textarea" v-model="recordData.text" rows="4" :editMode="isEditMode" />
        </el-form-item>
        <div class="flex justify-between">
          <el-button @click="dismiss" style="mr-2">{{ $t("src.components.project.projectnotes.abbrechen") }}</el-button>
          <el-button type="primary" @click="addRecord">{{
            $t("src.components.project.projectnotes.speichern")
          }}</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-row justify="space-between" class="mb-4">
      <el-col :span="20">
        <p class="profile-title">{{ $t("src.components.project.projectnotes.weitereAnmerkungen") }}</p>
      </el-col>
      <el-col v-if="isEditMode" :span="4" class="text-right">
        <el-button type="primary" @click="showModal">
          <plus-icon />{{ $t("src.components.project.projectnotes.neuesAnmerkung") }}</el-button
        >
      </el-col>
    </el-row>
    <div>
      <div class="note-item" v-for="(note, idx) in projectNotes" :key="idx">
        <div class="note-title">{{ note.title }}</div>
        <div class="note-text">{{ note.text }}</div>
        <div class="note-actions">
          <button
            class="btn btn-sm btn-ghost"
            :title="$t('src.components.project.projectnotes.bearbeiten')"
            @click="editRecord($event, note, idx)"
          >
            <pencil-icon />
          </button>
          <button
            class="btn btn-sm btn-ghost"
            style="magin-left: 0"
            :title="$t('src.components.project.projectnotes.lschen')"
            @click="removeRecord($event, idx)"
          >
            <trash-can-outline-icon />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { Dialog, Form, FormItem } from "element-ui";
import PencilIcon from "vue-material-design-icons/Pencil";
import PlusIcon from "vue-material-design-icons/Plus";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";

export default {
  name: "project-notes",
  props: {
    projectNotes: { type: Array, default: () => [] },
    isEditMode: { type: Boolean },
    // accessRights: { type: String },
  },
  components: {
    [PencilIcon.name]: PencilIcon,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [PlusIcon.name]: PlusIcon,
    [TrashCanOutline.name]: TrashCanOutline,
  },
  data() {
    return {
      editingIdx: null,
      visible: false,
      formRules: {
        title: [{ required: true, message: "Bitte Titel zu Anmerkungen eintragen", trigger: "change" }],
        text: [{ required: true, message: "Bitte Text zu Anmerkungen eintragen", trigger: "change" }],
      },
      recordData: {
        title: null,
        text: null,
      },
    };
  },
  created() {
    this.debounceUpdate = debounce(() => this.$emit("updateData"), 200);
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    dismiss() {
      this.$refs.form.resetFields();
      this.visible = false;
      this.isEditingRecordIdx = null;
      this.recordData = {
        title: null,
        text: null,
      };
    },
    addRecord(e) {
      e.preventDefault();
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (typeof this.isEditingRecordIdx === "number") {
          this.projectNotes.splice(this.isEditingRecordIdx, 1, { ...this.recordData });
        } else {
          this.projectNotes.push({ ...this.recordData });
        }
        this.debounceUpdate();
        this.dismiss();
      });
    },
    editRecord(e, record, index) {
      e.preventDefault();
      this.visible = true;
      this.isEditingRecordIdx = index;
      this.recordData = {
        title: record.title,
        text: record.text,
      };
    },
    removeRecord(e, idx) {
      e.preventDefault();
      this.$confirmDelete().then(() => {
        this.projectNotes.splice(idx, 1);
        this.$emit("updateData");
      });
    },
  },
};
</script>

<style lang="scss">
.note-item {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 2px;
  position: relative;
}
.note-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.note-text {
  white-space: pre-line;
}
.note-actions {
  position: absolute;
  top: 0px;
  right: 10px;
  direction: rtl;
}
</style>
