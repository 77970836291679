import axios from "axios";

const state = {
  colorSettings: {
    employee: "#fff",
    machine: "#fff",
    progress_1: "#fff",
    progress_2: "#fff",
    progress_3: "#fff",
    rhb: "#fff",
    subcontractor: "#fff",
    supply: "#fff",
    vehicle: "#fff",
  },
  date: null,
  logo: null,
  loaded: false,
};

const actions = {
  async fetch({ commit }) {
    try {
      const response = await axios.get("/api/customer-settings");
      commit("setData", response.data);
    } catch (error) {
      throw error;
    }
  },
  async saveColors({ commit }, newColorSettings) {
    const response = await axios.post("/api/customer-settings", { colorSettings: newColorSettings });
    commit("setData", response.data);
  },
  async saveDate({ commit }, newDate) {
    const response = await axios.post("/api/customer-settings", { date: newDate });
    commit("setData", response.data);
  },
  async saveLogo({ commit }, newLogo) {
    const response = await axios.post("/api/customer-settings", { logo: newLogo });
    commit("setData", response.data);
  },
};

const mutations = {
  setData(state, newData) {
    if (newData.colorSettings) {
      state.colorSettings = newData.colorSettings;
    }
    state.date = newData.date;
    state.logo = newData.logo;
    state.loaded = true;
  },
};

const getters = {
  colorSettings(state) {
    return state.colorSettings;
  },
  date(state) {
    return state.date;
  },
};

export const customerSettings = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
