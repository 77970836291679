<template>
  <div>
    <!-- PREVIEW PICTURE -->
    <div class="front" v-show="previewPicture">
      <lazy-image v-if="previewPictureSrc" :src="previewPictureSrc" fit="contain" style="max-height: 100%" />
    </div>
    <!-- DIALOG WITH PICTURE -->
    <el-dialog width="30%" :visible.sync="dialogVisible" center>
      <div style="text-align: center; height: 70vh; overflow: hidden">
        <lazy-image :src="previewPictureSrc" key="cover" fit="contain" height="100%" wrapperStyle="height: 100%;" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from "element-ui";
import { LazyImage } from "src/components/UIComponents";

export default {
  name: "picture-preview",
  components: {
    [Dialog.name]: Dialog,
    [LazyImage.name]: LazyImage,
  },
  data() {
    return {
      previewPicture: false,
      dialogVisible: false,
      previewPictureSrc: null,
    };
  },
  methods: {
    showPreview(src) {
      this.previewPicture = true;
      this.previewPictureSrc = this.axios.defaults.baseURL + src;
    },
    hidePreview() {
      this.previewPicture = false;
      // this.previewPictureSrc = null;
    },
    showDialog(src) {
      this.dialogVisible = true;
      this.previewPictureSrc = this.axios.defaults.baseURL + src;
    },
  },
  watch: {},
};
</script>
