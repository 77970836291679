<template>
  <el-button type="primary" v-bind="$attrs" @click="(evt) => $emit('click', evt)">
    <span style="margin-bottom: 4px">{{ text }}</span>
  </el-button>
</template>

<script>
export default {
  name: "save-button",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Speichern",
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
