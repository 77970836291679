<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.compSettingsSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Projekt einstellungen"
        v-model="value.project"
        :description="$t('userGroupProfile.compSettings.projectDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Maschine einstellungen"
        v-model="value.machine"
        :description="$t('userGroupProfile.compSettings.machineDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="RHB einstellungen"
        v-model="value.rhb"
        :description="$t('userGroupProfile.compSettings.rhbDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Verbrauchsmaterial einstellungen"
        v-model="value.supply"
        :description="$t('userGroupProfile.compSettings.supplyDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="KFZ einstellungen"
        v-model="value.vehicle"
        :description="$t('userGroupProfile.compSettings.vehicleDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Mitarbeiter einstellungen"
        v-model="value.employee"
        :description="$t('userGroupProfile.compSettings.employeeDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Dienstleister einstellungen"
        v-model="value.service_provider"
        :description="$t('userGroupProfile.compSettings.serviceProviderDescription')"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsRow from "./AccessRightsRow.vue";
import AccessRightsTable from "./AccessRightsTable.vue";

export default {
  name: "settings-specific-access",
  components: { AccessRightsRow, AccessRightsTable },
  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style>
</style>