<template>
  <div>
    <div class="row">
      <div class="col-md-4 form-group">
        <profile-select
          filterable
          :multiple="false"
          :editMode="!disabled"
          :items="serviceProviderTypeOptions"
          v-model="contactData.contactType"
          :placeholder="$t('src.components.project.contacts.components.serviceproviderrecord.typ')"
          labelIdentifier="label"
          valueIdentifier="value"
          :title="$t('src.components.project.contacts.components.serviceproviderrecord.typ')"
        />
      </div>
      <div class="col-md-4 form-group">
        <profile-input v-model="contactData.name" :label="$t('company')" :editMode="!disabled" />
      </div>
      <div class="col-md-4 form-group" v-if="!disabled" style="margin-top: 21px">
        <service-provider-auto-fill :serviceProviderType="contactData.contactType" @onSelect="handleAutoFill" />
        <el-button v-slot:reference icon="el-icon-delete" @click="handleRemove">{{
          $t("src.components.project.contacts.components.serviceproviderrecord.lschen")
        }}</el-button>
      </div>
    </div>
    <div class="contact-flex">
      <div class="mr-3" style="flex-shrink: 0">
        <avatar-uploader
          class="n-grid-container-edit-avatar"
          :isEditMode="isEditMode"
          :actionURI="avatarActionURI"
          :avatar="localAvatar"
          v-on:avatar-changed="setAvatar"
          :name="`projectContact_${projectId}_picture_${getTitle()}`"
        />
      </div>
      <div class="form-group row">
        <div class="col-md-4">
          <profile-input
            v-model="contactData.firstName"
            :label="$t('src.components.dashboard.views.pages.userprofile.editprofileform.firstName')"
            :editMode="!disabled"
          />
        </div>
        <div class="col-md-4">
          <profile-input
            v-model="contactData.lastName"
            :label="$t('src.components.dashboard.views.pages.userprofile.editprofileform.lastName')"
            :editMode="!disabled"
          />
        </div>

        <div class="col-md-4">
          <profile-input
            v-model="contactData.phone"
            :label="$t('src.components.project.contacts.components.serviceproviderrecord.telefon')"
            :editMode="!disabled"
          />
        </div>

        <div class="col-md-4">
          <profile-input
            v-model="contactData.mobile"
            :label="$t('src.components.project.contacts.components.serviceproviderrecord.mobile')"
            :editMode="!disabled"
          />
        </div>

        <div class="col-md-4">
          <profile-input
            v-model="contactData.fax"
            :label="$t('src.components.project.contacts.components.serviceproviderrecord.fax')"
            :editMode="!disabled"
          />
        </div>

        <div class="col-md-4">
          <profile-input
            v-model="contactData.email"
            :name="$t('src.components.project.contacts.components.serviceproviderrecord.eMail')"
            type="email"
            rules="email"
            :label="$t('src.components.project.contacts.components.serviceproviderrecord.eMail')"
            :editMode="!disabled"
          />
        </div>
        <div class="col-md-4">
          <profile-input
            v-model="contactData.company"
            :label="$t('src.components.project.contacts.components.serviceproviderrecord.firma')"
            :editMode="!disabled"
          />
        </div>
        <div class="col-md-8 form-group has-label" v-if="addFreeText">
          <profile-input
            v-model="contactData.freeText"
            type="textarea"
            :label="$t('src.components.project.contacts.components.serviceproviderrecord.freitext')"
            :editMode="!disabled"
            :autosize="{ minRows: 2 }"
          />
        </div>
        <div class="col-md-4 form-group">
          <label>{{ $t("src.components.project.contacts.components.serviceproviderrecord.datei") }}</label>
          <el-upload
            :headers="authHeader"
            :action="actionURI"
            :data="{ metadata: getMetadata() }"
            :file-list="contactData.documents"
            ref="upload"
            :on-success="fileUploaded"
            :on-remove="removeFile"
            :on-error="handleUploadError"
            :on-preview="showFile"
            :disabled="disabled"
          >
            <el-button class="mb-2" v-show="!disabled" icon="el-simple-add" type="primary" size="mini">{{
              $t("src.components.project.contacts.components.serviceproviderrecord.dateiHinzufgen")
            }}</el-button>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Input, Button, Upload, Popconfirm } from "element-ui";
import { mapGetters } from "vuex";
import { get } from "lodash";
import ServiceProviderAutoFill from "./ServiceProviderAutoFill";
import { Avatar } from "src/components/UIComponents";

export default {
  name: "service-provider-record",
  components: {
    ServiceProviderAutoFill,
    [Avatar.name]: Avatar,
    [Popconfirm.name]: Popconfirm,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
  },
  props: {
    title: { type: String, required: true },
    contactData: { type: Object, required: true, default: () => ({}) },
    isEditMode: { type: Boolean },
    addFreeText: { type: Boolean },
    disabled: { type: Boolean },
    showRemoveButton: { type: Boolean },
    projectId: { type: String },
    serviceProviderTypeOptions: {
      type: Array,
      validator: (value) => {
        return value.every((item) => typeof item.value === "string" && typeof item.label === "string");
      },
      description: "[{ value: 'id', label: 'Name' }]",
    },
  },
  data() {
    return {
      avatarActionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      localAvatar: {},
    };
  },
  mounted() {
    if (this.contactData && this.contactData.picture) {
      this.localAvatar = { ...this.contactData.picture };
    }
  },
  methods: {
    getTitle() {
      return this.title.replace(" ", "").replace("#", "");
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    getMetadata() {
      const metadata = "project_" + this.projectId + "_contacts_" + this.title.replace(" ", "").replace("#", "");
      return metadata;
    },

    removeFile(file) {
      const metadata = this.getMetadata();
      this.contactData.documents = this.contactData.documents.filter((item) => item._id !== file._id);
      this.$emit("deleteFile", `/api/fileupload?filename=${file.name}&metadata=${metadata}`);
    },
    handleRemove() {
      this.$confirmDelete().then(() => {
        this.$emit("removeRecord");
      });
    },
    fileUploaded(response) {
      if (!this.contactData.documents) {
        this.contactData.documents = [];
      }
      this.contactData.documents.push({ url: response[0].url, name: response[0].name, _id: response[0]._id });
    },
    setAvatar(avatarSettings) {
      this.contactData.picture = avatarSettings;
      this.$nextTick(() => {
        this.$emit("updated");
      });
    },
    showFile(file) {
      if (file.response && file.response[0]) {
        window.open(file.response[0].url, "_blank");
      } else if (file.url) {
        window.open(file.url, "_blank");
      } else {
        console.warn("Could not parse file. File object below.");
        console.log(JSON.stringify(file, null, 2));
      }
    },
    handleAutoFill(data) {
      this.$set(this.contactData, "name", data.name);
      this.$set(this.contactData, "firstName", get(data, "hrContact.name", get("hrContact.contactPerson") || ""));
      this.$set(this.contactData, "lastName", get(data, "hrContact.lastName", ""));
      // this.$set(this.contactData, "contactPerson", get(data, "hrContact.name", ""));
      this.$set(this.contactData, "phone", get(data, "hrContact.phone", ""));
      this.$set(this.contactData, "mobile", get(data, "hrContact.mobile", ""));
      this.$set(this.contactData, "fax", get(data, "hrContact.fax", ""));
      this.$set(this.contactData, "email", get(data, "hrContact.email", ""));
      this.$set(this.contactData, "company", get(data, "hrContact.company", ""));
      this.$set(this.contactData, "freeText", get(data, "hrContact.freeText", ""));
      this.$set(this.contactData, "picture", get(data, "hrContact.picture", undefined));
      console.log("handleAutoFill", data);
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
  },
  watch: {
    // fixes flickering of contact record when editing input fields
    "contactData.picture": {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.url !== this.localAvatar.url) {
          this.localAvatar = { ...newVal };
        }
      },
    },
    "contactData.contactType": {
      handler: function (newVal, oldVal) {
        if (!this.serviceProviderTypeOptions) {
          return;
        }
        if (!oldVal || oldVal.contactTypeName || newVal.contactTypeName !== oldVal.contactTypeName) {
          const newContactTypeName = this.serviceProviderTypeOptions.find((item) => item.value === newVal) || {};
          this.contactData.contactTypeName = newContactTypeName.label;
        }
      },
      // deep: true
    },
  },
};
</script>

<style></style>
