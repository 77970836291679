<template>
  <widget-tab
    :title="$t('src.components.dashboard.views.dashboard.widgets.unavailableResources.title')"
    :hasFilters="true"
  >
    <template #filters>
      <el-form :inline="true">
        <el-form-item>
          <profile-select
            :editMode="true"
            :multiple="false"
            v-model="activeTab"
            :title="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.group')"
            style="width: 150px"
            :items="[
              { value: 'employee', label: 'Personal' },
              { value: 'machine', label: 'Maschinen' },
              { value: 'vehicle', label: 'KFZ' },
              { value: 'rhb', label: 'RHB' },
              { value: 'supply', label: 'Verbrauchsm.' },
            ]"
          />
        </el-form-item>
      </el-form>
    </template>
    <div style="height: 100%">
      <el-table class="d-widget-table" :data="unavailableResources" style="width: 100%" max-height="auto">
        <el-table-column
          :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.name')"
          prop="name"
        >
        </el-table-column>
        <el-table-column label="Austrittsdatum" prop="dateOfLeaving" :formatter="dateFormatter"></el-table-column>
        <el-table-column width="120" v-if="accessRights === 'manage' || accessRights === 'full'">
          <template v-slot="scope">
            <div class="text-right">
              <el-button size="small" @click="handleActiveChange(scope.row, scope.$index)"> Deaktivieren </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </widget-tab>
</template>

<script>
import WidgetTab from "./Widgets/WidgetTab.vue";
import { Form, FormItem, Table, TableColumn, Switch, Message } from "element-ui";
import { moment } from "src/config/moment";
import { sortBy, get } from "lodash";

export default {
  name: "unavailable-resources-widget",
  props: {
    accessRights: { type: String },
  },
  components: {
    WidgetTab,
    [Message.name]: Message,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Switch.name]: Switch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      activeTab: "employee",
      unavailableResources: [],
    };
  },
  mounted() {
    this.fetchResources();
  },
  methods: {
    async fetchResources() {
      try {
        const allResourceUrls = {
          employee: `/api/employees/meta`,
          machine: `/api/machines/meta`,
          vehicle: `/api/vehicles/meta`,
          rhb: `/api/rhb/meta`,
          supply: `/api/supply/meta`,
        };
        const today = moment();
        const response = await this.axios.get(allResourceUrls[this.activeTab]);
        const unavailableResources = response.data.filter(
          (item) => moment(item.dateOfLeaving).isBefore(today, "day") && item.active
        );
        this.unavailableResources = sortBy(
          unavailableResources.map((item) => ({
            name: item.name || `${item.lastName}, ${item.firstName}`,
            ...item,
          })),
          ["name"]
        );
      } catch (error) {
        throw error;
      }
    },
    async handleActiveChange(record, index) {
      try {
        const allResourceUrls = {
          employee: `/api/employees/activate`,
          machine: `/api/machines/activate`,
          vehicle: `/api/vehicles/activate`,
          rhb: `/api/rhb/activate`,
          supply: `/api/supply/activate`,
        };
        await this.axios.put(`${allResourceUrls[this.activeTab]}/${record.id}`, { active: false });
        this.unavailableResources = this.unavailableResources.filter((item) => item.id !== record.id);
        Message.success(`${record.name} deaktiviert`);
      } catch (error) {
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      }
    },
    dateFormatter(record) {
      if (record.dateOfLeaving) {
        return moment(record.dateOfLeaving).format("L");
      }
      return "N/A";
    },
  },
  watch: {
    activeTab() {
      this.fetchResources();
    },
  },
};
</script>

<style>
</style>