<template>
  <div
    v-bind="$attrs"
    @dragover="handleDragOver"
    @dragenter="handleDragOver"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
    :class="['dropzone', { over: isOver }, { 'error-shown': isErrorShown }]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "dropzone",
  props: {
    /** accepts Files list as argument. If result of execution is falsy - error state is shown */
    onDrop: { type: Function, required: true },
  },
  data() {
    return {
      timeout: null,
      isOver: false,
      isErrorShown: false,
    };
  },
  methods: {
    handleDragOver(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isOver = true;
    },
    handleDragLeave(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isOver = false;
    },
    async handleDrop(e) {
      try {
        e.preventDefault();
        e.stopPropagation();
        this.isOver = false;
        const files = e.dataTransfer.files;
        const result = await this.$props.onDrop(files);
        console.log("result", result);
        if (!result) {
          this.showErrorState();
        }
      } catch (error) {
        throw error;
      }
    },
    showErrorState() {
      console.log("showErrorState");
      this.isErrorShown = true;
      this.timeout = setTimeout(() => {
        this.isErrorShown = false;
        this.timeout = null;
      }, 2000);
    },
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};
</script>

<style lang="scss" scoped>
.dropzone.over {
  border-radius: 5px;
  border: 2px dashed black;
}
.dropzone.error-shown {
  border-radius: 5px;
  border: 2px dashed red;
}
</style>
