<template>
  <router-link v-if="to" :to="to" v-slot="{ navigate }">
    <el-button type="primary" @click="navigate" v-bind="$attrs" :id="id">
      <i class="fa fa-plus" style="margin-right: 5px"></i>
      <slot></slot>
    </el-button>
  </router-link>
  <el-button v-else type="primary" :id="id" v-bind="$attrs" @click="(evt) => $emit('click', evt)">
    <i class="fa fa-plus" style="margin-right: 5px"></i>
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: "new-listitem-button",
  props: {
    id: String,
    to: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
