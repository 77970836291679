<template>
  <div class="n-edit-wrapper">
    <!-- BREADCRUM TRAIL -->
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{ $t("src.components.rhb.edit.rhb") }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/rhb' }">{{ $t("src.components.rhb.edit.rhbListe") }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ rhb.name }} </el-breadcrumb-item>
          <el-breadcrumb-item>{{ activeTabLabel }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title">{{ profileName }}</div>
      </el-col>
      <el-col :span="12" class="profile-buttons-wrapper">
        <cancel-button icon="el-icon-arrow-left" @click="handleBack">{{
          $t("src.components.rhb.edit.zurck")
        }}</cancel-button>
        <template v-if="isEditMode">
          <cancel-button v-if="$route.params.id === 'new'" @click="deleteProfile()" />
          <cancel-button v-if="$route.params.id !== 'new'" @click="changeEditMode(false)">{{
            $t("src.components.rhb.edit.editierenBeenden")
          }}</cancel-button>
          <delete-button v-if="$route.params.id !== 'new'" @click="deleteProfile()" />
          <cancel-button v-if="$route.params.id !== 'new'" @click.prevent="copyProfile" :disabled="!isEditMode">{{
            $t("src.components.rhb.edit.kopieren")
          }}</cancel-button>
          <save-button @click="updateProfile(rhb)" data-testid="save_profile" />
        </template>
        <template v-else>
          <edit-profile-button
            v-if="$can('update', 'rhb')"
            :editMode="isEditMode"
            @click="changeEditMode(!isEditMode)"
          />
        </template>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" tab-position="left" class="profile-tabs hide-disabled">
      <el-tab-pane
        :label="$t('src.components.rhb.edit.general')"
        name="general"
        :disabled="!rhbAccess.specificAccess.general"
      >
        <ValidationObserver ref="validateObserver">
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <rhb-profile
              :isEditMode="isEditMode"
              :rhb="rhb"
              :tabName="$t('src.components.rhb.edit.general')"
              :accessRights="rhbAccess.specificAccess.general"
              @setProfileData="setProfileData"
              @savePartialProfileData="savePartialProfileData"
              :updateProfile="updateProfile"
            />
          </perfect-scrollbar>
        </ValidationObserver>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.rhb.edit.status')"
        name="status"
        :disabled="!rhbAccess.specificAccess.status"
        v-if="!isNew"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <ressource-status
            :isEditMode="isEditMode"
            statusModel="rhb"
            :statusModelID="rhb.id"
            :accessRights="rhbAccess.specificAccess.status"
          />
        </perfect-scrollbar>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.rhb.edit.documents')"
        name="documents"
        lazy
        :disabled="!rhbAccess.specificAccess.documents"
        v-if="!isNew"
      >
        <document-list
          v-if="activeName === 'documents'"
          :isEditMode="isEditMode"
          modelType="rhb"
          :modelID="rhb.id"
          :accessRights="rhbAccess.specificAccess.documents"
          withSuppliers
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.rhb.edit.sourcing')"
        name="sourcing"
        lazy
        v-if="!isNew && rhbAccess.specificAccess.sourcing"
        :disabled="!rhb.id"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <supplier-articles
            :isEditMode="isEditMode"
            :resourceId="rhb.id"
            resourceType="rhb"
            :accessRights="rhbAccess.specificAccess.sourcing"
          />
        </perfect-scrollbar>
      </el-tab-pane>
    </el-tabs>

    <copy-dialog
      ref="copyDialogRef"
      resourceType="rhb"
      :resourceId="rhb.id"
      :resourceRecord="rhb"
      :checkboxesList="copyCheckboxesList"
    />
    <div v-show="false">
      <rhb-profile-settings
        :rhb="rhb"
        :isEditMode="rhbAccess.specificAccess.settings !== 'readonly'"
        v-show="rhbAccess.specificAccess.settings"
      />
    </div>
  </div>
</template>

<script>
import ProfileSettings from "./ProfileSettings";
import Profile from "./Profile.vue";
import CopyDialog from "../Generic/CopyDialog";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import { MessageBox, Message, Tabs, TabPane } from "element-ui";
import { Documents, RessourceStatus } from "src/components/UIComponents";
import SupplierArticles from "../Generic/SupplierArticles/SupplierArticles.vue";
import { ValidationObserver } from "vee-validate";
import { unlink } from "src/utils/unlink";
import { get } from "lodash";

export default {
  components: {
    ValidationObserver,
    PerfectScrollbar,
    MessageBox,
    Message,
    SupplierArticles,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Documents.name]: Documents,
    [Profile.name]: Profile,
    [RessourceStatus.name]: RessourceStatus,
    [CopyDialog.name]: CopyDialog,
    [ProfileSettings.name]: ProfileSettings,
  },
  data() {
    return {      
      activeName: "0",
      allTabNames: ["general", "status", "documents", "sourcing", "settings"],
      originalData: null,
      rhb: {
        active: true,
        projectRelevant: true,
      },
      copyCheckboxesList: [
        { value: "STATUS", label: "Status", url: "/api/status-events/copy" },
        { value: "DOCUMENTS", label: "Document", url: "/api/documents/copy" },
        { value: "SUPPLIERS", label: "Lieferant", modelProperty: "supplier" },
      ],
    };
  },
  mounted() {
    this.setFirstTab(this.accessRights);

  
    const id = this.$route.params.id; //coming from routing parameter
    const identifier = this.$route.meta.identifier;

    //create or edit mode - edit possible
    if (identifier === "rhb_edit" || id === "new") {
      this.changeEditMode(true)
    } else {
      //view mode - no editing possible
      this.changeEditMode(false)
    }

    if (this.$route.query.tab_pane) {
      this.activeName = this.$route.query.tab_pane;
    }
    if (id !== "new") {
      const route = `/api/rhb/${id}`;
      //get data from api REST call
      this.axios
        .get(route)
        .then((result) => {
          //set user data loaded from database
          this.rhb = result.data;
          this.originalData = unlink(result.data);

          //now is data available -> update dependent selections
          //product groups:
          let selectedProductGroups = {
            selectedProductGroup1: this.rhb.selectedProductGroup1,
            selectedProductGroup2: this.rhb.selectedProductGroup2,
          };
          this.setSelections(selectedProductGroups);
          this.setPreservedData(result.data);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    } else {
      setTimeout(() => {
        this.setPreservedData(this.rhb);
        this.checkDirtyFlag(this.rhb);
      }, 1000);
    }
    if (this.$route.query.tab_pane) {
      this.activeName = this.$route.query.tab_pane;
    }
  },
  beforeDestroy() {
    this.discardDirty();
  },
  beforeRouteLeave(to, from, next) {
    let self = this;
    if (this.isDirty && this.$route.meta.identifier === "rhb_edit") {
      MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        distinguishCancelAndClose: true,
        confirmButtonClass: "el-button--success",
      })
        .then(function (action) {
          self.updateProfile(self.rhb, false);
          next();
        })
        .catch(function (action) {
          if (action === "close") {
            next(false);
          } else {
            next();
          }
        });
    } else {
      next();
    }
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    ...mapState("profileEditMode", ["isEditMode"]),
    ...mapState("account", { accessRights: "accessRights" }),
    activeTabLabel() {
      if (this.activeName === "0") {
        return "";
      }
      return this.$t(`src.components.rhb.edit.${this.activeName}`);
    },
    rhbAccess() {
      return get(this.accessRights, "rhb", { specificAccess: {}, generalAccess: null });
    },
    profileName() {
      return this.$route.params.id === "new" ? "Neuer RHB" : this.rhb.name;
    },
    isNew() {
      return this.$route.params.id === "new";
    },
  },
  methods: {
    ...mapActions({
      setPreservedData: "dirtyFlag/setPreservedData",
      checkDirtyFlag: "dirtyFlag/checkDirty",
      discardDirty: "dirtyFlag/discard",
      setSelections: "productGroupSettings/setSelections",
    }),
    ...mapActions("profileEditMode", ["changeEditMode"]),
    setProfileData(data) {
      this.rhb = unlink(data);
    },
    savePartialProfileData(data) {
      this.rhb = unlink({ ...this.rhb, ...data });
    },
    async updateProfile(entity, redirectOnCreate = true) {
      const isValid = await this.$refs.validateObserver.validate();
      const isNewProfile = this.$route.params.id === "new";
      if (!entity.name || !isValid || !entity.dateOfEntering) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        return;
      }
      try {
        const route = "/api/rhb";
        if (isNewProfile) {
          const response = await this.axios.post(route, entity);
          this.rhb = response.data;
          this.originalData = unlink(response.data);
          if (redirectOnCreate) {
            this.$router.push({
              name: "RHB Edit",
              params: { id: response.data.id },
            });
          }
        } else {
          const response = await this.axios.put(`${route}/${entity.id}`, entity);
          this.originalData = response.data;
        }
        Message({
          message: "RHB Daten gespeichert",
          type: "success",
        });
        this.setPreservedData(entity);
        this.checkDirtyFlag(entity);
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    copyProfile() {
      this.$refs.copyDialogRef.showModal();
    },
    deleteProfile() {
      this.$confirmDelete().then(() => {
        this.axios.delete("/api/rhb/" + this.rhb.id);
        this.discardDirty();
        this.$router.push({
          name: "RHB Index",
        }); //directly go back to index page
      });
    },
    handleBack() {
      this.$router.push({ name: "RHB Index" });
    },
    setFirstTab(accessRights) {
      if (!accessRights.rhb) {
        return;
      }
      if (this.activeName === "0") {
        const tabsAccessRights = accessRights.rhb.specificAccess;
        const firstTab = this.allTabNames.find((tabName) => !!tabsAccessRights[tabName]);
        this.activeName = firstTab;
      }
    },
  },
  watch: {
    accessRights(accessRights) {
      this.setFirstTab(accessRights);
    },
    isEditMode(newVal) {
      if (!newVal) {
        this.rhb = unlink(this.originalData);
        this.setPreservedData(this.rhb);
        this.discardDirty();
      }
    },
  },
};
</script>

<style></style>
