<template>
  <div style="display: flex; justify-content: start; align-items: center">
    <div style="display: flex; align-items: center; margin-right: 5px;">
      <span
        :style="{ backgroundColor: typeObject.color }"
        :class="{ 'document-type-color': true, 'system-type': typeObject.systemRelevant }"
      ></span>
      <span :class="typeObject.icon" style="font-size: 20px;"></span>
    </div>
    <span style="display: inline-block; margin-right: 5px;">{{ typeObject.label }}</span>
  </div>
</template>

<script>
export default {
  name: "document-type-cell",
  props: {
    typeId: String,
    typeOptions: { type: Array, default: () => [] }
  },
  data() {
    return {
      // typeObject: {}
    };
  },
  computed: {
    typeObject() {
      return this.typeOptions.find(item => item._id === this.typeId) || {};
    }
  }
  // watch: {
  //   typeId(newVal, oldVal) {
  //     if (newVal !== oldVal) {
  //       this.typeObject = this.typeOptions.find(item => item._id === newVal) || {};
  //     }
  //   },
  //   typeOptions(newVal, oldVal) {
  //     this.typeObject = newVal.find(item => item._id === this.typeId) || {};
  //   }
  // }
};
</script>

<style></style>
