<template>
  <div>
    <template v-if="filetype === 'pdf'"> <file-pdf-box-icon /> </template>
    <template v-else-if="filetype.indexOf('xls') === 0">
      <file-excel-icon />
    </template>
    <template v-else-if="filetype.indexOf('doc') === 0">
      <file-word-icon />
    </template>
    <template
      v-else-if="
        filetype === 'mp4' ||
        filetype.indexOf('mpeg') === 0 ||
        filetype === 'avi' ||
        filetype === 'mkv' ||
        filetype === 'mov'
      "
    >
      <file-video-icon />
    </template>
     <template v-else>
      <file-icon />
    </template>
  </div>
</template>

<script>
import FilePdfBox from "vue-material-design-icons/FilePdfBox.vue";
import FileExcel from "vue-material-design-icons/FileExcel.vue";
import FileWord from "vue-material-design-icons/FileWord.vue";
import FileVideo from "vue-material-design-icons/FileVideo.vue";
import File from "vue-material-design-icons/File.vue";

export default {
  name: "file-type-icon",
  components: {
    [FilePdfBox.name]: FilePdfBox,
    [FileExcel.name]: FileExcel,
    [FileWord.name]: FileWord,
    [FileVideo.name]: FileVideo,
    [File.name]: File,
  },
  props: {
    filename: { type: String, required: true },
  },
  computed: {
    filetype() {
      const splited = this.filename.split(".");
      return splited[splited.length - 1].toLowerCase();
    },
  },
};
</script>

<style>
</style>