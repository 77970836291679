/**
 * This module stands for displaying resource events in project calendar conflicts when extending project range
 */
const state = {
  conflictEvents: [],
  projectInfo: {},
};

const mutations = {
  init(state, { conflictEvents, projectInfo }) {
    state.conflictEvents = conflictEvents;
    state.projectInfo = projectInfo;
  },
  resolveEvent(state, { eventId }) {
    const eventIdx = state.conflictEvents.findIndex(item => item.id === eventId);
    if (eventIdx !== -1) {
      state.conflictEvents.splice(eventIdx, 1);
    }
  },
  dismiss(state) {
    state.conflictEvents = [];
    state.projectInfo = {};
  }
};

const actions = {
  init({ commit }, payload) {
    commit('init', payload);
  },
  resolveEvent({ commit }, payload) {
    commit('resolveEvent', payload);
  },
  dismiss({ commit }) {
    commit('dismiss');
  }
};

export const projectRangeResolve = {
  namespaced: true,
  state,
  mutations,
  actions,
}