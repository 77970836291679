<template>
  <div>
    <div class="type-settings-wrapper">
      <div style="flex-grow: 1">
        <profile-select
          :editMode="true"
          :title="getTitle()"
          :multiple="false"
          :items="optionsOrdered"
          v-model="selectedTypeID"
          valueIdentifier="_id"
          :hideInactive="false"
          :disableInactive="false"
        />
      </div>
      <div v-if="isEditMode" style="margin-left: 6px; margin-bottom: 6px">
        <el-button class="btn btn-sm btn-ghost" @click="createSettings">
          <plus-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="editSettings">
          <pencil-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="deleteType">
          <trash-can-outline-icon />
        </el-button>
      </div>
    </div>
    <status-settings-dialog ref="dialog" @updated="onUpdated" :isEditMode="isEditMode" :resourceType="resourceType" />
    <!-- STATUS REPLACEMENT DIALOG -->
    <el-dialog
      :visible.sync="statusReplacementVisible"
      :title="$t('src.components.uicomponents.helper.typesettings.replaceStatusTitle')"
    >
      <p>
        Status-Typ {{ getSelectedStatusType }} soll gelöscht werden, jedoch sind diverse Events damit verknüpft. Bitte
        wähle neuen Status-Typ für die betroffenen Events <br />oder lösche die betroffenen Events im Kalender oder an
        der Resource
      </p>
      <profile-select
        :editMode="true"
        :title="$t('src.components.uicomponents.helper.typesettings.newStatusType')"
        :multiple="false"
        :items="optionsOrderedWithoutSelected"
        v-model="typeIdToBeReplaced"
        valueIdentifier="_id"
        :hideInactive="false"
        :disableInactive="false"
      />
      <save-button
        :text="$t('src.components.uicomponents.helper.typesettings.speichern')"
        @click="removeAndReplaceEvents"
      ></save-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  ButtonGroup,
  Input,
  FormItem,
  Form,
  Dialog,
  Select,
  Option,
  Button,
  Message,
  MessageBox,
  Checkbox,
  Tooltip,
  Switch,
} from "element-ui";

import { indexOf, orderBy, get } from "lodash";

import Vue from "vue";
import SymbolSelect from "./SymbolSelect.vue";
import ColorSelect from "./ColorSelect.vue";
import StatusSettingsDialog from "./StatusSettingsDialog.vue";

export default {
  name: "status-settings",
  components: {
    Button,
    Message,
    MessageBox,
    SymbolSelect,
    ColorSelect,
    StatusSettingsDialog,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [Option.name]: Option,
    [Select.name]: Select,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [ButtonGroup.name]: ButtonGroup,
    [Checkbox.name]: Checkbox,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: true,
    },
    forAdmin: {
      type: Boolean,
    },
    resourceType: {
      type: String,
      default: "",
      description: "model id like employee",
    },
  },
  data() {
    return {
      options: [],
      selectedTypeID: "",
      statusReplacementVisible: false,
      typeIdToBeReplaced: "",
    };
  },
  beforeDestroy() {
    this.$root.$off("loadSettings");
  },
  mounted() {
    //Sometimes resourceType data is loaded later
    if (this.resourceType) {
      this.loadDataFromServer();
    }
    this.$root.$on("loadSettings", (data) => {
      if (data.resourceType === this.resourceType && data.modelType === "status") {
        this.loadDataFromServer();
      }
    });
  },
  watch: {
    //we need to watch the resourceType and lazy load its depending data!
    //resourceType source is loaded after this component is created
    resourceType: function (newVal) {
      if (newVal) {
        this.loadDataFromServer();
      }
    },
  },
  computed: {
    optionsOrdered: function () {
      let ordered = orderBy(this.options, ["label"], ["asc"]);
      return ordered;
    },
    optionsOrderedWithoutSelected() {
      return this.optionsOrdered.filter((item) => item._id !== this.selectedTypeID);
    },
    getSelectedStatusType() {
      return get(
        this.optionsOrdered.find((item) => item._id === this.selectedTypeID),
        "label",
        ""
      );
    },
  },
  methods: {
    loadDataFromServer() {
      let self = this;

      this.axios
        .get("/api/status-events/types", {
          params: {
            resourceType: this.resourceType,
            forAdmin: true,
          },
        })
        .then(function (response) {
          self.options = response.data.map((item) => {
            if (item.isPrivate && item.publicName) {
              item.label = `${item.label} (${item.publicName})`;
            }
            return item;
          });
          // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
          let data = {
            options: self.optionsOrdered,
            operation: "loaded",
            info: null,
            modelID: self.resourceType,
            modelType: "status",
          };
          self.$root.$emit("settingsChanged", data);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    onUpdated(record) {
      const option = this.options.find((x) => x._id === this.selectedTypeID);
      let index = indexOf(this.options, option);
      if (index === -1) {
        index = this.options.length;
      }
      Vue.set(this.options, index, record);

      // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
      const data = {
        options: this.optionsOrdered.filter((item) => item.active),
        operation: "updated",
        info: record,
        modelID: this.resourceType,
        modelType: "status",
      };
      this.$root.$emit("settingsChanged", data);
    },
    getTitle() {
      return "Status (" + this.optionsOrdered.length + ")";
    },
    createSettings() {
      this.$refs.dialog.$open();
    },
    editSettings() {
      if (!this.selectedTypeID) {
        MessageBox.alert("Bitte Status Typ auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.$refs.dialog.$edit(this.selectedTypeID);
    },
    deleteType() {
      let self = this;

      if (!this.selectedTypeID) {
        MessageBox.alert("Status Typ auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }

      MessageBox.confirm("wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(function () {
        self.axios
          .delete("/api/model-type/" + self.selectedTypeID)
          .then(function (response) {
            let option = self.options.find((x) => x._id === self.selectedTypeID);
            let index = indexOf(self.options, option);
            Vue.delete(self.options, index, response.data);
            // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
            let data = {
              options: self.optionsOrdered.filter((item) => item.active),
              operation: "deleted",
              info: self.selectedTypeID,
              modelID: self.resourceType,
              modelType: "status",
            };

            self.$root.$emit("settingsChanged", data);

            self.selectedTypeID = "";
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.message) {
              console.log("error.response.data", error.response.data);
              if (error.response.data.code === 100) {
                // TODO: show modal window with selection for new status type
                self.statusReplacementVisible = true;
              } else {
                Message({
                  message: error.response.data.message,
                  type: "error",
                });
              }
            } else {
              Message({
                message: error.message,
                type: "error",
              });
            }
          });
      });
    },
    async removeAndReplaceEvents() {
      try {
        await this.axios.put("/api/status-events/types/replace", {
          oldStatusType: this.selectedTypeID,
          newStatusType: this.typeIdToBeReplaced,
        });
        this.loadDataFromServer();
        this.statusReplacementVisible = false;
        this.typeIdToBeReplaced = "";
        this.selectedTypeID = "";
        Message.success(this.$t("src.components.uicomponents.helper.typesettings.statusEventsMoved"));
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style>
.icon-options .el-select-dropdown__item.selected {
  background-color: #f5f7fa;
}
.type-settings-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
</style>
