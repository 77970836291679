<template>
  <div class="form-group">
    <el-row justify="space-between">
      <el-col :span="12">
        <p class="profile-title">{{ $t("src.components.project.employeecontrol.bauleitung") }}</p>
      </el-col>
      <el-col :span="12" class="text-right">
        <profile-radio-group
          class="mr-2"
          v-model="hideOld"
          :items="[
            { label: 'Alle Einträge', value: false },
            { label: 'Aktuelle Einträge', value: true },
          ]"
        />
      </el-col>
    </el-row>
    <el-dialog :visible="visible" @close="dismissEdit" width="900px" id="employee_control" destroy-on-close>
      <el-form :model="formData" :rules="formRules" ref="form">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="selectedPosition">
              <profile-select
                required
                style="width: 100%"
                :editMode="editMode"
                :items="positionOptions"
                v-model="formData.selectedPosition"
                :placeholder="$t('src.components.project.employeecontrol.position')"
                :title="$t('src.components.project.employeecontrol.position')"
                labelIdentifier="label"
                valueIdentifier="id"
                :multiple="false"
              >
              </profile-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="selectedEmployee">
              <span style="" class="n-profile-select-title-red">*</span>
              <span class="n-profile-label-title">{{ $t("src.components.project.employeecontrol.mitarbeiter") }}</span>
              <br />
              <el-select
                v-if="isEditing"
                required
                style="width: 100%"
                v-model="formData.selectedEmployee"
                :placeholder="$t('src.components.project.employeecontrol.mitarbeiter')"
                filterable
                :filter-method="filterEmployeesMethod"
              >
                <el-option
                  :disabled="!item.active"
                  v-for="item in filteredEmployees"
                  :key="item.id"
                  :value="item.id"
                  :label="`${item.firstName} ${item.lastName}`"
                >
                </el-option>
              </el-select>
              <div v-else>{{ getEmployeeName(formData.selectedEmployee) }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="selectedDateRange">
              <profile-date-picker
                required
                :title="$t('src.components.project.employeecontrol.zeitraum')"
                v-if="isEditing"
                style="width: 100%; margin-top: 2px"
                :disabled="!formData.selectedPosition"
                :projectDateRange="projectDateRange"
                is-range
                :placeholder="$t('src.components.project.employeecontrol.zeitraum')"
                v-model="formData.selectedDateRange"
                :focusDate="today"
                :attributes="datePickerAttributes"
              ></profile-date-picker>
              <div v-else>
                <label class="n-profile-label-title">{{ $t("src.components.project.employeecontrol.zeitraum") }}</label>
                <div>{{ formData.selectedDateRange | formatDateRange }}</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-if="isEditing" class="text-right">
        <el-button @click.prevent="dismissEdit">{{ $t("src.components.project.employeecontrol.abbrechen") }}</el-button>
        <el-button type="primary" v-if="isAddingNew" @click.prevent="addEmployee">{{
          $t("src.components.project.employeecontrol.speichern")
        }}</el-button>
        <el-button type="primary" v-else @click.prevent="saveEditedEmployee">{{
          $t("src.components.project.employeecontrol.speichern")
        }}</el-button>
      </div>
    </el-dialog>
    <el-row>
      <el-col :md="12">
        <h6>Bauleitung:</h6>
        <div :key="employeeData._id" v-for="employeeData in constructionManagers">
          <editable-tag
            @view="viewEmployeeData(employeeData)"
            @edit="editEmployeeData(employeeData)"
            @remove="removeEmployeeData(employeeData.idx)"
            :isEditMode="editMode"
            :title="formatEmployeeDataString(employeeData)"
            :record="employeeData"
            :highlighted="employeeData.highlighted"
          />
        </div>
      </el-col>
      <el-col :md="12">
        <h6>Verantwortlicher Mitarbeiter:</h6>
        <div :key="employeeData._id" v-for="employeeData in responsibleEmployees">
          <editable-tag
            @view="viewEmployeeData(employeeData)"
            @edit="editEmployeeData(employeeData)"
            @remove="removeEmployeeData(employeeData.idx)"
            :isEditMode="editMode"
            :title="formatEmployeeDataString(employeeData)"
            :record="employeeData"
            :highlighted="employeeData.highlighted"
          />
        </div>
      </el-col>
    </el-row>
    <cancel-button v-if="editMode" @click.prevent="showAddEmployeeData()">
      <plus-icon class="mr-2" />{{ $t("src.components.project.employeecontrol.hinzufgenBauleitung") }}</cancel-button
    >
  </div>
</template>

<script>
import {
  DatePicker,
  Select,
  Input,
  Timeline,
  TimelineItem,
  Message,
  MessageBox,
  Form,
  FormItem,
  Dialog,
} from "element-ui";
import { moment } from "src/config/moment";
import { sortBy } from "lodash";
import PrDatePicker from "../UIComponents/Inputs/PrDatePicker.vue";
import { EditableTag } from "src/components/UIComponents";
import PlusIcon from "vue-material-design-icons/Plus";

export default {
  name: "employee-control",
  props: {
    editMode: { type: Boolean },
    projectEntity: { type: Object },
    projectDateRange: { type: Array },
  },
  components: {
    PrDatePicker,
    [EditableTag.name]: EditableTag,
    [PlusIcon.name]: PlusIcon,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Input.name]: Input,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [MessageBox.name]: MessageBox,
  },
  data: function () {
    const validateDateRange = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum eintragen"));
      }
      const currentDateRange = moment.range(value);
      const currentConstructionManagers =
        this.normalizedEmployees.filter((item) => item.position === this.formData.selectedPosition) || [];
      // find conflicting records to exclude currently editing (if editing) record from validation
      const conflictRecords = currentConstructionManagers.filter((item) =>
        moment.range(item.dateRange).overlaps(currentDateRange)
      );
      // some records overlap currently set daterange
      if (conflictRecords.length > 0) {
        if (
          // if more than 1 record overlaps - conflict is guaranteed
          conflictRecords.length > 1 ||
          // otherwise, there's only one element.
          // So if this element idx is NOT the one we're currently editing - it's a conflict
          conflictRecords[0].idx !== this.editingIdx
        ) {
          return callback(new Error("Datumsbereich sollte sich nicht überschneiden"));
        }
      }
      return callback();
    };
    return {
      formRules: {
        selectedPosition: { required: true, message: "Bitte Position auswählen" },
        selectedEmployee: { required: true, message: "Bitte Mitarbeiter auswählen" },
        selectedDateRange: { required: true, validator: validateDateRange, trigger: "change" },
      },
      formData: {
        selectedPosition: "",
        selectedEmployee: "",
        selectedDateRange: null,
      },
      hideOld: true,
      positionOptions: [
        { id: "CONSTRUCTION_MANAGER", label: "Bauleiter" },
        { id: "RESPONSIBLE_EMPLOYEE", label: "Verantwortlicher Mitarbeiter" },
      ],
      visible: false,
      isEditing: false,
      editingIdx: null,
      normalizedEmployees: [],
      employeesMeta: [], // list of all employees
      filteredEmployees: [],
      today: new Date(),
    };
  },
  mounted() {
    this.fetchEmployeesMeta();
  },
  methods: {
    async fetchEmployeesMeta() {
      try {
        const response = await this.axios.get("api/employees/meta");
        const activeEmployees = response.data.filter((item) => item.active && item.projectRoles.length);
        this.employeesMeta = activeEmployees;
        this.filteredEmployees = activeEmployees;
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    triggerSave() {
      this.$emit("save", { submitAfterUpdate: true });
      this.dismissEdit();
    },
    clearInputs() {
      this.formData.selectedEmployee = "";
      this.formData.selectedPosition = "";
      this.formData.selectedDateRange = "";
      this.editingIdx = null;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    viewEmployeeData(employeeData) {
      this.editEmployeeData(employeeData);
      this.isEditing = false;
    },
    showAddEmployeeData() {
      this.visible = true;
      this.isEditing = true;
    },
    addEmployee() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.projectEntity.constructionManagers) {
          this.projectEntity.constructionManagers = [];
        }
        this.projectEntity.constructionManagers.push({
          employee: this.formData.selectedEmployee,
          position: this.formData.selectedPosition,
          dateRange: [
            moment(this.formData.selectedDateRange[0]).startOf("day").toISOString(),
            moment(this.formData.selectedDateRange[1]).endOf("day").toISOString(),
          ],
        });
        this.projectEntity.constructionManagers = sortBy(this.projectEntity.constructionManagers, [
          (rec) => rec.dateRange && moment(rec.dateRange[0]).toDate(),
        ]);
        this.clearInputs();
        this.triggerSave();
      });
    },
    editEmployeeData(editEmployeeData) {
      const employeeData = this.normalizedEmployees.find((item) => item.idx === editEmployeeData.idx);
      this.formData.selectedEmployee = employeeData.employee;
      this.formData.selectedPosition = employeeData.position;
      this.formData.selectedDateRange = employeeData.dateRange.slice();
      this.editingIdx = employeeData.idx;
      this.visible = true;
      this.isEditing = true;
    },
    dismissEdit() {
      this.visible = false;
      this.isEditing = false;
      this.clearInputs();
    },
    saveEditedEmployee() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const newData = {
          employee: this.formData.selectedEmployee,
          dateRange: [
            moment(this.formData.selectedDateRange[0]).startOf("day").toISOString(),
            moment(this.formData.selectedDateRange[1]).endOf("day").toISOString(),
          ],
          position: this.formData.selectedPosition,
        };
        this.projectEntity.constructionManagers.splice(this.editingIdx, 1, newData);
        this.dismissEdit();
        this.triggerSave();
      });
    },
    removeEmployeeData(idx) {
      MessageBox.confirm("Bauleiter löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
      }).then(() => {
        this.projectEntity.constructionManagers.splice(idx, 1);
        if (idx === this.editingIdx) {
          this.editingIdx = null;
          this.triggerSave();
        }
      });
    },
    filterEmployeesByPosition(positionId) {
      if (positionId) {
        // discard selected employee if a smartass decides to select employee then change position
        if (this.formData.selectedEmployee) {
          const employeeData = this.employeesMeta.find((item) => item.id === this.formData.selectedEmployee) || {};
          if (!employeeData.projectRoles.includes(positionId)) {
            this.formData.selectedEmployee = "";
          }
        }
        this.filteredEmployees = this.employeesMeta.filter((item) => item.projectRoles.includes(positionId));
      } else {
        this.filteredEmployees = this.employeesMeta.slice();
      }
    },
    filterEmployeesMethod(value) {
      if (!value) {
        this.filterEmployeesByPosition(this.formData.selectedPosition);
      } else {
        const query = value.toLowerCase();
        this.filteredEmployees = this.filteredEmployees.filter((item) => {
          return `${item.firstName || ""}${item.lastName || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
      }
    },
    formatEmployeeDataString(record) {
      const { dateRange, employee, position } = record;
      const positionRecord = this.positionOptions.find((item) => item.id === position) || {};
      const employeeRecord = this.employeesMeta.find((item) => item.id === employee) || {};
      const employeeName = `${employeeRecord.firstName} ${employeeRecord.lastName}`;

      if (dateRange && dateRange.length === 2) {
        const start = moment(dateRange[0]).format("DD.MM.YYYY");
        const end = moment(dateRange[1]).format("DD.MM.YYYY");
        return `${employeeName} (${positionRecord.label}): ${start} - ${end}`;
      }
      return `${employeeName} (${positionRecord.label}): - ?? - `;
    },
    getEmployeeName(employeeId) {
      const employee = this.filteredEmployees.find((item) => item.id === employeeId);
      if (employee) {
        return `${employee.firstName} ${employee.lastName}`;
      }
      return "";
    },
    getTimelineNodeClass(managementRecord) {
      const className = "employee-position-node";
      const employee = this.employeesMeta.find((item) => item.id === managementRecord.employee);
      console.log("this.employeesMeta", this.employeesMeta);
      console.log("employee", employee);
      if (!employee) {
        return className;
      }
      let classNameToAttach;
      let counter = 0;
      if (employee.projectRoles.includes("CONSTRUCTION_MANAGER")) {
        counter++;
        classNameToAttach = "c-manager-color";
      }
      if (employee.projectRoles.includes("RESPONSIBLE_EMPLOYEE")) {
        counter++;
        classNameToAttach = "staff-color";
      }
      if (counter === 2) {
        classNameToAttach = "both-color";
      }
      return className + ` ${classNameToAttach}`;
    },
    // provides a list with respect of hideOld switcher and specified position
    filterByPositionAndSwitcher(position) {
      let list = this.normalizedEmployees.filter((item) => item.position === position);
      if (this.hideOld) {
        list = list.filter((item) => {
          const today = moment().startOf("day");
          return today.isSameOrBefore(item.dateRange[1], "day");
        });
      }
      return list;
    },
  },
  watch: {
    "projectEntity.constructionManagers": function (newVal, oldVal) {
      const today = moment();
      // crutch to provide sort of id's handled on client side to delete records.
      this.normalizedEmployees = sortBy(
        this.projectEntity.constructionManagers.map((item, idx) => ({
          ...item,
          idx,
          // to highlight current records (today is within specified dateRange)
          highlighted: today.within(moment.range(item.dateRange).snapTo("day")),
        })),
        [
          (o) => o.position,
          (o) => o.dateRange && new Date(o.dateRange[0]).getTime(),
          (o) => o.dateRange && new Date(o.dateRange[1]).getTime(),
        ]
      );
    },
    // // pre-select position when select employee if position is empty
    // "formData.selectedEmployee": function (newVal, oldVal) {
    //   const employeeData = this.employeesMeta.find((item) => item.id === newVal);
    //   if (employeeData && employeeData.selectedPosition !== this.formData.selectedPosition) {
    //     this.formData.selectedPosition = employeeData.selectedPosition;
    //   }
    // },
    "formData.selectedPosition": function (newVal, oldVal) {
      this.filterEmployeesByPosition(newVal);
    },
  },
  computed: {
    isAddingNew() {
      return this.isEditing && typeof this.editingIdx !== "number";
    },
    constructionManagers() {
      return this.filterByPositionAndSwitcher("CONSTRUCTION_MANAGER");
    },
    responsibleEmployees() {
      return this.filterByPositionAndSwitcher("RESPONSIBLE_EMPLOYEE");
    },
    datePickerAttributes() {
      const attributes = [];
      if (this.formData.selectedPosition) {
        const positionRecords = this.normalizedEmployees.filter(
          (item) => item.position === this.formData.selectedPosition
        );
        positionRecords.forEach((posRecord) => {
          attributes.push({
            key: "project-range",
            highlight: { color: "gray", fillMode: "light" },

            dates: {
              start: moment(posRecord.dateRange[0]).toDate(),
              end: moment(posRecord.dateRange[1]).toDate(),
            },
          });
        });
      }
      return attributes;
    },
  },
  filters: {
    formatDateRange(dateRange) {
      if (!dateRange || dateRange.length !== 2) {
        return "";
      }
      return `${moment(dateRange[0]).format("L")} - ${moment(dateRange[1]).format("L")}`;
    },
  },
};
</script>

<style lang="scss">
.employee-position-node {
  top: 0;
  left: -1px;
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #e4e7ed;
}
.c-manager-color {
  border-color: #6294f9;
}
.staff-color {
  border-color: #ff9b4b;
}
.both-color {
  border-right-color: #ff9b4b;
  border-top-color: #6294f9;
  border-bottom-color: #ff9b4b;
  border-left-color: #6294f9;
}
</style>
