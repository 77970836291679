<template>
  <el-card data-testid="service_days_card">
    <el-form
      :disabled="!isEditMode"
      :inline="true"
      :model="serviceRecord"
      :rules="rules"
      ref="serviceForm"
      data-testid="service_days_form"
      v-if="isEditMode"
    >
      <el-form-item prop="startDate">
        <pr-date-picker
          v-model="serviceRecord.startDate"
          :placeholder="$t('src.components.machine.components.servicedays.startDatum')"
          :disabled="!isEditMode"
        />
      </el-form-item>
      <el-form-item prop="intervalDays">
        <el-input
          style="width: 210px"
          type="number"
          v-model="serviceRecord.intervalDays"
          :placeholder="$t('src.components.machine.components.servicedays.intervall')"
        >
          <template v-slot:append>{{ $t("src.components.machine.components.servicedays.tage") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="entriesCount">
        <el-input
          style="width: 180px"
          type="number"
          v-model="serviceRecord.entriesCount"
          :placeholder="$t('src.components.machine.components.servicedays.anzahl')"
        >
          <template v-slot:append>#</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="preWarnDays">
        <el-input
          type="number"
          v-model="serviceRecord.preWarnDays"
          :placeholder="$t('src.components.machine.components.servicedays.vorwarnzeit')"
          :disabled="!isEditMode"
        >
          <template v-slot:append>{{ $t("src.components.machine.components.servicedays.tage") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t("src.components.machine.components.servicedays.hinzufgen")
        }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="serviceDates" style="width: 100%" class="mb-20">
      <el-table-column
        type="index"
        :label="$t('src.components.machine.components.servicedays.nr')"
        width="60"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.servicedays.datum')"
        prop="start"
        :formatter="(row, col, val) => formatDate(val)"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.servicedays.vorwarnzeit')"
        prop="preWarn"
        :formatter="formatPreWarnDays"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.servicedays.aktion')"
        width="150"
        v-if="isEditMode"
      >
        <template v-slot="scope">
          <el-button size="mini" type="danger" @click="handleDeleteRecord(scope.index)">{{
            $t("src.components.machine.components.servicedays.lschen")
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" @click="emitCopy" v-if="isEditMode">{{
      $t("src.components.machine.components.servicedays.kopieren")
    }}</el-button>
  </el-card>
</template>

<script>
import { DatePicker, Input, Card, Table, Form, FormItem, Button } from "element-ui";
import { moment } from "src/config/moment";

export default {
  name: "service-days",
  components: {
    [Input.name]: Input,
    [Card.name]: Card,
    [DatePicker.name]: DatePicker,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Table.name]: Table,
  },
  props: {
    serviceDates: { type: Array, default: () => [] },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      serviceRecord: {
        startDate: null,
        intervalDays: null,
        entriesCount: null,
        preWarnDays: null,
      },
      rules: {
        startDate: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        intervalDays: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        entriesCount: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        preWarnDays: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("ll");
    },
    formatPreWarnDays(record) {
      const { start, preWarn } = record;
      const differenceDays = moment(start).diff(preWarn, "days");
      return moment(preWarn).format(`ll [(${differenceDays} Tage)]`);
    },
    onSubmit() {
      this.$refs.serviceForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        const { entriesCount, startDate, intervalDays, preWarnDays } = this.serviceRecord;
        const records = [];
        for (let count = 0; count < entriesCount; count++) {
          const start = moment(startDate).startOf("day");
          if (count > 0) {
            start.add(count * intervalDays, "days");
          }
          const preWarn = start.clone().subtract(preWarnDays, "days");
          records.push({
            start,
            preWarn,
          });
        }
        this.serviceDates.push(...records);
        this.$refs.serviceForm.resetFields();
      });
    },
    handleDeleteRecord(index) {
      this.$confirmDelete().then(() => {
        this.serviceDates.splice(index, 1);
      });
    },
    emitCopy() {
      this.$emit("onCopyClick");
    },
  },
};
</script>

<style></style>
