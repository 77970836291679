<template>
  <el-dialog
    :title="createEventTitle"
    width="500px"
    :visible="visible"
    center
    :show-close="false"
    @close="close"
    @closed="discard"
    destroy-on-close
  >
    <el-form
      label-width="120px"
      ref="eventForm"
      :model="formData"
      :rules="formDataRules"
      :disabled="isViewOnlyMode"
      v-loading="loading"
    >
      <el-form-item :label="$t('dateRange')" prop="dateRange" style="width: 100%">
        <pr-date-picker v-model="formData.dateRange" is-range :disabled="isViewOnlyMode" />
      </el-form-item>
      <el-form-item :label="$t('freeText')" prop="comment" style="width: 100%">
        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4 }" v-model="formData.comment" />
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <span class="dialog-footer" v-if="!isViewOnlyMode">
        <cancel-button
          :text="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.abbrechen')"
          @click="close"
        />
        <save-button
          :text="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.bernehmen')"
          :loading="loading"
          @click="submitEvent"
        />
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Form, FormItem, Dialog, Message, DatePicker } from "element-ui";
import { moment } from "src/config/moment";

export default {
  name: "project-event-edit",
  props: {
    visible: Boolean,
    eventRecord: Object,
    getDbEvent: Function,
    resourceId: String,
    resourceType: String,
    resourceRecord: Object,
    ignoreNextUpdateCheck: Function,
  },
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
  },
  data() {
    return {
      loading: false,
      formDataRules: {
        dateRange: {
          required: true,
          message: "Bitte Datum auswählen",
          trigger: "change",
        },
      },
      formData: {
        dateRange: null,
        comment: "",
      },
      isViewOnlyMode: false,
    };
  },
  methods: {
    initModal() {
      if (this.eventRecord) {
        this.formData.dateRange = [this.eventRecord.startDate, this.eventRecord.endDate];
        this.formData.comment = this.eventRecord.data.comment;
      }
    },
    submitEvent() {
      this.$refs.eventForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const formBody = {
              start: moment(this.formData.dateRange[0]).format("YYYY-MM-DD"),
              end: moment(this.formData.dateRange[1]).format("YYYY-MM-DD"),
              comment: this.formData.comment,
              resourceId: this.resourceId,
              resourceType: this.resourceType,
              projectId: this.eventRecord.projectId,
              quantity: this.eventRecord.quantity,
            };
            let response;
            this.$props.ignoreNextUpdateCheck();

            formBody.id = this.eventRecord.id;
            response = await this.axios.put(`/api/project-events/${this.eventRecord.id}`, formBody);
            const event = window.resourceScheduler.eventStore.getById(this.eventRecord.id);
            event.set({
              id: response.data.id,
              dbEventId: response.data.id,
              name: this.eventRecord.name,
              resourceType: response.data.resourceType,
              resourceId: response.data.resourceId,
              startDate: response.data.start,
              start: response.data.start,
              end: response.data.end,
              endDate: response.data.end,
              draggable: true,
              comment: response.data.comment,
            });
            if (window.projectScheduler && !window.projectScheduler.isDestroyed) {
              const event = window.projectScheduler.eventStore.getById(response.data.id);
              event.set({
                startDate: response.data.start,
                start: response.data.start,
                end: response.data.end,
                endDate: response.data.end,
                comment: response.data.comment,
              });
            }
            this.$emit("eventEdited", response.data);
          } catch (error) {
            if (error.response && error.response.data) {
              Message.error(error.response.data.message);
            } else {
              Message.error(error.message);
            }
            throw error;
          } finally {
            this.loading = false;
            this.close();
          }
        } else {
          return false;
        }
      });
    },
    close() {
      this.$emit("close");
    },
    discard() {
      this.$refs.eventForm.clearValidate();
      this.formData.dateRange = null;
      this.formData.comment = "";
      this.isViewOnlyMode = false;
      this.initialized = false;
    },
  },
  computed: {
    createEventTitle: function () {
      let title = (this.resourceRecord && this.resourceRecord.name) || "";
      if (this.isViewOnlyMode) {
        title += ": Projekt Übersicht";
      } else {
        title += ": bearbeiten";
      }
      return title;
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.initModal();
      } else {
        this.discard();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
