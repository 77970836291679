<template>
  <div>
    <el-card>
      <template v-slot:header>
        <div>
          <div class="col-md-12">
            <div class="form-group">
              <h6>{{ $t("src.components.uicomponents.helper.suppliersettings.lieferant") }}</h6>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <el-button v-if="isEditMode" @click="dialogVisible = true">
              <i class="nc-icon el-icon-plus"></i
              >{{ $t("src.components.uicomponents.helper.suppliersettings.hinzufgen") }}</el-button
            >

            <el-dialog :title="'Neuer Lieferant'" :visible.sync="dialogVisible">
              <h6 class="title">{{ $t("src.components.uicomponents.helper.suppliersettings.allgemein") }}</h6>
              <div class="form-group row">
                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.name')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.name')"
                    v-model="supplier.name"
                  />
                </div>

                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.webseite')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.webseite')"
                    v-model="supplier.website"
                  />
                </div>
              </div>

              <h6 class="title">{{ $t("src.components.uicomponents.helper.suppliersettings.kontaktdaten") }}</h6>
              <div class="form-group row">
                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.ansprechpartner')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.ansprechpartner')"
                    v-model="supplier.contactPerson"
                  />
                </div>

                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.telefon')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.telefon')"
                    v-model="supplier.hrSupplier.phone"
                  />
                </div>

                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.handy')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.handy')"
                    v-model="supplier.hrSupplier.mobile"
                  />
                </div>

                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.fax')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.fax')"
                    v-model="supplier.hrSupplier.fax"
                  />
                </div>

                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.email')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.email')"
                    v-model="supplier.hrSupplier.email"
                  />
                </div>
              </div>

              <h6 class="title">{{ $t("src.components.uicomponents.helper.suppliersettings.adressdaten") }}</h6>
              <div class="form-group row">
                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.strae')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.strae')"
                    v-model="supplier.hrSupplier.street"
                  />
                </div>

                <div class="col-md-2">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.hausnummer')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.nr')"
                    v-model="supplier.hrSupplier.no"
                  />
                </div>

                <div class="col-md-2">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.plz')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.plz')"
                    v-model="supplier.hrSupplier.zip"
                  />
                </div>

                <div class="col-md-4">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.stadt')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.stadt')"
                    v-model="supplier.hrSupplier.city"
                  />
                </div>

                <div class="col-md-3">
                  <fg-input
                    type="text"
                    :label="$t('src.components.uicomponents.helper.suppliersettings.adresszusatz')"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.adresszusatz')"
                    v-model="supplier.hrSupplier.addressAddition"
                  />
                </div>

                <div class="col-md-3">
                  <label>{{ $t("src.components.uicomponents.helper.suppliersettings.land") }}</label>
                  <br />
                  <el-select
                    :filterable="true"
                    v-model="supplier.hrSupplier.country"
                    :placeholder="$t('src.components.uicomponents.helper.suppliersettings.beginneZuTippen')"
                  >
                    <el-option
                      v-for="country in this.countries"
                      :key="country.id"
                      :label="country.name"
                      :value="country.value"
                    >
                      <div style="display: flex; align-items: center">
                        <country-flag :country="country.value"></country-flag>
                        <span style="margin-left: 5px">{{ country.name }}</span>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </div>
              <template v-slot:footer>
                <span class="dialog-footer">
                  <cancel-button
                    :text="$t('src.components.uicomponents.helper.suppliersettings.abbrechen')"
                    @click="closeDialog()"
                  ></cancel-button>
                  <save-button
                    :text="$t('src.components.uicomponents.helper.suppliersettings.speichern')"
                    @click="saveDialog(supplier)"
                  ></save-button>
                </span>
              </template>
            </el-dialog>
          </div>
        </div>
      </div>

      <el-select
        style="max-width: 190px"
        filterable
        clearable
        :disabled="!isEditMode"
        v-model="selectedTypeID"
        :placeholder="$t('src.components.uicomponents.helper.suppliersettings.lieferant')"
      >
        <el-option v-for="item in options" :key="item._id" :label="item.name" :value="item._id">
          <span style="float: left; font-size: 20px; margin-top: 7px" v-bind:class="item.icon"></span>
          <span style="float: right; color: #8492a6; font-size: 15px">{{ item.name }}</span>
        </el-option>
      </el-select>
      <edit-button v-if="isEditMode" style="margin-left: 10px" @click="editType" />
      <delete-button v-if="isEditMode" style="margin-left: 10px" v-on:click="deleteType"></delete-button>
    </el-card>
  </div>
</template>

<script>
import { Input, FormItem, Form, Dialog, Select, Option, DatePicker, Button, Message, MessageBox } from "element-ui";
import { sortBy } from "lodash";

import CountryFlag from "vue-country-flag";
import countryList from "src/country-list.json";
export default {
  name: "supplier-settings",
  components: {
    CountryFlag,
    Button,
    Message,
    MessageBox,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  props: {
    model: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isEditingManually: false,
      isEditMode: true,
      selectedTypeID: "",
      options: [],
      supplier: {
        hrSupplier: {},
      },
      dialogVisible: false,
      countries: countryList,
    };
  },
  mounted() {
    let route = "/api/suppliers";
    let self = this;
    this.isEditMode = this.$route.name.includes("Edit");

    //load supplier list for selection
    this.axios
      .get(route, {
        params: {
          model: this.model,
        },
      })
      .then(function (result) {
        //set user data loaded from database
        self.options = result.data;

        self.$emit("supplier-loaded", sortBy(self.options, "name"));
      })
      .catch(function (error) {
        Message({
          message: error.message,
          type: "error",
        });
      });
  },
  methods: {
    closeDialog() {
      this.isEditingManually = false;
      this.dialogVisible = false;
    },
    saveDialog() {
      var self = this;

      //set now the model
      this.supplier.model = this.model;

      if (this.isEditingManually) {
        this.axios
          .put("/vapi/suppliers/" + this.supplier._id, this.supplier)
          .then(function (response) {
            self.$emit("supplier-edited", self.supplier);
            self.supplier = {
              hrSupplier: {},
            };
            self.closeDialog();
            Message({
              message: "Neuen Lieferant gespeichert",
              type: "success",
            });
          })
          .catch(function (error) {
            Message({
              message: error.message,
              type: "error",
            });
          });
      } else {
        this.axios
          .post("/api/suppliers", this.supplier)
          .then(function (response) {
            self.$emit("supplier-saved", response.data);
            self.supplier = {
              hrSupplier: {},
            };
            self.closeDialog();
            Message({
              message: "Neuen Lieferant gespeichert",
              type: "success",
            });
          })
          .catch(function (error) {
            Message({
              message: error.message,
              type: "error",
            });
          });
      }
    },
    editType() {
      if (this.selectedTypeID == "") {
        Message({
          message: "Bitte Lieferant auswählen",
          type: "info",
        });
        return;
      }

      let route = `/api/suppliers/${this.selectedTypeID}`;
      let self = this;
      this.isEditingManually = true;

      //load supplier by id
      this.axios
        .get(route)
        .then(function (result) {
          //set user data loaded from database
          self.supplier = result.data;
          self.dialogVisible = true;
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    deleteType() {
      if (this.selectedTypeID == "") {
        Message({
          message: "Bitte Lieferant auswählen",
          type: "info",
        });
        return;
      }
      this.$confirmDelete().then(() => {
        let self = this;
        this.axios
          .delete("/api/suppliers/" + this.selectedTypeID)
          .then(function (response) {
            self.$emit("supplier-deleted", self.selectedTypeID);
            self.selectedTypeID = "";
            Message({
              message: "Lieferant gelöscht",
              type: "success",
            });
          })
          .catch(function (error) {
            if (error.response && error.response.status == 590) {
              Message({
                message: error.response.data,
                type: "error",
              });
            } else {
              Message({
                message: error.message,
                type: "error",
              });
            }
          });
      });
    },
  },
};
</script>

<style></style>
