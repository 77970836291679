<template>
  <div class="card card-user">
    <div v-if="!contacts.length" style="padding: 10px; text-align: center; color: #858585">
      {{ $t("src.components.project.contacts.components.serviceproviderslist.keineDaten") }}
    </div>
    <div v-for="(contactData, idx) in contacts" :key="idx">
      <contact-record
        :subcontractorId="subcontractorId"
        :contactData="contactData"
        :isEditMode="isEditMode"
        :title="`contact_${idx + 1}`"
        @removeRecord="removeItem(idx)"
      />
      <el-divider />
    </div>

    <div class="form-group" v-show="isEditMode">
      <el-button type="primary" :disabled="!isEditMode" icon="el-icon-plus" @click="addItem">{{
        $t("src.components.project.contacts.components.serviceproviderslist.hinzufgen")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { Button, Divider } from "element-ui";
import ContactRecord from "./ContactRecord.vue";

export default {
  name: "contacts",
  components: {
    ContactRecord,
    [Divider.name]: Divider,
    [Button.name]: Button,
  },
  props: {
    isEditMode: Boolean,
    subcontractorId: { type: String },
    contacts: { type: Array, default: () => [] },
  },
  methods: {
    addItem() {
      this.contacts.push({});
    },
    handleDeleteFile(url) {
      this.$emit("deleteFile", url);
    },
    removeItem(idx) {
      this.contacts.splice(idx, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
