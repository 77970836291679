<template>
  <el-dialog :title="getTitle" :visible.sync="isVisible">
    <el-form label-position="top">
      <el-form-item :label="$t('src.components.generic.copydialog.name')">
        <el-input v-model="newName" />
      </el-form-item>
      <el-form-item :label="$t('src.components.generic.copydialog.zuKopierendeDaten')">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{
          $t("src.components.generic.copydialog.alles")
        }}</el-checkbox>
        <el-checkbox-group v-model="checkedOptions" @change="handleCheckedOptionsChange">
          <el-checkbox v-for="item in checkboxesList" :label="item.value" :key="item.value">{{
            item.label
          }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-row justify="space-between" type="flex">
        <el-button @click="dismissModal">{{ $t("src.components.generic.copydialog.abbrechen") }}</el-button>
        <el-button @click="submit" type="primary" :loading="loading">{{
          $t("src.components.generic.copydialog.copy")
        }}</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
import { Dialog, Form, FormItem, Input, Checkbox, CheckboxGroup, Row, Button, Message } from "element-ui";

export default {
  name: "copy-dialog",
  props: {
    resourceType: { type: String, required: true },
    resourceId: { type: String },
    resourceRecord: { type: Object, default: () => ({}) },
    checkboxesList: {
      type: Array,
      default: () => [],
      validator: (val) => val.every((item) => item.value && item.label && (item.url || item.modelProperty)),
    },
  },
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [CheckboxGroup.name]: CheckboxGroup,
    [Message.name]: Message,
    [Row.name]: Row,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      loading: false,
      isVisible: false,
      isIndeterminate: false,
      checkAll: false,
      newName: "",
      checkedOptions: [],
    };
  },
  computed: {
    getTitle() {
      return `${this.resourceRecord.name} kopieren`;
    },
  },
  methods: {
    showModal() {
      this.isVisible = true;
    },
    handleCheckAllChange(val) {
      this.checkedOptions = val ? this.checkboxesList.map((item) => item.value) : [];
      this.isIndeterminate = false;
    },
    handleCheckedOptionsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkboxesList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkboxesList.length;
    },
    dismissModal() {
      this.isVisible = false;
    },
    async submit() {
      let urlFormData;
      try {
        this.loading = true;
        const formData = { ...this.resourceRecord, name: this.newName };
        const { urlsToSubmit, propsToExclude } = this.checkboxesList.reduce(
          (obj, current) => {
            const isInCheckedList = this.checkedOptions.includes(current.value);
            // NOTE: modelProperty works the opposite of checked: if modelProperty is not checked - then it is !excluded! from submitted form data.
            if (current.modelProperty && !isInCheckedList) {
              obj.propsToExclude.push(current.modelProperty);
            } else if (current.url && isInCheckedList) {
              obj.urlsToSubmit.push(current.url);
            }
            return obj;
          },
          { urlsToSubmit: [], propsToExclude: [] }
        );
        propsToExclude.forEach((prop) => (formData[prop] = undefined));
        const copiedRecord = await this.axios
          .post(`/api/${this.resourceType}/copy/${this.resourceId}`, formData)
          .then((res) => res.data)
          .catch((err) => {
            throw err;
          });

        urlFormData = { oldModelId: this.resourceId, newModelId: copiedRecord.id };
        await Promise.all(urlsToSubmit.map((url) => this.axios.post(url, urlFormData)));
        this.dismissModal();
        Message.success(`"${copiedRecord.name}" Daten kopiert`);
      } catch (error) {
        Message.error(error.message);
        if (urlFormData.newModelId) {
          this.axios.delete(`/api/${this.resourceType}/${urlFormData.newModelId}`);
        }
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    resourceRecord(newVal, oldVal) {
      if (!this.newName && newVal && newVal.name) {
        this.newName = `${newVal.name} - COPY`;
      }
    },
  },
};
</script>

<style></style>
