<template>
  <div ref="wrapper">
    <draggable tag="div" v-model="localValue" @end="onEnd" handle=".handle">
      <div
        v-for="(item, idx) in localValue"
        :key="idx"
        style="display: flex; justify-content: space-between; align-items: center"
        class="mb-2"
      >
        <!-- Actions for editing item -->
        <div class="handle">
          <drag-icon />
        </div>
        <template v-if="idx === editingIdx">
          <div class="flex" style="flex-grow: 1">
            <el-autocomplete
              style="width: 100%"
              :value="editingValue"
              @input="handleInput($event, idx)"
              class="mr-2"
              :fetch-suggestions="querySearch"
              :trigger-on-focus="false"
              @select="handleSelect"
              select-when-unmatched
            />

            <v-select v-model="editingResourceType" :options="resourceTypesOptions" filterable clearable class="mr-2" />
          </div>

          <el-button-group v-if="idx === editingIdx" style="flex-shrink: 0">
            <el-button
              type="primary"
              icon="el-icon-check"
              :disabled="!editingValue || !editingResourceType"
              @click="saveItem(idx)"
            ></el-button>
            <el-button icon="el-icon-close" @click="cancelEditing"></el-button>
          </el-button-group>
        </template>
        <!-- Actions for non-editing item -->
        <template v-else>
          <div class="input-readonly mr-2" style="width: 100%">
            {{ item.name }} - {{ item.resourceType | formatResourceType }}
          </div>
          <el-button-group style="flex-shrink: 0">
            <el-button icon="el-icon-edit" @click="setEditMode(idx)"></el-button>
            <el-button type="danger" icon="el-icon-delete" @click="deleteItem(idx)"></el-button>
          </el-button-group>
        </template>
      </div>
    </draggable>
    <div>
      <el-button @click="addItem" :disabled="isAddDisabled">{{ $t("hinzufugen") }}</el-button>
      <el-button @click="sortAB">{{ $t("Sort alphabetically") }}</el-button>
    </div>
  </div>
</template>

<script>
import { ButtonGroup, Button, Autocomplete } from "element-ui";
import DragIcon from "vue-material-design-icons/Drag.vue";
import draggable from "vuedraggable";
import { waitFor } from "src/utils/waitFor";
import { last, orderBy } from "lodash";

const resourceTypesOptions = [
  { label: "Mitarbeiter", value: "employee" },
  { label: "Maschine", value: "machine" },
  { label: "KFZ", value: "vehicle" },
  { label: "RHB", value: "rhb" },
  { label: "Verbrauchsmaterial", value: "supply" },
];

export default {
  name: "checklist-items",
  props: {
    value: { type: Array },
    isEditing: { type: Boolean },
  },
  components: {
    draggable,
    DragIcon,
    [Autocomplete.name]: Autocomplete,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      resourceSortOrder: {
        machine: 1,
        rhb: 2,
        vehicle: 3,
        employee: 4,
      },
      localValue: [],
      suggestions: [],
      editingIdx: null,
      editingValue: "",
      editingResourceType: null,
      resourceTypesOptions: resourceTypesOptions,
    };
  },
  mounted() {
    if (this.value && this.value.length) {
      this.localValue = [].concat(this.value);
    }
  },
  methods: {
    onEnd({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        this.$emit("input", this.localValue.slice());
      }
    },
    async addItem() {
      if (typeof this.editingIdx === "number") {
        this.localValue[this.editingIdx].name = this.editingValue;
        this.localValue[this.editingIdx].resourceType = this.editingResourceType;
        this.$emit("input", this.localValue.slice());
      }
      await waitFor(200);
      if (!this.localValue[this.localValue.length - 1]) {
        this.localValue.pop();
      }
      const previousItem = last(this.localValue);
      let initialResourceType = null;
      if (previousItem) {
        initialResourceType = previousItem.resourceType;
      }
      this.localValue.push({ name: "", resourceType: initialResourceType });
      this.dismissEditing();

      this.setEditMode(this.localValue.length - 1);
      this.$nextTick(() => {
        this.$refs.wrapper.querySelector("input").focus();
      });
    },
    handleInput(inputValue, idx) {
      this.editingValue = inputValue;
      this.$emit("itemChange", { name: inputValue, resourceType: this.editingResourceType });
    },
    setEditMode(idx) {
      if (typeof this.editingIdx === "number") {
        this.saveItem(this.editingIdx);
      }
      this.editingValue = this.localValue[idx].name;
      this.editingResourceType = this.localValue[idx].resourceType;
      this.editingIdx = idx;
    },
    deleteItem(idx) {
      this.localValue.splice(idx, 1);
      this.$emit("input", this.localValue.slice());
    },
    saveItem(idx) {
      this.localValue[idx] = { name: this.editingValue, resourceType: this.editingResourceType };
      this.cancelEditing();
      this.$emit("input", this.localValue.slice());
    },
    dismissEditing() {
      this.editingIdx = null;
      this.editingValue = "";
      this.editingResourceType = null;
    },
    cancelEditing() {
      if (!this.editingValue || !this.localValue[this.localValue.length - 1]) {
        this.localValue.pop();
      }
      this.dismissEditing();
    },
    async querySearch(queryString, cb) {
      try {
        const query = queryString.toLowerCase();
        const result = (await this.axios.get("/api/checklists/suggestions", { params: { search: query } })) || [];
        cb(result.data.sort().map((item) => ({ value: item })));
      } catch (error) {
        throw error;
      }
    },
    handleSelect(item) {
      this.editingValue = item.value;
    },
    sortAB() {
      const ordered = orderBy(this.localValue, [
        (o) => this.resourceSortOrder[o.resourceType],
        (o) => o.name.toLowerCase(),
      ]);
      this.localValue = ordered;
      this.$nextTick(() => {
        this.$emit("input", this.localValue.slice());
      });
    },
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.localValue = [].concat(newVal);
      }
    },
    editingResourceType(newVal, oldVal) {
      this.$emit("itemChange", { name: this.editingValue, resourceType: newVal });
    },
  },
  computed: {
    isAddDisabled() {
      return typeof this.editingIdx === "number" && (!this.editingValue || !this.editingResourceType);
    },
  },
  filters: {
    formatResourceType(value) {
      const valueFound = resourceTypesOptions.find((opt) => opt.value === value);
      return valueFound ? valueFound.label : "";
    },
  },
};
</script>

<style></style>
