<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.serviceprovider.index.einkauf")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.subcontractor.index") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="21">
        <div class="n-view-title">{{ $t("src.components.subcontractor.index") }}</div>
      </el-col>

      <el-col :span="3" class="text-right">
        <new-listitem-button v-if="$can('create', 'subcontractor')" to="/purchasing/subcontractor/profile/new">{{
          $t("src.components.serviceprovider.index.newSubcontractor")
        }}</new-listitem-button>
      </el-col>
    </el-row>

    <index-table
      v-if="render"
      :propsToSearch="propsToSearch"
      authSubject="subcontractor"
      modelType="subcontractor"
      :extendedColumns="extendedColumns"
      :supressColumns="supressColumns"
      :defaultSort="{ prop: 'name', order: 'ascending' }"
    >
      <!-- NAME and PICTURE COLUMN -->
      <template v-slot:Name="props">
        <el-row type="flex" align="middle">
          <el-col :span="24">
            <router-link
              v-if="$can('read', 'subcontractor')"
              :to="`/purchasing/subcontractor/profile/view/${props.data.row.id}`"
            >
              {{ props.data.row.name }}
            </router-link>
            <span v-else>{{ props.data.row.name }}</span>
            <el-badge
              :type="props.data.row.active ? 'success' : 'danger'"
              style="padding-top: 9px; padding-left: 6px"
              is-dot
            />
          </el-col>
        </el-row>
      </template>
    </index-table>
  </div>
</template>

<script>
import { IndexTable, NewListItemButton } from "src/components/UIComponents";

export default {
  data() {
    return {
      render: true,
      extendedColumns: [
        {
          label: "Name",
          key: "name",
          minWidth: 300,
        },
      ],
      supressColumns: ["status"],
      propsToSearch: ["name", ["contact", "zip"], "contact.contactPerson", "contact.city"],
    };
  },
  mounted() {},
  methods: {
    // this hook is used to provide fetched types to IndexTable
    async reload() {
      this.render = false;
      await this.$nextTick();
      this.render = true;
    },
  },
  components: {
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace !important;
}
</style>
