import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alert.module";
import { account } from "./account.module";
import { users } from "./users.module";
import { versions } from "./version.module";
import { montagsplanung } from "./montagsplanung.module";
import { deleteCalendarEvent } from "./deleteCalendarEvent.module";
import { projectRangeResolve } from "./projectRangeResolve.module";
import { defaultServiceProviders } from "./defaultServiceProviders.module";
import { productGroupSettings } from "./productGroupSettings.module";
import { serviceSpectrum } from "./serviceSpectrum.module";
import { ressourceStatus } from "./ressourceStatus.module";
import { dirtyFlag } from "./dirtyFlag.module";
import { dailyReport } from "./DailyReport.module";
import { customerSettings } from "./customerSettings.module";
import { currentTab } from "./currentTab.module";
import { granularitySettings } from "./granularitySettings.module";
import { notifications } from "./notifications.module";
import { pdfViewer } from "./pdfViewer.module";

// event bus replacement
import { widget } from "./widget.module";
import { profileEditMode } from "./profileEditMode.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    account,
    users,
    versions,
    montagsplanung,
    deleteCalendarEvent,
    projectRangeResolve,
    defaultServiceProviders,
    productGroupSettings,
    serviceSpectrum,
    ressourceStatus,
    dailyReport,
    dirtyFlag,
    customerSettings,
    currentTab,
    granularitySettings,
    notifications,
    pdfViewer,
    //former event bus implementation
    widget,
    profileEditMode,
  },
});
