<template>
  <div style="margin-top: -25px">
    <h4 class="n-profile-subtitle" style="margin-left: 0px; padding-top: 20px">
      {{ $t("src.components.project.downloadcenter.uploadedprojectdocuments.projektdateien") }}
    </h4>
    <el-table
      v-loading="loading"
      :data="documents"
      size="small"
      ref="table"
      @selection-change="handleSelectionChange"
      :default-sort="{ order: 'ascending', prop: 'createdAt' }"
    >
      <el-table-column type="selection" width="55" label-class-name="pl-15"> </el-table-column>
      <el-table-column
        width="300"
        sortable
        show-overflow-tooltip
        prop="documentTypeName"
        :label="$t('src.components.project.downloadcenter.uploadedprojectdocuments.typ')"
      >
        <template v-slot="props">
          <div style="white-space: pre-line">{{ props.row.documentTypeName }}</div>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        width="*"
        min-width="200"
        prop="document.name"
        :label="$t('src.components.project.downloadcenter.uploadedprojectdocuments.name')"
      >
      </el-table-column>
      <el-table-column
        sortable
        width="200"
        prop="createdAt"
        :label="$t('src.components.project.downloadcenter.uploadedprojectdocuments.hochgeladenBei')"
      >
        <template v-slot="props">
          {{ props.row.createdAt | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="right" class-name="action-buttons td-actions" width="200">
        <template v-slot:header>
          <template v-if="rowsSelection.length">
            <el-button class="btn btn-sm btn-ghost" @click.prevent="viewDocument(rowsSelection)">
              <eye-icon class="icon-lg" />
            </el-button>
            <!-- <el-button class="btn btn-sm btn-ghost" @click.prevent="printDocuments(rowsSelection)">
              <file-pdf-box-icon class="icon-lg" />
            </el-button> -->
            <el-button class="btn btn-sm btn-ghost" @click.prevent="downloadAndSave(rowsSelection)">
              <download-icon class="icon-lg" />
            </el-button>

            <el-button
              class="btn btn-sm btn-ghost"
              @click.prevent="removeDocuments(rowsSelection)"
              v-if="accessRights === 'full'"
            >
              <trash-can-outline-icon class="icon-lg" />
            </el-button>
          </template>
        </template>
        <template v-slot="props">
          <el-button class="btn btn-sm btn-ghost" @click.prevent="viewDocument(props.row)">
            <eye-icon class="icon-lg" />
          </el-button>
          <!-- <el-button class="btn btn-sm btn-ghost" @click.prevent="printDocuments(props.row)">
            <file-pdf-box-icon class="icon-lg" />
          </el-button> -->
          <el-button class="btn btn-sm btn-ghost" @click.prevent="downloadAndSave(props.row)">
            <download-icon class="icon-lg" />
          </el-button>

          <el-button
            class="btn btn-sm btn-ghost"
            @click.prevent="removeDocuments(props.row)"
            v-if="accessRights === 'full'"
          >
            <trash-can-outline-icon class="icon-lg" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn, Message } from "element-ui";
import { moment } from "src/config/moment";
import DownloadIcon from "vue-material-design-icons/Download.vue";
import FilePdfBoxIcon from "vue-material-design-icons/FilePdfBox.vue";
import EyeIcon from "vue-material-design-icons/Eye.vue";
import { saveAs } from "file-saver";
import { getFilenameFromResponse } from "src/utils/getFilenameFromResponse";
import printJs from "print-js";

export default {
  name: "uploaded-project-documents",
  props: {
    accessRights: { type: String },
    projectId: { type: String, required: true },
    loading: { type: Boolean },
    documents: { type: Array },
  },
  components: {
    EyeIcon,
    [FilePdfBoxIcon.name]: FilePdfBoxIcon,
    [DownloadIcon.name]: DownloadIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Message.name]: Message,
  },
  data() {
    return {
      rowsSelection: [],
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.rowsSelection = val;
    },
    async getMergedDocuments(rowData) {
      try {
        if (Array.isArray(rowData)) {
          const formBody = { ids: rowData.map((i) => i._id) };
          const response = await this.axios.request({
            method: "POST",
            url: "/api/project-documents/concat",
            data: formBody,
            responseType: "blob",
          });
          return response.data;
        } else {
          return this.downloadDocuments(rowData);
        }
      } catch (error) {
        throw error;
      }
    },
    async viewDocument(rowData) {
      const response = await this.downloadDocuments(rowData);
      const arrayBuffer = await response.data.arrayBuffer();
      const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
      const objectUrl = window.URL.createObjectURL(pdfBlob);
      window.open(objectUrl, "_blank");
    },
    async viewDocuments(rowData) {
      const blob = await this.getMergedDocuments(rowData);
      const arrayBuffer = await blob.arrayBuffer();
      const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
      const objectUrl = window.URL.createObjectURL(pdfBlob);
      window.open(objectUrl, "_blank");
    },
    // async printDocuments(rowData) {
    //   const loadingString = this.$t("loadingString");
    //   this.$message({
    //     type: null,
    //     dangerouslyUseHTMLString: true,
    //     message: `<i class="el-icon-loading"></i> ${loadingString}`,
    //   });
    //   try {
    //     const blob = await this.downloadDocuments(rowData);
    //     const objectUrl = window.URL.createObjectURL(blob);
    //     printJs({
    //       printable: objectUrl,
    //       type: "pdf",
    //       showModal: true,
    //       modalMessage: "Vorbereitung zum Drucken",
    //       onError: (err) => {
    //         throw err;
    //       },
    //     });
    //     // setTimeout(() => URL.revokeObjectURL(objectUrl), 3000);
    //     // saveAs(response.data, filename);
    //   } catch (error) {
    //     Message.error(error.message);
    //     throw error;
    //   } finally {
    //     this.$message.close();
    //   }
    // },
    async downloadAndSave(rowData) {
      const loadingString = this.$t("loadingString");
      this.$message({
        type: null,
        dangerouslyUseHTMLString: true,
        message: `<i class="el-icon-loading"></i> ${loadingString}`,
      });
      try {
        const response = await this.downloadDocuments(rowData);
        const filename = getFilenameFromResponse(response);
        saveAs(response.data, filename);
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.$message.close();
      }
    },
    async downloadDocuments(rowData) {
      try {
        const formBody = { ids: Array.isArray(rowData) ? rowData.map((i) => i._id) : [rowData._id] };
        const response = await this.axios.request({
          method: "POST",
          url: "/api/project-documents/download",
          data: formBody,
          responseType: "blob",
        });
        return response;
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    removeDocuments(rowData) {
      this.$confirmDelete()
        .then(async () => {
          try {
            const formBody = { ids: Array.isArray(rowData) ? rowData.map((i) => i._id) : [rowData._id] };
            await this.axios.post("/api/project-documents/remove", formBody);
            this.$emit("documentAdded");
          } catch (error) {
            Message.error(error.message);
            throw error;
          }
        })
        .catch(() => {});
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("lll");
    },
  },
};
</script>

<style></style>
