export const profileEditMode = {
  namespaced: true,
  state: {
    isEditMode: false,
  },
  mutations: {
    setEditMode(state, value) {
      state.isEditMode = value;
    },
  },
  actions: {
    changeEditMode({ commit }, value) {
      commit('setEditMode', value);
    },
  },
};