<template>
  <div>
    <el-empty description="Keine Rechte" v-if="showEmpty" />
    <el-tabs v-else v-model="activeTab" tab-position="left" class="settings-tabs hide-disabled">
      <el-tab-pane label="Projekt" name="project" :disabled="!settingsAccess.specificAccess.project">
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <project-profile-settings
            :accessRights="settingsAccess.specificAccess.project"
            :isEditMode="settingsAccess.specificAccess.project !== 'readonly'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="Maschine" name="machine" :disabled="!settingsAccess.specificAccess.machine">
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <machine-profile-settings
            :accessRights="settingsAccess.specificAccess.machine"
            :isEditMode="settingsAccess.specificAccess.machine !== 'readonly'"
            :isActive="activeTab === 'machine'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="RHB" name="rhb" :disabled="!settingsAccess.specificAccess.rhb">
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <rhb-profile-settings
            :accessRights="settingsAccess.specificAccess.rhb"
            :isEditMode="settingsAccess.specificAccess.rhb !== 'readonly'"
            :isActive="activeTab === 'rhb'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="Verbrauchsm." name="supply" :disabled="!settingsAccess.specificAccess.supply">
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <supply-profile-settings
            :accessRights="settingsAccess.specificAccess.supply"
            :isEditMode="settingsAccess.specificAccess.supply !== 'readonly'"
            :isActive="activeTab === 'supply'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane
        label="KFZ"
        name="vehicle"
        :disabled="!settingsAccess.specificAccess.vehicle"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <vehicle-profile-settings
            :isActive="activeTab === 'vehicle'"
            :accessRights="settingsAccess.specificAccess.vehicle"
            :isEditMode="settingsAccess.specificAccess.vehicle !== 'readonly'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="Mitarbeiter" name="employee" :disabled="!settingsAccess.specificAccess.employee">
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <employee-profile-settings
            :accessRights="settingsAccess.specificAccess.employee"
            :isEditMode="settingsAccess.specificAccess.employee !== 'readonly'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane
        label="Dienstleister"
        name="service_provider"
        :disabled="!settingsAccess.specificAccess.service_provider"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 140px)">
          <serviceProvider-profile-settings
            :accessRights="settingsAccess.specificAccess.service_provider"
            :isEditMode="settingsAccess.specificAccess.service_provider !== 'readonly'"
          />
        </perfect-scrollbar>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Tabs, TabPane, Empty } from "element-ui";
import ProjectProfileSettings from "../../Project/ProfileSettings.vue";
import MachineProfileSettings from "../../Machine/ProfileSettings.vue";
import RhbProfileSettings from "../../RHB/ProfileSettings.vue";
import SupplyProfileSettings from "../../Supply/ProfileSettings.vue";
import VehicleProfileSettings from "../../Vehicle/ProfileSettings.vue";
import EmployeeProfileSettings from "../../Employees/ProfileSettings.vue";
import ServiceProviderProfileSettings from "../../ServiceProvider/ProfileSettings.vue";
import { mapState } from "vuex";
import { get } from "lodash";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "settings-management",
  components: {
    ProjectProfileSettings,
    MachineProfileSettings,
    RhbProfileSettings,
    SupplyProfileSettings,
    VehicleProfileSettings,
    EmployeeProfileSettings,
    ServiceProviderProfileSettings,
    PerfectScrollbar,
    [Empty.name]: Empty,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  props: {
    isEditMode: Boolean,
  },
  data() {
    return {
      activeTab: "0",
      showEmpty: false,
    };
  },
  methods: {
    handleSetFirstTab() {
      if (this.activeTab === "0") {
        const settingsAccess = this.settingsAccess;
        const visibleTabs = Object.entries({
          project: Boolean(settingsAccess.specificAccess.project),
          machine: Boolean(settingsAccess.specificAccess.machine),
          rhb: Boolean(settingsAccess.specificAccess.rhb),
          supply: Boolean(settingsAccess.specificAccess.supply),
          vehicle: Boolean(settingsAccess.specificAccess.vehicle),
          employee: Boolean(settingsAccess.specificAccess.employee),
          service_provider: Boolean(settingsAccess.specificAccess.service_provider),
        })
          .filter(([_, value]) => value)
          .map(([key]) => key);
        if (visibleTabs.length) {
          this.activeTab = visibleTabs[0];
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
      }
    },
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    settingsAccess() {
      return get(this.accessRights, "company_settings", { specificAccess: {}, generalAccess: null });
    },
  },
  watch: {
    accessRights(newVal, oldVal) {
      if (newVal.id && !oldVal.id) {
        this.handleSetFirstTab();
      }
    },
  },
};
</script>

<style>
/* .settings-tabs .el-tabs__content {
  height: calc(100vh - 140px);
  overflow: auto;
} */
</style>