<template>
  <div>
    <form>
      <span class="n-profile-title">{{ tabName }}</span>

      <div class="card-body">
        <!-- ALLGEMEIN -->
        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.privateprofile.allgemein") }}
        </div>
        <el-row :gutter="20" class="n-profile-spacer">
          <el-col :md="6">
            <profile-date-picker
              v-model="employee.birthday"
              :title="$t('src.components.employees.privateprofile.geburtstag')"
              :isEditMode="computedEditMode"
              viewFormat="DD.MM"
              valueFormat="YYYY-MM-DD"
            />
          </el-col>
        </el-row>

        <hr />

        <!-- KONTAKTDATEN -->
        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.privateprofile.kontaktdaten") }}
        </div>
        <el-row :gutter="20" class="n-profile-spacer">
          <!-- TELEFON -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.phone"
              :label="$t('src.components.employees.privateprofile.telefon')"
              :editMode="computedEditMode"
            />
          </el-col>
          <!-- HANDY -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.mobile"
              :label="$t('src.components.employees.privateprofile.handy')"
              :name="$t('src.components.employees.privateprofile.handy')"
              :editMode="computedEditMode"
              key="mobile"
            />
          </el-col>
          <!-- FAX -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.fax"
              :label="$t('src.components.employees.privateprofile.fax')"
              :editMode="computedEditMode"
            />
          </el-col>
          <!-- EMAIL -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.email"
              :name="$t('src.components.employees.privateprofile.email')"
              type="email"
              rules="email"
              :label="$t('src.components.employees.privateprofile.email')"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>

        <hr />

        <!-- ADDRESSDATEN -->
        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.employees.privateprofile.adressdaten") }}
        </div>
        <el-row :gutter="20" style="margin-bottom: 20px">
          <!-- STRAßE -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.street"
              :label="$t('src.components.employees.privateprofile.strae')"
              :editMode="computedEditMode"
            />
          </el-col>

          <!-- HAUSNUMMER -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.no"
              :label="$t('src.components.employees.privateprofile.hausnummer')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.zip"
              :label="$t('src.components.employees.privateprofile.plz')"
              :editMode="computedEditMode"
            />
          </el-col>

          <!-- STADT -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.city"
              :label="$t('src.components.employees.privateprofile.stadt')"
              :editMode="computedEditMode"
            />
          </el-col>

          <!-- ADRESSZUSATZ -->
          <el-col :md="6">
            <profile-input
              v-model="employee.hrPrivate.addressAddition"
              :label="$t('src.components.employees.privateprofile.adresszusatz')"
              :editMode="computedEditMode"
            />
          </el-col>

          <!-- ZIP -->
          <el-col :md="6">
            <profile-select
              :title="$t('src.components.employees.privateprofile.land')"
              :multiple="false"
              :items="countries"
              v-model="employee.hrPrivate.country"
              valueIdentifier="value"
              label="name"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>
      </div>
      <!-- END OF CARD -->
    </form>
  </div>
</template>

<script>
import countryList from "src/country-list.json";

export default {
  name: "employee-private-profile",
  props: {
    employee: Object,
    tabName: String,
    accessRights: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  methods: {},
  data() {
    return {
      countries: countryList,
    };
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>
