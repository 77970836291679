import {
  FgInput,
  EditButton,
  SaveButton,
  CancelButton,
  EditProfileButton,
  DeleteProfileButton,
  SaveProfileButton,
  CancelProfileButton,
  ProfileInput,
  ProfileDropDown,
  ProfileSelect,
  ProfileDatePicker,
  ProductGroupSelect,
  VSelect,
  ProfileSwitch,
  ProfileRadioGroup,
  PrDatePicker,
} from "src/components/UIComponents";
import {
  Button,
  Card,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Select,
  Option,
  Menu,
  MenuItem,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Image,
  Badge,
  Dialog,
  DatePicker,
  Link,
  Table,
  TableColumn,
} from "element-ui";

import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import Plus from "vue-material-design-icons/Plus";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 * Ideally, only small components that are re-used many times across your application should be registered here.
 * For plugins and bigger components local registration is preferable because it will allow you to do code splitting easier :)
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Input.name, Input);
    Vue.component(FgInput.name, FgInput);
    Vue.component(Button.name, Button);
    Vue.component(SaveButton.name, SaveButton);
    Vue.component(CancelButton.name, CancelButton);
    Vue.component(EditButton.name, EditButton);
    Vue.component(Card.name, Card);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(Link.name, Link);
    Vue.component(Table.name, Table);
    Vue.component(TableColumn.name, TableColumn);

    //menu
    Vue.component(Menu.name, Menu);
    Vue.component(MenuItem.name, MenuItem);
    Vue.component(Submenu.name, Submenu);

    //dropdown
    Vue.component(Dropdown.name, Dropdown);
    Vue.component(DropdownMenu.name, DropdownMenu);
    Vue.component(DropdownItem.name, DropdownItem);

    //Select
    Vue.component(Select.name, Select);
    Vue.component(VSelect.name, VSelect);
    Vue.component(Option.name, Option);
    Vue.component(ProfileSwitch.name, ProfileSwitch);
    Vue.component(ProfileRadioGroup.name, ProfileRadioGroup);

    //Breadcrumb
    Vue.component(Breadcrumb.name, Breadcrumb);
    Vue.component(BreadcrumbItem.name, BreadcrumbItem);

    //Layout
    Vue.component(Col.name, Col);
    Vue.component(Row.name, Row);

    //Image
    Vue.component(Image.name, Image);

    //Badge
    Vue.component(Badge.name, Badge);

    //Dialog
    Vue.component(Dialog.name, Dialog);

    //Profile Controls
    Vue.component(EditProfileButton.name, EditProfileButton);
    Vue.component(SaveProfileButton.name, SaveProfileButton);
    Vue.component(CancelProfileButton.name, CancelProfileButton);
    Vue.component(DeleteProfileButton.name, DeleteProfileButton);
    Vue.component(ProfileInput.name, ProfileInput);
    Vue.component(ProfileDropDown.name, ProfileDropDown);
    Vue.component(ProfileSelect.name, ProfileSelect);
    Vue.component(ProfileDatePicker.name, ProfileDatePicker);
    Vue.component(PrDatePicker.name, PrDatePicker);
    Vue.component(ProductGroupSelect.name, ProductGroupSelect);

    //VUE MATERIAL ICONS
    Vue.component(Pencil.name, Pencil);
    Vue.component(TrashCanOutline.name, TrashCanOutline);
    Vue.component(Plus.name, Plus);
  },
};

export default GlobalComponents;
