<template>
  <div style="width: 550px">
    <el-row>
      <el-col>
        <span class="n-profile-label-title">{{ $t("src.components.project.projectstatus.projektstatus") }}</span>
      </el-col>
    </el-row>

    <div>
      <div class="project-status-item">
        <template v-if="isEditMode">
          <el-radio v-model="localProgress" label="progress_1">{{
            $t("src.components.project.projectstatus.reserviert")
          }}</el-radio>
          <pr-date-picker
            :disabled="!isEditMode"
            :clearable="false"
            value-format="YYYY-MM-DD"
            v-model="localProgressDate_1"
          />
        </template>
        <template v-else>
          <div :class="['project-status', progress === 'progress_1' ? 'status-selected' : '']">
            {{ $t("src.components.project.projectstatus.reserviert") }}
          </div>
          <div class="project-date">{{ localProgressDate_1 | formatDate }}</div>
        </template>
      </div>
      <div class="project-status-item">
        <template v-if="isEditMode">
          <el-radio v-model="localProgress" label="progress_2">{{
            $t("src.components.project.projectstatus.auszufhrendeArbeit")
          }}</el-radio>
          <pr-date-picker
            :disabled="!isEditMode"
            :clearable="false"
            value-format="YYYY-MM-DD"
            v-model="localProgressDate_2"
          />
        </template>
        <template v-else>
          <div :class="['project-status', progress === 'progress_2' ? 'status-selected' : '']">
            {{ $t("src.components.project.projectstatus.auszufhrendeArbeit") }}
          </div>
          <div class="project-date">{{ localProgressDate_2 | formatDate }}</div>
        </template>
      </div>
      <div class="project-status-item">
        <template v-if="isEditMode">
          <el-radio v-model="localProgress" label="progress_3">{{
            $t("src.components.project.projectstatus.abgeschlossen")
          }}</el-radio>
          <pr-date-picker :disabled="!isEditMode" :clearable="false" v-model="localProgressDate_3" />
        </template>
        <template v-else>
          <div :class="['project-status', progress === 'progress_3' ? 'status-selected' : '']">
            {{ $t("src.components.project.projectstatus.abgeschlossen") }}
          </div>
          <div class="project-date">{{ localProgressDate_3 | formatDate }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Radio, DatePicker } from "element-ui";
import { moment } from "src/config/moment";

export default {
  name: "project-status",
  components: {
    [Radio.name]: Radio,
    [DatePicker.name]: DatePicker,
  },
  props: {
    isEditMode: { type: Boolean, required: true },
    progress: { type: String },
    progressDates: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      localProgressDates: {
        progress_1: null,
        progress_2: null,
        progress_3: null,
      },
      statuscolor: "#123456",
    };
  },
  mounted() {
    if (this.progressDates) {
      this.localProgressDates = this.progressDates.reduce((obj, curr) => {
        obj[curr.progress] = curr.date;
        return obj;
      }, {});
    }
  },
  methods: {
    getButtonType(progress) {
      return this.progress === progress ? "primary" : undefined;
    },
    setProgress(progress) {
      const date = new Date();
      this.localProgressDates[progress] = date;
      // set progress_1 when progress_2 is clicked (if progress_1 is not set)
      if (progress === "progress_2" && !this.localProgressDates["progress_1"]) {
        this.localProgressDates["progress_1"] = date;
        this.$emit("updateProgress", { progress: "progress_1", date });
      }
      this.$emit("updateProgress", { progress, date });
    },
  },
  computed: {
    localProgress: {
      get: function () {
        return this.progress;
      },
      set: function (newVal) {
        this.setProgress(newVal);
      },
    },
    localProgressDate_1: {
      get: function () {
        return this.localProgressDates["progress_1"];
      },
      set: function (newVal) {
        if (moment(newVal).isValid()) {
          this.localProgressDates.progress_1 = newVal;
          this.$emit("updateProgress", { progress: "progress_1", date: newVal });
        }
      },
    },
    localProgressDate_2: {
      get: function () {
        return this.localProgressDates["progress_2"];
      },
      set: function (newVal) {
        if (moment(newVal).isValid()) {
          this.localProgressDates.progress_2 = newVal;
          this.$emit("updateProgress", { progress: "progress_2", date: newVal });
        }
      },
    },
    localProgressDate_3: {
      get: function () {
        return this.localProgressDates["progress_3"];
      },
      set: function (newVal) {
        if (moment(newVal).isValid()) {
          this.localProgressDates.progress_3 = newVal;
          this.$emit("updateProgress", { progress: "progress_3", date: newVal });
        }
      },
    },
  },
  watch: {
    progressDates: function (newVal = []) {
      this.localProgressDates = newVal.reduce((obj, curr) => {
        obj[curr.progress] = curr.date;
        return obj;
      }, {});
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("L");
      }
      return "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.project-status-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 350px;
  justify-content: space-between;
  .el-radio {
    min-width: 160px;
    margin-right: 20px;
  }
  .project-status {
    padding: 4px 8px;
    font-size: 12px;
    display: inline-block;
    margin-right: 4px;
    background: #f8f8f8;
  }
  .status-selected {
    background: #a6d8d6;
  }
}
.status-label {
  width: 170px;
  cursor: auto;
  pointer-events: none;
}

.status-selected {
  background-color: #46a19c;
  &:focus,
  &:hover {
    background-color: #46a19c;
  }
}

::v-deep {
  .el-input--suffix .el-input__inner {
    width: 170px;
  }
}
</style>
