<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" center>
      <div style="text-align: center; height: 70vh; overflow: hidden">
        <lazy-image :src="dialogImage" key="contain" height="100%" wrapperStyle="height: 100%;" />
      </div>
    </el-dialog>
    <p class="table-title pickpack-title">
      <span class="table-title__badge">{{ data.length }}</span> {{ title }}
    </p>
    <el-table :data="data" v-if="data.length" :title="title" class="pickpack-report">
      <el-table-column width="50" class-name="avatar-column">
        <template v-slot="props">
          <a style="display: inline-block; height: 50px; width: 50px; line-height: 50px" v-on:click="showAvatar(props)">
            <lazy-image
              :src="getAvatar(props)"
              fit="cover"
              style="width: 50px; height: 50px; border-radius: 2px"
              scroll-container=".pickpack-scrollbar"
              lazy
            >
              <template v-slot:placeholder>
                <div class="image-slot">
                  <span style="font-size: 12px"
                    >{{ $t("src.components.project.pickpack.pickpackresourcetable.lade")
                    }}<span class="dot">{{ $t("src.components.project.pickpack.pickpackresourcetable.108") }}</span>
                  </span>
                </div>
              </template>
              <template v-slot:error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </lazy-image>
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="name" width="*" />
      <el-table-column
        :label="$t('src.components.project.pickpack.pickpackresourcetable.anzahl')"
        width="180"
        v-if="resourceType === 'supply'"
      >
        <template v-slot="scope">
          {{ scope.row.quantity }}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="unit"
        :label="$t('src.components.project.pickpack.pickpackresourcetable.einheit')"
        width="180"
        v-if="resourceType === 'supply'"
      />
      <template #empty><span></span></template>
    </el-table>
    <div v-else style="border-bottom: 1px solid #ebebeb; margin-bottom: 15px" />
  </div>
</template>

<script>
import { Message, Dialog, Table, TableColumn, Image } from "element-ui";
import { LazyImage } from "src/components/UIComponents";

export default {
  name: "pickpack-resource-table",
  props: {
    data: { type: Array, default: () => [] },
    title: { type: String },
    resourceType: { type: String },
  },
  components: {
    [Message.name]: Message,
    [LazyImage.name]: LazyImage,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Image.name]: Image,
  },
  data() {
    return {
      dialogImage: "",
      dialogVisible: false,
    };
  },
  methods: {
    async showAvatar(props) {
      if (props.row.picture) {
        // const blobResponse = await this.axios.get(props.row.picture.url, {
        //   responseType: "blob",
        // });
        // const objectUrl = URL.createObjectURL(blobResponse.data);
        this.dialogImage = props.row.picture.url;
        this.dialogVisible = true;
      }
    },
    getAvatar(props) {
      if (props.row.picture) {
        return this.axios.defaults.baseURL + props.row.picture.thumbnail;
      } else {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      }
    },
  },
  watch: {
    dialogVisible(newVal) {
      if (!newVal) {
        URL.revokeObjectURL(this.dialogImage);
        this.dialogImage = "";
      }
    },
  },
};
</script>

<style lang="scss">
div.pickpack-scrollbar.ps {
  height: calc(100% - 100px);
}

.el-table.pickpack-report {
  margin-bottom: 15px;
  padding-left: 10px;

  thead,
  .el-table__empty-block {
    display: none;
  }

  .avatar-column,
  .avatar-column .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.table-title {
  font-size: 16px;
}

.pickpack-title {
  background: #f8f8f8;
  padding-left: 5px;
}

.table-title__badge {
  display: inline-block;
  padding: 6px 0;
  background-color: #f8f8f8;
  margin-right: 8px;
  width: 49px;
  text-align: right;
  line-height: 20px;
}
</style>
