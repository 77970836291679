<template>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="$attrs">
    <g clip-path="url(#clip0_2363_5184)">
      <rect width="120" height="120" rx="24" fill="url(#paint0_linear_2363_5184)" />
      <rect
        opacity="0.4"
        x="73"
        y="-12"
        width="159"
        height="144"
        fill="url(#paint1_radial_2363_5184)"
        fill-opacity="0.6"
      />
      <rect
        opacity="0.4"
        x="-39"
        y="33"
        width="159"
        height="144"
        fill="url(#paint2_radial_2363_5184)"
        fill-opacity="0.6"
      />
      <g filter="url(#filter0_dddi_2363_5184)">
        <path
          d="M24 35C24 28.3726 29.3726 23 36 23H84C90.6274 23 96 28.3726 96 35V84C96 90.6274 90.6274 96 84 96H36C29.3726 96 24 90.6274 24 84V35Z"
          fill="url(#paint3_linear_2363_5184)"
        />
      </g>
      <g filter="url(#filter1_dddii_2363_5184)">
        <path
          d="M24 35C24 28.3726 29.3726 23 36 23H84C90.6274 23 96 28.3726 96 35V43H24V35Z"
          fill="url(#paint4_linear_2363_5184)"
        />
      </g>
      <rect x="24" y="57" width="2" height="72" transform="rotate(-90 24 57)" fill="#3E3C3B" fill-opacity="0.1" />
      <rect x="24" y="67" width="2" height="72" transform="rotate(-90 24 67)" fill="#3E3C3B" fill-opacity="0.1" />
      <rect x="24" y="76" width="2" height="72" transform="rotate(-90 24 76)" fill="#3E3C3B" fill-opacity="0.1" />
      <rect x="24" y="86" width="2" height="72" transform="rotate(-90 24 86)" fill="#3E3C3B" fill-opacity="0.1" />
      <path
        d="M24.9114 46.4668L95.0886 46.4668"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="2"
        stroke-miterlimit="2"
        stroke-linecap="round"
        stroke-dasharray="0.1 4"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddi_2363_5184"
        x="9"
        y="9"
        width="102"
        height="103"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2363_5184" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2363_5184" result="effect2_dropShadow_2363_5184" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_2363_5184" result="effect3_dropShadow_2363_5184" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2363_5184" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect4_innerShadow_2363_5184" />
      </filter>
      <filter
        id="filter1_dddii_2363_5184"
        x="14"
        y="17"
        width="92"
        height="40"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2363_5184" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2363_5184" result="effect2_dropShadow_2363_5184" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_2363_5184" result="effect3_dropShadow_2363_5184" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2363_5184" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.878431 0 0 0 0 0.4 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect4_innerShadow_2363_5184" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.698039 0 0 0 0 0.00392157 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect4_innerShadow_2363_5184" result="effect5_innerShadow_2363_5184" />
      </filter>
      <linearGradient id="paint0_linear_2363_5184" x1="60" y1="0" x2="60" y2="120" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CEF0D8" />
        <stop offset="1" stop-color="#5580D2" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2363_5184"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(152.5 60) rotate(90) scale(72 79.5)"
      >
        <stop stop-color="white" />
        <stop offset="0.380208" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2363_5184"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(40.5 105) rotate(90) scale(72 79.5)"
      >
        <stop stop-color="white" />
        <stop offset="0.380208" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient id="paint3_linear_2363_5184" x1="60" y1="23" x2="60" y2="96" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FBFBFA" />
        <stop offset="0.637101" stop-color="#EAE7E3" />
      </linearGradient>
      <linearGradient id="paint4_linear_2363_5184" x1="24" y1="23" x2="24" y2="43" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFC24C" />
        <stop offset="1" stop-color="#E3922E" />
      </linearGradient>
      <clipPath id="clip0_2363_5184">
        <rect width="120" height="120" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "notes-icon",
};
</script>

<style lang="scss" scoped></style>
