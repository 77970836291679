<template>
  <div>
    <form>
      <div class="card-body">
        <el-row>
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.machine.profilesettings.leistungsspektrum") }}
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="12">
            <!-- service spectrum settings -->
            <type-settings
              :isEditMode="computedEditMode"
              modelid="serviceSpectrum"
              modelType="global"
              type="Leistungsspektrum"
            />
          </el-col>
        </el-row>

        <hr />

        <el-row>
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.machine.profilesettings.warengruppen") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <!-- WARENGRUPPEN -->
          <el-col :span="24">
            <productgroup-settings ref="productGroupSettings" modelType="machine" :isEditMode="computedEditMode" />
          </el-col>
        </el-row>

        <hr />

        <el-row :gutter="20">
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.machine.profilesettings.status") }}
            </div>
          </el-col>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.machine.profilesettings.dokumente") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px" :gutter="20">
          <el-col :span="12">
            <!-- Status Settings -->
            <status-settings :isEditMode="isEditMode" resourceType="machine" />
          </el-col>
          <el-col :span="12">
            <!-- Dokument Settings -->
            <type-settings
              :isEditMode="computedEditMode"
              modelid="machine"
              modelType="documents"
              type="Dokument"
              :useSystemRelevant="true"
              :useInSma="true"
              :useAccessGroups="true"
              :forAdmin="true"
            />
          </el-col>
        </el-row>
      </div>
    </form>
  </div>
</template>

<script>
import { TypeSettings, ProductGroups } from "src/components/UIComponents";
import StatusSettings from "../UIComponents/Helper/StatusSettings.vue";

export default {
  name: "machine-profile-settings",
  components: {
    StatusSettings,
    [TypeSettings.name]: TypeSettings,
    [ProductGroups.name]: ProductGroups,
  },
  props: {
    accessRights: String,
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isActive: Boolean,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
  watch: {
    isActive(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$refs.productGroupSettings.initialize();
      }
    },
  },
};
</script>
