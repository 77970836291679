var render = function render(){var _vm=this,_c=_vm._self._c;return _c('widget-tab',{attrs:{"title":_vm.$t('src.components.dashboard.views.dashboard.widgets.unavailableResources.title'),"hasFilters":true},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',[_c('profile-select',{staticStyle:{"width":"150px"},attrs:{"editMode":true,"multiple":false,"title":_vm.$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.group'),"items":[
            { value: 'employee', label: 'Personal' },
            { value: 'machine', label: 'Maschinen' },
            { value: 'vehicle', label: 'KFZ' },
            { value: 'rhb', label: 'RHB' },
            { value: 'supply', label: 'Verbrauchsm.' },
          ]},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})],1)],1)]},proxy:true}])},[_c('div',{staticStyle:{"height":"100%"}},[_c('el-table',{staticClass:"d-widget-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.unavailableResources,"max-height":"auto"}},[_c('el-table-column',{attrs:{"label":_vm.$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.name'),"prop":"name"}}),_c('el-table-column',{attrs:{"label":"Austrittsdatum","prop":"dateOfLeaving","formatter":_vm.dateFormatter}}),(_vm.accessRights === 'manage' || _vm.accessRights === 'full')?_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"text-right"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.handleActiveChange(scope.row, scope.$index)}}},[_vm._v(" Deaktivieren ")])],1)]}}],null,false,1185695086)}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }