<template>
  <div>
    <save-profile-button
      @click="openModal"
      :text="$t('src.components.generic.supplierarticles.addsupplierarticle.lieferantHinzufgen')"
      data-testid="add_supplier_article"
    />
    <el-dialog
      :title="$t('src.components.generic.supplierarticles.addsupplierarticle.lieferantenHinzufgen')"
      width="400px"
      :visible.sync="visible"
      @close="discard"
      v-loading="loading"
    >
      <profile-select
        :title="$t('src.components.generic.supplierarticles.addsupplierarticle.lieferant')"
        :editMode="true"
        :clearable="false"
        :multiple="false"
        v-model="selectedSupplier"
        :items="availableSuppliersOptions"
        :required="true"
        filterable
      />
      <div class="mt-2 text-right">
        <save-profile-button
          :text="$t('src.components.generic.supplierarticles.addsupplierarticle.lieferantHinzufgen')"
          :disabled="!selectedSupplier"
          @click="assignSupplier"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Message } from "element-ui";
import SaveProfileButton from "../../UIComponents/Buttons/SaveProfileButton.vue";

export default {
  name: "add-supplier-article",
  components: {
    SaveProfileButton,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
  },
  props: {
    resourceType: { type: String, required: true },
    resourceId: { type: String, required: true },
  },
  data() {
    return {
      visible: false,
      loading: false,
      selectedSupplier: "",
      availableSuppliersOptions: [],
    };
  },
  methods: {
    discard() {
      this.visible = false;
      this.selectedSupplier = "";
      this.availableSuppliersOptions = [];
    },
    openModal() {
      this.visible = true;
      this.fetchAvailableSuppliers();
    },
    async fetchAvailableSuppliers() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/suppliers", {
          params: { resourceType: this.resourceType },
        });
        this.availableSuppliersOptions = response.data
          .filter((supplier) => supplier.active)
          .map((supplier) => ({ label: supplier.name, value: supplier._id }));
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async assignSupplier() {
      try {
        this.loading = true;
        const formData = {
          supplierId: this.selectedSupplier,
          resourceType: this.resourceType,
          resourceId: this.resourceId,
        };
        await this.axios.post("/api/supplier-articles", formData);
        this.$emit("supplierAssigned"); // callback event to trigger re-fetch
        this.discard();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
