import Vue from "vue";
import Keycloak from "keycloak-js";
import { get } from "lodash";
const keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL;

const searchParams = new URLSearchParams(window.location.search);
let realm;
if (searchParams.has("realm")) {
  realm = searchParams.get("realm");
} else {
  realm = localStorage.getItem("realm") || "";
}
const isKioskMode = searchParams.get("kiosk") === "true";

const clientId = isKioskMode ? "kiosk-client" : "vue-client";
const initOptions = {
  url: keycloakUrl,
  realm: realm,
  clientId: clientId,
  onTokenExpired: () => console.log("token expired. Minutes:", new Date().getMinutes()),
};

const _keycloak = new Keycloak(initOptions);

const Plugin = {
  install: (Vue) => {
    Vue.$keycloak = _keycloak;
  },
};
Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
  Vue.prototype.$can = function (action, resource) {
    return get(this.$store.state.account.permissions, `${resource}.${action}`, false);
  };
};

Vue.use(Plugin);

export default Plugin;
