<template>
  <div>
    <el-dialog :visible="visible" @close="dismiss" width="680px" title="Tages-Info" id="tages_info" destroy-on-close>
      <el-form ref="form" :model="formData" :rules="formRules">
        <el-form-item prop="dateRange">
          <profile-date-picker
            style="width: 100%"
            :projectDateRange="projectDateRange"
            :isEditMode="isEditing"
            v-model="formData.dateRange"
            is-range
            :title="$t('src.components.project.departurecontrol.zeitraum')"
            :placeholder="$t('src.components.project.departurecontrol.zeitraum')"
            value-format="YYYY-MM-DD"
            required
          />
        </el-form-item>

        <el-form-item prop="freeText">
          <profile-input
            v-model="formData.freeText"
            :editMode="isEditMode"
            placeholder="Notizen"
            :label="$t('Notes')"
            type="textarea"
            :autosize="{ minRows: 7 }"
          />
        </el-form-item>
        <div class="flex justify-between" v-if="isEditing">
          <el-button class="mr-2" @click.prevent="dismiss()">{{
            $t("src.components.project.departurecontrol.abbrechen")
          }}</el-button>
          <template v-if="isAddingNew">
            <el-button type="primary" class="mt-2" @click.prevent="addInfoSchedule()">{{
              $t("src.components.project.departurecontrol.speichern")
            }}</el-button>
          </template>
          <template v-else>
            <save-button class="mt-2" :disabled="!formData.dateRange" @click.prevent="saveSelectedDeparture()">{{
              $t("src.components.project.departurecontrol.speichern")
            }}</save-button>
          </template>
        </div>
      </el-form>
    </el-dialog>

    <h6>{{ "Tages-Info" }}:</h6>
    <editable-tag
      v-for="(record, idx) in visibleInfoSchedules"
      :key="idx"
      @view="viewInfoSchedule(record)"
      @edit="initEditInfoSchedule(record)"
      @remove="removeInfoSchedule(record)"
      :isEditMode="isEditMode"
      :title="formatInfoScheduleString(record)"
    />
    <cancel-button v-if="isEditMode" @click.prevent="showaddInfoSchedule()">
      <plus-icon class="mr-2" />{{ $t("addInfoSchedule") }}</cancel-button
    >
  </div>
</template>

<script>
import PrDatePicker from "../UIComponents/Inputs/PrDatePicker.vue";
import PlusIcon from "vue-material-design-icons/Plus";
import { EditableTag } from "src/components/UIComponents";
import { moment } from "src/config/moment";
import { Dialog, Form, FormItem, Button, MessageBox } from "element-ui";

export default {
  name: "detailed-info-schedule",
  components: {
    [MessageBox.name]: MessageBox,
    [PrDatePicker.name]: PrDatePicker,
    [PlusIcon.name]: PlusIcon,
    [EditableTag.name]: EditableTag,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
  },
  props: {
    detailedInfoSchedule: { type: Array, default: () => [] },
    isEditMode: Boolean,
    hideOld: Boolean,
    projectDateRange: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const validateDateRange = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum auswählen"));
      }
      const currentDateRange = moment.range(value);
      // find conflicting records to exclude currently editing (if editing) record from validation
      const conflictRecords = this.detailedInfoSchedule.filter((item) => {
        if (item._id && item._id === this.selectedInfo._id) {
          return false;
        }
        return moment.range(item.dateRange).overlaps(currentDateRange, { adjacent: true });
      });
      // some records overlap currently set daterange
      if (conflictRecords.length > 0) {
        return callback(new Error("Datumsbereich sollte sich nicht überschneiden"));
      }
      return callback();
    };

    return {
      visible: false,
      isEditing: false,
      isAddingNew: false,
      formRules: {
        dateRange: { required: true, validator: validateDateRange, trigger: "change" },
      },
      formData: {
        freeText: null,
        dateRange: null,
      },
      selectedInfo: {},
    };
  },
  methods: {
    addInfoSchedule() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.detailedInfoSchedule) {
          this.detailedInfoSchedule = [];
        }
        this.detailedInfoSchedule.push({
          dateRange: this.formData.dateRange.slice(),
          freeText: this.formData.freeText,
        });
        this.$refs.form.resetFields();
        this.dismiss();
      });
    },
    viewInfoSchedule(record) {
      this.initEditInfoSchedule(record);
      this.isEditing = false;
    },
    initEditInfoSchedule(record) {
      this.visible = true;
      this.isEditing = true;
      this.selectedInfo = record;
      this.formData.freeText = record.freeText;
      this.formData.dateRange = record.dateRange;
    },
    removeInfoSchedule(record) {
      MessageBox.confirm("Abfahrtszeit löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
      }).then(() => {
        let selectedInfo = record || this.selectedInfo;
        const recordIdx = this.detailedInfoSchedule.findIndex((item) => item._id === selectedInfo._id);
        if (recordIdx !== -1) {
          this.detailedInfoSchedule.splice(recordIdx, 1);
        }
        this.dismiss();
      });
    },
    formatInfoScheduleString(record) {
      const dateRangeString = this.$options.filters.formatDate(record.dateRange);
      if (record.freeText) {
        return `${dateRangeString}: ${record.freeText}`;
      } else {
        return dateRangeString;
      }
    },
    saveSelectedDeparture() {
      const recordIdx = this.detailedInfoSchedule.findIndex((item) => item._id === this.selectedInfo._id);
      if (recordIdx !== -1) {
        const changedRecord = {
          ...this.selectedInfo,
          dateRange: this.formData.dateRange,
          freeText: this.formData.freeText,
        };
        this.detailedInfoSchedule.splice(recordIdx, 1, changedRecord);

        this.formData.dateRange = null;
        this.formData.freeText = null;

        this.isEditing = false;
      }
      this.dismiss();
    },
    showaddInfoSchedule() {
      this.visible = true;
      this.isEditing = true;
      this.isAddingNew = true;
    },
    dismiss() {
      this.isEditing = false;
      this.visible = false;
      this.isAddingNew = false;
      this.selectedInfo = {};
      this.$refs.form.resetFields();
    },
  },
  computed: {
    visibleInfoSchedules() {
      if (this.hideOld) {
        const today = moment().startOf("day");
        return this.detailedInfoSchedule.filter((item) => {
          return today.isSameOrBefore(item.dateRange[1], "day");
        });
      } else {
        return this.detailedInfoSchedule;
      }
    },
  },
  filters: {
    formatDate: function (dateRange) {
      if (!dateRange || dateRange.length !== 2) {
        return "N/A";
      }
      if (moment(dateRange[0]).isSame(dateRange[1], "day")) {
        return moment(dateRange[0]).format("DD.MM.YYYY");
      }
      return dateRange.map((date) => moment(date).format("DD.MM.YYYY")).join("-");
    },
  },
};
</script>

<style></style>
