import { authHeader } from "../auth";
import axios from "axios";

export const userService = {
  login,
  logout,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  //do this to have an instance inside the promise callbacks
  let axiosRef = axios;

  return fetch(`${axios.defaults.baseURL}/api/auth/login`, requestOptions)
    .then(handleResponse)
    .then(userResponse => {
      // login successful if there's a jwt token in the response
      if (userResponse.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem("user", JSON.stringify(userResponse));
      }

      //set axios to communicate with token
      axiosRef.defaults.headers = authHeader();

      return userResponse;
    });
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("user");

  //remove token from axios header
  axios.defaults.headers = null;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
