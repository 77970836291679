<template>
  <div :class="['todo-prio', getPrioClass]">
    <bookmark-icon />
    {{ getPrioLabel }}
  </div>
</template>

<script>
import BookmarkIcon from "vue-material-design-icons/Bookmark.vue";
const prioClassMap = {
  0: "low",
  1: "med",
  2: "high",
};
const prioLabelMap = {
  0: "Low priority",
  1: "Medium priority",
  2: "High priority",
};

export default {
  name: "todo-priority",
  components: {
    [BookmarkIcon.name]: BookmarkIcon,
  },
  props: {
    priority: Number,
  },
  computed: {
    getPrioClass() {
      return prioClassMap[this.priority] || "low";
    },
    getPrioLabel() {
      return this.$t(prioLabelMap[this.priority] || "Low priority");
    },
  },
};
</script>

<style>
</style>