<template>
  <el-table :data="data" style="width: 100%" max-height="auto" :show-header="false" v-loading="loading">
    <el-table-column width="70" class-name="avatar-column">
      <template v-slot="props">
        <a style="display: inline-block; height: 50px; width: 50px; line-height: 50px" v-on:click="showAvatar(props)">
          <lazy-image
            :src="getAvatar(props)"
            fit="cover"
            style="width: 50px; height: 50px; border-radius: 2px"
            scroll-container=".match-res-scrollbar"
            lazy
          >
            <template v-slot:placeholder>
              <div class="image-slot">
                <span style="font-size: 12px"
                  >{{ $t("src.components.project.pickpack.pickpackresourcetable.lade")
                  }}<span class="dot">{{ $t("src.components.project.pickpack.pickpackresourcetable.108") }}</span>
                </span>
              </div>
            </template>

            <template v-slot:error>
              <div class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </template>
          </lazy-image>
        </a>
      </template>
    </el-table-column>
    <el-table-column prop="name" />
    <template v-slot:empty>
      <div v-if="!query || !resourceType" style="line-height: 1.4; word-break: break-word">
        {{ $t("Input name and select resource type to see matching results") }}
      </div>
      <div v-else>Keine Daten</div>
    </template>
  </el-table>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { LazyImage } from "src/components/UIComponents";
import { debounce } from "lodash";

export default {
  name: "matching-resources-table",
  components: {
    LazyImage,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Table.name]: Table,
  },
  props: {
    resourceType: String,
    query: String,
  },
  data() {
    return {
      loading: false,
      resourceTypeToUrl: {
        employee: "/api/employees/meta",
        machine: "/api/machines/meta",
        rhb: "/api/rhb/meta",
        vehicle: "/api/vehicles/meta",
        supply: "/api/supply/meta",
      },
      data: [],
    };
  },
  created() {
    this.debounceFetch = debounce(this.fetchMatches, 300);
  },
  methods: {
    async fetchMatches() {
      console.log("fetchMatches", this.query, this.resourceType);
      try {
        this.loading = true;
        const url = this.resourceTypeToUrl[this.resourceType];
        if (!url) {
          return;
        }
        if (!this.query || !this.resourceType) {
          this.data = [];
          return;
        }
        const response = await this.axios.get(url);
        let allResources = response.data.slice();
        if (this.resourceType === "employee") {
          allResources = allResources.map((i) => ({
            ...i,
            name: `${i.firstName} ${i.lastName}`,
          }));
        }
        const query = this.query.toLowerCase();
        this.data = allResources.filter((i) => i.name.toLowerCase().indexOf(query) !== -1);
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async showAvatar(props) {
      if (props.row.picture) {
        // const blobResponse = await this.axios.get(props.row.picture.url, {
        //   responseType: "blob",
        // });
        // const objectUrl = URL.createObjectURL(blobResponse.data);
        // this.dialogImage = props.row.picture.url;
        // this.dialogVisible = true;
      }
    },
    getAvatar(props) {
      if (props.row.picture) {
        return this.axios.defaults.baseURL + props.row.picture.thumbnail;
      } else {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      }
    },
  },
  watch: {
    query(newVal, oldVal) {
      if (!newVal) {
        this.data = [];
      } else if (newVal !== oldVal) {
        this.debounceFetch();
      }
    },
    resourceType(newVal, oldVal) {
      if (!newVal) {
        this.data = [];
      } else if (newVal !== oldVal) {
        this.debounceFetch();
      }
    },
  },
  computed: {
    emptyText() {},
  },
};
</script>
