<template>
  <el-popover placement="top" v-model="visible" width="200">
    <p class="mb-1">{{ $t("Search by:") }}</p>
    <div class="d-flex justify-content-center">
      <el-radio-group :value="localValue" @input="handleChange">
        <el-radio label="text">{{ $t("Text") }}</el-radio>
        <el-radio label="author">{{ $t("Author") }}</el-radio>
      </el-radio-group>
    </div>
    <template v-slot:reference>
      <div style="cursor: pointer" class="p-1 mr-1">
        <tune-icon />
      </div>
    </template>
  </el-popover>
</template>

<script>
import { Popover, RadioGroup, Radio } from "element-ui";
import Tune from "vue-material-design-icons/Tune.vue";

export default {
  name: "search-mode-select",
  components: {
    [Tune.name]: Tune,
    [Popover.name]: Popover,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  props: {
    value: String,
  },
  data() {
    return {
      visible: false,
      localValue: "",
    };
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    handleChange(val) {
      this.localValue = val;
      this.visible = false;
      this.$emit("input", val);
    },
  },
};
</script>

<style></style>
