<template>
  <el-button @click="handleClick" v-bind="$attrs" type="default">
    <template v-slot:label>
      <i class="el-icon-close"></i>
    </template>
    <!-- TODO: IS this {{ text }} correct here -->
    {{ text }}
  </el-button>
</template>

<script>
export default {
  name: "cancel-button",
  props: {
    text: String,
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style></style>
