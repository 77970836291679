<template>
  <el-dialog :title="modalTitle" width="800px" :visible="visible" :before-close="hideModal" destroy-on-close>
    <el-row :gutter="20" type="flex" align="bottom" style="text-align: center" v-if="wasPrepared">
      <el-col :span="12">
        <p>{{ $t("src.components.project.bryntumscheduler.montagsplanung.tagesVorlage") }}</p>
        <p>
          <b>{{ formData.cloneFromDate | moment }}</b>
        </p>
      </el-col>
      <el-col :span="12">
        <p>{{ $t("src.components.project.bryntumscheduler.montagsplanung.zeitraum") }}</p>
        <p>
          <b>{{ formData.dateRange[0] | moment }} - {{ formData.dateRange[1] | moment }}</b>
        </p>
      </el-col>
    </el-row>
    <el-form
      :model="formData"
      ref="form"
      :rules="formDataRules"
      hide-required-asterisk
      label-position="top"
      style="text-align: center"
      v-else
    >
      <el-row :gutter="20">
        <el-col :span="9">
          <el-form-item
            :label="$t('src.components.project.bryntumscheduler.montagsplanung.tagesVorlage')"
            prop="cloneFromDate"
          >
            <pr-date-picker
              style="width: 100%"
              v-model="formData.cloneFromDate"
              :placeholder="$t('src.components.project.bryntumscheduler.montagsplanung.tagesVorlage')"
            />
            <!-- :max-date="formData.dateRange && new Date(formData.dateRange[0])" -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" align="bottom">
        <el-col :span="9">
          <el-form-item :label="$t('Project')" prop="projectId">
            <profile-select
              style="width: 100%"
              v-model="formData.projectId"
              :items="availableProjectsSelection"
              editMode
              name="Projekt"
              label="text"
              :multiple="false"
              :clearable="false"
              filterable
              no-label
              valueIdentifier="value"
            />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('src.components.project.bryntumscheduler.montagsplanung.zeitraum')" prop="dateRange">
            <pr-date-picker
              style="width: 100%"
              v-model="formData.dateRange"
              :min-date="project && project.dateRange[0]"
              :max-date="project && project.dateRange[1]"
              is-range
            />
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item>
            <el-button type="primary" style="width: 100%" @click="submitForm" :loading="loading">{{
              $t("src.components.project.bryntumscheduler.montagsplanung.planen")
            }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-show="wasPrepared">
      <div class="mondayplan">
        <p>{{ $t("src.components.project.bryntumscheduler.montagsplanung.whleRessourceDrcke") }}</p>
        <el-checkbox-group v-model="selectedCompanyRoles" v-if="formData.resourceType === 'employee'">
          <el-checkbox-button v-for="role in companyRoles" :label="role" :key="role">{{ role }}</el-checkbox-button>
        </el-checkbox-group>
        <el-transfer
          filterable
          :filter-method="filterMethod"
          v-model="selectedResources"
          :data="visibleResources"
          :titles="['Alle', 'Ausgewählt']"
        >
          <span slot-scope="{ option }">
            <el-tooltip effect="dark" :content="option.label" placement="top-start">
              <span>{{ option.label }}</span>
            </el-tooltip>
          </span>
        </el-transfer>
      </div>
      <div v-if="unavailableResources.length" class="mb-2">
        <p class="profile-title text-center">Not allocatable resources</p>
        <el-button @click="downloadPdfReport" :loading="pdfLoading">{{ $t("downloadReport") }}</el-button>
        <el-table :data="unavailableResources" style="width: 100%" max-height="auto">
          <el-table-column width="200" :label="$t('Name')" prop="label"> </el-table-column>
          <el-table-column width="*" :label="$t('Date ranges')" prop="label">
            <template v-slot="props">
              <div v-for="(item, idx) in props.row.conflicts" :key="idx">
                {{ formatConflictString(item) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-button @click="stepBack">{{
            $t("src.components.project.bryntumscheduler.montagsplanung.zurck")
          }}</el-button>
        </el-col>
        <el-col style="text-align: right">
          <el-button type="primary" @click="createEvents" :loading="loading">{{
            $t("src.components.project.bryntumscheduler.montagsplanung.planungAbschlieen")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import {
  Dialog,
  DatePicker,
  Message,
  Transfer,
  Button,
  Form,
  FormItem,
  Row,
  Col,
  Tooltip,
  CheckboxGroup,
  CheckboxButton,
  Table,
  TableColumn,
} from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import { moment } from "src/config/moment";
import { saveAs } from "file-saver";
import { get } from "lodash";

export default {
  name: "Montagsplanung",
  props: {
    currentMonday: {
      type: Date,
      required: true,
    },
    getProjectById: {
      type: Function,
      required: true,
    },
    projectsSelection: { type: Array, default: () => [] },
    createProjectEvents: {
      type: Function,
      required: true,
    },
    getResourcesByResourceType: {
      type: Function,
      required: true,
    },
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    [DatePicker.name]: DatePicker,
    [Message.name]: Message,
    [Transfer.name]: Transfer,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Tooltip.name]: Tooltip,
  },
  data: function () {
    return {
      pdfLoading: false,
      resourceType: "",
      project: null,
      currentProjectId: null,
      selectedResources: [],
      companyRoles: ["Bauleitung", "Gewerblich", "Verwaltung"],
      selectedCompanyRoles: ["Gewerblich"],
      allResources: [],
      unavailableResources: [],
      formData: {
        resourceType: "",
        cloneFromDate: "",
        projectId: "",
        dateRange: [],
      },
      formDataRules: {
        cloneFromDate: [
          {
            required: true,
            // message: "Dieses Feld wird benötigt",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("Dieses Feld wird benötigt"));
              }
              if (this.formData.dateRange[0] && value > this.formData.dateRange[0]) {
                return callback(new Error("Datum muss vor dem Zeitraum sein"));
              }
              callback();
            },
          },
        ],
        dateRange: [
          {
            required: true,
            // message: "Dieses Feld wird benötigt",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!value || !value.length) {
                return callback(new Error("Dieses Feld wird benötigt"));
              }
              if (value[0] && this.formData.cloneFromDate > value[0]) {
                return callback(new Error("Datum muss nach dem zu klonenden Tag sein"));
              }
              callback();
            },
          },
        ],
        projectId: [{ required: true, message: "Dieses Feld wird benötigt" }],
      },
      wasPrepared: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("montagsplanung", ["visible", "resource"]),
    ...mapState("granularitySettings", { granularity: "data" }),
    visibleResources() {
      if (this.formData.resourceType === "employee") {
        return this.allResources.filter((item) => this.selectedCompanyRoles.includes(item.companyRole));
      } else {
        return this.allResources;
      }
    },
    modalTitle() {
      return "Planungsvorlage";
    },
    availableProjectsSelection() {
      return this.projectsSelection.filter(
        ({ dateRange }) =>
          this.formData.cloneFromDate &&
          moment(this.formData.cloneFromDate).within(moment.range(dateRange).snapTo("day"))
      );
    },
  },
  watch: {
    visible: function (isVisible) {
      if (isVisible) {
        this.setup();
      } else {
        this.tearDown();
      }
    },
    "formData.projectId": function (newVal, oldVal) {
      if (oldVal && newVal && newVal !== oldVal) {
        this.project = { ...this.getProjectById(newVal) };
        this.setDefaultDates();
      }
    },
    "formData.cloneFromDate": function (newVal, oldVal) {
      if (newVal) {
        const date = moment(newVal);
        const newAvailableProjects = this.projectsSelection.filter(({ dateRange }) =>
          date.within(moment.range(dateRange).snapTo("day"))
        );

        // clear project selection if already selected project is not available on new date
        if (newAvailableProjects.findIndex(({ value: id }) => this.formData.projectId === id) === -1) {
          this.formData.projectId = "";
        }
      }
    },
  },
  methods: {
    ...mapActions("montagsplanung", { hideModal: "hide" }),
    setup() {
      // ID String "{projectId}_{resourceType}" is received from /projects/calendar request at "children" array
      const [projectId, resourceType] = this.resource.id.split("_");
      this.formData.projectId = projectId;
      const project = this.getProjectById(projectId);
      if (!project) {
        throw new Error("Project not found: " + projectId);
      }
      this.currentProjectId = projectId;
      this.project = { ...project };
      this.formData.resourceType = resourceType;
      this.setDefaultDates();
    },
    tearDown() {
      this.resourceType = "";
      this.project = null;
      this.currentProjectId = null;
      this.selectedResources = [];
      this.allResources = [];
      this.unavailableResources = [];
      this.wasPrepared = false;
      this.formData = {
        resourceType: "",
        cloneFromDate: "",
        projectId: "",
        dateRange: [],
      };
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    setDefaultDates() {
      const projectRange = moment.range(this.project.dateRange).snapTo("day");
      const calendarDate = this.currentMonday;
      const currentWeekMonday = moment(calendarDate).isoWeekday(1);
      const currentWeekFriday = moment(calendarDate).isoWeekday(5);

      let defaultCloneFromDate = currentWeekFriday.clone().subtract(7, "days");
      // check if selected date is within project range
      if (!defaultCloneFromDate.within(projectRange)) {
        // if selected date is after project range -> pick latest project range
        if (defaultCloneFromDate.isAfter(this.project.dateRange[1], "day")) {
          defaultCloneFromDate = moment(this.project.dateRange[1]);
          // if selected date is before project started -> pick project start date
        } else {
          defaultCloneFromDate = moment(this.project.dateRange[0]);
        }
      }
      let start, end;
      // if monday of current week is within project range
      if (currentWeekMonday.within(projectRange)) {
        start = currentWeekMonday;
      } else {
        const currentWeekRange = moment.range([currentWeekMonday, currentWeekFriday]);
        // if project starts on this week but not on monday
        if (currentWeekRange.overlaps(projectRange)) {
          start = moment(projectRange.start);
        } else {
          // exit. can not set default date range for project that is not within current week
          return;
        }
      }

      if (["employee", "vehicle"].includes(this.formData.resourceType) && currentWeekFriday.within(projectRange)) {
        end = currentWeekFriday;
      } else {
        end = moment(projectRange.end);
      }
      this.formData.cloneFromDate = defaultCloneFromDate.toISOString();
      this.formData.dateRange = [start, end];
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.fetchResources();
        } else {
          return false;
        }
      });
    },
    stepBack() {
      this.wasPrepared = false;
    },
    async createEvents() {
      try {
        this.loading = true;
        const allResources = this.getResourcesByResourceType(this.formData.resourceType);
        const resourceData = this.selectedResources.map((resourceId) =>
          allResources.find((item) => item.id === resourceId)
        );
        const eventPostObjects = resourceData.map((resource) => ({
          title: resource.displayName,
          resourceType: this.formData.resourceType,
          start: moment(this.formData.dateRange[0]).format("YYYY-MM-DD"),
          end: moment(this.formData.dateRange[1]).format("YYYY-MM-DD"),
          resourceId: resource.id,
          projectId: this.currentProjectId,
        }));

        await this.createProjectEvents(eventPostObjects);
        Message({
          type: "success",
          message: "Projektzuweisung Erfolgreich",
        });
        this.hideModal();
      } catch (error) {
        // Message({ type: "error", message: error.message });
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchResources() {
      try {
        this.loading = true;

        const { dateRange, ...restFormData } = this.formData;
        const formBody = {
          ...restFormData,
          start: dateRange[0],
          end: dateRange[1],
        };

        const response = await this.axios.post("/api/project-events/planning-resources", formBody, {
          params: { mode: this.granularity[formBody.resourceType] },
        });

        const { allResources, unavailableResources } = response.data.allResources.reduce(
          (buffer, item) => {
            // #2637
            // disabled resources that are not related to referred project should be just skipped
            if (item.disabled) {
              // disabled resource that are related to the referred project should be shown
              if (item.isTakenFromClonedDay) {
                buffer.unavailableResources.push(item);
              }
            } else {
              buffer.allResources.push(item);
            }
            return buffer;
          },
          { allResources: [], unavailableResources: [] }
        );
        this.allResources = allResources;
        this.unavailableResources = unavailableResources;
        this.selectedResources = response.data.preselectedResources;
        this.wasPrepared = true;
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    formatConflictString(record) {
      return `${moment(record.start).format("L")}-${moment(record.end).format("L")}`;
    },
    async downloadPdfReport(e) {
      e.preventDefault();
      try {
        this.pdfLoading = true;
        // format payload
        const payload = {
          unavailableResources: this.unavailableResources.map((record) => ({
            resourceId: record.key,
            name: record.label,
            conflicts: record.conflicts.map(this.formatConflictString),
          })),
        };
        // send request
        const response = await this.axios.request({
          method: "POST",
          url: "/api/project-events/get-unavailable-resources-report",
          data: payload,
          responseType: "blob",
        });
        const file = new File([response.data], "report.pdf", { type: "application/octet-stream" });
        saveAs(file, file.name);
      } catch (error) {
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      } finally {
        this.pdfLoading = false;
      }
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style>
.mondayplan {
  text-align: center;
  margin-bottom: 20px;
}
.mondayplan .el-transfer {
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.mondayplan .el-transfer-panel {
  flex-grow: 1;
}
</style>
