<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{ $t("src.components.vehicle.index.kfz") }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.vehicle.index.kfzListe") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="21">
        <div class="n-view-title">{{ $t("src.components.vehicle.index.kfzListe") }}</div>
      </el-col>

      <el-col :span="3" class="text-right">
        <new-listitem-button v-if="$can('create', 'vehicle')" to="/vehicle/profile/new">{{
          $t("src.components.vehicle.index.neuesKfz")
        }}</new-listitem-button>
      </el-col>
    </el-row>

    <!-- PREVIEW PICTURE -->
    <div class="front" v-show="previewPicture">
      <lazy-image v-if="previewPictureSrc" :src="previewPictureSrc" fit="contain" style="max-height: 100%" />
    </div>
    <!-- DIALOG WITH PICTURE -->
    <el-dialog width="30%" :visible.sync="dialogVisible" center>
      <div style="text-align: center; height: 70vh; overflow: hidden">
        <lazy-image :src="dialogImage" key="cover" height="100%" wrapperStyle="height: 100%;" />
      </div>
    </el-dialog>

    <index-table
      authSubject="vehicle"
      :propsToSearch="propsToSearch"
      modelType="vehicle"
      :extendedColumns="extendedColumns"
      :defaultSort="{ prop: 'name', order: 'ascending' }"
    >
      <template v-slot:additional>
        <el-button v-on:click="openNavlink" class="success" plain style="margin-right: 50%">
          <span class="nc-icon nc-pin-3" />{{ $t("src.components.vehicle.index.navbasic") }}</el-button
        >
      </template>

      <!-- NAME and PICTURE COLUMN -->
      <template v-slot:Name="props">
        <el-row type="flex" align="middle">
          <div class="mr-10" style="flex-shrink: 0">
            <a
              style="display: inline-block; height: 50px; width: 50px; line-height: 50px"
              v-on:mouseover="showPreviewPicture(props)"
              v-on:mouseleave="hidePreviewPicture()"
              v-on:click="showPictures(props)"
            >
              <lazy-image
                :src="getThumbnail(props)"
                fit="cover"
                style="width: 50px; height: 50px; border-radius: 2px"
                lazy
                :scroll-container="getScrollContainer()"
              >
                <template v-slot:placeholder>
                  <div class="image-slot">
                    <span style="font-size: 12px"
                      >{{ $t("src.components.vehicle.index.lade")
                      }}<span class="dot">{{ $t("src.components.vehicle.index.742") }}</span>
                    </span>
                  </div>
                </template>
                <template v-slot:error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </lazy-image>
            </a>
          </div>

          <div style="flex-grow: 1">
            <router-link v-if="$can('read', 'vehicle')" :to="`/vehicle/profile/view/${props.data.row.id}`">
              {{ props.data.row.name }}
            </router-link>
            <span v-else>{{ props.data.row.name }}</span>
            <el-badge
              :type="props.data.row.active ? 'success' : 'danger'"
              style="padding-top: 9px; padding-left: 6px"
              is-dot
            />
          </div>
        </el-row>
      </template>

      <template v-slot:Team="props">{{ props.data.row.team }}</template>
      <template v-slot:Anzahl-Sitze="props">{{
        props.data.row.numberSeats == null ? "—" : props.data.row.numberSeats
      }}</template>
      <template v-slot:Kennzeichen="props">{{
        props.data.row.registration == null ? "—" : props.data.row.registration
      }}</template>
    </index-table>
  </div>
</template>

<script>
import { IndexTable, NewListItemButton, LazyImage } from "src/components/UIComponents";

export default {
  data() {
    return {
      previewPicture: false,
      dialogImage: "",
      dialogVisible: false,
      previewPictureSrc: "",
      extendedColumns: [
        {
          label: "Name",
          key: "name",
          minWidth: 220,
        },
        {
          label: "Team",
          key: "team",
          minWidth: 150,
        },
        {
          label: "Anzahl-Sitze",
          key: "numberSeats",
          minWidth: 170,
        },
        {
          label: "Kennzeichen",
          key: "registration",
          minWidth: 170,
        },
      ],
      units: [],
      propsToSearch: ["name", "team", "registration", "vehicleNumber", "statusReference.0.statusType.label"],
    };
  },
  components: {
    [LazyImage.name]: LazyImage,
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
  },
  methods: {
    getScrollContainer() {
      return document.querySelector(".table-wrapper .el-table__body-wrapper");
    },
    openNavlink() {
      window.open("https://login.navkonzept.com/", "_blank");
    },
    getThumbnail(props) {
      if (!props.data.row.picture) {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      } else {
        return this.axios.defaults.baseURL + props.data.row.picture.thumbnail;
      }
    },
    showPreviewPicture(props) {
      if (props.data.row.picture) {
        this.previewPictureSrc = encodeURI(this.axios.defaults.baseURL + props.data.row.picture.url);
        this.previewPicture = true;
      }
    },
    hidePreviewPicture() {
      this.previewPictureSrc = "";
      this.previewPicture = false;
    },
    showPictures(props) {
      this.hidePreviewPicture();
      if (props.data.row.picture) {
        this.dialogImage = this.axios.defaults.baseURL + props.data.row.picture.url;
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="scss"></style>
