<template>
  <div class="widget-tab">
    <div class="widget-tab-header">
      <div class="widget-tab-header-controls">
        <div class="widget-tab-header-title">{{ title }}</div>
        <div style="display: flex">
          <div v-if="hasSorter" class="mr-2">
            <span class="n-profile-label-title"> {{ $t("src.components.project.projectconfiguration.sort") }}: </span>
            <br />
            <profile-radio-group light v-model="sorterValue" :items="sorterOptions" />
          </div>
          <div>
            <slot name="filters"></slot>
          </div>
          <!-- <div v-if="hasFilters">
            <cancel-button @click="toggleFilters" :active="filtersVisible">
              <filter-icon />
              {{ $t("src.components.project.projectconfiguration.filters") }}
            </cancel-button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="widget-tab-body" :style="bodyOverflow ? { overflow: bodyOverflow } : undefined"><slot></slot></div>
  </div>
</template>

<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import FilterIcon from "vue-material-design-icons/Filter.vue";

export default {
  name: "widget-tab",
  components: {
    FilterIcon,
    [CollapseTransition.name]: CollapseTransition,
  },
  props: {
    title: String,
    hasFilters: Boolean,
    hasSorter: Boolean,
    bodyOverflow: String,
  },
  data() {
    return {
      sorterValue: "ASC",
      sorterOptions: [
        { value: "ASC", label: "Az" },
        { value: "DESC", label: "Za" },
      ],
      filtersVisible: false,
    };
  },
  methods: {
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible;
    },
  },
  watch: {
    sorterValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("sortOrderChange", newVal);
      }
    },
  },
};
</script>

<style>
.widget-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget-tab-header {
  padding: 8px 8px 8px 20px;
  border-bottom: 1px solid #edf0f7;
}
.widget-tab-header-controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.widget-tab-header-title {
  font-size: 24px;
  line-height: 32px;
  margin-right: 20px;
}
.widget-tab-body {
  flex-grow: 1;
  overflow: auto;
}
.widget-tab-header .el-form-item {
  margin-bottom: 0;
}
.widget-tab-header .el-form-item__content {
  line-height: 21px;
}
</style>
