<template>
  <div class="navbar-wrapper" v-if="notInDetachedCalendarMode">
    <div style="display: flex; align-items: center">
      <a href="#" class="navbar-wrapper-title" @click.prevent="handleWelcomePage">
        <div>{{ $t("src.components.uicomponents.sidebarplugin.sidebar.schwegler") }}</div>
      </a>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-topbar"
        text-color="#ACC3FF"
        background-color="#224CB8"
        active-text-color="#FFFFFF"
        mode="horizontal"
        @select="handleSelect"
        ref="sidebar"
        v-if="!isKioskMode"
      >
        <template v-for="(item, idx) in getSidebarLinks">
          <el-submenu
            v-if="item.children"
            :key="idx"
            :index="item.path || idx.toString()"
            :class="{
              'is-active': isActiveClass(item.matchRegex),
            }"
          >
            <template v-slot:title>
              <span
                :class="{
                  'active-link': isActiveClass(item.matchRegex),
                  'with-dot': item.hasDot,
                }"
                >{{ item.name }}</span
              >
            </template>
            <el-menu-item v-for="(child, childIdx) in item.children" :key="`${idx}-${childIdx}`" :index="child.path">{{
              child.name
            }}</el-menu-item>
          </el-submenu>
          <el-menu-item v-else :key="idx" :index="item.path || idx.toString()">
            <span :class="{ 'active-link': isActiveClass(item.matchRegex), 'with-dot': item.hasDot }">{{
              item.name
            }}</span>
          </el-menu-item>
        </template>
      </el-menu>
      <div class="navbar-bar"></div>
    </div>
    <div class="d-flex align-items-center">
      <header-notifications v-if="!isKioskMode" />
      <div class="navbar-wrapper-profile" v-if="!isKioskMode">
        <el-dropdown @command="handleUserMenuCommand">
          <div class="profile-menu-btn">
            <div style="width: 30px; height: 30px" class="d-flex align-items-center">
              <lazy-image width="30" height="30" fit="fit" :src="profileImagePath" />
            </div>
            <chevron-down-icon />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item disabled>{{ getDisplayName }}</el-dropdown-item>
            <el-dropdown-item command="logout">{{
              $t("src.components.uicomponents.sidebarplugin.sidebar.logout")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import ChevronDown from "vue-material-design-icons/ChevronDown";
import { remove } from "lodash";
import { Menu, MenuItem, Submenu, Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import { mapState, mapGetters } from "vuex";
import { LazyImage } from "src/components/UIComponents";
import HeaderNotifications from "./HeaderNotifications.vue";

export default {
  components: {
    LazyImage,
    CollapseTransition,
    HeaderNotifications,
    [ChevronDown.name]: ChevronDown,
    [MenuItem.name]: MenuItem,
    [Submenu.name]: Submenu,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Menu.name]: Menu,
  },
  props: {
    title: {
      type: String,
      default: "schwegler",
      description: "Sidebar title",
    },
    backgroundColor: {
      type: String,
      default: "white",
      validator: (value) => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (white|brown|black)",
    },
    activeColor: {
      type: String,
      default: "danger",
      validator: (value) => {
        let acceptedValues = ["primary", "info", "success", "warning", "danger"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar active text color (primary|info|success|warning|danger)",
    },
    logo: {
      type: String,
      default: "static/img/vue-logo.png",
      description: "Sidebar Logo",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: "Sidebar links. Can be also provided as children components (sidebar-item)",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      isClosed: true,
      activeIndex: this.$route.path,
      profileName: "Benutzer",
      profileImagePath: "/static/img/faces/avatar-placeholder.jpg",
      // user: JSON.parse(sessionStorage.getItem("user")), //lazy load
    };
  },
  mounted() {
    this.fetchProfileImage();
    setTimeout(() => {
      this.initActiveNavbarItem();
    }, 300);
  },
  computed: {
    ...mapState("account", ["user", "isKioskMode"]),
    ...mapGetters("notifications", ["hasProjectNotifications"]),
    notInDetachedCalendarMode() {
      return this.$route.query.detachMode !== "true";
    },
    getDisplayName() {
      if (this.user) {
        return this.user.name;
      }
    },
    getSidebarLinks() {
      const filteredSideBar = [];
      console.log("hasProjectNotifications", this.hasProjectNotifications);
      // workaround when F5 is hit -> no more abilities are available anymore
      if (!this.$keycloak.authenticated) {
        this.$router.push("/login");
      } else {
        this.sidebarLinks.forEach((sidebarLink) => {
          // test if has permission is set
          if (
            (sidebarLink.children && sidebarLink.children.some((child) => this.$can("read", child.auth))) ||
            this.$can("read", sidebarLink.auth)
          ) {
            if (sidebarLink.auth === "project") {
              sidebarLink.hasDot = this.hasProjectNotifications;
            }
            filteredSideBar.push(sidebarLink);
            // when no permission is set remove child
            remove(sidebarLink.children, (submenu) => {
              return !this.$can("read", submenu.auth);
            });
          }
        });
      }
      return filteredSideBar;
    },
  },
  methods: {
    async fetchProfileImage() {
      const response = await this.axios.get("/api/employees/avatar");
      if (response.data) {
        this.profileImagePath = `${this.axios.defaults.baseURL}${response.data}`;
      } else {
        this.profileImagePath = `${window.location.origin}/static/img/faces/avatar-placeholder.jpg`;
      }
    },
    handleUserMenuCommand(command) {
      const redirectUri = window.location.origin;
      switch (command) {
        case "logout":
          this.$keycloak.logout({ redirectUri });
          break;
        default:
          break;
      }
    },
    handleSelect(key, keyPath) {
      this.$router.push(key);
      console.log("key", key);
    },
    isActiveClass(matchRegexString) {
      if (matchRegexString) {
        const reg = new RegExp(matchRegexString, "g");
        const path = this.$route.path;
        const result = reg.test(path);
        return result;
      } else {
        return false;
      }
    },
    isWithDot(auth) {
      if (auth === "project") {
        return this.hasProjectNotifications;
      } else {
        return false;
      }
    },
    initActiveNavbarItem() {
      const activeSpan = document.querySelector(".el-menu-topbar .active-link");
      const activeLi = activeSpan ? activeSpan.parentNode : document.querySelector(".el-menu-topbar li.is-active");
      if (activeLi) {
        const { width, x } = activeLi.getBoundingClientRect();
        const bar = document.querySelector(".navbar-bar");
        bar.style.width = `${activeLi.offsetWidth}px`;
        bar.style.transform = `translate(${x}px, 0)`;
      }
    },
    handleWelcomePage() {
      if (this.isKioskMode) {
        return;
      } else {
        this.$router.push("/welcome");
      }
    },
  },
  watch: {
    "$route.path": {
      handler: function (newPath, oldPath) {
        this.activeIndex = newPath;
        setTimeout(() => {
          this.initActiveNavbarItem();
        }, 300);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.el-menu-topbar {
  display: inline-flex;
  flex-wrap: nowrap;
}
.el-menu-topbar .el-menu-item,
.el-menu-topbar .el-submenu__title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #acc3ff;
}
.navbar-wrapper .el-submenu__title,
.navbar-wrapper .el-menu-item {
  border: none !important;
}
.el-menu-topbar.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  position: absolute;
  right: 5px;
  top: 27px;
  color: #acc3ff;
}
.el-menu--horizontal > .el-menu-item.is-active,
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
}
.el-menu__active-bar {
  width: 24px;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @if $HEADER_COLOR {
    background-color: $HEADER_COLOR;
  } @else {
    background-color: #224cb8;
  }
  width: 100%;
  height: 64px;
  padding: 0 100px;
}
.navbar-wrapper-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-right: 80px;
  padding: 10px 20px;
  display: inline-block;
  border: 1px solid #fff;
  color: #fff;
  &:hover,
  &:focus,
  &:hover:focus {
    text-decoration: none;
    color: #fff;
  }
}
.navbar-wrapper-profile {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #acc3ff;

  border-radius: 50%;
}

.active-link {
  display: block;
  color: #fff;
  // box-shadow: inset 0 -4px #fff;
}
.with-dot {
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    position: relative;
    z-index: 10;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: #46a19c;
    top: -7px;
    right: -2px;
  }
}
.navbar-wrapper {
  position: relative;
}
.navbar-bar {
  position: absolute;
  bottom: 2px;
  left: 0;
  background: #fff;
  height: 4px;
  transition: all 0.3s ease;
  transform: translate(291px, 0);
}
</style>
