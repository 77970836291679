<template>
  <div v-loading="loading">
    <el-row>
      <el-col :span="18">
        <div class="n-profile-title">{{ $t("src.components.project.dailyreport.dailyreport.tagesberichte") }}</div>
      </el-col>
      <!-- NEW STATUS BUTTON -->
      <template v-if="isEditMode">
        <el-col :span="6" class="text-right" style="padding-right: 20px">
          <el-button type="primary" @click="addNewReport" v-if="accessRights !== 'readonly'">
            <plus-icon />{{ $t("src.components.project.dailyreport.dailyreport.tagesberichtHinzufgen") }}</el-button
          >
        </el-col>
      </template>
    </el-row>

    <div class="card-body">
      <el-row>
        <el-col>
          <el-table :data="reports" ref="reporttable" :default-sort="{ prop: 'dateRange', order: 'ascending' }">
            <!-- INDEX -->
            <el-table-column type="index" sortable width="60">
              <template v-slot:header>
                <span class="n-table-header">#</span>
              </template>
            </el-table-column>

            <!-- DURATION -->
            <el-table-column
              sortable
              :sort-method="sortDateRange"
              :formatter="formatDateRange"
              width="200"
              prop="dateRange"
            >
              <!-- HEADER -->
              <template v-slot:header>
                <span class="n-table-header">{{ $t("src.components.project.dailyreport.dailyreport.zeitraum") }}</span>
              </template>
            </el-table-column>

            <!-- FILE list -->
            <el-table-column max-width="150" sortable prop="document">
              <template v-slot:header>
                <span class="n-table-header">{{ $t("src.components.project.dailyreport.dailyreport.datei") }}</span>
              </template>
              <template v-slot="scope">
                <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                  {{ getFilenameForDocument(scope.row) }}
                </div>
              </template>
            </el-table-column>

            <!-- NOTEs -->
            <el-table-column max-width="200">
              <template v-slot:header>
                <span class="n-table-header">{{ $t("src.components.project.dailyreport.dailyreport.notizen") }}</span>
              </template>
              <template v-slot="scope">
                <span style="display: inline-block; margin-right: 5px">{{ getFreeText(scope.row) }}</span>
              </template>
            </el-table-column>

            <!-- ACIONS -->
            <el-table-column fixed="right" width="170">
              <template v-slot="scope">
                <el-button class="btn btn-sm btn-ghost" @click="viewDocument(scope.row)">
                  <eye-icon />
                </el-button>
                <el-button class="btn btn-sm btn-ghost" @click="downloadDocument(scope.row)">
                  <download-icon />
                </el-button>
                <span v-if="isEditMode">
                  <!-- EDIT BUTTON -->
                  <el-button
                    class="btn btn-sm btn-ghost"
                    v-if="accessRights !== 'readonly'"
                    @click="editReport(scope.row, scope.$index)"
                  >
                    <pencil-icon />
                  </el-button>
                  <!-- DELETE BUTTON -->
                  <el-button
                    v-if="accessRights === 'full'"
                    class="btn btn-sm btn-ghost"
                    @click="deleteReport(scope.row)"
                  >
                    <trash-can-outline-icon />
                  </el-button>
                </span>
              </template>
            </el-table-column>

            <!-- SLOT EMPTY -->
            <template v-slot:empty>{{
              $t("src.components.project.dailyreport.dailyreport.keineTagesberichteVorhanden")
            }}</template>
          </el-table>
        </el-col>
      </el-row>
    </div>

    <!-- ** DIALOG ** -->
    <el-dialog width="50%" :visible.sync="newReportVisible" @close="closing">
      <ValidationObserver ref="validateObserver">
        <!-- HEADER -->
        <div style="padding-left: 20px">
          <span class="n-profile-title-font">{{
            $t("src.components.project.dailyreport.dailyreport.tagesberichteBearbeiten")
          }}</span>
        </div>
        <div class="card-body">
          <el-row>
            <el-col>
              <el-switch
                v-model="isSingleDate"
                :active-text="$t('src.components.project.dailyreport.dailyreport.einzelnesDatum')"
                :inactive-text="$t('src.components.project.dailyreport.dailyreport.zeitraum')"
                active-color="#3c4b61"
                inactive-color="#3c4b61"
                @change="switchChanged"
              >
              </el-switch>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="10">
              <profile-date-picker
                v-if="isSingleDate"
                :projectDateRange="projectRange"
                v-model="editModel.date"
                :title="$t('src.components.project.dailyreport.dailyreport.datum')"
                :isEditMode="true"
                :name="$t('src.components.project.dailyreport.dailyreport.datum')"
                rules="required"
                required
              />
              <profile-date-picker
                v-else
                :projectDateRange="projectRange"
                v-model="editModel.dateRange"
                :title="$t('src.components.project.dailyreport.dailyreport.zeitraum')"
                :isEditMode="true"
                is-range
                :name="$t('src.components.project.dailyreport.dailyreport.zeitraum')"
                rules="required"
                required
              />
            </el-col>

            <el-col :span="14">
              <profile-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                v-model="editModel.freeText"
                :label="$t('src.components.project.dailyreport.dailyreport.notizen')"
                :editMode="true"
              />
            </el-col>
          </el-row>

          <!-- FILE UPLOAD -->
          <el-row style="margin-top: 20px">
            <el-col :span="24">
              <span class="n-profile-label-title">{{
                $t("src.components.project.dailyreport.dailyreport.statusAnhang")
              }}</span>
              <br />
              <el-upload
                :headers="authHeader"
                style="display: inline-block"
                action=""
                :file-list="fileList"
                :multiple="false"
                :limit="1"
                :on-remove="fileRemoved"
                :on-error="handleUploadError"
                :http-request="fileUploaded"
              >
                <el-button size="small" icon="el-icon-document-add">{{
                  $t("src.components.project.dailyreport.dailyreport.dateiAuswhlen")
                }}</el-button>
              </el-upload>
            </el-col>
          </el-row>
        </div>

        <!-- FOOTER BUTTONS -->
        <el-row v-if="isEditMode">
          <div class="n-drawer-footer">
            <cancel-button @click="closeReportDialog" />
            <save-button @click="saveDialog" />
          </div>
        </el-row>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import { Upload, Message, Switch } from "element-ui";
import { moment } from "src/config/moment";
import { mapGetters, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import DownloadIcon from "vue-material-design-icons/Download";
import EyeIcon from "vue-material-design-icons/Eye";

export default {
  name: "daily-report",
  props: {
    isEditMode: { type: Boolean, required: true },
    accessRights: { type: String, required: true },
    projectId: { type: String, required: true },
    projectRange: { type: Array },
  },
  components: {
    ValidationObserver,
    [DownloadIcon.name]: DownloadIcon,
    [EyeIcon.name]: EyeIcon,
    [Upload.name]: Upload,
    [Message.name]: Message,
    [Switch.name]: Switch,
  },
  data: function () {
    return {
      newReportVisible: false,
      loading: false,
      closedProperly: false,
      isSingleDate: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("dailyReport/initAsync", { projectId: this.projectId });
    } catch (error) {
      Message({
        message: error.message,
        type: "error",
      });
    } finally {
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("account", ["authHeader"]), //for file upload
    editModel: {
      get: function () {
        return this.$store.getters["dailyReport/editModel"];
      },
      set: function (newValue) {
        this.$store.commit("dailyReport/updateEditModel", newValue);
      },
    },
    reports: {
      get: function () {
        return this.$store.getters["dailyReport/dataList"];
      },
    },
    fileList() {
      // check if document record exists and is not emopty object
      if (this.editModel.document && this.editModel.document.url) {
        return [this.editModel.document];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    switchChanged() {
      this.$store.commit("dailyReport/initEditModelDate");
    },
    addNewReport() {
      if (this.newReportVisible) {
        return;
      }

      this.$store.commit("dailyReport/initEditModel");

      this.openReportDialog();
    },
    editReport(storedRecord, index) {
      this.editModel = { ...storedRecord };
      if (this.editModel.dateRange && this.editModel.dateRange.length > 0) {
        this.isSingleDate = false;
      } else {
        this.isSingleDate = true;
      }
      this.openReportEditDialog();
    },
    async saveDialog() {
      const isValid = await this.$refs.validateObserver.validate();
      if (!isValid) {
        return;
      }
      try {
        if (this.editModel.isNew) {
          if (!this.editModel.freeText && !this.editModel.file) {
            Message.error("Geben Sie einen Text ein oder wählen Sie eine Datei aus.");
            return;
          }

          await this.createNewReport(this.editModel);
        } else {
          if (!this.editModel.freeText && !this.editModel.document && !this.editModel.file) {
            Message.error("Geben Sie einen Text ein oder wählen Sie eine Datei aus.");
            return;
          }

          await this.editExistingReport(this.editModel);
        }
        this.closeReportDialog();
      } catch (error) {
        throw error;
      }
    },
    async fileUploaded({ data, file, filename }) {
      this.editModel.file = file;
    },
    async fileRemoved() {
      this.editModel.file = null;
      this.editModel.document = null;
    },
    async saveFileList() {},
    async deleteFile(fileName) {},
    closing() {
      if (!this.closedProperly) {
        this.closeReportDialog();
      }
    },
    getFreeText(record) {
      if (record.freeText) {
        return record.freeText;
      } else {
        return " - ";
      }
    },
    getFilenameForDocument(record) {
      if (record.document) {
        return record.document.name;
      } else {
        return " - ";
      }
    },
    getUrlForDocument(record) {
      if (record.document) {
        return record.document.url;
      } else {
        return undefined;
      }
    },
    viewDocument(record) {
      const url = this.getUrlForDocument(record);
      if (url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + record.document.url;
        this.openPdf({ url: completeUrl, fileName: record.document.name });
      } else {
        window.open(`${url}?view=true`, "_blank");
      }
    },
    downloadDocument(record) {
      const url = this.getUrlForDocument(record);
      window.open(url, "_blank");
    },
    formatDateRange(datarow) {
      if (datarow.dateRange && datarow.dateRange.length === 2) {
        return `${moment(datarow.dateRange[0]).format("L")} - ${moment(datarow.dateRange[1]).format("L")}`;
      } else {
        return moment(datarow.date).format("L");
      }
    },
    sortDateRange(a, b) {
      let dateLeft, dateRight;
      if (a.date) {
        dateLeft = a.date;
      } else {
        dateLeft = a.dateRange[0];
      }

      if (b.date) {
        dateRight = b.date;
      } else {
        dateRight = b.dateRange[0];
      }

      const aDate = new Date(dateLeft);
      const bDate = new Date(dateRight);
      console.log("aDate", aDate);
      console.log("bDate", bDate);
      if (aDate < bDate) {
        return -1;
      } else if (bDate < aDate) {
        return 1;
      } else {
        return 0;
      }
    },
    closeReportDialog() {
      this.newReportVisible = false;
      this.closedProperly = true;
    },
    openReportDialog() {
      this.newReportVisible = true;
      this.closedProperly = false;
      this.isSingleDate = false;

      this.$store.commit("dailyReport/initEditModelDate");
    },
    openReportEditDialog() {
      this.newReportVisible = true;
      this.closedProperly = false;
    },
    async createNewReport(reportData) {
      try {
        this.loading = true;
        const formData = this.formatFormBody(reportData);
        await this.$store.dispatch("dailyReport/create", formData);

        this.closeReportDialog();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async editExistingReport(reportData) {
      try {
        this.loading = true;
        const reportId = reportData._id;
        const formData = this.formatFormBody(reportData);
        await this.$store.dispatch("dailyReport/update", { reportId: reportId, formData: formData });
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async deleteReport(report) {
      this.$confirmDelete()
        .then(async () => {
          let { _id } = report;
          try {
            await this.$store.dispatch("dailyReport/delete", _id);
          } catch (error) {
            Message({
              message: error.message,
              type: "error",
            });
          } finally {
            this.loading = false;
          }
        })
        .catch(() => {
          // nothing to do then prevent uncatched error message in console
        });
    },
    // returns ready to submit form body
    formatFormBody(reportData) {
      const formData = new FormData();
      const { file, freeText, date, dateRange } = reportData;

      formData.append("projectId", this.projectId);

      if (file || file === null) {
        formData.append("file", file);
      }

      if (this.isSingleDate) {
        formData.append("date", moment(date).toISOString());
      } else {
        formData.append("daterange", JSON.stringify(dateRange));
      }

      if (freeText) {
        formData.append("freeText", freeText);
      }

      return formData;
    },
  },
  watch: {
    isSingleDate() {
      this.$refs.validateObserver.reset();
    },
  },
};
</script>
