<template>
  <div>
    <el-button
      class="symbol-select"
      @click="handleShow"
      :style="`background-color: ${value}; height: 42px; width: 54px`"
    >
      <i v-if="value" :class="value"></i>
      <span v-else>N/A</span>
    </el-button>
    <el-dialog :visible.sync="visible" :title="$t('selectColor')" append-to-body>
      <div class="symbol-list">
        <el-button-group>
          <el-button
            v-for="item in colorList"
            :key="item"
            type="default"
            @click="handleChange(item)"
            :class="{ 'color-active': value === item }"
            :style="`background-color: ${item}; height: 42px; width: 54px`"
          >
            <i :class="item" />
          </el-button>
        </el-button-group>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ButtonGroup, Button, Dialog } from "element-ui";
export default {
  name: "color-select",
  props: {
    value: String,
  },
  components: {
    [Dialog.name]: Dialog,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      visible: false,
      colorList: [
        "#cc0000",
        "#a30000",
        "#8b0000",
        "#470000",
        "#f7bd7f",
        "#f39d3c",
        "#ec7a08",
        "#b35c00",
        "#f9d67a",
        "#f5c12e",
        "#f0ab00",
        "#b58100",
        "#c8eb79",
        "#ace12e",
        "#92d400",
        "#6ca100",
        "#9ecf99",
        "#6ec664",
        "#3f9c35",
        "#2d7623",
        "#7dbdc3",
        "#3a9ca6",
        "#007a87",
        "#005c66",
        "#7cdbf3",
        "#35caed",
        "#00b9e4",
        "#008bad",
        "#a18fff",
        "#8461f7",
        "#703fec",
        "#582fc0",
        "#7dc3e8",
        "#39a5dc",
        "#0088ce",
        "#00659c",
        "#ededed",
        "#d1d1d1",
        "#bbbbbb",
        "#8b8d8f",
      ],
    };
  },
  methods: {
    handleShow() {
      this.visible = true;
    },
    handleChange(value) {
      this.$emit("input", value);
      this.visible = false;
    },
  },
};
</script>

<style>
</style>