<template>
  <div>
    <form>
      <div class="card-body">
        <!-- should only load values but not manage it -->
        <div style="display: none">
          <!-- EINHEITEN -->
          <type-settings
            modelType="global"
            modelid="unit"
            type="Einheit"
            :isEditMode="false"
            :useSymbol="false"
            :useColors="false"
            :useAccessGroups="false"
          />
        </div>

        <el-row>
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.supply.profilesettings.warengruppen") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <!-- WARENGRUPPEN -->
          <el-col :span="24">
            <productgroup-settings ref="productGroupSettings" modelType="supply" :isEditMode="isEditMode" />
          </el-col>
        </el-row>

        <hr />

        <el-row :gutter="20">
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.supply.profilesettings.status") }}
            </div>
          </el-col>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.supply.profilesettings.dokumente") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px" :gutter="20">
          <el-col :span="12">
            <!-- Status Settings -->
            <status-settings :isEditMode="isEditMode" resourceType="supply" />
          </el-col>
          <el-col :span="12">
            <!-- Dokument Settings -->
            <type-settings
              :isEditMode="isEditMode"
              modelid="supply"
              modelType="documents"
              type="Dokument"
              :useSystemRelevant="true"
              :useInSma="true"
              :useAccessGroups="true"
              :forAdmin="true"
            />
          </el-col>
        </el-row>
      </div>
    </form>
  </div>
</template>

<script>
import { TypeSettings, ProductGroups } from "src/components/UIComponents";
import StatusSettings from "../UIComponents/Helper/StatusSettings.vue";

export default {
  name: "supply-profile-settings",
  components: {
    StatusSettings,
    [TypeSettings.name]: TypeSettings,
    [ProductGroups.name]: ProductGroups,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    isActive: Boolean,
  },
  watch: {
    isActive(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$refs.productGroupSettings.initialize();
      }
    },
  },
  computed: {
    profileInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
