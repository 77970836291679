<template>
  <div class="n-edit-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.employees.edit.mitarbeiter")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/employee' }">{{
            $t("src.components.employees.edit.mitarbeiterListe")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ employee.firstName }} {{ employee.lastName }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ activeNameLabel }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title">{{ profileName }}</div>
      </el-col>
      <el-col :span="12" class="profile-buttons-wrapper">
        <cancel-button icon="el-icon-arrow-left" @click="handleBack">{{
          $t("src.components.employees.edit.zurck")
        }}</cancel-button>
        <template v-if="isEditMode">
          <cancel-button v-if="$route.params.id === 'new'" @click="cancelProfile()" />
          <cancel-button v-if="$route.params.id !== 'new'" @click="changeEditMode(false)">{{
            $t("src.components.employees.edit.editierenBeenden")
          }}</cancel-button>
          <delete-button v-if="$route.params.id !== 'new'" @click="deleteProfile()" />
          <save-button @click.prevent="updateProfile(employee)" data-testid="save_profile" />
        </template>
        <template v-else>
          <edit-profile-button
            v-if="$can('update', 'employee')"
            :editMode="isEditMode"
            @click="changeEditMode(!isEditMode)"
          />
        </template>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" tab-position="left" class="profile-tabs hide-disabled">
      <el-tab-pane :label="$t('src.components.employees.edit.general')" name="general" :disabled="generalTabDisabled">
        <ValidationObserver ref="validateObserver">
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <employee-profile
              v-show="employee"
              :isEditMode="isEditMode"
              :employee="employee"
              :tabName="$t('src.components.employees.edit.general')"
              :accessRights="generalTabAccess"
              @setProfileData="setProfileData"
              @savePartialProfileData="savePartialProfileData"
              :updateProfile="updateProfile"
              :isNew="isNew"
            />
          </perfect-scrollbar>
        </ValidationObserver>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.employees.edit.privateData')"
        name="privateData"
        :disabled="!employeeAccess.specificAccess.privateData"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <employee-private-profile
            :isEditMode="isEditMode"
            :employee="employee"
            :tabName="$t('src.components.employees.edit.privateData')"
            :accessRights="employeeAccess.specificAccess.privateData"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.employees.edit.privateDataAbroad')"
        name="privateDataAbroad"
        :disabled="!employeeAccess.specificAccess.privateDataAbroad"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <employee-foreign-profile
            :isEditMode="isEditMode"
            :employee="employee"
            :tabName="$t('src.components.employees.edit.privateDataAbroad')"
            :accessRights="employeeAccess.specificAccess.privateDataAbroad"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.employees.edit.status')"
        name="status"
        v-if="!isNew"
        :disabled="!employeeAccess.specificAccess.status"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <ressource-status
            :isEditMode="isEditMode"
            statusModel="employee"
            :statusModelID="employee.id"
            :accessRights="employeeAccess.specificAccess.status"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.employees.edit.documents')"
        name="documents"
        lazy
        v-if="!isNew"
        :disabled="!employeeAccess.specificAccess.documents"
      >
        <document-list
          :isEditMode="isEditMode"
          modelType="employee"
          :modelID="employee.id"
          @updateResource="updateProfile"
          :accessRights="employeeAccess.specificAccess.documents"
        ></document-list>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.employees.edit.user')"
        name="user"
        lazy
        v-if="employeeAccess.specificAccess.user && employee.user"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <user-profile :employeeId="employee.user.userId" />
        </perfect-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <div v-show="false">
      <employee-profile-settings :isEditMode="false" />
    </div>
  </div>
</template>

<script>
import { get } from "lodash";
import Profile from "./Profile.vue";
import PrivateProfile from "./PrivateProfile.vue";
import ForeignProfile from "./ForeignProfile.vue";
import ProfileSettings from "./ProfileSettings";
import { Tabs, TabPane, MessageBox, Message } from "element-ui";
import { Documents, RessourceStatus } from "src/components/UIComponents";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import UserProfile from "./UserProfile.vue";
import { unlink } from "src/utils/unlink";

export default {
  mounted() {
    this.setFirstTab(this.accessRights);
    let self = this;

    const id = this.$route.params.id; //coming from routing parameter
    const identifier = this.$route.meta.identifier;
    //create or edit mode - edit possible
    if (identifier === "profile_edit" || id === "new") {
      this.changeEditMode(true);
    } else {
      //view mode - no editing possible
      this.changeEditMode(false);
    }
    if (id !== "new") {
      const route = `/api/employees/${id}`;
      //get data from api REST call
      this.axios
        .get(route)
        .then(function (result) {
          //set user data loaded from database
          self.employee = { ...self.employee, ...result.data };
          self.originalData = unlink({ ...self.employee, ...result.data });
          self.setPreservedData({ ...self.employee, ...result.data });
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    } else {
      setTimeout(() => {
        this.setPreservedData(this.employee);
        this.checkDirtyFlag(this.employee);
      }, 1000);
    }
    if (this.$route.query.tab_pane) {
      this.activeName = this.$route.query.tab_pane;
    }
  },
  beforeDestroy() {
    this.discardDirty();
  },
  components: {
    ValidationObserver,
    PerfectScrollbar,
    Message,
    MessageBox,
    UserProfile,
    [Profile.name]: Profile,
    [PrivateProfile.name]: PrivateProfile,
    [ForeignProfile.name]: ForeignProfile,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Documents.name]: Documents,
    [ProfileSettings.name]: ProfileSettings,
    [RessourceStatus.name]: RessourceStatus,
  },
  data() {
    return {
      activeName: "0",
      originalData: null,
      employee: {
        userGroup: null,
        projectRoles: [],
        active: true,
        // inital: true, //this is used in watcher od sub components to determine dirty flag
        hrOffice: { email: "" },
        hrPrivate: {},
        hrForeign: {},
      },
      allTabNames: ["general", "privateData", "privateDataAbroad", "status", "documents", "user", "settings"],
    };
  },
  beforeRouteLeave(route, redirect, next) {
    let self = this;
    if (this.isDirty) {
      MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        distinguishCancelAndClose: true,
        confirmButtonClass: "el-button--success",
      })
        .then(function (action) {
          self.updateProfile(self.employee, false);
          next();
        })
        .catch(function (action) {
          if (action === "close") {
            next(false);
          } else {
            next();
          }
        });
    } else {
      next();
    }
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    ...mapState("account", { accessRights: "accessRights" }),
    ...mapState("profileEditMode", ["isEditMode"]),
    employeeAccess() {
      return get(this.accessRights, "employee", { specificAccess: {}, generalAccess: null });
    },
    activeNameLabel() {
      if (this.activeName === "0") {
        return "";
      }
      return this.$t(`src.components.employees.edit.${this.activeName}`);
    },
    profileName() {
      return this.$route.params.id === "new"
        ? "Neuer Mitarbeiter"
        : `${this.employee.lastName}, ${this.employee.firstName}`;
    },
    isNew() {
      return this.$route.params.id === "new";
    },
    generalTabAccess() {
      const specificAccess = this.employeeAccess.specificAccess || {};
      if (Object.values(specificAccess).every((truly) => !truly) && this.employeeAccess.generalAccess === "full") {
        return "full";
      } else {
        return this.employeeAccess.specificAccess.general;
      }
    },
    generalTabDisabled() {
      // this check is needed for the case when
      // a Superadmin wants to create a initial employee
      const specificAccess = this.employeeAccess.specificAccess || {};
      if (Object.values(specificAccess).every((truly) => !truly) && this.employeeAccess.generalAccess === "full") {
        return false;
      } else {
        return !this.employeeAccess.specificAccess || !this.employeeAccess.specificAccess.general;
      }
    },
  },
  methods: {
    ...mapActions("dirtyFlag", {
      setPreservedData: "setPreservedData",
      checkDirtyFlag: "checkDirty",
      discardDirty: "discard",
    }),
    ...mapActions("profileEditMode", ["changeEditMode"]),
    setProfileData(data) {
      this.employee = unlink({ ...this.employee, ...data });
    },
    savePartialProfileData(data) {
      this.employee = unlink({ ...this.employee, ...data });
    },
    async updateProfile(employeeEntity, redirectOnCreate = true) {
      console.log("edit:updateProfile");
      const isValid = await this.$refs.validateObserver.validate();
      const mainTabInvalid =
        !employeeEntity.firstName ||
        !employeeEntity.lastName ||
        !employeeEntity.companyRole ||
        !employeeEntity.dateOfEntering ||
        !isValid ||
        !get(employeeEntity, "hrOffice.email");
      if (mainTabInvalid) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        return;
      }

      const isNewProfile = this.$route.params.id === "new";
      try {
        const route = "/api/employees";
        if (isNewProfile) {
          const response = await this.axios.post(route, employeeEntity);
          this.employee = response.data;
          this.originalData = unlink(response.data);
          if (redirectOnCreate) {
            this.$router.push({
              name: "Employee Edit",
              params: { id: response.data.id },
            });
          }
        } else {
          const response = await this.axios.put(`${route}/${employeeEntity.id}`, employeeEntity);
          this.originalData = unlink(response.data);
        }
        Message({
          message: "Mitarbeiter Daten gespeichert",
          type: "success",
        });
        this.setPreservedData(employeeEntity);
        this.checkDirtyFlag(employeeEntity);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          Message({
            message: "Bitte füllen Sie alle erforderlichen Felder aus",
            type: "error",
          });
        } else {
          Message({
            message: error.message,
            type: "error",
          });
          throw error;
        }
      }
    },
    cancelProfile() {
      if (this.employee.id) {
        this.axios.delete(`/api/employees/${this.employee.id}`);
      }

      //directly go back to index page
      this.$router.push({ name: "Employee Index" });
    },
    deleteProfile() {
      this.$confirmDelete().then(async () => {
        let self = this;
        self.axios
          .delete(`/api/employees/${this.employee.id}`)
          .then(function (response) {
            Message({
              message: "Mitarbeiter gelöscht",
              type: "success",
            });
            self.discardDirty();
            self.$router.push({
              name: "Employee Index",
            }); //directly go back to index page
          })
          .catch(function (error) {
            Message({
              message: error.message,
              type: "error",
            });
          });

        self.$router.push({
          name: "Employee Index",
        }); //directly go back to index page
      });
    },
    handleBack() {
      this.$router.push({ name: "Employee Index" });
    },
    setFirstTab(accessRights) {
      if (!accessRights.employee) {
        return;
      }
      if (!this.generalTabDisabled) {
        this.activeName = "general";
      } else if (this.activeName === "0") {
        const tabsAccessRights = accessRights.employee.specificAccess;
        const firstTab = this.allTabNames.find((tabName) => !!tabsAccessRights[tabName]);
        this.activeName = firstTab;
      }
    },
  },
  watch: {
    accessRights(rights) {
      this.setFirstTab(rights);
    },
    isEditMode(newVal) {
      if (!newVal) {
        this.employee = unlink(this.originalData);
        this.setPreservedData(this.employee);
        this.discardDirty();
      }
    },
  },
};
</script>
