<template>
  <el-badge type="info" value="Neu" class="self-standing" :hidden="!localUnread"> </el-badge>
</template>

<script>
import { Badge } from "element-ui";

export default {
  name: "new-badge",
  components: {
    [Badge.name]: Badge,
  },
  props: {
    unread: Boolean,
  },
  data() {
    return {
      localUnread: this.unread,
    };
  },
  methods: {
    markAsUnread() {
      this.localUnread = true;
    },
  },
  watch: {
    unread(newVal) {
      setTimeout(() => {
        this.localUnread = newVal;
      }, 5000);
    },
  },
};
</script>
