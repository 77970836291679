// src/store/widget.module.js
import { cloneDeep } from "lodash";

const defaultDocStateWidgetData = [
  { number: 0, labelTemplate: `abgelaufen`, color: "#fbc658" },
  { number: 0, labelTemplate: `läuft innerhalb der nächsten {variable} Tage ab`, variable: 7, color: "#fbc658" },
];

const getDefaultState = () => ({
  birthdaysWidgetData: [],
  docStateWidgetData: [...defaultDocStateWidgetData],
  invoiceStatusWidgetData: [],
});

export const widget = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateWidgetData(state, { type, payload }) {
      switch (type) {
        case "birthdays":
          state.birthdaysWidgetData = cloneDeep(payload);
          break;
        case "documentsState":
          state.docStateWidgetData = cloneDeep(payload);
          break;
        case "invoiceStatus":
          state.invoiceStatusWidgetData = cloneDeep(payload);
          break;
        default:
          console.warn(`Unhandled widget type: ${type}`);
      }
    },
    resetWidgetData(state, type) {      
      const defaultState = getDefaultState();
      if (type === "all") {
        Object.assign(state, cloneDeep(defaultState));
      } else if (state.hasOwnProperty(`${type}WidgetData`)) {
        state[`${type}WidgetData`] = cloneDeep(defaultState[`${type}WidgetData`]);
      } else {
        console.warn(`Unhandled widget type for reset: ${type}`);
      }
    },
  },
  actions: {
    widgetLoaded({ commit }, { type, payload }) {
      commit("updateWidgetData", { type, payload });
    },
    resetWidget({ commit }, type = "all") {
      commit("resetWidgetData", type);
    },
  },
};
