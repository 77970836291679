<template>
  <el-card data-testid="service_hours_card">
    <el-form
      :disabled="!isEditMode"
      :inline="true"
      :model="serviceRecord"
      :rules="rules"
      ref="serviceForm"
      data-testid="service_hours_form"
      v-if="isEditMode"
    >
      <el-form-item prop="startHours">
        <el-input
          type="number"
          v-model="serviceRecord.startHours"
          :placeholder="$t('src.components.machine.components.servicehours.startHours')"
          :disabled="!isEditMode"
        ></el-input>
      </el-form-item>
      <el-form-item prop="intervalHours">
        <el-input
          style="width: 210px"
          type="number"
          v-model="serviceRecord.intervalHours"
          :placeholder="$t('src.components.machine.components.servicehours.intervall')"
        >
          <template v-slot:append>{{ $t("src.components.machine.components.servicehours.uhre") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="entriesCount">
        <el-input
          style="width: 180px"
          type="number"
          v-model="serviceRecord.entriesCount"
          :placeholder="$t('src.components.machine.components.servicehours.anzahl')"
        >
          <template v-slot:append>#</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="preWarnHours">
        <el-input
          type="number"
          v-model="serviceRecord.preWarnHours"
          :placeholder="$t('src.components.machine.components.servicehours.vorwarnzeit')"
          :disabled="!isEditMode"
        >
          <template v-slot:append>{{ $t("src.components.machine.components.servicehours.uhre") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t("src.components.machine.components.servicehours.hinzufgen")
        }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="serviceHours" style="width: 100%" class="mb-20">
      <el-table-column
        type="index"
        :label="$t('src.components.machine.components.servicehours.nr')"
        width="60"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.servicehours.datum')"
        prop="start"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.servicehours.vorwarnzeit')"
        prop="preWarn"
        :formatter="formatPreWarnHours"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.servicehours.aktion')"
        width="150"
        v-if="isEditMode"
      >
        <template v-slot="scope">
          <el-button size="mini" type="danger" @click="handleDeleteRecord(scope.index)">{{
            $t("src.components.machine.components.servicehours.lschen")
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" @click="emitCopy" v-if="isEditMode">{{
      $t("src.components.machine.components.servicehours.kopieren")
    }}</el-button>
  </el-card>
</template>

<script>
import { Input, Card, Table, Form, FormItem, Button } from "element-ui";

export default {
  name: "service-hours",
  components: {
    [Input.name]: Input,
    [Card.name]: Card,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Table.name]: Table,
  },
  props: {
    serviceHours: { type: Array, default: () => [] },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      serviceRecord: {
        startHours: null,
        intervalHours: null,
        entriesCount: null,
        preWarnHours: null,
      },
      rules: {
        startHours: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        intervalHours: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        entriesCount: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        preWarnHours: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
    };
  },
  methods: {
    formatPreWarnHours(record) {
      const { start, preWarn } = record;
      const differenceHours = start - preWarn;
      return `${preWarn} (${differenceHours} Uhre)`;
    },
    onSubmit() {
      this.$refs.serviceForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        const { entriesCount, startHours, intervalHours, preWarnHours } = this.serviceRecord;
        const records = [];
        for (let count = 0; count < entriesCount; count++) {
          const start = parseFloat(startHours);
          if (count > 0) {
            start += count * intervalHours;
          }
          const preWarn = start - preWarnHours;
          records.push({
            start,
            preWarn,
          });
        }
        this.serviceHours.push(...records);
        this.$refs.serviceForm.resetFields();
      });
    },
    handleDeleteRecord(index) {
      this.$confirmDelete().then(() => {
        this.serviceHours.splice(index, 1);
      });
    },
    emitCopy() {
      this.$emit("onCopyClick");
    },
  },
};
</script>

<style></style>
