const state = {
    visible: false,
    resource: null,
};

const mutations = {
    init(state, resource) {
        state.visible = true;
        state.resource = resource;
    },
    hide(state) {
        state.visible = false;
        state.resource = null;
    }
};

const actions = {
    init({ commit }, resource) {
        commit('init', resource);
    },
    hide({ commit }) {
        commit('hide');
    }
};

export const montagsplanung = {
    namespaced: true,
    state,
    mutations,
    actions,
}