<template>
  <div>
    <form>
      <div class="card-body">
        <el-row :gutter="20">
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.vehicle.profilesettings.stammdaten") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px" :gutter="20">
          <!-- Führerscheine ! COMING FROM employee modeltype ! -->
          <el-col :span="12">
            <type-settings
              modelType="employee"
              modelid="licence"
              type="Führerscheine"
              :isEditMode="isEditMode"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </el-col>
        </el-row>

        <hr />

        <el-row :gutter="20">
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.vehicle.profilesettings.warengruppen") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <!-- WARENGRUPPEN -->
          <el-col :span="24">
            <productgroup-settings ref="productGroupSettings" modelType="vehicle" :isEditMode="isEditMode" />
          </el-col>
        </el-row>

        <hr />

        <el-row :gutter="20">
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.vehicle.profilesettings.status") }}
            </div>
          </el-col>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.vehicle.profilesettings.dokumente") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px" :gutter="20">
          <el-col :span="12">
            <!-- Status Settings -->
            <status-settings :isEditMode="isEditMode" resourceType="vehicle" />
          </el-col>
          <el-col :span="12">
            <!-- Dokument Settings -->
            <type-settings
              :isEditMode="isEditMode"
              modelid="vehicle"
              modelType="documents"
              type="Dokument"
              :useSystemRelevant="true"
              :useInSma="true"
              :useAccessGroups="true"
              :forAdmin="true"
            />
          </el-col>
        </el-row>
      </div>
    </form>
  </div>
</template>

<script>
import { TypeSettings, ProductGroups } from "src/components/UIComponents";
import StatusSettings from "../UIComponents/Helper/StatusSettings.vue";

export default {
  name: "vehicle-profile-settings",
  components: {
    StatusSettings,
    [TypeSettings.name]: TypeSettings,
    [ProductGroups.name]: ProductGroups,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isActive: Boolean,
  },
  watch: {
    isActive(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$refs.productGroupSettings.initialize();
      }
    },
  },
};
</script>
