<template>
  <div v-loading="loading" class="n-edit-wrapper">
    <ValidationObserver ref="validateObserver" slim>
      <!-- BREADCRUM TRAIL -->
      <el-row type="flex">
        <el-col :span="24">
          <el-breadcrumb class="n-view-breadcrumb" separator="/">
            <el-breadcrumb-item :to="{ path: '/project' }">{{
              $t("src.components.project.edit.projektListe")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ project.bvName }} </el-breadcrumb-item>
            <el-breadcrumb-item>{{ activeNameLabel }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <el-row type="flex" align="center">
        <el-col :span="12" style="display: flex; align-items: center">
          <div class="n-view-title mr-2" style="display: inline-block">{{ project.bvName }}</div>
          <div class="n-view-title-meta" v-if="projectDateRange">{{ projectDateRange }}</div>
        </el-col>
        <el-col :span="12" class="profile-buttons-wrapper" style="display: flex; align-items: center">
          <cancel-button icon="el-icon-arrow-left" @click="handleBack">{{
            $t("src.components.project.edit.zurck")
          }}</cancel-button>
        </el-col>
      </el-row>

      <el-tabs v-model="activeName" tab-position="left" class="profile-tabs hide-disabled">
        <el-tab-pane
          :label="$t('src.components.project.edit.downloadCenter')"
          name="downloadCenter"
          lazy
          class="ps-no-save-button"
          v-show="access.specificAccess.downloadCenter"
          :disabled="!access.specificAccess.downloadCenter"
        >
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <download-center :project="project" v-if="project.id" />
          </perfect-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('src.components.project.edit.documents')"
          name="documents"
          lazy
          class="ps-no-save-button"
          v-show="access.specificAccess.documents"
          :disabled="!access.specificAccess.documents"
        >
          <document-list
            :isEditMode="true"
            modelType="project"
            :modelID="project.id"
            :projectsMeta="projectsMeta"
            :noDateRange="project.noDateRange"
            :projectDateRange="project.dateRange"
            :showRelationColumn="false"
            :accessRights="'full'"
          ></document-list>
        </el-tab-pane>

        <el-tab-pane
          :label="$t('src.components.project.edit.reports')"
          name="reports"
          :lazy="true"
          class="ps-no-save-button"
          v-show="access.specificAccess.reports"
          :disabled="!access.specificAccess.reports"
        >
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <daily-report
              :isEditMode="true"
              :projectRange="project.dateRange"
              :noDateRange="project.noDateRange"
              :projectId="project.id"
              :accessRights="'full'"
            ></daily-report>
          </perfect-scrollbar>
        </el-tab-pane>

        <el-tab-pane
          :label="$t('ToDos')"
          name="todos"
          :lazy="true"
          class="ps-no-save-button"
          v-show="access.specificAccess.todos"
          :disabled="!access.specificAccess.todos"
        >
          <todo-list :projectId="project.id" :isWorkshop="true" />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('Notes')"
          name="notes"
          :lazy="true"
          class="ps-no-save-button"
          v-show="access.specificAccess.notes"
          :disabled="!access.specificAccess.notes"
        >
          <notes-list :projectId="project.id" :isWorkshop="true" :projectsMeta="projectsMeta" />
        </el-tab-pane>
      </el-tabs>
      <div v-show="false">
        <project-profile-settings />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ProfileSettings from "./ProfileSettings";
import DownloadCenter from "./DownloadCenter/DownloadCenter.vue";
import Pencil from "vue-material-design-icons/Pencil";
import { moment } from "src/config/moment";
import { Tabs, TabPane, Message, MessageBox } from "element-ui";
import NotesList from "./ProjectNotes/NotesList.vue";
import TodoList from "./TodoList/TodoList.vue";
import { Documents } from "src/components/UIComponents";
import DailyReport from "./DailyReport/DailyReport.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapState } from "vuex";
import { get } from "lodash";

export default {
  name: "workshop-edit",
  props: {},
  components: {
    PerfectScrollbar,
    ValidationObserver,
    DownloadCenter,
    NotesList,
    TodoList,
    DailyReport,
    [Documents.name]: Documents,
    [Pencil.name]: Pencil,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [ProfileSettings.name]: ProfileSettings,
  },
  data() {
    return {
      activeName: "downloadCenter",
      project: {},
      loading: false,
      isMemberOfProject: false,
      projectsMeta: [],
      allTabNames: ["downloadCenter", "documents", "reports", "todos", "notes"],
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        const projectId = this.$route.params.id;
        const route = `/api/projects/${projectId}`;
        const response = await this.axios.get(route);
        await Promise.all([this.fetchProjectMeta(), this.checkIfMemberOfProject(projectId)]);
        this.project = { ...response.data };
        if (this.$route.params.tab_pane && this.hasAccessToTab(this.$route.params.tab_pane)) {
          this.activeName = this.$route.params.tab_pane;
        } else {
          this.setFirstTab(this.accessRights);
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    handleBack() {
      this.$router.push({ name: "Project Index" });
    },
    async fetchProjectMeta() {
      const response = await this.axios.get("/api/projects/meta");
      this.projectsMeta = response.data;
    },
    async checkIfMemberOfProject(projectId) {
      try {
        const response = await this.axios.get(`/api/employees/is-member-of-project/${projectId}`);
        this.isMemberOfProject = response.data;
      } catch (error) {
        throw error;
      }
    },
    hasAccessToTab(tabName) {
      return !!get(this.access, `specificAccess.${tabName}`, false);
    },
    setFirstTab(accessRights) {
      if (!accessRights.project) {
        return;
      }
      if (this.activeName === "downloadCenter") {
        const tabsAccessRights = this.access.specificAccess;
        let firstTab = this.allTabNames.find((tabName) => !!tabsAccessRights[tabName]);
        this.activeName = firstTab;
      }
    },
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    access() {
      return get(this.accessRights, "workshop_projects", { specificAccess: {}, generalAccess: null });
    },
    projectDateRange() {
      if (this.project.noDateRange) {
        return null;
      } else if (this.project.dateRange && this.project.dateRange.length === 2) {
        return `(${this.project.dateRange.map((d) => moment(d).format("L")).join(" - ")})`;
      }
      return null;
    },
    activeNameLabel() {
      return this.$t(`src.components.project.edit.${this.activeName}`);
    },
  },
  watch: {
    access(rights) {
      this.setFirstTab(rights);
    },
  },
};
</script>
