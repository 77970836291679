<template>
  <div class="n-edit-wrapper">
    <!-- BREADCRUM TRAIL -->
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{ $t("src.components.vehicle.edit.kfz") }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/vehicle' }">{{
            $t("src.components.vehicle.edit.kfzListe")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ vehicle.name }} </el-breadcrumb-item>
          <el-breadcrumb-item>{{ activeNameLabel }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title">{{ profileName }}</div>
      </el-col>
      <el-col :span="12" class="profile-buttons-wrapper">
        <cancel-button icon="el-icon-arrow-left" @click="handleBack">{{
          $t("src.components.vehicle.edit.zurck")
        }}</cancel-button>
        <template v-if="isEditMode">
          <cancel-button v-if="$route.params.id === 'new'" @click="deleteProfile()" />
          <cancel-button v-if="$route.params.id !== 'new'" @click="changeEditMode(false)">{{
            $t("src.components.vehicle.edit.editierenBeenden")
          }}</cancel-button>
          <delete-button v-if="$route.params.id !== 'new'" @click="deleteProfile()" />
          <cancel-button v-if="$route.params.id !== 'new'" @click.prevent="copyProfile" :disabled="!isEditMode">{{
            $t("src.components.vehicle.edit.kopieren")
          }}</cancel-button>
          <save-button @click="updateProfile(vehicle)" data-testid="save_profile" />
        </template>
        <template v-else>
          <edit-profile-button
            v-if="$can('update', 'vehicle')"
            :editMode="isEditMode"
            @click="changeEditMode(!isEditMode)"
          />
        </template>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" tab-position="left" class="profile-tabs hide-disabled">
      <el-tab-pane
        :label="$t('src.components.vehicle.edit.general')"
        name="general"
        :disabled="!vehicleAccess.specificAccess.general"
      >
        <ValidationObserver ref="validateObserver">
          <perfect-scrollbar :options="{ suppressScrollX: true }">
            <vehicle-profile
              :isEditMode="isEditMode"
              :vehicle="vehicle"
              :tabName="activeNameLabel"
              v-on:copyProfileCicked="copyProfile"
              :accessRights="vehicleAccess.specificAccess.general"
              @setProfileData="setProfileData"
              @savePartialProfileData="savePartialProfileData"
              :updateProfile="updateProfile"
            />
          </perfect-scrollbar>
        </ValidationObserver>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.vehicle.edit.status')"
        name="status"
        :disabled="!vehicleAccess.specificAccess.status"
        v-if="!isNew"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <ressource-status
            :isEditMode="isEditMode"
            statusModel="vehicle"
            :statusModelID="vehicle.id"
            :accessRights="vehicleAccess.specificAccess.status"
          />
        </perfect-scrollbar>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.vehicle.edit.documents')"
        name="documents"
        lazy
        v-if="!isNew"
        :disabled="!vehicleAccess.specificAccess.documents"
      >
        <document-list
          v-if="activeName === 'documents'"
          :isEditMode="isEditMode"
          modelType="vehicle"
          @updateResource="updateProfile"
          :modelID="vehicle.id"
          :accessRights="vehicleAccess.specificAccess.documents"
          withSuppliers
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.vehicle.edit.sourcing')"
        name="sourcing"
        lazy
        v-if="!isNew && vehicleAccess.specificAccess.sourcing"
        :disabled="!vehicle.id"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <supplier-articles
            :isEditMode="isEditMode"
            :resourceId="vehicle.id"
            resourceType="vehicle"
            :accessRights="vehicleAccess.specificAccess.sourcing"
          />
        </perfect-scrollbar>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.vehicle.edit.service')"
        name="service"
        :disabled="!vehicleAccess.specificAccess.service"
      >
        <customer-service
          :isEditMode="vehicleAccess.specificAccess.service !== 'readonly' && isEditMode"
          :vehicle="vehicle"
          v-on:copyProfileCicked="copyProfile"
        />
      </el-tab-pane>
    </el-tabs>

    <copy-dialog
      ref="copyDialogRef"
      resourceType="vehicles"
      :resourceId="vehicle.id"
      :resourceRecord="vehicle"
      :checkboxesList="copyCheckboxesList"
    />
    <div v-show="false">
      <vehicle-profile-settings :isEditMode="vehicleAccess.specificAccess.settings !== 'readonly'" />
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Profile from "./Profile.vue";
import ProfileSettings from "./ProfileSettings";
import Service from "./Service.vue";
import CustomerService from "./CustomerService.vue";
import CopyDialog from "../Generic/CopyDialog";
import { mapActions, mapState } from "vuex";
import SupplierArticles from "../Generic/SupplierArticles/SupplierArticles.vue";
import { get } from "lodash";
import { Message, Tabs, TabPane, MessageBox } from "element-ui";
import { Documents, RessourceStatus, LazyImage } from "src/components/UIComponents";
import { ValidationObserver } from "vee-validate";
import { unlink } from "src/utils/unlink";

export default {
  components: {
    ValidationObserver,
    SupplierArticles,
    PerfectScrollbar,
    MessageBox,
    Message,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [LazyImage.name]: LazyImage,
    [Documents.name]: Documents,
    [Profile.name]: Profile,
    [RessourceStatus.name]: RessourceStatus,
    [Service.name]: Service,
    [CustomerService.name]: CustomerService,
    [CopyDialog.name]: CopyDialog,
    [ProfileSettings.name]: ProfileSettings,
  },
  data() {
    return {
      activeName: "0",
      originalData: null,
      allTabNames: ["general", "status", "documents", "sourcing", "service", "settings"],
      vehicle: {
        projectRelevant: true,
        hrSupplier: {},
        customerService: {},
        inital: true,
        active: true,
      },
      copyCheckboxesList: [
        { value: "STATUS", label: "Status", url: "/api/status-events/copy" },
        { value: "DOCUMENTS", label: "Document", url: "/api/documents/copy" },
        { value: "SUPPLIERS", label: "Lieferant", modelProperty: "supplier" },
        { value: "CUSTOMER_SERVICE", label: "KFZ Service", modelProperty: "customerService" },
      ],
    };
  },
  mounted() {
    this.setFirstTab(this.accessRights);
    
    this.loadData();
    if (this.$route.query.tab_pane) {
      this.activeName = this.$route.query.tab_pane;
    }
  },
  beforeDestroy() {
    this.discardDirty();
  },
  beforeRouteLeave(route, redirect, next) {
    if (this.isDirty && this.$route.meta.identifier === "vehicle_edit") {
      MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        distinguishCancelAndClose: true,
        confirmButtonClass: "el-button--success",
      })
        .then(() => {
          this.updateProfile(this.vehicle, false);
          next();
        })
        .catch(function (action) {
          if (action === "close") {
            next(false);
          } else {
            next();
          }
        });
    } else {
      next();
    }
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    ...mapState("profileEditMode", ["isEditMode"]),
    ...mapState("account", { accessRights: "accessRights" }),
    activeNameLabel() {
      if (this.activeName === "0") {
        return "";
      }
      return this.$t(`src.components.vehicle.edit.${this.activeName}`);
    },
    vehicleAccess() {
      return get(this.accessRights, "vehicle", { specificAccess: {}, generalAccess: null });
    },
    profileName() {
      return this.$route.params.id === "new" ? "Neues KFZ" : this.vehicle.name;
    },
    isNew() {
      return this.$route.params.id === "new";
    },
  },
  methods: {
    ...mapActions({
      setPreservedData: "dirtyFlag/setPreservedData",
      checkDirtyFlag: "dirtyFlag/checkDirty",
      discardDirty: "dirtyFlag/discard",
      setSelections: "productGroupSettings/setSelections",
    }),
    ...mapActions("profileEditMode", ["changeEditMode"]),
    setProfileData(data) {
      this.vehicle = unlink(data);
    },
    savePartialProfileData(data) {
      this.vehicle = unlink({ ...this.vehicle, ...data });
    },
    loadData() {
      const id = this.$route.params.id; //coming from routing parameter
      const identifier = this.$route.meta.identifier;
      //create or edit mode - edit possible
      if (identifier === "vehicle_edit" || id === "new") {
        this.changeEditMode(true);
      } else {
        //view mode - no editing possible
        this.changeEditMode(false);
      }

      if (id === "new") {
        setTimeout(() => {
          this.setPreservedData(this.vehicle);
          this.checkDirtyFlag(this.vehicle);
        }, 500);
      } else {
        const route = `/api/vehicles/${id}`;
        //get data from api REST call
        this.axios
          .get(route)
          .then((result) => {
            //set user data loaded from database
            this.vehicle = result.data;
            this.originalData = unlink(result.data);
            this.setPreservedData(result.data);
            //now is data available -> update dependent selections
            //product groups:1
            let selectedProductGroups = {
              selectedProductGroup1: this.vehicle.selectedProductGroup1,
              selectedProductGroup2: this.vehicle.selectedProductGroup2,
            };
            this.setSelections(selectedProductGroups);
          })
          .catch(function (error) {
            Message({
              message: error.message,
              type: "error",
            });
          });
      }
    },
    async updateProfile(entity, redirectOnCreate = true) {
      const isValid = await this.$refs.validateObserver.validate();
      const isNewProfile = this.$route.params.id === "new";
      if (!entity.name || !isValid || !entity.dateOfEntering) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        return;
      }
      try {
        const route = "/api/vehicles";
        if (isNewProfile) {
          const response = await this.axios.post(route, entity);
          this.vehicle = response.data;
          this.originalData = unlink(response.data);
          if (redirectOnCreate) {
            this.$router.push({
              name: "Vehicle Edit",
              params: { id: response.data.id },
            });
          }
        } else {
          const response = await this.axios.put(`${route}/${entity.id}`, entity);
          this.originalData = unlink(response.data);
        }
        this.setPreservedData(entity);
        this.checkDirtyFlag(entity);
        Message({
          message: "KFZ Daten gespeichert",
          type: "success",
        });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    copyProfile() {
      this.$refs.copyDialogRef.showModal();
    },
    deleteProfile() {
      this.$confirmDelete().then(() => {
        this.axios.delete("/api/vehicles/" + this.vehicle.id);
        this.discardDirty();
        this.$router.push({
          name: "Vehicle Index",
        }); //directly go back to index page
      });
    },
    handleBack() {
      this.$router.push({ name: "Vehicle Index" });
    },
    setFirstTab(accessRights) {
      if (!accessRights.vehicle) {
        return;
      }
      if (this.activeName === "0") {
        const tabsAccessRights = accessRights.vehicle.specificAccess;
        const firstTab = this.allTabNames.find((tabName) => !!tabsAccessRights[tabName]);
        this.activeName = firstTab;
      }
    },
  },
  watch: {
    accessRights(rights) {
      this.setFirstTab(rights);
    },
    vehicle: {
      handler(newVal) {
        this.checkDirtyFlag(newVal);
      },
      deep: true,
    },
    isEditMode(newVal) {
      if (newVal === false) {
        if (this.isDirty) {
          this.loadData();
        }
        this.vehicle = unlink(this.originalData);
        this.setPreservedData(this.vehicle);
        this.discardDirty();
      }
    },
  },
};
</script>

<style></style>
