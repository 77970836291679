import { moment } from "src/config/moment";
import { flattenDeep } from "lodash";

/**
 * Returns array of remaining date ranges that are left from extracting array of{rangesToExtract} from {givenRange}
 * @param {[Date, Date][]} rangesToExtract - array of date ranges.
 * @param {Moment.Range} givenRange - basically it's event range
 */
export function subtractRanges(rangesToExtract, givenRange) {
  let result = [givenRange];
  for (let i = 0; i < rangesToExtract.length; i++) {
    result = flattenDeep(
      result
        .map(item => item.subtract(moment.range(rangesToExtract[i])))
        .filter(item => !!item)
    );
  }
  result = result.filter(item => !!item);
  return result;
}