<template>
  <div>
    <h4 class="title">{{ $t("src.components.administration.excelsync.index.excelDatenbankSynchronisierung") }}</h4>

    <div class="row">
      <div class="col-md-4">
        <div class="mb-2">
          <label>{{ $t("src.components.administration.excelsync.index.tabelleWhlen") }}</label>
          <v-select
            :filterable="true"
            v-model="selectedTable"
            :placeholder="$t('src.components.administration.excelsync.index.bitteAuswhlen')"
            :options="tables"
            valueIdentifier="value"
            labelIdentifier="text"
          />
        </div>
        <el-button type="primary" :loading="downloading" @click.prevent="downloadExcel()">
          <span class="el-icon-download"></span
          >{{ $t("src.components.administration.excelsync.index.datenHerunterladen") }}
        </el-button>

        <!-- <el-upload
          style="background: blue; width: 300px; display: contents"
          class="upload-demo"
          ref="upload"
          accept="*.csv"
          :multiple="false"
          action=""
          :limit="1"
          :on-success="fileUploaded"
          :file-list="fileList"
          :on-remove="removeFile"
          :before-upload="beforeUpload"
          :http-request="preserveFile"
        >
          <button style="margin-left: 10px" class="btn btn-success btn-fill btn-wd" v-slot:trigger>
            <span class="el-icon-upload2"></span>{{ $t("src.components.administration.excelsync.index.csvHochladen") }}
          </button>

          <div class="el-upload__tip" v-slot:tip>
            {{ $t("src.components.administration.excelsync.index.aktuelleCsvDatei") }}
          </div>
        </el-upload> -->
      </div>
    </div>

    <!-- <div class="col-md-12">
      <button
        style="height: 100px; width: 300px; margin-left: 100px; margin-top: 20px; font-size: 16px"
        class="btn btn-success btn-fill btn-wd"
        @click.prevent="synchronize()"
      >
        {{ $t("src.components.administration.excelsync.index.datenSynchronisieren") }}
      </button>
    </div> -->
  </div>
</template>

<script>
import { Message, Upload } from "element-ui";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";

let today = new Date();
let day = today.getDay();
let month = today.getMonth();
let year = today.getFullYear();
let dateString = `${day}-${month}-${year}`;

export default {
  components: {
    Message,
    [Upload.name]: Upload,
  },
  data() {
    return {
      downloading: false,
      file: null,
      selectedTable: "",
      tables: [
        { value: "employee", text: "Mitarbeiter", folderName: "employee" },
        { value: "machine", text: "Maschinen", folderName: "machine" },
        { value: "vehicle", text: "KFZ", folderName: "vehicle" },
        { value: "supply", text: "Verbrauchsmaterial", folderName: "supply" },
        { value: "rhb", text: "RHB", folderName: "rhb" },
      ],
      uploadMetadata: "",
      uploadFile: "",
    };
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    fileList() {
      if (this.file) {
        return [{ name: this.file.name }];
      }
      return [];
    },
  },
  methods: {
    preserveFile({ data, file, filename }) {
      this.fileWasChanged = true;
      this.file = file;
    },
    async downloadExcel() {
      if (!this.selectedTable) {
        Message({
          message: "Bitte Tabelle auswählen",
          type: "info",
        });
        return;
      }
      try {
        const response = await this.axios.get(`/api/mass-upload/${this.selectedTable}`, { responseType: "blob" });
        saveAs(response.data, `${this.selectedTable}.csv`);
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    synchronize() {
      if (!this.file) {
        Message({
          message: "Bitte Datei auswählen",
          type: "error",
        });
        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("collection", this.selectedTable);
      console.log("formData", formData);
      this.axios
        .post("/api/mass-upload/post", formData)
        .then(function (result) {
          Message({
            message: "Excel erfolgreich heruntergeladen",
            type: "success",
          });
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    beforeUpload(file) {
      const isCSV = file.type === "application/vnd.ms-excel";
      if (!this.selectedTable) {
        Message({
          message: "Bitte Tabelle auswählen",
          type: "info",
        });
      }
      return isCSV && this.selectedTable != "";
    },
    fileUploaded(response, file, fileList) {
      //find folder name out of selected table
      let folder = this.getFolderName();

      //replace this.axios.defaults.baseURL
      fileList.forEach((element) => {
        element.url = "/documents/excel/import/" + dateString + "/" + folder + "/" + element.name;
      });

      //store meta and filename for sync
      this.uploadFile = file.name;
    },
    removeFile(file, fileList) {
      this.file = null;
    },
    getFolderName() {
      let folder = "";
      for (let index = 0; index < this.tables.length; index++) {
        const table = this.tables[index];
        if (table.value === this.selectedTable) {
          folder = table.folderName;
          break;
        }
      }
      return folder;
    },
  },
};
</script>

<style></style>
