<template>
  <div v-loading="loading" style="height: 100%; display: flex; flex-direction: column">
    <div style="display: none">
      <keyfigures
        modelType="global"
        modelID="serviceSpectrum"
        type="Leistungsspektrum"
        v-on:dataChanged="serviceSpectrumOptionsChanged"
        :isEditMode="computedEditMode"
      />
      <productgroup-settings :modelType="activeTab" />
    </div>
    <div>
      <el-row type="flex" justify="space-between" style="padding: 0 15px; margin-bottom: 15px; margin-top: 6px">
        <el-col>
          <h3 class="n-profile-title" style="margin: 0">
            {{ $t("src.components.project.projectconfiguration.konfiguriereDasProjekt") }}
          </h3>
        </el-col>
        <el-col style="text-align: right">
          <div id="for-granularity">
            <granularity-switcher resourceType="employee" />
          </div>
          <profile-radio-group
            class="mr-2"
            v-model="showOnlyAllocated"
            :items="[
              { label: 'Alle Ressourcen', value: false },
              { label: 'Zugewiesene Ressourcen', value: true },
            ]"
          />
          <cancel-button @click="toggleFilters" :active="filtersVisible">{{
            $t("src.components.project.projectconfiguration.filters")
          }}</cancel-button>
        </el-col>
      </el-row>
      <el-collapse-transition>
        <div class="form-group" v-show="filtersVisible" style="padding-right: 10px">
          <div class="row">
            <div class="col-sm-6">
              <el-input
                :placeholder="$t('src.components.project.projectconfiguration.suche')"
                v-model="searchQuery"
                clearable
                suffix-icon="el-icon-search"
              />
            </div>
            <div class="col-sm-6">
              <div id="for-widget"></div>
            </div>
          </div>
          <div class="row" v-if="computedEditMode">
            <div class="col-md-3">
              <span class="n-profile-label-title"
                >{{ $t("src.components.project.projectconfiguration.projektzeitraum") }}:</span
              ><br />
              <pr-date-picker
                :disabled="!computedEditMode"
                v-model="projectConfigurationManager.selectedDateRange"
                name="projectDateRange"
                is-range
                :placeholder="$t('src.components.project.projectconfiguration.projektzeitraum')"
              />
            </div>

            <div class="col-md-3">
              <service-spectrum-select
                v-show="['machine', 'rhb'].includes(activeTab)"
                :editMode="computedEditMode"
                v-model="selectedServiceSpectrumList"
              />
            </div>

            <div class="col-md-3">
              <product-group-select
                :editMode="computedEditMode"
                productGroupType="pg1"
                v-model="selectedProductGroup1"
              />
            </div>

            <div class="col-md-3">
              <product-group-select
                :editMode="computedEditMode"
                productGroupType="pg2"
                v-model="selectedProductGroup2"
              />
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div style="flex-grow: 1; overflow: hidden">
      <el-tabs stretch type="card" v-model="activeTab" class="profile-inner-tab">
        <el-tab-pane name="machine" :label="$t('src.components.project.projectconfiguration.machines')">
          <template v-slot:label>
            <span><headphones-icon />{{ $t("src.components.project.projectconfiguration.maschinen") }}</span>
          </template>
          <configuration-table
            ref="machine"
            :disabled="!computedEditMode"
            :showOnlyAllocated="showOnlyAllocated"
            :searchQuery="searchQuery"
            :dataManager="projectConfigurationManager"
            modelType="machine"
            :granularityMode="granularitySettings['machine']"
          />
        </el-tab-pane>
        <el-tab-pane name="vehicle" :label="$t('src.components.project.projectconfiguration.kfz')" lazy>
          <template v-slot:label>
            <span><truck-fast-outline-icon />{{ $t("src.components.project.projectconfiguration.kfz") }}</span>
          </template>
          <configuration-table
            ref="vehicle"
            :disabled="!computedEditMode"
            :showOnlyAllocated="showOnlyAllocated"
            :searchQuery="searchQuery"
            :dataManager="projectConfigurationManager"
            modelType="vehicle"
            :granularityMode="granularitySettings['vehicle']"
          />
        </el-tab-pane>
        <el-tab-pane name="rhb" :label="$t('src.components.project.projectconfiguration.rhb')" lazy>
          <template v-slot:label>
            <span><diamond-stone-icon />{{ $t("src.components.project.projectconfiguration.rhb") }}</span>
          </template>
          <configuration-table
            ref="rhb"
            :disabled="!computedEditMode"
            :showOnlyAllocated="showOnlyAllocated"
            :searchQuery="searchQuery"
            :dataManager="projectConfigurationManager"
            modelType="rhb"
            :granularityMode="granularitySettings['rhb']"
          />
        </el-tab-pane>
        <el-tab-pane name="supply" :label="$t('src.components.project.projectconfiguration.verbrauschmaterial')" lazy>
          <template v-slot:label>
            <span
              ><archive-outline-icon />{{ $t("src.components.project.projectconfiguration.verbrauschmaterial") }}</span
            >
          </template>
          <configuration-table
            ref="supply"
            :disabled="!computedEditMode"
            :showOnlyAllocated="showOnlyAllocated"
            :searchQuery="searchQuery"
            :dataManager="projectConfigurationManager"
            modelType="supply"
            :granularityMode="granularitySettings['supply']"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ConfigurationTable from "./Wizard/ConfigurationTable.vue";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

import { Collapse, CollapseItem, Keyfigures, ProductGroups } from "src/components/UIComponents";

import { Message, DatePicker, MessageBox, Tabs, TabPane, Input } from "element-ui";
import Headphones from "vue-material-design-icons/Headphones";
import DiamondStone from "vue-material-design-icons/DiamondStone";
import ArchiveOutline from "vue-material-design-icons/ArchiveOutline";
import TruckFastOutline from "vue-material-design-icons/TruckFastOutline";
import ServiceSpectrumSelect from "src/components/UIComponents/Inputs/ServiceSpectrumSelect";
import { ChecklistWidget } from "src/components/Project/BryntumScheduler/ChecklistWidget";
import GranularitySwitcher from "src/components/GranularitySwitcher";
import { mapGetters } from "vuex";
// let checklistWidget;

export default {
  name: "project-configuration",
  props: {
    project: Object,
    projectConfigurationManager: Object,
    updateProject: Function,
    isEditMode: Boolean,
    accessRights: String,
  },
  components: {
    [GranularitySwitcher.name]: GranularitySwitcher,
    [CollapseTransition.name]: CollapseTransition,
    [ConfigurationTable.name]: ConfigurationTable,
    [Headphones.name]: Headphones,
    [DiamondStone.name]: DiamondStone,
    [ArchiveOutline.name]: ArchiveOutline,
    [TruckFastOutline.name]: TruckFastOutline,
    Message,
    MessageBox,
    [Input.name]: Input,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [DatePicker.name]: DatePicker,
    Collapse,
    CollapseItem,
    [Keyfigures.name]: Keyfigures,
    [ProductGroups.name]: ProductGroups,
    ServiceSpectrumSelect,
  },
  data() {
    return {
      checklistWidget: null,
      activeTab: "machine",
      serviceSpectrumOptions: [],
      selectedServiceSpectrumList: [],
      selectedProductGroup1: "",
      selectedProductGroup2: "",
      searchQuery: "",
      filtersVisible: true,
      showOnlyAllocated: true, // wether only show resources that are already added to current project or all
      loading: false,
    };
  },
  mounted() {
    this.setupChecklistWidget();
  },
  beforeDestroy() {
    if (this.checklistWidget) {
      this.checklistWidget.destroy();
    }
  },
  computed: {
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    placeHolderSS: function () {
      if (this.serviceSpectrumOptions.length > 0) {
        return this.serviceSpectrumOptions.length + " verfügbar";
      } else {
        return "keine erstellt";
      }
    },
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
  methods: {
    async setupChecklistWidget() {
      try {
        this.checklistWidget = new ChecklistWidget({
          appendTo: "for-widget",
          cls: "checklist_configuration",
          id: `checklist_configuration`,
          resourceType: this.activeTab,
          onSelect: (value) => {
            if (value) {
              this.searchQuery = value.name;
              this.activeTab = value.resourceType;
            } else {
              this.searchQuery = "";
            }
          },
        });
        const response = await this.axios.get("/api/checklists");
        const withoutEmployees = response.data.map((checklist) => ({
          ...checklist,
          items: checklist.items.filter((item) => item.resourceType !== "employee"),
        }));
        if (withoutEmployees.length) {
          this.checklistWidget.setData(withoutEmployees);
        }
      } catch (error) {
        throw error;
      }
    },
    handleChangeGranularityMode(mode) {
      this.projectConfigurationManager.setGranularityMode(mode);
    },
    async initProjectConfigurationManager() {
      console.log("initProjectConfigurationManager");
      try {
        this.loading = true;
        await this.projectConfigurationManager.initialize(this.$props.project, this.granularitySettings);
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async switchToPickAndPack() {
      // update project and set events to resources before switching to pick-pack list to grab recent changes
      await this.$props.updateProject();
      await this.projectConfigurationManager.initialize(this.$props.project, this.granularitySettings);
      this.$emit("switchToPickAndPack");
    },
    async updateProfile() {
      await this.$props.updateProject();
      await this.projectConfigurationManager.initialize(this.$props.project, this.granularitySettings);
    },
    serviceSpectrumOptionsChanged(data) {
      this.serviceSpectrumOptions = data && data.options && data.options.filter((item) => item.active);
    },
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible;
    },
  },
  watch: {
    "project.dateRange": function (newVal, oldVal) {
      if (newVal && newVal.length === 2 && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.projectConfigurationManager.setDateRange(newVal);
      }
    },
    "projectConfigurationManager.selectedDateRange": function (newVal, oldVal) {
      this.projectConfigurationManager.initialize(this.$props.project, this.granularitySettings);
      this.$refs[this.activeTab].reComputeData();
    },
    selectedServiceSpectrumList: function (newVal, oldVal) {
      this.projectConfigurationManager.serviceSpectrumChanged(newVal);
      this.$refs[this.activeTab].reComputeData();
    },
    selectedProductGroup1: function (newVal, oldVal) {
      this.projectConfigurationManager.selectedProductGroup1 = newVal;
      this.projectConfigurationManager.finishInitialization();
      this.$refs[this.activeTab].reComputeData();
    },
    selectedProductGroup2: function (newVal, oldVal) {
      this.projectConfigurationManager.selectedProductGroup2 = newVal;
      this.projectConfigurationManager.finishInitialization();
      this.$refs[this.activeTab].reComputeData();
    },
    project: function (newVal, oldVal) {
      if (!newVal.id) {
        //not yet loaded in base
        return;
      }
      this.projectConfigurationManager.currentProject = newVal;
    },
    projectConfigurationManager: function (newVal, oldVal) {
      // if project configuration was changed
      if (!this.$props.isDirty && (newVal.createEvents.length || newVal.deleteEvents.length)) {
        this.$emit("dirtyFlagSet", "projectConfigurationManager");
      }
    },
    async activeTab(newVal, oldVal) {
      if (newVal !== oldVal) {
        await this.$store.dispatch("productGroupSettings/initAsync", { modelType: newVal });
        this.selectedServiceSpectrumList = [];
        this.selectedProductGroup1 = "";
        this.selectedProductGroup2 = "";
      }
    },
  },
};
</script>
<style lang="scss">
.profile-inner-tab {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.profile-tabs .profile-inner-tab .el-tabs__content {
  padding: 0;
  flex-grow: 2;
}

.checklist_configuration#checklist_configuration {
  border: 1px solid #dcdfe6;

  .b-button {
    color: #727272;
    min-height: 40px;
    height: 40px;

    &.b-disabled {
      color: rgba(#727272, 0.3);
    }

    &.b-pressed {
      background-color: rgba(98, 181, 249, 0.8);
    }
  }
}

#for-granularity {
  display: inline-flex;
  position: relative;
  top: -17px;
  margin-right: 10px;
}
</style>
