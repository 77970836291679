<template>
  <div class="d-flex align-items-center">
    <profile-date-picker
      :isEditMode="false"
      :title="$t('src.components.project.profile.projektlaufzeit')"
      :value="dateRange"
    />
    <el-button class="ml-2" @click="showModal">{{ $t("updateDateRange") }}</el-button>
    <el-dialog
      :title="$t('Project date range adjustment')"
      :visible.sync="reportVisible"
      width="100%"
      @closed="dismissDateChange"
      style="max-width: 1200px; margin: 0 auto"
      custom-class="full-height-dialog"
      :close-on-click-modal="false"
    >
      <div class="d-flex align-items-center">
        <profile-date-picker
          :isEditMode="true"
          :title="$t('src.components.project.profile.projektlaufzeit')"
          v-model="newDateRange"
          is-range
          :clearable="false"
        />
        <el-button
          :type="reportFetched ? 'primary' : undefined"
          class="ml-2"
          @click="fetchConfirmationReport"
          :loading="confirmationLoading"
          >{{ $t(reportFetched ? "updateDateRangeAfter" : "updateDateRange") }}</el-button
        >
      </div>
      <div class="granularity-switchers">
        <granularity-switcher showResourceNameInLabel resourceType="employee" id="g-employee" hidden />
        <granularity-switcher showResourceNameInLabel resourceType="machine" id="g-machine" hidden />
        <granularity-switcher showResourceNameInLabel resourceType="rhb" id="g-rhb" hidden />
        <granularity-switcher showResourceNameInLabel resourceType="vehicle" id="g-vehicle" hidden />
        <!-- <granularity-switcher showResourceNameInLabel resourceType="supply" id="g-supply" hidden /> -->
      </div>

      <el-alert
        v-if="showNoChangesInfo"
        :title="$t('Project date range change will be applied without changes in resources')"
        type="info"
        :closable="false"
        show-icon
        center
      />
      <el-tabs v-model="activeTab" v-if="toResolveTab.length || noConflictsTab.length" type="card">
        <el-tab-pane :label="$t('Need to change')" name="not_ok" v-if="renderTableWithConflicts">
          <el-alert
            :title="
              $t(
                'These resources conflict with another project or status assignment. You can replace them with another resource, otherwise they will be removed from project'
              )
            "
            type="warning"
            :closable="false"
            show-icon
          />
          <el-tabs v-model="toResolveTab">
            <el-tab-pane :label="$t('employee')" name="employee" v-if="toResolve.employee.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="toResolve.employee"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="toResolveEmployee"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column min-width="200" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <div>
                            {{ scope.row.name }}
                            <el-tag
                              effect="dark"
                              size="small"
                              type="info"
                              style="margin-left: 5px"
                              v-if="scope.row.isSkipped"
                            >
                              {{ $t("Skip") }}
                            </el-tag>
                          </div>
                          <div v-if="!!scope.row.changes[projectId]">
                            <span v-if="scope.row.changes[projectId].type === 'EXCHANGE'">{{
                              scope.row.changes[projectId].resourceName
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="projectEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.projektStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line" v-html="formatProjectEvents(scope.row)"></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="statusEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.ressourceStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line">
                        {{ formatStatusEvents(scope.row.statusEvents) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150">
                    <template v-slot="scope">
                      <el-button
                        class="btn btn-sm btn-ghost"
                        style="width: 120px"
                        @click="handleReplaceResource(scope.row.resourceType, scope.row)"
                      >
                        <pencil-icon /> Bearbeiten
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('machine')" name="machine" v-if="toResolve.machine.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="toResolve.machine"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="toResolveMachine"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column min-width="200" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <div>
                            {{ scope.row.name }}
                            <el-tag
                              effect="dark"
                              size="small"
                              type="info"
                              style="margin-left: 5px"
                              v-if="scope.row.isSkipped"
                            >
                              {{ $t("Skip") }}
                            </el-tag>
                          </div>
                          <div v-if="!!scope.row.changes[projectId]">
                            <span v-if="scope.row.changes[projectId].type === 'EXCHANGE'">{{
                              scope.row.changes[projectId].resourceName
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="projectEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.projektStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line" v-html="formatProjectEvents(scope.row)"></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="statusEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.ressourceStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line">
                        {{ formatStatusEvents(scope.row.statusEvents) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150">
                    <template v-slot="scope">
                      <el-button
                        class="btn btn-sm btn-ghost"
                        style="width: 120px"
                        @click="handleReplaceResource(scope.row.resourceType, scope.row)"
                      >
                        <pencil-icon /> Bearbeiten
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('vehicle')" name="vehicle" v-if="toResolve.vehicle.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="toResolve.vehicle"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="toResolveVehicle"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column min-width="200" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <div>
                            {{ scope.row.name }}
                            <el-tag
                              effect="dark"
                              size="small"
                              type="info"
                              style="margin-left: 5px"
                              v-if="scope.row.isSkipped"
                            >
                              {{ $t("Skip") }}
                            </el-tag>
                          </div>
                          <div v-if="!!scope.row.changes[projectId]">
                            <span v-if="scope.row.changes[projectId].type === 'EXCHANGE'">{{
                              scope.row.changes[projectId].resourceName
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="projectEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.projektStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line" v-html="formatProjectEvents(scope.row)"></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="statusEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.ressourceStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line">
                        {{ formatStatusEvents(scope.row.statusEvents) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150">
                    <template v-slot="scope">
                      <el-button
                        class="btn btn-sm btn-ghost"
                        style="width: 120px"
                        @click="handleReplaceResource(scope.row.resourceType, scope.row)"
                      >
                        <pencil-icon /> Bearbeiten
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('rhb')" name="rhb" v-if="toResolve.rhb.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="toResolve.rhb"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="toResolveRhb"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column min-width="200" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <div>
                            {{ scope.row.name }}
                            <el-tag
                              effect="dark"
                              size="small"
                              type="info"
                              style="margin-left: 5px"
                              v-if="scope.row.isSkipped"
                            >
                              {{ $t("Skip") }}
                            </el-tag>
                          </div>
                          <div v-if="!!scope.row.changes[projectId]">
                            <span v-if="scope.row.changes[projectId].type === 'EXCHANGE'">{{
                              scope.row.changes[projectId].resourceName
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="projectEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.projektStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line" v-html="formatProjectEvents(scope.row)"></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="statusEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.ressourceStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line">
                        {{ formatStatusEvents(scope.row.statusEvents) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150">
                    <template v-slot="scope">
                      <el-button
                        class="btn btn-sm btn-ghost"
                        style="width: 120px"
                        @click="handleReplaceResource(scope.row.resourceType, scope.row)"
                      >
                        <pencil-icon /> Bearbeiten
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('supply')" name="supply" v-if="toResolve.supply.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="toResolve.supply"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="toResolveSupply"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column min-width="200" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <div>
                            {{ scope.row.name }}
                            <el-tag
                              effect="dark"
                              size="small"
                              type="info"
                              style="margin-left: 5px"
                              v-if="scope.row.isSkipped"
                            >
                              {{ $t("Skip") }}
                            </el-tag>
                          </div>
                          <div v-if="!!scope.row.changes[projectId]">
                            <span v-if="scope.row.changes[projectId].type === 'EXCHANGE'">{{
                              scope.row.changes[projectId].resourceName
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="projectEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.projektStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line" v-html="formatProjectEvents(scope.row)"></div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="200"
                    prop="statusEvents"
                    :label="$t('src.components.uicomponents.helper.indextable.ressourceStatus')"
                  >
                    <template v-slot="scope">
                      <div style="white-space: pre-line">
                        {{ formatStatusEvents(scope.row.statusEvents) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="150">
                    <template v-slot="scope">
                      <el-button
                        class="btn btn-sm btn-ghost"
                        style="width: 120px"
                        @click="handleReplaceResource(scope.row.resourceType, scope.row)"
                      >
                        <pencil-icon /> Bearbeiten
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane :label="$t('Will be changed automatically')" name="is_ok">
          <el-alert
            :title="$t('These resources have no conflicts and can be moved with current project to a new date range.')"
            type="success"
            :closable="false"
            show-icon
          />
          <el-tabs v-model="noConflictsTab">
            <el-tab-pane :label="$t('employee')" name="employee" v-if="noConflicts.employee.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="noConflicts.employee"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="noConflictsEmployee"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column width="400" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex align-items-center">
                        <div class="green-ok mr-2">
                          <check-icon />
                        </div>
                        <div>
                          {{ scope.row.name }}
                          <el-tag
                            effect="dark"
                            size="small"
                            type="info"
                            style="margin-left: 5px"
                            v-if="scope.row.isSkipped"
                          >
                            {{ $t("Skip") }}
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column sortable prop="resourceTypeLabel" label="Ressource typ"> </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('machine')" name="machine" v-if="noConflicts.machine.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="noConflicts.machine"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="noConflictsMachine"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column width="400" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex align-items-center">
                        <div class="green-ok mr-2">
                          <check-icon />
                        </div>
                        <div>
                          {{ scope.row.name }}
                          <el-tag
                            effect="dark"
                            size="small"
                            type="info"
                            style="margin-left: 5px"
                            v-if="scope.row.isSkipped"
                          >
                            {{ $t("Skip") }}
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column sortable prop="resourceTypeLabel" label="Ressource typ"> </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('vehicle')" name="vehicle" v-if="noConflicts.vehicle.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="noConflicts.vehicle"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="noConflictsVehicle"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column width="400" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex align-items-center">
                        <div class="green-ok mr-2">
                          <check-icon />
                        </div>
                        <div>
                          {{ scope.row.name }}
                          <el-tag
                            effect="dark"
                            size="small"
                            type="info"
                            style="margin-left: 5px"
                            v-if="scope.row.isSkipped"
                          >
                            {{ $t("Skip") }}
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column sortable prop="resourceTypeLabel" label="Ressource typ"> </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('rhb')" name="rhb" v-if="noConflicts.rhb.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="noConflicts.rhb"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="noConflictsRhb"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column width="400" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex align-items-center">
                        <div class="green-ok mr-2">
                          <check-icon />
                        </div>
                        <div>
                          {{ scope.row.name }}
                          <el-tag
                            effect="dark"
                            size="small"
                            type="info"
                            style="margin-left: 5px"
                            v-if="scope.row.isSkipped"
                          >
                            {{ $t("Skip") }}
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column sortable prop="resourceTypeLabel" label="Ressource typ"> </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
            <el-tab-pane :label="$t('supply')" name="supply" v-if="noConflicts.supply.length">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
                <el-table
                  :data="noConflicts.supply"
                  @selection-change="(s) => handleChangeSelection(s)"
                  ref="noConflictsSupply"
                  size="mini"
                >
                  <el-table-column type="selection" width="58"> </el-table-column>
                  <el-table-column width="400" sortable prop="name" label="Resource name">
                    <template v-slot="scope">
                      <div class="d-flex align-items-center">
                        <div class="green-ok mr-2">
                          <check-icon />
                        </div>
                        <div>
                          {{ scope.row.name }}
                          <el-tag
                            effect="dark"
                            size="small"
                            type="info"
                            style="margin-left: 5px"
                            v-if="scope.row.isSkipped"
                          >
                            {{ $t("Skip") }}
                          </el-tag>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column sortable prop="resourceTypeLabel" label="Ressource typ"> </el-table-column>
                </el-table>
              </perfect-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
      <el-alert
        v-else-if="!showNoChangesInfo"
        title="Aktualisieren Sie den Datumsbereich des Projekts, um den Bericht anzuzeigen"
        type="info"
        :closable="false"
        center
      />
      <template v-slot:footer>
        <div>
          <div>
            <div class="text-right mb-2" v-if="reportFetched">
              <el-button
                v-if="!confirmedTabs[activeTab]"
                type="primary"
                @click="confirmTab(activeTab)"
                style="width: 96px"
                >{{ $t(confirmedTabs[activeTab] ? "Confirmed" : "Confirm") }}</el-button
              >
            </div>
            <div class="dialog-footer d-flex justify-content-between">
              <cancel-button
                :text="$t('src.components.management.projectsettings.projectdoctypesettings.abbrechen')"
                @click="dismissDateChange"
              ></cancel-button>
              <div>
                <div v-if="reportFetched">
                  <el-button @click="skipSelected">{{ $t("Skip selected") }}</el-button>
                  <el-button @click="releaseSelected" :disabled="activeTab === 'is_ok'">{{
                    $t("Release selected")
                  }}</el-button>
                  <el-tooltip :disabled="allTabsConfirmed" content="Both tabs should be confirmed before submitting!">
                    <save-button
                      :text="$t('src.components.management.projectsettings.projectdoctypesettings.speichern')"
                      style="width: 96px"
                      :class="{ 'is-disabled': !allTabsConfirmed }"
                      @click="confirmDateChange"
                    ></save-button>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      :title="`${$t('replaceResource')} ${replaceResourceName}`"
      :visible.sync="replaceVisible"
      width="450px"
      @closed="dismissReplacement"
      destroy-on-close
    >
      <profile-select
        :multiple="false"
        :editMode="true"
        v-model="selectedProject"
        :title="$t('Project')"
        :items="conflictProjectOptions"
        v-if="allOptionsAvailable"
      />
      <div style="position: relative; margin-bottom: 20px">
        <ValidationObserver ref="replacementDateObserver" v-if="selectedProject === projectId">
          <ValidationProvider rules="required" name="Zeitraum" v-slot="{ errors }">
            <v-date-picker
              style="width: 100%"
              v-model="replacementDate"
              :model-config="modelConfig"
              :attributes="replacementDateAttributes"
              is-range
              :max-date="newDateRange && newDateRange[1]"
              :min-date="newDateRange && newDateRange[0]"
            />
            <transition name="slide">
              <div v-show="errors[0]" class="el-form-item__error">{{ errors[0] }}</div>
            </transition>
          </ValidationProvider>
        </ValidationObserver>
        <v-calendar
          v-else
          style="width: 100%"
          :attributes="replacementDateAttributes"
          :max-date="newDateRange && newDateRange[1]"
          :min-date="newDateRange && newDateRange[0]"
        />
      </div>
      <div class="d-flex justify-content-center">
        <!-- <el-tooltip content="Wählen Sie einen Zeitraum aus, um fortzufahren" :disabled="!!replacementDate"> -->
        <el-radio-group v-model="actionType">
          <el-radio-button :disabled="!allOptionsAvailable" label="RELEASE">{{
            $t("Release from project")
          }}</el-radio-button>
          <el-radio-button :disabled="!allOptionsAvailable" label="EXCHANGE">{{ $t("Exchange by") }}</el-radio-button>
        </el-radio-group>
        <!-- </el-tooltip> -->
      </div>
      <ValidationObserver ref="replaceByObserver" v-if="actionType === 'EXCHANGE'">
        <profile-select
          filterable
          v-model="selectedReplacement"
          label="name"
          :multiple="false"
          :editMode="true"
          :title="$t('src.components.project.profile.replacedBy')"
          :items="pickableResources"
          valueIdentifier="id"
          required
          rules="required"
          :name="$t('src.components.project.profile.replacedBy')"
        />
      </ValidationObserver>

      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.abbrechen')"
            @click="dismissReplacement"
          ></cancel-button>
          <div>
            <el-button @click="skipReplacement">{{ $t("Skip") }}</el-button>
            <save-button
              :text="$t('src.components.management.projectsettings.projectdoctypesettings.speichern')"
              @click="saveReplacement"
            ></save-button>
          </div>
        </span>
      </template>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" @closed="dismissDateChange" :visible.sync="successVisible" width="500px">
      <p class="el-dialog__title">{{ $t("Date range adjusted successfully.") }}</p>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dismissReplacement">OK</el-button>
          <save-button :text="$t('downloadReport')" :loading="pdfLoading" @click="downloadPdfReport"></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Button,
  Message,
  Alert,
  RadioGroup,
  RadioButton,
  Tooltip,
  Tag,
} from "element-ui";
import { moment } from "src/config/moment";
import CheckIcon from "vue-material-design-icons/Check.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { get, uniqBy, sortBy, flatten, groupBy } from "lodash";
import VDatePicker from "v-calendar/lib/components/date-picker.umd";
import VCalendar from "v-calendar/lib/components/calendar.umd";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ProfileSelect from "../UIComponents/Inputs/ProfileSelect.vue";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import GranularitySwitcher from "src/components/GranularitySwitcher";

const resourceTypeToGerman = {
  employee: "Mitarbeiter",
  machine: "Maschine",
  vehicle: "KFZ",
  rhb: "RHB",
  supply: "Verbrauchsm.",
  subcontractor: "Nachunternehmer",
};

export default {
  name: "project-date-change",
  components: {
    GranularitySwitcher,
    PerfectScrollbar,
    ValidationObserver,
    ValidationProvider,
    VCalendar,
    VDatePicker,
    ProfileSelect,
    [CheckIcon.name]: CheckIcon,
    [Tag.name]: Tag,
    [Tooltip.name]: Tooltip,
    [Alert.name]: Alert,
    [Tabs.name]: Tabs,
    [Message.name]: Message,
    [TabPane.name]: TabPane,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
  },
  props: {
    projectId: { type: String },
    projectName: { type: String },
    dateRange: { type: Array },
  },
  data() {
    return {
      confirmationLoading: false,
      pdfLoading: false,
      renderTableWithConflicts: true,
      activeTab: "not_ok",
      toResolveTab: "",
      noConflictsTab: "",
      confirmedTabs: {
        not_ok: false,
        is_ok: false,
      },
      showNoChangesInfo: false,
      reportVisible: false,
      successVisible: false,
      replaceVisible: false,
      allOptionsAvailable: false,
      reportFetched: false,
      newDateRange: null,
      pickableResources: [],
      alreadySelectedResources: [],
      selectedReplacement: "",
      selectedProject: "",
      selectedRecords: [],
      conflictProjectOptions: [],
      actionType: "RELEASE",
      toResolve: {
        employee: [],
        machine: [],
        rhb: [],
        vehicle: [],
        supply: [],
      },
      noConflicts: {
        employee: [],
        machine: [],
        rhb: [],
        vehicle: [],
        supply: [],
      },
      resourceType: null,
      replaceResourceName: "",
      currentAssignmentAttributes: [],
      resourceConflictAttributes: [],
      replacementDate: null,
      modelConfig: {
        start: {
          timeAdjust: "00:00:00",
        },
        end: {
          timeAdjust: "23:59:59.999",
        },
      },
    };
  },
  methods: {
    showModal() {
      this.reportVisible = true;
      this.newDateRange = this.dateRange.slice();
    },
    async fetchConfirmationReport() {
      this.selectedRecords = [];
      this.clearTableSelection();
      try {
        this.confirmationLoading = true;
        const payload = { dateRange: this.newDateRange, granularitySettings: this.granularitySettings };
        const response = await this.axios.put(`/api/projects/${this.projectId}/change-date-range`, payload);
        this.reportFetched = true;
        if (!response.data.length) {
          this.showNoChangesInfo = true;
          this.confirmedTabs.not_ok = true;
          this.confirmedTabs.is_ok = true;
          return;
        } else {
          this.showNoChangesInfo = false;
        }
        const uniqueResourceTypes = Object.keys(groupBy(response.data, "resourceType"));
        document.querySelector(".granularity-switchers").childNodes.forEach((node) => (node.hidden = true));
        uniqueResourceTypes.forEach((resourceType) => {
          const node = document.getElementById(`g-${resourceType}`);
          if (node) {
            node.hidden = false;
          }
        });
        this.toResolve.employee = [];
        this.toResolve.machine = [];
        this.toResolve.rhb = [];
        this.toResolve.vehicle = [];
        this.toResolve.supply = [];
        this.noConflicts.employee = [];
        this.noConflicts.machine = [];
        this.noConflicts.rhb = [];
        this.noConflicts.vehicle = [];
        this.noConflicts.supply = [];
        const { toResolve, noConflicts } = response.data.reduce(
          (buffer, record) => {
            if (record.isBusy) {
              buffer.toResolve.push(record);
            } else {
              buffer.noConflicts.push(record);
            }
            return buffer;
          },
          { toResolve: [], noConflicts: [] }
        );

        toResolve.forEach((item) => {
          this.toResolve[item.resourceType].push({
            ...item,
            resourceTypeLabel: resourceTypeToGerman[item.resourceType],
            isSkipped: false,
            changes: {},
          });
        });
        noConflicts.forEach((item) => {
          if (this.noConflicts[item.resourceType]) {
            this.noConflicts[item.resourceType].push({
              ...item,
              resourceTypeLabel: resourceTypeToGerman[item.resourceType],
            });
          }
        });
        if (!toResolve.length) {
          this.activeTab = "is_ok";
          this.confirmedTabs["not_ok"] = true;
        } else {
          this.activeTab = "not_ok";
        }
        const firstNotEmptyToResolveTab = Object.entries(this.toResolve).find(([key, list]) => list.length);
        if (firstNotEmptyToResolveTab) {
          this.toResolveTab = firstNotEmptyToResolveTab[0];
        }
        const firstNotEmptyNoConflictsTab = Object.entries(this.noConflicts).find(([key, list]) => list.length);
        if (firstNotEmptyNoConflictsTab) {
          this.noConflictsTab = firstNotEmptyNoConflictsTab[0];
        }
      } catch (error) {
        this.reportFetched = false;
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      } finally {
        this.confirmationLoading = false;
      }
    },
    dismissDateChange() {
      this.successVisible = false;
      this.reportVisible = false;
      this.activeTab = "not_ok";
      this.confirmedTabs = {
        not_ok: false,
        is_ok: false,
      };
      this.toResolveTab = "";
      this.noConflictsTab = "";
      this.newDateRange = null;
      this.toResolve.employee = [];
      this.toResolve.machine = [];
      this.toResolve.rhb = [];
      this.toResolve.vehicle = [];
      this.toResolve.supply = [];
      this.noConflicts.employee = [];
      this.noConflicts.machine = [];
      this.noConflicts.rhb = [];
      this.noConflicts.vehicle = [];
      this.noConflicts.supply = [];
      this.alreadySelectedResources = [];
      this.replacementDate = null;
      this.selectedRecords = [];
      this.clearTableSelection();
    },
    confirmTab(tabName) {
      if (tabName === "not_ok" && !this.confirmedTabs["is_ok"]) {
        this.activeTab = "is_ok";
      } else if (!this.confirmedTabs["not_ok"]) {
        this.activeTab = "not_ok";
      }
      this.confirmedTabs[tabName] = true;
    },
    async confirmDateChange() {
      if (!this.allTabsConfirmed) {
        return;
      }
      try {
        const payload = {
          dateRange: this.newDateRange,
          confirm: true,
          changes: [],
          granularitySettings: this.granularitySettings,
        };
        flatten(Object.values(this.toResolve)).forEach((record) => {
          const changeRecords = Object.values(record.changes);
          if (record.isSkipped) {
            const resourceId = record._id;
            const resourceType = record.resourceType;
            payload.changes.push({
              resourceId,
              resourceType,
              isSkipActions: true,
            });
          } else if (changeRecords.length) {
            const resourceId = record._id;
            const resourceType = record.resourceType;
            const isSkipped = record.isSkipped;
            payload.changes.push({
              resourceId,
              resourceType,
              isSkipActions: isSkipped,
              actions: changeRecords.map((item) => ({
                type: item.type,
                projectId: item.projectId,
                exchangeResourceId: item.resourceId,
                dateRange: this.replacementDate,
                inWholeProject: this.selectedProject !== this.projectId,
              })),
            });
          }
        });
        flatten(Object.values(this.noConflicts)).forEach((record) => {
          const resourceId = record._id;
          const resourceType = record.resourceType;
          const isSkipped = record.isSkipped;
          if (record.isSkipped) {
            payload.changes.push({
              resourceId,
              resourceType,
              isSkipActions: isSkipped,
            });
          }
        });
        // console.log("payload", payload);
        // return;
        const response = await this.axios.put(`/api/projects/${this.projectId}/change-date-range`, payload);
        // // debug
        // return;
        this.$emit("onChange", {
          dateRange: response.data.dateRange,
          constructionManagers: response.data.constructionManagers,
        });
        this.successVisible = true;
        Message.success("Datumsbereich des Projekts wurde geändert");
      } catch (error) {
        if (error.response && error.response.data) {
          const { code, message } = error.response.data;
          // Not all conflicts are resolved
          if (code === 10) {
            Message.error(`Der Konflikt für "${message.resource}" wird nicht gelöst`);
          } else if (code === 11) {
            const resourceRecord =
              this.toResolve[message.resourceType].find((item) => item._id === message.resourceId) || {};
            Message.error(`Die Substitution für "${resourceRecord.name}" deckt nicht den gesamten Datumsbereich ab`);
          }
        }
        throw error;
      }
    },
    handleReplaceResource(resourceType, resourceRecord) {
      this.replaceVisible = true;
      this.resourceType = resourceType;
      this.replaceResourceName = resourceRecord.name;
      this.resourceThatGetsReplaced = resourceRecord._id;
      // if a status event is in conflicts - only skip should be possible
      const allOptionsAvailable = !(resourceRecord.statusEvents && resourceRecord.statusEvents.length);
      this.allOptionsAvailable = allOptionsAvailable;
      this.fillCurrentAssignmentAttributes(resourceRecord._id, this.projectId);
      this.conflictProjectOptions = uniqBy(
        resourceRecord.projectEvents.map((item) => ({
          label: item.projectId.bvName,
          value: item.projectId.id,
        })),
        // .concat({ value: this.projectId, label: this.projectName }),
        "value"
      );
      const committedChanges = Object.values(resourceRecord.changes);
      if (committedChanges.length) {
        const firstChange = committedChanges[0];
        this.selectedProject = firstChange.projectId;
        this.actionType = firstChange.type;
        if (firstChange.resourceId) {
          this.selectedReplacement = firstChange.resourceId;
        }
        if (firstChange.dateRange) {
          this.replacementDate = { ...firstChange.dateRange };
        }
      } else {
        this.selectedProject = this.conflictProjectOptions[0] && this.conflictProjectOptions[0].value;
      }
      // fill in conflict date ranges (status and project events) as date picker attributes
      this.resourceConflictAttributes = []
        .concat(resourceRecord.projectEvents, resourceRecord.statusEvents)
        .filter(Boolean)
        .map((event) => {
          const record = {
            key: event.id,
            dates: null,
            order: 0,
            highlight: {
              color: "red",
              fillMode: null,
            },
            popover: { label: null },
          };
          if (event.statusType) {
            record.highlight.fillMode = "solid";
            record.popover.label = event.statusType.label;
          } else {
            record.highlight.fillMode = "outline";
            record.popover.label = event.projectId.bvName;
          }
          // if (moment(event.start).isAfter(this.newDateRange[0], "day")) {
          record.dates = {
            start: new Date(event.start),
            end: new Date(event.end),
          };
          // } else {
          // record.dates = {
          //   start: this.newDateRange[0],
          //   end: new Date(event.end),
          // };
          // }
          return record;
        });
    },
    dismissReplacement() {
      this.successVisible = false;
      this.allOptionsAvailable = true;
      this.resourceThatGetsReplaced = "";
      this.currentAssignmentAttributes = [];
      this.resourceConflictAttributes = [];
      this.selectedReplacement = "";
      this.selectedProject = "";
      this.replaceVisible = false;
      this.pickableResources = [];
      this.replacementDate = null;
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderTableWithConflicts = false;
      await this.$nextTick();
      this.renderTableWithConflicts = true;
    },
    async saveReplacement() {
      let hasDateSelected;
      if (this.$refs.replacementObserver) {
        hasDateSelected = await this.$refs.replacementDateObserver.validate();
      } else {
        hasDateSelected = true;
      }
      if (this.$refs.replaceByObserver) {
        const isValid = await this.$refs.replaceByObserver.validate();
        if (!isValid || !hasDateSelected) {
          return;
        }
      }
      if (!hasDateSelected) {
        return;
      }
      const toReplaceIdx = this.toResolve[this.resourceType].findIndex(
        (item) => item._id === this.resourceThatGetsReplaced
      );
      // remove previous resource from already selected to be available for next selections
      const previousReplacementId = get(
        this.toResolve[this.resourceType],
        `[${toReplaceIdx}].changes[${this.selectedProject}].resourceId`,
        undefined
      );
      if (previousReplacementId) {
        this.alreadySelectedResources = this.alreadySelectedResources.filter((item) => item !== previousReplacementId);
      }
      const changesRecord = this.toResolve[this.resourceType][toReplaceIdx].changes;
      this.toResolve[this.resourceType][toReplaceIdx].isSkipped = false;
      let namespace;
      if (this.selectedProject) {
        namespace = this.selectedProject;
      } else {
        namespace = new Date().getTime().toString();
      }
      changesRecord[namespace] = {
        type: this.actionType,
        projectId: this.selectedProject,
        inWholeProject: this.selectedProject !== this.projectId,
        dateRange: this.replacementDate,
      };

      if (this.actionType === "EXCHANGE") {
        const newResource = this.pickableResources.find((item) => item.id === this.selectedReplacement);
        changesRecord[this.selectedProject].resourceName = newResource.name;
        changesRecord[this.selectedProject].resourceId = newResource.id;
        this.alreadySelectedResources.push(newResource.id);
      }
      this.forceRerender();
      this.dismissReplacement();
    },
    // fetches all resource assignments for current project and display them in calendar
    async fillCurrentAssignmentAttributes(resourceId, projectId) {
      try {
        const response = await this.axios.get("/api/project-events/project", { params: { projectId, resourceId } });
        this.currentAssignmentAttributes = response.data.map((item) => ({
          key: item.id,
          dates: {
            start: item.start,
            end: item.end,
          },
          order: 0,
          highlight: {
            color: "indigo",
            fillMode: "outline",
          },
        }));
      } catch (error) {
        throw error;
      }
    },
    async fetchPickableResources() {
      const postPayload = {
        dateRange:
          this.selectedProject === this.projectId ? [this.replacementDate.start, this.replacementDate.end] : undefined,
        resourceId: this.resourceThatGetsReplaced,
        resourceType: this.resourceType,
        projectId: this.selectedProject,
        granularitySettings: this.granularitySettings,
      };
      const response = await this.axios.post(`/api/projects/resources-for-new-project-range`, postPayload);
      const noConflictsResources = flatten(Object.values(this.noConflicts)).map((item) => item._id);
      this.pickableResources = response.data
        // one resource should not replace multiple times
        .filter((item) => !this.alreadySelectedResources.includes(item.id) && !noConflictsResources.includes(item.id))
        .map((item) => ({
          ...item,
          id: item._id || item.id,
          name: item.name || `${item.firstName} ${item.lastName}`,
        }));
    },
    formatProjectEvents(record) {
      let text = "";
      record.projectEvents.forEach((event) => {
        let eventText = `<div>${event.projectId.bvName} \n(${moment(event.start).format("L")} - ${moment(
          event.end
        ).format("L")})</div>`;
        const changeRecord = get(record.changes, event.projectId.id, false);
        if (changeRecord) {
          eventText = `<div class="strike-through">${eventText}</div>`;
          if (changeRecord.type === "EXCHANGE") {
            eventText += `<div><span class="el-tag el-tag--small el-tag--info el-tag--dark">${changeRecord.resourceName}</span></div>`;
          }
        }
        text += `${eventText}`;
      });
      return text;
    },
    formatStatusEvents(records) {
      let text = "";
      records.forEach((record) => {
        text += `${record.statusType.label} \n(${moment(record.start).format("L")} - ${moment(record.end).format(
          "L"
        )})\n`;
      });
      return text;
    },
    handleChangeSelection(selection) {
      this.selectedRecords = selection.slice();
    },
    skipSelected() {
      const data = this.activeTab === "is_ok" ? this.noConflicts : this.toResolve;
      this.selectedRecords.forEach((record) => {
        const list = data[record.resourceType];
        const toReplaceIdx = list.findIndex((item) => item._id === record._id);
        const resourceRecord = list[toReplaceIdx];
        resourceRecord.isSkipped = true;
        resourceRecord.changes = {};
      });
      this.clearTableSelection();
    },
    clearTableSelection() {
      if (this.$refs.toResolveEmployee) {
        this.$refs.toResolveEmployee.clearSelection();
      }
      if (this.$refs.toResolveMachine) {
        this.$refs.toResolveMachine.clearSelection();
      }
      if (this.$refs.toResolveRhb) {
        this.$refs.toResolveRhb.clearSelection();
      }
      if (this.$refs.toResolveSupply) {
        this.$refs.toResolveSupply.clearSelection();
      }
      if (this.$refs.toResolveVehicle) {
        this.$refs.toResolveVehicle.clearSelection();
      }
      if (this.$refs.noConflictsEmployee) {
        this.$refs.noConflictsEmployee.clearSelection();
      }
      if (this.$refs.noConflictsMachine) {
        this.$refs.noConflictsMachine.clearSelection();
      }
      if (this.$refs.noConflictsRhb) {
        this.$refs.noConflictsRhb.clearSelection();
      }
      if (this.$refs.noConflictsVehicle) {
        this.$refs.noConflictsVehicle.clearSelection();
      }
    },
    releaseSelected() {
      this.selectedRecords.forEach((record) => {
        const toReplaceIdx = this.toResolve[record.resourceType].findIndex((item) => item._id === record._id);
        const resourceRecord = this.toResolve[record.resourceType][toReplaceIdx];
        const changesRecord = resourceRecord.changes;
        if (resourceRecord.projectEvents) {
          resourceRecord.projectEvents.forEach((evt) => {
            changesRecord[evt.projectId.id] = {
              type: "RELEASE",
              projectId: evt.projectId.id,
              inWholeProject: true,
            };
          });
        }
      });
      this.clearTableSelection();
    },
    async downloadPdfReport(e) {
      e.preventDefault();
      try {
        this.pdfLoading = true;
        // format payload
        const resolvedRecords = sortBy(
          flatten(Object.values(this.toResolve)).map((record) => {
            return {
              name: record.name,
              resourceTypeLabel: record.resourceTypeLabel,
              isSkipped: record.isSkipped,
              projectChanges:
                record.projectEvents &&
                record.projectEvents.map((evt) => {
                  const change = record.changes[evt.projectId.id];
                  return {
                    type: record.isSkipped ? "SKIP" : change && change.type,
                    name: evt.projectId.bvName,
                    newResourceName: record.isSkipped ? "" : change && change.resourceName,
                  };
                }),
              statusEvents:
                record.projectEvents &&
                record.statusEvents.map((evt) => {
                  return {
                    name: evt.statusType.label,
                    dateRange: `${moment(evt.start).format("L")} - ${moment(evt.end).format("L")}`,
                  };
                }),
            };
          }),
          "resourceTypeLabel"
        );
        const noConflictRecords = sortBy(
          flatten(Object.values(this.noConflicts)).map((item) => ({
            name: item.name,
            resourceTypeLabel: item.resourceTypeLabel,
          })),
          "resourceTypeLabel"
        );
        const payload = {
          newDateRange: `${moment(this.newDateRange[0]).format("L")}-${moment(this.newDateRange[1]).format("L")}`,
          projectName: this.projectName,
          resolvedRecords,
          noConflictRecords,
        };
        // send request
        const response = await this.axios.request({
          method: "POST",
          url: "/api/projects/get-date-change-pdf-report",
          data: payload,
          responseType: "blob",
        });
        const file = new File([response.data], "report.pdf", { type: "application/octet-stream" });
        saveAs(file, file.name);
      } catch (error) {
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      } finally {
        this.pdfLoading = false;
      }
    },
    skipReplacement() {
      const toReplaceIdx = this.toResolve[this.resourceType].findIndex(
        (item) => item._id === this.resourceThatGetsReplaced
      );
      this.toResolve[this.resourceType][toReplaceIdx].isSkipped = true;
      this.toResolve[this.resourceType][toReplaceIdx].changes = {};
      this.dismissReplacement();
    },
  },
  computed: {
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    allTabsConfirmed() {
      return Object.values(this.confirmedTabs).every(Boolean);
    },
    replacementDateAttributes() {
      const attributes = [
        { key: "today", dot: { color: "green" }, dates: new Date(), order: 0, popover: { label: "Heute" } },
      ];
      if (this.currentAssignmentAttributes.length) {
        attributes.push(...this.currentAssignmentAttributes);
      }
      if (this.resourceConflictAttributes.length) {
        attributes.push(...this.resourceConflictAttributes);
      }
      return attributes;
    },
  },
  watch: {
    selectedProject(newVal) {
      if (newVal !== this.projectId) {
        this.replacementDate = null;
      }
    },
    actionType(newVal) {
      if (newVal === "EXCHANGE") {
        this.fetchPickableResources();
      } else {
        this.pickableResources = [];
      }
    },
    replaceVisible(newVal) {
      if (newVal && this.actionType === "EXCHANGE") {
        this.fetchPickableResources();
      }
    },
    replacementDate(newVal) {
      if (newVal && this.actionType === "EXCHANGE") {
        this.fetchPickableResources();
      }
    },
  },
  filters: {
    formatDateRange(dateRange) {
      return `${moment(dateRange.start).format("L")} - ${moment(dateRange.end).format("L")}`;
    },
  },
};
</script>

<style lang="scss">
.green-ok {
  font-size: 20px;
  color: #32ad81;
}

.granularity-switchers {
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;

  > div {
    &:empty {
      display: none;
    }

    display: inline-block;
    margin-right: 10px;
  }
}
</style>
