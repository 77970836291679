<template>
  <div style="height: 100%; padding-right: 8px">
    <el-tabs
      v-model="activePiPTab"
      type="card"
      v-if="project.id"
      class="tab-content-no-padding flex flex-column h100p tab-content-h100p"
    >
      <el-tab-pane
        v-if="!isNew && projectAccess.specificAccess.pickAndPack"
        :disabled="isNew || projectConfigurationManager.loading"
        label="Berichte"
        name="pick_pack_report"
      >
        <pickpack-list
          :isEditMode="isEditMode"
          :projectConfigurationManager="projectConfigurationManager"
          :project="project"
          :refreshPickPack="refreshPickPack"
          v-on:updateProject="updateProjectWithConfiguration"
          v-on:updateSuppliesProjectData="updateSuppliesProjectData"
          :accessRights="projectAccess.specificAccess.pickAndPack"
        />
      </el-tab-pane>

      <!-- v-if="!isNew && projectAccess.specificAccess.configuration" -->
      <el-tab-pane
        :label="$t('src.components.project.edit.configuration')"
        lazy
        v-if="!isNew && projectAccess.specificAccess.pickAndPack"
        :disabled="isNew || projectConfigurationManager.loading"
        name="configuration"
      >
        <project-configuration
          :projectConfigurationManager="projectConfigurationManager"
          :project="project"
          :updateProject="updateProjectWithConfiguration"
          :isDirty="isDirty"
          :isEditMode="isEditMode"
          v-on:switchToPickAndPack="switchToPickAndPack"
          :accessRights="projectAccess.specificAccess.pickAndPack"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Tabs, TabPane, Message, MessageBox } from "element-ui";
import ProjectConfiguration from "./ProjectConfiguration.vue";
import PickPackList from "./PickPack/PickPackList.vue";

export default {
  name: "pick-pack-tabs",
  components: {
    [ProjectConfiguration.name]: ProjectConfiguration,
    [PickPackList.name]: PickPackList,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  props: {
    project: Object,
    projectAccess: Object,
    isEditMode: Boolean,
    isNew: Boolean,
    isDirty: Boolean,
    refreshPickPack: Boolean,
    projectConfigurationManager: Object,
    updateProjectWithConfiguration: Function,
    updateSuppliesProjectData: Function,
    switchToPickAndPack: Function,
  },
  data() {
    return {
      activePiPTab: "pick_pack_report",
    };
  },
};
</script>
