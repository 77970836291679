<template>
  <el-tooltip content="Upload Prozess wurde gestartet" placement="top">
    <div class="pending-icon">
      <progress-upload-icon class="pulse icon2x mb-2" :size="48" />
      <div>{{ formattedProgress }}</div>
    </div>
  </el-tooltip>
</template>

<script>
import { Tooltip } from "element-ui";
import ProgressUploadIcon from "vue-material-design-icons/ProgressUpload.vue";

export default {
  name: "pending-icon",
  components: {
    [Tooltip.name]: Tooltip,
    ProgressUploadIcon,
  },
  props: {
    progress: Number,
  },
  computed: {
    formattedProgress() {
      console.log("progress", this.progress);
      if (this.progress && this.progress !== 100) {
        return `(...${Math.floor(this.progress)}%)`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
