<template>
  <el-card>
    <el-form
      :disabled="!isEditMode"
      :inline="true"
      :model="serviceRecord"
      :rules="rules"
      ref="serviceForm"
      data-testid="logged_hours_form"
      v-if="isEditMode"
    >
      <el-form-item prop="hoursWorked">
        <el-input style="width: 210px" min="1" type="number" v-model="serviceRecord.hoursWorked">
          <template v-slot:append>{{ $t("src.components.machine.components.loggedhours.stunden") }}</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t("src.components.machine.components.loggedhours.hinzufgen")
        }}</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="loggedHours" style="width: 100%">
      <el-table-column
        type="index"
        :label="$t('src.components.machine.components.loggedhours.nr')"
        width="60"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.loggedhours.datum')"
        prop="date"
        :formatter="dateFormatter"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.loggedhours.maschinenstunden')"
        prop="hoursWorked"
      ></el-table-column>
      <el-table-column
        :label="$t('src.components.machine.components.loggedhours.aktion')"
        width="150"
        v-if="isEditMode"
      >
        <template v-slot="scope">
          <el-button v-if="isEditMode" size="mini" @click="handleEdit(scope.$index)" icon="el-icon-edit" />
          <el-button
            v-if="isEditMode"
            size="mini"
            type="danger"
            @click="handleDeleteRecord(scope.$index)"
            icon="el-icon-delete"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="$t('src.components.machine.components.loggedhours.bearbeiten')" :visible.sync="editFormVisible">
      <el-form :rules="rules" ref="editFormRef" :model="editingRecord" :inline="true">
        <el-form-item :label="$t('src.components.machine.components.loggedhours.datum')" prop="date" label-width="90px">
          <pr-date-picker v-model="editingRecord.date" />
        </el-form-item>
        <el-form-item
          :label="$t('src.components.machine.components.loggedhours.stunden')"
          prop="hoursWorked"
          label-width="90px"
        >
          <el-input style="width: 210px" min="1" type="number" v-model="editingRecord.hoursWorked" />
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="editFormVisible = false">{{
            $t("src.components.machine.components.loggedhours.abbrechen")
          }}</el-button>
          <el-button type="primary" @click="saveChanges">{{
            $t("src.components.machine.components.loggedhours.speichern")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { moment } from "src/config/moment";
import { Input, Card, Table, Form, FormItem, Button, Dialog, DatePicker } from "element-ui";

export default {
  name: "logged-hours",
  components: {
    [Input.name]: Input,
    [Card.name]: Card,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Table.name]: Table,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
  },
  props: {
    loggedHours: { type: Array, default: () => [] },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editFormVisible: false,
      editingIdx: null,
      editingRecord: {
        hoursWorked: null,
        date: null,
      },
      serviceRecord: {
        date: null,
        hoursWorked: null,
      },
      rules: {
        hoursWorked: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        date: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.serviceForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        const { hoursWorked } = this.serviceRecord;
        const date = moment().startOf("day");
        this.loggedHours.push({ date, hoursWorked });
        this.$refs.serviceForm.resetFields();
      });
    },
    handleDeleteRecord(index) {
      this.loggedHours.splice(index, 1);
    },
    handleEdit(idx) {
      this.editingIdx = idx;
      this.editingRecord = { ...this.loggedHours[idx] };
      this.editFormVisible = true;
    },
    saveChanges() {
      // this.loggedHours[this.editingIdx] = { ...this.editingRecord };
      this.$set(this.loggedHours, this.editingIdx, { ...this.editingRecord });
      this.editFormVisible = false;
    },
    dateFormatter(record, column) {
      return moment(record.date).format("L");
    },
  },
  watch: {
    editFormVisible: function (newVal, oldVal) {
      if (!newVal) {
        this.editingIdx = null;
        this.$refs.editFormRef.resetFields();
        this.editingRecord = { hoursWorked: null, date: null };
        this.$refs.editFormRef.clearValidate(["hoursWorked", "date"]);
      }
    },
  },
};
</script>

<style></style>
