<template>
  <div>
    <span v-if="!noTitle" class="n-profile-label-title">{{ title }}:</span>
    <div :class="{ 'mt-1': !noTitle }">
      <el-switch
        v-if="editMode"
        :style="{ height: height }"
        :active-color="colorizeActive ? baseActiveColor : activeColor"
        :inactive-color="colorizeActive ? baseInactiveColor : inactiveColor"
        v-bind="$attrs"
        v-model="input"
        :data-testid="name"
      ></el-switch>
      <template v-else>
        <template v-if="value === true"> Ja </template>
        <template v-else>{{ $t("src.components.uicomponents.inputs.profileswitch.nein") }}</template>
      </template>
    </div>
  </div>
</template>

<script>
import { Switch } from "element-ui";

export default {
  name: "profile-switch",
  components: {
    [Switch.name]: Switch,
  },
  props: {
    value: {
      type: Boolean,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    activeColor: {
      type: String,
    },
    inactiveColor: {
      type: String,
    },
    title: {
      type: String,
      default: "Titel",
    },
    colorizeActive: {
      default: true,
      type: Boolean, // shortcut for active/inactive color. true - green, false - red
    },
    name: { type: String },
    height: { type: [Number, String] },
    editMode: { type: Boolean, default: true },
  },
  data() {
    return {
      baseActiveColor: "#18ce68",
      baseInactiveColor: "#ff6868",
    };
  },
  computed: {
    input: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
        this.$emit("change", newValue);
      },
    },
  },
};
</script>

<style></style>
