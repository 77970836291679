<template>
  <div>
    <div class="card card-user">
      <div class="card-header">
        <h6 class="title text-center">
          {{ $t("src.components.project.contacts.contacts.ansprechpartner") }}
        </h6>
      </div>
      <div class="card-body ps-height" style="padding: 0">
        <el-tabs v-model="activeTab" type="card" class="contacts-tabs">
          <el-tab-pane
            :label="$t('src.components.project.contacts.contacts.auftraggeber')"
            name="1"
            style="padding: 0 10px"
          >
            <h5 class="title">{{ $t("src.components.project.contacts.contacts.auftraggeber") }}</h5>
            <perfect-scrollbar :options="{ suppressScrollX: true }" style="padding-right: 10px">
              <!-- Bereichsleiter -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.client.areaManager"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.bereichsleiter')"
                :disabled="!computedEditMode"
                @setContact="setContact('client.areaManager', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <!-- Bereichsleiter -->
              <!-- Bauleiter -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.client.siteManager"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.bauleiter')"
                :disabled="!computedEditMode"
                @setContact="setContact('client.siteManager', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <!-- Bauleiter -->
              <!-- Polier -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.client.foreman"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.polier')"
                :disabled="!computedEditMode"
                @setContact="setContact('client.foreman', $event)"
                @deleteFile="addFileToDelete"
              />
              <!-- Polier -->
            </perfect-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('src.components.project.contacts.contacts.hauptAuftraggeber')"
            name="mainClient"
            style="padding: 0 10px"
          >
            <h5 class="title">{{ $t("src.components.project.contacts.contacts.hauptAuftraggeber") }}</h5>
            <perfect-scrollbar :options="{ suppressScrollX: true }" style="padding-right: 10px">
              <!-- Bereichsleiter -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.mainClient.areaManager"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.bereichsleiter')"
                :disabled="!computedEditMode"
                @setContact="setContact('mainClient.areaManager', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <!-- Bereichsleiter -->
              <!-- Bauleiter -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.mainClient.siteManager"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.bauleiter')"
                :disabled="!computedEditMode"
                @setContact="setContact('mainClient.siteManager', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <!-- Bauleiter -->
              <!-- Polier -->
              <contacts-record
                :projectId="projectId"
                :allContacts="allContacts"
                :contactData="contacts.mainClient.foreman"
                :title="$t('src.components.project.contacts.contacts.polier')"
                :disabled="!computedEditMode"
                @setContact="setContact('mainClient.foreman', $event)"
                @deleteFile="addFileToDelete"
              />
            </perfect-scrollbar>
            <!-- Polier -->
          </el-tab-pane>
          <el-tab-pane
            :label="$t('src.components.project.contacts.contacts.bauherr')"
            name="owner"
            style="padding: 0 10px"
          >
            <h5 class="title">{{ $t("src.components.project.contacts.contacts.bauherr") }}</h5>
            <perfect-scrollbar :options="{ suppressScrollX: true }" style="padding-right: 10px">
              <!-- Bereichsleiter -->
              <contacts-record
                :contactData="contacts.owner.areaManager"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.bereichsleiter')"
                :disabled="!computedEditMode"
                @setContact="setContact('owner.areaManager', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <!-- Bereichsleiter -->
              <!-- Bauleiter -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.owner.siteManager"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.bauleiter')"
                :disabled="!computedEditMode"
                @setContact="setContact('owner.siteManager', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <!-- Bauleiter -->
              <!-- Polier -->
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.owner.planer"
                :title="$t('src.components.project.contacts.contacts.architektPlaner')"
                :allContacts="allContacts"
                :disabled="!computedEditMode"
                @setContact="setContact('owner.planer', $event)"
                @deleteFile="addFileToDelete"
              />
            </perfect-scrollbar>
            <!-- Polier -->
          </el-tab-pane>
          <el-tab-pane
            :label="$t('src.components.project.contacts.contacts.weitereAnsprechpartner')"
            name="furtherClients"
            style="padding: 0 10px"
          >
            <perfect-scrollbar :options="{ suppressScrollX: true }" style="padding-right: 10px">
              <div style="padding: 10px; margin: -10px 0 10px -10px; background: #ffb8b8">
                <contacts-record
                  :projectId="projectId"
                  :allContacts="allContacts"
                  :contactData="contacts.emergencyContacts"
                  :title="$t('src.components.project.contacts.contacts.werksNotfallNummer')"
                  :disabled="!computedEditMode"
                  @setContact="setContact('emergencyContacts', $event)"
                  @deleteFile="addFileToDelete"
                />
              </div>
              <h5 class="title">{{ $t("src.components.project.contacts.contacts.weitereAnsprechpartner") }}</h5>
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.furtherClients.fireBrigade"
                :title="$t('src.components.project.contacts.contacts.werksfeuerwehr')"
                :disabled="!computedEditMode"
                :allContacts="allContacts"
                @setContact="setContact('furtherClients.fireBrigade', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <contacts-record
                :projectId="projectId"
                :contactData="contacts.furtherClients.security"
                :allContacts="allContacts"
                :title="$t('src.components.project.contacts.contacts.werkschutz')"
                :disabled="!computedEditMode"
                @setContact="setContact('furtherClients.security', $event)"
                @deleteFile="addFileToDelete"
              />
              <hr style="margin: 1rem 0" />
              <service-providers-list
                :projectId="projectId"
                :contactDataList="contacts.furtherClients.serviceProviders"
                :disabled="!computedEditMode"
                :addFreeText="true"
                @deleteFile="addFileToDelete"
              />
            </perfect-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { set, sortBy } from "lodash";
import { Tabs, TabPane, Dialog } from "element-ui";
import { isDataChanged } from "src/utils/isDataChanged";
import { unlink } from "src/utils/unlink";
import ContactsRecord from "./Components/ContactsRecord";
import ServiceProvidersList from "./Components/ServiceProvidersList";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

const INITIAL_CONTACTS = () => ({
  client: {
    areaManager: {},
    siteManager: {},
    foreman: {},
  },
  mainClient: {
    areaManager: {},
    siteManager: {},
    foreman: {},
  },
  owner: {
    areaManager: {},
    siteManager: {},
    planer: {},
  },
  furtherClients: {
    serviceProviders: [],
    fireBrigade: {},
    security: {},
  },
  emergencyContacts: {},
  initial: true,
});

export default {
  name: "contacts",
  props: {
    allContacts: { type: Array, required: true },
    accessRights: String,
    projectId: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PerfectScrollbar,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [ContactsRecord.name]: ContactsRecord,
    [ServiceProvidersList.name]: ServiceProvidersList,
  },
  data() {
    return {
      activeTab: "1",
      contacts: INITIAL_CONTACTS(),
      // when user deletes file - we don't send request right away.
      // Insead we store all urls and send delete requests when Project gets saved.
      filesUrlsToDelete: [],
      contactsSample: null,
    };
  },
  async mounted() {
    this.fetchContactsForProject();
  },
  // created() {
  //   this.debounceUpdateContacts = debounce(this.handleUpdateContacts, 1000);
  // },
  methods: {
    async fetchContactsForProject() {
      if (this.isNewProject) {
        this.contacts = INITIAL_CONTACTS();
      } else {
        try {
          const response = await this.axios.get(`/api/project-contacts/${this.projectId}`);
          this.contacts = { ...this.contacts, ...response.data };
          this.contactsSample = unlink(this.contacts);
          this.isDirty = false;
          this.$nextTick(() => {});
        } catch (error) {
          throw error;
        }
      }
    },
    setContact(namespace, contactData) {
      set(this.contacts, namespace, contactData);
    },
    addFileToDelete(fileUrl) {
      this.filesUrlsToDelete.push(fileUrl);
    },
    async submitData() {
      if (!this.projectId) {
        throw new Error("No projectId = no save of contacts!");
      }
      const response = await this.axios.post(`/api/project-contacts/${this.projectId}`, this.contacts);
      this.contacts = response.data;
      this.contactsSample = unlink(response.data);
      this.filesUrlsToDelete.map((url) => this.axios.delete(url));
      this.filesUrlsToDelete = [];
    },
    getIsModified() {
      return isDataChanged(this.contacts, this.contactsSample);
    },
  },
  computed: {
    isNewProject() {
      return this.$route.params.id === "new";
    },
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
  watch: {},
};
</script>

<style>
.ps-height .ps {
  height: calc(100vh - 355px);
}
.el-tabs.contacts-tabs .el-tabs__content {
  padding-top: 0;
}
</style>
