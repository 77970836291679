<template>
  <div>
    <el-row type="flex">
      <el-col :span="20">
        <!-- <div class="n-view-title">{{ $t("src.components.management.companysettings.workshopprojects.werkstatt") }}</div> -->
      </el-col>

      <el-col :span="4" class="text-right">
        <el-button type="primary" @click="createWorkshop" v-if="isEditMode">
          <span style="margin-right: 5px"> <plus-icon /> </span
          >{{ $t("src.components.management.companysettings.workshopprojects.neueWerkstatt") }}</el-button
        >
      </el-col>
    </el-row>
    <el-table v-loading="loading" :data="workshops" style="width: 100%" size="small">
      <el-table-column :label="$t('src.components.management.companysettings.workshopprojects.name')" prop="bvName">
      </el-table-column>
      <el-table-column
        :label="$t('src.components.management.companysettings.workshopprojects.zeitraum')"
        width="220"
        prop="dateRange"
        :formatter="dateRangeFormatter"
      >
      </el-table-column>
      <el-table-column class-name="action-buttons td-actions" width="140" v-if="isEditMode">
        <template v-slot="props">
          <el-button class="btn btn-ghost" v-show="$can('update', 'project')" @click.prevent="editWorkshop(props.row)">
            <pencil-icon />
          </el-button>
          <el-button
            class="btn btn-sm btn-ghost"
            v-show="!props.row.alwaysExists && $can('delete', 'project')"
            @click.prevent="removeWorkshop(props.row)"
          >
            <trash-can-outline-icon />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="modalTitle" width="500px" :visible.sync="modalVisible" @closed="dismissModal">
      <el-form ref="form" label-position="top" :model="editingWorkshop" :rules="formDataRules">
        <div style="display: flex; flex-wrap: nowrap">
          <div style="margin-right: 20px">
            <el-form-item
              :label="$t('src.components.management.companysettings.workshopprojects.active')"
              prop="active"
            >
              <el-switch v-model="editingWorkshop.active" :disabled="editingWorkshop.alwaysExists" />
            </el-form-item>
          </div>
          <div style="flex-grow: 1">
            <el-form-item :label="$t('src.components.management.companysettings.workshopprojects.name')" prop="bvName">
              <el-input
                :placeholder="$t('src.components.management.companysettings.workshopprojects.name')"
                v-model="editingWorkshop.bvName"
              />
            </el-form-item>
          </div>
        </div>
        <div style="display: flex; flex-wrap: nowrap">
          <el-form-item
            :label="$t('src.components.management.companysettings.workshopprojects.zeitraum')"
            prop="dateRange"
          >
            <pr-date-picker
              style="width: 100%"
              is-range
              :placeholder="$t('src.components.management.companysettings.workshopprojects.zeitraum')"
              v-model="editingWorkshop.dateRange"
              :disabled="editingWorkshop.noDateRange"
            />
          </el-form-item>
          <el-form-item
            :label="$t('src.components.management.companysettings.workshopprojects.4177')"
            class="label-white"
            prop="noDateRange"
            style="margin-left: 10px; width: 255px"
          >
            <el-switch v-model="editingWorkshop.noDateRange" />{{
              $t("src.components.management.companysettings.workshopprojects.zeitlichUnbegrenzt")
            }}</el-form-item
          >
        </div>
      </el-form>

      <template v-slot:footer>
        <span class="flex justify-between">
          <cancel-button
            :text="$t('src.components.management.companysettings.workshopprojects.abbrechen')"
            @click="modalVisible = false"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.companysettings.workshopprojects.speichern')"
            :loading="formLoading"
            @click="submitForm"
          ></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  Message,
  MessageBox,
  Dialog,
  Input,
  Table,
  TableColumn,
  DatePicker,
  Form,
  FormItem,
  Button,
  Switch,
} from "element-ui";
import { moment } from "src/config/moment";
import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import Plus from "vue-material-design-icons/Plus";
import { get } from "lodash";

export default {
  name: "workshop-projects",
  components: {
    [Switch.name]: Switch,
    Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
    [Plus.name]: Plus,
    [Message.name]: Message,
    [MessageBox.name]: MessageBox,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    isEditMode: { type: Boolean },
  },
  data() {
    const validateDateRange = (rule, value, callback) => {
      if (this.editingWorkshop.noDateRange) {
        return callback();
      }
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum auswählen"));
      }
      return callback();
    };
    return {
      formLoading: false,
      loading: false,
      workshops: [],
      editingWorkshop: { active: true },
      modalVisible: false,
      formDataRules: {
        bvName: {
          required: true,
          message: "Bitte Name auswählen",
          trigger: "change",
        },
        dateRange: {
          required: true,
          message: "Bitte Zeitraum auswählen",
          validator: validateDateRange,
          trigger: "change",
        },
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/workshops");
        this.workshops = response.data;
      } catch (error) {
        const message = get(error, "response.data.message");
        Message.error(message || error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    removeWorkshop(workshop) {
      MessageBox.confirm(`Werkstatt "${workshop.bvName}" löschen?`, "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
      })
        .then(async () => {
          try {
            await this.axios.delete(`/api/workshops/${workshop.id}`);
            await this.fetch();
          } catch (error) {
            const message = get(error, "response.data.message");
            Message.error(message || error.message);
            throw error;
          }
        })
        .catch(() => {});
    },
    editWorkshop(workshop) {
      if (workshop.noDateRange) {
        workshop.dateRange = undefined;
      }
      this.editingWorkshop = { ...workshop };
      this.modalVisible = true;
    },
    dateRangeFormatter(props) {
      if (props.noDateRange) {
        return "zeitlich unbegrenzt";
      }
      // if (props.alwaysExists) {
      //   return "N/A";
      // }
      if (props.dateRange && props.dateRange.length) {
        return `${moment(props.dateRange[0]).format("L")} - ${moment(props.dateRange[1]).format("L")}`;
      } else {
        return "N/A";
      }
    },
    createWorkshop() {
      this.modalVisible = true;
    },
    dismissModal() {
      this.editingWorkshop = { active: true };
      this.modalVisible = false;
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        try {
          const formData = { ...this.editingWorkshop };
          if (formData.dateRange) {
            formData.dateRange[1] = moment(formData.dateRange[1]).endOf("day");
          }
          this.formLoading = true;
          if (this.editingWorkshop.id) {
            await this.axios.put(`/api/workshops/${this.editingWorkshop.id}`, formData);
          } else {
            await this.axios.post(`/api/workshops`, formData);
          }
          this.fetch();
          this.$refs.form.resetFields();
          this.dismissModal();
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.formLoading = false;
        }
      });
    },
  },
  computed: {
    modalTitle() {
      return this.editingWorkshop.id
        ? this.$t("src.components.management.companysettings.workshopprojects.editWerkstatt")
        : this.$t("src.components.management.companysettings.workshopprojects.newWerkstatt");
    },
  },
  watch: {
    modalVisible(newVal, oldVal) {
      if (this.$refs.form && newVal && newVal !== oldVal) {
        this.$refs.form.clearValidate();
      }
    },
    editingWorkshop(newVal, oldVal) {
      if (newVal.noDateRange && oldVal.dateRange) {
        this.editingWorkshop.dateRange = undefined;
      }
    },
  },
};
</script>

<style>
.label-white.el-form-item label {
  color: #fff;
}
</style>
