<template></template>

<script>
export default {
  name: "Logout",
  created() {
    const searchParams = new URLSearchParams(window.location.search);
    localStorage.removeItem("PROJECT_CALENDAR_COLLAPSED");
    const redirectUri = searchParams.get("from");
    this.$keycloak.login({ redirectUri });
  },
};
</script>

<style>
</style>