<template>
  <type-settings
    :isEditMode="isEditMode"
    :modelid="modelID"
    :modelType="modelType"
    :type="type"
    :useSymbol="useSymbol"
    :useColors="useColors"
    :useAccessGroups="useAccessGroups"
    @dataChanged="handleDataChanged"
  />
</template>

<script>
import { TypeSettings } from "src/components/UIComponents";

import { Message, MessageBox } from "element-ui";

export default {
  name: "keyfigures",
  components: {
    Message,
    MessageBox,
    [TypeSettings.name]: TypeSettings,
  },
  props: {
    modelType: String, //type of calling component. e.g. employee
    modelID: String, //modelid in database entity
    type: String, //used e.g. as title or placeholder
    useSymbol: {
      type: Boolean,
      default: false,
    },
    useColors: {
      type: Boolean,
      default: false,
    },
    useAccessGroups: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDataChanged(data) {
      console.log('handleDataChanged', data);
      this.$emit("dataChanged", data);
    },
  },
};
</script>

<style></style>
