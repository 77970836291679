<template>
  <div class="granularity-wrapper b-box-item" :id="id || 'granularity_widget'" role="presentation" v-bind="$attrs">
    <div class="granularity-label" role="presentation">{{ label }}</div>
    <div
      :class="['granularity-widget', isConcurrent ? 'concurrent' : 'standard']"
      role="presentation"
      @click.prevent="setVisible(true)"
    >
      <div class="granularity-option standard" data-type="standard" role="presentation">
        <div class="granularity-row row-1" role="presentation"></div>
        <div class="granularity-row row-2" role="presentation"></div>
      </div>
      <div class="granularity-option concurrent" data-type="concurrent" role="presentation">
        <div class="granularity-row row-1" role="presentation"></div>
        <div class="granularity-row row-2" role="presentation"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const resourceLabelMap = {
  employee: "Mitarbeiter",
  machine: "Maschinen",
  vehicle: "KFZ",
  rhb: "RHB",
  supply: "Verbrauchsm.",
  subcontractor: "Nachunternehmer",
};

export default {
  name: "granularity-switcher",
  props: {
    showResourceNameInLabel: { type: Boolean },
    resourceType: { type: String, required: true },
    id: { type: String },
  },
  methods: {
    ...mapActions("granularitySettings", ["setVisible"]),
  },
  computed: {
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    label() {
      if (this.showResourceNameInLabel) {
        return `${resourceLabelMap[this.resourceType]} granularity:`;
      } else {
        return "Granularity:";
      }
    },
    isConcurrent() {
      return this.granularitySettings[this.resourceType] === "CONCURRENT";
    },
  },
};
</script>
