<template>
  <div :class="['todo-status', getStatusClass]" v-bind="$attrs">{{ getStatusLabel }}</div>
</template>

<script>
const statusClassMap = {
  TODO: "todo",
  IN_PROGRESS: "in-progress",
  DONE: "done",
};
const statusLabelMap = {
  TODO: "To do",
  IN_PROGRESS: "In progress",
  DONE: "Done",
};
export default {
  name: "todo-status",
  props: {
    status: { type: String },
  },
  computed: {
    getStatusClass() {
      return statusClassMap[this.status] || "todo";
    },
    getStatusLabel() {
      return this.$t(statusLabelMap[this.status] || "To do");
    },
  },
};
</script>

<style>
</style>