<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.rhbSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Stammdaten"
        v-model="value.general"
        :withDelete="false"
        :description="$t('userGroupProfile.rhb.general')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Status"
        v-model="value.status"
        :description="$t('userGroupProfile.rhb.status')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Dokumente"
        v-model="value.documents"
        :description="$t('userGroupProfile.rhb.documents')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Sourcing"
        v-model="value.sourcing"
        :description="$t('userGroupProfile.rhb.sourcing')"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsRow from "./AccessRightsRow.vue";
import AccessRightsTable from "./AccessRightsTable.vue";

export default {
  name: "rhb-profile-access",
  components: { AccessRightsRow, AccessRightsTable },

  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style>
</style>