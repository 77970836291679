<template>
  <div class="card" style="flex-grow: 1; margin-bottom: 0">
    <!-- HEADER -->
    <el-row type="flex" :gutter="20">
      <!-- SEARCH -->
      <el-col :span="6">
        <div class="n-search-indextable">
          <el-input
            :placeholder="$t('src.components.uicomponents.helper.indextable.suche')"
            clearable
            v-model="searchQuery"
            suffix-icon="el-icon-search"
          >
          </el-input>
        </div>
      </el-col>
      <!-- SLOT FOR ADDITIONAL CONTROLS FROM PARENT -->
      <el-col :span="4" style="margin-top: 24px">
        <slot name="additional" />
      </el-col>

      <el-col :span="19" class="text-right" style="padding-top: 30px">
        <!-- AKTIV/INAKTIV FILTER -->
        <div style="padding-right: 40px">
          <!-- DESCRIPTION -->
          <el-checkbox-group v-model="active">
            <el-checkbox-button label="ACTIVE">Aktiv</el-checkbox-button>
            <el-checkbox-button label="INACTIVE">Inaktiv</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-col>
    </el-row>
    <div class="d-flex justify-content-end" style="padding-right: 40px">
      <el-checkbox-group v-model="activeFilters">
        <el-checkbox-button
          v-for="option in filterOptions"
          :label="option.value"
          :key="option.value"
          class="icon-larger"
        >
          <filter-type-icon v-if="option.value !== 'ALL'" :type="option.value" />
          {{ option.label }}</el-checkbox-button
        >
      </el-checkbox-group>
    </div>
    <!-- TABLE -->
    <el-table
      :data="queriedData"
      style="width: 100%"
      height="calc(100vh - 272px)"
      v-loading="loading"
      element-loading-text="Lade Daten..."
      :default-sort="defaultSort"
      @sort-change="handleSortChange"
    >
      <el-table-column :width="60" align="center">
        <template v-slot:header>
          <span class="n-table-header"></span>
        </template>
        <template v-slot="props">
          <project-color :color="props.row.active ? '#46A19C' : '#333'" position="left" />
          <filter-type-icon :type="props.row.filterType" />
        </template>
      </el-table-column>
      <!-- VARIABLE CONTENT FROM CALLING COMPONENT -->
      <el-table-column
        sortable
        :width="column.width"
        :min-width="column.minWidth"
        v-for="column in extendedColumns"
        :key="column.label"
        :label="column.label"
        :prop="column.key"
        :sort-by="column.key"
        :sort-method="column.sortMethod || defaultSortMethod(column.key)"
      >
        <!-- HEADER -->
        <template v-slot:header>
          <span class="n-table-header">{{ column.label }}</span>
        </template>

        <!-- CELL CONTENT -->
        <template v-slot="props">
          <slot :name="column.label" :data="props"></slot>
        </template>
      </el-table-column>

      <!-- Action Buttons -->
      <el-table-column align="right" fixed="right" class-name="action-buttons td-actions" width="160">
        <template v-slot="props">
          <template v-if="modelType === 'project' && props.row.isWorkshop">
            <router-link class="btn btn-sm btn-ghost" :to="`/project/profile/workshop/${props.row.id}`">
              <eye-outline-icon />
            </router-link>
          </template>
          <template v-else>
            <slot name="extended_actions" :props="props" />
            <router-link
              v-if="$can('update', authSubject)"
              class="btn btn-ghost"
              :to="getEditUrl(props.row.id)"
              data-testid="edit_item"
            >
              <pencil-icon />
            </router-link>
            <router-link
              v-if="showViewButton && $can('read', authSubject)"
              class="btn btn-sm btn-ghost"
              :to="getViewUrl(props.row.id)"
              data-testid="view_item"
            >
              <eye-outline-icon />
            </router-link>
            <el-button
              v-if="$can('delete', authSubject) && !hideRemoveAction"
              class="btn btn-sm btn-ghost"
              @click.prevent="removeModel(props)"
              data-testid="delete_item"
            >
              <trash-can-outline-icon />
            </el-button>
          </template>
          <project-color :color="props.row.statusColor" position="right" />
        </template>
      </el-table-column>
      <infinite-loading
        slot="append"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        force-use-infinite-wrapper=".el-table__body-wrapper"
        :distance="300"
      >
        <template v-slot:no-more>
          <span></span>
        </template>
      </infinite-loading>
    </el-table>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Message,
  MessageBox,
  CheckboxGroup,
  CheckboxButton,
  RadioGroup,
  RadioButton,
} from "element-ui";
import * as moment from "moment";
import { indexOf, groupBy, get, debounce, sortBy, reverse } from "lodash";
import EyeIcon from "vue-material-design-icons/EyeOutline";
import InfiniteLoading from "vue-infinite-loading";
import { mapState } from "vuex";
import ProjectColor from "../../Project/ProjectColor.vue";
import { until } from "src/utils/until";
import FilterTypeIcon from "./FilterTypeIcon.vue";

export const SEARCH_QUERY_NAMESPACE = "indexTableSearchQuery";

// used to create urls to back-end
const modelTypeToUrlName = {
  machine: "machines",
  vehicle: "vehicles",
  project: "projects",
  employee: "employees",
  serviceProvider: "service-providers",
  subcontractor: "subcontractor",
};
// used to navigate through profile pages
function modelTypeToAppPathname(modelType) {
  if (modelType === "serviceProvider" || modelType === "subcontractor") {
    return `purchasing/${modelType}`;
  } else if (modelType === "users" || modelType === "user-groups") {
    return `administration/${modelType}`;
  } else {
    return `${modelType}`;
  }
}

export default {
  name: "project-index-table",
  props: {
    defaultSort: { type: Object },
    authSubject: {
      type: String,
      default: "none",
    },
    hideRemoveAction: { type: Boolean },
    showViewButton: {
      type: Boolean,
      default: true,
    },
    modelType: String,
    extendedColumns: Array,
    supressColumns: {
      type: Array,
      default: () => [],
    },
    supressEntities: {
      type: Object,
      default: () => {},
    },
    propsToSearch: {
      type: Array,
      default: () => ["name"],
    },
    rowClassName: {
      type: Function,
    },
    normalizeResponse: {
      type: Function,
    },
  },
  components: {
    InfiniteLoading,
    ProjectColor,
    FilterTypeIcon,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [EyeIcon.name]: EyeIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Message.name]: Message,
    [MessageBox.name]: MessageBox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
  },
  data() {
    return {
      filtersNotInitialized: true,
      active: ["ACTIVE"],
      loading: false,
      dataTable: [], // all data received from DB
      projectsMeta: [],
      projectEventsMap: {},
      searchQuery: "",
      filteredData: [], // data filtered by "active" filter
      queriedData: [], // data filtered by search query
      activeFilters: ["RUNNING", "FUTURE"], // used to filter records when "tabMode" is set
      PAGE: 1, // gets incremented by infinite loader to return next slice of list
      OFFSET: 50, // amount of records that are returned within one page
      infiniteId: 0,
      filterOptions: [
        { label: "Laufende", value: "RUNNING" },
        { label: "Zukünftig", value: "FUTURE" },
        { label: "Vergangene", value: "PREVIOUS" },
        { label: "Alle", value: "ALL" },
      ],
    };
  },
  async mounted() {
    const initialFiltersValue = sessionStorage.getItem(this.filtersKey);
    // check if returned from Profile view to preserve search filter query
    if (new RegExp(`^\/${this.modelType}\/(view|profile)\/(.*)`, "g").test(this.routeState.from.path)) {
      const preservedSearchQuery = sessionStorage.getItem(SEARCH_QUERY_NAMESPACE);
      this.searchQuery = preservedSearchQuery;
      const initialActiveFilterValue = sessionStorage.getItem(this.activeFilterKey);
      if (initialActiveFilterValue !== null) {
        this.active = JSON.parse(initialActiveFilterValue);
      }
      if (initialFiltersValue !== null && JSON.parse(initialFiltersValue).length) {
        this.activeFilters = JSON.parse(initialFiltersValue);
      }
    } else {
      // or drop saved value
      sessionStorage.removeItem(SEARCH_QUERY_NAMESPACE);
      sessionStorage.removeItem(this.activeFilterKey);
      sessionStorage.removeItem(this.filtersKey);
    }
    await until(() => this.accessRightsLoaded);
    if (this.hasAccessToWorkshopProjects) {
      this.filterOptions.unshift({ label: "Dauer-Projekte", value: "IS_WORKSHOP" });
      if (!initialFiltersValue) {
        this.activeFilters.push("IS_WORKSHOP");
      }
    }
    this.filtersNotInitialized = false;
    await this.initTable();
    this.$root.$on("refetch_indexTable", this.initTable);
  },
  created() {
    this.debounceFilterBySearchQuery = debounce(this.filterBySearchQuery, 300);
    this.debounceDiscardInfiniteScroll = debounce(this.discardInfiniteScroll, 300);
    this.debounceFilterData = debounce(this.filterData, 200);
  },
  beforeDestroy() {
    this.$root.$off("refetch_indexTable", this.initTable);
  },
  computed: {
    ...mapState({ routeState: (state) => state.route }),
    ...mapState("account", { accessRights: "accessRights", accessRightsLoaded: "accessRightsLoaded" }),
    hasCalendarAccess() {
      const access = get(this.accessRights, "calendar", { specificAccess: {}, generalAccess: null });
      return !!access.generalAccess;
    },
    hasAccessToWorkshopProjects() {
      const access = get(this.accessRights, "workshop_projects.generalAccess", null);
      return !!access;
    },
    activeFilterKey() {
      return `activeFilter`;
    },
    filtersKey() {
      return `filters`;
    },
    filterOptionsMap() {
      return this.filterOptions.reduce((buff, current) => {
        buff[current.value] = current.label;
        return buff;
      }, {});
    },
  },
  methods: {
    handleSortChange({ column, prop, order }) {
      console.log("sort", { column, prop, order });
      const columnProps = this.extendedColumns.find((item) => item.key === prop);
      this.dataTable.sort(columnProps.sortMethod);
      if (order === "descending") {
        reverse(this.dataTable);
      }
      console.log(
        "this.dataTable",
        this.dataTable.map((i) => i.bvName)
      );
      this.$nextTick(() => {
        this.filterData();
      });
    },
    updateTableData(newData) {
      this.dataTable = JSON.parse(JSON.stringify(newData));
      this.$nextTick(() => {
        this.filterData();
      });
    },
    async initTable() {
      try {
        this.loading = true;
        //get data from api REST call
        const projectMetaReq = this.axios
          .get("/api/projects/meta")
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            Message({ type: "error", message: error.message });
          });
        const params = { include_managers: true };

        if (this.hasAccessToWorkshopProjects) {
          params.include_workshop = true;
        }
        const modelTypeReq = this.axios
          .get("/api/projects", { params })
          .then((response) => {
            if (this.normalizeResponse && response.data.length) {
              return this.normalizeResponse(response.data);
            } else {
              return response.data;
            }
          })
          .catch((error) => {
            Message({
              message: error.message,
              type: "error",
            });
            throw error;
          });

        const fetchRequests = [projectMetaReq, modelTypeReq];

        await Promise.all(fetchRequests)
          .then((responses) => {
            const [projectMetaData, modelTypeData] = responses;
            // provide each model with status events data
            let normalizedModelTypeData;
            normalizedModelTypeData = modelTypeData;
            this.projectsMeta = projectMetaData;
            if (this.defaultSort) {
              const sortMethod = this.extendedColumns.find((item) => item.key === this.defaultSort.prop).sortMethod;

              normalizedModelTypeData.sort(sortMethod || this.defaultSortMethod(this.defaultSort.prop));
              if (this.defaultSort.order === "descending") {
                reverse(normalizedModelTypeData);
              }
            }
            this.dataTable = normalizedModelTypeData;

            this.$emit("tableFetched", modelTypeData);

            this.filterData();
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    filterData() {
      this.filteredData = this.dataTable.slice();

      this.filterBySearchQuery();
      this.discardInfiniteScroll();
    },
    async removeModel(props) {
      let self = this;

      MessageBox.confirm("wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(async function () {
        const modelName = modelTypeToUrlName[self.modelType] || self.modelType;

        await self.axios
          .delete(`/api/${modelName}/${props.row.id}`)
          .then(function (entities) {
            Message({ message: "erfolgreich gelöscht", type: "success" });

            //remove from table
            let entity = self.dataTable.find((e) => e.id == props.row.id);
            let index = indexOf(self.dataTable, entity);
            self.dataTable.splice(index, 1);
          })
          .then(self.initTable)
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.message === "HAS_ASSIGNED_RESOURCES") {
              const requestDelete = (releaseResources) =>
                self.axios.delete("/api/projects/" + props.row.id, { params: { releaseResources } });
              MessageBox.confirm("Zugewiesene Ressourcen freigeben?", {
                confirmButtonText: "Ja",
                cancelButtonText: "Nein",
                type: "warning",
              })
                .then(() => requestDelete(true))
                .then(self.initTable)
                .catch((err) => {
                  // Message({
                  //   message: err.message,
                  //   type: "error"
                  // });
                });
            } else {
              Message({
                message: error.message,
                type: "error",
              });
            }
          });

        self.filterData();
        self.$emit("itemRemoved", props.row.id);
      });
    },
    defaultSortMethod(prop) {
      return function (a, b) {
        let aVal = get(a, prop, ""),
          bVal = get(b, prop, "");
        aVal = aVal && aVal.toString().toLowerCase();
        bVal = bVal && bVal.toString().toLowerCase();
        if (aVal > bVal) {
          return 1;
        } else if (aVal < bVal) {
          return -1;
        } else {
          return 1;
        }
      };
    },
    getFormatedDate(date) {
      if (date) {
        try {
          return moment(date).format("DD.MM.YYYY");
        } catch (e) {
          console.log(e);
        }
      } else {
        return "N/A";
      }
    },
    async loadProjectEvents() {
      let self = this;
      return await this.axios
        .get("/api/project-events/project", { params: { excludeOld: true, resourceType: self.modelType } })
        .then(function (result) {
          // transform list of events into map grouped by resource id
          return groupBy(result.data, "resourceId");
        })
        .catch(function (error) {
          Message({ message: error.message, type: "error" });
          return {};
        });
    },

    infiniteHandler($state) {
      if (!this.filteredData.length) {
        $state.complete();
        return;
      }
      this.PAGE += 1;
      $state.loaded();
      if (this.filteredData.length && this.PAGE * this.OFFSET >= this.filteredData.length) {
        $state.complete();
      }
    },
    discardInfiniteScroll() {
      this.PAGE = 1;
      this.infiniteId++;
    },
    filterBySearchQuery() {
      // preserve search query for case when return from profile to index view
      sessionStorage.setItem(SEARCH_QUERY_NAMESPACE, this.searchQuery);
      let result = [];
      if (this.active.length === 0) {
        this.queriedData = result;
        return;
      }
      this.activeFilters.forEach((filterType) => {
        switch (filterType) {
          case "IS_WORKSHOP":
            result.push(...this.filteredData.filter((item) => item.isWorkshop).map((i) => ({ ...i, filterType })));
            break;
          case "RUNNING": {
            const today = moment();
            result.push(
              ...this.filteredData
                .filter((item) => {
                  if (item.isWorkshop) {
                    return false;
                  }
                  return today.isBetween(item.dateRange[0], item.dateRange[1], "day", "[]");
                })
                .map((i) => ({ ...i, filterType }))
            );
            break;
          }
          case "FUTURE": {
            const today = moment();
            result.push(
              ...this.filteredData
                .filter((item) => !item.isWorkshop && today.isBefore(item.dateRange[0], "day"))
                .map((i) => ({ ...i, filterType }))
            );
            break;
          }
          case "PREVIOUS": {
            const today = moment();
            result.push(
              ...this.filteredData
                .filter((item) => !item.isWorkshop && today.isAfter(item.dateRange[1], "day"))
                .map((i) => ({ ...i, filterType }))
            );
            break;
          }
          default:
            break;
        }
      });

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        result = result.filter((entity) => {
          return this.propsToSearch.some((prop) => {
            const property = get(entity, prop);
            return property && property.toString().toLowerCase().indexOf(query) !== -1;
          });
        });
      }
      if (!result.length) {
        this.queriedData = [];
        return;
      }
      if (this.active.length === 1) {
        if (this.active[0] === "ACTIVE") {
          result = result.filter((i) => i.active === true);
        } else {
          result = result.filter((i) => i.active === false);
        }
      }
      result = result.slice(0, this.PAGE * this.OFFSET);
      this.queriedData = result;
    },
    getEditUrl(recordId) {
      return `/${modelTypeToAppPathname(this.modelType)}/profile/${recordId}`;
    },
    getViewUrl(recordId) {
      return `/${modelTypeToAppPathname(this.modelType)}/profile/view/${recordId}`;
    },
  },
  watch: {
    PAGE(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filterBySearchQuery();
      }
    },
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounceFilterBySearchQuery();
        this.debounceDiscardInfiniteScroll();
      }
    },
    activeFilters(newVal, oldVal) {
      if (this.filtersNotInitialized) {
        return;
      }
      console.log("activeFilters", this.filtersNotInitialized, newVal, oldVal);
      const allOptions = this.filterOptions.slice();
      // when checking "All" and some checkboxes are unchecked
      if (newVal.length !== allOptions.length) {
        const hasAll = newVal.includes("ALL");
        if (hasAll) {
          this.activeFilters = allOptions.map((i) => i.value);
        }
      }
      // when all options except "All" are selected - automatically select "All"
      if (newVal.length === allOptions.length - 1) {
        this.activeFilters = allOptions.map((i) => i.value);
      }
      if (oldVal.length === allOptions.length) {
        // when unchecking any option and "All" checkbox was checked - uncheck also "All" checkebox
        if (newVal.some((i) => i === "ALL")) {
          this.activeFilters = newVal.filter((i) => i !== "ALL");
        } else {
          // when unchecking "All" checkbox - uncheck all checkeboxes
          this.activeFilters = [];
        }
      }

      this.$nextTick(() => {
        sessionStorage.setItem(this.filtersKey, JSON.stringify(this.activeFilters));
        this.debounceFilterData();
      });
    },
    active(newFilter) {
      sessionStorage.setItem(this.activeFilterKey, JSON.stringify(newFilter));
      this.filterData();
    },
  },
};
</script>

<style lang="scss">
// to properly hide text behind fixed column (was transparent before)
.table-striped tbody .el-table__row:nth-of-type(odd) {
  background-color: transparent;
}

.text-wrap {
  vertical-align: top;
  white-space: pre-line;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

.avatar-bigger {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}

.front {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  height: 80vh;
  width: 600px;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}

.image-slot {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}

.n-table-header {
  font-size: 14px;
  line-height: 16px;
  color: #727272;
  text-transform: none;
}

.table-wrapper {
  margin-top: 24px;
}

.el-table .cell {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.icon-larger .material-design-icon {
  height: 0.8em;
  transform: scale(1.5);
}
</style>
