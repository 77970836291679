<template>
  <div>
    <el-dialog
      :visible="visible"
      @close="dismiss"
      width="680px"
      :title="$t('projectInterruptions')"
      id="project_interruptions"
    >
      <el-form ref="form" :model="formData" :rules="formRules">
        <div class="row">
          <div class="col-md-7">
            <el-form-item prop="dateRange">
              <profile-date-picker
                style="width: 100%"
                :projectDateRange="projectDateRange"
                :isEditMode="isEditing"
                v-model="formData.dateRange"
                is-range
                :title="$t('src.components.project.departurecontrol.zeitraum')"
                :placeholder="$t('src.components.project.departurecontrol.zeitraum')"
                value-format="YYYY-MM-DD"
                :focusDate="today"
                required
              />
            </el-form-item>
          </div>
          <div class="col-md-5">
            <el-form-item prop="notes">
              <span class="n-profile-label-title">{{ $t("Notes") }}</span>
              <template v-if="isEditing">
                <el-input
                  :styles="{ width: '100%', marginTop: '-1px' }"
                  v-model="formData.notes"
                  :disabled="!isEditMode"
                  placeholder="Notizen"
                  maxlength="20"
                  show-word-limit
                />
              </template>
              <div v-else>{{ formData.notes }}</div>
            </el-form-item>
          </div>
          <div class="col-md-12">
            <div class="flex justify-between" v-if="isEditing">
              <el-button class="mr-2" @click.prevent="dismiss()">{{
                $t("src.components.project.departurecontrol.abbrechen")
              }}</el-button>
              <template v-if="isAddingNew">
                <el-button type="primary" class="mt-2" @click.prevent="addInterruption()">{{
                  $t("src.components.project.departurecontrol.speichern")
                }}</el-button>
              </template>
              <template v-else>
                <save-button class="mt-2" :disabled="!formData.dateRange" @click.prevent="saveSelectedDeparture()">{{
                  $t("src.components.project.departurecontrol.speichern")
                }}</save-button>
              </template>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>

    <h6>{{ $t("projectInterruptions") }}:</h6>
    <editable-tag
      v-for="(interruption, idx) in visibleInterruptions"
      :key="idx"
      @view="viewInterruption(interruption)"
      @edit="initEditInterruption(interruption)"
      @remove="removeInterruption(interruption)"
      :isEditMode="isEditMode"
      :title="formatInterruptionString(interruption)"
    />
    <cancel-button v-if="isEditMode" @click.prevent="showAddInterruption()">
      <plus-icon class="mr-2" />{{ $t("addInterruption") }}</cancel-button
    >
  </div>
</template>

<script>
import PrDatePicker from "../UIComponents/Inputs/PrDatePicker.vue";
import PlusIcon from "vue-material-design-icons/Plus";
import { EditableTag } from "src/components/UIComponents";
import { moment } from "src/config/moment";
import { Dialog, Form, FormItem, Button, MessageBox } from "element-ui";

export default {
  name: "project-interruptions",
  components: {
    [MessageBox.name]: MessageBox,
    [PrDatePicker.name]: PrDatePicker,
    [PlusIcon.name]: PlusIcon,
    [EditableTag.name]: EditableTag,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
  },
  props: {
    interruptions: { type: Array, default: () => [] },
    isEditMode: Boolean,
    hideOld: Boolean,
    projectDateRange: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const validateDateRange = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum auswählen"));
      }
      const currentDateRange = moment.range(value);
      // find conflicting records to exclude currently editing (if editing) record from validation
      const conflictRecords = this.interruptions.filter((item) => {
        if (item._id && item._id === this.selectedInterruption._id) {
          return false;
        }
        return moment.range(item.dateRange).overlaps(currentDateRange, { adjacent: true });
      });
      // some records overlap currently set daterange
      if (conflictRecords.length > 0) {
        return callback(new Error("Datumsbereich sollte sich nicht überschneiden"));
      }
      return callback();
    };

    return {
      today: new Date(),
      visible: false,
      isEditing: false,
      isAddingNew: false,
      formRules: {
        dateRange: { required: true, validator: validateDateRange, trigger: "change" },
      },
      formData: {
        notes: null,
        dateRange: null,
      },
      selectedInterruption: {},
    };
  },
  methods: {
    addInterruption() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.interruptions) {
          this.interruptions = [];
        }
        this.interruptions.push({
          dateRange: this.formData.dateRange.slice(),
          notes: this.formData.notes,
        });
        this.$refs.form.resetFields();
        this.dismiss();
      });
    },
    viewInterruption(record) {
      this.initEditInterruption(record);
      this.isEditing = false;
    },
    initEditInterruption(record) {
      this.visible = true;
      this.isEditing = true;
      this.selectedInterruption = record;
      this.formData.notes = record.notes;
      this.formData.dateRange = record.dateRange;
    },
    removeInterruption(record) {
      MessageBox.confirm("Unterbrechung löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
      }).then(() => {
        let selectedInterruption = record || this.selectedInterruption;
        const recordIdx = this.interruptions.findIndex((item) => item._id === selectedInterruption._id);
        if (recordIdx !== -1) {
          this.interruptions.splice(recordIdx, 1);
        }
        this.dismiss();
      });
    },
    formatInterruptionString(record) {
      const dateRangeString = this.$options.filters.formatDate(record.dateRange);
      if (record.notes) {
        return `${dateRangeString}: ${record.notes}`;
      } else {
        return dateRangeString;
      }
    },
    saveSelectedDeparture() {
      const interruptionIdx = this.interruptions.findIndex((item) => item._id === this.selectedInterruption._id);
      if (interruptionIdx !== -1) {
        const changedInterruption = {
          ...this.selectedInterruption,
          dateRange: this.formData.dateRange,
          notes: this.formData.notes,
        };
        this.interruptions.splice(interruptionIdx, 1, changedInterruption);

        this.formData.dateRange = null;
        this.formData.notes = null;

        this.isEditing = false;
      }
      this.dismiss();
    },
    showAddInterruption() {
      this.visible = true;
      this.isEditing = true;
      this.isAddingNew = true;
    },
    dismiss() {
      this.isEditing = false;
      this.visible = false;
      this.isAddingNew = false;
      this.selectedInterruption = {};
      this.$refs.form.resetFields();
    },
  },
  computed: {
    visibleInterruptions() {
      if (this.hideOld) {
        const today = moment().startOf("day");
        return this.interruptions.filter((item) => {
          return today.isSameOrBefore(item.dateRange[1], "day");
        });
      } else {
        return this.interruptions;
      }
    },
  },
  filters: {
    formatDate: function (dateRange) {
      if (!dateRange || dateRange.length !== 2) {
        return "N/A";
      }
      if (moment(dateRange[0]).isSame(dateRange[1], "day")) {
        return moment(dateRange[0]).format("DD.MM.YYYY");
      }
      return dateRange.map((date) => moment(date).format("DD.MM.YYYY")).join("-");
    },
  },
};
</script>

<style></style>
