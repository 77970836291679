<template>
  <div>
    <form>
      <div class="card-body">
        <el-row>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profilesettings.stammdaten") }}
            </div>
            <type-settings
              modelType="serviceProvider"
              modelid="serviceProviderType"
              type="Dienstleister Typ"
              :isEditMode="isEditMode"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </el-col>
        </el-row>

        <hr />

        <el-row>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profilesettings.dokumente") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <el-col :span="12">
            <!-- Dokument Settings -->
            <type-settings
              :isEditMode="isEditMode"
              modelid="serviceProviderType"
              modelType="documents"
              type="Dokument"
              :useSystemRelevant="true"
              :useAccessGroups="true"
              :forAdmin="true"
            />
          </el-col>
        </el-row>
      </div>
    </form>
  </div>
</template>

<script>
import { TypeSettings } from "src/components/UIComponents";

export default {
  name: "serviceProvider-profile-settings",
  components: {
    [TypeSettings.name]: TypeSettings,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
