<template>
  <div :class="{ hidden: !shown }">
    <div class="welcome-shape shape-1 welcome-shape-1-transition"></div>
    <div class="welcome-shape shape-2 welcome-shape-2-transition"></div>
    <div :class="['text-center', 'welcome-transition']">
      <p class="welcome-greeting">
        {{ $t("src.components.dashboard.layout.welcomepage.hallo", { "1": getDisplayName }) }}
      </p>
      <h1 class="welcome-headline">
        {{ $t("src.components.dashboard.layout.welcomepage.willkommenInDeinem") }}<span class="blue">S</span
        >{{ $t("src.components.dashboard.layout.welcomepage.chwegler") }}<span class="blue">P</span
        >{{ $t("src.components.dashboard.layout.welcomepage.roject") }} <span class="blue">M</span
        >{{ $t("src.components.dashboard.layout.welcomepage.anagement") }}
      </h1>
      <h2 class="welcome-motto blue">{{ $t("src.components.dashboard.layout.welcomepage.spmOnePlace") }}</h2>
      <div class="welcome-info">
        {{ $t("src.components.dashboard.layout.welcomepage.deinLetzterLogin", { "1": lastLoginTimeString }) }}
      </div>
    </div>
    <div class="version-info">UI: v{{ this.feVersion }} API: v{{ this.beVersion }}</div>
  </div>
</template>
<script>
import { moment } from "src/config/moment";
import { mapState } from "vuex";
import pkg from "../../../../package.json";
import { Loading, MessageBox } from "element-ui";

export default {
  data() {
    return { shown: false, previousLogin: null, beVersion: "", feVersion: "" };
  },
  mounted() {
    this.shown = true;
    this.fetchLastLoginTime();
    this.setVersion();
    // const loading = Loading.service({
    //   fullscreen: true,
    //   text: "Ihre SPM Version wird gerade aktualisiert...In ein paar Sekunden geht es weiter.",
    //   background: "#fff",
    // });
    // setTimeout(() => {
    //   loading.close();
    //   MessageBox({
    //     title: "Aktualisierung fertig gestellt.",
    //     showCancelButton: false,
    //     showClose: false,
    //     closeOnClickModal: false,
    //     closeOnPressEscape: false,
    //     center: true,
    //     confirmButtonText: "Klicken Sie bitte hier um zu aktualisieren",
    //   }).then(() => window.location.reload());
    // }, 3000);
    window._signPdf = async (payload) => {
      await this.axios.post("/api/pdf-service/sign", payload, { responseType: "blob" });
      window.open(`${payload.pdfUrl}?view=true`, "_blank");
    };
  },
  beforeDestroy() {
    this.shown = false;
  },
  methods: {
    async setVersion() {
      try {
        this.feVersion = pkg.version;
        const response = await this.axios.get("/api/version");
        this.beVersion = response.data;
      } catch (error) {
        throw error;
      }
    },
    async fetchLastLoginTime() {
      const response = await this.axios.get("/api/users/last-login");
      if (response.data) {
        this.previousLogin = response.data;
      }
    },
  },
  computed: {
    ...mapState("account", ["user"]),
    lastLoginTimeString() {
      if (this.previousLogin) {
        return moment(this.previousLogin).format("lll");
      }
      return "";
    },
    getDisplayName() {
      if (this.user && this.user.name) {
        return this.user.name;
      } else {
        return "Benutzer";
      }
    },
  },
};
</script>

<style>
.hidden {
  display: none;
}
.welcome-transition {
  opacity: 0;
  animation: go 1s ease 0.3s forwards;
}
.welcome-shape-1-transition {
  opacity: 0;
  animation: shape-1-animation 1s ease-in 0.5s forwards;
}
.welcome-shape-2-transition {
  opacity: 0;
  animation: shape-2-animation 1s ease-in 0.8s forwards;
}

.welcome-shape-2-transition {
  opacity: 0;
  animation: go 1s ease 0.3s forwards;
}

.version-info {
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 0.75rem;
  opacity: 0.5;
}

@keyframes go {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shape-1-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shape-2-animation {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
