<template>
  <div>
    <div :class="{ 'red-color': item.redBackground }" v-for="(item, idx) in visibleData" :key="idx">
      {{ item.text }}
    </div>
    <el-button type="text" v-if="isExpandVisible" @click.prevent="expand">
      {{ $t("Expand") }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "configuration-table-projects-list",
  props: {
    data: Array,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    expand() {
      this.isExpanded = true;
    },
  },
  computed: {
    isExpandVisible() {
      return !this.isExpanded && this.data.length > 3;
    },
    visibleData() {
      if (this.isExpanded) {
        return this.data;
      } else {
        return this.data.slice(0, 3);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
