<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.workshopProjectsSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Download-Center"
        v-model="value.downloadCenter"
        :withDelete="false"
        :withEdit="false"
        :description="$t('userGroupProfile.workshopProjects.downloadCenter')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="BL-Unterlagen"
        v-model="value.documents"
        :withDelete="false"
        :withEdit="false"
        :description="$t('userGroupProfile.workshopProjects.documents')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Tagesberichte"
        v-model="value.reports"
        :withDelete="false"
        :withEdit="false"
        :description="$t('userGroupProfile.workshopProjects.reports')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="ToDos"
        v-model="value.todos"
        :withDelete="false"
        :withEdit="false"
        :description="$t('userGroupProfile.workshopProjects.todos')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Notiz"
        v-model="value.notes"
        :withDelete="false"
        :withEdit="false"
        :description="$t('userGroupProfile.workshopProjects.notes')"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsRow from "./AccessRightsRow.vue";
import AccessRightsTable from "./AccessRightsTable.vue";
export default {
  name: "workshop-projects-profile-access",
  components: { AccessRightsRow, AccessRightsTable },
  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>
