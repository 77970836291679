<template>
  <div>
    <el-input v-model="invoiceId" v-if="isEditMode">
      <template v-slot:prepend>
        <span class="input-color"> {{ shortname }} # </span>
      </template>
    </el-input>
    <template v-else>
      <h5 class="drawer-name">{{ invoiceTitle }}</h5>
      <label v-if="name">{{ name }}</label>
    </template>
  </div>
</template>

<script>
import { Input } from "element-ui";

export default {
  name: "editable-invoice-title",
  components: {
    [Input.name]: Input,
  },
  props: {
    isEditMode: Boolean,
    shortname: String,
    value: String,
    name: String,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    invoiceId: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    invoiceTitle() {
      return `${this.shortname}${this.value ? ` #${this.value}` : ""}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
