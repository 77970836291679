<template>
  <el-button
    v-bind="$attrs"
    :class="{ 'n-light-gray-button': true, active: active }"
    @click="(evt) => $emit('click', evt)"
  >
    <slot>{{ $t('src.components.uicomponents.buttons.button.unnamedButton') }}</slot>
  </el-button>
</template>
<script>
export default {
  name: "n-button",
  props: {
    
  },
  computed: {
    classes() {
      let btnClasses = [
        { [`btn-outline-${this.type}`]: this.outline },
        { "btn-icon": this.icon },
        { "btn-round": this.round },
        { "btn-block": this.block },
        { "btn-social": this.social },
        { "btn-link": this.link },
        { "btn-wd": this.wide },
        `btn-${this.type}`,
      ];
      if (this.size) {
        btnClasses.push(`btn-${this.size}`);
      }
      return btnClasses;
    },
  },
  methods: {

  },
};
</script>
<style>
</style>
