<template>
  <el-button type="danger" @click="(evt) => $emit('click', evt)" data-testid="delete_button" v-bind="$attrs">
    {{ editText }}
  </el-button>
</template>

<script>
export default {
  name: "delete-button",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editText: "Löschen",
    };
  },
};
</script>

<style></style>
