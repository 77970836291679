const state = {
  visible: false,
  fileName: "",
  url: "",
};

const actions = {
  close({ commit }) {
    commit("CLOSE_VIEWER");
  },
  open({ commit }, payload) {
    commit("OPEN_VIEWER", payload);
  },
};

const mutations = {
  CLOSE_VIEWER(state) {
    state.visible = false;
    state.url = "";
    state.fileName = "";
  },
  OPEN_VIEWER(state, payload) {
    state.visible = true;
    state.url = payload.url;
    state.fileName = payload.fileName || "";
  },
};

export const pdfViewer = {
  namespaced: true,
  state,
  actions,
  mutations,
};
