<template>
  <div
    style="height: 100vh; display: flex; align-items: center; justify-content: center; flex-direction: column"
    v-loading="loading"
  >
    <!-- <el-progress :percentage="percentCompleted"></el-progress> -->
  </div>
</template>

<script>
import { Progress } from "element-ui";
import { get, last } from "lodash";

export default {
  components: {
    [Progress.name]: Progress,
  },
  name: "download-document",
  data() {
    return {
      loading: false,
      percentCompleted: 0,
    };
  },
  mounted() {
    this.fetchDocumentFromPath();
  },
  methods: {
    async fetchDocumentFromPath() {
      const isView = this.$route.query.view;

      try {
        this.loading = true;
        const downloadPath = this.axios.defaults.baseURL + this.$route.path;
        const fileResponse = await this.axios.get(downloadPath, {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const total = parseFloat(
              progressEvent.currentTarget.responseHeaders &&
                progressEvent.currentTarget.responseHeaders["Content-Length"]
            );
            const current = progressEvent.currentTarget.response && progressEvent.currentTarget.response.length;
            if (total && current) {
              let percentCompleted = Math.floor((current / total) * 100);
              this.percentCompleted = percentCompleted;
            }
          },
        });
        console.log("fileResponse", fileResponse);
        const filename = decodeURI(last(downloadPath.split("/")));
        const objectUrl = URL.createObjectURL(fileResponse.data);
        if (isView) {
          const ref = window.open(objectUrl, "_blank");
          // close this window when as soon as view window is closed
          const interval = setInterval(() => {
            if (ref.closed) {
              clearInterval(interval);
              window.close();
            }
          }, 500);
        } else {
          const link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", filename);
          link.click();
          link.remove();
          setTimeout(() => {
            URL.revokeObjectURL(objectUrl);
            window.close();
          }, 200);
        }
      } catch (error) {
        const errorMessage = get(error, "response.data.message", error.message);
        this.errorMessage = errorMessage;
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
