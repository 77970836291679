var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.filetype === 'pdf')?[_c('file-pdf-box-icon')]:(_vm.filetype.indexOf('xls') === 0)?[_c('file-excel-icon')]:(_vm.filetype.indexOf('doc') === 0)?[_c('file-word-icon')]:(
      _vm.filetype === 'mp4' ||
      _vm.filetype.indexOf('mpeg') === 0 ||
      _vm.filetype === 'avi' ||
      _vm.filetype === 'mkv' ||
      _vm.filetype === 'mov'
    )?[_c('file-video-icon')]:[_c('file-icon')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }