<template>
  <div>
    <!-- preview picture -->
    <div class="front" v-show="previewPicture">
      <lazy-image v-if="previewPictureSrc" :src="previewPictureSrc" fit="contain" style="max-height: 100%" />
    </div>

    <el-table :data="tableData" style="width: 100%" max-height="250">
      <el-table-column
        type="index"
        :label="$t('src.components.machine.resourceselectiontable.nr')"
        width="60"
      ></el-table-column>
      <el-table-column width="100" :label="$t('src.components.machine.resourceselectiontable.bild')">
        <template v-slot="props">
          <div>
            <a
              style="display: block; height: 45px"
              v-on:mouseover="showPreviewPicture(props)"
              v-on:mouseleave="hidePreviewPicture"
            >
              <lazy-image
                style="width: auto; height: 100%"
                fit="contain"
                :src="getThumbnail(props)"
                scroll-container=".el-table__body-wrapper"
              >
              </lazy-image>
            </a>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('src.components.machine.resourceselectiontable.name')"
        width="*"
      ></el-table-column>
      <el-table-column
        prop="name"
        :label="$t('src.components.machine.resourceselectiontable.aktion')"
        width="150"
        align="right"
      >
        <template v-slot="scope">
          <el-button class="btn btn-sm btn-ghost" @click="handleDeleteResource(scope.row)">
            <trash-can-outline-icon />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table } from "element-ui";
import { LazyImage } from "src/components/UIComponents";

export default {
  name: "selection-table",
  components: {
    [Table.name]: Table,
    [LazyImage.name]: LazyImage,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    resourceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      previewPicture: false,
      previewPictureSrc: "",
    };
  },
  methods: {
    showPreviewPicture(props) {
      if (props.row.picture) {
        this.previewPictureSrc = this.axios.defaults.baseURL + props.row.picture.url;
        this.previewPicture = true;
      }
    },
    hidePreviewPicture() {
      this.previewPictureSrc = "";
      this.previewPicture = false;
    },
    getThumbnail(props) {
      // console.log(
      //   "getThumbnail",
      //   props.row.picture
      //     ? this.axios.defaults.baseURL + props.row.picture.thumbnail
      //     : this.axios.defaults.baseURL + "/img/default-avatar.png"
      // );
      if (!props.row.picture) {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      } else {
        return props.row.picture
          ? this.axios.defaults.baseURL + props.row.picture.thumbnail
          : this.axios.defaults.baseURL + "/img/default-avatar.png";
      }
    },
    showPreviewPicture(props) {
      if (props.row.picture) {
        this.previewPictureSrc = this.axios.defaults.baseURL + props.row.picture.url;
        this.previewPicture = true;
      }
    },
    hidePreviewPicture() {
      this.previewPictureSrc = "";
      this.previewPicture = false;
    },
    handleDeleteResource(resource) {
      this.$confirmDelete().then(() => {
        this.$emit("deleteResource", this.$props.resourceType, resource);
      });
    },
  },
};
</script>

<style></style>
