<template>
  <div>
    <div class="card-plain col-md-2">
      <div class="col-md-12">
        <label>{{ $t("src.components.vehicle.customerservice.nchsterTermin") }}</label>
        <br />
        <pr-date-picker
          :disabled="!isEditMode"
          name="nextDate"
          v-model="vehicle.customerService.nextDate"
          :placeholder="$t('src.components.vehicle.customerservice.datum')"
        />
      </div>

      <div class="col-md-12">
        <fg-input
          :disabled="!isEditMode"
          type="text"
          :label="$t('src.components.vehicle.customerservice.kilometerstand')"
          :placeholder="$t('src.components.vehicle.customerservice.2281')"
          v-model="vehicle.customerService.nextMileage"
        ></fg-input>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Message, DatePicker } from "element-ui";

export default {
  name: "customer-service",
  props: {
    vehicle: Object,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    [DatePicker.name]: DatePicker,
    Message,
  },
};
</script>

<style>
</style>
