<template>
  <profile-input
    style="max-width: 300px"
    :value="viewedValue"
    viewModeSuffix="€"
    ref="input"
    v-bind="$attrs"
    @input="formatCurrency($event)"
    @blur="formatCurrency($event, 'blur')"
    @buttonClick="$emit('buttonClick')"
  >
    <template v-slot:append>€</template>
  </profile-input>
</template>

<script>
export default {
  name: "profile-price-input",
  props: {
    value: [String, Number],
  },
  data() {
    return {
      viewedValue: "",
    };
  },
  mounted() {
    if (this.value) {
      const newValAsPrice = this.numberToString(this.value);
      this.viewedValue = newValAsPrice;
    }
  },
  methods: {
    formatNumber(n) {
      // format number 1000000 to 1.234.567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatCurrency(event, blur) {
      const input = this.$refs.input.$refs.inputRef.getInput();

      let input_val = input.value;
      // don't validate empty input
      if (input_val === "") {
        this.viewedValue = input_val;
        this.$emit("input", null);
        return;
      }

      const original_len = input_val.length;

      let caret_pos = input.selectionStart;

      // check for decimal
      if (input_val.indexOf(",") >= 0) {
        // get position of first decimal
        // this prevents multiple decimals from
        // being entered
        const decimal_pos = input_val.indexOf(",");

        // split number by decimal point
        let left_side = input_val.substring(0, decimal_pos);
        let right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = this.formatNumber(left_side);

        // validate right side
        right_side = this.formatNumber(right_side);
        // On blur make sure 2 numbers after decimal
        if (blur === "blur") {
          right_side += "00";
        }

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = left_side + "," + right_side;
      } else {
        // no decimal entered
        // add commas to number
        // remove all non-digits
        input_val = this.formatNumber(input_val);

        // final formatting
        if (blur === "blur") {
          input_val += ",00";
        }
      }

      // send updated string to input
      input.value = input_val;

      // put caret back in the right position
      const updated_len = input_val.length;
      caret_pos = updated_len - original_len + caret_pos;
      input.setSelectionRange(caret_pos, caret_pos);

      this.viewedValue = input_val;
      const numberVal = input_val ? parseFloat(input_val.replace(/\./g, "").replace(/\,/g, ".")) : null;
      this.$emit("input", numberVal);
    },

    numberToString(n) {
      if (n) {
        return Number(n).toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      } else {
        return n;
      }
    },
    updateValue(newValue) {
      const newValAsPrice = this.numberToString(newValue);
      this.viewedValue = newValAsPrice;
    },
  },
  watch: {
    value(newVal, oldVal) {
      // TODO: allow to update new value from props if it's not empty: first reset then set
      if (!this.viewedValue && !isNaN(newVal)) {
        const newValAsPrice = this.numberToString(newVal);
        if (newValAsPrice !== this.viewedValue) {
          this.viewedValue = newValAsPrice;
        }
      }
    },
  },
};
</script>

<style></style>
