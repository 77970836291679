<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }">
    <div :class="['el-form-item', { 'is-error': !!errors[0] }]">
      <template v-if="editMode">
        <span v-if="!noLabel">
          <!-- TITLE with or without hinz badge -->
          <template v-if="hint && hint != ''">
            <span v-if="required" style="float: left" class="n-profile-select-title-red">*</span>
            <span style="float: left" class="n-profile-label-title" v-if="title">{{ title }}:</span>
            <span style="float: right" class="n-profile-select-title-red">{{ hint }}</span>
          </template>
          <template v-else>
            <span v-if="required" style="" class="n-profile-select-title-red">*</span>
            <span class="n-profile-label-title" v-if="title">{{ title }}:</span>
          </template>

          <br v-if="title" />
        </span>
        <div class="el-form-item__content">
          <v-select
            :reserve-keyword="reserveKeyword"
            :multiple="multiple"
            :clearable="clearable"
            :filterable="filterable"
            :filter-method="filterMethod"
            :placeholder="placeholder"
            :valueIdentifier="isGrouped ? 'value' : valueIdentifier"
            :labelIdentifier="isGrouped ? 'label' : label"
            :options="items"
            :isGrouped="isGrouped"
            v-model="listInput"
            :size="size"
            :disabled="disabled"
            :disableInactive="disableInactive"
            :hideInactive="hideInactive"
            :data-testid="title"
          >
            <template v-slot:option="item">
              <el-badge is-dot :hidden="hideBadge(item)">
                <span style="float: left" :class="{ inactive: item.active === false }">{{ item[label] }}</span>
              </el-badge>
            </template>
          </v-select>
          <transition name="slide">
            <div v-show="errors[0]" class="el-form-item__error">{{ customMessage || errors[0] }}</div>
          </transition>
        </div>
      </template>
      <template v-else>
        <span class="n-profile-label-title" v-if="!noLabel">{{ title }}:</span>
        <br />
        <span class="n-profile-label-value" :data-testid="title">{{ getLabel() }} </span>
      </template>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "profile-select",
  components: {
    ValidationProvider,
  },
  props: {
    reserveKeyword: { type: Boolean },
    name: { type: String },
    customMessage: { type: String },
    rules: { type: String },
    value: {
      type: [Array, String, Number, Boolean],
    },
    valueIdentifier: {
      type: String,
      default: "value",
    },
    disableInactive: {
      type: Boolean,
      default: true,
    },
    hideInactive: {
      type: Boolean,
      default: true,
    },
    noLabel: {
      type: Boolean,
    },
    /** Enable to show dropdown splitted by groups
     * Then the structure of items should be like
     * [{ label: string, children: [{label: string, value: string }] }]
     */
    isGrouped: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: Boolean,
    filterable: Boolean,
    title: {
      type: String,
    },
    filterMethod: {
      type: Function,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "label",
    },
    showValue: {
      type: Boolean,
      // default: true
    },
    placeholder: {
      type: String,
      default: " — ",
    },
    hint: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
    },
    size: {
      type: String,
    },
  },
  computed: {
    listInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    hideBadge(item) {
      if (!item) {
        return true;
      }
      // has object this property?
      else if (!item.hasOwnProperty("systemRelevant")) {
        return true;
      }
      return !item.systemRelevant;
    },
    getLabel() {
      // console.log(this.multiple, this.value, this.items);
      if (this.multiple && Array.isArray(this.value)) {
        if (this.value.length === 0) {
          return " — ";
        }

        let result = "";
        this.value.forEach((x, index) => {
          let item = this.items.find((i) => i[this.valueIdentifier] == x);
          //when items list has changed compared to value list
          if (item) {
            result += item[this.label];
            if (index + 1 < this.value.length) {
              result += ", ";
            }
          }
        });
        return result;
      } else if (!this.multiple && typeof this.value === "string") {
        if (this.showValue) {
          return this.value ? this.value : " — ";
        } else {
          if (this.value) {
            let item;
            if (this.isGrouped) {
              for (let index = 0; index < this.items.length; index++) {
                const group = this.items[index];
                const itemFound = group.children && group.children.find((child) => child.value === this.value);
                if (itemFound) {
                  return itemFound.label;
                }
              }
            } else {
              item = this.items.find((i) => i[this.valueIdentifier] == this.value);
            }

            if (!item) {
              return this.value;
            }
            return item[this.label];
          } else {
            return " — ";
          }
        }
      } else {
        return " — ";
      }
    },
  },
};
</script>

<style lang="scss">
@mixin tag($type) {
  .el-tag,
  .el-tag.el-tag--#{$type} {
    .el-select__tags-text {
      color: black;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
    .el-tag__close {
      color: black;
      background-color: white !important;
    }
    .el-tag__close.el-icon-close {
      color: black;
    }
    .el-tag__close.el-icon-close:hover {
      color: green;
      font-size: 18px;
    }
    .el-tag__close:hover {
      background-color: white;
      color: black;
    }
    border: 0px;
  }
}

.el-badge__content.is-fixed.is-dot {
  right: 0px !important;
  top: 8px !important;
  width: 6px !important;
  height: 6px !important;
}

@include tag("info");
</style>
