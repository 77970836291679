<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.dashboardSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('src.components.dashboard.views.dashboard.widgets.birthdayswidget.geburtstage')"
        v-model="value.birthdays"
        :description="$t('userGroupProfile.birthdaysDescription')"
        :withDelete="false"
        :withEdit="false"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="
          $t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.gltigkeitVonRessourcendokumenten')
        "
        v-model="value.documentsValidity"
        :description="$t('userGroupProfile.documentsValidityDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.fehlendeDatenstze')"
        v-model="value.missingData"
        :description="$t('userGroupProfile.missingDataDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusRechnungsstellung')"
        v-model="value.invoiceReports"
        :description="$t('userGroupProfile.invoiceReportsDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('src.components.dashboard.views.dashboard.widgets.unavailableResources.title')"
        v-model="value.unavailableResources"
        :description="$t('userGroupProfile.unavailableResourcesDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('src.components.dashboard.views.dashboard.widgets.financeReport.financeReport')"
        v-model="value.financeReport"
        :description="$t('userGroupProfile.financeReportDescription')"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsTable from "./AccessRightsTable.vue";
import AccessRightsRow from "./AccessRightsRow.vue";

export default {
  name: "dashboard-access",
  components: {
    AccessRightsTable,
    AccessRightsRow,
  },
  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style>
</style>