<template>
  <div :class="['todo-progress-dot', progressClass]"></div>
</template>

<script>
export default {
  name: "todo-progress-dot",
  props: {
    status: String,
  },
  computed: {
    progressClass() {
      switch (this.status) {
        case "IN_PROGRESS":
          return "in-progress";
        case "TODO":
          return "todo";
        case "DONE":
          return "done";
        default:
          return;
      }
    },
  },
};
</script>

<style>
</style>