<template>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind="$attrs">
    <g clip-path="url(#clip0_2369_5391)">
      <rect width="120" height="120" rx="24" fill="url(#paint0_linear_2369_5391)" />
      <rect
        opacity="0.4"
        x="73"
        y="-12"
        width="159"
        height="144"
        fill="url(#paint1_radial_2369_5391)"
        fill-opacity="0.6"
      />
      <rect
        opacity="0.4"
        x="-39"
        y="33"
        width="159"
        height="144"
        fill="url(#paint2_radial_2369_5391)"
        fill-opacity="0.6"
      />
      <g filter="url(#filter0_dddi_2369_5391)">
        <rect x="20" y="20" width="70" height="24" rx="8" fill="url(#paint3_linear_2369_5391)" />
      </g>
      <g filter="url(#filter1_dddi_2369_5391)">
        <rect x="38" y="48" width="62" height="24" rx="8" fill="url(#paint4_linear_2369_5391)" />
      </g>
      <g filter="url(#filter2_dddi_2369_5391)">
        <rect x="20" y="76" width="59" height="24" rx="8" fill="url(#paint5_linear_2369_5391)" />
      </g>
      <g filter="url(#filter3_i_2369_5391)">
        <rect x="24" y="24" width="20" height="16" rx="4" fill="#EE7F7F" />
      </g>
      <g filter="url(#filter4_i_2369_5391)">
        <rect x="44" y="52" width="20" height="16" rx="4" fill="#E9BA74" />
      </g>
      <g filter="url(#filter5_i_2369_5391)">
        <rect x="25" y="80" width="20" height="16" rx="4" fill="#6DB68A" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dddi_2369_5391"
        x="5"
        y="6"
        width="100"
        height="54"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2369_5391" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2369_5391" result="effect2_dropShadow_2369_5391" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_2369_5391" result="effect3_dropShadow_2369_5391" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2369_5391" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect4_innerShadow_2369_5391" />
      </filter>
      <filter
        id="filter1_dddi_2369_5391"
        x="23"
        y="34"
        width="92"
        height="54"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2369_5391" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2369_5391" result="effect2_dropShadow_2369_5391" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_2369_5391" result="effect3_dropShadow_2369_5391" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2369_5391" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect4_innerShadow_2369_5391" />
      </filter>
      <filter
        id="filter2_dddi_2369_5391"
        x="5"
        y="62"
        width="89"
        height="54"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2369_5391" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_2369_5391" result="effect2_dropShadow_2369_5391" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_2369_5391" result="effect3_dropShadow_2369_5391" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2369_5391" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect4_innerShadow_2369_5391" />
      </filter>
      <filter
        id="filter3_i_2369_5391"
        x="24"
        y="24"
        width="20"
        height="16"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2369_5391" />
      </filter>
      <filter
        id="filter4_i_2369_5391"
        x="44"
        y="52"
        width="20"
        height="16"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2369_5391" />
      </filter>
      <filter
        id="filter5_i_2369_5391"
        x="25"
        y="80"
        width="20"
        height="16"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2369_5391" />
      </filter>
      <linearGradient id="paint0_linear_2369_5391" x1="60" y1="0" x2="60" y2="120" gradientUnits="userSpaceOnUse">
        <stop stop-color="#CFE7E8" />
        <stop offset="1" stop-color="#A879AC" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2369_5391"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(152.5 60) rotate(90) scale(72 79.5)"
      >
        <stop stop-color="white" />
        <stop offset="0.380208" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2369_5391"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(40.5 105) rotate(90) scale(72 79.5)"
      >
        <stop stop-color="white" />
        <stop offset="0.380208" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_2369_5391"
        x1="54.4531"
        y1="20"
        x2="54.4531"
        y2="44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBFBFA" />
        <stop offset="0.637101" stop-color="#EAE7E3" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2369_5391"
        x1="68.5156"
        y1="48"
        x2="68.5156"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBFBFA" />
        <stop offset="0.637101" stop-color="#EAE7E3" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2369_5391"
        x1="49.0391"
        y1="76"
        x2="49.0391"
        y2="100"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FBFBFA" />
        <stop offset="0.637101" stop-color="#EAE7E3" />
      </linearGradient>
      <clipPath id="clip0_2369_5391">
        <rect width="120" height="120" rx="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "todos-icon",
};
</script>

<style lang="scss" scoped></style>
