<template>
  <div>
    <el-row type="flex">
      <!-- SEARCH -->
      <el-col :lg="6" :md="8">
        <!-- <el-input
          class="n-search"
          v-model="searchQuery"
          :placeholder="$t('src.components.uicomponents.helper.ressourcestatus.sucheNachZeitraumDdmmyyyy')"
          clearable
          suffix-icon="el-icon-search"
        >
        </el-input> -->
      </el-col>
      <el-col v-if="isEditMode" :lg="18" :md="16" class="text-right" style="padding-right: 20px">
        <el-button type="primary" @click="newHoliday"> <plus-icon />{{ $t("publicHolidays.newHoliday") }}</el-button>
      </el-col>
    </el-row>
    <el-table
      :data="holidays"
      style="width: 100%"
      :default-sort="{ prop: 'start', order: 'ascending' }"
      v-loading="loading"
    >
      <el-table-column type="index" sortable width="60">
        <template v-slot:header>
          <span class="n-table-header">#</span>
        </template>
      </el-table-column>
      <el-table-column sortable prop="name" min-width="150">
        <!-- HEADER -->
        <template v-slot:header>
          <span class="n-table-header">{{ $t("publicHolidays.holidayName") }}</span>
        </template>
      </el-table-column>
      <el-table-column sortable :sort-method="sortDateRange" prop="start" :formatter="formatDateRange" min-width="150">
        <!-- HEADER -->
        <template v-slot:header>
          <span class="n-table-header">{{ $t("src.components.uicomponents.helper.ressourcestatus.zeitraum") }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="120">
        <template v-slot="scope">
          <!-- EDIT BUTTON -->
          <el-button class="btn btn-sm btn-ghost" v-if="isEditMode" @click="handleEditRecord(scope.row)">
            <pencil-icon />
          </el-button>
          <!-- DELETE BUTTON -->
          <el-button class="btn btn-sm btn-ghost" v-if="isEditMode" @click="handleDeleteRecord(scope.row)">
            <trash-can-outline-icon />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="isVisible"
      :title="getTitle"
      @closed="discard"
      width="1000px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form :rules="formDataRules" :model="formData" ref="form" inline>
        <el-form-item label="Name" prop="name">
          <el-input v-model="formData.name" />
        </el-form-item>
        <el-form-item :label="$t('src.components.uicomponents.helper.ressourcestatus.zeitraum')" prop="dateRange">
          <pr-date-picker is-range v-model="formData.dateRange" ref="datepicker" />
        </el-form-item>
        <el-form-item prop="color" label="Farbe">
          <el-color-picker v-model="formData.color" style="min-width: 150px" :predefine="colorList" />
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button type="primary" @click="submit">{{
          $t("src.components.administration.user.profile.speichern")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn, Form, FormItem, ColorPicker, DatePicker, Input, Message } from "element-ui";
import { moment } from "src/config/moment";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import FileDocumentOutline from "vue-material-design-icons/FileDocumentOutline";

export default {
  name: "public-holidays",
  components: {
    [EyeOutline.name]: EyeOutline,
    [FileDocumentOutline.name]: FileDocumentOutline,
    [Message.name]: Message,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [ColorPicker.name]: ColorPicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    isEditMode: { type: Boolean },
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      holidays: [],
      loading: false,
      searchQuery: "",
      isVisible: false,
      formData: {
        dateRange: null,
        name: "",
        color: "",
      },
      formDataRules: {
        dateRange: { required: true, trigger: "change", message: "Dieses Feld wird benötigt" },
        name: { required: true, trigger: "change", message: "Dieses Feld wird benötigt" },
        color: { required: true, trigger: "change", message: "Dieses Feld wird benötigt" },
      },
      colorList: [
        "#cc0000",
        "#a30000",
        "#8b0000",
        "#470000",
        "#f7bd7f",
        "#f39d3c",
        "#ec7a08",
        "#b35c00",
        "#f9d67a",
        "#f5c12e",
        "#f0ab00",
        "#b58100",
        "#c8eb79",
        "#ace12e",
        "#92d400",
        "#6ca100",
        "#9ecf99",
        "#6ec664",
        "#3f9c35",
        "#2d7623",
        "#7dbdc3",
        "#3a9ca6",
        "#007a87",
        "#005c66",
        "#7cdbf3",
        "#35caed",
        "#00b9e4",
        "#008bad",
        "#a18fff",
        "#8461f7",
        "#703fec",
        "#582fc0",
        "#7dc3e8",
        "#39a5dc",
        "#0088ce",
        "#00659c",
        "#ededed",
        "#d1d1d1",
        "#bbbbbb",
        "#8b8d8f",
      ],
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          try {
          } catch (error) {
            Message.error(error.message);
            throw error;
          }
          const [start, end] = this.formData.dateRange.map((d) => moment(d).format("YYYY-MM-DD"));
          const formBody = {
            name: this.formData.name,
            start: start,
            end: end,
            color: this.formData.color,
          };
          if (this.formData._id) {
            await this.axios.put(`/api/public-holidays/${this.formData._id}`, formBody);
          } else {
            await this.axios.post(`/api/public-holidays`, formBody);
          }
          this.discard();
          this.fetch();
        }
      });
    },
    newHoliday() {
      this.isVisible = true;
    },
    handleEditRecord(record) {
      const { start, end, ...restRecord } = record;
      this.formData = { ...restRecord, dateRange: [start, end] };
      this.isVisible = true;
    },
    async handleDeleteRecord(record) {
      this.$confirmDelete().then(async () => {
        await this.axios.delete(`/api/public-holidays/${record._id}`);
        this.fetch();
      });
    },
    async fetch() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/public-holidays");
        this.holidays = response.data;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    discard() {
      this.$refs.form.resetFields();
      this.isVisible = false;
      this.formData.dateRange = null;
      this.formData.name = "";
      this.formData.color = "";
      this.$refs.datepicker.resetPreviousValue();
    },
    formatDateRange(datarow) {
      if (!datarow.start || !datarow.end) {
        return " - ";
      }
      return `${moment(datarow.start).format("L")} - ${moment(datarow.end).format("L")}`;
    },
    sortDateRange(a, b) {
      const aDate = new Date(a.start).getTime();
      const bDate = new Date(b.start).getTime();
      if (aDate < bDate) {
        return -1;
      } else if (bDate < aDate) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  computed: {
    getTitle() {
      return this.formData._id ? this.$t("Edit public holiday") : this.$t("Create public holiday");
    },
  },
};
</script>

<style></style>
