<template>
  <div>
    <!-- id "hotel-section" is used to programmatically navigate to this section 
    by parsing query paramenter at Edit.vue -->
    <el-row class="mb-10" justify="space-between">
      <el-col :span="12">
        <p class="profile-title" id="hotel-section">
          {{ $t("src.components.project.hotelsreservation.hotelReservierung") }}
        </p>
      </el-col>
      <el-col :span="12" class="text-right">
        <profile-radio-group
          class="mr-2"
          v-model="hideOld"
          :items="[
            { label: 'Alle Einträge', value: false },
            { label: 'Aktuelle Einträge', value: true },
          ]"
        />
      </el-col>
    </el-row>
    <div style="margin-top: -10px; margin-bottom: 10px">
      <profile-switch
        height="40px"
        :editMode="!viewOnly"
        :title="$t('src.components.project.hotelsreservation.hotelReservationIsNecessary')"
        :name="$t('src.components.project.hotelsreservation.hotelReservationIsNecessary')"
        v-model="localhotelReserveAlarm"
        v-on:change="handleChangeHotelReserveAlarm"
      ></profile-switch>
    </div>
    <div style="margin-top: -10px; margin-bottom: 10px">
      <profile-switch
        height="40px"
        :editMode="!viewOnly"
        :title="$t('src.components.project.hotelsreservation.excludeLastDayFromBooking')"
        :name="$t('src.components.project.hotelsreservation.excludeLastDayFromBooking')"
        v-model="localExcludeLastDayFromBooking"
        v-on:change="handleExcludeLastDayFromBooking"
      ></profile-switch>
    </div>
    <el-dialog
      :visible="visible"
      @close="dismissEdit"
      width="800px"
      :title="$t('src.components.project.hotelsreservation.hotelsUndBuchungszeitraum')"
      id="hotels_reservation"
      destroy-on-close
    >
      <el-form :model="formData" :rules="formRules" ref="form">
        <div class="row">
          <div class="col-md-6 form-group">
            <div class="select-wrapper" v-if="isEditing">
              <el-form-item prop="selectedHotel">
                <span class="n-profile-label-title">
                  <span style="margin-top: 3px" class="n-profile-select-title-red">*</span
                  >{{ $t("src.components.project.hotelsreservation.hotel") }}</span
                >
                <br />
                <div>
                  <el-select
                    :disabled="viewOnly"
                    style="width: 100%"
                    v-model="formData.selectedHotel"
                    :placeholder="$t('src.components.project.hotelsreservation.hotels')"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="debounceFilterHotels"
                    clearable
                  >
                    <el-option v-for="item in displayedHotelOptions" :key="item.id" :label="item.name" :value="item.id">
                      <span style="float: left" :title="item.name">
                        {{ item.name }}
                      </span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ getLabelMeta(item) }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
            <div v-else>
              {{ getHotelName(formData.selectedHotel) }}
            </div>
            <router-link
              class="hotel-link"
              v-if="formData.selectedHotel"
              :to="{ name: 'ServiceProvider View', params: { id: formData.selectedHotel } }"
              >{{ $t("src.components.project.hotelsreservation.linkZumHotel") }}</router-link
            >
          </div>
          <div class="col-md-6 form-group">
            <el-form-item prop="selectedHotelDateRange">
              <profile-date-picker
                required
                style="width: 100%"
                :isEditMode="isEditing"
                :projectDateRange="projectDateRange"
                :focusDate="today"
                v-model="formData.selectedHotelDateRange"
                is-range
                :title="$t('src.components.project.hotelsreservation.buchungszeitraum')"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </div>
          <div class="col-md-6 form-group">
            <span class="n-profile-label-title">{{ $t("src.components.project.hotelsreservation.preis") }}</span>
            <div v-if="isEditing">
              <el-input
                :disabled="viewOnly"
                type="number"
                min="0"
                step="0.01"
                :placeholder="$t('src.components.project.hotelsreservation.preis')"
                v-model="formData.pricePerNight"
              >
                <template v-slot:append>€</template>
              </el-input>
            </div>
            <div v-else>{{ formData.pricePerNight }} €</div>
          </div>
          <div class="col-md-6 form-group">
            <profile-switch
              v-model="formData.withBreakfast"
              :title="$t('src.components.project.hotelsreservation.frhstckInklusive')"
              :editMode="isEditing"
            />
          </div>
          <div class="col-md-12">
            <profile-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              :label="$t('src.components.project.hotelsreservation.freeText')"
              :editMode="isEditing"
              v-model="formData.selectedHotelFreeText"
            />
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <el-upload
                  multiple
                  ref="upload"
                  :headers="authHeader"
                  :disabled="viewOnly || !projectId"
                  :action="actionURI"
                  :data="{ metadata: getMetadata() }"
                  :on-preview="showFile"
                  :before-remove="beforeRemoveFile"
                  :on-remove="removeFile"
                  :on-success="fileUploaded"
                  accept=".png, .PNG, .JPG, .JPEG, .PDF, .jpg, .jpeg, .pdf"
                  :file-list="documents"
                >
                  <div v-if="isEditing">
                    <el-tooltip content="Bitte speichern Sie das Projekt, um Dokumente hinzufügen zu können">
                      <div>
                        <el-button style="margin: 10px 0" type="primary" :disabled="!projectId">
                          <i class="nc-icon nc-simple-add"></i
                          >{{ $t("src.components.project.hotelsreservation.dateiHinzufgen") }}</el-button
                        >
                      </div>
                    </el-tooltip>
                  </div>
                </el-upload>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="flex justify-between" v-if="isEditing">
              <el-button class="mr-2" @click.prevent="dismissEdit()">{{
                $t("src.components.project.hotelsreservation.abbrechen")
              }}</el-button>
              <template v-if="isAddingNew">
                <el-button type="primary" @click.prevent="addHotel()">{{
                  $t("src.components.project.hotelsreservation.speichern")
                }}</el-button>
              </template>
              <template v-else>
                <el-button type="primary" @click.prevent="saveEditedHotel()">{{
                  $t("src.components.project.hotelsreservation.speichern")
                }}</el-button>
              </template>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <div class="form-group">
      <editable-tag
        v-for="hotel in visibleHotels"
        :key="hotel._id"
        @view="viewHotelData(hotel)"
        @edit="editHotelData(hotel)"
        @remove="removeHotelData(hotel.idx)"
        :isEditMode="!viewOnly"
        :title="formatHotelDataString(hotel)"
        :record="hotel"
      />
      <cancel-button v-if="!viewOnly" :disabled="!localhotelReserveAlarm" @click.prevent="showAddHotelData()">
        <plus-icon class="mr-2" />{{ $t("src.components.project.hotelsreservation.hotelHinzufgen") }}</cancel-button
      >
    </div>
  </div>
</template>

<script>
import {
  DatePicker,
  Timeline,
  TimelineItem,
  Select,
  Input,
  Upload,
  Button,
  MessageBox,
  Switch,
  Checkbox,
  Tooltip,
  Form,
  FormItem,
} from "element-ui";
import { moment } from "src/config/moment";
import { mapGetters } from "vuex";
import { debounce, get } from "lodash";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import { PrDatePicker } from "src/components/UIComponents";
import { EditableTag } from "src/components/UIComponents";
import PlusIcon from "vue-material-design-icons/Plus";

export default {
  name: "hotels-reservation",
  components: {
    PrDatePicker,
    [EditableTag.name]: EditableTag,
    [PlusIcon.name]: PlusIcon,
    [DatePicker.name]: DatePicker,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Select.name]: Select,
    [Upload.name]: Upload,
    [Button.name]: Button,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
    [Switch.name]: Switch,
    [TrashCanOutline.name]: TrashCanOutline,
    [Tooltip.name]: Tooltip,
    [Checkbox.name]: Checkbox,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    value: { type: Boolean },
    onAddHotel: { type: Function, required: true },
    onRemoveHotel: { type: Function, required: true },
    onEditHotel: { type: Function, required: true },
    selectedHotels: { type: Array },
    viewOnly: { type: Boolean },
    projectDateRange: { type: Array },
    hotelReserveAlarm: { type: Boolean },
    excludeLastDayFromBooking: { type: Boolean },
    projectId: { type: String }, // required for uploading documents
  },
  data: function () {
    return {
      today: new Date(),
      localhotelReserveAlarm: false,
      localExcludeLastDayFromBooking: false,
      selectedHotelsWithId: [],
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      formData: {
        selectedHotel: "",
        selectedHotelDateRange: null,
        selectedHotelFreeText: "",
        pricePerNight: "",
        withBreakfast: false,
      },
      formRules: {
        selectedHotel: { required: true, message: "Bitte Hotel auswählen" },
        selectedHotelDateRange: { required: true, message: "Bitte Buchungszeitraum eintragen" },
      },
      documents: [],
      isEditing: false,
      visible: false,
      currentHotelIdx: null, // idx of currently editing hotel
      hotelOptions: [], // list of hotels
      displayedHotelOptions: [], // filtered by search query in select box
      hideOld: true, // to hide records before today ("hotelDateRange" not within today)
    };
  },
  watch: {
    selectedHotels: function (newVal, oldVal) {
      // crutch to provide sort of id's handled on client side to delete records.
      this.selectedHotelsWithId = this.$props.selectedHotels.map((item, idx) => ({ ...item, idx }));
    },
    "$props.hotelReserveAlarm": function (newVal) {
      this.localhotelReserveAlarm = newVal;
    },
    "$props.excludeLastDayFromBooking": function (newVal) {
      this.localExcludeLastDayFromBooking = !!newVal;
    },
  },
  created() {
    this.debounceFilterHotels = debounce(this.filterHotels, 200);
  },
  mounted() {
    this.fetchHotelOptions();
  },
  methods: {
    handleChangeHotelReserveAlarm() {
      this.$emit("changeHotelReserveAlarm");
    },
    handleExcludeLastDayFromBooking() {
      this.$emit("changeExcludeLastDayFromBooking");
    },
    showAddHotelData() {
      this.visible = true;
      this.isEditing = true;
      const today = moment();
      if (this.projectDateRange && moment(this.projectDateRange[0]).isAfter(today)) {
        // this.formData.selectedHotelDateRange = [this.projectDateRange[0], this.projectDateRange[0]];
      } else {
        // this.formData.selectedHotelDateRange = [today.startOf("day").toDate(), today.startOf("day").toDate()];
      }
    },
    addHotel() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const hotel = {
          hotelID: this.formData.selectedHotel,
          hotelDateRange: this.formData.selectedHotelDateRange,
          freeText: this.formData.selectedHotelFreeText,
          documents: this.documents,
          price: this.formData.pricePerNight,
          withBreakfast: this.formData.withBreakfast,
        };

        this.$props.onAddHotel(hotel);
        this.dismissEdit();
      });
    },
    dismissEdit() {
      this.visible = false;
      this.isEditing = false;

      this.formData.selectedHotel = "";
      this.formData.selectedHotelFreeText = "";
      this.documents = [];
      this.formData.selectedHotelDateRange = null;
      this.$refs.upload.clearFiles();
      this.formData.selectedHotel = null;
      this.formData.pricePerNight = "";
      this.formData.withBreakfast = false;
      this.$refs.form.resetFields();
      this.currentHotelIdx = null;
    },
    editHotelData(hotel) {
      this.visible = true;
      this.isEditing = true;
      this.currentHotelIdx = hotel.idx;
      const hotelRecord = this.hotelOptions.find((item) => item.id === hotel.hotelID) || { extendedProps: {} };

      this.formData.selectedHotel = hotel.hotelID;
      this.formData.selectedHotelDateRange = hotel.hotelDateRange;
      this.formData.selectedHotelFreeText = hotel.freeText;
      this.formData.withBreakfast = hotel.withBreakfast
        ? hotel.withBreakfast
        : hotelRecord.extendedProps.breakfastInclusive;
      this.formData.pricePerNight = hotel.price ? hotel.price : hotelRecord.extendedProps.price;
      this.documents = hotel.documents;
    },
    viewHotelData(hotel) {
      this.editHotelData(hotel);
      this.isEditing = false;
    },
    saveEditedHotel() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        const hotel = {
          hotelID: this.formData.selectedHotel,
          hotelDateRange: this.formData.selectedHotelDateRange,
          freeText: this.formData.selectedHotelFreeText,
          withBreakfast: this.formData.withBreakfast,
          price: this.formData.pricePerNight,
          documents: this.documents,
        };

        this.$props.onEditHotel(hotel, this.currentHotelIdx);
        this.dismissEdit();
      });
    },
    removeHotelData(idx) {
      MessageBox.confirm("Hotel löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(() => {
        this.$props.onRemoveHotel(idx);
        if (idx === this.currentHotelIdx) {
          this.currentHotelIdx = null;
        }
      });
    },
    getMetadata() {
      const metadata = `project_${this.projectId}_hotelsReservation_0`;
      return metadata;
    },
    showFile(file) {
      if (file.response && file.response[0]) {
        window.open(file.response[0].url, "_blank");
      } else if (file.url) {
        window.open(file.url, "_blank");
      } else {
        console.warn("Could not parse file. File object below.");
        console.log(JSON.stringify(file, null, 2));
      }
    },
    beforeRemoveFile(file, fileList) {
      return MessageBox.confirm(file.name + " löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      });
    },
    removeFile(file, fileList) {
      let self = this;
      const metadata = this.getMetadata();
      this.axios
        .delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        })
        .then(function (response) {
          self.documents = fileList;
        });
    },
    fileUploaded(response, file, fileList) {
      this.documents = this.documents.concat(response);
    },
    async fetchHotelOptions() {
      try {
        const route = "/api/service-providers";
        const hotels = await this.axios.get(route, { params: { active: true, type: "HOTEL" } });
        this.hotelOptions = hotels.data;
        this.displayedHotelOptions = hotels.data;
      } catch (error) {
        throw error;
      }
    },
    filterHotels(query) {
      if (query !== "") {
        const searchQuery = query.toLowerCase();
        this.displayedHotelOptions = this.hotelOptions.filter((item) => {
          const { city, zip } = get(item, "hrContact", {});
          return [item.name, city, zip].some((term) => term && term.toLowerCase().indexOf(searchQuery) !== -1);
        });
      } else {
        this.displayedHotelOptions = this.hotelOptions.slice();
      }
    },
    getLabelMeta(hotel) {
      const { city, zip } = get(hotel, "hrContact", {});
      let meta = [];
      if (city) {
        meta.push(city);
      }
      if (zip) {
        meta.push(zip);
      }
      return meta.join(", ");
    },
    getHotelName(hotelId) {
      return get(
        this.hotelOptions.find((item) => item.id === hotelId),
        "name",
        ""
      );
    },
    formatHotelDataString(record) {
      const hotelTitle = this.$options.filters.formatHotelTitle(record);
      return hotelTitle;
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    disabledAddHotel() {
      return !this.formData.selectedHotel || !this.formData.selectedHotelDateRange;
    },
    reserveAlarm() {
      return this.hotelReserveAlarm;
    },
    visibleHotels() {
      let visibleHotels;
      // filter records in the past
      if (this.hideOld) {
        const today = moment().startOf("day");
        visibleHotels = this.selectedHotelsWithId.filter((item) => {
          // show only future and current hotel reservations
          return today.isSameOrBefore(item.hotelDateRange[1], "day");
        });
      } else {
        visibleHotels = this.selectedHotelsWithId.slice();
      }
      // provide hotel name
      return visibleHotels.map((item) => {
        const { name = "no hotel name" } = this.hotelOptions.find((hotel) => hotel.id === item.hotelID) || {};
        return { ...item, name };
      });
    },
    isAddingNew() {
      return this.isEditing && typeof this.currentHotelIdx !== "number";
    },
  },
  filters: {
    formatHotelTitle(hotel) {
      const { hotelDateRange: dateRange, name } = hotel;
      if (dateRange && dateRange.length === 2) {
        const start = moment(dateRange[0]).format("DD.MM.YYYY");
        const end = moment(dateRange[1]).format("DD.MM.YYYY");
        return `${name} (${start} - ${end})`;
      }
      return `${name} ( - ?? - )`;
    },
  },
};
</script>

<style>
.el-timeline-item__content p {
  margin-bottom: 5px;
}
.el-timeline-item__content ul {
  padding-left: 20px;
  font-size: 13px;
}
.el-timeline-item__content .free-text {
  color: #9a9a9a;
  font-size: 13px;
  white-space: pre-line;
}
.free-text-wrap {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pre-line {
  white-space: pre-line;
}
.hotel-link {
  margin-top: 8px;
  font-size: 0.8rem;
  color: #4b4b4b;
}
</style>
