<template>
  <div style="padding-left: 10px">
    <!-- ## type = stringList ## -->
    <template v-if="type === 'stringList'">
      <!-- EDIT MODE -->
      <template v-if="editMode">
        <!-- TITLE -->
        <span class="n-profile-label-title">{{ title }}:</span>
        <br />

        <!-- DROPDOWN -->
        <el-dropdown trigger="click" @command="(cmd) => $emit('input', cmd)">
          <!-- SELECTED ITEM LABEL -->
          <span class="n-profile-dropdown-value">
            {{ $attrs.value }} <i class="el-icon-arrow-down el-icon--right"></i>
          </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in items" :key="index" :command="item">
              {{ item }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <!-- VIEW MODE -->
      <template v-else>
        <span class="n-profile-label-title">{{ title }}:</span>
        <br />
        <span class="n-profile-label-value">{{ $attrs.value }}</span>
      </template>
    </template>

    <!-- ## type = complexList ## -->
    <template v-if="type === 'complexList'">
      <!-- EDIT MODE -->
      <template v-if="editMode">
        <!-- TITLE -->
        <span class="n-profile-label-title">{{ title }}:</span>
        <br />
        <!-- DROPDOWN -->
        <el-dropdown trigger="click" @command="(item) => $emit('input', item[id])">
          <!-- SELECTED ITEM LABEL -->
          <span class="n-profile-dropdown-value">
            {{ $attrs.value != null ? items.find((i) => i[id] == $attrs.value)[label] : " — " }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in items" :key="index" :command="item">
              {{ item[label] }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <!-- VIEW MODE -->
      <template v-else>
        <span class="n-profile-label-title">{{ title }}:</span>
        <br />
        <span class="n-profile-label-value">{{
          $attrs.value != null ? items.find((i) => i[id] == $attrs.value)[label] : " — "
        }}</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "profile-dropdown",
  props: {
    id: {
      type: String,
      default: "id",
    },
    label: {
      type: String,
      default: "label",
    },
    type: {
      type: String,
      default: "complexList",
      validator: (obj) => {
        if (obj === "complexList" || obj === "stringList") {
          return true;
        } else {
          return true;
        }
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Titel",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>
