<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-8 ml-auto mr-auto">
              <info-section
                class="mt-5"
                type="danger"
                :title="$t('src.components.generalviews.notfoundpage.marketing')"
                description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                icon="nc-icon nc-alert-circle-i"
              >
                <h2 class="text-white mt-3">{{ $t('src.components.generalviews.notfoundpage.seiteNichtGefunden') }}</h2>
                <small class="text-white"
                  >{{ $t('src.components.generalviews.notfoundpage.oopsDieSeite') }}<br />{{ $t('src.components.generalviews.notfoundpage.hierGehtsZurckZum') }}<router-link to="/">{{ $t('src.components.generalviews.notfoundpage.dashboard') }}</router-link>
                </small>
              </info-section>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url(/static/img/background/jan-sendereks.jpg) "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button } from "element-ui";
import { Checkbox, InfoSection } from "src/components/UIComponents";
import AppNavbar from "./../Dashboard/Views/Pages/Layout/AppNavbar";
import AppFooter from "./../Dashboard/Views/Pages/Layout/AppFooter";
export default {
  components: {
    Card,
    Button,
    AppNavbar,
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox
  },
  methods: {}
};
</script>
<style></style>
