<template>
  <span class="mr-2">
    <el-button :disabled="!serviceProviderType" @click="showModal">{{
      $t("src.components.project.contacts.components.serviceproviderautofill.autoFill")
    }}</el-button>
    <el-dialog width="500px" :visible="visible" @close="handleClose">
      <span class="n-profile-label-title">{{
        $t("src.components.project.contacts.components.serviceproviderautofill.dienstleister")
      }}</span>
      <br />
      <el-select
        filterable
        :filter-method="filterProvidersMethod"
        :multiple="false"
        v-model="selectedItem"
        :placeholder="$t('src.components.project.contacts.components.serviceproviderautofill.dienstleister')"
        :title="$t('src.components.project.contacts.components.serviceproviderautofill.dienstleister')"
      >
        <el-option v-for="item in filteredServiceProviders" :key="item.id" :value="item.id" :label="item.name">
          <span style="float: left" :title="item.name">
            {{ item.name }}
          </span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ getLabelMeta(item) }}</span>
        </el-option>
      </el-select>
      <div class="mt-2">
        <profile-select
          v-if="serviceProviderType === 'SUBCONTRACTOR'"
          filterable
          v-model="selectedSubcontractorContact"
          :multiple="false"
          :items="subcontractorContacts"
          valueIdentifier="_id"
          label="displayName"
          :title="$t('Contact')"
          editMode
        />
      </div>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">{{
            $t("src.components.project.contacts.components.serviceproviderautofill.abbrechen")
          }}</el-button>
          <el-button type="primary" @click="handleSelect" :disabled="isDisabledAdd">{{
            $t("src.components.project.contacts.components.serviceproviderautofill.speichern")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </span>
</template>

<script>
import { get } from "lodash";
import { Button, Dialog } from "element-ui";

export default {
  name: "service-provider-auto-fill",
  props: {
    serviceProviderType: { type: String },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      visible: false,
      serviceProviders: [],
      filteredServiceProviders: [],
      selectedItem: "",
      selectedSubcontractor: "",
      selectedSubcontractorContact: "",
    };
  },
  methods: {
    showModal() {
      this.visible = true;
      this.fetchItems();
    },
    handleClose() {
      this.visible = false;
      this.serviceProviders = [];
      this.filteredServiceProviders = [];
      this.selectedItem = "";
      this.selectedSubcontractor = "";
      this.selectedSubcontractorContact = "";
    },
    async fetchItems() {
      let response;
      if (this.serviceProviderType === "SUBCONTRACTOR") {
        const { data } = await this.axios.get("/api/subcontractor");
        response = data;
      } else {
        const params = { type: this.serviceProviderType };
        const { data } = await this.axios.get("/api/service-providers", { params });
        response = data;
      }
      this.serviceProviders = response;
      this.filteredServiceProviders = response;
    },
    handleSelect() {
      let selectedRecord;
      if (this.serviceProviderType === "SUBCONTRACTOR") {
        selectedRecord = {
          name: this.serviceProviders.find((item) => item.id === this.selectedItem).name,
          hrContact: { ...this.subcontractorContacts.find((item) => item._id === this.selectedSubcontractorContact) },
        };
      } else {
        selectedRecord = this.serviceProviders.find((item) => item.id === this.selectedItem);
      }
      console.log("selectedRecord", selectedRecord);
      this.$emit("onSelect", selectedRecord);
      this.handleClose();
    },
    filterProvidersMethod(value) {
      if (!value) {
        this.filteredServiceProviders = this.serviceProviders.map((item) => ({ name: item.name, id: item.id }));
      } else {
        const query = value.toLowerCase();
        this.filteredServiceProviders = this.serviceProviders.reduce((array, item) => {
          const stringToSearch = `${item.name}${get(item, "hrContact.zip")}${get(
            item,
            "hrContact.city"
          )}`.toLowerCase();
          if (stringToSearch.indexOf(query) !== -1) {
            array.push(item);
          }
          return array;
        }, []);
      }
    },
    getLabelMeta(provider) {
      const { city, zip } = get(provider, "hrContact", {});
      let meta = [];
      if (city) {
        meta.push(city);
      }
      if (zip) {
        meta.push(zip);
      }
      return meta.join(", ");
    },
    filterMethod(query, item) {
      const queries = query.toLowerCase().split(" ");
      const name = get(item, "name", "").toLowerCase();
      const lastName = get(item, "lastName", "").toLowerCase();
      const company = get(item, "company", "").toLowerCase();
      // filter logic defined in comment https://www.goodday.work/t/GvfgaW/s9Pi4V
      return queries.every((q) => name.indexOf(q) > -1 || lastName.indexOf(q) > -1 || company.indexOf(q) > -1);
    },
    filterContacts(query) {},
    getDisplayName(record) {
      let name = "";
      if (record.lastName) {
        name += `${record.lastName}, `;
      }
      name += record.name;
      return name;
    },
  },
  computed: {
    isDisabledAdd() {
      if (this.selectedItem) {
        return this.serviceProviderType === "SUBCONTRACTOR" ? !this.selectedSubcontractorContact : false;
      } else {
        return true;
      }
    },
    subcontractorContacts() {
      if (this.serviceProviderType === "SUBCONTRACTOR") {
        const foundItem = this.serviceProviders.find((item) => item.id === this.selectedItem);
        if (foundItem) {
          const contacts = foundItem.contacts || [];
          return contacts.map((i) => ({ ...i, displayName: this.getDisplayName(i) }));
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
  filters: {
    formatName(record) {
      let name = "";
      if (record.lastName) {
        name += `${record.lastName}, `;
      }
      name += record.name;
      return name;
    },
  },
};
</script>

<style></style>
