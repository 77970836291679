<template>
  <el-dialog
    @close="dismissModal"
    :visible="visible"
    :title="$t('src.components.project.coreservicestable.anmerkungen')"
    width="500px"
  >
    <profile-input
      type="textarea"
      :label="$t('src.components.project.coreservicestable.anmerkungen')"
      v-model="localComment"
      :autosize="{ minRows: 3 }"
      :editMode="isEditCommentMode"
    />
    <template v-slot:footer>
      <span class="flex justify-between">
        <template v-if="isEditCommentMode">
          <cancel-button
            :text="$t('src.components.management.companysettings.workshopprojects.abbrechen')"
            @click="dismissModal"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.companysettings.workshopprojects.speichern')"
            @click="submit"
          ></save-button>
        </template>
        <template v-else-if="canEditComment">
          <el-button class="btn btn-sm btn-ghost" style="width: 120px" @click="editComment">
            <pencil-icon /> Bearbeiten
          </el-button>
        </template>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { Dialog, Message } from "element-ui";

export default {
  name: "event-comment-dialog",
  components: {
    [Dialog.name]: Dialog,
  },
  props: {
    visible: Boolean,
    dataManager: Object,
    payload: Object,
    canEditComment: Boolean,
  },
  data() {
    return {
      isViewMode: true,
      localComment: "",
    };
  },
  methods: {
    dismissModal() {
      this.$emit("dismissModal");
    },
    editComment() {
      this.isViewMode = false;
    },
    async submit() {
      try {
        await this.dataManager.editComment(this.payload.eventId, this.localComment);
        this.dismissModal();
        this.$emit("update");
        Message.success("Datei wurde hinzugefügt");
      } catch (error) {
        throw error;
      }
    },
  },
  computed: {
    isEditCommentMode() {
      // if no comment - start with edit mode already, otherwise - edit mode is activated by button
      return this.payload.comment ? !this.isViewMode : true;
    },
  },
  watch: {
    "payload.comment": function (newVal) {
      if (newVal) {
        this.localComment = newVal;
      }
    },
    visible(newVal) {
      if (!newVal) {
        this.isViewMode = true;
        this.localComment = "";
      }
    },
  },
};
</script>
