<template>
  <el-dialog :visible.sync="visible" width="900px" custom-class="hotel-info-modal">
    <template #title>
      <div style="margin-bottom: 12px">
        <div class="hotel-info-title">
          <div class="hotel-cell booked"></div>
          {{ $t("src.components.project.bryntumscheduler.hotelinfo.hotelDetails") }}
        </div>
        <div class="hotel-info-name">{{ projectName }}</div>
      </div>
    </template>
    <div v-loading="loading">
      <div v-for="(entry, idx) in providerData" :key="entry._id">
        <el-descriptions :title="entry.name" :column="4" direction="vertical" :border="true" class="mb-4">
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.buchungszeitraum')">{{
            entry.dateRange | formatDateRange
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.addresse')">
            {{ entry.addressString }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.phone')">
            {{ entry.phone }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.handy')">
            {{ entry.mobile }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.fax')">
            {{ entry.fax }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.eMail')">
            {{ entry.email }}
          </el-descriptions-item>
          <el-descriptions-item label="Preis / Person / Nacht">
            {{ entry.price | formatPrice }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.frhstckInklusive')">
            {{ entry.withBreakfast }}
          </el-descriptions-item>
          <el-descriptions-item :label="$t('src.components.project.bryntumscheduler.hotelinfo.freiText')">
            {{ entry.freeText }}
          </el-descriptions-item>
        </el-descriptions>
        <div v-if="entry.documents.length">
          <div class="hotel-info-section-title mb-2">
            {{ $t("src.components.project.bryntumscheduler.hotelinfo.datei") }}
          </div>
          <drawer-upload :data="{ metadata: '' }" :file-list="entry.documents" :disabled="true" />
        </div>
        <!-- <el-divider v-if="idx" /> -->
        <!-- <div class="hotel-info-section-label">
          {{ $t("src.components.project.bryntumscheduler.hotelinfo.buchungszeitraum") }}
        </div>
        <div class="hotel-info-section-value">{{ entry.dateRange | formatDateRange }}</div>
        <div class="hotel-info-section-label">
          {{ $t("src.components.project.bryntumscheduler.hotelinfo.hotelName") }}
        </div>
        <div class="hotel-info-section-value">{{ entry.name }}</div>
        <div class="hotel-info-section-label">
          {{ $t("src.components.project.bryntumscheduler.hotelinfo.addresse") }}
        </div>
        <div class="hotel-info-section-value">{{ entry.addressString }}</div>
        <div class="hotel-info-section-label">{{ $t("src.components.project.bryntumscheduler.hotelinfo.phone") }}</div>
        <div class="hotel-info-section-value">{{ entry.phone }}</div>
        <div class="hotel-info-section-label">{{ $t("src.components.contacts.contactdetails.handy") }}</div>
        <div class="hotel-info-section-value">{{ entry.mobile }}</div>
        <div class="hotel-info-section-label">{{ $t("src.components.project.bryntumscheduler.hotelinfo.fax") }}</div>
        <div class="hotel-info-section-value">{{ entry.fax }}</div>
        <div class="hotel-info-section-label">{{ $t("src.components.project.bryntumscheduler.hotelinfo.eMail") }}</div>
        <div class="hotel-info-section-value">{{ entry.email }}</div>

        <div class="hotel-info-section-label">Preis / Person / Nacht</div>
        <div class="hotel-info-section-value">{{ entry.price }}</div>
        <div class="hotel-info-section-label">
          {{ $t("src.components.project.bryntumscheduler.hotelinfo.frhstckInklusive") }}
        </div>
        <div class="hotel-info-section-value">{{ entry.withBreakfast }}</div>
        <div class="hotel-info-section-label">
          {{ $t("src.components.project.bryntumscheduler.hotelinfo.freiText") }}
        </div>
        <div class="hotel-info-section-value pre-line">{{ entry.freeText }}</div>
        <div v-if="entry.documents.length">
          <div class="hotel-info-section-title mb-2">
            {{ $t("src.components.project.bryntumscheduler.hotelinfo.datei") }}
          </div>
          <drawer-upload :data="{ metadata: '' }" :file-list="entry.documents" :disabled="true" />
        </div> -->
      </div>
    </div>
    <template v-slot:footer>
      <div v-if="showButtons">
        <div class="text-right mt-3">
          <cancel-button class="mr-2" @click="printHotelReport" :loading="printLoading"
            ><printer-icon />{{ $t("src.components.project.bryntumscheduler.hotelinfo.print") }}</cancel-button
          >
          <cancel-button @click="downloadHotelReport" :loading="downloadLoading"
            ><download-icon />{{ $t("src.components.project.bryntumscheduler.hotelinfo.download") }}</cancel-button
          >
          <!-- <router-link
          class="mr-2"
          :to="{ name: 'Project View', params: { id: projectId }, query: { scrollTo: 'hotel-section' } }"
          >{{ $t('src.components.project.bryntumscheduler.hotelinfo.linkZumHotelReservierung') }}</router-link
        >
        <router-link :to="{ name: 'ServiceProvider View', params: { id: providerData.id } }"
          >{{ $t('src.components.project.bryntumscheduler.hotelinfo.linkZumHotel') }}</router-link
        > -->
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Dialog, Message, Button, Divider, Descriptions, DescriptionsItem } from "element-ui";
import { moment } from "src/config/moment";
import PrinterIcon from "vue-material-design-icons/Printer";
import DownloadIcon from "vue-material-design-icons/Download";
import DrawerUpload from "../Invoices/DrawerUpload.vue";
import printJs from "print-js";

export default {
  name: "hotel-info",
  props: {
    getHotelsForDay: { type: Function, required: true },
    hideButtons: Boolean,
  },
  components: {
    [DrawerUpload.name]: DrawerUpload,
    [Descriptions.name]: Descriptions,
    [DescriptionsItem.name]: DescriptionsItem,
    [Divider.name]: Divider,
    [Dialog.name]: Dialog,
    [DownloadIcon.name]: DownloadIcon,
    [PrinterIcon.name]: PrinterIcon,
    [Message.name]: Message,
    [Button.name]: Button,
  },
  data() {
    return {
      visible: false,
      loading: false,
      downloadLoading: false,
      printLoading: false,
      projectId: null,
      projectName: "",
      providerData: [],
      showButtons: !this.hideButtons,
    };
  },
  methods: {
    async fetchData(hotelData) {
      try {
        this.loading = true;
        const { projectName, records } = this.$props.getHotelsForDay(hotelData);
        this.projectName = projectName;
        console.log("projectName, records", projectName, records);
        const providerData = await Promise.all(
          records.map(async (record) => {
            const response = await this.axios.get(`/api/service-providers/${record.hotelID}`);
            const { name, hrContact } = response.data;
            const addressString = [
              [hrContact.street, hrContact.no].filter(Boolean).join(" "),
              [hrContact.zip, hrContact.city].filter(Boolean).join(" "),
              hrContact.country,
            ]
              .filter((item) => (Array.isArray(item) ? item.length : Boolean(item)))
              .join(", ");
            return {
              reportRecordId: record._id,
              id: record.hotelID,
              name: name,
              dateRange: record.hotelDateRange,
              price: record.price || "-",
              withBreakfast: record.withBreakfast ? "Ja" : "Nein",
              addressString: addressString.trim() || "-",
              phone: hrContact.phone ? hrContact.phone : "-",
              email: hrContact.email || "-",
              fax: hrContact.fax || "-",
              mobile: hrContact.mobile || "-",
              freeText: record.freeText || "-",
              documents: record.documents,
            };
          })
        );
        console.log("providerData", providerData);
        this.providerData = providerData;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async showInfo(hotelData) {
      try {
        this.visible = true;
        this.projectId = hotelData.projectId;
        await this.fetchData(hotelData);
      } catch (error) {
        throw error;
      }
    },
    async requestReport() {
      const formBody = {
        projectId: this.projectId,
        hotelRecordIds: this.providerData.map(({ reportRecordId }) => reportRecordId),
      };
      const response = await this.axios.request({
        method: "GET",
        url: "/api/projects/hotel-report-pdf",
        params: formBody,
        responseType: "blob",
      });
      const blob = response.data;
      let filename;
      const contentDisposition = response.headers["Content-Disposition"];
      if (contentDisposition) {
        filename = contentDisposition.match('filename="([^"]*)"')[1];
      } else {
        filename = `hotel-report.pdf`;
      }
      const file = new File([blob], filename, { type: "application/octet-stream" });
      Message({
        message: "PDF erstellt",
        type: "success",
      });
      return file;
    },
    async printHotelReport() {
      try {
        this.printLoading = true;
        const objectUrl = await this.requestReport().then((file) => window.URL.createObjectURL(file));
        printJs({
          printable: objectUrl,
          type: "pdf",
          onError: (err) => {
            throw err;
          },
        });
        setTimeout(() => URL.revokeObjectURL(objectUrl), 3000);
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.printLoading = false;
      }
    },
    async downloadHotelReport() {
      try {
        this.downloadLoading = true;
        const file = await this.requestReport();
        const objectUrl = window.URL.createObjectURL(file);
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(objectUrl, file.name);
        } else {
          const downloadLink = document.createElement("a");
          document.body.appendChild(downloadLink);
          // Create a link to the file
          downloadLink.href = objectUrl;
          // Setting the file name
          downloadLink.download = file.name;
          //triggering the function
          downloadLink.click();
          downloadLink.remove();
          setTimeout(() => URL.revokeObjectURL(objectUrl), 3000);
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.downloadLoading = false;
      }
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        this.projectId = null;
        this.projectName = "";
        this.providerData = [];
      }
    },
  },
  filters: {
    formatDateRange(dateRange = []) {
      const str = dateRange.map((date) => moment(date).format("L")).join(" - ");
      return str ? `${str}` : "";
    },
    formatPrice(price) {
      if (isNaN(price)) {
        return "-";
      } else {
        return Number(price).toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.hotel-info-modal {
  .el-dialog__footer {
    border-top: 1px solid #ebebeb;
  }

  .el-dialog__body {
    border-top: 1px solid #ebebeb;
    padding-top: 15px;
  }
}

.hotel-info-title {
  font-weight: bold;

  .hotel-cell {
    height: 40px;
    width: 40px;
    font-size: 22px;
    display: inline-block;
    margin-right: 10px;
  }
}

.hotel-info-section-title {
  font-size: 18px;
  color: #727272;
}

.hotel-info-section-label {
  font-size: 12px;
  color: #727272;
}

.hotel-info-section-value {
  margin-bottom: 12px;
  color: #000;
}

.hotel-info-name {
  font-size: 24px;
  margin-top: 15px;
}
</style>
