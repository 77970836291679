import { moment } from "src/config/moment";

export class CreateEventReq {
  constructor(formBody) {
    const requiredProps = {
      uid: String,
      title: String,
      start: Date,
      end: Date,
      resourceId: String,
      resourceType: String,
      projectId: String,
      quantity: String,
      comment: String,
    };
    if (Object.keys(formBody).some((key) => !requiredProps[key])) {
      console.warn(JSON.stringify(formBody, null, 2));
      throw new Error("Invalid form body");
    }
    this.uid = formBody.uid;
    this.title = formBody.title;
    this.start = moment(formBody.start).format("YYYY-MM-DD");
    this.end = moment(formBody.end).format("YYYY-MM-DD");
    this.resourceId = formBody.resourceId;
    this.resourceType = formBody.resourceType;
    this.projectId = formBody.projectId;
    this.comment = formBody.comment;
    this.quantity = typeof formBody.quantity === "string" ? +formBody.quantity : formBody.quantity;
  }
}
