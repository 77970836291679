<template>
  <div>
    <div class="type-settings-wrapper">
      <div style="flex-grow: 1">
        <profile-select
          :editMode="true"
          :title="`Projekttypen (${projectTypes.length})`"
          :multiple="false"
          :items="projectTypes"
          v-model="selectedTypeId"
          valueIdentifier="_id"
          label="name"
          :disableInactive="false"
          :hideInactive="false"
        />
      </div>
      <div v-if="isEditMode" style="margin-left: 6px; margin-bottom: 6px">
        <el-button class="btn btn-sm btn-ghost" @click="showEditForm">
          <plus-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="editType">
          <pencil-icon />
        </el-button>
        <el-button v-if="$can('delete', 'project')" class="btn btn-sm btn-ghost" @click="deleteType">
          <trash-can-outline-icon />
        </el-button>
      </div>
    </div>
    <!-- DIALOG -->
    <el-dialog
      :title="$t('src.components.management.projectsettings.projecttypesettings.projekttypKonfiguration')"
      :visible.sync="visible"
      @close="dismiss"
      width="800px"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="formData" :rules="formRules" :disabled="!editable">
        <el-form-item
          :label="$t('src.components.management.projectsettings.projecttypesettings.active')"
          prop="active"
          label-width="200px"
        >
          <profile-switch v-model="formData.active" no-title></profile-switch>
        </el-form-item>
        <el-form-item
          :label="$t('src.components.management.projectsettings.projecttypesettings.name')"
          prop="name"
          label-width="200px"
        >
          <el-input autofocus v-model="formData.name" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          prop="documentTypes"
          :label="$t('src.components.management.projectsettings.projecttypesettings.dokumenttypen')"
          label-width="200px"
        >
          <el-select
            multiple
            v-model="formData.documentTypes"
            :placeholder="$t('src.components.management.projectsettings.projecttypesettings.dokumenttypen')"
          >
            <el-option v-for="item in docTypes" :key="item._id" :label="item.name" :value="item._id" />
          </el-select>
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.management.projectsettings.projecttypesettings.abbrechen')"
            @click="dismiss"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.projectsettings.projecttypesettings.speichern')"
            @click="saveType"
          ></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Message, Form, FormItem, Input, MessageBox } from "element-ui";
import ProfileSwitch from "../../UIComponents/Inputs/ProfileSwitch.vue";

export default {
  name: "project-type-settings",
  props: {
    isEditMode: Boolean,
  },
  components: {
    [MessageBox.name]: MessageBox,
    [Form.name]: Form,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [ProfileSwitch.name]: ProfileSwitch,
    [Input.name]: Input,
  },
  data() {
    return {
      visible: false,
      editable: false,
      projectTypes: [],
      docTypes: [],
      formRules: {
        name: {
          required: true,
          message: "Bitte Name auswählen",
          trigger: "change",
        },
        documentTypes: {
          required: true,
          message: "Bitte Dokumenttypen auswählen",
          trigger: "change",
        },
      },
      selectedTypeId: "",
      formData: {
        active: true,
        name: "",
        documentTypes: [],
      },
    };
  },
  mounted() {
    this.fetch();
    this.$root.$on("settingsChanged", (data) => {
      if (data.modelType === "project" && data.modelID === "docTypes" && data.operation === "loaded") {
        this.docTypes = data.options ? data.options.slice() : [];
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
  },
  methods: {
    async fetch() {
      try {
        const prTypes = await this.axios.get("/api/project-types");
        const prDocTypes = await this.axios.get("/api/project-document-types");
        this.projectTypes = prTypes.data || [];
        this.docTypes = prDocTypes.data || [];
        this.notify("loaded", prTypes.data);
        this.notifyDocTypes("loaded", prDocTypes.data);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    dismiss() {
      this.visible = false;
      this.editable = false;
      this.$refs.form.resetFields();
    },
    showEditForm() {
      this.visible = true;
      this.editable = true;
    },
    editType() {
      if (!this.selectedTypeId) {
        MessageBox.alert("Bitte Projekttyp auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.showEditForm();

      const selectedRecord = this.projectTypes.find((item) => item._id === this.selectedTypeId) || {};
      this.formData.active = selectedRecord.active;
      this.formData.name = selectedRecord.name;
      this.formData.documentTypes = selectedRecord.documentTypes || [];
    },
    deleteType() {
      if (!this.selectedTypeId) {
        MessageBox.alert("Projekttyp auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/project-types/${this.selectedTypeId}`);
          this.projectTypes = this.projectTypes.filter((item) => item._id !== this.selectedTypeId);
          this.selectedTypeId = "";
          this.notify("deleted", this.projectTypes.slice());
        } catch (error) {
          if (error.response && error.response.status === 403) {
            Message.error("Der Projekttyp wird in einigen Projekten verwendet");
          }
          throw error;
        }
      });
    },
    async saveType() {
      try {
        await this.$refs.form.validate();
        if (this.selectedTypeId) {
          // edit
          await this.axios.put(`/api/project-types/${this.selectedTypeId}`, this.formData);
        } else {
          // create
          await this.axios.post(`/api/project-types`, this.formData);
        }
        this.fetch();
        this.dismiss();
      } catch (error) {
        // form validation exception - just return
        if (typeof error === "boolean") {
          return;
        }
        Message.error(error.message);
      }
    },
    /**
     * Common eventbus payload interface with TypeSettings.
     * @param {('loaded'|'created'|'updated'|'deleted')} operation
     * @param {Array} options
     */
    notify(operation, options) {
      const data = {
        modelType: "project",
        modelID: "projectTypes",
        operation: operation,
        options: options,
      };
      this.$root.$emit("settingsChanged", data);
      this.$emit("dataChanged", data);
    },
    notifyDocTypes(operation, options) {
      const data = {
        modelType: "project",
        modelID: "docTypes",
        operation: operation,
        options: options,
      };
      this.$root.$emit("settingsChanged", data);
      this.$emit("dataChanged", data);
    },
  },
};
</script>

<style></style>
