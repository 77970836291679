<template>
  <div class="d-flex">
    <cancel-button @click.prevent="$emit('discard')">{{
      $t("src.components.contacts.contactdetails.abbrechen")
    }}</cancel-button>
    <save-button :disabled="!selectedArticles.length" @click.prevent="isVisible = true" :text="$t('selectResources')" />
    <el-dialog :visible.sync="isVisible" :title="$t('selectResources')">
      <el-form label-position="top" :model="formData" ref="form" :rules="formDataRules">
        <el-form-item prop="selectedResources">
          <profile-select
            :filterable="true"
            style="margin-top: 4px"
            :required="true"
            :multiple="true"
            :reserve-keyword="true"
            v-model="formData.selectedResources"
            :title="$t('selectResources')"
            :editMode="true"
            :items="resourceOptions"
            size="small"
          />
        </el-form-item>
        <div class="flex justify-between">
          <el-button @click="closeDialog">{{ $t("src.components.contacts.contactdetails.abbrechen") }}</el-button>
          <el-button type="primary" @click="submitData">{{ $t("submit") }}</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Message, Button, Dialog, Form, FormItem } from "element-ui";

export default {
  name: "copy-supplier-dialog",
  components: {
    [Message.name]: Message,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    currentResourceId: { type: String, required: true },
    resourceType: { type: String, required: true },
    selectedArticles: { type: Array, required: true },
  },
  data() {
    return {
      isVisible: false,
      resourceOptions: [],
      formDataRules: {
        selectedResources: { message: "Bitte auswählen", required: true },
      },
      formData: {
        selectedResources: [],
      },
    };
  },
  mounted() {
    this.fetchResources();
  },
  methods: {
    async fetchResources() {
      try {
        const resourceTypeToUrl = {
          machine: "machines",
          vehicle: "vehicles",
          rhb: "rhb",
          supply: "supply",
        };
        this.resourceOptions = await this.axios.get(`/api/${resourceTypeToUrl[this.resourceType]}/meta`).then((res) =>
          res.data
            .map((item) => ({ ...item, id: item.id || item._id }))
            .filter((item) => item.active && item.id !== this.currentResourceId)
            .map((item) => ({ label: item.name, value: item.id }))
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    closeDialog() {
      this.isVisible = false;
      this.$refs.form.resetFields();
    },
    submitData() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          const formData = {
            articleIds: this.selectedArticles.map((item) => item._id),
            resourceType: this.resourceType,
            resourceIds: this.formData.selectedResources,
          };
          await this.axios.post("/api/supplier-articles/copy", formData);
          Message.success("Kopiert!");
          this.$emit("discard");
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
  },
};
</script>

<style></style>
