<template>
  <el-button @click="handleClick" :loading="loading" type="primary" v-bind="$attrs">
    <template v-slot:label>
      <i class="el-icon-check"></i>
    </template>
    <!-- TODO: IS this {{ text }} correct here -->
    {{ text }}
  </el-button>
</template>

<script>
export default {
  name: "save-button",
  props: {
    loading: Boolean,
    text: {
      required: true,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style></style>
