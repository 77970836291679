<template>
  <el-dialog title="Tages-Info" :visible.sync="visible" width="600px" @closed="dismiss">
    <div style="white-space: pre">{{ infoString }}</div>
  </el-dialog>
</template>
<script>
import { Dialog } from "element-ui";

export default {
  name: "project-cell-info",
  props: {},
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      visible: false,
      infoString: "",
    };
  },
  methods: {
    showInfo(infoString) {
      this.visible = true;
      this.infoString = infoString;
    },
    dismiss() {
      this.visible = false;
      this.infoString = "";
    },
  },
};
</script>
