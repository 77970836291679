<template>
  <div class="n-edit-wrapper" style="overvlow: hidden">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.administration.usergroup.profile.benutzerguppen")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/administration/user-groups' }">{{
            $t("src.components.administration.usergroup.profile.benutzerguppenListe")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title d-flex align-items-center">
          <span v-if="isNewProfile">
            {{ title }}
          </span>
          <div v-else class="d-flex" style="width: 400px">
            <el-input v-if="isEditingName" v-model="userGroup.name" />
            <span v-else>{{ userGroup.name }}</span>
            <template v-if="canEdit">
              <span v-if="!isEditingName" class="title-edit" @click="isEditingName = true">
                <pencil-icon class="mr-1" /> Bearbeiten
              </span>
              <span v-else class="title-edit" @click="handleCancelEditName">
                <close-icon class="mr-1" /> Abbrechen</span
              >
            </template>
          </div>
        </div>
      </el-col>
      <el-col :span="12" class="profile-buttons-wrapper">
        <cancel-button icon="el-icon-arrow-left" @click="goBack">{{
          $t("src.components.administration.usergroup.profile.zurck")
        }}</cancel-button>
        <template v-if="canEdit">
          <delete-button v-if="$route.params.id !== 'new'" @click="cancelProfile" />
          <save-button @click="updateProfile" :disabled="!userGroup.name" />
        </template>
      </el-col>
    </el-row>

    <div class="card card-user" v-loading="!isFetched && loading">
      <div class="card-body" style="padding-top: 0; padding-bottom: 0">
        <div v-if="isNewProfile">
          <h6 class="title">{{ $t("src.components.administration.usergroup.profile.allgemein") }}</h6>
          <div class="form-group row">
            <div class="col-md-3">
              <profile-input
                :editMode="canEdit"
                :label="$t('src.components.administration.usergroup.profile.name')"
                :placeholder="$t('src.components.administration.usergroup.profile.name')"
                v-model="userGroup.name"
              />
            </div>
          </div>
          <hr />
          <el-alert
            :title="$t('src.components.administration.usergroup.profile.legenSieDen')"
            type="info"
            center
            show-icon
            :closable="false"
          >
          </el-alert>
        </div>
        <div v-else>
          <div class="d-flex align-items-stretch flex-nowrap">
            <div style="width: 250px; margin-left: -15px; flex-shrink: 0" class="position-relative">
              <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: calc(100vh - 185px)">
                <el-menu :default-active="selectedPath" class="sidebar-card-menu" @select="handleSelect">
                  <el-menu-item index="dashboard"> Dashboard </el-menu-item>
                  <el-menu-item index="project-list"> Projekte </el-menu-item>
                  <el-menu-item index="project-calendar" key="project-calendar"> Kalender </el-menu-item>
                  <el-submenu index="machine">
                    <template v-slot:title> Maschinen </template>
                    <el-menu-item index="list" :class="{ 'is-active': selectedPath === 'machine-list' }">
                      Maschinen
                    </el-menu-item>
                    <el-menu-item index="rhb" :class="{ 'is-active': selectedPath === 'machine-rhb' }">
                      RHB
                    </el-menu-item>
                    <el-menu-item index="supply" :class="{ 'is-active': selectedPath === 'machine-supply' }">
                      Verbrauchsmaterialien
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item index="vehicle"> KFZ </el-menu-item>
                  <el-menu-item index="employee"> Mitarbeiter </el-menu-item>
                  <el-submenu index="providers">
                    <template v-slot:title> Einkauf </template>
                    <el-menu-item
                      index="service_provider"
                      :class="{ 'is-active': selectedPath === 'providers-service_provider' }"
                    >
                      Dienstleister
                    </el-menu-item>
                    <el-menu-item index="supplier" :class="{ 'is-active': selectedPath === 'providers-supplier' }">
                      Lieferanten
                    </el-menu-item>
                    <el-menu-item
                      index="subcontractor"
                      :class="{ 'is-active': selectedPath === 'providers-subcontractor' }"
                    >
                      Nachunternehmer
                    </el-menu-item>
                  </el-submenu>
                  <el-submenu index="management">
                    <template v-slot:title> Management </template>
                    <el-menu-item index="contacts"> Kontakte </el-menu-item>
                    <el-menu-item index="finance"> Finance </el-menu-item>
                  </el-submenu>
                  <el-submenu index="administration">
                    <template v-slot:title> Administration </template>
                    <el-menu-item
                      index="company_settings"
                      :class="{ 'is-active': selectedPath === 'administration-company_settings' }"
                    >
                      Firmeneinstellungen
                    </el-menu-item>
                    <el-menu-item
                      index="project_settings"
                      :class="{ 'is-active': selectedPath === 'administration-project_settings' }"
                    >
                      Projekt Einstellungen
                    </el-menu-item>
                    <el-menu-item index="user" :class="{ 'is-active': selectedPath === 'administration-user' }">
                      Benutzer
                    </el-menu-item>
                    <el-menu-item
                      index="usergroup"
                      :class="{ 'is-active': selectedPath === 'administration-usergroup' }"
                    >
                      Benutzerguppen
                    </el-menu-item>
                    <el-menu-item
                      index="excel_sync"
                      :class="{ 'is-active': selectedPath === 'administration-excel_sync' }"
                    >
                      Excel Sync
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item index="sma" :class="{ 'is-active': selectedPath === 'sma' }"> SMA </el-menu-item>
                </el-menu>
              </perfect-scrollbar>
            </div>
            <div style="width: 100%; margin-right: -15px" v-if="isFetched">
              <perfect-scrollbar
                ref="scrollContainer"
                class="_profile-scrollbar"
                :options="{ suppressScrollX: true }"
                style="height: calc(100vh - 185px)"
              >
                <div v-show="selectedPath === 'dashboard'">
                  <div class="group-title">Dashboard</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.dashboardGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      v-model="userGroup.dashboard.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.dashboardGeneralDescription')"
                      label="Dashboard"
                    />
                  </access-rights-table>
                  <dashboard-access
                    v-model="userGroup.dashboard.specificAccess"
                    :maximumAccess="userGroup.dashboard.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'project-list'">
                  <div class="group-title">{{ $t("List") }}</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.projectListGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.project.generalAccess"
                      :description="$t('userGroupProfile.projectListGeneralDescription')"
                      :canEdit="canEdit"
                    />
                  </access-rights-table>
                  <project-profile-access
                    v-model="userGroup.project.specificAccess"
                    :maximumAccess="userGroup.project.generalAccess"
                    :canEdit="canEdit"
                  />
                  <div class="group-title">{{ $t("Notes") }}</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.notesGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('Privileged user')"
                      v-model="userGroup.project_notes.isPrivileged"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.privilegedNotesDescription')"
                      :booleanMode="true"
                    />
                  </access-rights-table>
                  <div class="group-title">{{ $t("ToDos") }}</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.todosGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('Privileged user')"
                      v-model="userGroup.project_todos.isPrivileged"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.privilegedTodosDescription')"
                      :booleanMode="true"
                    />
                  </access-rights-table>

                  <div class="group-title">Dauerprojekte</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.workshopGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      label="Dauerprojekte"
                      v-model="userGroup.workshop_projects.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.workshopGeneralDescription')"
                      :with-edit="false"
                      :with-delete="false"
                    />
                  </access-rights-table>
                  <workshop-projects-profile-access
                    v-model="userGroup.workshop_projects.specificAccess"
                    :maximumAccess="userGroup.workshop_projects.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'project-calendar'">
                  <div class="group-title">{{ $t("Calendar") }}</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.calendarGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('Calendar')"
                      v-model="userGroup.calendar.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.calendarGeneralDescription')"
                    />
                  </access-rights-table>
                  <spm-calendar-access
                    v-model="userGroup.calendar.specificAccess"
                    :maximumAccess="userGroup.calendar.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'machine-list'">
                  <div class="group-title">{{ $t("Machines") }}</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.machineGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.machine.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.machineGeneralDescription')"
                    />
                  </access-rights-table>
                  <machine-profile-access
                    v-model="userGroup.machine.specificAccess"
                    :maximumAccess="userGroup.machine.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'machine-rhb'">
                  <div class="group-title">RHB</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.rhbGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.rhb.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.rhbGeneralDescription')"
                    />
                  </access-rights-table>
                  <rhb-profile-access
                    v-model="userGroup.rhb.specificAccess"
                    :maximumAccess="userGroup.rhb.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'machine-supply'">
                  <div class="group-title">Verbrauchsmaterialien</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.supplyGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.supply.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.supplyGeneralDescription')"
                    />
                  </access-rights-table>
                  <supply-profile-access
                    v-model="userGroup.supply.specificAccess"
                    :maximumAccess="userGroup.supply.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'vehicle'">
                  <div class="group-title">KFZ</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.vehicleGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.vehicle.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.vehicleGeneralDescription')"
                    />
                  </access-rights-table>
                  <vehicle-profile-access
                    v-model="userGroup.vehicle.specificAccess"
                    :maximumAccess="userGroup.vehicle.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'employee'">
                  <div class="group-title">Mitarbeiter</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.employeeGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.employee.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.employeeGeneralDescription')"
                    />
                  </access-rights-table>
                  <employee-profile-access
                    v-model="userGroup.employee.specificAccess"
                    :maximumAccess="userGroup.employee.generalAccess"
                    :canEdit="canEdit"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'providers-service_provider'">
                  <div class="group-title">Dienstleister</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.serprovGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.service_provider.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.serprovGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'providers-supplier'">
                  <div class="group-title">Lieferanten</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.supplierGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.supplier.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.supplierGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'providers-subcontractor'">
                  <div class="group-title">Nachunternehmer</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.subcontractorGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.subcontractor.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.subcontractorGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'management-contacts'">
                  <div class="group-title">Kontakte</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.contactsGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.contacts.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.contactsGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'management-finance'">
                  <div class="group-title">Finance</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.financeGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.finance.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.financeGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'administration-company_settings'">
                  <div class="group-title">Firmeneinstellungen</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.compSettingsGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.company_settings.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.compSettingsGeneralDescription')"
                    />
                  </access-rights-table>
                  <settings-specific-access
                    :canEdit="canEdit"
                    :maximumAccess="userGroup.company_settings.generalAccess"
                    v-model="userGroup.company_settings.specificAccess"
                  />
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'administration-project_settings'">
                  <div class="group-title">Projekt Einstellungen</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.projectSettingsGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.project_settings.generalAccess"
                      :withDelete="false"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.projectSettingsGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'administration-user'">
                  <div class="group-title">Benutzer</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.userGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.user.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.userGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'administration-usergroup'">
                  <div class="group-title">Benutzerguppen</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.userGroupGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.usergroup.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.userGroupGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'administration-excel_sync'">
                  <div class="group-title">Excel Sync</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.excelSyncGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.excel_sync.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.excelSyncGeneralDescription')"
                    />
                  </access-rights-table>
                </div>
                <!-- v-show end -->
                <div v-show="selectedPath === 'sma'">
                  <div class="group-title">SMA</div>
                  <div class="group-subtitle">
                    {{ $t("General access") }}
                    <div class="group-description">
                      {{ $t("userGroupProfile.smaGeneralDescription") }}
                    </div>
                  </div>
                  <access-rights-table>
                    <access-rights-row
                      :label="$t('List')"
                      v-model="userGroup.sma.generalAccess"
                      :canEdit="canEdit"
                      :description="$t('userGroupProfile.smaGeneralDescription')"
                    />
                  </access-rights-table>
                  <sma-access
                    v-model="userGroup.sma.specificAccess"
                    :maximumAccess="userGroup.sma.generalAccess"
                    :canEdit="canEdit"
                    :notesPrivileged="userGroup.project_notes.isPrivileged"
                    :todosPrivileged="userGroup.project_todos.isPrivileged"
                    @notesPrivilegedChange="handleNotesPrivilegedChange"
                    @todosPrivilegedChange="handleTodosPrivilegedChange"
                  />
                </div>
                <!-- v-show end -->
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message, Tooltip, Alert, Menu, MenuItem, Submenu } from "element-ui";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import CloseIcon from "vue-material-design-icons/Close.vue";
import InformationIcon from "vue-material-design-icons/Information";
import SaveButton from "../../UIComponents/Buttons/SaveProfileButton.vue";
import DeleteButton from "../../UIComponents/Buttons/DeleteProfileButton.vue";
import CancelButton from "../../UIComponents/Buttons/CancelProfileButton.vue";
import AccessRightSwitcher from "./Components/AccessRightSwitcher.vue";
import ProjectProfileAccess from "./Components/ProjectProfileAccess.vue";
import MachineProfileAccess from "./Components/MachineProfileAccess.vue";
import VehicleProfileAccess from "./Components/VehicleProfileAccess.vue";
import SupplyProfileAccess from "./Components/SupplyProfileAccess.vue";
import EmployeeProfileAccess from "./Components/EmployeeProfileAccess.vue";
import RhbProfileAccess from "./Components/RhbProfileAccess.vue";
import SpmCalendarAccess from "./Components/SpmCalendarAccess.vue";
import SmaAccess from "./Components/SmaAccess.vue";
import ProfileSwitch from "../../UIComponents/Inputs/ProfileSwitch.vue";
import DashboardAccess from "./Components/DashboardAccess.vue";
import SettingsSpecificAccess from "./Components/SettingsSpecificAccess.vue";
import WorkshopProjectsProfileAccess from "./Components/WorkshopProjectsProfileAccess.vue";
import AccessRightsRow from "./Components/AccessRightsRow.vue";
import AccessRightsTable from "./Components/AccessRightsTable.vue";

export default {
  name: "userGroup-profile",
  components: {
    PerfectScrollbar,
    [InformationIcon.name]: InformationIcon,
    [Tooltip.name]: Tooltip,
    [Message.name]: Message,
    [Alert.name]: Alert,
    [CloseIcon.name]: CloseIcon,
    [Menu.name]: Menu,
    [MenuItem.name]: MenuItem,
    [Submenu.name]: Submenu,
    SaveButton,
    DeleteButton,
    CancelButton,
    AccessRightSwitcher,
    AccessRightsRow,
    AccessRightsTable,
    ProjectProfileAccess,
    MachineProfileAccess,
    VehicleProfileAccess,
    SupplyProfileAccess,
    EmployeeProfileAccess,
    RhbProfileAccess,
    SpmCalendarAccess,
    SmaAccess,
    ProfileSwitch,
    DashboardAccess,
    SettingsSpecificAccess,
    WorkshopProjectsProfileAccess,
  },
  data() {
    return {
      loading: false,
      isFetched: false,
      isEditingName: false,
      activeTab: "dashboard",
      projectTab: "spm_calendar",
      machinesTab: "machine",
      serviceProviderTab: "service_provider",
      administrationTab: "company_settings",
      currentUserGroupName: "",
      selectedPath: "dashboard",
      userGroup: {
        name: "",
      },
    };
  },
  async mounted() {
    this.fetchGroup();
  },
  methods: {
    async fetchGroup() {
      if (!this.isNewProfile) {
        try {
          this.loading = true;
          this.isFetched = false;
          const response = await this.axios.get("/api/user-groups/" + this.$route.params.id);
          this.userGroup = response.data;
          this.currentUserGroupName = response.data.name;
        } catch (error) {
          throw error;
        } finally {
          this.isFetched = true;
          this.loading = false;
        }
      }
    },
    async updateProfile() {
      try {
        this.loading = true;
        const route = "/api/user-groups";
        if (this.isNewProfile) {
          const response = await this.axios.post(route, { name: this.userGroup.name });
          this.userGroup = response.data;
          this.$router.push({
            name: "UserGroup Edit",
            params: { id: response.data.id },
          });
        } else {
          await this.axios.put(`${route}/${this.userGroup.id}`, { ...this.userGroup });
        }
        this.fetchGroup();
        this.isEditingName = false;
        Message({
          message: "Benuztergruppe gespeichert",
          type: "success",
        });
      } catch (error) {
        if (error.response) {
          Message({
            message: error.response.data.message,
            type: "error",
          });
        } else {
          Message({
            message: error.message,
            type: "error",
          });
        }
        throw error;
      } finally {
        this.loading = false;
      }
    },
    cancelProfile() {
      this.$confirmDelete().then(async () => {
        await Promise.all([
          this.axios.delete("/api/user-groups/" + this.userGroup.id),
          this.axios.delete("/api/model-type/user-groups/" + this.userGroup.id),
        ]);
        this.$router.push({
          name: "UserGroup Index",
        }); //directly go back to index page
      });
    },
    goBack() {
      this.$router.push({ name: "UserGroup Index" });
    },
    handleNotesPrivilegedChange(value) {
      this.userGroup.project_notes.isPrivileged = value;
    },
    handleTodosPrivilegedChange(value) {
      this.userGroup.project_todos.isPrivileged = value;
    },
    handleCancelEditName() {
      this.isEditingName = false;
      this.userGroup.name = this.currentUserGroupName;
    },
    handleSelect(index, indexPath) {
      this.selectedPath = indexPath.join("-");
      const profileScroll = document.querySelector("._profile-scrollbar");
      profileScroll.scrollTop = 0;
    },
  },
  computed: {
    title() {
      if (this.isNewProfile) {
        return "Neue Benutzergruppe";
      } else {
        return this.userGroup.name || " - ";
      }
    },
    isNewProfile() {
      return this.$route.params.id === "new";
    },
    isViewMode() {
      return this.$route.name === "UserGroup View";
    },
    canEdit() {
      return !this.isViewMode && this.$can("update", "usergroup");
    },
  },
};
</script>

<style></style>
