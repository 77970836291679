<template>
  <div>
    <el-button class="symbol-select" @click="handleShow">
      <i v-if="value" :class="value"></i>
      <span v-else>N/A</span>
    </el-button>
    <el-dialog :visible.sync="visible" :title="$t('selectIcon')" append-to-body>
      <div class="symbol-list">
        <el-button-group>
          <el-button
            v-for="item in nucleoIcons"
            :key="item"
            type="default"
            @click="handleChange(item)"
            :class="{ 'color-active': value === item }"
          >
            <i :class="item" />
          </el-button>
        </el-button-group>
      </div>
      <!-- <div class="text-right">
        <el-button type="primary">{{ $t("hinzufugen") }}</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { ButtonGroup, Button, Dialog } from "element-ui";
export default {
  name: "symbol-select",
  props: {
    value: String,
  },
  components: {
    [Dialog.name]: Dialog,
    [ButtonGroup.name]: ButtonGroup,
    [Button.name]: Button,
  },
  data() {
    return {
      visible: false,
      nucleoIcons: [
        "nc-icon nc-air-baloon",
        "nc-icon nc-album-2",
        "nc-icon nc-alert-circle-i",
        "nc-icon nc-align-center",
        "nc-icon nc-align-left-2",
        "nc-icon nc-ambulance",
        "nc-icon nc-app",
        "nc-icon nc-atom",
        "nc-icon nc-badge",
        "nc-icon nc-bag-16",
        "nc-icon nc-bank",
        "nc-icon nc-basket",
        "nc-icon nc-bell-55",
        "nc-icon nc-bold",
        "nc-icon nc-book-bookmark",
        "nc-icon nc-bookmark-2",
        "nc-icon nc-box-2",
        "nc-icon nc-box",
        "nc-icon nc-briefcase-24",
        "nc-icon nc-bulb-63",
        "nc-icon nc-bullet-list-67",
        "nc-icon nc-bus-front-12",
        "nc-icon nc-button-pause",
        "nc-icon nc-button-play",
        "nc-icon nc-button-power",
        "nc-icon nc-calendar-60",
        "nc-icon nc-camera-compact",
        "nc-icon nc-caps-small",
        "nc-icon nc-cart-simple",
        "nc-icon nc-chart-bar-32",
        "nc-icon nc-chart-pie-36",
        "nc-icon nc-chat-33",
        "nc-icon nc-check-2",
        "nc-icon nc-circle-10",
        "nc-icon nc-cloud-download-93",
        "nc-icon nc-cloud-upload-94",
        "nc-icon nc-compass-05",
        "nc-icon nc-controller-modern",
        "nc-icon nc-credit-card",
        "nc-icon nc-delivery-fast",
        "nc-icon nc-diamond",
        "nc-icon nc-email-85",
        "nc-icon nc-favourite-28",
        "nc-icon nc-glasses-2",
        "nc-icon nc-globe-2",
        "nc-icon nc-globe",
        "nc-icon nc-hat-3",
        "nc-icon nc-headphones",
        "nc-icon nc-html5",
        "nc-icon nc-image",
        "nc-icon nc-istanbul",
        "nc-icon nc-key-25",
        "nc-icon nc-laptop",
        "nc-icon nc-layout-11",
        "nc-icon nc-lock-circle-open",
        "nc-icon nc-map-big",
        "nc-icon nc-minimal-down",
        "nc-icon nc-minimal-left",
        "nc-icon nc-minimal-right",
        "nc-icon nc-minimal-up",
        "nc-icon nc-mobile",
        "nc-icon nc-money-coins",
        "nc-icon nc-note-03",
        "nc-icon nc-palette",
        "nc-icon nc-paper",
        "nc-icon nc-pin-3",
        "nc-icon nc-planet",
        "nc-icon nc-refresh-69",
        "nc-icon nc-ruler-pencil",
        "nc-icon nc-satisfied",
        "nc-icon nc-scissors",
        "nc-icon nc-send",
        "nc-icon nc-settings-gear-65",
        "nc-icon nc-settings",
        "nc-icon nc-share-66",
        "nc-icon nc-shop",
        "nc-icon nc-simple-add",
        "nc-icon nc-simple-delete",
        "nc-icon nc-simple-remove",
        "nc-icon nc-single-02",
        "nc-icon nc-single-copy-04",
        "nc-icon nc-sound-wave",
        "nc-icon nc-spaceship",
        "nc-icon nc-sun-fog-29",
        "nc-icon nc-support-17",
        "nc-icon nc-tablet-2",
        "nc-icon nc-tag-content",
        "nc-icon nc-tap-01",
        "nc-icon nc-tie-bow",
        "nc-icon nc-tile-56",
        "nc-icon nc-time-alarm",
        "nc-icon nc-touch-id",
        "nc-icon nc-trophy",
        "nc-icon nc-tv-2",
        "nc-icon nc-umbrella-13",
        "nc-icon nc-user-run",
        "nc-icon nc-vector",
        "nc-icon nc-watch-time",
        "nc-icon nc-world-2",
        "nc-icon nc-zoom-split",
      ],
    };
  },
  methods: {
    handleShow() {
      this.visible = true;
    },
    handleChange(value) {
      this.$emit("input", value);
      this.visible = false;
    },
  },
};
</script>

<style>
</style>