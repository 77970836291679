<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.administration.user.index.administration")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.administration.user.index.benutzerListe") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="20">
        <div class="n-view-title">{{ $t("src.components.administration.user.index.benutzerListe") }}</div>
      </el-col>
    </el-row>

    <index-table
      ref="table"
      :propsToSearch="propsToSearch"
      authSubject="user"
      modelType="users"
      :extendedColumns="extendedColumns"
      :supressColumns="supressColumns"
      :suppressActiveButtons="true"
      :showViewButton="false"
      hideRemoveAction
      :defaultSort="{ prop: 'username', order: 'ascending' }"
    >
      <template v-slot:additional>
        <el-button
          v-on:click="showGroupsForAllUsers"
          :loading="loading"
          class="success"
          plain
          style="margin-right: 50%"
        >
          {{ $t("Show groups for all users") }}</el-button
        >
      </template>

      <template v-slot:Mitarbeiter="props"> {{ props.data.row.lastName }} {{ props.data.row.firstName }} </template>
      <!-- BENUTZERNAME COLUMN -->
      <template v-slot:Benuztername="props">
        <el-row type="flex" align="middle">
          <el-col>
            <router-link v-if="$can('update', 'user')" :to="`/administration/users/profile/view/${props.data.row.id}`">
              {{ props.data.row.username }}
            </router-link>
            <span v-else>{{ props.data.row.username }}</span>
            <el-badge
              :type="props.data.row.enabled ? 'success' : 'danger'"
              style="padding-top: 9px; padding-left: 6px"
              is-dot
            />
          </el-col>
        </el-row>
      </template>
      <template v-slot:Benutzergruppen="props">
        <div v-if="props.data.row.userGroups">{{ props.data.row.userGroups }}</div>
        <el-popover v-else trigger="click" placement="left" @show="showGroupMembership(props.data.row.id)">
          <div v-loading="loadingUserGroup">{{ userGroupMembership }}</div>

          <template v-slot:reference>
            <span class="buffer-check-icon">
              <account-group-icon :title="$t('src.components.project.wizard.configurationtable.buffercheck')" />
            </span>
          </template>
        </el-popover>
      </template>
    </index-table>
  </div>
</template>

<script>
import { Popover } from "element-ui";
import { IndexTable, NewListItemButton } from "src/components/UIComponents";
import AccountGroupIcon from "vue-material-design-icons/AccountGroup.vue";

export default {
  components: {
    [AccountGroupIcon.name]: AccountGroupIcon,
    [Popover.name]: Popover,
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
  },
  data() {
    return {
      loading: false,
      loadingUserGroup: false,
      userGroupMembership: "",
      supressColumns: ["pictures", "status", "dispo"],
      extendedColumns: [
        {
          label: "Benuztername",
          key: "username",
          minWidth: "200",
        },
        {
          label: "Mitarbeiter",
          key: "firstName",
          minWidth: "200",
        },
        {
          label: "Benutzergruppen",
          key: "userGroups",
          width: "150",
        },
      ],
      propsToSearch: ["firstName", "lastName", "username"],
    };
  },
  methods: {
    async showGroupsForAllUsers() {
      try {
        this.loading = true;
        const data = this.$refs.table.dataTable.slice();
        const result = await Promise.all(data.map(({ id }) => this.showGroupMembership(id, true)));
        data.forEach((item, idx) => {
          item.userGroups = result[idx];
        });
        this.$refs.table.updateTableData(data);
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async showGroupMembership(userId, returnValue) {
      let data;
      try {
        this.userGroupMembership = "";
        this.loadingUserGroup = true;
        const response = await this.axios.get(`api/users/${userId}/groups`);
        if (!response.data) {
          data = `Keine Daten`;
        } else {
          data = response.data.name;
        }
      } catch (error) {
        data = `Keine Daten (${error.message})`;
      } finally {
        this.loadingUserGroup = false;
        if (returnValue) {
          return data;
        } else {
          this.userGroupMembership = data;
        }
      }
    },
  },
};
</script>

<style></style>
