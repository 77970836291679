<template>
  <div>
    <el-form :model="colorSettings" :inline="true">
      <div>
        <p>Ressourcen:</p>
        <el-form-item prop="employee" :label="$t('src.components.employees.edit.mitarbeiter')" class="color-setting">
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.employee"
          ></el-color-picker>
        </el-form-item>
        <el-form-item prop="machine" :label="$t('src.components.machine.edit.maschinen')" class="color-setting">
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.machine"
          ></el-color-picker>
        </el-form-item>
        <el-form-item prop="rhb" :label="$t('src.components.rhb.edit.rhb')" class="color-setting">
          <el-color-picker :disabled="!$can('create', 'company_settings')" v-model="colorSettings.rhb"></el-color-picker>
        </el-form-item>
        <el-form-item prop="vehicle" :label="$t('src.components.vehicle.edit.kfz')" class="color-setting">
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.vehicle"
          ></el-color-picker>
        </el-form-item>
        <el-form-item prop="supply" :label="$t('src.components.supply.edit.verbrauchsmaterial')" class="color-setting">
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.supply"
          ></el-color-picker>
        </el-form-item>
        <el-form-item
          prop="subcontractor"
          :label="$t('src.components.project.bryntumscheduler.bryntumscheduler.subcontractor')"
          class="color-setting"
        >
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.subcontractor"
          ></el-color-picker>
        </el-form-item>
      </div>
      <div>
        <p>{{ $t("src.components.project.projectstatus.projektstatus") }}</p>
        <el-form-item
          prop="progress_1"
          :label="$t('src.components.project.projectstatus.reserviert')"
          class="color-setting"
        >
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.progress_1"
          ></el-color-picker>
        </el-form-item>
        <el-form-item
          prop="progress_2"
          :label="$t('src.components.project.projectstatus.auszufhrendeArbeit')"
          class="color-setting"
        >
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.progress_2"
          ></el-color-picker>
        </el-form-item>
        <el-form-item
          prop="progress_3"
          :label="$t('src.components.project.projectstatus.abgeschlossen')"
          class="color-setting"
        >
          <el-color-picker
            :disabled="!$can('create', 'company_settings')"
            v-model="colorSettings.progress_3"
          ></el-color-picker>
        </el-form-item>
      </div>
      <el-form-item style="margin-top: 7px">
        <el-button type="primary" v-if="$can('create', 'company_settings')" @click="saveColors">{{
          $t("src.components.management.companysettings.saveColorSettings")
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ColorPicker, Form, FormItem, Message } from "element-ui";
import { mapState } from "vuex";

export default {
  name: "color-settings",
  components: {
    [Message.name]: Message,
    [Form.name]: Form,
    [ColorPicker.name]: ColorPicker,
    [FormItem.name]: FormItem,
  },
  data() {
    return {
      colorSettings: {
        employee: "",
        machine: "",
        rhb: "",
        vehicle: "",
        supply: "",
        subcontractor: "",
        progress_1: "",
        progress_2: "",
        progress_3: "",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.colorSettings = { ...this.customerSettings.colorSettings };
    },
    async saveColors() {
      try {
        await this.$store.dispatch("customerSettings/saveColors", { ...this.colorSettings });
        document.documentElement.style.setProperty("--project-progress-1", this.colorSettings.progress_1);
        document.documentElement.style.setProperty("--project-progress-2", this.colorSettings.progress_2);
        document.documentElement.style.setProperty("--project-progress-3", this.colorSettings.progress_3);
        Message.success(this.$t("src.components.management.companysettings.colorSettingsSaved"));
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
  },
  computed: {
    ...mapState(["customerSettings"]),
  },
  watch: {
    "customerSettings.loaded": function (newVal, oldVal) {
      // if loaded changed from false to true
      if (!oldVal && newVal) {
        this.init();
      }
    },
  },
};
</script>

<style>
.color-setting {
  height: 55px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  padding: 5px 10px;
}
</style>