<template>
  <table class="access-rights-table">
    <thead>
      <tr>
        <th>Name</th>
        <th>{{ $t("Description") }}</th>
        <th>{{ $t("Access") }}</th>
      </tr>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "access-rights-table",
};
</script>

<style>
</style>