<template>
  <div>
    <div class="type-settings-wrapper">
      <div style="flex-grow: 1">
        <profile-select
          :editMode="true"
          :title="`Checklists (${checklists.length})`"
          :multiple="false"
          :items="checklists"
          v-model="selectedChecklistId"
          valueIdentifier="_id"
          label="name"
          :disableInactive="false"
          :hideInactive="false"
        />
      </div>
      <div v-if="isEditMode" style="margin-left: 6px; margin-bottom: 6px">
        <el-button class="btn btn-sm btn-ghost" @click="showCreateForm">
          <plus-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="editChecklist">
          <pencil-icon />
        </el-button>
        <el-button v-if="$can('delete', 'project')" class="btn btn-sm btn-ghost" @click="deleteChecklist">
          <trash-can-outline-icon />
        </el-button>
      </div>
    </div>
    <!-- DIALOG -->
    <el-dialog
      :title="$t('Checklist')"
      :visible.sync="visible"
      @closed="dismiss"
      width="1200px"
      destroy-on-close
      class="checklist-dialog"
    >
      <el-row :gutter="20" style="height: 100%">
        <el-col :span="14" style="height: 100%">
          <el-form ref="form" class="checklist-form" :model="formData" :rules="formRules" :disabled="!editable">
            <el-form-item
              :label="$t('src.components.management.projectsettings.projectdoctypesettings.name')"
              prop="name"
              label-width="200px"
            >
              <el-input autofocus v-model="formData.name" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item v-if="isNew" label-width="200px">
              <el-button @click.prevent="handleCopy">Kopieren aus...</el-button>
            </el-form-item>

            <el-form-item
              :label="$t('Checklist items')"
              ref="checklistWrapper"
              class="checklist-items-wrapper"
              prop="items"
              label-width="200px"
            >
              <checklist-items v-model="formData.items" @itemChange="handleItemChange" />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10" style="height: 100%">
          <perfect-scrollbar class="match-res-scrollbar" :options="{ suppressScrollX: false }">
            <matching-resources-table :resourceType="checklistItemResourceType" :query="checklistInputValue" />
          </perfect-scrollbar>
        </el-col>
      </el-row>

      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.abbrechen')"
            @click="dismiss"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.speichern')"
            @click="saveChecklist"
          ></save-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :title="$t('Copy checklist')"
      :visible.sync="isCopyVisible"
      @closed="dismissCopy"
      width="400px"
      destroy-on-close
    >
      <profile-select
        :editMode="true"
        :title="`Checklists (${copyableChecklistOptions.length})`"
        :multiple="false"
        :items="copyableChecklistOptions"
        v-model="selectedCopyFrom"
      />

      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.abbrechen')"
            @click="dismissCopy"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.speichern')"
            :disabled="isSubmitCopyDisabled"
            @click="submitCopy"
          ></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Message, Form, FormItem, Input, MessageBox } from "element-ui";
import { get } from "lodash";
import ChecklistItems from "./ChecklistItems.vue";
import MatchingResourcesTable from "./MatchingResourcesTable.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "checklist-settings",
  props: {
    isEditMode: Boolean,
  },
  components: {
    PerfectScrollbar,
    MatchingResourcesTable,
    ChecklistItems,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [MessageBox.name]: MessageBox,
  },
  data() {
    return {
      visible: false,
      editable: false,
      isNew: false,
      isCopyVisible: false,
      selectedCopyFrom: null,
      checklists: [],
      selectedChecklistId: "",
      formRules: {
        name: {
          required: true,
          message: "Bitte Name auswählen",
          trigger: "change",
        },
      },
      formData: {
        name: "",
        items: [],
      },
      checklistItemResourceType: "",
      checklistInputValue: "",
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleItemChange({ name, resourceType }) {
      console.log("itemChange", { name, resourceType });
      this.checklistItemResourceType = resourceType;
      this.checklistInputValue = name;
    },
    async fetch() {
      try {
        const response = await this.axios.get("/api/checklists");
        this.checklists = response.data || [];
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async saveChecklist() {
      try {
        await this.$refs.form.validate();
        if (this.isNew) {
          await this.axios.post("/api/checklists", this.formData);
          Message.success("Checklist created");
        } else {
          await this.axios.put(`/api/checklists/${this.selectedChecklistId}`, this.formData);
          Message.success("Checklist edited");
        }
        this.fetch();
        this.dismiss();
      } catch (error) {
        if (error.response) {
          const message = get(error.response, "data.message", error.message);
          Message.error(message);
        }
        throw error;
      }
    },
    editChecklist() {
      if (!this.selectedChecklistId) {
        MessageBox.alert("Bitte Checklist auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      const selectedRecord = this.checklists.find((item) => item._id === this.selectedChecklistId) || {};
      this.formData.name = selectedRecord.name || "";
      this.formData.items = selectedRecord.items || [];
      this.showEditForm();
    },
    deleteChecklist() {
      if (!this.selectedChecklistId) {
        MessageBox.alert("Checklist auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.$confirmDelete().then(async () => {
        await this.axios.delete(`/api/checklists/${this.selectedChecklistId}`);
        this.checklists = this.checklists.filter((item) => item._id !== this.selectedChecklistId);
        this.selectedChecklistId = "";
      });
    },
    dismiss() {
      this.isNew = false;
      this.visible = false;
      this.editable = false;
      this.$refs.form.resetFields();
      this.formData.name = "";
      this.formData.items = [];
    },
    showCreateForm() {
      this.isNew = true;
      this.visible = true;
      this.editable = true;
    },
    showEditForm() {
      this.isNew = false;
      this.visible = true;
      this.editable = true;
    },
    handleCopy() {
      this.isCopyVisible = true;
    },
    submitCopy() {
      const checklist = this.checklists.find((i) => i._id === this.selectedCopyFrom);
      this.formData.items = checklist.items.map(({ _id, ...data }) => data);
      this.dismissCopy();
    },
    dismissCopy() {
      this.isCopyVisible = false;
      this.selectedCopyFrom = null;
    },
  },
  computed: {
    isSubmitCopyDisabled() {
      return !this.selectedCopyFrom;
    },
    copyableChecklistOptions() {
      return this.checklists.map((i) => ({ value: i._id, label: i.name }));
    },
  },
  watch: {
    "formData.items": function (newVal, oldVal) {
      if (oldVal.length && oldVal.length < newVal.length) {
        const wrapper = document.querySelector(".checklist-items-wrapper");
        if (wrapper) {
          setTimeout(() => {
            wrapper.scrollTo(0, wrapper.scrollHeight);
          }, 200);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.checklist-dialog {
  .el-dialog {
    height: calc(100vh - 15vh - 50px);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .el-dialog__footer,
    .el-dialog__header {
      flex-shrink: 0;
    }

    .el-dialog__body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }
  }

  .checklist-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .checklist-items-wrapper {
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.match-res-scrollbar {
  height: 100%;
}
</style>
