import Vue from "vue";
import { store } from "./store";

export const updateToken = async () => {
  try {
    await Vue.$keycloak.updateToken(20);
    store.commit("account/setToken", Vue.$keycloak.token);
    return Vue.$keycloak.token;
  } catch (error) {
    console.log("Error during Vue.$keycloak.updateToken");
    throw error;
  }
};
