<template>
  <div>
    <div class="form-group row">
      <div class="col-md-12">
        <el-form
          :disabled="!computedEditMode"
          :inline="true"
          :model="purchasesRecord"
          :rules="rules"
          ref="purchasesForm"
        >
          <el-form-item prop="position">
            <el-select
              :disabled="!computedEditMode"
              :filterable="true"
              v-model="purchasesRecord.position"
              :placeholder="$t('src.components.project.projectcontrolling.purchases.position')"
            >
              <el-option-group
                :label="$t('src.components.project.projectcontrolling.purchases.suppliers')"
                key="Suppliers"
              >
                <el-option
                  v-for="option in suppliersOptions"
                  :key="option.label"
                  :label="option.label"
                  :value="option.label"
                ></el-option>
              </el-option-group>
              <el-option-group
                :label="$t('src.components.project.projectcontrolling.purchases.positions')"
                key="Positions"
              >
                <el-option
                  v-for="option in positionsOptions"
                  :key="option.label"
                  :label="option.label"
                  :value="option.label"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item prop="description">
            <el-input
              v-model="purchasesRecord.description"
              :placeholder="$t('src.components.project.projectcontrolling.purchases.description')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="amount">
            <el-input
              type="number"
              style="max-width: 250px"
              v-model="purchasesRecord.amount"
              :placeholder="$t('src.components.project.projectcontrolling.purchases.amount')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="isConstructionSite">
            <el-checkbox v-model="purchasesRecord.isConstructionSite" :disabled="!computedEditMode">{{
              $t("src.components.project.projectcontrolling.purchases.baustellengemeinkosten")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="loading">{{
              $t("src.components.project.projectcontrolling.purchases.hinzufgen")
            }}</el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="computedPurchases"
          style="width: 100%"
          :default-sort="{ prop: 'position', order: 'ascending' }"
        >
          <el-table-column
            type="index"
            :label="$t('src.components.project.projectcontrolling.purchases.nr')"
            width="60"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.projekt')"
            min-width="180"
            prop="parentProjectName"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.purchases.position')"
            min-width="180"
            prop="position"
            sortable
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.purchases.beschreibung')"
            min-width="180"
            prop="description"
            sortable
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.purchases.betragen')"
            min-width="180"
            prop="amount"
            width="150"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.purchases.baustellengemeinkosten')"
            prop="isConstructionSite"
            :formatter="formatBoolean"
            width="200"
            sortable
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.purchases.aktion')"
            fixed="right"
            width="150"
          >
            <template slot-scope="scope" v-if="computedEditMode">
              <el-button size="mini" @click="handleEditRecord(scope.row, scope.$index)" icon="el-icon-edit"></el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDeleteRecord(scope.$index)"
                icon="el-icon-delete"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :title="$t('src.components.project.projectcontrolling.purchases.bearbeiten')"
      :visible.sync="isEditVisible"
      width="100%"
      style="max-width: 900px; margin: 0 auto"
    >
      <el-form
        :rules="editingRules"
        ref="editFormRef"
        :model="editingPurchasesRecord"
        label-position="top"
        :close-on-click-modal="false"
      >
        <div class="row">
          <div class="col-md-4">
            <el-form-item prop="position" :label="$t('src.components.project.projectcontrolling.purchases.position')">
              <el-select
                :disabled="!computedEditMode"
                :filterable="true"
                v-model="editingPurchasesRecord.position"
                :placeholder="$t('src.components.project.projectcontrolling.purchases.position')"
                style="width: 100%"
              >
                <el-option-group
                  :label="$t('src.components.project.projectcontrolling.purchases.suppliers')"
                  key="Suppliers"
                >
                  <el-option
                    v-for="option in suppliersOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                  ></el-option>
                </el-option-group>
                <el-option-group
                  :label="$t('src.components.project.projectcontrolling.purchases.positions')"
                  key="Positions"
                >
                  <el-option
                    v-for="option in positionsOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-8">
            <el-form-item
              prop="description"
              :label="$t('src.components.project.projectcontrolling.purchases.description')"
            >
              <el-input
                style="width: 100%"
                v-model="editingPurchasesRecord.description"
                :placeholder="$t('src.components.project.projectcontrolling.purchases.description')"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="col-md-4">
            <el-form-item prop="amount" :label="$t('src.components.project.projectcontrolling.purchases.amount')">
              <el-input
                type="number"
                style="width: 100%"
                v-model="editingPurchasesRecord.amount"
                :placeholder="$t('src.components.project.projectcontrolling.purchases.amount')"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="col-md-4">
            <el-form-item prop="isConstructionSite">
              <el-checkbox
                style="margin-top: 50px"
                v-model="editingPurchasesRecord.isConstructionSite"
                :disabled="!computedEditMode"
                >{{ $t("src.components.project.projectcontrolling.purchases.baustellengemeinkosten") }}</el-checkbox
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="isEditVisible = false">{{
            $t("src.components.project.projectcontrolling.purchases.abbrechen")
          }}</el-button>
          <el-button type="primary" @click.prevent="saveChanges">{{
            $t("src.components.project.projectcontrolling.purchases.speichern")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Table, Input, Checkbox, Form, FormItem, Select, OptionGroup, Dialog, MessageBox, Message } from "element-ui";

export default {
  name: "purchases",
  props: {
    accessRights: { type: String },
    isEditMode: { type: Boolean, required: true },
    purchases: { type: Array },
    projectsMeta: { type: Array },
    projectId: { type: String },
    suppliersOptions: { type: Array, default: () => [] },
    positionsOptions: { type: Array, default: () => [] },
  },
  components: {
    [Table.name]: Table,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [OptionGroup.name]: OptionGroup,
    [Dialog.name]: Dialog,
    [MessageBox.name]: MessageBox,
    [Message.name]: Message,
  },
  data: function () {
    return {
      isEditVisible: false,
      editingIdx: null,
      purchasesRecord: {
        position: "",
        description: "",
        isConstructionSite: false,
        amount: "",
      },
      editingPurchasesRecord: {
        _id: null,
        position: "",
        description: "",
        isConstructionSite: false,
        amount: "",
      },
      rules: {
        position: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        amount: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        isConstructionSite: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      editingRules: {
        position: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        amount: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        isConstructionSite: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.$refs.purchasesForm.validate(async (valid) => {
        try {
          this.loading = true;
          if (!valid) {
            return false;
          }
          const { position, description, isConstructionSite, amount } = this.purchasesRecord;
          const record = {
            position: position,
            description: description,
            isConstructionSite: isConstructionSite,
            amount: amount,
          };
          if (!this.purchases) {
            this.purchases = [];
          }
          const response = await this.axios.post(`/api/project-purchases/${this.projectId}`, record);
          this.purchases.push(response.data);
          this.$refs.purchasesForm.resetFields();
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    handleEditRecord(storedRecord, index) {
      this.isEditVisible = true;
      this.editingIdx = index;
      this.editingPurchasesRecord = {
        _id: storedRecord._id,
        position: storedRecord.position,
        description: storedRecord.description,
        isConstructionSite: storedRecord.isConstructionSite,
        amount: storedRecord.amount,
      };
    },
    saveChanges() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          const formData = { ...this.editingPurchasesRecord };
          let response;
          if (this.editingPurchasesRecord._id) {
            response = await this.axios.put(`/api/project-purchases/${formData._id}`, formData);
          } else {
            response = await this.axios.post(`/api/project-purchases/${this.projectId}`, formData);
          }
          this.$set(this.purchases, this.editingIdx, response.data);
          this.isEditVisible = false;
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    handleDeleteRecord(idx) {
      MessageBox.confirm("Sind Sie sicher?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(async () => {
        await this.axios.delete(`/api/project-purchases/${this.purchases[idx]._id}`);
        this.purchases.splice(idx, 1);
      });
    },
    formatBoolean(record) {
      return record.isConstructionSite ? "Ja" : "Nein";
    },
    resetRecord() {
      this.editingPurchasesRecord = {
        _id: null,
        position: "",
        description: "",
        isConstructionSite: false,
        amount: "",
      };
      this.editingIdx = null;
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    computedPurchases() {
      const purchasesWithParentProjectName = this.purchases.map((purchase) => {
        const project = this.projectsMeta.find((project) => project.id === purchase.parentProjectId) || {};
        return { ...purchase, parentProjectName: project.bvName };
      });
      if (this.hideRelatedCosts) {
        return purchasesWithParentProjectName.filter((purchase) => purchase.parentProjectId === this.projectId);
      }
      return purchasesWithParentProjectName;
    },
  },
  watch: {
    isEditVisible: function (newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.resetRecord();
        this.$refs.editFormRef.resetFields();
      }
    },
  },
};
</script>

<style></style>
