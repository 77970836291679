<template>
  <div style="height: 100%; padding-right: 8px">
    <h3 class="n-profile-title mb-2" style="margin-left: 0px">
      {{ $t("src.components.project.downloadcenter.downloadcenter.downloadCenter") }}
    </h3>
    <el-tabs v-model="activeTab" type="card" v-if="project.id" class="tab-content-no-padding">
      <el-tab-pane
        v-if="project.projectTypes"
        :label="$t('src.components.project.downloadcenter.downloadcenter.projektdateien')"
        name="uploaded"
      >
        <uploaded-project-documents
          :documents="documents"
          :projectId="project.id"
          :loading="loading"
          @documentAdded="fetchDocuments"
          ref="uploadedDocuments"
          :accessRights="accessRights"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.project.downloadcenter.downloadcenter.mitarbeiterdokumente')"
        name="employee_docs"
        lazy
        class="tab-content-no-padding"
      >
        <employee-documents-center
          :projectId="project.id"
          :accessRights="accessRights"
          :project-date-range="project.dateRange"
          :noDateRange="project.noDateRange"
        />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.project.downloadcenter.downloadcenter.vorlagen')"
        name="templates"
        lazy
        v-if="this.accessRights !== 'readonly' && project.projectTypes"
      >
        <document-templates
          :projectTypes="projectTypes"
          :loading="loading"
          :projectId="project.id"
          :projectDateRange="project.dateRange"
          @documentAdded="fetchDocuments"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Tabs, TabPane, Message } from "element-ui";
import UploadedProjectDocuments from "./UploadedProjectDocuments.vue";
import DocumentTemplates from "./DocumentTemplates.vue";
import EmployeeDocumentsCenter from "./EmployeeDocumentsCenter.vue";
import { uniqBy, flatten } from "lodash";

export default {
  name: "download-center",
  props: {
    project: { type: Object },
    accessRights: String,
  },
  components: {
    UploadedProjectDocuments,
    DocumentTemplates,
    EmployeeDocumentsCenter,
    [Message.name]: Message,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  data() {
    return {
      activeTab: this.project.projectTypes ? "uploaded" : "employee_docs",
      documents: [],
      documentTypes: [],
      projectTypes: [],
      loading: false,
    };
  },
  async mounted() {
    this.fetchDocuments();
  },
  methods: {
    async fetchDocuments() {
      try {
        this.loading = true;
        await this.fetchDocumentTypes();
        const response = await this.axios.get(`/api/project-documents/${this.project.id}`);
        const sma = await this.axios.get(`/api/project-documents/sma/${this.project.id}`);
        console.log("project-documents/sma", sma.data);
        this.documents =
          response.data.map((record) => {
            const docType = this.documentTypes.find((docT) => docT._id === record.documentType) || {};
            return { ...record, documentTypeName: docType.name };
          }) || [];
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchDocumentTypes() {
      try {
        this.loading = true;
        const response = await this.axios.get(`/api/project-document-types/project-view`, {
          params: { projectTypes: this.project.projectTypes },
        });
        this.documentTypes = uniqBy(flatten(response.data.map((i) => i.documentTypes)), "_id");
        this.projectTypes = response.data || [];
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style>
.el-tabs__content .tab-content-no-padding .el-tabs__content {
  padding-top: 0;
}
</style>
