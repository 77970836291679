<template>
  <el-dialog
    :visible="visible"
    width="90vw"
    @close="close"
    :title="fileName"
    custom-class="pdf-viewer-dialog"
    top="5vh"
  >
    <pdf-viewer v-if="url" :url="url" />
  </el-dialog>
</template>

<script>
import PdfViewer from "./PdfViewer.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "pdf-viewer-dialog",
  components: {
    [PdfViewer.name]: PdfViewer,
  },
  methods: {
    ...mapActions("pdfViewer", ["close"]),
  },
  computed: {
    ...mapState("pdfViewer", ["visible", "url", "fileName"]),
  },
  watch: {},
};
</script>

<style lang="scss">
.el-dialog.pdf-viewer-dialog {
  height: 90vh;
  overflow: hidden;
  margin: 5vh auto;
  display: flex;
  flex-direction: column;
  .el-dialog__header {
    flex-shrink: 0;
  }
  .el-dialog__body {
    padding-top: 10px;
    flex-grow: 2;
    height: 100%;
    display: flex;
    overflow: hidden;
    padding-bottom: 0;
  }
}
</style>
