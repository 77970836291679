import { Widget, DomHelper } from "@bryntum/scheduler";
import { store } from "src/store";

export class GranularityModeWidget extends Widget {
  static mode = null;
  static onChangeHandler = null;
  static get type() {
    return "granularity_widget";
  }
  static get $name() {
    return "granularity_widget";
  }

  static get configurable() {
    return {
      id: null,
      cls: null,
      mode: null,
      label: null,
      resourceType: null,
      onChangeHandler: null,
      disabled: false,
    };
  }

  compose() {
    const { cls = "", onChange, resourceType, label, id, disabled = false } = this; // collect all relevant configs properties (for auto-detection)
    this.resourceType = resourceType;
    const preservedSettings = JSON.parse(localStorage.getItem("granularity_settings") || "{}");
    const mode = preservedSettings[resourceType] || "STANDARD";
    this.mode = mode;
    this.onChangeHandler = onChange;
    this.disabled = disabled;
    return {
      id: id,
      tag: "div",
      listeners: {
        click: "onClickMode",
      },
      class: "granularity-wrapper",
      children: [
        { tag: "div", class: "granularity-label", html: (label || "Granularity") + ":" },
        {
          tag: "div",
          reference: "wrapper",
          class: {
            "granularity-widget": true,
            [cls]: true,
            "is-disabled": disabled,
            concurrent: mode === "CONCURRENT",
            standard: mode === "STANDARD",
          },
          children: [
            {
              tag: "div",
              class: "granularity-option standard",
              ["data-type"]: "standard",
              children: [
                { tag: "div", class: "granularity-row row-1" },
                { tag: "div", class: "granularity-row row-2" },
              ],
            },
            {
              tag: "div",
              class: "granularity-option concurrent",
              ["data-type"]: "concurrent",
              children: [
                { tag: "div", class: "granularity-row row-1" },
                { tag: "div", class: "granularity-row row-2" },
              ],
            },
          ],
        },
      ],
    };
  }

  setMode(mode) {
    this.mode = mode;
    switch (mode) {
      case "STANDARD":
        this.byRef.wrapper.classList.remove("concurrent");
        this.byRef.wrapper.classList.add("standard");
        break;
      case "CONCURRENT":
        this.byRef.wrapper.classList.add("concurrent");
        this.byRef.wrapper.classList.remove("standard");
        break;
      default:
        throw new Error(`Unsuppoerted mode value: ${mode}`);
    }
  }

  onClickMode(e) {
    if (this.disabled) {
      return;
    }
    store.dispatch("granularitySettings/setVisible", true);
  }

  doDestroy() {
    super.doDestroy();
  }
}
GranularityModeWidget.initClass();
