<template>
  <div>
    <el-row>
      <el-col :span="9">
        <el-row>
          <el-col>
            <el-radio v-model="mainProductGroupLevel" label="1" border>Warengruppe 1</el-radio>
            <el-radio v-model="mainProductGroupLevel" label="2" border>Warengruppe 2</el-radio>
          </el-col>
        </el-row>
        <el-row>
          <!-- EDIT LISTS -->
          <el-col>
            <el-row style="display: flex; justify-content: start; align-items: center; margin-top: 10px">
              <el-col :span="12">
                <profile-select
                  :multiple="false"
                  :editMode="true"
                  title="Warengruppen"
                  :items="productGroupList"
                  v-model="selectedProductGroupID"
                  valueIdentifier="_id"
                  :disableInactive="false"
                  :hideInactive="false"
                />
              </el-col>
              <el-col v-if="isEditMode" :span="12" style="margin-left: 6px; margin-bottom: 6px">
                <el-button class="btn btn-sm btn-ghost" @click="prepareCreation">
                  <plus-icon />
                </el-button>
                <el-button class="btn btn-sm btn-ghost" @click="prepareEdit">
                  <pencil-icon />
                </el-button>
                <el-button class="btn btn-sm btn-ghost" @click="deleteProductGroup">
                  <trash-can-outline-icon />
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="15">
        <el-table
          ref="resultTable"
          :data="expandableDataTable"
          :cell-style="resultCellStyle"
          :show-overflow-tooltip="false"
          style="width: 100%"
          row-key="id"
        >
          <!-- EXPAND COLUMN -->
          <el-table-column v-slot="scope" width="40">
            <template v-if="scope.row.expand">
              <chevron-down-icon @click="toggleExpandedKey(scope.row, scope)" v-if="scope.row.expanded" />
              <chevron-right-icon @click="toggleExpandedKey(scope.row, scope)" v-if="!scope.row.expanded" />
            </template>
          </el-table-column>

          <!-- WARENGRUPPE 1 -->
          <el-table-column border prop="name" label="Warengruppe 1" min-width="250" v-slot="scope">
            <template v-if="scope.row.show">
              {{ scope.row.name }}
            </template>
          </el-table-column>

          <!-- WARENGRUPPE 2 -->
          <el-table-column border prop="pg2" label="Warengruppe 2" min-width="250" v-slot="scope">
            <template v-if="scope.row.expanded">
              {{ scope.row.pg2 }}
            </template>
          </el-table-column>

          <!-- HEADER ALLE AUSKLAPPEN -->
          <el-table-column min-width="130">
            <!-- HACK: keep slot-scope, even if unused, else header is not reactive -->
            <template v-slot:header="props">
              <el-link @click.prevent="expandAll" :key="props.$index">
                <chevron-down-icon v-if="!expandAllFlag" />
                <chevron-right-icon v-else />
                <span class="n-link-label">{{ expandAllFlag ? "Alle ausklappen" : "Alle einklappen" }}</span>
              </el-link>
            </template>
            <template slot-scope="props" v-if="isEditMode && hasAccessToResource">
              <el-button type="primary" size="mini" @click="handleShowBatchModal(props.row)">anwenden auf</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- DIALOG EDIT product group -->
    <el-dialog width="600px" :title="editDialogTitle" :visible.sync="dialogEdit">
      <ValidationObserver ref="validateEditObserver">
        <el-row>
          <el-col :span="15">
            <el-radio v-if="editProductGroupLevel === '1'" v-model="editProductGroupLevel" label="1" border
              >Warengruppe 1</el-radio
            >
            <el-radio v-if="editProductGroupLevel === '2'" v-model="editProductGroupLevel" label="2" border
              >Warengruppe 2</el-radio
            >
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="15">
            <product-group-select
              v-if="editProductGroupLevel === '2'"
              title="Legen Sie die übergeordnete Warengruppe fest"
              :editMode="isEditMode"
              productGroupType="pg1"
              v-model="selectedProductGroup1"
              required
            />
          </el-col>
        </el-row>

        <el-divider />

        <el-row>
          <el-col :span="15">
            <profile-input
              name="Warengruppen Name"
              v-model="changedProductGroupName"
              label="Neuer Name"
              :editMode="true"
              rules="required"
              required
            />
          </el-col>
        </el-row>
      </ValidationObserver>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button text="Abbrechen" @click="dialogEdit = false"></cancel-button>
          <save-button
            :disabled="(editProductGroupLevel === '2' && !selectedProductGroup1) || changedProductGroupName === ''"
            text="Speichern"
            @click="changeProductGroup"
          ></save-button>
        </span>
      </template>
    </el-dialog>

    <!-- DIALOG create NEW product group -->
    <el-dialog width="600px" title="Neue Warengruppe" :visible.sync="dialogCreateProductGroup">
      <ValidationObserver ref="validateObserver">
        <el-row>
          <el-col :span="15">
            <p>Legen Sie bitte die Warengruppen Ebene fest:</p>
            <el-radio v-model="editProductGroupLevel" @change="resetSelection" label="1" border>Warengruppe 1</el-radio>
            <el-radio v-model="editProductGroupLevel" label="2" border>Warengruppe 2</el-radio>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="15">
            <product-group-select
              v-if="editProductGroupLevel === '2'"
              title="Legen Sie die übergeordnete Warengruppe fest"
              :editMode="isEditMode"
              productGroupType="pg1"
              v-model="selectedProductGroup1"
              required
            />
          </el-col>
        </el-row>

        <el-divider />

        <el-row>
          <el-col :span="15">
            <profile-input
              name="Warengruppen Name"
              v-model="newProductGroupName"
              label="Warengruppen Name"
              :editMode="true"
              rules="required"
              required
            />
          </el-col>
        </el-row>
      </ValidationObserver>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button text="Abbrechen" @click="dialogCreateProductGroup = false"></cancel-button>
          <save-button
            :disabled="(editProductGroupLevel === '2' && selectedProductGroup1 === '') || newProductGroupName === ''"
            text="Erstellen"
            @click="createProductGroup"
          ></save-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog width="600px" title="Stapelverabeitung" :visible.sync="batchModalVisible" @closed="dismissBatchAddModal">
      <el-alert type="info" :closable="false" style="word-break: break-word" class="mb-3">
        {{ batchModalLabel }}
      </el-alert>
      <div class="d-flex align-items-start">
        <profile-select
          :filterable="true"
          clearable
          style="margin-top: 4px; margin-right: 5px; flex-grow: 1"
          :required="true"
          :multiple="true"
          v-model="selectedResources"
          :title="$t('selectResources')"
          :editMode="true"
          :items="resourcesList"
          valueIdentifier="_id"
          label="name"
        />
      </div>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button text="Abbrechen" @click="dismissBatchAddModal"></cancel-button>
          <save-button
            :disabled="!selectedResources.length"
            text="Speichern"
            @click="submitBatchAdd"
            :loading="batchLoading"
          ></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { orderBy, cloneDeep, get } from "lodash";
import ChevronRight from "vue-material-design-icons/ChevronRight";
import ChevronDown from "vue-material-design-icons/ChevronDown";
import { Divider, Radio, Dialog, MessageBox, Message, Tree, Alert } from "element-ui";
import { ValidationObserver } from "vee-validate";
import { mapState } from "vuex";

export default {
  name: "productgroup-settings",
  components: {
    ValidationObserver,
    [Dialog.name]: Dialog,
    MessageBox,
    Message,
    [Tree.name]: Tree,
    [Radio.name]: Radio,
    [Divider.name]: Divider,
    [Alert.name]: Alert,
    [ChevronRight.name]: ChevronRight,
    [ChevronDown.name]: ChevronDown,
  },
  props: {
    modelType: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expandAllFlag: false,
      expandIdList: [],
      mainProductGroupLevel: "1",
      // textfields of dialogs
      changedProductGroupName: "",
      newProductGroupName: "",
      // show dialogs
      dialogCreateProductGroup: false,
      dialogEdit: false,
      // selected ids
      addProductGroupChild_SelectedID: "",
      selectedProductGroupID: "",
      currentSelectedID: "",
      isCurrentSelectedRoot: false,
      editProductGroupLevel: "1",
      beforeEditingGroupLevel: "",
      selectedProductGroup1: "",
      editDialogTitle: "",
      //add PG options
      options: [],
      resourcesList: [],
      batchModalVisible: false,
      batchLoading: false,
      batchAddPayload: null,
      selectedResources: [],
      batchModalLabel: "",
      resourceTypeToUrl: {
        machine: "machines",
        vehicle: "vehicles",
        rhb: "rhb",
        supply: "supply",
      },
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    hasAccessToResource() {
      return get(this.accessRights, `${this.modelType}.generalAccess`, false);
    },
    expandableDataTable: {
      get: function () {
        if (this.expandIdList.length == 0) {
          return this.productGroupResult;
        }
        const tableData = this.productGroupResult.filter(
          (item) => item.show || this.expandIdList.indexOf(item.refId) === -1
        );
        return tableData;
      },
    },
    productGroupResult: {
      get: function () {
        // load lists
        let productgrouplist_1 = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
        productgrouplist_1 = orderBy(productgrouplist_1, ["label"], ["asc"]);
        let productgrouplist_2 = this.$store.getters["productGroupSettings/productgroup_2_ordered"];
        productgrouplist_2 = orderBy(productgrouplist_2, ["label"], ["asc"]);
        const tableData = [];

        // ** fill array with special table structure: **
        // loop product group 1
        productgrouplist_1.forEach((productgroup_1) => {
          // check number of children
          const rows = productgroup_1.children.length;
          // no children?
          if (rows == 0) {
            // just add product group 1
            tableData.push({
              id: productgroup_1._id,
              refId: productgroup_1._id, //added just to complete structure, used to filter with expanding. This can never be expanded.
              name: productgroup_1.label,
              pg1Name: productgroup_1.label,
              pg2Name: null,
              pg2: " — ", //no productgroup 2
              expand: false, //can never be expanded
              show: true, //always shown
              expanded: true, //is expanded
            });
            // there are children?
          } else {
            // loop children
            productgroup_1.children.forEach((productgroup_2_id, index) => {
              // find assigned product group 2
              const productgroup_2 = productgrouplist_2.find((x) => x._id === productgroup_2_id);
              if (productgroup_2) {
                // add product group 1 and 2
                tableData.push({
                  id: productgroup_2._id,
                  refId: productgroup_1._id, //used to filter when expand is closed
                  pg1Name: productgroup_1.label,
                  pg2Name: productgroup_2.label,
                  name: index == 0 ? `${productgroup_1.label} (${productgroup_1.children.length})` : "",
                  pg2: productgroup_2.label,
                  expand: index == 0 ? true : false,
                  show: index == 0 ? true : false, //show only first
                  expanded: true,
                });
              }
            });
          }
        });

        return tableData;
      },
    },
    productGroupList: {
      get: function () {
        let productgrouplist;
        if (this.mainProductGroupLevel === "1") {
          // get product group 1 list
          productgrouplist = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
        } else if (this.mainProductGroupLevel === "2") {
          // get product group 2 list
          productgrouplist = this.$store.getters["productGroupSettings/productgroup_2_ordered"];
        } else {
          return [];
        }

        // .. and sort arrays
        productgrouplist = orderBy(productgrouplist, ["label"], ["asc"]);

        //clear selection due to changing group type
        this.selectedProductGroupID = "";

        return productgrouplist;
      },
    },
    productGroups: {
      get: function () {
        return this.$store.getters["productGroupSettings/productGroupList"];
      },
    },
    productGroupsByCurrentLevel: {
      get: function () {
        let productgrouplist;
        if (this.editProductGroupLevel === "1") {
          // get product group 1 list
          productgrouplist = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
        } else if (this.editProductGroupLevel === "2") {
          // get product group 2 list
          productgrouplist = this.$store.getters["productGroupSettings/productgroup_2_ordered"];
        } else {
          return [];
        }
        return productgrouplist;
      },
    },
    currentSelectedProductGroup: function () {
      // is it the root element?
      if (this.isCurrentSelectedRoot) {
        //return root (not part of productGroups)
        return this.productGroupTree_root;
      } else {
        //is it a child?
        if (this.currentSelectedID != "") {
          //find child from productGroups
          const child = this.productGroups.find((x) => x._id === this.currentSelectedID);
          return child;
        } else {
          return {};
        }
      }
    },
    productGroupTree_root: function () {
      return this.$store.getters["productGroupSettings/productGroupTree_root"];
    },
    productGroupTree: function () {
      // resulting tree
      let tree = [];

      // Create a Map of list like {_id: ProductGroup}
      let productGroupMap = new Map();
      this.productGroups.forEach((productGroup) => {
        productGroupMap.set(productGroup._id, productGroup);
      });

      //Iterate recursivly over each children and replace each child id with ProductGroup entity picked from Map
      const replaceRecursivly = (productGroup) => {
        if (productGroup && productGroup.children) {
          // replace each child id with ProductGroup entity
          for (let index = 0; index < productGroup.children.length; index++) {
            // get child from map by ID
            let child = productGroupMap.get(productGroup.children[index]);
            //when child was removed -> stop here
            if (!child) {
              continue;
            }
            // replace ID with child
            productGroup.children[index] = child;

            // replace children now
            replaceRecursivly(child);

            // if (productGroup.model.includes("_root")) {
            //   tree.push(child);
            // }
          }
        }
      };

      //dont change the root in here, just its clone for proper visualization
      const root = cloneDeep(this.productGroupTree_root);
      //begin with root product group
      tree.push(root);
      //start replacing
      root.children && replaceRecursivly(root);

      return tree;
    },
  },
  methods: {
    async initialize() {
      try {
        await this.$store.dispatch("productGroupSettings/initAsync", { modelType: this.modelType });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    toggleExpandedKey(row, scope) {
      row.expanded = !row.expanded;
      this.handleExpandRow(row);
    },
    expandAll() {
      this.productGroupResult.forEach((productGroup) => {
        // can be expanded (contains arrow)
        if (productGroup.expand) {
          productGroup.expanded = this.expandAllFlag;
          this.handleExpandRow(productGroup);
        }
      });

      this.expandAllFlag = !this.expandAllFlag;

      //HACK: clear expanded list in any to case to sync with single expanded rows
      this.$nextTick(() => {
        if (!this.expandAllFlag) {
          this.expandIdList.splice(0, this.expandIdList.length);
        }
      });
    },
    handleExpandRow(row) {
      if (row.expanded) {
        let index = this.expandIdList //contains list of IDs
          .map((x) => {
            return x;
          })
          .indexOf(row.refId);

        this.expandIdList.splice(index, 1);
      } else {
        this.expandIdList.push(row.refId);
      }
    },
    resultCellStyle({ row, column, rowIndex, columnIndex }) {
      // push text of productgroup 1 column to top
      if (columnIndex == 1 && row.expand) {
        return { "text-align": "left", "vertical-align": "top" };
      }
    },

    // reset warengruppe selection in dialog
    resetSelection() {
      this.selectedProductGroup1 = "";
    },
    prepareEdit() {
      if (!this.selectedProductGroupID) {
        MessageBox.alert("Bitte Warengruppe auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }

      // first reset
      this.resetSelection();

      //store productGroup ID
      this.currentSelectedID = this.selectedProductGroupID;
      this.isCurrentSelectedRoot = false;

      //set the old name
      this.changedProductGroupName = this.currentSelectedProductGroup.label;

      //set selected product group level
      this.editProductGroupLevel = this.mainProductGroupLevel;
      //to differ easy a change when saving
      this.beforeEditingGroupLevel = this.mainProductGroupLevel;

      //if product group 2, set parent group
      if (this.editProductGroupLevel === "2") {
        let productgrouplist_1 = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
        let productgroup_1 = productgrouplist_1.find((x) =>
          x.children.find((y) => y === this.currentSelectedProductGroup._id)
        );
        this.selectedProductGroup1 = productgroup_1._id;
      }

      //set dialog title
      this.editDialogTitle = `${this.currentSelectedProductGroup.label} editieren`;

      // show dialog
      this.dialogEdit = true;
    },
    async changeProductGroup() {
      let isValid = await this.$refs.validateEditObserver.validate();
      if (!isValid) {
        return;
      }

      // if name changed
      if (this.currentSelectedProductGroup.label != this.changedProductGroupName) {
        // check if name allready exists
        let checkIndex = this.productGroupsByCurrentLevel.findIndex((x) => x.label === this.changedProductGroupName);
        if (checkIndex != -1) {
          MessageBox.alert("Dieser Name wurde bereits vergeben", "", {
            type: "error",
            showClose: false,
          });
          return;
        }
      }

      // check possible product group type changes
      if (this.editProductGroupLevel != this.beforeEditingGroupLevel) {
        //changed to level 1 - WAS level 2
        if (this.editProductGroupLevel === "1") {
          try {
            // still assigned somewhere?
            let productgrouplist_1 = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
            let productgroup_1 = productgrouplist_1.find((x) =>
              x.children.find((y) => y === this.currentSelectedProductGroup._id)
            );

            // assignement found
            if (productgroup_1) {
              // remove
              let index = productgroup_1.children
                .map((x) => {
                  return x;
                })
                .indexOf(this.currentSelectedProductGroup._id);
              productgroup_1.children.splice(index, 1);

              // update database
              await this.$store.dispatch("productGroupSettings/updateProductGroup", productgroup_1);

              // add to root
              let rootElement = this.$store.getters["productGroupSettings/productGroupTree_root"];
              rootElement.children.push(this.currentSelectedProductGroup._id);
              //update database
              await this.$store.dispatch("productGroupSettings/updateProductGroup", rootElement);

              Message({
                message: `${this.currentSelectedProductGroup.label} neu zugeordnet`,
                type: "success",
              });
            }
          } catch (error) {
            Message({
              message: "Name nicht geändert",
              type: "error",
            });
          }

          //changed to level 2 - WAS level 1
        } else if (this.editProductGroupLevel === "2") {
          //assigned same product group
          if (this.selectedProductGroup1 == this.currentSelectedProductGroup._id) {
            MessageBox.alert(`die Produktgruppe kann nicht sich selbt zugeordnet werden!`, "", {
              type: "error",
              showClose: false,
            });
            return;
          }

          // contains assigned product group 2 elements?
          if (this.currentSelectedProductGroup.children.length > 0) {
            MessageBox.alert(
              `Es sind noch ${this.currentSelectedProductGroup.children.length} Produktgruppen zugeordnet. Bitte entfernen Sie diese vorher.`,
              "",
              {
                type: "error",
                showClose: false,
              }
            );
            return;
          }

          // get new parent
          let parentProductGroup = this.productGroups.find((x) => x._id === this.selectedProductGroup1);
          parentProductGroup.children.push(this.currentSelectedProductGroup._id);
          //update database
          await this.$store.dispatch("productGroupSettings/updateProductGroup", parentProductGroup);

          // get root
          let rootElement = this.$store.getters["productGroupSettings/productGroupTree_root"];
          // remove from root
          let index = rootElement.children
            .map((x) => {
              return x;
            })
            .indexOf(this.currentSelectedProductGroup._id);
          rootElement.children.splice(index, 1);
          //update database
          await this.$store.dispatch("productGroupSettings/updateProductGroup", rootElement);

          Message({
            message: `${this.currentSelectedProductGroup.label} neu zugeordnet`,
            type: "success",
          });
        }
      }
      // changed parent in level 2 product groups?
      else if (this.editProductGroupLevel === "2") {
        let productgrouplist_1 = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
        let productgroup_1 = productgrouplist_1.find((x) =>
          x.children.find((y) => y === this.currentSelectedProductGroup._id)
        );

        //remove from old parent
        let index = productgroup_1.children
          .map((x) => {
            return x;
          })
          .indexOf(this.currentSelectedProductGroup._id);
        productgroup_1.children.splice(index, 1);
        // update database
        await this.$store.dispatch("productGroupSettings/updateProductGroup", productgroup_1);

        // add to new parent
        let parentProductGroup = this.productGroups.find((x) => x._id === this.selectedProductGroup1);
        parentProductGroup.children.push(this.currentSelectedProductGroup._id);
        //update database
        await this.$store.dispatch("productGroupSettings/updateProductGroup", parentProductGroup);

        Message({
          message: `${this.currentSelectedProductGroup.label} neu zugeordnet`,
          type: "success",
        });
      }

      // use new name
      if (this.currentSelectedProductGroup.label != this.changedProductGroupName) {
        this.currentSelectedProductGroup.label = this.changedProductGroupName;
        try {
          //call update
          await this.$store.dispatch("productGroupSettings/updateProductGroup", this.currentSelectedProductGroup);

          Message({
            message: "Name geändert",
            type: "success",
          });
        } catch (error) {
          Message({
            message: "Name nicht geändert",
            type: "error",
          });
        }
      }

      // close dialog
      this.dialogEdit = false;

      //clear
      this.changedProductGroupName = "";
      this.selectedProductGroup1 = "";
      this.$refs.validateEditObserver.reset();
    },
    prepareCreation() {
      // first reset
      this.resetSelection();

      //clear name
      this.newProductGroupName = "";

      // preselect product group level
      this.editProductGroupLevel = this.mainProductGroupLevel;

      //show dialog
      this.dialogCreateProductGroup = true;
    },
    async createProductGroup() {
      let isValid = await this.$refs.validateObserver.validate();
      if (!isValid) {
        return;
      }

      // check if name allready exists
      let checkIndex = this.productGroupsByCurrentLevel.findIndex((x) => x.label === this.newProductGroupName);
      if (checkIndex != -1) {
        MessageBox.alert("Dieser Name wurde bereits vergeben", "", {
          type: "error",
          showClose: false,
        });
        return;
      }

      let rootElement = this.$store.getters["productGroupSettings/productGroupTree_root"];

      // create new product group object depending on productgroup hierarchie
      let productgroupItem = {
        label: this.newProductGroupName,
        model: this.modelType,
        children: [],
      };

      try {
        //call axios directly - not vuex to get the new product group ID
        let response = await this.axios.post(`/api/product-groups`, productgroupItem);
        let newID = response.data._id;

        // add productgroup to product group root as child
        if (this.editProductGroupLevel === "1") {
          rootElement.children.push(newID);
          //call update
          await this.$store.dispatch("productGroupSettings/updateProductGroup", rootElement);
        }
        // add productgroup to selected product group 1 as child
        else if (this.editProductGroupLevel === "2") {
          let parentProductGroup = this.productGroups.find((x) => x._id === this.selectedProductGroup1);
          parentProductGroup.children.push(newID);

          //call update
          await this.$store.dispatch("productGroupSettings/updateProductGroup", parentProductGroup);
        }

        // clear
        this.selectedProductGroup1 = "";
        this.editProductGroupLevel = "1";
        this.newProductGroupName = "";

        //close dialog
        this.dialogCreateProductGroup = false;
        this.$refs.validateObserver.reset();
        Message({
          message: "Warengruppe erstellt",
          type: "success",
        });
      } catch (error) {
        Message({
          message: "Warengruppe nicht erstellt",
          type: "error",
        });
      }
    },
    async deleteProductGroup() {
      if (this.selectedProductGroupID == "") {
        MessageBox.alert("Bitte Warengruppe auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }

      //prepare current selected
      this.currentSelectedID = this.selectedProductGroupID;

      let productGroupToDelete = this.currentSelectedProductGroup;

      //are there children? get rid of them first!
      if (productGroupToDelete.children && productGroupToDelete.children.length > 0) {
        MessageBox.alert("Entfernen Sie bitte zuerst alle Warengruppe 2 Zuordnungen", "", {
          type: "error",
          showClose: false,
        });

        // clear current selected
        this.currentSelectedID = "";

        return;
      }

      MessageBox.confirm("Wollen Sie die Warengruppe wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      })
        .then(async () => {
          await this.$store.dispatch("productGroupSettings/deleteProductGroup", productGroupToDelete);
          // still assigned somewhere?
          let productgrouplist_1 = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
          let productgroup_1 = productgrouplist_1.find((x) => x.children.find((y) => y === productGroupToDelete._id));
          // assignement found
          if (productgroup_1) {
            // remove
            let index = productgroup_1.children
              .map((x) => {
                return x;
              })
              .indexOf(productGroupToDelete._id);
            productgroup_1.children.splice(index, 1);

            // update database
            await this.$store.dispatch("productGroupSettings/updateProductGroup", productgroup_1);
          }

          //remove from root?
          let rootElement = this.$store.getters["productGroupSettings/productGroupTree_root"];
          let productgroupInRoot = rootElement.children.find((x) => x === productGroupToDelete._id);

          //call deletion
          //yes -> remove from root
          if (productgroupInRoot) {
            let index = rootElement.children
              .map((x) => {
                return x;
              })
              .indexOf(productGroupToDelete._id);
            rootElement.children.splice(index, 1);
            //update database
            await this.$store.dispatch("productGroupSettings/updateProductGroup", rootElement);
          }

          // clear selection
          this.selectedProductGroupID = "";
          this.currentSelectedID = "";
          this.isCurrentSelectedRoot = false;

          Message({
            message: "Warengruppe gelöscht",
            type: "success",
          });
        })
        .catch((error) => {
          console.error(error);
          let message = "Warengruppe nicht gelöscht";
          if (error.response && error.response.data && error.response.data.message === "USED_IN_RESOURCE") {
            message = "Warengruppe ist einer Ressource zugeordnet";
          }
          Message({
            message: message,
            type: "error",
          });
        });

      // clear current selected
      this.currentSelectedID = "";
    },
    async fetchResources() {
      try {
        const response = await this.axios.get(`/api/${this.resourceTypeToUrl[this.modelType]}/meta`);
        this.resourcesList = response.data;
      } catch (error) {
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      }
    },
    handleShowBatchModal(record) {
      this.fetchResources();
      let payload = {};
      // pg1 does not have nested pg2's or is collapsed - then assign only pg1
      if (!record.expanded || !record.expand) {
        payload.selectedProductGroup1 = record.refId;
        this.batchModalLabel = `Warengruppe 1 "${record.pg1Name}" wird ausgewählten Ressourcen hinzugefügt`;
        // both pg's should be added
      } else {
        payload.selectedProductGroup1 = record.refId;
        payload.selectedProductGroup2 = record.id;
        this.batchModalLabel = `Warengruppe 1 "${record.pg1Name}" und Warengruppe 2 "${record.pg2Name}" wird ausgewählten Ressourcen hinzugefügt`;
      }
      this.batchModalVisible = true;
      this.batchAddPayload = payload;
    },
    dismissBatchAddModal() {
      this.batchModalVisible = false;
      this.batchAddPayload = null;
      this.batchModalLabel = "";
      this.selectedResources = [];
    },
    async submitBatchAdd() {
      try {
        this.batchLoading = true;
        const resourceUrl = this.resourceTypeToUrl[this.modelType];
        await Promise.all(
          this.selectedResources.map((resourceId) =>
            this.axios.put(`/api/${resourceUrl}/${resourceId}`, this.batchAddPayload)
          )
        );
        Message.success("Warengruppen bearbeitet");
        this.dismissBatchAddModal();
      } catch (error) {
        const message = get(error, "response.data.message", error.message);
        Message.error(message);
        throw error;
      } finally {
        this.batchLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.pointer {
  color: #ffaa77;
  cursor: pointer;
}
</style>
