<template>
<div>
    <div class="card card-user">
        <form>
            <div class="card-header ">
                 <h6 class="title text-center">{{tabName}}</h6>
            </div>

            <div class="card-body">

                <div class="row">
                    
                    <div class="col-md-3">
                        <textarea v-model="vehicle.service" class="form-control" :placeholder="$t('src.components.vehicle.service.freeText')" rows="3"></textarea>
                    </div>
                </div>

                <div class="text-center">
                    <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile(vehicle)">{{ $t('src.components.vehicle.service.speichern') }}</button>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import { Tag } from "element-ui";

export default {
  name: "vehicle-service",
  props: {
    vehicle: Object,
    tabName: String,
    updateProfile: {
      type: Function,
      required: true
    }
  },
  components: {
    [Tag.name]: Tag,
  },
  methods: {
  }
};
</script>

<style>
</style>
