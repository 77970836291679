<template>
  <span class="icon-larger">
    <account-hard-hat-outline v-if="type === 'RUNNING'" />
    <arrow-right v-else-if="type === 'FUTURE'" />
    <arrow-left v-else-if="type === 'PREVIOUS'" />
    <sync v-else-if="type === 'IS_WORKSHOP'" />
    <span v-else>{{ type }}</span>
  </span>
</template>

<script>
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import AccountHardHatOutline from "vue-material-design-icons/AccountHardHatOutline.vue";
import Sync from "vue-material-design-icons/Sync.vue";

export default {
  name: "filter-type-icon",
  components: {
    ArrowRight,
    ArrowLeft,
    AccountHardHatOutline,
    Sync,
  },
  props: {
    type: String,
  },
};
</script>
