<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Resource type access") }}
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('employee')"
        v-model="value.employeeCalendarAccess"
        :description="$t('employeeCalendarAccessDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('vehicle')"
        v-model="value.vehicleCalendarAccess"
        :description="$t('vehicleCalendarAccessDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('machine')"
        v-model="value.machineCalendarAccess"
        :description="$t('machineCalendarAccessDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('rhb')"
        v-model="value.rhbCalendarAccess"
        :description="$t('rhbCalendarAccessDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('supply')"
        v-model="value.supplyCalendarAccess"
        :description="$t('supplyCalendarAccessDescription')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('subcontractor')"
        v-model="value.subcontractorCalendarAccess"
        :description="$t('subcontractorCalendarAccessDescription')"
      />
    </access-rights-table>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.spmCalendarSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('quickProjectSetup')"
        v-model="value.quickProjectSetup"
        :description="$t('quickProjectSetupDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('infoDownload')"
        v-model="value.infoDownload"
        :description="$t('infoDownloadDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('Planungsvorlage')"
        v-model="value.montagsplanung"
        :description="$t('montagsplanungDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('hotelInfo')"
        v-model="value.hotelInfo"
        :description="$t('hotelInfoDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('departureTimes')"
        v-model="value.departureTimes"
        :description="$t('departureTimesDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('invoices')"
        v-model="value.invoices"
        :description="$t('invoicesDescription')"
        :booleanMode="true"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsTable from "./AccessRightsTable.vue";
import AccessRightsRow from "./AccessRightsRow.vue";

export default {
  name: "spm-calendar-access",
  components: {
    AccessRightsTable,
    AccessRightsRow,
  },
  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style></style>
