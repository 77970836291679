import axios from "axios";

const state = {
  dataList: [],
  projectId: "",
  editModel: {}
};

// Mutations Must Be Synchronous
const mutations = {
  //must be called to reset selections from other entities
  init(state) {
    state.dataList = [];
  },
  initEditModel(state) {
    state.editModel = { date: null, dateRange: null, freeText: "", document: {}, isNew: true };
  },
  initEditModelDate(state) {
    state.editModel.date = null;
    state.editModel.dateRange = null;
  },
  updateDataList(state, dataList) {
    state.dataList = dataList;
  },
  updateProjectId(state, projectId) {
    state.projectId = projectId;
  },
  updateEditModel(state, editModel) {
    state.editModel = editModel;
  }
};

// Actions can contain arbitrary asynchronous operations
const actions = {
  async initAsync({ commit }, params) {
    //store modelType
    const { projectId } = params;

    //init ressource status
    commit("init");
    commit("initEditModel");

    //store metadata
    commit("updateProjectId", projectId);


    try {
      // load all ressource status
      const response = await axios.get(`/api/daily-c-report/project/${projectId}`);
      //store dataList
      commit("updateDataList", response.data);
    } catch (e) {
      throw e;
    }
  },
  async reloadDataListAsync({ commit, state }) {
    //init selection
    commit("init");

    try {
      // load all ressource status
      const response = await axios.get(`/api/daily-c-report/project/${state.projectId}`);
      //store dataList
      commit("updateDataList", response.data);
    } catch (e) {
      throw e;
    }
  },
  async delete({ dispatch }, reportId) {
    try {
      await axios.delete(`/api/daily-c-report/${reportId}`);
      dispatch("reloadDataListAsync");
    } catch (e) {
      throw e;
    }
  },
  async update({ dispatch }, reportData) {
    try {
      let { reportId, formData } = reportData;
      await axios.put(`/api/daily-c-report/${reportId}`, formData);
      dispatch("reloadDataListAsync");
    } catch (e) {
      throw e;
    }
  },
  async create({ dispatch }, reportData) {
    try {
      await axios.post("/api/daily-c-report", reportData);
      dispatch("reloadDataListAsync");
    } catch (e) {
      throw e;
    }
  }
};

const getters = {
  dataList(state) {
    return state.dataList;
  },
  editModel(state) {
    return state.editModel;
  }
};

export const dailyReport = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
