import axios from "axios";

const state = {
  statusList: [],
  statusModelID: "",
  statusModel: "",
  editingStatus: {},
};

// Mutations Must Be Synchronous
const mutations = {
  //must be called to reset selections from other entities
  init(state) {
    state.statusList = [];
    state.editingStatus = {};
  },
  updateStatusList(state, statusList) {
    state.statusList = statusList;
  },
  updateStatusModelID(state, statusModelID) {
    state.statusModelID = statusModelID;
  },
  updateStatusModel(state, statusModel) {
    state.statusModel = statusModel;
  },
  updateEditingStatus(state, editingStatus) {
    state.editingStatus = editingStatus;
  },
};

// Actions can contain arbitrary asynchronous operations
const actions = {
  async initAsync({ commit }, params) {
    //store modelType
    const { statusModelID } = params;
    const { statusModel } = params;

    //init ressource status
    commit("init");

    //store metadata
    commit("updateStatusModelID", statusModelID);
    commit("updateStatusModel", statusModel);
    console.log('statusModel', statusModel);
    try {
      // load all ressource status
      let response = await axios.get("/api/status-events", {
        params: { resourceId: statusModelID, resourceType: statusModel },
      });
      console.log("initAsync", response.data);
      //store statusList
      commit("updateStatusList", response.data);
    } catch (e) {
      throw e;
    }
  },
  async reloadStatusAsync({ commit, state }) {
    //init selection
    commit("init");
    try {
      // load all ressource status
      let response = await axios.get("/api/status-events", { params: { resourceId: state.statusModelID } });
      //store statusList
      commit("updateStatusList", response.data);
    } catch (error) {
      throw error;
    }
  },
  async delete({ dispatch, state }, statusID) {
    console.log("delete", statusID);

    const metadata = state.statusModel + "_" + state.statusModelID + "_status_" + statusID;
    await axios.delete(`/api/status-events/${statusID}`, { params: { metadata: metadata } });
    dispatch("reloadStatusAsync");
  },
  async createEmpty({ state, commit }) {
    try {
      let result = await axios.post("/api/status-events/empty", {
        resourceType: state.statusModel,
        resourceId: state.statusModelID,
      });
      let newStatus = result.data;
      // we mark it as a new entry to handle user cancel event correctly
      newStatus.isNew = true;
      console.log("createEmpty", newStatus);
      commit("updateEditingStatus", newStatus);
    } catch (error) {
      throw error;
    }
  },
  async update({ dispatch }, status) {
    const formData = { ...status };
    console.log("update", formData);
    if (typeof status.statusType === "object") {
      formData.statusType = formData.statusType._id;
    }
    try {
      await axios.put(`/api/status-events/${status.id}`, formData);
      dispatch("reloadStatusAsync");
    } catch (error) {
      throw error;
    }
  },
  async updateFileList({ state }, filelist_status) {
    console.log("updateFileList", filelist_status);
    console.log("updateFileList statusList", state.statusList);
    let status = null;
    // HACK:use same data to update - just change filellist
    if (filelist_status.isNew) {
      // when new use this object to update, the state is not yet in the statelist
      status = { ...state.editingStatus };
    } else {
      let storedStatus = state.statusList.find((x) => x.id === filelist_status.id);
      status = { ...storedStatus };
    }

    status.fileList = filelist_status.fileList;
    if (typeof status.statusType === "object") {
      status.statusType = status.statusType._id;
    }
    await axios.put(`/api/status-events/${status.id}`, status);
  },
};

const getters = {
  statusList: (state) => {
    return state.statusList;
  },
  editingStatus: (state) => {
    return state.editingStatus;
  },
};

export const ressourceStatus = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
