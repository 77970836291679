<template>
  <div>
    <template v-if="editMode">
      <span v-if="required" style="float: left" class="n-profile-select-title-red">*</span>
      <span class="n-profile-label-title">{{ getTitle() }}:</span><br />
      <v-select
        v-model="listInput"
        clearable
        :multiple="false"
        :placeholder="placeholder"
        :valueIdentifier="valueIdentifier"
        :options="items"
        :size="size"
        :data-testid="`${productGroupType}_select`"
      />
    </template>
    <template v-else>
      <span class="n-profile-label-title">{{ getTitle() }}:</span>
      <br />
      <span class="n-profile-label-value">{{ getLabel() }} </span>
    </template>
  </div>
</template>

<script>
export default {
  name: "product-group-select",
  props: {
    productGroupType: {
      type: String,
      required: true,
      default: null,
      validator: function (value) {
        // The value must match one of these strings
        return ["pg1", "pg2"].indexOf(value) !== -1;
      },
    },
    value: {
      type: [Array, String],
    },
    valueIdentifier: {
      type: String,
      default: "_id",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    showValue: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
    },
    title: {
      type: String,
      default: null,
    },
    size: {
      type: String,
    },
  },
  computed: {
    listInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        if (this.productGroupType === "pg1") {
          this.$store.commit("productGroupSettings/updateSelectedProductGroup_1", newValue);
          //inform registered components about changing
          this.$root.$emit("selectedProductGroup1Changed", newValue);
        } else if (this.productGroupType === "pg2") {
          this.$store.commit("productGroupSettings/updateSelectedProductGroup_2", newValue);
        }

        this.$emit("input", newValue);
      },
    },
    items: {
      get() {
        let result;
        if (this.productGroupType === "pg1") {
          result = this.$store.getters["productGroupSettings/productgroup_1_ordered"];
        } else if (this.productGroupType === "pg2") {
          result = this.$store.getters["productGroupSettings/productGroup_2_Computed"];
        } else {
          result = [];
        }
        return result;
      },
    },
    placeholder: {
      get() {
        if (this.productGroupType === "pg1") {
          return " — ";
        } else if (this.productGroupType === "pg2") {
          let result = this.$store.getters["productGroupSettings/productgroup_2_placeholder"];
          return result;
        }
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    //need to watch changes of PG1 for reset always PG2
    if (this.productGroupType === "pg2") {
      this.$root.$on("selectedProductGroup1Changed", (selectedProductGroup1) => {
        this.listInput = null;
      });
    }
  },
  beforeDestroy() {
    if (this.productGroupType === "pg2") {
      this.$root.$off("selectedProductGroup1Changed");
    }
  },
  methods: {
    getLabel() {
      if (this.value) {
        let item = this.items.find((i) => i[this.valueIdentifier] == this.value);
        if (item) {
          return item["label"];
        } else {
          return " — ";
        }
      } else {
        return " — ";
      }
    },
    getTitle() {
      // return custom title if set, else standard title depending on product group
      if (this.title) {
        return this.title;
      } else {
        return this.productGroupType === "pg1" ? "Warengruppe Ebene 1" : "Warengruppe Ebene 2";
      }
    },
  },
};
</script>

<style lang="scss">
@mixin tag($type) {
  .el-tag,
  .el-tag.el-tag--#{$type} {
    .el-select__tags-text {
      color: black;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
    .el-tag__close {
      color: black;
      background-color: white !important;
    }
    .el-tag__close.el-icon-close {
      color: black;
    }
    .el-tag__close.el-icon-close:hover {
      color: green;
      font-size: 18px;
    }
    .el-tag__close:hover {
      background-color: white;
      color: black;
    }
    border: 0px;
    background-color: white;
  }
}

@include tag("info");
</style>
