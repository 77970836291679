<template>
  <div>
    <template v-if="editMode">
      <span class="n-profile-label-title">{{
        $t("src.components.uicomponents.inputs.servicespectrumselect.leistungsspektrum")
      }}</span
      ><br />
      <v-select
        v-model="inputValue"
        :multiple="true"
        :placeholder="placeholder"
        :options="computedList"
        valueIdentifier="_id"
      />
    </template>
    <template v-else>
      <span class="n-profile-label-title">{{
        $t("src.components.uicomponents.inputs.servicespectrumselect.leistungsspektrum")
      }}</span>
      <br />
      <span class="n-profile-label-value">{{ getLabel() }} </span>
    </template>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { mapActions, mapState } from "vuex";

export default {
  name: "service-spectrum-select",
  props: {
    value: [Array, String],
    editMode: Boolean,
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
  },
  mounted() {
    if (!this.loading && !this.list.length) {
      this.loadList();
    }
  },
  methods: {
    ...mapActions("serviceSpectrum", ["loadList"]),
    getLabel() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value
            .map((id) => {
              const found = this.list.find((item) => item._id === id);
              if (found) {
                return found.label;
              }
              return id;
            })
            .toString();
        } else {
          let item = this.list.find((i) => i[this.valueIdentifier] == this.value);
          if (item) {
            return item["label"];
          } else {
            return " — ";
          }
        }
      } else {
        return " — ";
      }
    },
  },
  computed: {
    ...mapState("serviceSpectrum", ["list", "loading"]),
    computedList() {
      return this.list.filter((item) => item.active);
    },
    placeholder: function () {
      if (this.computedList.length > 0) {
        return this.computedList.length + " verfügbar";
      } else {
        return "keine erstellt";
      }
    },
    inputValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>
