<template>
  <el-popover
    placement="left"
    :title="$t('src.components.project.downloadcenter.templatevariablesguide.vorlagenvariablen')"
    trigger="click"
  >
    <div>
      <ul style="padding-left: 15px">
        <li>
          <b>{{ $t("src.components.project.downloadcenter.templatevariablesguide.creationdate") }}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.datumAn") }}
        </li>
        <li>
          <b>{{ $t("src.components.project.downloadcenter.templatevariablesguide.projecttype") }}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.nameDesProjekttyps") }}
        </li>
        <li>
          <b>{{ $t("src.components.project.downloadcenter.templatevariablesguide.projectname") }}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.nameDesProjekts") }}
        </li>
        <li>
          <b>{{ $t("src.components.project.downloadcenter.templatevariablesguide.projectstartdate") }}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.startdatumDesProjekts") }}
        </li>
        <li>
          <b>{{ $t("src.components.project.downloadcenter.templatevariablesguide.projectenddate") }}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.enddatumDesProjekts") }}
        </li>
        <li>
          <b>{{ $t("src.components.project.downloadcenter.templatevariablesguide.projectdaterange") }}</b>
          {{ $t("src.components.project.downloadcenter.templatevariablesguide.datumsbereichDesProjekts") }}
        </li>
        <li>
          <b>${CURRENT_CONSTRUCTION_MANAGER}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.constrManagerLabel") }}
        </li>
        <li>
          <b>${CURRENT_RESPONSIBLE_EMPLOYEE}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.responsibleEmployeeLabel") }}
        </li>
        <li>
          <b>${CORE_SERVICES}</b
          >{{ $t("src.components.project.downloadcenter.templatevariablesguide.coreServicesLabel") }}
        </li>
        <li><b>${EMPLOYEES}</b>{{ $t("List of employees within project") }}</li>
        <li><b>${DATE_RANGE}</b>{{ $t("Selected date range") }}</li>
      </ul>
    </div>
    <template v-slot:reference>
      <span>
        <el-button class="mr-2" type="info" v-if="infoType === 'button'">
          <information-icon />{{
            $t("src.components.project.downloadcenter.templatevariablesguide.vorlagenvariablen")
          }}</el-button
        >
        <span v-else>
          <el-tooltip content="Vorlagenvariablen">
            <span style="color: #51bcda; margin-left: 5px">
              <information-icon />
            </span>
          </el-tooltip>
        </span>
      </span>
    </template>
  </el-popover>
</template>

<script>
import { Popover, Tooltip } from "element-ui";
import InformationIcon from "vue-material-design-icons/Information";

export default {
  name: "template-variables-guide",
  components: {
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip,
    InformationIcon,
  },
  props: {
    infoType: { type: String, enum: ["icon", "button"], default: "button" },
  },
};
</script>

<style></style>
