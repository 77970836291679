<template>
  <div>
    <p class="title mb-10" style="font-size: 16px">
      {{ $t("src.components.project.contacts.components.serviceproviderslist.dienstleister") }}
    </p>
    <div v-if="!contactDataList.length" style="padding: 10px; text-align: center; color: #858585">
      {{ $t("src.components.project.contacts.components.serviceproviderslist.keineDaten") }}
    </div>
    <service-provider-record
      v-for="(contactData, idx) in contactDataList"
      :key="idx"
      :contactData="contactData"
      :disabled="disabled"
      :title="`Dienstleister #${idx + 1}`"
      :addFreeText="addFreeText"
      :projectId="projectId"
      :isEditMode="!disabled"
      @deleteFile="handleDeleteFile"
      @removeRecord="removeItem(idx)"
      :serviceProviderTypeOptions="serviceProviderOptions"
    />
    <div class="form-group" v-show="!disabled">
      <el-button type="primary" :disabled="disabled" icon="el-icon-plus" @click="addItem">{{
        $t("src.components.project.contacts.components.serviceproviderslist.hinzufgen")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import { Button, Message } from "element-ui";
import ServiceProviderRecord from "./ServiceProviderRecord";

export default {
  name: "service-providers-list",
  components: {
    [Message.name]: Message,
    [Button.name]: Button,
    [ServiceProviderRecord.name]: ServiceProviderRecord,
  },
  props: {
    contactDataList: { type: Array, required: true },
    addFreeText: { type: Boolean },
    disabled: { type: Boolean },
    projectId: { type: String },
  },
  data() {
    return {
      serviceProviderOptions: [],
    };
  },
  mounted() {
    this.fetchServiceProviderOptions();
  },
  methods: {
    async fetchServiceProviderOptions() {
      try {
        const response = await this.axios.get("/api/project-contacts/service-provider-options");
        this.serviceProviderOptions = sortBy(
          response.data.map((item) => ({
            value: item._id,
            label: item.label,
          })),
          "label"
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    addItem() {
      this.contactDataList.push({});
    },
    handleDeleteFile(url) {
      this.$emit("deleteFile", url);
    },
    removeItem(idx) {
      this.contactDataList.splice(idx, 1);
    },
  },
};
</script>

<style></style>
