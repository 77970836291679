<template>
  <div>
    <div class="type-settings-wrapper">
      <div style="flex-grow: 1">
        <profile-select
          :editMode="true"
          :title="`Projekt dokumenttyp (${docTypes.length})`"
          :multiple="false"
          :items="docTypes"
          v-model="selectedTypeId"
          valueIdentifier="_id"
          label="name"
          :disableInactive="false"
          :hideInactive="false"
        />
      </div>
      <div v-if="isEditMode" style="margin-left: 6px; margin-bottom: 6px">
        <el-button class="btn btn-sm btn-ghost" @click="showCreateForm">
          <plus-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="editType">
          <pencil-icon />
        </el-button>
        <el-button v-if="$can('delete', 'project')" class="btn btn-sm btn-ghost" @click="deleteType">
          <trash-can-outline-icon />
        </el-button>
      </div>
    </div>
    <!-- DIALOG -->
    <el-dialog
      :title="$t('src.components.management.projectsettings.projectdoctypesettings.projektDokumenttypKonfiguration')"
      :visible.sync="visible"
      @closed="dismiss"
      width="800px"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="formData" :rules="formRules" :disabled="!editable">
        <el-form-item
          :label="$t('src.components.management.projectsettings.projectdoctypesettings.name')"
          prop="name"
          label-width="200px"
        >
          <el-input autofocus v-model="formData.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item
          prop="file"
          :label="$t('src.components.management.projectsettings.projectdoctypesettings.template')"
          label-width="200px"
        >
          <el-upload
            :headers="authHeader"
            style="display: inline-block"
            action=""
            :file-list="fileList"
            :multiple="false"
            :limit="1"
            :on-remove="fileRemoved"
            :accept="formData.isTemplate ? '.pdf, .pptx, .docx, .xlsx' : undefined"
            :on-error="handleUploadError"
            :http-request="fileUploaded"
            :on-exceed="handleExceed"
          >
            <el-button size="small" icon="el-icon-document-add">{{
              $t("src.components.management.projectsettings.projectdoctypesettings.dateiAuswhlen")
            }}</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item prop="usedInSma" label-width="200px">
          <el-checkbox v-model="formData.usedInSma" auto-complete="off">{{
            $t("src.components.management.projectsettings.projectdoctypesettings.usedInSma")
          }}</el-checkbox>
        </el-form-item>
        <el-form-item prop="isTemplate" label-width="200px">
          <el-checkbox v-model="formData.isTemplate" auto-complete="off">{{
            $t("src.components.management.projectsettings.projectdoctypesettings.istVorlage")
          }}</el-checkbox>
        </el-form-item>
      </el-form>

      <div>
        <template-variables-guide />
        <el-button v-if="savedTemplate" @click="downloadTemplate">{{ $t("Download document type") }}</el-button>
      </div>

      <template v-slot:footer>
        <div class="dialog-footer">
          <cancel-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.abbrechen')"
            @click="dismiss"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.projectsettings.projectdoctypesettings.speichern')"
            @click="saveType"
          ></save-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Message, Form, FormItem, Input, MessageBox, Checkbox, Upload } from "element-ui";
import { mapGetters } from "vuex";
import TemplateVariablesGuide from "../../Project/DownloadCenter/TemplateVariablesGuide.vue";
import { saveAs } from "file-saver";

export default {
  name: "project-doc-type-settings",
  props: {
    isEditMode: Boolean,
  },
  components: {
    TemplateVariablesGuide,
    [Upload.name]: Upload,
    [Checkbox.name]: Checkbox,
    [MessageBox.name]: MessageBox,
    [Form.name]: Form,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  data() {
    const fileValidaror = (r, value, callback) => {
      if (this.localFile || this.savedTemplate) {
        callback();
      } else {
        callback(new Error("Bitte Datei auswählen"));
      }
    };
    return {
      downloadLoading: false,
      visible: false,
      editable: false,
      isNew: false,
      docTypes: [],
      formRules: {
        name: {
          required: true,
          message: "Bitte Name auswählen",
          trigger: "change",
        },
        file: {
          required: true,
          message: "Bitte Datei auswählen",
          trigger: "change",
          validator: fileValidaror,
        },
      },
      selectedTypeId: "",
      formData: {
        name: "",
        isTemplate: true,
        usedInSma: false,
        file: null,
      },
      localFile: null, // used for storing file before submit
      savedTemplate: null, // used for displaying saved file
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const response = await this.axios.get("/api/project-document-types");
        this.docTypes = response.data || [];
        this.notify("loaded", response.data);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    dismiss() {
      this.isNew = false;
      this.visible = false;
      this.editable = false;
      this.localFile = null;
      this.savedTemplate = null;

      this.$refs.form.resetFields();
      this.formData.name = "";
      this.formData.file = null;
      this.formData.isTemplate = true;
      this.formData.usedInSma = false;
    },
    showCreateForm() {
      this.isNew = true;
      this.visible = true;
      this.editable = true;
    },
    showEditForm() {
      this.isNew = false;
      this.visible = true;
      this.editable = true;
    },
    editType() {
      if (!this.selectedTypeId) {
        MessageBox.alert("Bitte Projekttyp auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.showEditForm();

      const selectedRecord = this.docTypes.find((item) => item._id === this.selectedTypeId) || {};
      this.formData.name = selectedRecord.name;
      this.savedTemplate = selectedRecord.template;
      this.formData.isTemplate = selectedRecord.isTemplate;
      this.formData.usedInSma = !!selectedRecord.usedInSma;
    },
    deleteType() {
      if (!this.selectedTypeId) {
        MessageBox.alert("Projekt dokumenttyp auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.$confirmDelete().then(async () => {
        await this.axios.delete(`/api/project-document-types/${this.selectedTypeId}`);
        this.docTypes = this.docTypes.filter((item) => item._id !== this.selectedTypeId);
        this.selectedTypeId = "";
        this.notify("deleted", this.docTypes.slice());
        this.notify("loaded", this.docTypes.slice());
      });
    },
    async downloadTemplate() {
      try {
        this.downloadLoading = true;
        const response = await this.axios.get(this.savedTemplate.url, { responseType: "blob" });
        const file = new File([response.data], this.savedTemplate.name, { type: "application/octet-stream" });
        saveAs(file, file.name);
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.downloadLoading = false;
      }
    },
    async fileUploaded({ data, file, filename }) {
      this.localFile = file;
    },
    async fileRemoved() {
      if (this.localFile) {
        this.localFile = null;
      }
      if (this.savedTemplate) {
        this.savedTemplate = null;
      }
    },
    handleExceed(files, fileList) {
      this.fileUploaded({ file: files[0] });
    },
    async saveType() {
      try {
        await this.$refs.form.validate();
        const formData = this.formatFormBody(this.formData);
        if (this.isNew) {
          // create
          await this.axios.post(`/api/project-document-types`, formData);
        } else {
          // edit
          await this.axios.put(`/api/project-document-types/${this.selectedTypeId}`, formData);
        }
        this.fetch();
        this.dismiss();
      } catch (error) {
        // form validation exception - just return
        if (typeof error === "boolean") {
          return;
        }
        Message.error(error.message);
      }
    },
    /**
     * Common eventbus payload interface with TypeSettings.
     * @param {('loaded'|'created'|'updated'|'deleted')} operation
     * @param {Array} options
     */
    notify(operation, options) {
      const data = {
        modelType: "project",
        modelID: "docTypes",
        operation: operation,
        options: options,
      };
      this.$root.$emit("settingsChanged", data);
      this.$emit("dataChanged", data);
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    // returns ready to submit form body
    formatFormBody(data) {
      const formData = new FormData();
      const { name, isTemplate, usedInSma } = data;
      if (this.localFile) {
        formData.append("file", this.localFile);
      }
      if (name) {
        formData.append("name", name);
      }
      formData.append("isTemplate", isTemplate);
      formData.append("usedInSma", !!usedInSma);
      return formData;
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]), //for file upload
    fileList() {
      if (this.localFile) {
        return [{ name: this.localFile.name }];
      }
      if (this.savedTemplate) {
        return [this.savedTemplate];
      }
      return [];
    },
  },
};
</script>

<style></style>
