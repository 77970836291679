<template>
  <div
    v-if="color"
    :class="['project-color', { right: position === 'right' }]"
    :style="{ backgroundColor: color }"
  ></div>
</template>

<script>
export default {
  name: "project-color",
  props: {
    color: { type: String },
    position: { type: String, enum: ["left", "right"], default: "left" },
  },
};
</script>

<style lang="scss">
.project-color {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  bottom: 0;
  &.right {
    left: initial;
    right: 0;
  }
}
</style>
