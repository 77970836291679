<template>
  <el-popover placement="top" v-model="visible" width="200">
    <p class="mb-1">{{ $t("Layout:") }}</p>
    <div class="d-flex justify-content-center">
      <el-radio-group :value="localValue" @input="handleChange">
        <el-radio label="table">{{ $t("Table") }}</el-radio>
        <el-radio label="list">{{ $t("List") }}</el-radio>
      </el-radio-group>
    </div>
    <template v-slot:reference>
      <div style="cursor: pointer; margin-top: 2px" class="p-1 mr-1">
        <VectorArrangeAboveIcon />
      </div>
    </template>
  </el-popover>
</template>

<script>
import { Popover, RadioGroup, Radio } from "element-ui";
import VectorArrangeAbove from "vue-material-design-icons/VectorArrangeAbove.vue";

export default {
  name: "layout-type-select",
  components: {
    [VectorArrangeAbove.name]: VectorArrangeAbove,
    [Popover.name]: Popover,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  props: {
    value: String,
  },
  data() {
    return {
      visible: false,
      localValue: "",
    };
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    handleChange(val) {
      this.localValue = val;
      this.visible = false;
      this.$emit("input", val);
    },
  },
};
</script>

<style></style>
