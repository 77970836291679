<template>
  <div class="el-form-item">
    <div>
      <div class="d-flex align-items-center" style="width: 335px">
        <button class="el-button switcher-button mr-1" ref="null" @click="handleClick($event, null)">
          <eye-off-icon /> No access
        </button>
        <el-button-group>
          <button class="el-button switcher-button" ref="readonly" @click="handleClick($event, 'readonly')">
            <eye-icon /> View
          </button>
          <button v-if="withEdit" class="el-button switcher-button" ref="manage" @click="handleClick($event, 'manage')">
            <pencil-icon /> Edit
          </button>
          <button v-if="withDelete" class="el-button switcher-button" ref="full" @click="handleClick($event, 'full')">
            <trash-can-outline-icon /> Remove
          </button>
        </el-button-group>
      </div>
      <div class="ml-2">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import { ButtonGroup } from "element-ui";
import EyeOff from "vue-material-design-icons/EyeOff.vue";
import Eye from "vue-material-design-icons/Eye.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline.vue";

const getAccessValue = (access) => {
  switch (access) {
    case null:
    case undefined:
    case "":
      return 0;
    case "readonly":
      return 1;
    case "manage":
      return 2;
    case "full":
      return 3;
    default:
      throw new Error(`Unsupported access value: ${access}`);
  }
};

export default {
  name: "access-right-switcher",
  components: {
    [ButtonGroup.name]: ButtonGroup,
    [EyeOff.name]: EyeOff,
    [Eye.name]: Eye,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
  },
  props: {
    label: String,
    value: String,
    maximumAccess: { type: String, validator: (prop) => typeof prop === "string" || prop === null },
    canEdit: Boolean,
    withEdit: { type: Boolean, default: true },
    withDelete: { type: Boolean, default: true },
    description: String,
  },
  mounted() {
    this.processAccessRight(this.value);
    this.processMaximumAccess(this.maximumAccess);
  },
  methods: {
    handleClick(event, newV) {
      event.preventDefault();
      if (!this.canEdit) {
        return;
      }
      this.$emit("input", newV);
    },
    processAccessRight(newV) {
      if (newV === null) {
        this.$refs.null.classList.add("el-button--active");

        this.$refs.readonly.classList.remove("el-button--active");
        if (this.$refs.manage) this.$refs.manage.classList.remove("el-button--active");
        if (this.$refs.full) this.$refs.full.classList.remove("el-button--active");
      } else if (newV === "readonly") {
        this.$refs.readonly.classList.add("el-button--active");

        this.$refs.null.classList.remove("el-button--active");
        if (this.$refs.manage) this.$refs.manage.classList.remove("el-button--active");
        if (this.$refs.full) this.$refs.full.classList.remove("el-button--active");
      } else if (newV === "manage") {
        this.$refs.readonly.classList.add("el-button--active");
        if (this.$refs.manage) this.$refs.manage.classList.add("el-button--active");

        this.$refs.null.classList.remove("el-button--active");
        if (this.$refs.full) this.$refs.full.classList.remove("el-button--active");
      } else if (newV === "full") {
        this.$refs.readonly.classList.add("el-button--active");
        if (this.$refs.manage) this.$refs.manage.classList.add("el-button--active");
        if (this.$refs.full) this.$refs.full.classList.add("el-button--active");

        this.$refs.null.classList.remove("el-button--active");
      }
    },
    processMaximumAccess(access) {
      if (access === "full") {
        this.$refs.readonly.disabled = false;
        if (this.$refs.manage) this.$refs.manage.disabled = false;
        if (this.$refs.full) this.$refs.full.disabled = false;
      } else if (access === "manage") {
        this.$refs.readonly.disabled = false;
        if (this.$refs.manage) this.$refs.manage.disabled = false;
        if (this.$refs.full) this.$refs.full.disabled = true;
      } else if (access === "readonly") {
        this.$refs.readonly.disabled = false;
        if (this.$refs.manage) this.$refs.manage.disabled = true;
        if (this.$refs.full) this.$refs.full.disabled = true;
      } else if (access === null) {
        this.$refs.readonly.disabled = true;
        if (this.$refs.manage) this.$refs.manage.disabled = true;
        if (this.$refs.full) this.$refs.full.disabled = true;
      }
    },
  },
  watch: {
    value(newVal) {
      this.processAccessRight(newVal);
    },
    // when maximum access changes - disable button, downgrade access to allowed maximum
    maximumAccess(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }
      this.processMaximumAccess(newVal);
      this.processAccessRight(newVal);
      if ((newVal === "manage" || newVal === "full") && !this.withEdit && !this.withDelete) {
        this.$emit("input", "readonly");
      } else if (newVal === "full" && !this.withDelete) {
        this.$emit("input", "manage");
      } else {
        this.$emit("input", newVal);
      }
    },
  },
};
</script>

<style>
</style>