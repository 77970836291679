<template>
  <div style="position: relative">
    <h6>
      <span v-if="hotelReserveAlarm" class="n-profile-select-title-red">*</span>
      <span>{{ $t("src.components.project.profile.arbeitszeiten") }}:</span>
    </h6>
    <el-dialog :visible="visible" @close="dismissEdit" width="800px" id="working_times">
      <el-form ref="form" :model="selectedWorkingDay" :rules="formDataRules">
        <el-row :gutter="20" type="flex" justify="start" style="flex-wrap: wrap">
          <el-col :span="12">
            <el-form-item prop="weekDays">
              <span class="n-profile-select-title-red">*</span>
              <label class="n-profile-label-title">Arbeitstage</label>
              <template v-if="isEditing">
                <weekday-select style="width: 100%" mode="select" v-model="selectedWorkingDay.weekDays" />
              </template>
              <template v-else>
                <div>
                  {{ selectedWorkingDay.weekDays | formatWeekdays }}
                </div>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="times" style="margin-right: 10px">
              <div style="flex-grow: 1">
                <span class="n-profile-select-title-red">*</span>
                <label class="n-profile-label-title">{{
                  $t("src.components.project.workingtimes.arbeitszeiten")
                }}</label>
              </div>
              <div style="display: flex; justify-content: flex-start; margin-top: -2px; align-items: start">
                <div v-if="isEditing" style="display: flex; flex-wrap: wrap">
                  <div class="timepicker-wrapper" v-for="(time, idx) in selectedWorkingDay.times" :key="idx">
                    <time-picker
                      style="width: 100%"
                      @input="handleTimeChange($event, idx)"
                      :value="time | formatTime"
                      is-range
                    />
                    <i
                      v-if="isEditing && idx !== 0"
                      @click="removeTime(idx)"
                      style="position: absolute; top: -2px; right: -25px"
                      class="close-icon el-select__caret el-input__icon el-icon-circle-close"
                    ></i>
                  </div>
                </div>
                <template v-else>
                  <div>
                    {{ selectedWorkingDay.times | formatTimes }}
                  </div>
                </template>
                <el-button
                  icon="el-icon-plus"
                  style="margin-left: 15px; margin-top: 1px; height: 40px"
                  size="small"
                  v-if="isEditing"
                  @click="addTime"
                ></el-button>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="dateRange" class="mb-4">
              <label class="n-profile-label-title">{{ $t("src.components.project.workingtimes.zeitraum") }}</label>
              <template v-if="isEditing">
                <pr-date-picker
                  :projectDateRange="projectDateRange"
                  style="max-width: 100%; min-width: 100%"
                  v-model="selectedWorkingDay.dateRange"
                  is-range
                  :placeholder="$t('src.components.project.workingtimes.projektlaufzeit')"
                  value-format="YYYY-MM-DD"
                />
              </template>
              <template v-else>
                <div>
                  <template v-if="!selectedWorkingDay.dateRange">{{
                    $t("src.components.project.workingtimes.projektlaufzeit")
                  }}</template>
                  <template v-else>
                    {{ selectedWorkingDay.dateRange | formatDateRange }}
                  </template>
                </div>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="mb-3">
            <profile-input
              :editMode="isEditing"
              type="textarea"
              :label="$t('src.components.project.workingtimes.wichtigeInformationen')"
              :placeholder="$t('src.components.project.workingtimes.wichtigeInformationen')"
              :autosize="{ minRows: 3, maxRows: 4 }"
              v-model="selectedWorkingDay.freeText"
            ></profile-input>
          </el-col>
        </el-row>
        <el-row v-if="isEditMode">
          <div class="n-drawer-footer">
            <el-button class="mr-2" @click.prevent="dismissEdit()">{{
              $t("src.components.project.workingtimes.abbrechen")
            }}</el-button>
            <el-button type="primary" v-if="isAddingNew" @click.prevent="addWorkingTime()">{{
              $t("src.components.project.workingtimes.speichern")
            }}</el-button>
            <el-button type="primary" v-else @click.prevent="saveWorkingTime()">{{
              $t("src.components.project.workingtimes.speichern")
            }}</el-button>
          </div>
        </el-row>
      </el-form>
    </el-dialog>
    <div class="form-group">
      <editable-tag
        v-for="(workingDay, idx) in visibleWorkingDays"
        :key="workingDay._id"
        @view="viewWorkingDay(workingDay, idx)"
        @edit="editWorkingDay(workingDay, idx)"
        @remove="removeWorkingDay(idx)"
        :isEditMode="isEditMode"
        :title="formatWorkingDayString(workingDay)"
        :record="workingDay"
      />
      <cancel-button v-if="isEditMode" @click.prevent="showAddWorkingTime()" data-testid="show_working_times">
        <plus-icon class="mr-2" />{{ $t("src.components.project.workingtimes.hinzufgenZeitrume") }}</cancel-button
      >
      <ValidationProvider
        name="Arbeitszeiten"
        :rules="`${required ? 'required|not_empty_array' : ''}`"
        v-slot="{ errors }"
      >
        <input v-show="false" v-model="workingDays" />
        <transition name="slide">
          <p v-show="errors[0]" class="el-form-item__error">{{ errors[0] }}</p>
        </transition>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { TimePicker } from "src/components/UIComponents";
import { Timeline, TimelineItem, Button, DatePicker, Form, FormItem, Input, Dialog } from "element-ui";
import WeekdaySelect from "src/components/UIComponents/Inputs/WeekdaySelect";
import { moment } from "src/config/moment";
import PrDatePicker from "../UIComponents/Inputs/PrDatePicker.vue";
import PlusIcon from "vue-material-design-icons/Plus";
import { EditableTag } from "src/components/UIComponents";
import { ValidationProvider } from "vee-validate";

export default {
  name: "working-times",
  components: {
    PrDatePicker,
    ValidationProvider,
    [EditableTag.name]: EditableTag,
    [PlusIcon.name]: PlusIcon,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [WeekdaySelect.name]: WeekdaySelect,
    [TimePicker.name]: TimePicker,
    [DatePicker.name]: DatePicker,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
  },
  props: {
    hideOld: Boolean,
    hotelReserveAlarm: Boolean,
    projectDateRange: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEditMode: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    workingDays: { type: Array, default: () => [] },
  },
  data() {
    const weekDays = [
      { label: "Mo", value: 1 },
      { label: "Di", value: 2 },
      { label: "Mi", value: 3 },
      { label: "Do", value: 4 },
      { label: "Fr", value: 5 },
      { label: "Sa", value: 6 },
      { label: "So", value: 7 },
    ];
    const validateTimes = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Arbeitszeiten auswählen"));
      }
      const isNotComplete = (time) => time.includes("null") || time.includes("HH") || time.includes("mm");
      const times = this.selectedWorkingDay.times;
      if (
        times.some((item) => {
          if (!item.startTime || !item.endTime) {
            return true;
          }
          if (isNotComplete(item.startTime) || isNotComplete(item.endTime)) {
            return true;
          }
        })
      ) {
        console.log("times", times);
        return callback(new Error("Bitte Arbeitszeiten eintragen"));
      }
      return callback();
    };
    return {
      visible: false,
      isEditing: false,
      formDataRules: {
        weekDays: {
          required: true,
          message: "Bitte Arbeitstage auswählen",
          trigger: "change",
        },
        times: {
          required: true,
          validator: validateTimes,
          trigger: "change",
        },
      },
      weekDays: weekDays,
      workingDayRecordIdx: null,
      selectedWorkingDay: {
        dateRange: null,
        freeText: null,
        weekDays: [],
        times: [{ start: null, end: null }],
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    showAddWorkingTime() {
      this.showModal();
      this.isEditing = true;
      this.selectedWorkingDay.weekDays = [1, 2, 3, 4, 5];
    },
    addWorkingTime() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        console.log("addWorkingTime");
        const newRecord = { ...this.selectedWorkingDay };
        this.$props.workingDays.push(newRecord);
        console.log("newRecord", newRecord);
        this.$nextTick(() => {
          this.$emit("save", { submitAfterUpdate: true });
          this.dismissEdit();
        });
      });
    },
    saveWorkingTime() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        const newRecord = { ...this.selectedWorkingDay };
        this.$props.workingDays[this.workingDayRecordIdx] = newRecord;
        this.$nextTick(() => {
          this.$emit("save", { submitAfterUpdate: true });
          this.dismissEdit();
        });
      });
    },
    handleTimeChange(value, idx) {
      const payload = value ? { startTime: value[0], endTime: value[1] } : { startTime: null, endTime: null };
      this.$set(this.selectedWorkingDay.times, idx, payload);
    },
    removeTime(idx) {
      this.selectedWorkingDay.times.splice(idx, 1);
      this.$refs.form.clearValidate("times");
    },
    removeWorkingDay(idx) {
      this.$confirmDelete().then(() => {
        this.$props.workingDays.splice(idx, 1);
        this.$nextTick(() => {
          this.$emit("save", { submitAfterUpdate: true });
        });
      });
    },
    editWorkingDay(record, idx) {
      console.log("editWorkingDay record", record, idx, typeof idx);
      Object.assign(this, {
        visible: true,
        isEditing: true,
        workingDayRecordIdx: idx,
        selectedWorkingDay: {
          dateRange: record.dateRange ? record.dateRange.slice() : null,
          weekDays: record.weekDays.slice(),
          freeText: record.freeText,
          times: record.times.slice(),
        },
      });
    },
    dismissEdit() {
      this.$refs.form.resetFields();
      Object.assign(this, {
        visible: false,
        isEditing: false,
        workingDayRecordIdx: null,
        selectedWorkingDay: {
          dateRange: null,
          freeText: null,
          weekDays: [],
          times: [{ start: null, end: null }],
        },
      });
    },
    addTime() {
      this.selectedWorkingDay.times.push({ start: null, end: null });
    },
    formatWorkingDayString(record) {
      const dateRange = this.$options.filters.formatDateRange(record.dateRange);
      const weekDays = this.$options.filters.formatWeekdays(record.weekDays);
      const times = this.$options.filters.formatTimes(record.times);
      return `${dateRange ? `(${dateRange}) ` : ""}${weekDays}: ${times}`;
    },
    viewWorkingDay(record, idx) {
      this.visible = true;
      this.selectedWorkingDay = { ...record };
    },
  },
  computed: {
    isAddingNew() {
      return this.isEditing && typeof this.workingDayRecordIdx !== "number";
    },
    // provides a list with respect of hideOld switcher
    visibleWorkingDays() {
      if (this.hideOld) {
        const today = moment().startOf("day");
        return this.$props.workingDays.filter((item) => {
          return !item.dateRange || today.isSameOrBefore(item.dateRange[1], "day");
        });
      } else {
        return this.$props.workingDays;
      }
    },
  },
  filters: {
    formatTime(time) {
      if (time && time.startTime && time.endTime) {
        return [time.startTime, time.endTime];
      } else {
        return [];
      }
    },
    formatWeekdays(weekDays) {
      const weekDaysMap = {
        1: "Mo",
        2: "Di",
        3: "Mi",
        4: "Do",
        5: "Fr",
        6: "Sa",
        7: "So",
      };
      return weekDays.map((day) => weekDaysMap[day]).join(", ");
    },
    formatTimes(times) {
      return times.map((time) => `${time.startTime}-${time.endTime}`).join(", ");
    },
    formatDateRange(dateRange) {
      if (!dateRange || dateRange.length !== 2) {
        return "";
      }
      return `${moment(dateRange[0]).format("L")} - ${moment(dateRange[1]).format("L")}`;
    },
  },
};
</script>

<style lang="scss">
.timepicker-wrapper {
  position: relative;
}
.stretch-to-parent {
  .el-checkbox-group {
    display: flex;
    .el-checkbox-button,
    .el-checkbox-button__inner {
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
