<template>
  <div>
    <perfect-scrollbar>
      <!-- supplier -->
      <h6 class="title text-center">{{ $t('src.components.uicomponents.helper.supplier.lieferanten') }}</h6>

      <!-- select supplier and add -->
      <v-select
        :disabled="!isEditMode"
        :default-first-option="true"
        filterable
        v-model="selectedSupplierID"
        valueIdentifier="_id"
        labelIdentifier="name"
        :options="availableSupplierList"
      />
      <button
        v-if="isEditMode"
        style="margin-left:10px;"
        class="btn btn-info btn-fill btn-wd"
        @click.prevent="addSupplier()"
      >
        <span class="el-icon-plus"></span>{{ $t('src.components.uicomponents.helper.supplier.hinzufgen') }}</button>
      <!-- tabs with supplier  -->
      <el-tabs
        :value="currentSupplierID"
        type="card"
        @tab-click="tabClicked"
        :closable="isEditMode"
        @tab-remove="removeSupplier"
      >
        <el-tab-pane
          v-for="supplier in computedSuppliers"
          :key="supplier._id"
          :label="supplier.name"
          :name="supplier._id"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4">
                <div class="card stacked-form card-plain">
                  <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                  </div>
                  <div class="card-body">
                    <form method="#" action="#">
                      <div>
                        <!-- insert in base component the required fields -->
                        <slot :item="supplier"></slot>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-6 offset-md-1">
                <div class="card card-plain">
                  <div class="card-header">
                    <h4 class="card-title">{{ $t('src.components.uicomponents.helper.supplier.lieferant') }}</h4>
                  </div>
                  <div class="card-body">
                    <div class="col-12">
                      <h6 class="title">{{ $t('src.components.uicomponents.helper.supplier.allgemein') }}</h6>
                      <div class="form-group row">
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.name') }}</label>
                        <div class="col-md-3">{{ supplier.name }}</div>
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.webseite') }}</label>
                        <div class="col-md-3">{{ supplier.website }}</div>
                      </div>
                      <hr />
                      <h6 class="title">{{ $t('src.components.uicomponents.helper.supplier.kontaktdaten') }}</h6>
                      <div class="form-group row">
                        <label class="col-md-3">{{ $t('src.components.uicomponents.helper.supplier.ansprechpartner') }}</label>
                        <div class="col-md-3">{{ supplier.contactPerson }}</div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.telefon') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.phone }}</div>
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.handy') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.mobile }}</div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.fax') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.fax }}</div>
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.email') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.email }}</div>
                      </div>
                      <hr />
                      <h6 class="title">{{ $t('src.components.uicomponents.helper.supplier.adresse') }}</h6>
                      <div class="form-group row">
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.strasse') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.street }}</div>
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.hausnummer') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.no }}</div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.plz') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.zip }}</div>
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.stadt') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.city }}</div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-2">{{ $t('src.components.uicomponents.helper.supplier.adresszusatz') }}</label>
                        <div class="col-md-3">{{ supplier.hrSupplier.addressAddition }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </perfect-scrollbar>
    <div class="text-center">
      <button v-if="isEditMode" type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile()">{{ $t('src.components.uicomponents.helper.supplier.speichern') }}</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Message, MessageBox, Tabs, TabPane } from "element-ui";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "supplier",
  props: {
    availableSuppliers: Array,
    assignedSuppliers: Array,
    title: String,
    isEditMode: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PerfectScrollbar,
    Message,
    MessageBox,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane
  },
  data() {
    return {
      currentSupplierID: "", //contains id of current clicked supplier tab
      selectedSupplierID: "", //contains id of supplier selection in Tab
      availableSupplierList: [],
      assignedSupplierList: [],
      lookup: [] //lookup for availableSupplierList
    };
  },
  watch: {
    availableSuppliers: function(newVal, oldVal) {
      this.availableSupplierList = newVal;
      this.getSuppliers();
    },
    assignedSuppliers: function(newVal, oldVal) {
      this.assignedSupplierList = newVal;
      this.getSuppliers();
    }
  },
  computed: {
    computedSuppliers: function() {
      return this.lookup;
    }
  },
  methods: {
    tabClicked(tab) {
      this.currentSupplierID = tab.name;
    },
    getSuppliers: function() {
      this.lookup = [];
      let self = this;
      //if suppliers and assigned suppliers are available
      if (this.availableSupplierList.length > 0 && this.assignedSupplierList) {
        //for all assigned suppliers
        this.assignedSupplierList.forEach(supplier => {
          //lookup supplier data by id
          let supplierLookup = self.availableSupplierList.find(x => x._id == supplier.supplierID);

          if (!supplierLookup) {
            console.log(`supplier - cannot find assigned ID ${supplier.supplierID}`);
          } else {
            //add looked up supplier data to supplier object
            //contact info
            supplier.hrSupplier = supplierLookup.hrSupplier;
            //name
            supplier.name = supplierLookup.name;
            supplier.contactPerson = supplierLookup.contactPerson;
            //website
            supplier.website = supplierLookup.website;

            //add modified supplier
            this.lookup.push(supplier);
          }
        });
      }
      if (!this.currentSupplierID && this.lookup.length > 0) {
        this.currentSupplierID = this.lookup[0]._id;
      }
      return this.lookup;
    },
    addSupplier() {
      //test if supplier selected
      if (!this.selectedSupplierID) {
        Message({
          message: "Bitte Lieferant auswählen",
          type: "info"
        });

        return;
      }

      this.$emit("supplier-attached", this.selectedSupplierID);

      this.currentSupplierID = this.selectedSupplierID;
      this.selectedSupplierID = undefined;
      setTimeout(() => {
        this.updateProfile();
      }, 1000);
    },
    removeSupplier(tabId) {
      this.$emit("supplier-removed", tabId);
      if (this.lookup.length) {
        this.currentSupplierID = this.lookup[0]._id;
      } else {
        this.currentSupplierID = undefined;
      }
      setTimeout(() => {
        this.updateProfile();
      }, 1000);
    },
    updateProfile() {
      this.$emit("updateSupplierCicked");
    }
  }
};
</script>

<style></style>
