<template>
  <el-dialog
    :title="$t('src.components.project.bryntumscheduler.deleteeventmodal.zuordnungAufheben')"
    width="700px"
    :visible="visible"
    center
    :show-close="false"
    :before-close="dismiss"
  >
    <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px">
      <span style="margin-right: 10px; font-size: 25px" :class="resourceIcon" />
      <label style="margin-bottom: 0; font-size: 20px">{{ calendarEvent.name }}</label>
    </div>
    <el-form label-width="120px" :model="formData">
      <div style="padding-left: 26px">
        <el-checkbox v-model="formData.completeRemainingProject">{{
          $t("src.components.project.bryntumscheduler.deleteeventmodal.completeRemainingProject")
        }}</el-checkbox>
      </div>
      <el-form-item
        :label="$t('src.components.project.bryntumscheduler.deleteeventmodal.wochentage')"
        prop="checkedWeekDays"
      >
        <el-checkbox-group v-model="formData.checkedWeekDays" :disabled="formData.completeRemainingProject">
          <el-checkbox :label="1">Mo</el-checkbox>
          <el-checkbox :label="2">Di</el-checkbox>
          <el-checkbox :label="3">Mi</el-checkbox>
          <el-checkbox :label="4">Do</el-checkbox>
          <el-checkbox :label="5">Fr</el-checkbox>
          <el-checkbox :label="6">Sa</el-checkbox>
          <el-checkbox :label="7">So</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <span class="dialog-footer">
        <delete-button
          :text="$t('src.components.project.bryntumscheduler.deleteeventmodal.lschen')"
          @click="handleDeleteEvent"
          :loading="loading"
        ></delete-button>
        <cancel-button
          :text="$t('src.components.project.bryntumscheduler.deleteeventmodal.abbrechen')"
          @click="dismiss"
        ></cancel-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { RESOURCES } from "./utils/RESOURCES";
import { getDateRangesFromArray } from "../../../utils/getDateRangesFromArray";
import { subtractRanges } from "../../../utils/subtractRanges";
import { mapActions, mapState } from "vuex";
import { Checkbox, CheckboxGroup, CheckboxButton, Dialog, FormItem, Form, Switch, Message } from "element-ui";
import { moment } from "src/config/moment";

const defaultCheckedWeekdays = [1, 2, 3, 4, 5, 6, 7];

export default {
  name: "DeleteEventModal",
  components: {
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Switch.name]: Switch,
    [Message.name]: Message,
  },
  props: {
    onDeleteEvents: { type: Function, required: true },
  },
  data: function () {
    return {
      formData: {
        checkedWeekDays: [],
        completeRemainingProject: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("deleteCalendarEvent", ["visible", "calendarEvent", "databaseEvents"]),
    resourceIcon() {
      if (this.calendarEvent.resourceType) {
        switch (this.calendarEvent.resourceType) {
          case RESOURCES.EMPLOYEE:
            return "nc-icon nc-badge";
          case RESOURCES.MACHINE:
            return "nc-icon nc-headphones";

          case RESOURCES.VEHICLE:
            return "nc-icon nc-delivery-fast";

          case RESOURCES.RHB:
            return "nc-icon nc-diamond";

          case RESOURCES.SUPPLY:
            return "nc-icon nc-box-2";
        }
      }
      return "";
    },
  },
  methods: {
    ...mapActions("deleteCalendarEvent", { destroy: "hide" }),
    dismiss() {
      this.formData.checkedWeekDays = [];
      this.formData.completeRemainingProject = false;
      this.destroy();
    },
    setCheckedWeekDays(clickedEvent) {
      const momentDate = moment(clickedEvent.startDate);
      const endOfWeekDate = momentDate.clone().weekday(6);
      // respect end date of event: if event ends before Sunday - preselect event's end date.
      const dbEventId = clickedEvent.dbEventId;
      const clickedEventDbEvent = this.databaseEvents.find((item) => item.id === dbEventId);
      const eventEndDate = moment(clickedEventDbEvent.end);
      const lastSelectionWeekDay = (eventEndDate.isAfter(endOfWeekDate) ? endOfWeekDate : eventEndDate).weekday();

      /** Feature: when deleting event on a day (click on event in lower calendar) - check days between clicked day and Sunday */
      const clickedWeekDay = momentDate.weekday();

      let daysSelection = [];
      daysSelection = defaultCheckedWeekdays.slice(clickedWeekDay, lastSelectionWeekDay + 1);
      this.formData.checkedWeekDays = daysSelection;
    },
    async handleDeleteEvent() {
      try {
        this.loading = true;
        const clickedDayDate = this.calendarEvent.startDate;
        let newEventsArray;
        const eventIdsToDelete = [];
        if (!this.formData.completeRemainingProject) {
          const selectedDates = this.formData.checkedWeekDays.map((weekday) =>
            moment(clickedDayDate).startOf("day").isoWeekday(weekday)
          );
          const selectedDateRanges = getDateRangesFromArray(selectedDates);
          // here we subtract selected date ranges from each database event.
          // Resulting date ranges (remainingDates) will be used to create new events.
          const remainingDates = this.databaseEvents.reduce((buffer, event) => {
            const eventRange = moment.range(moment(event.start).startOf("day"), moment(event.end).endOf("day"));
            const overlappingDateRanges = selectedDateRanges.filter((range) => {
              if (eventRange.overlaps(moment.range(range))) {
                eventIdsToDelete.push(event.id);
                return true;
              }
              return false;
            });
            if (overlappingDateRanges.length) {
              const remainingDates = subtractRanges(overlappingDateRanges, eventRange);
              buffer = buffer.concat(remainingDates);
            }
            return buffer;
          }, []);

          newEventsArray = remainingDates.map((range) => {
            const start =
              range.start.hours() === 0 ? range.start.clone().startOf("day") : range.start.clone().add(1, "seconds");
            const end = range.end.hours() === 0 ? range.end.subtract(1, "seconds") : range.end.clone().endOf("day");
            // NOTE: Considering that events can be deleted only for one project!
            return { ...this.databaseEvents[0], start, end };
          });
        } else {
          const eventToDelete = this.databaseEvents.find((item) => item.id === this.calendarEvent.dbEventId);
          if (eventToDelete) {
            eventIdsToDelete.push(eventToDelete.id);
          }
        }

        await this.$props.onDeleteEvents(eventIdsToDelete, this.formData.completeRemainingProject, newEventsArray);
        this.dismiss();
      } catch (error) {
        Message(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "formData.checkedWeekDays": function (newVal, oldVal) {
      if (newVal.join(".") !== oldVal.join(".")) {
        newVal.sort(); // order of selected days is crucial when convertin days into date ranges
      }
    },
    calendarEvent: function (newVal, oldVal) {
      if (newVal.startDate) {
        if (newVal.resourceType === "employee") {
          this.setCheckedWeekDays(newVal);
        } else {
          this.formData.completeRemainingProject = true;
        }
      }
    },
  },
};
</script>

<style></style>
