import { groupBy } from "lodash";

const state = {
  data: [],
  dataMappedByProjectId: {},
};

const getters = {
  hasProjectNotifications(state) {
    return state.data.some((item) => !!item.projectId);
  },
  mappedByProjectId(state) {
    return state.dataMappedByProjectId;
  },
};

const actions = {
  set({ commit }, payload) {
    commit("SET_NOTIFICATIONS", payload);
  },
  new({ commit }, payload) {
    commit("NEW_NOTIFICATION", payload);
  },
  remove({ commit }, payload) {
    commit("REMOVE_NOTIFICATION", payload);
  },
  close({ commit }, payload) {
    commit("CLOSE_NOTIFICATION", payload);
  },
};

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.data = payload.slice();
    state.dataMappedByProjectId = groupBy(state.data, "projectId");
  },
  NEW_NOTIFICATION(state, payload) {
    state.data.push({ ...payload });
    state.dataMappedByProjectId = groupBy(state.data, "projectId");
  },
  REMOVE_NOTIFICATION(state, payload) {
    state.data = state.data.filter((item) => item.entryId !== payload.entryId);
    state.dataMappedByProjectId = groupBy(state.data, "projectId");
  },
  CLOSE_NOTIFICATION(state, payload) {
    state.data = state.data.filter((entry) => entry._id !== payload._id);
    state.dataMappedByProjectId = groupBy(state.data, "projectId");
  },
};

export const notifications = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
