<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        <div class="credits ml-auto">
          <div class="copyright">{{ $t('src.components.dashboard.views.pages.layout.appfooter.copyCodedWith') }}<i class="fa fa-heart heart"></i> by
            <a href="http://www.buero-buettner.de" target="_blank">{{ $t('src.components.dashboard.views.pages.layout.appfooter.cristianBttner') }}</a>.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer'
  }
</script>
<style>
</style>
