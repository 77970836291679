<template>
  <div>
    <div class="mb-20">
      <logged-hours :isEditMode="computedEditMode" :loggedHours="localLoggedHours" />
    </div>
    <div class="mb-20">
      <service-days
        :isEditMode="computedEditMode"
        :serviceDates="localServiceDates"
        @onCopyClick="showCopyModal('serviceDates')"
      />
    </div>
    <service-hours
      :isEditMode="computedEditMode"
      :serviceHours="localServiceHours"
      @onCopyClick="showCopyModal('serviceHours')"
    />

    <div v-if="computedEditMode" class="col-md-12 text-center">
      <hr style="margin-bottom: 0px" />

      <button
        type="submit"
        class="btn btn-info btn-fill btn-wd"
        @click.prevent="updateMachineService()"
        data-testid="save_machine_service"
      >
        {{ $t("src.components.machine.customerservice.speichern") }}
      </button>
    </div>
    <el-dialog :visible.sync="copyModalVisible" :title="$t('src.components.machine.customerservice.kopieren')">
      <el-table
        :data="machines.filter((data) => !searchQuery || data.name.toLowerCase().includes(searchQuery.toLowerCase()))"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        size="small"
        height="400"
        ref="tableRef"
        class="mb-20"
      >
        <el-table-column type="selection" width="60" />
        <el-table-column :label="$t('src.components.machine.customerservice.name')" prop="name">
          <template v-slot:header>
            <el-input
              v-model="searchQuery"
              size="mini"
              :placeholder="$t('src.components.machine.customerservice.typeToSearch')"
            />
          </template>
        </el-table-column>
      </el-table>

      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dismissCopyModal">{{ $t("src.components.machine.customerservice.abbrechen") }}</el-button>
          <el-button type="primary" @click="confirmCopy" :loading="copyLoading">{{
            $t("src.components.machine.customerservice.kopieren")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Message, Dialog, Table, TableColumn, Button, Input } from "element-ui";

// import { Collapse, CollapseItem, SupplierSettings, Supplier } from "src/components/UIComponents";
import ServiceDays from "./Components/ServiceDays";
import ServiceHours from "./Components/ServiceHours";
import LoggedHours from "./Components/LoggedHours";

export default {
  name: "customer-service",
  components: {
    [ServiceDays.name]: ServiceDays,
    [ServiceHours.name]: ServiceHours,
    [LoggedHours.name]: LoggedHours,
    [Message.name]: Message,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
  },
  data() {
    return {
      machines: [],
      machinesSelection: [],
      daterangeOptions: {
        firstDayOfWeek: 1,
      },
      localServiceDates: [],
      localServiceHours: [],
      localLoggedHours: [],
      selectedMachines: [],
      searchQuery: "",
      copyModalVisible: false,
      copyType: undefined,
      copyLoading: false,
    };
  },
  props: {
    machineId: { type: String },
    machineService: { type: Object, default: () => ({ serviceDates: [], serviceHours: [] }) },
    accessRights: { type: String },
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.fetchMachines();
  },
  methods: {
    async fetchMachines() {
      try {
        const machines = await this.axios
          .get("/api/machines")
          // filter out current machine
          .then((res) => res.data.filter((item) => item.id !== this.machineId))
          .catch((err) => {
            throw err;
          });
        this.machines = machines;
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    updateMachineService() {
      const serviceDates = this.localServiceDates.slice();
      const serviceHours = this.localServiceHours.slice();
      const loggedHours = this.localLoggedHours.slice();
      const updatedMachineService = { ...this.machineService, serviceDates, serviceHours, loggedHours };
      console.log("updatedMachineService", updatedMachineService);
      this.$emit("updateMachineService", updatedMachineService);
    },
    showCopyModal(type) {
      if (type !== "serviceHours" && type !== "serviceDates") {
        console.error(`Unknown type "${type}"`);
        return;
      }
      this.copyType = type;
      this.copyModalVisible = true;
    },
    dismissCopyModal() {
      this.copyModalVisible = false;
      this.copyType = undefined;
      this.machinesSelection = [];
      this.$refs.tableRef.clearSelection();
    },
    async copyServiceDays(machineIds) {
      try {
        const requests = machineIds.map((machineId) => {
          return this.axios.put(`/api/machines/service/${machineId}`, {
            serviceDates: this.machineService.serviceDates,
          });
        });
        await Promise.all(requests);
        this.dismissCopyModal();
        Message.success("Service days copied");
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async copyServiceHours(machineIds) {
      try {
        const requests = machineIds.map((machineId) => {
          return this.axios.put(`/api/machines/service/${machineId}`, {
            serviceHours: this.machineService.serviceHours,
          });
        });
        await Promise.all(requests);
        this.dismissCopyModal();
        Message.success("Service hours copied");
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async confirmCopy() {
      try {
        this.copyLoading = true;
        if (this.copyType === "serviceHours") {
          await this.copyServiceHours(this.machinesSelection);
        } else if (this.copyType === "serviceDates") {
          await this.copyServiceDays(this.machinesSelection);
        }
      } catch (error) {
        throw error;
      } finally {
        this.copyLoading = false;
      }
    },
    handleSelectionChange(val) {
      this.machinesSelection = val.map((item) => item.id);
    },
  },
  watch: {
    // to provide value fetched from back-end
    "machineService.serviceDates": function (newVal = [], oldVal = []) {
      if (newVal && newVal.length !== oldVal.length) {
        this.localServiceDates = newVal;
      }
    },
    "machineService.serviceHours": function (newVal = [], oldVal = []) {
      if (newVal && newVal.length !== oldVal.length) {
        this.localServiceHours = newVal;
      }
    },
    "machineService.loggedHours": function (newVal = [], oldVal = []) {
      if (newVal && newVal.length !== oldVal.length) {
        this.localLoggedHours = newVal;
      }
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style></style>
