import { userService } from "../services";
import { router } from "../routes";
import jwtDecode from "jwt-decode";
import { get } from "lodash";

const user = JSON.parse(sessionStorage.getItem("user"));
const state = user
  ? {
      status: { loggedIn: true },
      accessRights: {},
      user,
      realm: localStorage.getItem("realm") || "",
      permissions: {},
      isKioskMode: false,
      permissionsLoaded: false,
      accessRightsLoaded: false,
    }
  : {
      status: {},
      accessRights: {},
      user: null,
      realm: localStorage.getItem("realm") || "",
      permissions: {},
      permissionsLoaded: false,
      accessRightsLoaded: false,
    };

const actions = {
  setAccessRights({ commit }, accessRights) {
    commit("setAccessRights", accessRights);
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  setRealm(state, realm) {
    state.realm = realm;
  },
  setAccessRights(state, accessRights) {
    state.accessRights = accessRights;
    state.accessRightsLoaded = true;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
    state.realm = user.realm;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  setToken(state, token) {
    if (state.user) {
      // used for auth-image component in BryntumScheduler to fetch images for avatars
      sessionStorage.setItem("token", token);
      state.user.token = token;
    } else {
      state.user = { token };
    }
  },
  setRPT(state, { token, isKioskMode }) {
    // https://www.goodday.work/t/cPrC1U - in kiosk mode only read is allowed, no change - so remove all access that !== read
    const decodedToken = jwtDecode(token);
    // initially permissions is a list of {scopes: string[], rsname: Resource}
    // we convert it to Map to optimize performance
    const permissions = decodedToken.authorization.permissions || [];
    /**
     * Result of this reduce function will look like this:
     * {
     *  'employee': {
     *    create: true,
     *    update: true
     *  },
     *  rhb: {
     *  read: true
     *  }
     * ...
     * }
     */
    const permissionsMap = permissions.reduce((buffer, current) => {
      if (current.scopes) {
        buffer[current.rsname] = current.scopes.reduce((scopesMap, scope) => {
          if (isKioskMode) {
            if (scope === "read") {
              scopesMap[scope] = true;
            }
          } else {
            scopesMap[scope] = true;
          }
          return scopesMap;
        }, {});
      }
      return buffer;
    }, {});
    state.permissionsLoaded = true;
    state.isKioskMode = !!isKioskMode;
    state.permissions = permissionsMap;
  },
};

const getters = {
  authHeader(state) {
    if (state.user && state.user.token) {
      return { Authorization: "Bearer " + state.user.token };
    }
    return undefined;
  },
  authToken(state) {
    if (state.user && state.user.token) {
      return state.user.token;
    }
    return undefined;
  },
  userId(state) {
    return state.user && state.user.sub;
  },
  evaluateAccessRights: (state) => (path) => {
    return get(state.accessRights, path, false);
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
