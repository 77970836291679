<script>
import { Message } from "element-ui";
import { find, get, intersection } from "lodash";
import { moment } from "src/config/moment";
import { CreateEventReq } from "../Project/utils/CreateEventReq";
import { unlink } from "src/utils/unlink";

const DATA_TYPE_MACHINE = "machine";
const DATA_TYPE_RHB = "rhb";
const DATA_TYPE_SUPPLY = "supply";
const DATA_TYPE_VEHICLE = "vehicle";
const DATA_TYPE_EMPLOYEE = "employee";

export default {
  name: "ProjectConfigurationManager",
  components: {
    Message,
  },
  data() {
    return {
      selectedDateRange: null,
      granularitySettings: {},
      selectedProductGroup1: "",
      selectedProductGroup2: "",
      currentProject: {},
      serviceSpectrumList: [],
      databaseData: {
        modeltypes: [],
        status: [],
        projects: [],
        machines: [],
        vehicles: [],
        supplies: [],
        rhbs: [],
        employees: [],
      },
      dataStore: {
        machineModels: [],
        rhbModels: [],
        supplyModels: [],
        employeeModels: [],
        vehicleModels: [],
        computedModels: {},
      },
      createEvents: [], // store to POST events when save project
      deleteEvents: [], // store to delete events when save project
      loading: false,
    };
  },
  methods: {
    setDateRange(newVal) {
      let newRange = null;
      if (newVal) {
        newRange = [moment(newVal[0]).toISOString(), moment(newVal[1]).endOf("day").toISOString()];
      }
      this.selectedDateRange = newRange;
    },
    serviceSpectrumChanged(newVal) {
      this.serviceSpectrumList = newVal;
    },
    computedDataByModelType(modelType) {
      switch (modelType) {
        case DATA_TYPE_MACHINE:
          return this.computeData(this.dataStore.machineModels, DATA_TYPE_MACHINE);

        case DATA_TYPE_RHB:
          return this.computeData(this.dataStore.rhbModels, DATA_TYPE_RHB);

        case DATA_TYPE_SUPPLY:
          return this.computeData(this.dataStore.supplyModels, DATA_TYPE_SUPPLY);
        case DATA_TYPE_VEHICLE:
          return this.computeData(this.dataStore.vehicleModels, DATA_TYPE_VEHICLE);
        case "employee":
          return this.computeData(this.dataStore.employeeModels, "employee");

        default:
          throw "modeltype is unknown!";
      }
    },
    computeData(models, modelType) {
      let computedData = [];
      this.dataStore.computedModels[modelType] = []; //clear selection
      for (let index = 0; index < models.length; index++) {
        const model = models[index];

        let statusState = this.getStatus(model);
        let projectState = this.getProject(model);
        let available = statusState.available && projectState.available;
        let visible = this.computeFiltering(model);
        let selectionState = projectState.isCurrentProject ? "added" : "blank";

        const createEventsMap = new Map(this.createEvents.map((item) => [item.uid, item]));
        const getCreateEventDateRange = (id) => {
          const evt = createEventsMap.get(id);
          if (evt) {
            return [evt.start, evt.end];
          } else {
            return undefined;
          }
        };
        const { projectEvents = [] } = model;
        let currentProjectEvents = [];
        if (projectEvents.length) {
          currentProjectEvents = projectEvents
            .filter((e) => e.projectId === this.currentProject.id)
            .map((e) => ({
              ...e,
              dateRange: getCreateEventDateRange(e._id) || [e.start, e.end],
              comment: e.comment || null,
            }));
        }
        const entity = {
          //model data
          id: model.id,
          name: model.name,
          team: model.team,
          description: model.description,
          picture: model.picture,
          //computed data
          statusEvents: model.statusEvents,
          projectEvents: model.projectEvents,
          statuses: statusState.textList,
          projects: projectState.textList,
          available: available,
          selectionState: selectionState,
          modelType: modelType,
          currentProjectEvents: currentProjectEvents,
          visible: visible,
          unitName: model.unitName,
          selectedServiceSpectrumList: model.selectedServiceSpectrumList || [],
          selectedProductGroup1: model.selectedProductGroup1 || "",
          selectedProductGroup2: model.selectedProductGroup2 || "",
        };
        if (entity.modelType === DATA_TYPE_SUPPLY) {
          entity.quantities = currentProjectEvents.map(({ quantity }) => quantity) || [];
          entity.unitName = model.unitName;
        }
        computedData.push(entity);
      }
      this.$set(this.dataStore.computedModels, modelType, computedData);
      return computedData;
    },
    getFilteredData(modelType) {
      return this.dataStore.computedModels[modelType].filter(this.computeFiltering);
    },
    computeFiltering(model) {
      let visible = true;
      //check if any filter is set
      const isProductGroupRequired = this.selectedProductGroup1 !== "" || this.selectedProductGroup2 !== "";
      const isServiceSpectrumRequired = this.serviceSpectrumList.length > 0;

      if (
        //is productgroup filter active
        isProductGroupRequired &&
        //check productgroup 1 and productgroup 2
        !this.matchesSelectedProductGroup(model.selectedProductGroup1, model.selectedProductGroup2)
      ) {
        visible = false;
      }

      //is service spectrum filter active
      if (isServiceSpectrumRequired) {
        const intersectSpectrum = intersection(model.selectedServiceSpectrumList, this.serviceSpectrumList);
        if (intersectSpectrum.length > 0) {
          visible = true;
        } else {
          // set true if productgroup filter was also true to not override result
          visible = isProductGroupRequired && visible ? true : false;
        }
      }

      return visible;
    },
    getProject(model) {
      let self = this;

      let projectState = {
        available: true,
        textList: [],
        isCurrentProject: false,
      };
      for (const projectEvent of model.projectEvents) {
        const projectID = projectEvent.projectId;

        //if this is not the current project
        if (self.currentProject.id !== projectID) {
          // if overlaps - then the resource is not available for the selected date range
          const overlapsSelectedDateRange = moment
            .range(projectEvent.start, projectEvent.end)
            .snapTo("day")
            .overlaps(moment.range(this.selectedDateRange).snapTo("day"));

          let available =
            this.granularitySettings[model.modelType] === "CONCURRENT" ? true : !overlapsSelectedDateRange;
          //get project
          const project =
            find(self.databaseData.projects, function (searchingProject) {
              return searchingProject.id === projectID;
            }) || {}; // empty object is for fallback in case project was not found
          //get text of status
          const text = `${project.bvName}\n(${moment(projectEvent.start).format("DD.MM.YYYY")} - ${moment(
            projectEvent.end
          ).format("DD.MM.YYYY")})`;
          const eventEnd = moment(projectEvent.end);
          projectState.textList.push({
            redBackground:
              eventEnd.isBefore(this.selectedDateRange[0], "day") &&
              eventEnd.isSameOrAfter(moment(this.selectedDateRange[0]).subtract(4, "days"), "day"),
            text: text,
          });
          projectState.available = available;
        }
        //it is the same project set as selected
        else {
          projectState.isCurrentProject = true;
        }
      }

      return projectState;
    },
    getStatus(model) {
      const statusState = {
        available: true,
        textList: [],
      };
      if (model.statusEvents && model.statusEvents.length) {
        statusState.available = false;
        for (const event of model.statusEvents) {
          const statusModel = this.databaseData.modeltypes.find((item) => item._id === event.statusType) || {};
          statusState.textList.push({
            text: `${statusModel.label} (${moment(event.start).format("DD.MM.YYYY")} - ${moment(event.end).format(
              "DD.MM.YYYY"
            )})`,
          });
        }
      }
      return statusState;
    },
    async initialize(project, granularitySettings) {
      this.granularitySettings = { ...granularitySettings };
      try {
        this.loading = true;
        const { dateRange } = project;
        if (!dateRange) {
          return;
        }
        if (!this.selectedDateRange) {
          this.setDateRange(project.dateRange);
        }
        const start = moment(this.selectedDateRange[0]).subtract(4, "days").toDate();
        const end = moment(this.selectedDateRange[1]).toDate();
        this.currentProject = project;
        await this.loadMetaDataAsync();
        await Promise.all([
          this.loadMachinesAsync(start, end),
          this.loadRHBsAsync(start, end),
          this.loadSuppliesAsync(start, end),
          this.loadVehicles(start, end),
          this.loadEmployeesAsync(start, end),
        ]);
        this.finishInitialization();
        return Promise.resolve();
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    finishInitialization() {
      // //get state list
      // let stateModels = this.createStateList();
      //create required data structure
      this.dataStore.machineModels = this.lookupModels(DATA_TYPE_MACHINE, this.databaseData.machines);
      this.dataStore.rhbModels = this.lookupModels(DATA_TYPE_RHB, this.databaseData.rhbs);
      this.dataStore.supplyModels = this.lookupModels(DATA_TYPE_SUPPLY, this.databaseData.supplies);
      this.dataStore.vehicleModels = this.lookupModels(DATA_TYPE_VEHICLE, this.databaseData.vehicles);
      //create also required data structure with different source data structure
      this.dataStore.employeeModels = this.lookupEmployeeModels(this.databaseData.employees);
      //compute vehicles and employees here because they will never be configured in the UI
      this.computeData(this.dataStore.vehicleModels, DATA_TYPE_VEHICLE);
      this.computeData(this.dataStore.employeeModels, DATA_TYPE_EMPLOYEE);
    },
    createStateList() {
      let stateModels = [];
      let self = this;
      for (let stateIndex = 0; stateIndex < self.databaseData.status.length; stateIndex++) {
        const state = self.databaseData.status[stateIndex];
        const modelState = find(self.databaseData.modeltypes, function (findModel) {
          return state.selectedType === findModel._id;
        });

        if (modelState && modelState.systemRelevant) {
          let entity = {
            id: state.id,
            modelType: state.model,
            dateRange: moment.range(state.dateRange[0], state.dateRange[1]),
            modelID: state.modelID,
            text: modelState.label,
            icon: modelState.icon,
            color: modelState.color,
          };
          stateModels.push(entity);
        }
      }
      return stateModels;
    },
    lookupEmployeeModels(models) {
      let modelList = [];
      for (let index = 0; index < models.length; index++) {
        const model = models[index];

        let entity = {
          id: model._id,
          modelType: DATA_TYPE_EMPLOYEE,
          name: model.firstName + " " + model.lastName,
          description: model.nickname,
          picture: model.picture,
          statusEvents: model.statusEvents,
          projectEvents: model.projectEvents,
          // states: filter(stateModels, function (findState) {
          //     return findState.modelID === model.id;
          // }),
          // projects: model.projectSpecificData,
          selectedProductGroup1: "",
          selectedProductGroup2: "",
          selectedServiceSpectrumList: [],
        };
        modelList.push(entity);
      }
      return modelList;
    },
    lookupModels(modelType, models) {
      let modelList = [];
      for (let index = 0; index < models.length; index++) {
        const model = models[index];

        let entity = {
          id: model._id,
          modelType: modelType,
          name: model.name,
          team: model.team,
          description: model.description,
          picture: model.picture,
          statusEvents: model.statusEvents,
          projectEvents: model.projectEvents,
          selectedProductGroup1: model.selectedProductGroup1,
          selectedProductGroup2: model.selectedProductGroup2,
          selectedServiceSpectrumList: model.selectedServiceSpectrumList,
        };

        //e.g. add the unit property here
        this.addSupplySpecificProperties(entity, model);

        modelList.push(entity);
      }
      return modelList;
    },
    addSupplySpecificProperties(entity, model) {
      if (entity.modelType === DATA_TYPE_SUPPLY) {
        //set default values for pick pack table
        entity.quantity = "";
        entity.unitName = "?";

        //set unit of supplies if set TODO: get unit from first supplier article
        if (model.unitName) {
          entity.unitName = model.unitName;
        }
      }
    },
    setSelectionState({ type, record, newRange, newComment, newQuantity, eventIdx }) {
      console.log("setSelectionState", { type, record, newRange, newComment, newQuantity, eventIdx });
      if (type === "ADD") {
        const uid = new Date().getTime().toString();
        let initialDateRange;
        if (record.modelType === "employee" || record.modelType === "vehicle") {
          if (moment(this.selectedDateRange[0]).isoWeekday() > 5) {
            initialDateRange = [
              this.selectedDateRange[0],
              moment
                .min(
                  moment(this.selectedDateRange[0]).isoWeekday(7).endOf("day"),
                  moment(this.selectedDateRange[1]).endOf("day")
                )
                .toISOString(),
            ];
          } else {
            initialDateRange = [
              this.selectedDateRange[0],
              moment
                .min(
                  moment(this.selectedDateRange[0]).isoWeekday(5).endOf("day"),
                  moment(this.selectedDateRange[1]).endOf("day")
                )
                .toISOString(),
            ];
          }
        } else {
          initialDateRange = unlink(this.selectedDateRange);
        }
        record.currentProjectEvents.push({
          uid: uid,
          resourceType: record.modelType,
          dateRange: initialDateRange,
          resourceId: record.id,
        });
        record.selectionState = "added";
        this.createEvents.push(
          new CreateEventReq({
            title: record.name,
            start: initialDateRange[0],
            end: initialDateRange[1],
            resourceType: record.modelType,
            resourceId: record.id,
            projectId: this.currentProject.id,
            uid: uid,
          })
        );
      } else if (type === "EDIT") {
        // edit dateRange, change event payload
        if (newRange) {
          record.currentProjectEvents[eventIdx].dateRange = newRange;
          record.selectionState = "added";
        } else if (newComment) {
          record.currentProjectEvents[eventIdx].comment = newComment;
          record.selectionState = "added";
        } else if (newQuantity) {
          const quantity = parseInt(newQuantity) || null;
          record.currentProjectEvents[eventIdx].quantity = quantity;
          record.selectionState = "added";
        }
        const currentEvent = record.currentProjectEvents[eventIdx];
        // TODO: handle this.deleteEvents, this.createEvents
        if (currentEvent._id) {
          this.deleteEvents = this.deleteEvents.filter((item) => item.eventId !== currentEvent._id);
        } else {
          this.deleteEvents = this.deleteEvents.filter((item) => item.eventId !== eventId);
        }
        const eventId = currentEvent._id || currentEvent.uid;

        this.createEvents = this.createEvents.filter((item) =>
          item._id ? item._id !== eventId : item.uid !== eventId
        );
        // if event is stored in db - delete it and create new instead
        if (currentEvent._id) {
          this.deleteEvents.push({ eventId: currentEvent._id, resourceId: record.id });
        }
        if (currentEvent.dateRange || newRange) {
          this.createEvents.push(
            new CreateEventReq({
              title: record.name,
              start: newRange ? newRange[0] : currentEvent.dateRange[0],
              end: newRange ? newRange[1] : currentEvent.dateRange[1],
              resourceType: record.modelType,
              resourceId: record.id,
              projectId: this.currentProject.id,
              uid: eventId,
              comment: typeof newComment === "string" ? newComment : currentEvent.comment,
              quantity: newQuantity ? +newQuantity : currentEvent.quantity,
              // quantity: model.quantity,
            })
          );
        }
      } else {
        throw new Error(`Unhandled type: ${type}`);
      }
    },
    removeEvent(model, eventIdx) {
      const computedModelIdx = this.dataStore.computedModels[model.modelType].findIndex((item) => item.id === model.id);
      const currentEvent =
        this.dataStore.computedModels[model.modelType][computedModelIdx].currentProjectEvents[eventIdx];
      const eventId = currentEvent._id || currentEvent.uid;

      this.createEvents = this.createEvents.filter((evt) => evt.uid !== eventId);

      if (currentEvent._id) {
        this.deleteEvents = this.deleteEvents.filter((evt) => evt.eventId !== currentEvent._id);
        // add event id to the list of events to delete
        this.deleteEvents.push({ resourceId: model.id, eventId: eventId });
      }

      // remove event from the list
      this.dataStore.computedModels[model.modelType][computedModelIdx].currentProjectEvents.splice(eventIdx, 1);
      const noMoreEvents =
        this.dataStore.computedModels[model.modelType][computedModelIdx].currentProjectEvents.length === 0;
      if (noMoreEvents) {
        this.dataStore.computedModels[model.modelType][computedModelIdx].selectionState = "blank";
      } else {
        this.dataStore.computedModels[model.modelType][computedModelIdx].selectionState = "added";
      }
    },
    matchesSelectedProductGroup(pg1, pg2) {
      let matches = true;
      if (this.selectedProductGroup1) {
        matches = this.selectedProductGroup1 === pg1;
      }
      if (this.selectedProductGroup2) {
        matches = this.selectedProductGroup2 === pg2;
      }
      return matches;
    },
    async loadMetaDataAsync() {
      let self = this;
      await this.axios
        .all([
          this.axios.get("/api/model-type/project-configuration", {
            params: {
              modelType: "status",
            },
          }),
          this.axios.get("/api/projects?include_workshop=true"),
        ])
        .then(
          this.axios.spread(function (modeltypes, projects) {
            self.databaseData.modeltypes = modeltypes.data;
            // self.projectConfigurationManager.databaseData.status = status.data;
            self.databaseData.projects = projects.data;
          })
        )
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    async loadMachinesAsync(start, end) {
      let self = this;
      await this.axios
        .get("/api/machines/project", { params: { start, end } })
        .then(function (result) {
          self.databaseData.machines = result.data.filter((item) => item.active);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    async loadRHBsAsync(start, end) {
      let self = this;
      await this.axios
        .get("/api/rhb/project", { params: { start, end } })
        .then(function (result) {
          self.databaseData.rhbs = result.data.filter((item) => item.active);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    async loadSuppliesAsync(start, end) {
      try {
        const response = await this.axios.get("/api/supply/project", { params: { start, end } });
        const { data: units } = await this.axios.get("/api/model-type", {
          params: {
            modelType: "global",
            modelId: "unit",
          },
        });
        const actualSupplies = response.data
          .filter((item) => item.active && item.projectRelevant)
          .map((supply) => {
            const supplyUnit = get(supply, "unit[0].accountingUnit");
            let unitName = "";
            if (supplyUnit) {
              const unit = units.find((u) => u._id === supplyUnit) || {};
              unitName = unit.label || "";
            }
            return {
              ...supply,
              unitName,
            };
          });
        this.databaseData.supplies = actualSupplies;
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    async loadVehicles(start, end) {
      console.log("vehicle: called loadVehicles..");

      let self = this;
      await this.axios
        .get("/api/vehicles/project", { params: { start, end } })
        .then(function (result) {
          self.databaseData.vehicles = result.data.filter((item) => item.active);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    async loadEmployeesAsync(start, end) {
      console.log("employees: called loadEmployees..");

      let self = this;
      await this.axios
        .get("/api/employees/project", { params: { start, end } })
        .then(function (result) {
          self.databaseData.employees = result.data.filter((item) => item.active);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    getProjectById(projectId) {
      console.log(
        "project",
        this.databaseData.projects.find((item) => item.id === projectId)
      );
      return this.databaseData.projects.find((item) => item.id === projectId);
    },
  },
};
</script>
