/* eslint-disable no-console */
import { Notification, Loading, MessageBox } from "element-ui";
import { register } from "register-service-worker";
let loadingInstance;
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("%cService worker has been registered.", "background: yellow; color: green");
    },
    cached() {
      console.log("%cContent has been cached for offline use.", "background: yellow; color: green");
      if (loadingInstance) {
        loadingInstance.close();
      }
    },
    updatefound() {
      console.log("New content is downloading.");
      loadingInstance = Loading.service({
        fullscreen: true,
        text: "Ihre SPM Version wird gerade aktualisiert...In ein paar Sekunden geht es weiter.",
        background: "#fff",
      });
    },
    updated() {
      console.log("New content is available; please refresh.");
      if (loadingInstance) {
        loadingInstance.close();
      }
      MessageBox({
        title: "Aktualisierung fertig gestellt.",
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        center: true,
        confirmButtonText: "Klicken Sie bitte hier um zu aktualisieren",
      }).then(() => window.location.reload());
    },
    offline() {
      Notification({
        type: "warning",
        title: "Warnung",
        message: "No internet connection found. App is running in offline mode.",
      });
    },
    error(error) {
      console.error("Error during service worker registration:", error);
      if (loadingInstance) {
        loadingInstance.close();
      }
    },
  });
}
