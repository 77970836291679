<template>
  <el-dialog @close="dismissModal" :visible="visible" :title="dialogTitle" width="500px">
    <div v-if="skippedResources.length">
      {{ $t("These resources were skipped because there are already assigned to project:") }}
      <ul>
        <li v-for="item in skippedResources">
          {{ item }}
        </li>
      </ul>
    </div>
    <div v-else>
      <profile-date-picker
        required
        :isEditMode="true"
        :title="$t('src.components.uicomponents.helper.ressourcestatus.zeitraum')"
        v-model="dateRange"
        is-range
        :clearable="false"
      />
      <profile-select
        title="Resource(n) kopieren nach Projekt"
        required
        rules="required"
        :name="$t('Project')"
        :multiple="false"
        :items="availableProjectOptions"
        v-model="targetProjectId"
        label="text"
        :editMode="true"
      />
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll">Alle</el-checkbox>
      <el-checkbox-group v-model="checkedResources">
        <div v-for="item in allOptions">
          <el-checkbox :label="item.value" :key="item.value">{{ item.label }}</el-checkbox>
        </div>
      </el-checkbox-group>
      <div class="text-right">
        <el-button
          :disabled="!dateRange || !targetProjectId"
          type="primary"
          @click="copyResources"
          :loading="loading"
          >{{ $t("Copy Resources") }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { Dialog, Message, CheckboxGroup, Checkbox } from "element-ui";
import { moment } from "src/config/moment";
import { get } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "copy-resources-dialog",
  components: {
    [Dialog.name]: Dialog,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
  },
  props: { visible: Boolean, project: Object, projectsSelection: Array, defaultStartDate: Date },
  data() {
    return {
      loading: false,
      dateRange: null,
      availableProjectOptions: [],
      checkedResources: [],
      targetProjectId: null,
      skippedResources: [],
      allOptions: [
        { label: "Mitarbeiter", value: "employee" },
        { label: "KFZ", value: "vehicle" },
        { label: "Maschinen", value: "machine" },
        { label: "RHB", value: "rhb" },
        { label: "Verbrauchsm.", value: "supply" },
        { label: "Nachunternehmer", value: "subcontractor" },
      ],
    };
  },
  methods: {
    dismissModal() {
      this.dateRange = [];
      this.checkedResources = [];
      this.availableProjectOptions = [];
      this.targetProjectId = null;
      this.skippedResources = [];
      this.$emit("dismissModal");
    },
    async copyResources() {
      try {
        const response = await this.axios.post("/api/project-events/copy-resources", {
          dateRange: this.dateRange,
          referenceProjectId: this.project.id,
          targetProjectId: this.targetProjectId,
          resourceTypes: this.checkedResources,
        });
        const skippedResources = response.data.skippedResources;
        if (skippedResources.length) {
          this.skippedResources = skippedResources;
        } else {
          this.dismissModal();
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Conflict in granularity
          Message.error(
            "Der Prozess wurde aufgrund von Konflikten in den Zuweisungen abgelehnt. Überprüfen Sie Ihre Granularitätseinstellungen"
          );
        } else {
          const message = get(error, "response.data.message", error.message);
          Message.error(message);
        }
        throw error;
      }
    },
  },
  computed: {
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    isIndeterminate() {
      return this.checkedResources.length && this.checkedResources.length < this.allOptions.length;
    },
    checkAll: {
      get() {
        return this.checkedResources.length === this.allOptions.length;
      },
      set(checked) {
        if (checked) {
          this.checkedResources = this.allOptions.map((item) => item.value);
        } else {
          this.checkedResources = [];
        }
      },
    },
    dialogTitle() {
      return `Resource(n) kopieren von Projekt "${this.project.name}"`;
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const startDate = moment(
          Math.max(moment(this.project.dateRange[0]).toDate().getTime(), this.defaultStartDate.getTime())
        ).format("YYYY-MM-DD");
        const endDate = moment(this.project.dateRange[1]).format("YYYY-MM-DD");
        this.dateRange = [startDate, endDate];
        this.checkedResources = Object.entries(this.granularitySettings).reduce((list, [resourceType, setting]) => {
          if (setting === "CONCURRENT") {
            list.push(resourceType);
          }
          return list;
        }, []);
      }
    },
    dateRange(newVal, oldVal) {
      const startDate = moment(newVal[0]);
      const endDate = moment(newVal[1]);
      this.availableProjectOptions = this.projectsSelection.filter((item) => {
        return (
          item.value !== this.project.id &&
          moment(item.dateRange[0]).isSameOrBefore(startDate, "day") &&
          moment(item.dateRange[1]).isSameOrAfter(endDate, "day")
        );
      });
    },
  },
};
</script>
