<template>
  <div :class="['todo-item', { focused: isFocused }]" @click="handleSelectTodo">
    <div class="todo-editor-header flex-wrap">
      <div class="d-flex flex-grow-1" style="width: 100%">
        <div class="d-flex align-items-center mb-2">
          <todo-status :status="data.status" class="mr-1" />
          <todo-priority :priority="data.priority" class="mr-1" />
          <new-badge :unread="data.unread" ref="badge" />
        </div>
        <div class="ml-auto" v-if="data.isOwner || isPrivilegedUser || data.canMarkAsUnread">
          <el-dropdown @command="handleItemCommand">
            <button class="btn btn-sm btn-ghost card-btn" @click.stop style="font-size: 1rem">
              <dots-vertical-icon />
            </button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="data.canMarkAsUnread && !data.unread" :command="{ action: 'unread' }">
                Als ungelesen markieren
              </el-dropdown-item>
              <!-- DONE -> TODO/IN_PROGRESS -->
              <template v-if="data.isOwner || isPrivilegedUser || data.isResponsible">
                <el-dropdown-item :command="{ action: 'status', payload: 'TODO' }">
                  <todo-progress-dot status="TODO" />
                  {{ $t("To do") }}
                </el-dropdown-item>
                <el-dropdown-item :command="{ action: 'status', payload: 'IN_PROGRESS' }">
                  <todo-progress-dot status="IN_PROGRESS" />
                  {{ $t("In progress") }}
                </el-dropdown-item>
                <el-dropdown-item :command="{ action: 'status', payload: 'DONE' }">
                  <todo-progress-dot status="DONE" />
                  {{ $t("Done") }}
                </el-dropdown-item>
              </template>
              <el-dropdown-item :command="{ action: 'export_pdf' }" v-if="isPrivilegedUser">
                <el-tooltip
                  content="Anzeigbare Dateiformate: png, jpg, jpeg,
                tiff, bmp, gif, msg."
                  placement="left"
                  ><span> <download-icon class="mr-1" />{{ $t("Export as PDF") }} </span>
                </el-tooltip>
              </el-dropdown-item>
              <el-dropdown-item v-if="data.isOwner || isPrivilegedUser" :command="{ action: 'delete' }">
                <trash-can-outline-icon class="mr-1" />{{
                  $t("src.components.project.invoices.invoices.lschen")
                }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <todo-progressbar :data="data" vertical :isWorkshop="isWorkshop" />
    </div>
    <div class="todo-title" :title="data.title">{{ data.title }}</div>
    <div class="todo-text" :title="data.snippet">{{ data.snippet }}</div>
    <div class="todo-attachments" v-if="data.attachments.length || data.content.length">
      <div class="todo-attachment" v-if="data.attachments.length">
        <attachment-icon class="mr-1" /> {{ data.attachments.length }}
      </div>
      <div class="todo-attachment" v-if="data.content.length">
        <file-document-outline-icon class="mr-1" /> {{ data.content.length }}
      </div>
    </div>
  </div>
</template>

<script>
import Attachment from "vue-material-design-icons/Attachment";
import FileDocumentOutline from "vue-material-design-icons/FileDocumentOutline.vue";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import DownloadIcon from "vue-material-design-icons/Download.vue";
import { moment } from "src/config/moment";
import LazyImage from "../../UIComponents/Image/LazyImage.vue";
import { Dropdown, DropdownMenu, DropdownItem, Tooltip } from "element-ui";
import TodoStatus from "./TodoStatus.vue";
import TodoPriority from "./TodoPriority.vue";
import TodoProgressbar from "./TodoProgressbar.vue";
import TodoProgressDot from "./TodoProgressDot.vue";
import NewBadge from "./NewBadge.vue";

export default {
  name: "todo-item",
  components: {
    LazyImage,
    TodoStatus,
    NewBadge,
    TodoPriority,
    TodoProgressbar,
    TodoProgressDot,
    [DownloadIcon.name]: DownloadIcon,
    [FileDocumentOutline.name]: FileDocumentOutline,
    [Attachment.name]: Attachment,
    [DotsVertical.name]: DotsVertical,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tooltip.name]: Tooltip,
  },
  props: {
    data: { type: Object },
    isWorkshop: { type: Boolean },
    isFocused: { type: Boolean },
    isPrivilegedUser: { type: Boolean },
  },
  data() {
    return {};
  },
  methods: {
    handleItemCommand(data) {
      switch (data.action) {
        case "status":
          this.$emit("setStatus", { id: this.data._id, status: data.payload });
          break;
        case "delete":
          this.$emit("delete", this.data._id);
          break;
        case "export_pdf":
          this.$emit("exportPdf", this.data._id);
          break;
        case "unread":
          this.$refs.badge.markAsUnread();
          this.$emit("unread", this.data._id);
          break;
        default:
          break;
      }
    },
    handleSelectTodo() {
      this.$emit("select", this.data);
    },
  },
  filters: {
    formatDate(dateStr) {
      return moment(dateStr).format("lll");
    },
  },
  computed: {
    getAuthorName() {
      let authorName = this.data.authorName;
      if (this.data.isOwner) {
        authorName += ` (Sie)`;
      }
      return authorName;
    },
  },
};
</script>

<style></style>
