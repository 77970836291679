<template>
  <div class="n-edit-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.suppliers.suppliersglobal.einkauf")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.suppliers.suppliersglobal.lieferanten") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title">{{ $t("src.components.suppliers.suppliersglobal.lieferanten") }}</div>
      </el-col>
      <el-col :span="12" style="display: flex; align-items: center; justify-content: flex-end">
        <new-list-item-button v-if="$can('create', 'supplier')" @click="newSupplier">{{
          $t("src.components.suppliers.suppliersglobal.neuerLieferant")
        }}</new-list-item-button>
      </el-col>
    </el-row>
    <div class="card-body" style="background: #fff">
      <el-row type="flex" justify="space-between">
        <!-- SEARCH -->
        <el-col :span="5">
          <el-input
            class="n-search"
            :placeholder="$t('src.components.suppliers.suppliersglobal.sucheNachName')"
            clearable
            v-model="searchQuery"
            suffix-icon="el-icon-search"
          >
          </el-input>
        </el-col>
        <el-col class="text-right" :span="5">
          <!-- AKTIV/INAKTIV FILTER -->
          <span style="padding-right: 40px">
            <!-- DESCRIPTION -->
            <span class="n-profile-label-title" style="padding-right: 5px">{{
              $t("src.components.suppliers.suppliersglobal.filternNach")
            }}</span>
            <!-- DROPDOWN -->
            <el-dropdown trigger="click" @command="changeActive">
              <span class="n-profile-dropdown-value">
                {{ activeLabel }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in filterOptions" :key="item.label" :command="item.value">{{
                  item.label
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-col>
      </el-row>
      <perfect-scrollbar :options="{ suppressScrollX: true }" class="supplier-view">
        <el-table
          :data="filteredSuppliers"
          style="width: 100%"
          :default-sort="{ prop: 'name', order: 'ascending' }"
          v-loading="loading"
          height="calc(100vh - 272px)"
        >
          <el-table-column
            min-width="200"
            sortable
            prop="name"
            :label="$t('src.components.suppliers.suppliersglobal.name')"
          >
            <!-- HEADER -->
            <template v-slot:header>
              <span class="n-table-header">{{ $t("src.components.suppliers.suppliersglobal.lieferantName") }}</span>
            </template>
            <template v-slot="props">
              <div>
                <el-row type="flex" align="middle">
                  <el-col>
                    {{ props.row.name }}
                    <el-badge
                      :type="props.row.active ? 'success' : 'danger'"
                      style="padding-top: 9px; padding-left: 6px"
                      is-dot
                    />
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="100" sortable :sort-method="websiteSort" prop="website">
            <!-- HEADER -->
            <template v-slot:header>
              <span class="n-table-header">{{ $t("src.components.suppliers.suppliersglobal.webseite") }}</span>
            </template>
            <template v-slot="props">
              <a :href="getWebsite(props.row.website)" rel="noindex nofollow" target="_blank">{{
                props.row.website
              }}</a>
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :sort-method="(a, b) => hrDataSort('phone', a, b)"
            :formatter="(r) => getHrDataValue(r, 'phone')"
          >
            <!-- HEADER -->
            <template v-slot:header>
              <span class="n-table-header">{{ $t("src.components.suppliers.suppliersglobal.telefon") }}</span>
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :sort-method="(a, b) => hrDataSort('city', a, b)"
            :formatter="(r) => getHrDataValue(r, 'city')"
          >
            <!-- HEADER -->
            <template v-slot:header>
              <span class="n-table-header">{{ $t("src.components.suppliers.suppliersglobal.stadt") }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="130">
            <template v-slot="props">
              <button class="btn btn-sm btn-ghost" v-if="$can('update', 'supplier')" @click="editSupplier(props.row)">
                <pencil-icon />
              </button>
              <button class="btn btn-sm btn-ghost" v-if="$can('read', 'supplier')" @click="viewSupplier(props.row)">
                <eye-outline-icon />
              </button>
              <button class="btn btn-sm btn-ghost" v-if="$can('delete', 'supplier')" @click="deleteSupplier(props.row)">
                <trash-can-outline-icon />
              </button>
            </template>
          </el-table-column>
        </el-table>
      </perfect-scrollbar>
    </div>
    <supplier-details
      @closed="discardDetails"
      @onSupplierSave="saveSupplier"
      :supplierData="selectedSupplier"
      :deleteSupplier="deleteSupplier"
      :isEditMode="supplierEditMode"
      :isNewSupplier="isNewSupplier"
    />
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Message, Table, TableColumn, Dropdown, DropdownItem, DropdownMenu, Badge } from "element-ui";
import { debounce, get, filter } from "lodash";
import SaveProfileButton from "../UIComponents/Buttons/SaveProfileButton.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import SupplierDetails from "./SupplierDetails.vue";
import NewListItemButton from "../UIComponents/Buttons/NewListItemButton.vue";

export default {
  name: "suppliers-global",
  components: {
    PerfectScrollbar,
    SaveProfileButton,
    Message,
    SupplierDetails,
    NewListItemButton,
    [Badge.name]: Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [EyeOutline.name]: EyeOutline,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
  },
  data() {
    return {
      activeLabels: new Map([
        [true, "Aktiv"],
        [false, "Inaktiv"],
        [null, "Alle"],
      ]),
      activeFilter: true,
      filterOptions: [
        {
          value: true,
          label: "Aktiv",
        },
        {
          value: false,
          label: "Inaktiv",
        },
        {
          value: null,
          label: "Alle",
        },
      ],
      loading: false,
      searchQuery: "",
      suppliers: [],
      filteredSuppliers: [],
      selectedSupplier: null, // data passed to view/edit drawer
      supplierEditMode: false, // to distinguish between view and edit button
      isNewSupplier: false, // to delete supplier if created new one and then close drawer
    };
  },
  computed: {
    activeLabel() {
      return this.activeLabels.get(this.activeFilter);
    },
  },
  mounted() {
    this.fetch();
    this.debounceSetFilteredSuppliers = debounce(this.setFilteredSuppliers, 400);
  },
  methods: {
    changeActive(command) {
      this.activeFilter = command;
      this.setFilteredSuppliers();
    },
    async fetch() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/suppliers");
        this.suppliers = response.data;
        if (this.$route.query.id) {
          const supplierData = response.data.find((item) => item._id === this.$route.query.id);
          if (supplierData) {
            this.viewSupplier(supplierData);
            this.$route.query.id = undefined;
          }
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    newSupplier() {
      this.selectedSupplier = {};
      this.isNewSupplier = true;
      this.supplierEditMode = true;
    },
    viewSupplier(supplierData) {
      this.selectedSupplier = supplierData;
      this.isNewSupplier = false;
      this.supplierEditMode = false;
    },
    editSupplier(supplierData) {
      this.selectedSupplier = supplierData;
      this.isNewSupplier = false;
      this.supplierEditMode = true;
    },
    async deleteSupplier(supplierData) {
      await this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/suppliers/${supplierData._id}`);
          const foundIdx = this.suppliers.findIndex((item) => item._id === supplierData._id);
          if (foundIdx !== -1) {
            this.suppliers.splice(foundIdx, 1);
          }
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    saveSupplier(supplierData) {
      const foundIdx = this.suppliers.findIndex((item) => item._id === supplierData._id);
      if (foundIdx !== -1) {
        this.$set(this.suppliers, foundIdx, supplierData);
      } else {
        this.suppliers.push(supplierData);
      }
    },
    websiteSort(a, b) {
      const aSite = a.website ? a.website.toLowerCase() : undefined;
      const bSite = b.website ? b.website.toLowerCase() : undefined;
      if (aSite > bSite) {
        return 1;
      } else if (aSite < bSite) {
        return -1;
      } else {
        return 0;
      }
    },
    hrDataSort(namespace, a, b) {
      const aData = a.hrSupplier && a.hrSupplier[namespace];
      const bData = b.hrSupplier && b.hrSupplier[namespace];
      if (aData > bData) {
        return 1;
      } else if (aData < bData) {
        return -1;
      } else {
        return 0;
      }
    },
    getHrDataValue(record, namespace) {
      return get(record.hrSupplier, namespace);
    },
    setFilteredSuppliers() {
      let filteredSuppliers = this.suppliers.slice();
      let activeFilterPredicate;
      switch (this.activeFilter) {
        // active
        case true:
          activeFilterPredicate = (item) => item.active;
          break;
        // inactive
        case false:
          activeFilterPredicate = (item) => !item.active;
          break;
        // both
        default:
          activeFilterPredicate = () => true;
          break;
      }
      const predicates = [activeFilterPredicate];
      if (this.searchQuery && this.searchQuery.length) {
        const searchString = this.searchQuery.toLowerCase();
        predicates.push((supplier) => {
          let matches = false;
          if (supplier.name && supplier.name.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          if (
            !matches &&
            supplier.hrSupplier &&
            supplier.hrSupplier.city &&
            supplier.hrSupplier.city.toLowerCase().indexOf(searchString) !== -1
          ) {
            matches = true;
          }
          if (!matches && supplier.website && supplier.website.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          return matches;
        });
      }
      if (!predicates.length) {
        this.filteredSuppliers = filteredSuppliers;
      } else {
        this.filteredSuppliers = filter(filteredSuppliers, (doc) => predicates.every((func) => func(doc)));
      }
    },
    editSupplier(supplierData, isNew) {
      this.selectedSupplier = supplierData;
      this.isNewSupplier = !!isNew;
      this.supplierEditMode = true;
    },
    viewInvoice(supplierData) {
      this.selectedSupplier = supplierData;
      this.supplierEditMode = false;
    },
    discardDetails() {
      this.selectedSupplier = null;
      this.supplierEditMode = false;
      this.isNewSupplier = false;
    },
    getWebsite(website) {
      let websiteUrl = "";
      if (website) {
        if (website.toLowerCase().startsWith("www")) {
          websiteUrl = `//${website}`;
        } else {
          websiteUrl = website;
        }
      }
      return websiteUrl;
    },
  },
  watch: {
    suppliers(newVal) {
      if (newVal) {
        this.setFilteredSuppliers();
      }
    },
    searchQuery(newVal) {
      this.debounceSetFilteredSuppliers();
    },
  },
};
</script>

<style>
.supplier-view.ps {
  height: calc(100vh - 272px);
}
</style>
