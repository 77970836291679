<template>
  <div>
    <span v-if="required" style="" class="n-profile-select-title-red">*</span>
    <span class="n-profile-label-title">{{ label }}:</span>
    <br />
    <el-input-number v-bind="$attrs" @input="updateValue" @change="updateValue" @blur="$emit('blur')" />
  </div>
</template>

<script>
import { InputNumber } from "element-ui";

export default {
  name: "profile-input-number",
  components: {
    [InputNumber.name]: InputNumber,
  },
  props: {
    label: String,
    required: Boolean,
  },
  methods: {
    updateValue(inputValue) {
      this.$emit("input", inputValue);
    },
  },
};
</script>

<style>
</style>