<template>
  <div style="height: 100%" class="d-flex flex-column" v-loading="loading">
    <div class="d-flex justify-content-between pb-3" style="border-bottom: 1px solid #dee3ed">
      <span class="n-profile-title mx-0">{{ $t("ToDos") }}</span>
      <div class="d-flex">
        <el-button class="mr-1" @click="reFetch" :loading="loading">
          <sync-icon />
          {{ $t("Refresh") }}
        </el-button>
        <el-button class="mr-3" @click="makeNewTodo">
          <plus-icon />
          {{ $t("Add new todo") }}
        </el-button>
      </div>
    </div>
    <div class="row" style="border-bottom: 1px solid #dee3ed">
      <div class="col-lg-6 col-xl-4 p0 d-flex py-2">
        <el-input
          :placeholder="$t(searchMode === 'text' ? 'Search in text' : 'Search by author')"
          size="small"
          clearable
          class="mr-2"
          v-model="searchQuery"
        >
          <template v-slot:suffix>
            <span style="font-size: 20px; margin-top: 2px; float: right">
              <magnify-icon />
            </span>
          </template>
          <!-- <i v-slot:suffix class="el-input__icon el-icon-search"></i> -->
        </el-input>
        <span style="margin-right: -25px" class="d-flex align-items-center">
          <search-mode-select v-model="searchMode" />
          <layout-type-select v-model="layoutHandler" />
        </span>
      </div>
      <div class="col-lg-6 col-xl-8 d-flex align-items-center justify-content-end">
        <profile-radio-group
          class="mr-2"
          v-model="activeFilter"
          :items="[
            { label: $t('All'), value: 'all' },
            { label: $t('My Todos'), value: 'mine' },
            { label: $t('Assigned todos'), value: 'assigned' },
          ]"
        />
        <el-button @click="onlyOpen = !onlyOpen" :class="onlyOpen ? 'dark-btn' : undefined">Nur offen</el-button>
        <el-button @click="onlyNew = !onlyNew" :class="onlyNew ? 'dark-btn' : undefined">Neu</el-button>
        <!-- Sorting -->
        <div class="d-flex align-items-center mx-2">
          <span class="n-profile-label-title" style="padding-right: 5px">{{ $t("Sort by") }}:</span>
          <!-- DROPDOWN -->
          <el-dropdown trigger="click" @command="changeMultiSelect">
            <span class="n-profile-dropdown-value">
              {{ $t(currentSortByLabel) }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in sortingOptions" :key="item.value" :command="item.value">
                {{ $t(item.label) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- Table layout start -->
    <el-table
      v-if="layout === 'table'"
      style="width: 100%"
      height="calc(100vh - 272px)"
      :data="visibleTodos"
      :default-sort="{ prop: currentSortBy, order: currentSortOrder }"
      row-key="_id"
      :sort-orders="['ascending', 'descending']"
      @expand-change="handleExpandRow"
      @sort-change="handleSortChange"
    >
      <el-table-column type="expand">
        <template v-slot="props">
          <!-- <div style="height: 600px">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              ref="editorScrollbar"
              style="height: 100%; padding-right: 10px"
            > -->
          <todo-editor
            ref="todoEditor"
            :data="selectedTodo"
            :isPrivilegedUser="isPrivilegedUser"
            :isWorkshop="isWorkshop"
            @cancel="discardWithPrompt"
            @onTodoSubmit="handleTodoSubmit"
            @updateContents="updateContents"
            @removeTodo="handleRemoveCurrentTodo"
            @exportPdf="handleExportPdf(selectedTodo._id)"
            :loading="todoLoading"
            :projectMembers="projectMembers"
            :projectId="projectId"
          />
          <!-- </perfect-scrollbar>
          </div> -->
        </template>
      </el-table-column>

      <el-table-column
        prop="createdAt"
        :label="$t('Created')"
        sortable
        width="160"
        :formatter="dateFormatter"
      ></el-table-column>
      <el-table-column prop="title" :label="$t('Title')"></el-table-column>
      <el-table-column prop="creatorName" :label="$t('Author')" sortable width="180"></el-table-column>
      <el-table-column
        prop="responsibleName"
        sort-by="responsibleName"
        :label="$t('Responsible')"
        sortable
        width="180"
        :filters="getResponsibleFilters"
        :filter-method="filterResponsibleHandler"
      >
        <template v-slot="props">{{ props.row.responsibleName || props.row.workshopName }}</template>
      </el-table-column>
      <el-table-column
        prop="status"
        :label="$t('Status')"
        sortable
        sort-by="status"
        width="120"
        :filters="statusFilters"
        :filter-method="filterHandler"
      >
        <template v-slot="props"><todo-status :status="props.row.status" /></template>
      </el-table-column>
      <el-table-column prop="priority" :label="$t('Prio')" sortable width="160">
        <template v-slot="props"><todo-priority :priority="props.row.priority" /></template>
      </el-table-column>
      <el-table-column
        prop="dueDate"
        :label="$t('Due date')"
        sortable
        :formatter="dayFormatter"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="closedAt"
        :label="$t('Closed at')"
        sortable
        :formatter="dateFormatter"
        width="160"
      ></el-table-column>
    </el-table>
    <!-- Table layout end -->
    <!-- Sidebar layout start -->
    <div v-else class="pr-1 flex-grow-1" style="overflow: hidden">
      <div class="row g-0" style="height: 100%">
        <div class="col-lg-6 col-xl-4 p-0" style="height: 100%; z-index: 5">
          <div class="row" style="height: 100%">
            <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%; width: 100%" ref="scroll">
              <div v-if="visibleTodos.length" style="height: 100%">
                <div v-for="(todo, idx) in visibleTodos" :key="idx" class="pl-3">
                  <todo-item
                    :data="todo"
                    @select="handleSelectTodo"
                    @delete="handleDeleteTodoItem"
                    @setStatus="handleSetTodoStatus"
                    @exportPdf="handleExportPdf"
                    @unread="handleMarkAsUnread"
                    :isFocused="isTodoFocused(todo)"
                    :is-workshop="isWorkshop"
                    :isPrivilegedUser="$can('delete', 'project_todos')"
                  />
                </div>
              </div>
              <div v-else class="empty-data">{{ onlyNew ? "Keine ungelesenen Todos" : "Keine Todos gefunden" }}</div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="col-lg-6 col-xl-8 d-flex flex-column" style="height: 100%; padding-right: 0">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            ref="editorScrollbar"
            style="height: 100%; padding-right: 10px"
          >
            <todo-editor
              ref="todoEditor"
              :data="selectedTodo"
              :isPrivilegedUser="isPrivilegedUser"
              :isWorkshop="isWorkshop"
              @cancel="discardWithPrompt"
              @onTodoSubmit="handleTodoSubmit"
              @updateContents="updateContents"
              @removeTodo="handleRemoveCurrentTodo"
              @exportPdf="handleExportPdf(selectedTodo._id)"
              :loading="todoLoading"
              :projectMembers="projectMembers"
              :projectId="projectId"
            />
          </perfect-scrollbar>
        </div>
      </div>
    </div>
    <!-- Sidebar layout end -->
  </div>
</template>

<script>
import { Message, Dropdown, DropdownMenu, Input, MessageBox, Table, TableColumn } from "element-ui";
import Magnify from "vue-material-design-icons/Magnify.vue";
import SyncIcon from "vue-material-design-icons/Sync.vue";
import TodoItem from "./TodoItem.vue";
import TodoEditor from "./TodoEditor.vue";
import TodoPriority from "./TodoPriority.vue";
import TodoStatus from "./TodoStatus.vue";
import { unlink } from "src/utils/unlink";
import { debounce, first, get } from "lodash";
import SearchModeSelect from "./SearchModeSelect.vue";
import LayoutTypeSelect from "./LayoutTypeSelect.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import printJs from "print-js";
import { mapState } from "vuex";
import { moment } from "src/config/moment";

const TODO_LAYOUT = "todo_layout";

export default {
  name: "todo-list",
  props: {
    projectId: { type: String },
    isWorkshop: { type: Boolean, default: false },
  },
  components: {
    PerfectScrollbar,
    Magnify,
    SyncIcon,
    TodoEditor,
    TodoItem,
    TodoPriority,
    TodoStatus,
    SearchModeSelect,
    LayoutTypeSelect,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [MessageBox.name]: MessageBox,
    [Magnify.name]: Magnify,
    [Input.name]: Input,
    [Message.name]: Message,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      loading: false,
      todoLoading: false,
      activeFilter: "all",
      searchMode: "text",
      searchQuery: "",
      currentSortBy: "createdAt",
      currentSortOrder: "ascending",
      allTodos: [],
      visibleTodos: [],
      projectMembers: [],
      statusFilters: [
        { text: this.$t("To do"), value: "TODO" },
        { text: this.$t("In progress"), value: "IN_PROGRESS" },
        { text: this.$t("Done"), value: "DONE" },
      ],
      onlyOpen: true,
      onlyNew: false,
      selectedTodo: null,
      layout: localStorage.getItem(TODO_LAYOUT) || "list",
    };
  },
  created() {
    this.debounceFetchTodos = debounce(this.fetchTodos, 300);
  },
  async mounted() {
    await this.fetchTodos();
    this.fetchProjectMembers();
    this.handleFocusTodo();
    this.$root.$on("refetchTodos", this.debounceFetchTodos);
  },
  beforeDestroy() {
    this.$root.$off("refetchTodos");
  },
  methods: {
    async fetchTodos() {
      this.visibleTodos = [];
      try {
        if (this.$refs.scroll) {
          this.$refs.scroll.$el.scrollTop = 0;
        }
        this.loading = true;
        const params = { sortBy: this.currentSortBy };
        if (this.layout === "list" && this.currentSortBy === "createdAt") {
          params.sortOrder = "desc";
        }
        if (this.layout === "table") {
          params.sortOrder = this.currentSortOrder === "descending" ? "desc" : "asc";
        }
        if (this.searchQuery && this.searchMode === "text") {
          params.search = this.searchQuery;
        }
        const response = await this.axios.get(`/api/todo-list/project/${this.projectId}`, { params });
        this.$nextTick(() => {
          this.allTodos = response.data;
          this.filterData();
        });
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchProjectMembers() {
      try {
        const today = moment();
        const params = {
          start: today.clone().startOf("day").toISOString(),
          end: today.clone().endOf("day").toISOString(),
        };
        const response = await this.axios.get(`/api/todo-list/responsibles/project/${this.projectId}`, {
          params: params,
        });
        console.log("response.data", response.data);
        this.projectMembers = [
          {
            isGroup: true,
            label: response.data.currentProject.name,
            children: response.data.currentProject.employees.map((child) => ({
              value: child._id,
              label: child.isCurrentConstructionManager ? `${child.name} (Bauleiter)` : child.name,
            })),
          },
          {
            isGroup: true,
            label: this.$t("Workshops"),
            children: response.data.workshopProjects.map((item) => ({
              label: item.name,
              value: item._id,
            })),
          },
        ];
      } catch (error) {
        throw error;
      }
    },
    async handleExportPdf(todoId) {
      const loadingMessage = Message({
        message: `PDF herunterladen`,
        iconClass: "nc-icon nc-refresh-69 spin mr-2",
        duration: 0,
      });
      try {
        const response = await this.axios.request({
          method: "GET",
          url: `/api/todo-list/project/${this.projectId}/pdf/${todoId}`,
          responseType: "blob",
        });
        const blob = response.data;
        let filename;
        const contentDisposition = response.headers["Content-Disposition"];
        if (contentDisposition) {
          filename = contentDisposition.match('filename="([^"]*)"')[1];
        } else {
          filename = `todo.pdf`;
        }
        const file = new File([blob], filename, { type: "application/octet-stream" });
        Message({
          message: "PDF erstellt",
          type: "success",
        });
        const objectUrl = window.URL.createObjectURL(file);
        printJs({
          printable: objectUrl,
          type: "pdf",
          showModal: true,
          modalMessage: "Vorbereitung zum Drucken",
          onError: (err) => {
            throw err;
          },
          onPrintDialogClose: () => {
            URL.revokeObjectURL(objectUrl);
          },
        });
      } catch (error) {
        throw error;
      } finally {
        loadingMessage.close();
      }
    },
    async handleMarkAsUnread(todoId) {
      await this.axios.put(`/api/notifications/unread/${todoId}`);
      const todoIdx = this.allTodos.findIndex((i) => i._id === todoId);
      this.$set(this.allTodos, todoIdx, { ...this.allTodos[todoIdx], unread: true });
      this.filterData();
    },
    handleFocusTodo() {
      const todoId = this.$route.params.todoId;
      if (todoId) {
        const todo = this.allTodos.find((item) => item._id === todoId);
        this.$router.push({ path: this.$route.path, params: { tab_pane: "todos" } });
        if (todo) {
          this.handleSelectTodo(todo);
        }
      } else if (this.visibleTodos.length) {
        this.handleSelectTodo({ ...first(this.visibleTodos) });
      } else {
        this.selectedTodo = null;
      }
    },
    filterData() {
      const allTodos = this.onlyOpen
        ? this.allTodos.filter((item) => item.status === "TODO" || item.status === "IN_PROGRESS")
        : this.allTodos.slice();

      let visibleTodos = [];
      if (this.searchMode === "author" && this.searchQuery) {
        const searchQuery = this.searchQuery.toLowerCase();
        visibleTodos = allTodos.filter(
          (item) =>
            (item.creatorName && item.creatorName.toLowerCase().indexOf(searchQuery) !== -1) ||
            (item.content &&
              item.content.some(
                (child) => child.creatorName && child.creatorName.toLowerCase().indexOf(searchQuery) !== -1
              ))
        );
      } else {
        visibleTodos = allTodos;
      }
      if (this.onlyNew) {
        visibleTodos = visibleTodos.filter((item) => item.unread);
      }
      switch (this.activeFilter) {
        case "mine":
          this.visibleTodos = visibleTodos.filter((item) => item.isOwner);
          break;
        case "assigned":
          this.visibleTodos = visibleTodos.filter((item) => item.isResponsible);
          break;
        case "all":
        default:
          this.visibleTodos = visibleTodos;
          break;
      }
      this.$nextTick(() => {
        // clear selected todo if it is not in the resulting list
        if (
          this.selectedTodo &&
          !this.selectedTodo.isNew &&
          !this.onlyNew &&
          (!this.visibleTodos.length || !this.visibleTodos.some((item) => item._id === this.selectedTodo._id))
        ) {
          this.selectedTodo = null;
        }
      });
    },
    changeMultiSelect(newValue) {
      this.currentSortBy = newValue;
      if (newValue === "closedAt") {
        this.onlyOpen = false;
      }
      this.$nextTick(() => {
        this.fetchTodos();
      });
    },
    async makeNewTodo() {
      if (this.selectedTodo && this.selectedTodo.isOwner && !this.selectedTodo.isNew) {
        const response = await this.$refs.todoEditor.submit(true);
        await this.handleTodoSubmit(response);
      }
      this.$nextTick(() => {
        this.selectedTodo = {
          isNew: true,
          isOwner: true,
          projectId: this.projectId,
          title: "",
          body: "",
          priority: 1,
          attachments: [],
        };
      });
    },
    discardEdit() {
      this.handleFocusTodo();
    },
    discardWithPrompt() {
      let shouldPrompt = false;
      if (this.selectedTodo.isNew) {
        if (
          this.selectedTodo.title.length ||
          this.selectedTodo.body.length ||
          (this.selectedTodo.attachments && this.selectedTodo.attachments.length)
        ) {
          shouldPrompt = true;
        }
      } else {
        const todo = this.allTodos.find((item) => item._id === this.selectedTodo._id);
        if (
          this.selectedTodo.title !== todo.title ||
          this.selectedTodo.body !== todo.body ||
          (this.selectedTodo.attachments &&
            todo.attachments &&
            this.selectedTodo.attachments.length !== todo.attachments.length)
        ) {
          shouldPrompt = true;
        }
      }
      if (shouldPrompt) {
        MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonClass: "el-button--success",
        })
          .then(() => {
            this.$refs.todoEditor.submit();
          })
          .catch(() => {
            this.discardEdit();
          });
      } else {
        this.discardEdit();
      }
    },
    handleRemoveCurrentTodo() {
      this.removeTodo(this.selectedTodo._id);
    },
    handleDeleteTodoItem(todoId) {
      this.removeTodo(todoId);
    },
    async handleSetTodoStatus(payload) {
      const response = await this.axios.put(`/api/todo-list/${payload.id}`, { status: payload.status });
      const todoIdx = this.allTodos.findIndex((item) => item._id === payload.id);
      if (todoIdx !== -1) {
        this.allTodos.splice(todoIdx, 1, response.data);
      }
      if (this.selectedTodo && payload.id === this.selectedTodo._id) {
        this.selectedTodo = unlink(response.data);
        /* if (response.data.workshopProjectId) {
        //   this.selectedTodo.responsibleId = `${response.data.responsibleId}_${response.data.workshopProjectId}`;
        // } */
      }
      this.filterData();
    },
    removeTodo(todoId) {
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/todo-list/${todoId}`);
          this.allTodos = this.allTodos.filter((item) => item._id !== todoId);
          this.filterData();
          if (this.selectedTodo && todoId === this.selectedTodo._id) {
            this.discardEdit();
          }
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    async handleTodoSubmit(newTodo) {
      if (this.selectedTodo.isNew) {
        await this.fetchTodos();
        await this.handleSelectTodo(newTodo);
      } else {
        const idx = this.allTodos.findIndex((item) => item._id === newTodo._id);
        if (idx !== -1) {
          this.$set(this.allTodos, idx, newTodo);
        }
        if (this.selectedTodo._id === newTodo._id) {
          await this.handleSelectTodo(newTodo);
        }
      }
      this.filterData();
      if (this.selectedTodo.isNew) {
        this.handleFocusTodo();
      }
    },
    updateContents(newContent) {
      const todoIdx = this.allTodos.findIndex((item) => item._id === this.selectedTodo._id);
      this.$set(this.allTodos[todoIdx], "content", newContent);

      this.selectedTodo.content = [].concat(newContent);
      this.filterData();
    },
    handleExpandRow(expandedRows, expanded) {
      console.log("expandedRows", expandedRows);
      console.log("expanded", expanded);
    },
    async handleSelectTodo(todo) {
      if (!todo._id) {
        console.warn("No todo._id found! Won't fetch");
        return;
      }
      console.log("handleSelectTodo todo", todo);
      try {
        this.todoLoading = true;
        const allTodosIdx = this.allTodos.findIndex((item) => item._id === todo._id);
        const response = await this.axios.get(`/api/todo-list/project/${this.projectId}/${todo._id}`);
        this.selectedTodo = { ...response.data, projectId: this.projectId };
        // if (response.data.workshopProjectId) {
        //   this.selectedTodo.responsibleId = `${response.data.responsibleId}_${response.data.workshopProjectId}`;
        // }
        if (this.$refs.editorScrollbar) {
          this.$refs.editorScrollbar.ps.element.scrollTop = 0;
        }
        this.allTodos[allTodosIdx] = unlink(response.data);
        this.$nextTick(() => {
          this.filterData();
        });
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.todoLoading = false;
      }
    },
    isTodoFocused(todo) {
      if (this.selectedTodo && this.selectedTodo._id === todo._id) {
        return true;
      } else {
        return false;
      }
    },
    async reFetch() {
      try {
        this.fetchProjectMembers();
        await this.fetchTodos();
        if (this.selectedTodo) {
          await this.handleSelectTodo(this.selectedTodo);
        }
      } catch (error) {
        throw error;
      }
    },
    dateFormatter(row, col, cellValue) {
      if (cellValue) {
        return `${moment(cellValue).format("L HH:mm")}`;
      } else {
        return "";
      }
    },
    dayFormatter(row, col, cellValue) {
      if (cellValue) {
        return `${moment(cellValue).format("L")}`;
      } else {
        return "";
      }
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    filterResponsibleHandler(value, row, column) {
      const properties = [column.responsibleName, column.workshopName];
      return properties.includes(value);
    },
    handleSortChange({ column, prop, order }) {
      this.currentSortBy = prop;
      this.currentSortOrder = order;
      this.$nextTick(() => {
        this.debounceFetchTodos();
      });
    },
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    layoutHandler: {
      get() {
        return this.layout;
      },
      set(val) {
        localStorage.setItem(TODO_LAYOUT, val);
        this.layout = val;
      },
    },
    getResponsibleFilters() {
      const responsibles = this.allTodos.reduce((buffer, todo) => {
        if (todo.responsibleName && !buffer[todo.responsibleName]) {
          buffer[todo.responsibleName] = true;
        }
        if (todo.workshopName && !buffer[todo.workshopName]) {
          buffer[todo.workshopName] = true;
        }
        return buffer;
      }, {});
      const filters = Object.keys(responsibles)
        .sort()
        .map((item) => ({ value: item, text: item }));
      return filters;
    },
    sortingOptions() {
      const sortingOptions = [
        { value: "createdAt", label: "Generation date" },
        { value: "dueDate", label: "Deadline" },
        { value: "withoutDeadline", label: "Without deadline" },
        { value: "isOverdue", label: "Due/overdue" },
        { value: "priority", label: "Priority" },
        { value: "closedAt", label: "Closure date" },
        { value: "creatorName", label: "Author" },
      ];
      if (this.isWorkshop) {
        sortingOptions.push({ value: "workshopFreeText", label: "Assignee" });
      } else {
        sortingOptions.push({ value: "responsibleName", label: "Assignee" });
      }
      return sortingOptions;
    },
    activeTitle() {
      return this.activeTab ? this.$t("Active") : this.$t("Inactive");
    },
    currentSortByLabel() {
      return this.sortingOptions.find((item) => item.value === this.currentSortBy).label;
    },
    isPrivilegedUser() {
      return get(this.accessRights, "project_todos.isPrivileged", false);
    },
  },
  watch: {
    projectId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchTodos();
      }
    },
    onlyOpen(newVal) {
      this.filterData();
    },
    onlyNew(newVal) {
      this.filterData();
    },
    searchMode(newVal) {
      if (this.searchQuery) {
        this.debounceFetchTodos();
      }
    },
    searchQuery(newVal) {
      this.debounceFetchTodos();
    },
    activeFilter() {
      this.filterData();
      this.$nextTick(() => {
        this.handleFocusTodo();
      });
    },
  },
};
</script>

<style></style>
