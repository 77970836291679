<template>
  <div :class="contentClass" :style="inDetachedCalendarMode ? { height: '100vh' } : undefined">
    <transition name="fade" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  computed: {
    inDetachedCalendarMode() {
      return this.$route.query.detachMode === "true";
    },
    contentClass() {
      return this.$route.name === "Welcome" ? "welcome-container" : "content";
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  width: inherit;
  transition: opacity 0.2s ease;
}

.fade-enter-from .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
  overflow: hidden;
  opacity: 0;
}
.content > div {
  width: 100%;
}
</style>
