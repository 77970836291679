<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.projectSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Projektdaten"
        v-model="value.general"
        :withDelete="false"
        :description="$t('userGroupProfile.project.general')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Ansprechpartner"
        v-model="value.contacts"
        :withDelete="false"
        :description="$t('userGroupProfile.project.contacts')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Dokumente"
        v-model="value.documents"
        :description="$t('userGroupProfile.project.documents')"
      />
      <!-- <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Konfiguration"
        v-model="value.configuration"
        :description="$t('userGroupProfile.project.configuration')"
      /> -->
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Rechnungsverwaltung"
        v-model="value.invoices"
        :description="$t('userGroupProfile.project.invoices')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Projekt Controlling"
        v-model="value.projectControlling"
        :description="$t('userGroupProfile.project.projectControlling')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Tagesberichte"
        v-model="value.reports"
        :description="$t('userGroupProfile.project.reports')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Pick & Pack"
        v-model="value.pickAndPack"
        :description="$t('userGroupProfile.project.pickAndPack')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Download center"
        v-model="value.downloadCenter"
        :description="$t('userGroupProfile.project.downloadCenter')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Notiz"
        v-model="value.notes"
        :withEdit="false"
        :withDelete="false"
        :description="$t('userGroupProfile.project.notes')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="ToDos"
        v-model="value.todos"
        :withEdit="false"
        :withDelete="false"
        :description="$t('userGroupProfile.project.todos')"
      />
      <!-- <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Weitere Anmerkungen"
        v-model="value.additionalNotes"
        :description="$t('userGroupProfile.project.additionalNotes')"
      /> -->
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsRow from "./AccessRightsRow.vue";
import AccessRightsTable from "./AccessRightsTable.vue";

export default {
  name: "project-profile-access",
  components: { AccessRightsRow, AccessRightsTable },
  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style></style>
