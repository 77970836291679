<template>
  <div class="resolve-events-wrapper">
    <div class="text-right" v-show="projectRangeResolve.conflictEvents.length">
      <el-link v-if="showAlerts" type="text" @click="toggleShow" style="color: #2c2c2c;" icon="el-icon-arrow-down">{{ $t('src.components.project.bryntumscheduler.eventsresolver.alleWarnungenAusblenden') }}</el-link>
      <el-link v-else type="text" @click="toggleShow" style="color: #2c2c2c;" icon="el-icon-arrow-up">{{ $t('src.components.project.bryntumscheduler.eventsresolver.alleWarnungenAnzeigen') }}</el-link>
    </div>
    <el-collapse-transition>
      <div v-show="showAlerts">
        <el-alert
          class="mb-2"
          v-for="event in projectRangeResolve.conflictEvents"
          :key="event.id"
          :title="event.title"
          type="warning"
          :closable="false"
        >
          <pre>{{ getAlertDescription(event) }}</pre>
        </el-alert>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import { Alert, Link, MessageBox } from "element-ui";
import { moment } from "src/config/moment";
import { mapState } from "vuex";

export default {
  name: "events-resolver",
  props: {
    projectsSelection: { type: Array, required: true }
  },
  components: {
    [Alert.name]: Alert,
    [Link.name]: Link,
    [CollapseTransition.name]: CollapseTransition,
    [MessageBox.name]: MessageBox
  },
  data: function() {
    return {
      showAlerts: true,
      conflictsResolved: false, // should be changed to "true" when conflicts were presend and then resolved.
      conflictEventsLength: 0
    };
  },
  computed: {
    ...mapState(["projectRangeResolve"])
  },
  methods: {
    toggleShow() {
      this.showAlerts = !this.showAlerts;
    },
    getAlertDescription(eventData) {
      const projectOption = this.projectsSelection.find(item => item.value === eventData.projectId) || {};
      return `Projekt: ${projectOption.text}\nDatum: ${moment(eventData.start).format("L")} - ${moment(
        eventData.end
      ).format("L")}`;
    }
  },
  watch: {
    "projectRangeResolve.conflictEvents.length": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.conflictEventsLength = newVal;
      }
      if (newVal === 0 && oldVal > 0) {
        this.conflictsResolved = true;
        MessageBox.confirm("Return back to project editing page?", "All conflicts resolved", {
          showCancelButton: true,
          callback: action => {
            if (action === "confirm") {
              this.$router.push({
                name: "Project Edit",
                params: { id: this.projectRangeResolve.projectInfo.id }
              });
            }
          }
        });
      }
    }
  }
};
</script>

<style>
.resolve-events-wrapper {
  position: absolute;
  width: 255px;
  right: 20px;
  top: 10px;
  z-index: 999;
}
.resolve-events-wrapper pre {
  font-family: inherit;
  margin-bottom: 0;
}
</style>
