<template>
  <div style="height: 100%; overflow: hidden" ref="tableWrapper">
    <el-dialog :visible.sync="dialogVisible" center>
      <div style="text-align: center; height: 70vh; overflow: hidden">
        <img :src="dialogImage" height="100%" />
      </div>
    </el-dialog>
    <el-dialog
      :visible="eventDialogVisible"
      @close="dismissEventCommentDialog"
      :title="$t('src.components.project.coreservicestable.anmerkungen')"
      width="500px"
    >
      <profile-input
        type="textarea"
        :label="$t('src.components.project.coreservicestable.anmerkungen')"
        v-model="eventCommentValue"
        :autosize="{ minRows: 3 }"
        :editMode="eventDialogEditMode"
      />
      <template v-slot:footer>
        <span class="flex justify-between">
          <template v-if="eventDialogEditMode">
            <cancel-button
              :text="$t('src.components.management.companysettings.workshopprojects.abbrechen')"
              @click="dismissEventCommentDialog"
            ></cancel-button>
            <save-button
              :text="$t('src.components.management.companysettings.workshopprojects.speichern')"
              @click="saveCommentForEvent"
            ></save-button>
          </template>
        </span>
      </template>
    </el-dialog>
    <!-- TABLE START -->
    <el-table
      :data="queriedData"
      :height="tableHeight"
      size="small"
      :class="`resource-${modelType}`"
      :row-class-name="getRowClassName"
      ref="table"
      :cell-class-name="getCellClassName"
      :default-sort="{ order: 'ascending', prop: 'name' }"
    >
      <!-- <el-table-column sortable type="selection" width="55"></el-table-column> -->
      <el-table-column label width="60">
        <template v-slot="props">
          <div class="img-container" style="height: 40px">
            <a
              style="display: inline-block; height: 40px; width: 40px; line-height: 40px"
              v-on:mouseover="showPreviewPicture(props)"
              v-on:mouseleave="hidePreviewPicture()"
              v-on:click="showPictures(props)"
            >
              <lazy-image
                :src="getThumbnail(props)"
                lazy
                fit="cover"
                style="width: 40px; height: 40px; border-radius: 2px"
                :scroll-container="getScrollContainer()"
              >
                <template v-slot:placeholder>
                  <div class="image-slot" style="width: 40px; height: 40px">
                    <span style="font-size: 12px"
                      >{{ $t("src.components.project.wizard.configurationtable.lade")
                      }}<span class="dot">{{ $t("src.components.project.wizard.configurationtable.4187") }}</span>
                    </span>
                  </div>
                </template>
                <template v-slot:error>
                  <div class="image-slot" style="width: 40px; height: 40px">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </lazy-image>
            </a>
          </div>
          <!-- <div class="img-container">
                  </div> -->
        </template>
      </el-table-column>

      <el-table-column
        sortable
        width="300"
        prop="name"
        :label="$t('src.components.project.wizard.configurationtable.name')"
      >
      </el-table-column>
      <el-table-column v-if="hasTeamProp" sortable width="200" prop="team" :label="$t('Team')"> </el-table-column>

      <el-table-column
        sortable
        show-overflow-tooltip
        width="280"
        prop="description"
        :label="$t('src.components.project.wizard.configurationtable.beschreibung')"
      >
      </el-table-column>
      <el-table-column
        sortable
        width="*"
        prop="projects"
        :label="$t('src.components.project.wizard.configurationtable.projekte')"
      >
        <template v-slot="props">
          <div style="white-space: pre-line">
            <configuration-table-projects-list :data="props.row.projects" />
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column sortable width="*" prop="statuses" label="Status">
        <template v-slot="props">
          <div style="white-space: pre-line">
            <configuration-table-projects-list :data="props.row.statuses" />
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        v-if="modelType === 'supply'"
        width="100"
        :label="$t('src.components.project.wizard.configurationtable.einheit')"
        prop="unitName"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        v-if="modelType === 'supply'"
        width="190"
        prop="amount"
        :label="$t('src.components.project.wizard.configurationtable.anzahl')"
      >
        <template v-slot="scope">
          <div v-for="(eventRecord, idx) in scope.row.currentProjectEvents" :key="idx">
            <div
              style="height: 40px; display: flex; flex-wrap: wrap; align-items: center"
              v-if="isInCurrentProject(scope.row) && !disabled"
            >
              <el-input
                type="number"
                class="hide-arrows"
                :placeholder="$t('src.components.project.wizard.configurationtable.anzahl')"
                size="mini"
                v-model="eventRecord.quantity"
              >
                <el-button
                  @click="saveSupplyQuantity(scope.row, eventRecord, idx)"
                  size="mini"
                  slot="append"
                  type="success"
                  icon="el-icon-check"
                ></el-button>
                <el-button
                  @click="deleteSupplyQuantity(scope.row, eventRecord, idx)"
                  size="mini"
                  slot="append"
                  type="danger"
                  icon="el-icon-delete"
                ></el-button>
              </el-input>
            </div>
            <div v-else>
              {{ eventRecord.quantity }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        width="320"
        fixed="right"
        class-name="td-actions"
        prop="actions"
        :label="$t('src.components.project.wizard.configurationtable.pickPackListe')"
      >
        <template v-slot="props">
          <div v-if="hasEditAccess">
            <div v-if="isInCurrentProject(props.row)">
              <!-- <p-button type="danger" round @click="removeItem(props.$index, props.row)">
                      <span class="nc-icon nc-simple-delete"></span>{{ $t('src.components.project.wizard.configurationtable.entfernen') }}</p-button> -->
              <div v-if="disabled" style="white-space: pre-line">
                <!-- {{ props.row.dateRange | formatDateRange }} -->
                <div
                  style="display: flex; flex-wrap: nowrap; position: relative; top: -6px"
                  v-for="(record, idx) in props.row.currentProjectEvents"
                  :key="idx"
                >
                  {{ record | formatEventRange }}
                  <el-tooltip :content="record.comment" v-if="record.comment">
                    <div
                      style="align-items: center; padding: 2px 4px; font-size: 18px"
                      @click="showEventCommentDialog(props.row, record, idx, false)"
                    >
                      <tooltip-text-outline />
                    </div>
                  </el-tooltip>
                </div>
              </div>
              <template v-else>
                <div
                  style="display: flex; flex-wrap: nowrap"
                  v-for="(record, idx) in props.row.currentProjectEvents"
                  :key="idx"
                  :class="{ hasComment: !!record.comment }"
                >
                  <pr-date-picker
                    size="mini"
                    is-range
                    style="width: 100%"
                    :min-date="dataManager.currentProject.dateRange && dataManager.currentProject.dateRange[0]"
                    :max-date="dataManager.currentProject.dateRange && dataManager.currentProject.dateRange[1]"
                    :projectDateRange="dataManager.currentProject.dateRange"
                    v-model="record.dateRange"
                    v-on:change="setNewDateRange($event, props.row, idx)"
                    valueFormat=""
                  />
                  <el-tooltip :content="record.comment">
                    <div class="config-btn editComment" @click="showEventCommentDialog(props.row, record, idx, true)">
                      <tooltip-text-outline />
                    </div>
                  </el-tooltip>
                  <div class="config-btn newComment" @click="showEventCommentDialog(props.row, record, idx, true)">
                    <tooltip-plus-outline :key="record._id" />
                  </div>
                  <div class="config-btn" @click="removeItem(props.row, idx)"><trash-can-outline /></div>
                </div>
              </template>
            </div>
            <div v-if="!disabled && showAddButton(props)">
              <el-button
                type="text"
                style="color: #46a19c"
                icon="el-icon-plus"
                @click="addItem(props.$index, props.row)"
                >{{ $t("src.components.project.wizard.configurationtable.hinzufgen") }}</el-button
              >
            </div>
            <span v-if="!props.row.available && !isInCurrentProject(props.row)">nicht verfügbar</span>
          </div>
          <div v-else class="text-center">keine Kalender Berechtigung</div>
        </template>
      </el-table-column>

      <infinite-loading
        slot="append"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        :force-use-infinite-wrapper="`.resource-${modelType} .el-table__body-wrapper`"
        :distance="300"
      >
        <!-- TODO: is this template correct here? -->
        <template v-slot:no-more>
          <span></span>
        </template>
      </infinite-loading>
    </el-table>

    <!-- TABLE END -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce, get } from "lodash";
import { moment } from "src/config/moment";
import { Table, TableColumn, Select, Option, Dialog, DatePicker, Image, Popover, Tooltip } from "element-ui";
import Button from "src/components/UIComponents/Buttons/Button.vue";
import { LazyImage } from "src/components/UIComponents";
import ConfigurationTableProjectsList from "./ConfigurationTableProjectsList.vue";
import InfiniteLoading from "vue-infinite-loading";
import TooltipTextOutline from "vue-material-design-icons/TooltipTextOutline.vue";
import TooltipPlusOutline from "vue-material-design-icons/TooltipPlusOutline.vue";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline.vue";

export default {
  name: "configuration-table",
  components: {
    ConfigurationTableProjectsList: ConfigurationTableProjectsList,
    LazyImage: LazyImage,
    TooltipTextOutline: TooltipTextOutline,
    TooltipPlusOutline: TooltipPlusOutline,
    TrashCanOutline: TrashCanOutline,
    InfiniteLoading: InfiniteLoading,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip,
  },
  props: {
    granularityMode: String,
    modelType: String,
    dataManager: Object,
    dateRange: Array,
    disabled: Boolean,
    selectedProductGroup1: String,
    selectedProductGroup2: String,
    searchQuery: String,
    showOnlyAllocated: Boolean, // wether only show resources that are already added to current project
  },
  data() {
    return {
      imageScrollContainer: `.resource-${this.modelType} .el-table__body-wrapper`,
      computedData: [],
      queriedData: [],
      dialogVisible: false,
      dialogImage: "",
      propsToSearch: ["name", "description", "statuses", "projects", "team"],
      PAGE: 1, // gets incremented by infinite loader to return next slice of list
      OFFSET: 30, // amount of records that are returned within one page
      infiniteId: 0,
      tableHeight: "100%",
      // props for event comment editing
      eventDialogEditMode: false,
      eventDialogVisible: false,
      eventCommentValue: null,
      resourceRecord: null,
      eventIdx: null,
    };
  },
  created() {
    this.debounceFilterData = debounce(this.filterData, 300);
    this.debounceDiscardInfiniteScroll = debounce(this.discardInfiniteScroll, 300);
  },
  mounted() {
    this.computeData();
  },
  beforeDestroy() {
    this.$root.$off("project_configuration_initialized");
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    hasTeamProp() {
      switch (this.modelType) {
        case "employee":
        case "machine":
        case "vehicle":
          return true;

        default:
          return false;
      }
    },
    calendarAccess() {
      return get(this.accessRights, "calendar", { specificAccess: {}, generalAccess: null });
    },
    hasEditAccess() {
      return this.calendarAccess.generalAccess === "full" || this.calendarAccess.generalAccess === "manage";
    },
  },
  methods: {
    getCellClassName({ column }) {
      if (column.property === "amount" || column.property === "actions") {
        return "vertical-align-top";
      }
    },
    // reset and compute data received from back-end
    computeData() {
      this.dataManager.computedDataByModelType(this.modelType);
      this.computedData = this.dataManager.getFilteredData(this.modelType);
      this.$nextTick(() => {
        this.filterData();
      });
    },
    // receive data once again
    reComputeData() {
      this.computedData = this.dataManager.getFilteredData(this.modelType);
      this.$nextTick(() => {
        this.filterData();
      });
    },
    filterData() {
      let result;
      if (this.showOnlyAllocated) {
        const fn = this.isInCurrentProject;
        result = this.computedData.filter(fn);
        // result = this.computedData.slice();
      } else {
        result = this.computedData.slice();
      }
      if (this.searchQuery) {
        const sQuery = this.searchQuery
          .toLowerCase()
          .split(" ")
          .filter((item) => item !== "");
        result = result.filter((row) => {
          return this.propsToSearch.some((prop) => {
            const rowValue = row[prop];
            return rowValue && sQuery.some((q) => rowValue.toString().toLowerCase().indexOf(q) !== -1);
          });
        });
      }
      this.queriedData = result.slice(0, this.PAGE * this.OFFSET);
    },
    setNewDateRange(newRange, rowData, idx) {
      // delete record if "clear data" is pressed
      if (!newRange) {
        return this.removeItem(rowData, idx);
      }
      // rowData[idx].dateRange = newRange;
      this.dataManager.setSelectionState({ record: rowData, eventIdx: idx, newRange, type: "EDIT" });
    },
    isInCurrentProject(record) {
      return record.selectionState === "added" || record.selectionState === "edited";
    },
    showAddButton(props) {
      return (
        props.row.available &&
        (props.row.selectionState === "blank" ||
          this.granularityMode === "CONCURRENT" ||
          props.row.modelType === "supply")
      );
    },
    addItem(index, row) {
      this.dataManager.setSelectionState({ record: row, type: "ADD" });
      // this.dataManager.setSelectionState(row, "added");
    },
    removeItem(row, idx) {
      this.dataManager.removeEvent(row, idx);
    },
    getScrollContainer() {
      return document.querySelector(this.imageScrollContainer);
    },
    getThumbnail(props) {
      if (!props.row.picture) {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      } else {
        return this.axios.defaults.baseURL + props.row.picture.thumbnail;
      }
    },
    showPreviewPicture(props) {
      if (props.row.picture) {
        this.previewPictureSrc = this.axios.defaults.baseURL + props.row.picture.url;
        this.previewPicture = true;
      }
    },
    hidePreviewPicture() {
      this.previewPictureSrc = "";
      this.previewPicture = false;
    },
    showPictures(props) {
      this.hidePreviewPicture();
      if (props.row.picture) {
        this.dialogImage = `${this.axios.defaults.baseURL + props.row.picture.url}?please=true`;
        this.dialogVisible = true;
      }
    },
    saveSupplyQuantity(resourceRecord, record, idx) {
      this.dataManager.setSelectionState({
        record: resourceRecord,
        eventIdx: idx,
        newQuantity: record.quantity,
        type: "EDIT",
      });
    },
    deleteSupplyQuantity(resourceRecord, record, idx) {
      record.quantity = null;
      this.dataManager.setSelectionState({
        record: resourceRecord,
        eventIdx: idx,
        newQuantity: null,
        type: "EDIT",
      });
    },
    infiniteHandler($state) {
      if (!this.computedData.length) {
        $state.complete();
        return;
      }
      this.PAGE += 1;
      $state.loaded();
      if (this.computedData.length && this.PAGE * this.OFFSET >= this.computedData.length) {
        $state.complete();
      }
    },
    discardInfiniteScroll() {
      this.PAGE = 1;
      this.infiniteId++;
    },
    getRowClassName(data) {
      if (this.isInCurrentProject(data.row)) {
        return "allocated-resource";
      }
    },
    showEventCommentDialog(resourceData, eventData, eventIdx, editMode) {
      this.eventDialogEditMode = editMode;
      this.eventCommentValue = eventData.comment;
      this.resourceRecord = resourceData;
      this.eventIdx = eventIdx;
      this.eventDialogVisible = true;
    },
    dismissEventCommentDialog() {
      this.eventDialogEditMode = false;
      this.eventCommentValue = null;
      this.resourceRecord = null;
      this.eventIdx = null;
      this.eventDialogVisible = false;
    },
    saveCommentForEvent() {
      console.log("saveCommentForEvent", this.resourceRecord);
      console.log("this.eventCommentValue", this.eventCommentValue);
      this.dataManager.setSelectionState({
        record: this.resourceRecord,
        eventIdx: this.eventIdx,
        newComment: this.eventCommentValue,
        type: "EDIT",
      });
      this.$nextTick(() => {
        this.dismissEventCommentDialog();
        this.filterData();
      });
    },
  },
  watch: {
    granularityMode(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.computeData();
      }
    },
    showOnlyAllocated() {
      this.debounceFilterData();
      this.debounceDiscardInfiniteScroll();
    },
    searchQuery() {
      this.debounceFilterData();
      this.debounceDiscardInfiniteScroll();
    },
    PAGE(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filterData();
      }
    },
    "dataManager.loading": function (newVal, oldVal) {
      // to re-compute data after dataManager is re-initialized. Usual case - update date range
      if (newVal === false && oldVal === true) {
        this.computeData();
      }
    },
  },
  filters: {
    formatDateRange(dateRange) {
      if (dateRange && dateRange.length === 2) {
        return dateRange.map((date) => moment(date).format("L")).join("-");
      }
    },
    formatDateRanges(events = []) {
      return events.map(({ start, end }) => `${moment(start).format("L")}-${moment(end).format("L")}`).join("\n");
    },
    formatEventRange(event) {
      return `${moment(event.start).format("L")}-${moment(event.end).format("L")}`;
    },
  },
};
</script>

<style lang="scss">
.buffer-check-icon {
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  position: relative;
  margin-left: 5px;
}

.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

.el-table--small th,
.el-table--small td {
  padding: 8px 0 !important;
}

tr.allocated-resource {
  background: #cbdaff !important;
}

// .ps {
//   height: calc(100vh - 70vh);
// }
.red-color {
  background-color: rgb(252, 153, 153);
  padding: 5px;
  border-radius: 3px;
  display: inline-block;
}

.el-table__body td.el-table__cell {
  background-color: #fff;
}

.config-btn {
  align-items: center;
  padding: 2px 4px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
}

.newComment {
  display: flex;
}

.editComment {
  display: none;
}

.hasComment {
  .editComment {
    display: flex;
  }

  .newComment {
    display: none;
  }
}

.el-table .el-table__cell.vertical-align-top {
  vertical-align: top;
}
</style>
