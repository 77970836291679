import moment from 'moment';

/**
 * For example, we have a list of days(dates) [1, 2, 3, 5, 6, 8].
 * We need to obtain date ranges like [[1, 3], [5, 6], [8, 8]] to create proper moment.range
 * @param {Array} days - Array of dates;
 * NOTE: Always return array of date ranges for consistency.
 */
export function getDateRangesFromArray(days) {
  if (!Array.isArray(days)) {
    throw new Error('Please provide valid array');
  }
  if (days.length === 1) {
    return [[moment(days[0]), moment(days[0])]];
  }
  return days.reduce((buffer, current, currentIdx, array) => {
    // always start with adding of first date into range
    if (currentIdx === 0) {
      buffer.push([current]);
      return buffer;
    }
    const previousItemIdx = currentIdx - 1;
    const lastBufferItemIdx = buffer.length - 1;
    // If it's not the last day in range
    if (array[currentIdx + 1]) {
      // If current day is not after previous day (skip "2", in [1, 2, 3] sequence)
      if (!current.clone().subtract(1, 'day').isSame(array[previousItemIdx])) {
        // close current range and start new range
        buffer[lastBufferItemIdx].push(array[previousItemIdx].clone());
        buffer.push([current]);
      }
    } else {
      // if range is not fulfilled - push to last range to fulfill range with 1 item
      if (buffer[lastBufferItemIdx].length !== 2) {
        // if last date in array is the next day of previous date - push it to fulfill range
        if (current.clone().subtract(1, 'day').isSame(array[previousItemIdx])) {
          buffer[lastBufferItemIdx].push(current.clone());
        } else {
          // otherwise fulfill previous range by end of the day of previous date 
          buffer[lastBufferItemIdx].push(array[previousItemIdx].clone());
          // and create 1-day event for last date in array
          buffer.push([current.clone(), current.clone()]);
        }
      } else {
        // otherwise last range is fulfilled, so we create 1-day event for last date in array
        buffer.push([current.clone(), current.clone()]);
      }
    }
    return buffer;
  }, []);
}