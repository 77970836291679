<template>
  <div style="height: 100%">
    <el-table
      style="width: 100%"
      :data="records"
      class="d-widget-table separate-expanded"
      size="small"
      max-height="auto"
    >
      <el-table-column
        :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldstable.name')"
        prop="name"
      >
        <template v-slot="props">
          <span>
            {{ props.row.name }}
            <span style="margin-left: 5px; color: #f88e12">{{ props.row.fields.length }} fehlende felder</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="name" width="50">
        <template v-slot="props">
          <span @click="handleShowDialog(props.row)" class="btn btn-sm btn-ghost">
            <eye-outline-icon />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="getTitle" :visible.sync="dialogVisible" @close="discard" width="1200px">
      <div>
        <el-table
          style="width: 100%; margin-bottom: 10px"
          :data="currentRecord.fields"
          size="small"
          :row-class-name="getRowClassName"
          @selection-change="(s) => handleChangeSelection(s)"
          ref="fieldsTable"
        >
          <el-table-column type="selection" width="58"> </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldstable.feld')"
            prop="label"
            :formatter="(row, col, cellValue) => cellValue || row.key"
          />
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldstable.auszufllen')"
            prop="type"
          >
            <template v-slot="scope">
              <el-select
                style="width: 100%"
                v-model="scope.row.value"
                v-if="scope.row.type === 'select'"
                size="mini"
                :disabled="scope.row.disabled"
              >
                <el-option
                  v-for="option in scope.row.options"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
              <pr-date-picker
                :disabled="scope.row.disabled"
                style="width: 100%"
                v-model="scope.row.value"
                v-else-if="scope.row.type === 'date'"
                size="mini"
              />
              <el-select
                :disabled="scope.row.disabled"
                style="width: 100%"
                v-model="scope.row.value"
                v-else-if="scope.row.type === 'model_type'"
                :multiple="scope.row.valueType === 'list'"
                size="mini"
              >
                <el-option
                  v-for="option in getModelTypeOptions(scope.row.key)"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
              <el-select
                :disabled="scope.row.disabled"
                style="width: 100%"
                v-model="scope.row.value"
                v-else-if="scope.row.type === 'product_group'"
                :multiple="scope.row.valueType === 'list'"
                size="mini"
              >
                <el-option
                  v-for="option in getProductGroupOptions(scope.row.key)"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
              <el-select
                :disabled="scope.row.disabled"
                style="width: 100%"
                v-model="scope.row.value"
                v-else-if="scope.row.type === 'positions'"
                :multiple="scope.row.valueType === 'list'"
                size="mini"
              >
                <el-option
                  v-for="option in positionsOptions"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
              <el-input-number
                :disabled="scope.row.disabled"
                style="width: 100%"
                v-model="scope.row.value"
                v-else-if="scope.row.type === 'input_number'"
                size="mini"
              />
              <el-input
                :disabled="scope.row.disabled"
                style="width: 100%"
                v-model="scope.row.value"
                v-else
                size="mini"
              />
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id">
              <template v-slot="scope">
                <el-button @click="hideField(scope.row.key)" size="mini">{{ $t('src.components.dashboard.views.dashboard.widgets.emptyfieldstable.nichtMehrErinnern') }}</el-button>
              </template>
            </el-table-column> -->
        </el-table>

        <div style="display: flex; justify-content: space-between; padding: 0 10px; margin-bottom: 20px">
          <el-button
            style="margin-left: 10px"
            type="primary"
            v-if="accessRights === 'manage' || accessRights === 'full'"
            @click="handleDocumentsUpload()"
            >{{ $t("src.components.dashboard.views.dashboard.widgets.emptyfieldstable.docUpload") }}
          </el-button>
          <div>
            <el-button
              :disabled="dismissButtonDisabled"
              v-if="(accessRights === 'manage' || accessRights == 'full') && filterVisible"
              style="margin-right: 10px"
              @click="unhideFields()"
              >{{
                $t("src.components.dashboard.views.dashboard.widgets.emptyfieldstable.dismissQuittieren")
              }}</el-button
            >
            <el-button style="margin-right: 10px" @click="hideFields()" :disabled="quittiernButtonDisabled">{{
              $t("src.components.dashboard.views.dashboard.widgets.emptyfieldstable.quittieren")
            }}</el-button>
            <el-checkbox style="margin-right: 5px" v-model="hideForAll" v-if="accessRights === 'full'">{{
              $t("src.components.dashboard.views.dashboard.widgets.emptyfieldstable.aufAlleUser")
            }}</el-checkbox>
          </div>
        </div>
        <div class="text-right" v-if="$can('update', modelType)">
          <el-button type="primary" @click="onSaveFields()">
            {{ $t("src.components.dashboard.views.dashboard.widgets.emptyfieldstable.speichern") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      width="1200px"
      :visible.sync="documentsVisible"
      @opened="initDocuments"
      top="10px"
      class="full-height-modal"
    >
      <document-list
        ref="documentsList"
        :isEditMode="true"
        :accessRights="resourceDocumentTabAccessRights"
        :modelType="modelType"
        :modelID="this.currentRecord.id"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  Dialog,
  Table,
  TableColumn,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Button,
  Message,
  Checkbox,
} from "element-ui";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import { Documents } from "src/components/UIComponents";
import { mapState } from "vuex";
import { get } from "lodash";

export default {
  name: "empty-fields-table",
  components: {
    [Documents.name]: Documents,
    [EyeOutline.name]: EyeOutline,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Select.name]: Select,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Message.name]: Message,
    [DatePicker.name]: DatePicker,
  },
  props: {
    records: { type: Array },
    filterVisible: { type: Boolean },
    recordsUpdated: { type: Boolean },
    modelType: { type: String },
    accessRights: { type: String },
  },
  data() {
    return {
      // because some selection options are kept in modeltype_collection - we should fetch selection options separately.
      // localRecords is used to aggregate fetched selection options
      localRecords: [],
      // modelTypeOptions is used to keep available selection options per resource type
      modelTypeOptions: {},
      productGroupOptions: {},
      positionsOptions: [],
      hideFieldsSelection: {},
      hideForAll: false,
      currentRecord: { fields: [] },
      dialogVisible: false,
      documentsVisible: false,
      dismissButtonDisabled: false,
      quittiernButtonDisabled: false,
    };
  },
  methods: {
    getRowClassName({ row }) {
      if (row.isHidden) {
        return "inactive-row";
      }
    },
    hideFields() {
      const id = this.currentRecord.id;
      if (this.hideFieldsSelection[id] && this.hideFieldsSelection[id].length) {
        this.$emit("onMarkHiddenFields", {
          hideForAll: this.hideForAll,
          resourceId: id,
          fieldNames: this.hideFieldsSelection[id].map((i) => i.key),
        });
        // let newFields;
        // const newFields = this.hideFieldsSelection[id].reduce((fields, record) => {
        //   fields = fields.filter((item) => item.key !== record.key);
        //   return fields;
        // }, this.currentRecord.fields.slice());
        // this.currentRecord.fields = newFields;
        this.$refs.fieldsTable.clearSelection();
        this.hideFieldsSelection[id] = undefined;
      } else {
        Message.warning("Bitte wählen Sie die auszublendenden Felder aus");
      }
    },
    unhideFields() {
      const id = this.currentRecord.id;
      const ids = this.hideFieldsSelection[id].map((i) => i.hiddenFieldRecordId);
      this.$emit("onDismissHiddenFields", {
        ids: ids,
      });
      this.currentRecord.fields = this.currentRecord.fields.map((item) => {
        if (ids.includes(item.hiddenFieldRecordId)) {
          item.hiddenFieldRecordId = undefined;
          item.isHidden = undefined;
        }
        return item;
      });
      this.$refs.fieldsTable.clearSelection();
      this.hideFieldsSelection[id] = undefined;
    },
    onSaveFields() {
      this.$emit("saveChanges", { ...this.currentRecord });
    },
    getModelTypeOptions(objKey) {
      if (this.modelTypeOptions[objKey]) {
        return this.modelTypeOptions[objKey];
      } else {
        return [];
      }
    },
    getProductGroupOptions(objKey) {
      if (this.productGroupOptions[objKey]) {
        return this.productGroupOptions[objKey];
      } else {
        return [];
      }
    },
    async handleModelTypeRequests() {
      const modelTypeUrlsMap = this.records.reduce((map, { fields }) => {
        // seek for model_tpe field types to make requests
        const modelTypeFields = fields.filter((field) => field.type === "model_type");
        modelTypeFields.forEach((field) => {
          if (!map[field.key]) {
            // gather all model_type types by resource property name and generate urls
            map[field.key] = `/api/model-type?modelType=${field.modelType}&modelId=${field.modelId}`;
          }
        });
        return map;
      }, {});
      const requests = Object.values(modelTypeUrlsMap).map((url) => this.axios.get(url));
      const keys = Object.keys(modelTypeUrlsMap);
      const results = await Promise.all(requests);
      // map each modelType request into select-option for particular record propname
      results.forEach((result, idx) => {
        this.modelTypeOptions[keys[idx]] = result.data.reduce((arr, item) => {
          if (item.active) {
            arr.push({ id: item._id, name: item.label });
          }
          return arr;
        }, []);
      });
      this.$emit("onHandleUpdates");
    },
    async handleFetchProductGroups() {
      const productGroupUrlsMap = this.records.reduce((map, { fields }) => {
        // seek for model_tpe field types to make requests
        const pgTypeFields = fields.filter((field) => field.type === "product_group");
        pgTypeFields.forEach((field) => {
          if (!map[field.key]) {
            // gather all model_type types by resource property name and generate urls
            map[field.key] = `/api/product-groups?modelType=${field.modelType}`;
          }
        });
        return map;
      }, {});
      const requests = Object.values(productGroupUrlsMap).map((url) => this.axios.get(url));
      const keys = Object.keys(productGroupUrlsMap);
      const results = await Promise.all(requests);
      // map each modelType request into select-option for particular record propname
      results.forEach((result, idx) => {
        this.productGroupOptions[keys[idx]] = result.data.map((item) => ({ id: item._id, name: item.label }));
      });
      this.$emit("onHandleUpdates");
    },
    async handleFetchPositions() {
      if (this.modelType === "employee") {
        const response = await this.axios.get("/api/employees/positions");
        this.positionsOptions = response.data
          ?.filter((i) => i.active)
          .map((item) => ({ id: item._id, name: item.name }));
      }
    },
    handleChangeSelection(selection) {
      const id = this.currentRecord.id;
      this.hideFieldsSelection[id] = selection.map((item) => ({
        isHidden: item.isHidden,
        hiddenFieldRecordId: item.hiddenFieldRecordId,
        key: item.key,
      }));
      if (selection.every((item) => item.isHidden)) {
        this.dismissButtonDisabled = false;
      } else {
        this.dismissButtonDisabled = true;
      }
      if (selection.every((item) => !item.isHidden)) {
        this.quittiernButtonDisabled = false;
      } else {
        this.quittiernButtonDisabled = true;
      }
    },
    handleDocumentsUpload() {
      this.documentsVisible = true;
      // this.$refs.documentsList.fetchDocumentTypes();
      // this.$emit("onDocumentUploadClick", this.currentRecord.id);
    },
    initDocuments() {
      this.$refs.documentsList.fetchDocumentTypes();
      this.$refs.documentsList.loadDataFromServer();
    },
    discard() {
      this.currentRecord = { fields: [] };
    },
    closeModal() {
      this.dialogVisible = false;
      this.discard();
    },
    handleShowDialog(rowData) {
      this.currentRecord = { ...rowData };
      this.dialogVisible = true;
    },
  },
  watch: {
    recordsUpdated(newVal, oldVal) {
      if (newVal) {
        this.handleModelTypeRequests();
        this.handleFetchProductGroups();
        this.handleFetchPositions();
        // to update currentRecord only when it's shown
        if (this.currentRecord.id) {
          const updatedRecord = this.records.find((item) => item.id === this.currentRecord.id);
          this.handleShowDialog(updatedRecord);
        }
      }
    },
  },
  computed: {
    ...mapState("account", { appAccessRights: "accessRights" }),
    resourceDocumentTabAccessRights() {
      const rights = get(this.appAccessRights, [this.modelType, "specificAccess", "documents"], null);
      return rights;
    },
    getTitle() {
      if (this.currentRecord.name) {
        return this.currentRecord.name;
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
.separate-expanded .el-table__expanded-cell[class*="cell"] {
  border-bottom: 2px solid #7a7a7a;
}
.full-height-modal .el-dialog__body {
  height: calc(100vh - 50px);
}
</style>
