<template>
  <div>
    <el-dialog :visible="visible" @close="dismiss" width="400px">
      <el-form :model="newRecord" ref="form" :rules="formRules">
        <el-form-item prop="coreServiceId">
          <profile-select
            required
            :editMode="isEditMode && !isViewMode"
            :title="$t('src.components.project.coreservicestable.kernleistung')"
            :items="coreServicesOptions"
            v-model="newRecord.coreServiceId"
            valueIdentifier="_id"
            :label="$t('src.components.project.coreservicestable.label')"
            :multiple="false"
          />
        </el-form-item>
        <el-form-item prop="comment">
          <profile-input
            type="textarea"
            :label="$t('src.components.project.coreservicestable.anmerkungen')"
            v-model="newRecord.comment"
            :autosize="{ minRows: 3 }"
            :editMode="isEditMode && !isViewMode"
          />
        </el-form-item>
        <el-row v-if="isEditMode">
          <div class="n-drawer-footer">
            <el-button @click="dismiss" class="mr-2">{{ isViewMode ? "Schließen" : "Abbrechen" }}</el-button>
            <el-button type="primary" @click="addRecord" v-if="isEditMode && !isViewMode">{{
              $t("src.components.project.coreservicestable.hinzufgen")
            }}</el-button>
          </div>
        </el-row>
      </el-form>
    </el-dialog>
    <span class="n-profile-label-title">{{ $t("src.components.project.coreservicestable.kernleistungen") }}</span>
    <br />
    <editable-tag
      v-for="(coreService, idx) in coreServices"
      :key="coreService._id"
      @view="viewRecord(coreService, idx)"
      @edit="editRecord(coreService, idx)"
      @remove="removeRecord(idx)"
      :title="getCoreServiceName(coreService.coreServiceId)"
      :record="coreService"
      :isEditMode="isEditMode"
    />
    <!-- <div v-for="(coreService, idx) in coreServices" :key="coreService._id" class="editable-tag-item">
      {{ getCoreServiceName(coreService.coreServiceId) }}
      <template v-if="isEditMode">
        <el-dropdown trigger="click" @command="handleCommand(coreService, idx, $event)">
          <div class="editable-tag-trigger">
            <dots-vertical-icon />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="view"><eye-outline-icon class="mr-2" />{{ $t('src.components.project.coreservicestable.ansehen') }}</el-dropdown-item>
            <el-dropdown-item command="edit"><pencil-icon class="mr-2" />{{ $t('src.components.project.coreservicestable.bearbeiten') }}</el-dropdown-item>
            <el-dropdown-item command="remove"><trash-can-outline-icon class="mr-2" />{{ $t('src.components.project.coreservicestable.lschen') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template v-else>
        <div class="editable-tag-trigger" @click="viewRecord(coreService, idx)">
          <chevron-right-icon />
        </div>
      </template>
    </div> -->
    <cancel-button v-if="isEditMode" @click="showModal">
      <plus-icon class="mr-2" />{{
        $t("src.components.project.coreservicestable.hinzufgenKernleistungen")
      }}</cancel-button
    >
  </div>
</template>

<script>
import { Table, TableColumn, Dialog, Form, FormItem, Dropdown, DropdownItem } from "element-ui";
import PlusIcon from "vue-material-design-icons/Plus";
import Pencil from "vue-material-design-icons/Pencil";
import ChevronRight from "vue-material-design-icons/ChevronRight";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import FileDocumentOutline from "vue-material-design-icons/FileDocumentOutline";
import { EditableTag } from "src/components/UIComponents";

export default {
  name: "core-services-table",
  props: {
    isEditMode: Boolean,
    coreServices: { type: Array, default: () => [] },
    coreServicesOptions: { type: Array, default: () => [] },
  },
  components: {
    [EditableTag.name]: EditableTag,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [ChevronRight.name]: ChevronRight,
    [PlusIcon.name]: PlusIcon,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
    [EyeOutline.name]: EyeOutline,
    [DotsVertical.name]: DotsVertical,
    [FileDocumentOutline.name]: FileDocumentOutline,
  },
  data() {
    return {
      visible: false,
      isViewMode: false,
      isEditingRecordIdx: null,
      formRules: {
        coreServiceId: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      newRecord: {
        coreServiceId: null,
        comment: null,
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    dismiss() {
      this.$refs.form.resetFields();
      this.visible = false;
      this.isViewMode = false;
      this.isEditingRecordIdx = null;
      this.newRecord = {
        coreServiceId: null,
        comment: null,
      };
    },
    addRecord(e) {
      e.preventDefault();
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (typeof this.isEditingRecordIdx === "number") {
          this.coreServices.splice(this.isEditingRecordIdx, 1, { ...this.newRecord });
        } else {
          this.coreServices.push({ ...this.newRecord });
        }
        this.dismiss();
      });
    },
    editRecord(record, index) {
      this.visible = true;
      this.isEditingRecordIdx = index;
      this.newRecord = {
        coreServiceId: record.coreServiceId,
        comment: record.comment,
      };
    },
    viewRecord(record, index) {
      console.log("viewRecord", record, index);
      this.visible = true;
      this.isEditingRecordIdx = index;
      this.isViewMode = true;
      this.newRecord = {
        coreServiceId: record.coreServiceId,
        comment: record.comment,
      };
    },
    removeRecord(idx) {
      this.$confirmDelete().then(() => {
        this.coreServices.splice(idx, 1);
      });
    },
    getCoreServiceName(coreServiceId) {
      const coreService = this.coreServicesOptions.find((item) => item._id === coreServiceId) || {};
      return coreService.label || "N/A";
    },
    handleCommand(coreService, idx, command) {
      switch (command) {
        case "edit":
          this.editRecord(coreService, idx);
          break;
        case "view":
          this.viewRecord(coreService, idx);
          break;
        case "remove":
          this.removeRecord(idx);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss"></style>
