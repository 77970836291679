<template>
  <div class="pdf-viewer-page" ref="page">
    <canvas ref="pdfCanvas" />
  </div>
</template>

<script>
export default {
  name: "page",
  // https://www.npmjs.com/package/vue-virtual-scroll-list?activeTab=readme#simple-usage
  props: {
    source: Object,
    index: Number,
    pdfDocument: Object,
    scale: Number,
  },
  mounted() {
    this.renderPage(this.source.page);
  },
  methods: {
    async renderPage(pageNum) {
      let scale = this.scale;
      this.isPageRendering = true;
      const page = await this.pdfDocument.getPage(pageNum);

      const viewport = page.getViewport({ scale: scale });
      const canvas = this.$refs.pdfCanvas;
      if (canvas) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;
      }
      const ctx = canvas.getContext("2d");
      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      const renderTask = page.render(renderContext);

      // Wait for rendering to finish
      await renderTask.promise;
    },
  },
  watch: {
    scale(newScale, oldScale) {
      if (oldScale && newScale !== oldScale) {
        this.renderPage(this.source.page);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-viewer-page {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  canvas {
    margin: 0 auto;
  }
}
</style>
