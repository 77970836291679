<template>
  <div style="align-self: flex-start">
    <el-dropdown @command="handleItemCommand">
      <button class="btn btn-sm btn-ghost card-btn" @click.stop style="font-size: 1rem">
        <dots-vertical-icon />
      </button>
      <el-dropdown-menu slot="dropdown">
        <template v-if="data.isOwner || isPrivilegedUser || data.isResponsible || isWorkshop">
          <el-dropdown-item :command="{ action: 'status', payload: 'TODO' }">
            <todo-progress-dot status="TODO" />
            {{ $t("To do") }}
          </el-dropdown-item>
          <!-- TODO -> IN_PROGRESS -->
          <el-dropdown-item :command="{ action: 'status', payload: 'IN_PROGRESS' }">
            <todo-progress-dot status="IN_PROGRESS" />
            {{ $t("In progress") }}
          </el-dropdown-item>
          <!-- IN_PROGRESS -> DONE -->
          <el-dropdown-item :command="{ action: 'status', payload: 'DONE' }">
            <todo-progress-dot status="DONE" />
            {{ $t("Done") }}
          </el-dropdown-item>
        </template>
        <el-dropdown-item :command="{ action: 'export_pdf' }" v-if="isPrivilegedUser || isWorkshop">
          <el-tooltip
            content="Anzeigbare Dateiformate: png, jpg, jpeg,
                tiff, bmp, gif, msg."
            placement="left"
            ><span> <download-icon class="mr-1" />{{ $t("Export as PDF") }} </span>
          </el-tooltip></el-dropdown-item
        >
        <el-dropdown-item v-if="data.isOwner || isPrivilegedUser" :command="{ action: 'delete' }">
          <trash-can-outline-icon class="mr-1" />{{
            $t("src.components.project.invoices.invoices.lschen")
          }}</el-dropdown-item
        >
        <el-dropdown-item v-if="data.isOwner || isPrivilegedUser || isWorkshop" :command="{ action: 'edit' }">
          <pencil-icon class="mr-1" />{{ $t("src.components.project.invoices.invoices.bearbeiten") }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog :title="$t('Edit todo')" :visible.sync="isEditVisible" @close="cancelEditModal">
      <ValidationObserver ref="observer" slim>
        <div class="row mb-2">
          <div class="col-lg-6 col-xl-4">
            <profile-select
              :title="$t('Priority')"
              :name="$t('Priority')"
              rules="required"
              :editMode="true"
              :clearable="false"
              :multiple="false"
              v-model="editingRecord.priority"
              :items="priorityOptions"
              required
              size="small"
            />
          </div>
          <div class="col-lg-6 col-xl-4">
            <profile-input
              v-if="isWorkshop"
              :editMode="true"
              :label="$t('Assignee')"
              :placeholder="$t('Assignee')"
              size="small"
              v-model="editingRecord.workshopFreeText"
            ></profile-input>
            <!-- type="textarea" -->
            <!-- :autosize="{ minRows: 1, maxRows: 4 }" -->
            <profile-select
              v-else
              :title="$t('Responsible')"
              :name="$t('Responsible')"
              rules="required"
              :editMode="editingRecord.isOwner"
              :clearable="false"
              :multiple="false"
              v-model="editingRecord.responsibleId"
              label="name"
              isGrouped
              filterable
              valueIdentifier="_id"
              :items="projectMembers"
              required
              size="small"
            />
          </div>
          <div class="col-lg-6 col-xl-4">
            <profile-date-picker
              v-model="editingRecord.dueDate"
              :title="$t('Deadline')"
              :isEditMode="
                editingRecord.isOwner || editingRecord.isResponsible || (editingRecord.workshopProjectId && isWorkshop)
              "
              :name="$t('Deadline')"
              size="small"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <el-button @click="cancelEditModal">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="submitChanges">{{ $t("Save") }}</el-button>
        </div>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { Dropdown, DropdownItem, DropdownMenu, Badge, Dialog, Tooltip } from "element-ui";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import TodoProgressDot from "./TodoProgressDot.vue";
import DownloadIcon from "vue-material-design-icons/Download.vue";

export default {
  name: "todo-editor-dropdown",
  components: {
    ValidationObserver,
    TodoProgressDot,
    [DownloadIcon.name]: DownloadIcon,
    [DotsVertical.name]: DotsVertical,
    [Dialog.name]: Dialog,
    [Badge.name]: Badge,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tooltip.name]: Tooltip,
  },
  props: {
    data: Object,
    isWorkshop: Boolean,
    isPrivilegedUser: Boolean,
    priorityOptions: Array,
    projectMembers: Array,
  },
  data() {
    return {
      isEditVisible: false,
      editingRecord: {},
    };
  },
  methods: {
    handleItemCommand(data) {
      switch (data.action) {
        case "status":
          this.$emit("setStatus", { id: this.data._id, status: data.payload });
          break;
        case "delete":
          this.$emit("delete", this.data._id);
          break;
        case "export_pdf":
          this.$emit("exportPdf", this.data._id);
          break;
        case "edit":
          this.showEditModal();
          break;
        default:
          break;
      }
    },
    showEditModal() {
      this.isEditVisible = true;
      const { _id, responsibleId, priority, dueDate, isOwner, workshopFreeText } = this.data;
      this.editingRecord = { _id, isOwner, responsibleId, priority, dueDate, workshopFreeText };
    },
    cancelEditModal() {
      this.editingRecord = {};
      this.isEditVisible = false;
    },
    async submitChanges() {
      // const isValid = await this.$refs.observer.validate();
      // if (!isValid) {
      //   return;
      // }
      this.$emit("editRecord", { ...this.editingRecord });
      this.cancelEditModal();
    },
  },
};
</script>

<style></style>
