<template>
  <div>
    <el-row style="display: flex; justify-content: start; align-items: center">
      <el-col :span="10">
        <profile-select
          :editMode="true"
          :title="getTitle()"
          :multiple="false"
          :items="positionOptions"
          v-model="selectedPositionId"
          valueIdentifier="_id"
          :hideInactive="false"
          label="name"
        />
      </el-col>
      <el-col v-if="isEditMode" :span="6" style="margin-left: 6px; margin-bottom: 6px">
        <el-button class="btn btn-sm btn-ghost" @click="showCreate">
          <plus-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="showEdit">
          <pencil-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="removePosition">
          <trash-can-outline-icon />
        </el-button>
      </el-col>
    </el-row>
    <!-- DIALOG -->
    <el-dialog
      :title="$t('src.components.employees.employeepositions.mitarbeiterPositionenKonfiguration')"
      :visible="dialogVisible"
      @close="dismiss"
      width="500px"
      center
    >
      <el-form :model="formData" :rules="formRules" ref="form" :disabled="!isEditMode">
        <el-form-item :label-width="labelWidth" label="Aktiv" prop="active">
          <profile-switch v-model="formData.active" no-title></profile-switch>
        </el-form-item>
        <el-form-item
          :label="$t('src.components.employees.employeepositions.positionName')"
          prop="name"
          :label-width="labelWidth"
        >
          <el-input v-model="formData.name" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.employees.employeepositions.abbrechen')"
            @click="dismiss"
          ></cancel-button>
          <save-button
            v-if="!dialogIsEditForm"
            :text="$t('src.components.employees.employeepositions.speichern')"
            @click="create"
            :loading="loading"
          ></save-button>
          <save-button
            v-if="dialogIsEditForm"
            :text="$t('src.components.employees.employeepositions.speichern')"
            @click="update"
            :loading="loading"
          ></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Button, Message, MessageBox, Dialog, Form, FormItem, Input } from "element-ui";

export default {
  name: "employee-positions",
  props: {
    isEditMode: Boolean,
  },
  components: {
    Button,
    Message,
    MessageBox,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  data() {
    return {
      labelWidth: "120px",
      formRules: {
        name: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      formData: {
        active: true,
        name: "",
      },
      loading: false,
      dialogVisible: false,
      dialogIsEditForm: false,
      positionOptions: [],
      selectedPositionId: null,
    };
  },
  mounted() {
    this.fetchPositions();
  },
  methods: {
    getTitle() {
      return "Positionen (" + this.positionOptions.length + ")";
    },
    showCreate() {
      this.dialogVisible = true;
    },
    showEdit() {
      if (!this.selectedPositionId) {
        MessageBox.alert(this.$t("src.components.employees.employeepositions.addPositionMessage"), "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.dialogVisible = true;
      this.dialogIsEditForm = true;
      const foundPosition = this.positionOptions.find((item) => item._id === this.selectedPositionId);
      if (foundPosition) {
        // set initial field values for declared formData keys
        Object.keys(this.formData).forEach((key) => {
          this.formData[key] = foundPosition[key];
        });
      }
    },
    dismiss() {
      this.dialogVisible = false;
      this.dialogIsEditForm = false;

      this.formData.active = true;
      this.formData.name = "";
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    async fetchPositions() {
      try {
        const response = await this.axios.get("api/employees/positions");
        this.positionOptions = response.data || [];
        this.$root.$emit("employeePositionsUpdated", this.positionOptions);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          await this.axios.post("/api/employees/positions", this.formData);
          this.dismiss();
          Message.success(this.$t("src.components.employees.employeepositions.addedPositionMessage"));
          this.fetchPositions();
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          await this.axios.put(`/api/employees/positions/${this.selectedPositionId}`, this.formData);
          this.fetchPositions();
          Message.success(this.$t("src.components.employees.employeepositions.updatedPositionMessage"));
          this.dismiss();
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    removePosition() {
      if (!this.selectedPositionId) {
        MessageBox.alert(this.$t("src.components.employees.employeepositions.addPositionMessage"), "", {
          type: "error",
          showClose: false,
        });
        return;
      }
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/employees/positions/${this.selectedPositionId}`);
          this.selectedPositionId = null;
          this.fetchPositions();
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            Message.error(error.response.data.message);
          } else {
            Message.error(error.message);
          }
          throw error;
        }
      });
    },
  },
};
</script>

<style></style>
