<template>
    <el-button 
        @click="handleClick" 
        type="info">
            <i class="nc-icon el-icon-edit"></i> {{text}}
    </el-button>
</template>

<script>
export default {
  name:'edit-button',
  props:{
      text: ""
  },
  methods: {
      handleClick(evt) {
        this.$emit('click', evt);
      }
  }
};
</script>

<style>
</style>

