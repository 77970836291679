<template>
  <div>
    <div class="n-view-title">{{ $t("src.components.management.finance.index.finance") }}</div>
    <div class="card"></div>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  name: "finance",
  components: { [Message.name]: Message },
};
</script>

<style></style>
