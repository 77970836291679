<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.employees.index.mitarbeiter")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.employees.index.mitarbeiterListe") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="21">
        <div class="n-view-title">{{ $t("src.components.employees.index.mitarbeiterListe") }}</div>
      </el-col>

      <el-col :span="3" class="text-right">
        <new-listitem-button v-if="$can('create', 'employee')" to="/employee/profile/new" data-testid="new_employee">{{
          $t("src.components.employees.index.neuerMitarbeiter")
        }}</new-listitem-button>
      </el-col>
    </el-row>

    <!-- PREVIEW PICTURE -->
    <div class="front" v-show="previewPicture">
      <lazy-image v-if="previewPictureSrc" :src="previewPictureSrc" fit="contain" style="max-height: 100%" />
    </div>
    <!-- DIALOG WITH PICTURE -->
    <el-dialog width="30%" :visible.sync="dialogVisible" center>
      <div style="text-align: center; height: 70vh; overflow: hidden">
        <lazy-image :src="dialogImage" key="cover" height="100%" wrapperStyle="height: 100%;" />
      </div>
    </el-dialog>

    <index-table
      authSubject="employee"
      :propsToSearch="propsToSearch"
      modelType="employee"
      :extendedColumns="extendedColumns"
      :defaultSort="{ prop: 'name', order: 'ascending' }"
      :normalizeResponse="normalizeResponse"
    >
      <!-- NAME and PICTURE COLUMN -->
      <template v-slot:Name="props">
        <el-row type="flex" align="middle">
          <div class="mr-10" style="flex-shrink: 0">
            <a
              style="display: inline-block; height: 50px; width: 50px; line-height: 50px"
              v-on:mouseover="showPreviewPicture(props)"
              v-on:mouseleave="hidePreviewPicture()"
              v-on:click="showPictures(props)"
            >
              <lazy-image
                :src="getThumbnail(props)"
                fit="cover"
                style="width: 50px; height: 50px; border-radius: 2px"
                lazy
                :scroll-container="getScrollContainer()"
              >
                <template v-slot:placeholder>
                  <div class="image-slot">
                    <span style="font-size: 12px"
                      >{{ $t("src.components.employees.index.lade")
                      }}<span class="dot">{{ $t("src.components.employees.index.2644") }}</span>
                    </span>
                  </div>
                </template>

                <template v-slot:error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </lazy-image>
            </a>
          </div>

          <div style="flex-grow: 1">
            <router-link v-if="$can('read', 'employee')" :to="`/employee/profile/view/${props.data.row.id}`">
              {{ props.data.row.name }}
            </router-link>
            <span v-else>{{ props.data.row.name }}</span>
            <el-badge
              :type="props.data.row.active ? 'success' : 'danger'"
              style="padding-top: 9px; padding-left: 6px"
              is-dot
            />
            <p style="color: #545050; font-size: 12px; line-height: 16px">{{ props.data.row.nickname }}</p>
          </div>
        </el-row>
      </template>

      <!-- PERS NR COLUMN -->
      <template v-slot:Team="props">
        {{ props.data.row.team }}
      </template>
      <template v-slot:Pers-Nr="props">
        {{ props.data.row.employeeNumber }}
      </template>
      <!-- POSITION COLUMN -->
      <template v-slot:Position="props">
        {{ getPosition(props.data.row) }}
      </template>
      <!-- HANDY COLUMN -->
      <template v-slot:Handy-Privat="props">
        {{
          props.data.row.hrOffice && props.data.row.hrOffice.mobile
            ? `${props.data.row.hrOffice.mobile} (g)`
            : props.data.row.hrPrivate && props.data.row.hrPrivate.mobile
            ? `${props.data.row.hrPrivate.mobile} (p)`
            : "—"
        }}
      </template>
    </index-table>
  </div>
</template>

<script>
import { IndexTable, NewListItemButton, LazyImage } from "src/components/UIComponents";
import { Message } from "element-ui";

export default {
  data() {
    return {
      previewPicture: false,
      dialogImage: "",
      dialogVisible: false,
      previewPictureSrc: "",
      positionOptions: [],
      extendedColumns: [
        {
          label: "Name",
          key: "name",
          minWidth: 300,
        },
        {
          label: "Team",
          key: "team",
          minWidth: 150,
        },
        {
          label: "Pers-Nr",
          key: "employeeNumber",
          minWidth: 130,
        },
        {
          label: "Position",
          key: "position",
          minWidth: 200,
        },
        {
          label: "Handy-Privat",
          key: "hrPrivate",
          minWidth: 200,
          formatter: function (row, col) {
            return row.hrPrivate.mobile;
          },
        },
      ],
      propsToSearch: ["name", "team", "employeeNumber", "nickname", "position", "statusReference.0.statusType.label"],
    };
  },
  components: {
    [LazyImage.name]: LazyImage,
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
  },
  mounted() {
    let self = this;

    //get model types for label
    this.axios
      .get("/api/employees/positions")
      .then((result) => {
        this.positionOptions = result.data;
      })
      .catch((error) => {
        Message({
          message: error.message,
          type: "error",
        });
      });
  },
  methods: {
    getScrollContainer() {
      return document.querySelector(".table-wrapper .el-table__body-wrapper");
    },
    getThumbnail(props) {
      if (!props.data.row.picture) {
        return this.axios.defaults.baseURL + "/img/default-avatar.png";
      } else {
        return this.axios.defaults.baseURL + props.data.row.picture.thumbnail;
      }
    },
    showPreviewPicture(props) {
      if (props.data.row.picture) {
        this.previewPictureSrc = encodeURI(this.axios.defaults.baseURL + props.data.row.picture.url);
        this.previewPicture = true;
      }
    },
    hidePreviewPicture() {
      this.previewPictureSrc = "";
      this.previewPicture = false;
    },
    showPictures(props) {
      this.hidePreviewPicture();
      if (props.data.row.picture) {
        this.dialogImage = this.axios.defaults.baseURL + props.data.row.picture.url;
        this.dialogVisible = true;
      }
    },
    getPosition(entry) {
      if (entry && this.positionOptions && this.positionOptions.length > 0) {
        let position = this.positionOptions.find((m) => m._id === entry.selectedPosition);
        if (position) {
          return position.name;
        } else {
          return " — ";
        }
      }
      return " — ";
    },
    normalizeResponse(data) {
      return data.map(({ firstName, lastName, ...rest }) => ({
        ...rest,
        name: `${lastName}, ${firstName}`,
      }));
    },
  },
};
</script>

<style lang="scss"></style>
