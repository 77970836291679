<template>
  <div>
    <div class="card card-user">
      <form>
        <perfect-scrollbar>
          <div class="card-header">
            <h3 class="n-profile-title">{{ tabName }}</h3>
            <!-- <label>{{ $t('src.components.serviceprovider.profile.aktiv') }}</label>
            <el-switch
              :disabled="!isEditMode"
              style="margin-left: 10px"
              v-model="serviceProvider.active"
              active-color="#18ce68"
              inactive-color="#ff6868"
            /> -->
          </div>

          <div class="card-body">
            <div class="row col-md-12">
              <div class="col-md-2">
                <div class="form-group">
                  <avatar-uploader
                    :isEditMode="isEditMode"
                    :actionURI="actionURI"
                    :avatar="serviceProvider.picture"
                    v-on:avatar-changed="setPicture"
                    :name="'serviceProvider_' + serviceProvider.id + '_picture_0'"
                  />
                </div>
                <div class="form-group">
                  <profile-switch
                    :disabled="!isEditMode"
                    :title="$t('src.components.vehicle.profile.aktiv')"
                    v-model="serviceProvider.active"
                    :active-text="$t('src.components.vehicle.profile.ja')"
                    :inactive-text="$t('src.components.vehicle.profile.nein')"
                    colorizeActive
                  />
                </div>
              </div>
              <div class="col-md-3">
                <profile-select
                  :items="serviceProviderTypes"
                  :multiple="false"
                  :clearable="false"
                  :title="$t('src.components.serviceprovider.profile.dienstleisterTyp')"
                  :editMode="isEditMode"
                  v-model="serviceProvider.serviceProviderType"
                  name="Dienstleister Typ"
                  customMessage="Bitte Dienstleiter-Typ auswählen"
                  rules="required"
                  required
                />
              </div>
              <div class="col-md-3" v-if="!serviceProvider.inital">
                <profile-input
                  :value="serviceProvider.uid"
                  :label="$t('src.components.serviceprovider.profile.dienstleisternnummer')"
                  :editMode="false"
                />
              </div>
            </div>

            <hr />

            <!-- show this as soon as a service provider type is set -->
            <div v-if="serviceProvider.serviceProviderType">
              <h6 class="title">{{ serviceProvider.serviceProviderType | formatTypeToName }}</h6>
              <hotel
                v-show="isSelected(serviceProvider.serviceProviderType)"
                :isEditMode="isEditMode"
                :serviceProvider="serviceProvider"
              />
              <hr v-show="isSelected(serviceProvider.serviceProviderType)" />
            </div>

            <!-- MAßE -->
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profile.allgemein") }}
            </div>
            <el-row class="n-profile-spacer" :gutter="20">
              <el-col :span="4">
                <profile-input
                  name="Name"
                  rules="required"
                  required
                  v-model="serviceProvider.name"
                  :label="$t('src.components.serviceprovider.profile.name')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.description"
                  :label="$t('src.components.serviceprovider.profile.beschreibung')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.ourCustomerId"
                  :label="$t('ourCustomerId')"
                  :editMode="isEditMode"
                />
              </el-col>
            </el-row>

            <!-- MAßE -->
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profile.adressdaten") }}
            </div>
            <el-row class="n-profile-spacer" :gutter="20">
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.street"
                  :label="$t('src.components.serviceprovider.profile.strae')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.no"
                  :label="$t('src.components.serviceprovider.profile.hausnummer')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.zip"
                  :label="$t('src.components.serviceprovider.profile.plz')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.city"
                  :label="$t('src.components.serviceprovider.profile.stadt')"
                  :editMode="isEditMode"
                />
              </el-col>

              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.addressAddition"
                  :label="$t('src.components.serviceprovider.profile.adresszusatz')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-select
                  :multiple="false"
                  v-model="serviceProvider.hrContact.country"
                  label="name"
                  :title="$t('country')"
                  :editMode="isEditMode"
                  :items="countryList"
                  filterable
                />
              </el-col>
            </el-row>

            <hr />

            <!-- MAßE -->
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profile.kontaktdaten") }}
            </div>
            <el-row class="n-profile-spacer" :gutter="20">
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.contactPerson"
                  :label="$t('src.components.serviceprovider.profile.contactPerson')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.phone"
                  :label="$t('src.components.serviceprovider.profile.telefon')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.mobile"
                  :label="$t('src.components.serviceprovider.profile.handy')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.fax"
                  :label="$t('src.components.serviceprovider.profile.fax')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="serviceProvider.hrContact.email"
                  :name="$t('src.components.serviceprovider.profile.eMail')"
                  type="email"
                  rules="email"
                  :label="$t('src.components.serviceprovider.profile.eMail')"
                  :editMode="isEditMode"
                />
              </el-col>
            </el-row>
          </div>
        </perfect-scrollbar>
      </form>
    </div>
  </div>
</template>

<script>
import Hotel from "./Hotel.vue";
import { Message, RadioGroup, RadioButton, Switch } from "element-ui";
import { Avatar, Collapse, CollapseItem, Keyfigures } from "src/components/UIComponents";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapState } from "vuex";
import { sortBy } from "lodash";
import TypeSettings from "../UIComponents/Helper/TypeSettings.vue";
import countryList from "src/country-list";

export default {
  name: "serviceProvider-profile",
  props: {
    serviceProvider: Object,
    tabName: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PerfectScrollbar,
    TypeSettings,
    [Hotel.name]: Hotel,
    Message,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [Switch.name]: Switch,
    Collapse,
    CollapseItem,
    [Keyfigures.name]: Keyfigures,
  },
  data() {
    return {
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      customServiceProviderTypes: [],
      showHotel: false,
      countryList: countryList,
    };
  },
  mounted() {
    //settins changed
    this.$root.$on("settingsChanged", (data) => {
      if (data.modelType === "serviceProvider") {
        this.customServiceProviderTypes = data.options.map((i) => ({ label: i.label, value: i._id, active: i.active }));
      }
    });
  },
  watch: {
    serviceProvider: {
      handler: function (val, oldVal) {
        if (!oldVal.inital && this.isEditMode) {
          //just call parent, that serviceProvider changed when data is not initial set
          this.$emit("dirtyFlagSet");
        }
      },
      deep: true,
    },
  },
  methods: {
    isSelected(selectedType) {
      if (selectedType === "HOTEL" && this.serviceProvider.serviceProviderType === "HOTEL") {
        let hotel = {
          freeText: "",
          type: selectedType,
          stars: 0,
        };
        //if structure is not yet set
        if (!this.serviceProvider.extendedProps || this.serviceProvider.extendedProps.type !== "HOTEL") {
          this.serviceProvider.extendedProps = hotel;
        }
        return true;
      }
      return false;
    },
    setPicture(pictureSettings) {
      this.serviceProvider.picture = pictureSettings;
    },
    updateProfile(entity) {
      this.$emit("updateProfileCicked", this.serviceProvider);
    },
  },
  computed: {
    // default service providers defined in vuex store to be reusable in other parts of app
    ...mapState(["defaultServiceProviders"]),
    serviceProviderTypes() {
      return sortBy(this.defaultServiceProviders.defaultOptions.concat(this.customServiceProviderTypes), (o) =>
        o.label.toLowerCase()
      );
    },
  },
  filters: {
    formatTypeToName: function (serviceProviderType) {
      const labelMap = {
        HOTEL: "Hotel",
      };

      return labelMap[serviceProviderType];
    },
  },
};
</script>

<style></style>
