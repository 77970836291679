<!-- Key changes for event bus replacement:
Removed the data() function as we no longer need local state for the widget data.
Removed the created() and beforeDestroy() lifecycle hooks as we're no longer using the event bus.
Added a computed property using mapState to access the widget data from the Vuex store.
The template remains largely unchanged, but now it's using the computed properties that map to the Vuex state.
-->
<template>
  <el-row :gutter="16">
    <el-col :md="6" :sm="2" v-show="accessRights.birthdays">
      <widget-area-item
        :title="$t('src.components.dashboard.views.dashboard.widgets.birthdayswidget.geburtstage')"
        :data="birthdaysWidgetData"
      />
    </el-col>
    <el-col :md="6" :sm="2" v-show="accessRights.documentsValidity">
      <widget-area-item
        :title="
          $t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.gltigkeitVonRessourcendokumenten')
        "
        :data="docStateWidgetData"
      />
    </el-col>
    <el-col :md="6" :sm="2" v-show="accessRights.invoiceReports">
      <widget-area-item
        :title="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusRechnungsstellung')"
        :data="invoiceStatusWidgetData"
      />
    </el-col>
  </el-row>
</template>

<script>
import WidgetAreaItem from "./WidgetAreaItem.vue";
import { mapState } from "vuex";

export default {
  name: "widgets-area",
  components: { WidgetAreaItem },
  props: { accessRights: Object },
  computed: {
    ...mapState({
      birthdaysWidgetData: (state) => state.widget.birthdaysWidgetData,
      docStateWidgetData: (state) => state.widget.docStateWidgetData,
      invoiceStatusWidgetData: (state) => state.widget.invoiceStatusWidgetData,
    }),
  },
};
</script>

<style></style>
