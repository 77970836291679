<template>
  <div class="card card-user">
    <div class="card-header" style="display: flex; justify-content: space-between; align-items: center">
      <h3 class="n-profile-title">{{ $t("src.components.project.invoices.invoices.rechnungsverwaltung") }}</h3>

      <el-dropdown v-if="computedEditMode" trigger="click" @command="handleInvoiceAdd">
        <el-button type="primary"
          >{{ $t("src.components.project.invoices.invoices.rechnungstypHinzufgen")
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-circle-plus" command="partitialInvoice">{{
            $t("src.components.project.invoices.invoices.abschlagsrechnung")
          }}</el-dropdown-item>
          <el-dropdown-item icon="el-icon-plus" command="invoice">{{
            $t("src.components.project.invoices.invoices.rechnung")
          }}</el-dropdown-item>
          <el-dropdown-item icon="el-icon-circle-plus-outline" command="partialFinalInvoice">{{
            $t("src.components.project.invoices.invoices.teilschlurechnung")
          }}</el-dropdown-item>
          <el-dropdown-item icon="el-icon-check" command="finalInvoice">{{
            $t("src.components.project.invoices.invoices.schlurechnung")
          }}</el-dropdown-item>
          <el-dropdown-item icon="el-icon-minus" command="creditNote">{{
            $t("src.components.project.invoices.invoices.gutschrift")
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="card-body">
      <el-table
        :data="computedInvoiceHistory"
        style="width: 100%"
        show-summary
        :summary-method="getSummaries"
        :default-sort="{ prop: 'createdAt', order: 'ascending' }"
      >
        <el-table-column
          width="100px"
          prop="id"
          :label="$t('src.components.project.invoices.invoices.num')"
          :formatter="numFormatter"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="200px"
          prop="name"
          :label="$t('src.components.project.invoices.invoices.name')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="200px"
          prop="invoiceNumber"
          :label="$t('src.components.project.invoices.invoices.rechnungsnummer')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150px"
          prop="parentProjectName"
          :label="$t('src.components.project.invoices.invoices.projekt')"
          sortable
        >
        </el-table-column>
        <el-table-column
          min-width="150px"
          prop="status"
          :label="$t('src.components.project.invoices.invoices.status')"
          :formatter="statusFormatter"
          sortable
        >
        </el-table-column>
        <el-table-column
          width="200px"
          prop="dateRange"
          :formatter="dateRangeFormatter"
          :label="$t('src.components.project.invoices.invoices.leistungszeitraum')"
          sortable
        ></el-table-column>
        <el-table-column
          width="210px"
          prop="price"
          :formatter="priceFormatter"
          align="right"
          label="Rechnungsbetrag gesamt"
          sortable
        ></el-table-column>
        <el-table-column
          width="240px"
          prop="currentPrice"
          :formatter="currentPriceFormatter"
          align="right"
          label="Rechnungsbetrag Teilrechnung"
          sortable
        ></el-table-column>
        <el-table-column
          width="200px"
          prop="date"
          :formatter="dateFormatter"
          :label="$t('src.components.project.invoices.invoices.rechnungsdatum')"
          sortable
        ></el-table-column>
        <el-table-column
          width="200px"
          prop="createdAt"
          :formatter="dateFormatter"
          label="Erstelldatum"
          sortable
        ></el-table-column>
        <el-table-column width="150px" align="right" fixed="right" class-name="action-buttons td-actions text-right">
          <template v-slot="props">
            <button v-if="props.row.fileList.length" class="btn btn-sm btn-ghost" style="cursor: default">
              <attachment-icon />
              <div style="margin-left: 10px">{{ props.row.fileList.length }}</div>
            </button>
            <!-- edit mode -->
            <el-dropdown @command="handleItemCommand" v-if="computedEditMode">
              <button class="btn btn-sm btn-ghost" style="font-size: 1rem">
                <dots-vertical-icon />
              </button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ action: 'to_calendar', data: props.row }" v-if="hasAccessToCalendar">
                  <calendar-icon />{{
                    $t("src.components.project.invoices.invoices.imKalenderAnzeigen")
                  }}</el-dropdown-item
                >
                <el-dropdown-item :command="{ action: 'edit', data: props.row }">
                  <pencil-icon />{{ $t("src.components.project.invoices.invoices.bearbeiten") }}</el-dropdown-item
                >
                <el-dropdown-item :command="{ action: 'view', data: props.row }">
                  <eye-outline-icon />{{
                    $t("src.components.project.pickpack.pickpackimagedrop.ansehen")
                  }}</el-dropdown-item
                >
                <el-dropdown-item :command="{ action: 'delete', data: props.row }" v-if="accessRights === 'full'">
                  <trash-can-outline-icon />{{
                    $t("src.components.project.invoices.invoices.lschen")
                  }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- view mode -->
            <span v-else>
              <button
                class="btn btn-sm btn-ghost"
                :title="$t('src.components.project.pickpack.pickpackimagedrop.ansehen')"
                @click="viewInvoice(props.row)"
              >
                <eye-outline-icon />
              </button>
              <button
                class="btn btn-sm btn-ghost"
                :title="$t('src.components.project.invoices.invoices.imKalenderAnzeigen')"
                @click="goToCalendar(props.row)"
                v-if="hasAccessToCalendar"
              >
                <calendar-icon />
              </button>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <invoice-details
      @closed="discardDetails"
      @onInvoiceSave="invoiceSaved"
      :invoiceData="selectedInvoice"
      :statusMap="statusMap"
      :isEditMode="invoiceEditMode"
      :isNewInvoice="isNewInvoice"
      :deleteInvoice="deleteInvoice"
      :projectId="projectId"
      :projectDateRange="projectDateRange"
      :invoices="invoiceHistory"
      :getInvoicesOfType="getInvoicesOfType"
      :totalPrice="totalPrice"
    />
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Drawer,
  Button,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Message,
  MessageBox,
} from "element-ui";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import Attachment from "vue-material-design-icons/Attachment";
import Calendar from "vue-material-design-icons/Calendar";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import { moment } from "src/config/moment";
import InvoiceDetails from "./InvoiceDetails";
import { mapState } from "vuex";
import { get, last, orderBy } from "lodash";

export default {
  name: "project-invoices-new",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
    [Button.name]: Button,
    [Input.name]: Input,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [InvoiceDetails.name]: InvoiceDetails,
    [DropdownMenu.name]: DropdownMenu,
    [Message.name]: Message,
    [MessageBox.name]: MessageBox,
    [EyeOutline.name]: EyeOutline,
    [Pencil.name]: Pencil,
    [Attachment.name]: Attachment,
    [DotsVertical.name]: DotsVertical,
    [TrashCanOutline.name]: TrashCanOutline,
    [Calendar.name]: Calendar,
  },
  props: {
    accessRights: String,
    projectId: String,
    projectsMeta: Array,
    projectDateRange: Array,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusMap: {
        PREPARATION: "Vorbereitung",
        ACTIVE: "Rechnung erstellen",
        INVOICED: "Berechnet",
      },
      invoiceHistory: [],
      totalPrice: null,
      selectedInvoice: null, // data passed to view/edit drawer
      invoiceEditMode: false, // to distinguish between view and edit button
      isNewInvoice: false, // to delete invoice if created new one and then closed drawer
    };
  },
  mounted() {
    this.fetchInvoices();
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Summe";
          return;
        }
        if (column.property === "price") {
          if (this.totalPrice) {
            return (sums[index] = `€ ${this.totalPrice.toLocaleString("de-DE", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`);
          } else {
            return "";
          }
        }
      });

      return sums;
    },
    numFormatter(props) {
      return `${props.shortname}${props.id ? ` ${props.id}` : ""}`;
    },
    statusFormatter(props) {
      return this.statusMap[props.status];
    },
    dateRangeFormatter(props) {
      if (props.dateRange) {
        return props.dateRange.join(" - ");
      } else {
        return "N/A";
      }
    },
    dateFormatter(props, _, value) {
      if (value) {
        return moment(value).format("L");
      } else {
        return "N/A";
      }
    },
    formatPrice(cost) {
      return "€ " + parseFloat(cost).toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    },
    priceFormatter(record) {
      if (record.shortname === "RG" && record.currentPrice) {
        return this.formatPrice(record.currentPrice);
      } else if (record.price) {
        return this.formatPrice(record.price);
      } else {
        return "";
      }
    },
    currentPriceFormatter(record) {
      if (record.shortname === "RG") {
        return "";
      } else if (record.currentPrice) {
        return this.formatPrice(record.currentPrice);
      } else {
        return "";
      }
    },
    handleItemCommand(payload) {
      switch (payload.action) {
        case "edit":
          this.editInvoice(payload.data);
          break;
        case "view":
          this.viewInvoice(payload.data);
          break;
        case "delete":
          this.deleteInvoice(payload.data);
          break;
        case "to_calendar":
          this.goToCalendar(payload.data);
          break;
        default:
          throw new Error("Unknown action: " + payload.action);
      }
    },
    handleInvoiceAdd(invoiceType) {
      switch (invoiceType) {
        case "invoice":
          this.createInvoice();
          break;

        case "partitialInvoice":
          this.createPartitialInvoice();
          break;

        case "partialFinalInvoice":
          this.partialFinalInvoice();
          break;

        case "finalInvoice":
          this.finalInvoice();
          break;
        case "creditNote":
          this.creditNote();
          break;
        default:
          console.error("Unsupported invoice type: " + invoiceType);
          break;
      }
    },
    async createInvoice() {
      const project = this.projectsMeta.find((project) => project.id === this.projectId) || {};
      let start = moment(project.dateRange[0]).format("DD.MM.YYYY");
      let end = moment(project.dateRange[1]).format("DD.MM.YYYY");
      const invoiceData = {
        id: String(this.invoiceHistory.filter((item) => +item.type === 1).length + 1),
        type: "1",
        name: "Rechnung",
        shortname: "RG",
        dateRange: [start, end],
        fileList: [],
        freeText: "",
      };
      await this.submitInvoice(invoiceData);
    },
    async createPartitialInvoice() {
      // AR id increments for each subsequent AR
      // TR and TSR resets the AR id
      const invoices = orderBy(this.invoiceHistory, (i) => new Date(i).getTime(), "desc");
      let id = 1;
      const previousAR = invoices.find(({ shortname }) => shortname === "AR");
      if (previousAR) {
        id = +previousAR.id + 1;
      }
      const invoiceData = {
        id: id.toString(),
        type: "2",
        name: "Abschlagsrechnung",
        shortname: "AR",
        invoiceNumber: null,
        dateRange: [],
        fileList: [],
        freeText: "",
      };
      await this.submitInvoice(invoiceData);
    },
    async partialFinalInvoice() {
      const project = this.projectsMeta.find((project) => project.id === this.projectId) || {};
      let start = moment(project.dateRange[0]).format("DD.MM.YYYY");
      let end = moment(project.dateRange[1]).format("DD.MM.YYYY");
      const invoiceData = {
        id: String(this.invoiceHistory.filter((item) => +item.type === 3).length + 1),
        type: "3",
        name: "Teilschlußrechnung",
        shortname: "TR",
        invoiceNumber: null,
        dateRange: [start, end],
        fileList: [],
        freeText: "",
      };
      await this.submitInvoice(invoiceData);
    },
    async finalInvoice() {
      const project = this.projectsMeta.find((project) => project.id === this.projectId) || {};
      let start = moment(project.dateRange[0]).format("DD.MM.YYYY");
      let end = moment(project.dateRange[1]).format("DD.MM.YYYY");
      const invoiceData = {
        // dont submit id for this type of invoice
        // id: this.invoiceHistory.filter(item => +item.type === 4).length + 1,
        type: "4",
        name: "Schlußrechnung",
        shortname: "SR",
        dateRange: [start, end],
        fileList: [],
        freeText: "",
      };
      await this.submitInvoice(invoiceData);
    },
    async creditNote() {
      const invoiceData = {
        id: String(this.invoiceHistory.filter((item) => +item.type === 5).length + 1),
        type: "5",
        name: "Gutschrift",
        shortname: "GS",
        fileList: [],
        freeText: "",
      };
      await this.submitInvoice(invoiceData);
    },
    async submitInvoice(invoiceData) {
      try {
        const response = await this.axios.post(`/api/project-invoices/${this.projectId}/sample`, invoiceData);
        this.editInvoice(response.data, true);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    editInvoice(invoiceData, isNew) {
      this.selectedInvoice = invoiceData;
      this.isNewInvoice = !!isNew;
      this.invoiceEditMode = true;
    },
    async deleteInvoice(invoiceData, skipPrompt = false) {
      try {
        const index = this.invoiceHistory.findIndex((d) => d._id === invoiceData._id);
        if (!skipPrompt) {
          await MessageBox.confirm("Rechnung löschen?", "Achtung", {
            confirmButtonText: "Ja",
            cancelButtonText: "Nein",
            type: "warning",
          }).catch(() => {
            throw "ABORTED";
          });
        }
        await this.axios.delete(`/api/project-invoices/${this.invoiceHistory[index]._id}`);
        invoiceData.fileList.forEach((file) => {
          const metadata = `invoices_${invoiceData.parentProjectId}_${invoiceData.shortname}_${invoiceData._id}`;
          this.axios.delete("/api/fileupload", {
            params: {
              filename: file.name,
              metadata: metadata,
            },
          });
        });
        if (index !== -1) {
          this.fetchInvoices();
        }
      } catch (error) {
        if (error !== "ABORTED") {
          Message.error(error.message);
          throw error;
        }
      }
    },
    goToCalendar(invoiceData) {
      if (invoiceData && invoiceData.dateRange && invoiceData.dateRange.length === 2) {
        const invoiceStartDateString = moment(invoiceData.dateRange[0], "DD.MM.YYYY").toJSON();
        this.$emit("navigateToCalendar", { date: invoiceStartDateString });
      } else {
        Message.warning("Failed to navigate due to invalid date range");
      }
    },
    viewInvoice(invoiceData) {
      this.selectedInvoice = invoiceData;
      this.invoiceEditMode = false;
    },
    discardDetails() {
      this.selectedInvoice = null;
      this.invoiceEditMode = false;
      this.isNewInvoice = false;
    },
    async fetchInvoices() {
      if (this.projectId === "new") {
        return;
      }
      try {
        // TODO: here will be the summed invoices value
        const response = await this.axios.get(`/api/project-invoices/${this.projectId}`);
        this.invoiceHistory = response.data.data;
        this.totalPrice = response.data.totalPrice;
        if (this.$route.query.invoiceId) {
          const invoiceFound = this.invoiceHistory.find((item) => item._id === this.$route.query.invoiceId);
          if (invoiceFound) {
            this.viewInvoice(invoiceFound);
          }
          this.$router.replace({ query: {} });
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    invoiceSaved(updatedInvoice) {
      const foundIdx = this.invoiceHistory.findIndex((item) => item._id === updatedInvoice._id);
      if (foundIdx !== -1) {
        this.$set(this.invoiceHistory, foundIdx, updatedInvoice);
      } else {
        this.invoiceHistory.push(updatedInvoice);
      }
      this.fetchInvoices();
    },
    getInvoicesOfType(type) {
      return this.invoiceHistory.filter((item) => item.type === type);
    },
  },
  computed: {
    ...mapState("account", { appAccessRights: "accessRights" }),
    hasAccessToCalendar() {
      return !!get(this.appAccessRights, "calendar.generalAccess");
    },
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    computedInvoiceHistory() {
      const invoiceHistoryWithProjectName = this.invoiceHistory.map((invoice) => {
        const project = this.projectsMeta.find((project) => project.id === invoice.parentProjectId) || {};
        let projectName = project.bvName;
        if (project.constructionPhase) {
          projectName += ` | BA${project.constructionPhase}`;
        }
        return { ...invoice, parentProjectName: projectName };
      });
      // to show only invoices that are created within current project
      if (this.hideRelatedInvoices) {
        return invoiceHistoryWithProjectName.filter((item) => item.parentProjectId === this.projectId);
      }
      return invoiceHistoryWithProjectName;
    },
  },
};
</script>

<style></style>
