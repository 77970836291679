<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }" slim>
    <div :class="['el-form-item', { 'is-error': !!errors[0] }]">
      <template v-if="isEditMode">
        <span v-if="required" class="n-profile-select-title-red">*</span>
        <span class="n-profile-label-title">{{ title }}:</span>
        <br />
        <div class="el-form-item__content">
          <pr-date-picker
            :projectDateRange="projectDateRange"
            :disabled="!isEditMode"
            :value="value"
            @input="handleInput"
            v-bind="$attrs"
            :class="{ input: true, input_error: !!errors[0] }"
            :value-format="valueFormat"
            :dataTestid="name || title"
          />
          <transition name="slide">
            <div v-show="!!errors[0]" class="el-form-item__error">{{ errors[0] }}</div>
          </transition>
        </div>
      </template>
      <template v-else>
        <span class="n-profile-label-title">{{ title }}:</span>
        <br />
        <span class="n-profile-label-value" :dataTestid="name || title">{{ getValue() }}</span>
      </template>
    </div>
  </ValidationProvider>
</template>

<script>
import moment from "moment";
import PrDatePicker from "./PrDatePicker.vue";
import { ValidationProvider } from "vee-validate";

export default {
  components: { ValidationProvider, PrDatePicker },
  name: "profile-date-picker",
  props: {
    name: { type: String },
    rules: { type: String },
    isEditMode: { type: Boolean, default: true },
    required: { type: Boolean },
    value: { type: [Array, String, Date], default: null },
    title: { type: String, default: null },
    projectDateRange: { type: Array },
    error: { type: String, required: false },
    viewFormat: { type: String, default: "L" },
    valueFormat: { type: String },
  },
  data() {
    return {};
  },
  methods: {
    handleInput(val) {
      this.$emit("input", val);
    },
    getValue() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          return this.value.map((date) => moment(date).format(this.viewFormat)).join(" - ");
        }
        return moment(this.value).format(this.viewFormat);
      } else {
        return " — ";
      }
    },
  },
};
</script>

<style>
.form__error {
  color: #bf2441;
  margin-top: 0;
  width: 80%;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
