<template>
  <ValidationObserver ref="validateContactRecord">
    <div>
      <div v-if="typeSelection">
        <div class="form-group row">
          <div class="col-md-4">
            <profile-select
              filterable
              :multiple="false"
              :editMode="isEditMode"
              :items="typeSelectionOptions"
              v-model="localContactData.contactType"
              :placeholder="$t('src.components.project.contacts.components.contactsrecord.typ')"
              labelIdentifier="label"
              valueIdentifier="value"
              :title="$t('src.components.project.contacts.components.contactsrecord.typ')"
              size="small"
            />
          </div>
        </div>
      </div>
      <p v-else class="title mb-10 contact-title" style="font-size: 16px">
        <span style="margin-right: 10px">{{ title }}</span>
        <span v-if="!disabled">
          <span v-if="localContactData.contactId">
            <el-button size="small" title="Kontakt aufheben" @click="unlinkContact">
              <account-minus-outline-icon />
            </el-button>
          </span>
          <span v-else>
            <el-button size="small" title="Kontakt auswählen" @click="showContactSelect">
              <account-search-outline-icon />
            </el-button>
            <el-button
              size="small"
              v-if="$can('create', 'contacts')"
              title="als Kontakt speichern"
              @click.prevent="saveAsContact"
            >
              <account-plus-outline-icon />
            </el-button>
          </span>
        </span>
      </p>
      <div class="contact-flex">
        <div class="mr-3" style="flex-shrink: 0">
          <avatar-uploader
            class="n-grid-container-edit-avatar"
            :isEditMode="isEditMode"
            :actionURI="avatarActionURI"
            :avatar="localAvatar"
            v-on:avatar-changed="setAvatar"
            :name="`projectContact_${projectId}_picture_${getTitle()}`"
          />
        </div>
        <div class="form-group" style="flex-grow: 1">
          <el-row :gutter="10">
            <el-col :md="8" :lg="4">
              <profile-input
                v-model="localContactData.name"
                :rules="nameIsRequired ? 'required' : undefined"
                :name="`${title} ${$t('src.components.project.contacts.components.contactsrecord.firstName')}`"
                :label="$t('src.components.project.contacts.components.contactsrecord.firstName')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="8" :lg="4">
              <profile-input
                v-model="localContactData.lastName"
                :rules="nameIsRequired ? 'required' : undefined"
                :name="`${title} ${$t('src.components.project.contacts.components.contactsrecord.lastName')}`"
                :label="$t('src.components.project.contacts.components.contactsrecord.lastName')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="8">
              <profile-input
                v-model="localContactData.phone"
                :label="$t('src.components.project.contacts.components.contactsrecord.telefon')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="8">
              <profile-input
                v-model="localContactData.mobile"
                :label="$t('src.components.project.contacts.components.contactsrecord.mobile')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="8"
              ><profile-input
                v-model="localContactData.fax"
                :label="$t('src.components.project.contacts.components.contactsrecord.fax')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="8">
              <profile-input
                v-model="localContactData.email"
                :name="$t('src.components.project.contacts.components.contactsrecord.eMail')"
                type="email"
                rules="email"
                :label="$t('src.components.project.contacts.components.contactsrecord.eMail')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="8">
              <profile-input
                v-model="localContactData.company"
                :label="$t('src.components.project.contacts.components.contactsrecord.firma')"
                :editMode="isEditMode"
              />
            </el-col>
            <el-col :md="16" v-if="addFreeText">
              <profile-input
                v-model="localContactData.freeText"
                type="textarea"
                :label="$t('src.components.project.contacts.components.contactsrecord.freitext')"
                :editMode="isEditMode"
              />
            </el-col>
          </el-row>
          <div>
            <el-button
              @click="showMore = !showMore"
              :icon="showMore ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
              type="text"
              size="small"
              class="outline-none"
              >{{ $t("src.components.project.contacts.components.contactsrecord.expand") }}</el-button
            >
            <el-collapse-transition>
              <div v-show="showMore">
                <el-row :gutter="10">
                  <el-col :md="8">
                    <profile-input
                      v-model="localContactData.street"
                      :label="$t('src.components.contacts.contactdetails.strae')"
                      :editMode="isEditMode"
                    />
                  </el-col>
                  <el-col :md="8">
                    <profile-input
                      v-model="localContactData.no"
                      :label="$t('src.components.contacts.contactdetails.hausnummer')"
                      :editMode="isEditMode"
                    />
                  </el-col>
                  <el-col :md="8">
                    <profile-input
                      v-model="localContactData.zip"
                      :label="$t('src.components.contacts.contactdetails.plz')"
                      :editMode="isEditMode"
                    />
                  </el-col>
                  <el-col :md="8">
                    <profile-input
                      v-model="localContactData.city"
                      :label="$t('src.components.contacts.contactdetails.stadt')"
                      :editMode="isEditMode"
                    />
                  </el-col>
                  <el-col :md="8">
                    <profile-input
                      v-model="localContactData.addressAddition"
                      :label="$t('src.components.contacts.contactdetails.adresszusatz')"
                      :editMode="isEditMode"
                    />
                  </el-col>
                </el-row>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
      <!-- <div class="form-group" v-if="localDocuments.length || isEditMode">
        <label>{{ $t("src.components.project.contacts.components.contactsrecord.datei") }}</label>
        <el-upload
          :headers="authHeader"
          :action="actionURI"
          :data="{ metadata: getMetadata() }"
          :file-list="localDocuments"
          ref="upload"
          :on-success="fileUploaded"
          :on-remove="removeFile"
          :on-error="handleUploadError"
          :on-preview="showFile"
          :disabled="disabled"
        >
          <el-button class="mb-2" v-show="isEditMode" icon="el-simple-add" type="primary" size="mini">{{
            $t("src.components.project.contacts.components.contactsrecord.dateiHinzufgen")
          }}</el-button>
        </el-upload>
      </div> -->
      <el-dialog :visible.sync="isContactSelectVisible" title="Kontakt auswählen" @close="closeContactSelect">
        <profile-select
          v-model="selectedContact"
          filterable
          :filter-method="filterAddContactSelect"
          :multiple="false"
          :editMode="true"
          :items="filteredAllContacts"
          valueIdentifier="_id"
          label="displayName"
          name="Kontakt"
          :rules="isContactSelectVisible ? 'required' : undefined"
          required
          :title="$t('src.components.contacts.selectContact')"
        />
        <div class="text-right">
          <el-button type="primary" @click="handleSelectContact">Hinzufügen</el-button>
        </div>
      </el-dialog>
    </div>
  </ValidationObserver>
</template>

<script>
import { Input, Button, Upload, Dialog, Message, MessageBox } from "element-ui";
import { mapGetters } from "vuex";
import VSelect from "src/components/UIComponents/Inputs/VSelect.vue";
import { Avatar } from "src/components/UIComponents";
import { unlink } from "src/utils/unlink";
import AccountSearchOutline from "vue-material-design-icons/AccountSearchOutline.vue";
import AccountMinusOutline from "vue-material-design-icons/AccountMinusOutline.vue";
import AccountPlusOutline from "vue-material-design-icons/AccountPlusOutline.vue";
import { ValidationObserver } from "vee-validate";
import { isDataChanged } from "src/utils/isDataChanged.js";
import { debounce, get } from "lodash";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
  name: "contacts-record",
  components: {
    ValidationObserver,
    VSelect,
    [CollapseTransition.name]: CollapseTransition,
    [Message.name]: Message,
    [Dialog.name]: Dialog,
    [AccountSearchOutline.name]: AccountSearchOutline,
    [AccountMinusOutline.name]: AccountMinusOutline,
    [AccountPlusOutline.name]: AccountPlusOutline,
    [Avatar.name]: Avatar,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
  },
  props: {
    title: { type: String, required: true },
    contactData: { type: Object, required: true, default: () => ({}) },
    allContacts: { type: Array, default: () => [] },
    addFreeText: { type: Boolean },
    disabled: { type: Boolean },
    projectId: { type: String },
    typeSelection: { type: Boolean },
    typeSelectionOptions: {
      type: Array,
      validator: (value) => {
        return value.every((item) => typeof item.value === "string" && typeof item.label === "string");
      },
      description: "[{ value: 'id', label: 'Name' }]",
    },
  },
  data() {
    return {
      avatarActionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      localAvatar: {},
      localDocuments: [],
      localContactData: {},
      selectedContact: "",
      nameIsRequired: false,
      isContactSelectVisible: false,
      showMore: false,
      filteredAllContacts: [],
    };
  },
  created() {
    this.debounceUpdateContactData = debounce(this.updateContactData, 100);
  },
  mounted() {
    this.filteredAllContacts = this.allContacts.filter((i) => i.type === "contact");
  },
  methods: {
    updateContactData(newVal) {
      if (isDataChanged(newVal, this.contactData)) {
        this.$emit("setContact", unlink(newVal));
      }
    },
    async handleSelectContact() {
      // const isValid = await this.$refs.validateContactRecord.validate();
      // if (!isValid) {
      //   return;
      // }
      // drop __t because it crashes request
      const { _id, __t, ...contactRecord } = this.allContacts.find((item) => item._id === this.selectedContact);
      this.localContactData = unlink({ contactId: _id, ...contactRecord });
      this.$emit("setContact", { contactId: _id });
      this.closeContactSelect();
    },
    unlinkContact() {
      this.$emit("setContact", unlink({ contactId: null }));
      this.localContactData = {};
      this.localAvatar = {};
      this.closeContactSelect();
    },
    saveAsContact(saveAnyway) {
      this.nameIsRequired = true;
      this.$nextTick(async () => {
        const isValid = await this.$refs.validateContactRecord.validate();
        if (!isValid) {
          return;
        }
        delete this.contactData.__t;
        try {
          const response = await this.axios.post(
            "/api/contacts",
            { ...this.contactData, type: "contact" },
            { params: { saveAnyway: saveAnyway === true } }
          );
          const { _id, ...contactRecord } = response.data;
          this.localContactData = { contactId: _id, ...contactRecord, type: "contact" };
          this.$emit("setContact", { contactId: _id });
          let displayName;
          if (response.data.lastName) {
            displayName = `${response.data.lastName}, ${response.data.name}`;
          } else {
            displayName = `${response.data.name}`;
          }
          if (response.data.company) {
            displayName += ` | ${response.data.company}`;
          }
          this.allContacts.push({
            ...response.data,
            displayName: displayName,
          });
          this.filterAddContactSelect("");
          Message.success("Kontakt gespeichert");
        } catch (error) {
          const errorMessage = get(error, "response.data.message");
          const errorStatus = get(error, "response.status");
          if (errorStatus === 400 && errorMessage === "CONTACT_EXISTS") {
            const message = `Ein Kontakt mit ${[
              this.contactData.name || this.contactData.firstName,
              this.contactData.lastName,
            ].join(" ")}${
              this.contactData.company ? ", " + this.contactData.company : ""
            } besteht schon. Trotzdem Speichern?`;
            MessageBox.confirm(message, {
              confirmButtonText: "Ja",
              cancelButtonText: "Nein",
              type: "warning",
              confirmButtonClass: "el-button--success",
            })
              .then(() => {
                this.saveAsContact(true);
              })
              .catch(() => {});
          }
          throw error;
        } finally {
          this.nameIsRequired = false;
        }
      });
    },
    showContactSelect() {
      this.isContactSelectVisible = true;
    },
    closeContactSelect() {
      this.isContactSelectVisible = false;
      this.selectedContact = "";
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    getTitle() {
      return this.title.replace(" ", "").replace("#", "");
    },
    getMetadata() {
      const metadata = "project_" + this.projectId + "_contacts_" + this.getTitle();
      return metadata;
    },
    removeFile(file) {
      const metadata = this.getMetadata();
      this.contactData.documents = this.contactData.documents.filter((item) => item._id !== file._id);
      this.$emit("deleteFile", `/api/fileupload?filename=${file.name}&metadata=${metadata}`);
    },
    fileUploaded(response) {
      if (!this.contactData.documents) {
        this.contactData.documents = [];
      }
      this.localDocuments.push({ url: response[0].url, name: response[0].name, _id: response[0]._id });
      this.contactData.documents.push({ url: response[0].url, name: response[0].name, _id: response[0]._id });
      this.$nextTick(() => {
        this.$emit("updated");
      });
    },
    showFile(file) {
      if (file.response && file.response[0]) {
        window.open(file.response[0].url, "_blank");
      } else if (file.url) {
        window.open(file.url, "_blank");
      } else {
        console.warn("Could not parse file. File object below.");
        console.log(JSON.stringify(file, null, 2));
      }
    },
    setAvatar(avatarSettings) {
      this.contactData.picture = avatarSettings;
      this.$nextTick(() => {
        this.$emit("updated");
      });
    },
    findContactById(id) {
      return this.allContacts.find((item) => item._id === id);
    },
    filterMethod(query, item) {
      const queries = query.toLowerCase().split(" ");
      const name = get(item, "name", "").toLowerCase();
      const lastName = get(item, "lastName", "").toLowerCase();
      const company = get(item, "company", "").toLowerCase();
      // filter logic defined in comment https://www.goodday.work/t/GvfgaW/s9Pi4V
      return queries.every((q) => name.indexOf(q) > -1 || lastName.indexOf(q) > -1 || company.indexOf(q) > -1);
    },
    filterAddContactSelect(inputValue) {
      this.filteredAllContacts = this.allContacts.filter(
        (item) => item.type === "contact" && this.filterMethod(inputValue, item)
      );
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    isEditMode() {
      return !this.contactData.contactId && !this.disabled;
    },
  },
  watch: {
    "allContacts.length": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filteredAllContacts = this.allContacts.filter((i) => i.type === "contact");
      }
    },
    // fixes flickering of contact record when editing input fields
    "contactData.picture": {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.url !== this.localAvatar.url) {
          this.localAvatar = { ...newVal };
        }
      },
    },
    "localContactData.picture": {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.url !== this.localAvatar.url) {
          this.localAvatar = { ...newVal };
        }
      },
    },
    localContactData: {
      handler: function (newVal, oldVal) {
        if (!newVal.contactId) {
          this.debounceUpdateContactData(newVal);
        }
      },
      deep: true,
    },
    contactData: {
      handler: function (newVal, oldVal) {
        if (newVal.contactId) {
          if (newVal.contactId !== this.localContactData.contactId) {
            const { _id, __t, ...contactRecord } = unlink(this.findContactById(newVal.contactId));
            this.localContactData = { contactId: _id, ...contactRecord };
          }
        } else {
          this.localContactData = unlink(newVal);
        }
      },
      deep: true,
    },
    "contactData.documents": {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length !== this.localDocuments.length) {
          this.localDocuments = unlink(newVal);
        }
      },
    },
    "contactData.contactType": {
      handler: function (newVal, oldVal) {
        if (!this.typeSelectionOptions) {
          return;
        }
        if (!oldVal || oldVal.contactTypeName || newVal.contactTypeName !== oldVal.contactTypeName) {
          const newContactTypeName = this.typeSelectionOptions.find((item) => item.value === newVal) || {};
          this.contactData.contactTypeName = newContactTypeName.label;
        }
      },
    },
  },
};
</script>

<style>
.contact-flex {
  display: flex;
  flex-wrap: nowrap;
}
.contact-title {
  display: flex;
  align-items: center;
}
</style>
