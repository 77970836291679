<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.smaCalendarSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('quickProjectSetup')"
        v-model="value.quickProjectSetup"
        :description="$t('quickProjectSetupDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('invoices')"
        v-model="value.invoices"
        :description="$t('invoicesDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('hotelInfo')"
        v-model="value.hotelInfo"
        :description="$t('hotelInfoDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('departureTimes')"
        v-model="value.departureTimes"
        :description="$t('departureTimesDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('projectInfos')"
        v-model="value.projectInfos"
        :description="$t('userGroupProfile.projectInfosDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('projectDocuments')"
        v-model="value.projectDocuments"
        :description="$t('userGroupProfile.projectDocumentsDescription')"
        :booleanMode="true"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('Notes Privileged user')"
        :value="notesPrivileged"
        :description="$t('userGroupProfile.notesPrivilegedUserDescription')"
        :booleanMode="true"
        @input="$emit('notesPrivilegedChange', $event)"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        :label="$t('ToDos Privileged user')"
        :value="todosPrivileged"
        :description="$t('userGroupProfile.todosPrivilegedUserDescription')"
        :booleanMode="true"
        @input="$emit('todosPrivilegedChange', $event)"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsTable from "./AccessRightsTable.vue";
import AccessRightsRow from "./AccessRightsRow.vue";

export default {
  name: "sma-access",
  components: {
    AccessRightsTable,
    AccessRightsRow,
  },
  props: {
    value: { type: Object, default: () => ({}) },
    notesPrivileged: { type: Boolean },
    todosPrivileged: { type: Boolean },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style>
</style>