<template>
  <widget-tab
    :title="
      $t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.gltigkeitVonRessourcendokumenten')
    "
    :hasFilters="true"
  >
    <template #filters>
      <div class="filter-wrapper">
        <div class="form-group" style="flex-basis: 160px">
          <profile-input-number
            :editMode="true"
            clearable
            v-model="nextWeeks"
            :min="4"
            :max="16"
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.nchstenWochen')"
            :placeholder="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.nchstenWochen')"
          />
        </div>
        <div class="form-group">
          <profile-input
            :editMode="true"
            clearable
            v-model="filterType"
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.doktyp')"
            :placeholder="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.doktyp')"
          />
        </div>
        <div class="form-group">
          <profile-input
            :editMode="true"
            clearable
            v-model="filterName"
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.dokname')"
            :placeholder="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.dokname')"
          />
        </div>
        <div class="form-group">
          <profile-select
            :multiple="false"
            :editMode="true"
            v-model="activeTab"
            :items="tabOptions"
            :title="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.resource')"
          />
        </div>
      </div>
    </template>
    <div class="p-3 text-center" v-if="!tabOptions.length">
      Sie haben keine Berechtigung, Ressource Daten zu bearbeiten
    </div>
    <el-tabs v-model="activeTab" class="header-hidden stretch-height" v-else>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.mitarbeiter')"
        name="employee"
      >
        <div style="height: 100%">
          <el-table
            class="d-widget-table"
            :data="filteredDocuments"
            style="width: 100%"
            max-height="auto"
            :row-class-name="getRowClassName"
          >
            <el-table-column
              width="120"
              :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.resource')"
              :formatter="currentResourceFormatter"
            >
            </el-table-column>
            <el-table-column
              :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.name')"
              prop="resourceName"
            >
            </el-table-column>
            <el-table-column
              :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.doktyp')"
              prop="type"
              :formatter="typeFormatter"
            >
            </el-table-column>
            <el-table-column
              :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.dokname')"
              prop="name"
            >
            </el-table-column>
            <!-- <el-table-column :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.freiText')" prop="freeText">
            <template v-slot="scope">
              <div v-if="scope.row.freeText" class="overflow-ellipsis" style="width: 100%">
                {{ scope.row.freeText }}
              </div>
              <div v-else>
                <el-button type="text" @click="addFreeText(scope.row._id)">{{ $t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.freiText') }}</el-button>
              </div>
            </template>
          </el-table-column> -->
            <el-table-column
              :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.luftAbAm')"
              width="145"
              prop="expiresAt"
              :formatter="dateFormatter"
            >
            </el-table-column>
            <el-table-column
              prop="type"
              width="50"
              :formatter="typeFormatter"
              v-if="(accessRights === 'manage' || accessRights === 'full') && checkAccessToResource('employee')"
            >
              <template v-slot="scope">
                <router-link :to="getLinkToResource(scope.row)" style="font-size: 1.4rem">
                  <open-in-new-icon />
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.maschinen')"
        name="machine"
      >
        <el-table
          class="d-widget-table"
          :data="filteredDocuments"
          style="width: 100%"
          max-height="auto"
          size="small"
          :row-class-name="getRowClassName"
        >
          <el-table-column
            width="120"
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.resource')"
            :formatter="currentResourceFormatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.name')"
            prop="resourceName"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.seriennummer')"
            prop="serialNumber"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.doktyp')"
            prop="type"
            :formatter="typeFormatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.dokname')"
            prop="name"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.luftAbAm')"
            width="145"
            prop="expiresAt"
            :formatter="dateFormatter"
          ></el-table-column>
          <el-table-column
            prop="type"
            width="50"
            :formatter="typeFormatter"
            v-if="(accessRights === 'manage' || accessRights === 'full') && checkAccessToResource('machine')"
          >
            <template v-slot="scope">
              <router-link :to="getLinkToResource(scope.row)" style="font-size: 1.4rem">
                <open-in-new-icon />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.kfz')"
        name="vehicle"
      >
        <el-table
          class="d-widget-table"
          :data="filteredDocuments"
          style="width: 100%"
          max-height="auto"
          size="small"
          :row-class-name="getRowClassName"
        >
          <el-table-column
            width="120"
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.resource')"
            :formatter="currentResourceFormatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.name')"
            prop="resourceName"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.kennzeichen')"
            prop="registration"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.doktyp')"
            prop="type"
            :formatter="typeFormatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.dokname')"
            prop="name"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.luftAbAm')"
            width="145"
            prop="expiresAt"
            :formatter="dateFormatter"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            width="50"
            :formatter="typeFormatter"
            v-if="(accessRights === 'manage' || accessRights === 'full') && checkAccessToResource('vehicle')"
          >
            <template v-slot="scope">
              <router-link :to="getLinkToResource(scope.row)" style="font-size: 1.4rem">
                <open-in-new-icon />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.rhb')" name="rhb">
        <el-table
          class="d-widget-table"
          :data="filteredDocuments"
          style="width: 100%"
          max-height="auto"
          size="small"
          :row-class-name="getRowClassName"
        >
          <el-table-column
            width="120"
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.resource')"
            :formatter="currentResourceFormatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.name')"
            prop="resourceName"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.seriennummer')"
            prop="serialNumber"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.doktyp')"
            prop="type"
            :formatter="typeFormatter"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.dokname')"
            prop="name"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.luftAbAm')"
            width="145"
            prop="expiresAt"
            :formatter="dateFormatter"
          >
          </el-table-column>
          <el-table-column
            prop="type"
            width="50"
            :formatter="typeFormatter"
            v-if="(accessRights === 'manage' || accessRights === 'full') && checkAccessToResource('rhb')"
          >
            <template v-slot="scope">
              <router-link :to="getLinkToResource(scope.row)" style="font-size: 1.4rem">
                <open-in-new-icon />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </widget-tab>
</template>

<script>
import { Card, Table, TableColumn, Message, Input, Select, MessageBox, Tabs, TabPane } from "element-ui";
import { moment } from "src/config/moment";
import { debounce, get } from "lodash";
import OpenInNewIcon from "vue-material-design-icons/OpenInNew";
import WidgetTab from "./Widgets/WidgetTab.vue";
import ProfileInputNumber from "src/components/UIComponents/Inputs/ProfileInputNumber";
import { mapState, mapActions } from "vuex";

export default {
  name: "documents-state-widget",
  components: {
    ProfileInputNumber,
    WidgetTab,
    OpenInNewIcon,
    [Card.name]: Card,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Message.name]: Message,
    [Input.name]: Input,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [MessageBox.name]: MessageBox,
  },
  props: {
    accessRights: { type: String },
  },
  data() {
    return {
      loading: false,
      documents: [],
      nextWeeks: 4,
      activeTab: "",
      allTabOptions: [
        { label: "Mitarbeiter", value: "employee" },
        { label: "Maschinen", value: "machine" },
        { label: "KFZ", value: "vehicle" },
        { label: "RHB", value: "rhb" },
      ],
      filterType: "",
      filterName: "",
      filteredDocuments: [],
    };
  },
  created() {
    this.debounceFilterDocuments = debounce(this.filterDocuments, 400);
    this.debounceFetch = debounce(this.fetch, 400);
  },
  mounted() {
    this.setFirstAvailableTab();
    this.fetch();
  },
  beforeUnmount() {
    // Reset the specific widget data this component was using
    this.resetWidget('documentsState');    
  },
  methods: {
    checkAccessToResource(resourceType) {
      return !!get(this.appAccessRights, `${resourceType}.generalAccess`);
    },
    ...mapActions("widget", ["widgetLoaded","resetWidget"]),
    async fetch() {
      try {
        this.loading = true;
        const validUntil = moment().add(this.nextWeeks, "weeks");
        const response = await this.axios.post("/api/documents/expiration", { until: validUntil });
        const [employees, machines, vehicles, rhbs] = await Promise.all([
          this.axios.get("/api/employees/meta").then((res) => res.data.filter((item) => item.active)),
          this.axios.get("/api/machines/meta").then((res) => res.data.filter((item) => item.active)),
          this.axios.get("/api/vehicles/meta").then((res) => res.data.filter((item) => item.active)),
          this.axios.get("/api/rhb/meta").then((res) => res.data.filter((item) => item.active)),
        ]);
        // provide documents with resource-specific fields
        const docsWithNecessaryFields = response.data.reduce((array, doc) => {
          const expandedDoc = { ...doc };
          let resourceEntry;
          if (doc.model === "employee") {
            resourceEntry = employees.find((item) => item.id === doc.modelId);
            if (resourceEntry) {
              expandedDoc.resourceName = `${resourceEntry.firstName} ${resourceEntry.lastName}`;
            }
          } else if (doc.model === "machine") {
            resourceEntry = machines.find((item) => (item.id ? item.id === doc.modelId : item._id === doc.modelId));
            if (resourceEntry) {
              expandedDoc.resourceName = resourceEntry.name;
              expandedDoc.serialNumber = resourceEntry.serialNumber;
            }
          } else if (doc.model === "vehicle") {
            resourceEntry = vehicles.find((item) => (item.id ? item.id === doc.modelId : item._id === doc.modelId));
            if (resourceEntry) {
              expandedDoc.resourceName = resourceEntry.name;
              expandedDoc.registration = resourceEntry.registration;
            }
          } else if (doc.model === "rhb") {
            resourceEntry = rhbs.find((item) => (item.id ? item.id === doc.modelId : item._id === doc.modelId));
            if (resourceEntry) {
              expandedDoc.resourceName = resourceEntry.name;
            }
          }
          // show only active resources
          if (resourceEntry?.active) {
            array.push(expandedDoc);
          }
          return array;
        }, []);

        this.documents = docsWithNecessaryFields;
        this.notifyTopWidget(docsWithNecessaryFields);
        this.filterDocuments();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    notifyTopWidget(data) {
      const today = new Date();
      const expiredDocsNumber = data.filter((item) => moment(item.expiresAt).isBefore(today)).length;
      const expiredDocs = {
        labelTemplate: `abgelaufen`,
        color: "#fbc658",
        number: expiredDocsNumber,
      };

      const DAYS = 7;
      const goingToExpireDocsNumber = data.filter((item) => {
        if (!item.expiresAt) {
          return false;
        }
        const diff = moment(item.expiresAt).diff(today, "day");
        if (diff > 0 && diff < DAYS) {
          return true;
        } else {
          return false;
        }
      }).length;
      const goingToExpireDocs = {
        labelTemplate: `läuft innerhalb der nächsten {variable} Tage ab`,
        color: "#fbc658",
        variable: DAYS,
        number: goingToExpireDocsNumber,
      };
      //using vuex instead of event bus now
      this.widgetLoaded({ type: "documentsState", payload: [expiredDocs, goingToExpireDocs] });
    },
    currentResourceFormatter() {
      return this.tabOptions.find((item) => item.value === this.activeTab).label;
    },
    typeFormatter(record) {
      if (record.type && record.type.label) {
        return record.type.label;
      }
      return "N/A";
    },
    dateFormatter(record) {
      if (record.expiresAt) {
        return moment(record.expiresAt).format("L");
      }
      return "N/A";
    },
    filterDocuments() {
      let activeTabStr = this.activeTab;
      let filterTypeStr = this.filterType.toLowerCase();
      let filterNameStr = this.filterName.toLowerCase();
      const documentsOfType = this.documents.filter((item) => activeTabStr === item.model);
      if (!filterTypeStr && !filterNameStr) {
        this.filteredDocuments = documentsOfType;
        return;
      }
      const filteredDocuments = documentsOfType.filter((doc) => {
        let matches = false;

        if (filterNameStr && doc.name && doc.name.toLowerCase().indexOf(filterNameStr) !== -1) {
          matches = true;
        }
        if (filterTypeStr && doc.type && doc.type.label && doc.type.label.toLowerCase().indexOf(filterTypeStr) !== -1) {
          matches = true;
        }
        return matches;
      });
      this.filteredDocuments = filteredDocuments;
    },
    async addFreeText(docId) {
      try {
        const freeText = await MessageBox.prompt("Please input Notiz", "Notiz", {
          confirmButtonText: "Hinzufügen",
          cancelButtonText: "Abbrechen",
        })
          .then(({ value }) => {
            return value;
          })
          .catch((e) => {
            return false;
          });
        if (freeText) {
          const response = await this.axios.put(`/api/documents/${docId}`, { freeText });
          const docIdx = this.documents.findIndex((item) => item._id === docId);
          if (docIdx !== -1) {
            this.documents[docIdx].freeText = response.data.freeText;
          }
          this.filterDocuments();
        }
      } catch (error) {
        throw error;
      }
    },
    getLinkToResource(data) {
      return { path: `/${data.model}/profile/${data.modelId}`, query: { tab_pane: "documents" } };
    },
    getRowClassName({ row }) {
      if (moment(row.expiresAt).diff(new Date(), "day") < 7) {
        return "expiration-near";
      }
    },
    setFirstAvailableTab() {
      if (this.tabOptions.length) {
        this.activeTab = this.tabOptions[0].value;
      }
    },
  },
  computed: {
    ...mapState("account", { appAccessRights: "accessRights" }),
    tabOptions() {
      return this.allTabOptions.filter((item) => this.checkAccessToResource(item.value));
    },
  },
  watch: {
    activeTab() {
      this.filterDocuments();
    },
    filterType() {
      this.debounceFilterDocuments();
    },
    filterName() {
      this.debounceFilterDocuments();
    },
    nextWeeks() {
      this.debounceFetch();
    },
  },
};
</script>

<style>
.filter-wrapper {
  display: flex;
  justify-content: flex-start;
}
.filter-wrapper .form-group {
  margin-right: 5px;
}
tr.expiration-near {
  color: #eb4747 !important;
}
.header-hidden .el-tabs__header {
  display: none;
}
</style>
