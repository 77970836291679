import countryList from "src/country-list.json";

export const linksMap = {
  employee: "/api/employees",
  machine: "/api/machines",
  vehicle: "/api/vehicles",
  rhb: "/api/rhb",
};
/**
 * Schema is used to parse records and generate list of data to set up empty fields that can be filled.
 * [key] - resource type of given record. corresponds to "linksMap"
 * [key][propertyName] - propname of record. can be set as path like "hrOffice.phone" which tells that "phone"
 * property is nested in "hrOffise" object. Here you should list all props that have to be filled.
 * [key][propertyName] value - configuration for input:
 *   type - specifies input type.
 *     Available values:
 *      input_text - regular inputfield
 *      select - selection element with pre-defined list of options. Options are specified through
 *        "options" property like the following [{ id: 1, name: 'Foo' }]
 *      date - datepicker
 *      model_type - selection of options that have to be fetched from remote from modeltype_collection.
 *        "modelType" and "modelId" properties are mandatory and stand for corresponding fields in ModelType
 *        record. Used to create url.
 *        "valueType" - currently supports only "list" value which means that selection produces a list of
 *        picked options (is a multiselection).
 */
export const emptyFieldsSchema = {
  employee: {
    firstName: { type: "input_text", label: "Vorname", accessRightName: "general" },
    lastName: { type: "input_text", label: "Nachname", accessRightName: "general" },
    nationality: { type: "select", options: countryList, label: "Land", accessRightName: "general" },
    dateOfEntering: { type: "date", label: "Eintrittsdatum", accessRightName: "general" },
    dateOfLeaving: { type: "date", label: "Austrittsdatum", accessRightName: "general" },
    sex: {
      type: "select",
      label: "Geschlecht",
      options: [
        { name: "divers", id: "divers" },
        { name: "männlich", id: "männlich" },
        { name: "weiblich", id: "weiblich" },
      ],
      accessRightName: "general",
    },
    companyRole: {
      type: "select",
      label: "Rolle",
      options: [
        { name: "Bauleitung", id: "Bauleitung" },
        { name: "Gewerblich", id: "Gewerblich" },
        { name: "Verwaltung", id: "Verwaltung" },
      ],
    },
    nickname: { type: "input_text", label: "Rufname", accessRightName: "general" },
    employeeNumber: { type: "input_number", label: "Personalnummer", accessRightName: "general" },
    selectedPosition: {
      type: "positions",
      label: "Position",
      modelType: "employee",
      modelId: "position",
      accessRightName: "general",
    },
    workingqualificationIDList: {
      type: "model_type",
      label: "Arbeits-Qualifikationen",
      modelType: "employee",
      modelId: "qualification",
      valueType: "list",
      accessRightName: "general",
    },
    certificateIDList: {
      type: "model_type",
      label: "Zertifikate",
      modelType: "employee",
      modelId: "certificate",
      valueType: "list",
      accessRightName: "general",
    },
    confirmationIDList: {
      type: "model_type",
      label: "Bescheinigungen",
      modelType: "employee",
      modelId: "confirmation",
      valueType: "list",
      accessRightName: "general",
    },
    licenceIDList: {
      type: "model_type",
      label: "Führerscheine",
      modelType: "employee",
      modelId: "licence",
      valueType: "list",
      accessRightName: "general",
    },
    birthday: { type: "date", label: "Geburtstag", accessRightName: "privateData" },
    "hrOffice.phone": { type: "input_text", label: "Office Telefon", accessRightName: "general" },
    "hrOffice.mobile": { type: "input_text", label: "Office Handy", accessRightName: "general" },
    "hrOffice.fax": { type: "input_text", label: "Office Fax", accessRightName: "general" },
    "hrOffice.emergencyNumber": { type: "input_text", label: "Office Notfallnummer", accessRightName: "general" },
    "hrOffice.emergencyNumberInfo": {
      type: "input_text",
      label: "Office Info Notfallnummer",
      accessRightName: "general",
    },
    "hrOffice.street": { type: "input_text", label: "Office Straße", accessRightName: "general" },
    "hrOffice.no": { type: "input_text", label: "Office Hausnummer", accessRightName: "general" },
    "hrOffice.zip": { type: "input_text", label: "Office PLZ", accessRightName: "general" },
    "hrOffice.city": { type: "input_text", label: "Office Stadt", accessRightName: "general" },
    "hrOffice.addressAddition": { type: "input_text", label: "Office Adresszusatz", accessRightName: "general" },
    "hrOffice.email": { type: "input_text", label: "Office Email", accessRightName: "general" },
    "hrOffice.country": { type: "select", options: countryList, label: "Office Land", accessRightName: "general" },
    "hrPrivate.phone": { type: "input_text", label: "Private Telefon", accessRightName: "privateData" },
    "hrPrivate.mobile": { type: "input_text", label: "Private Handy", accessRightName: "privateData" },
    "hrPrivate.fax": { type: "input_text", label: "Private Fax", accessRightName: "privateData" },
    "hrPrivate.emergencyNumber": { type: "input_text", label: "Private Notfallnummer", accessRightName: "privateData" },
    "hrPrivate.street": { type: "input_text", label: "Private Straße", accessRightName: "privateData" },
    "hrPrivate.no": { type: "input_text", label: "Private Hausnummer", accessRightName: "privateData" },
    "hrPrivate.zip": { type: "input_text", label: "Private PLZ", accessRightName: "privateData" },
    "hrPrivate.city": { type: "input_text", label: "Private Stadt", accessRightName: "privateData" },
    "hrPrivate.addressAddition": { type: "input_text", label: "Private Adresszusatz", accessRightName: "privateData" },
    "hrPrivate.email": { type: "input_text", label: "Private Email", accessRightName: "privateData" },
    "hrPrivate.country": {
      type: "select",
      options: countryList,
      label: "Private Land",
      accessRightName: "privateData",
    },
    "hrForeign.phone": { type: "input_text", label: "Ausland Telefon", accessRightName: "privateDataAbroad" },
    "hrForeign.mobile": { type: "input_text", label: "Ausland Handy", accessRightName: "privateDataAbroad" },
    "hrForeign.fax": { type: "input_text", label: "Ausland Fax", accessRightName: "privateDataAbroad" },
    "hrForeign.emergencyNumber": {
      type: "input_text",
      label: "Ausland Notfallnummer",
      accessRightName: "privateDataAbroad",
    },
    "hrForeign.street": { type: "input_text", label: "Ausland Straße", accessRightName: "privateDataAbroad" },
    "hrForeign.no": { type: "input_text", label: "Ausland Hausnummer", accessRightName: "privateDataAbroad" },
    "hrForeign.zip": { type: "input_text", label: "Ausland PLZ", accessRightName: "privateDataAbroad" },
    "hrForeign.city": { type: "input_text", label: "Ausland Stadt", accessRightName: "privateDataAbroad" },
    "hrForeign.addressAddition": {
      type: "input_text",
      label: "Ausland Adresszusatz",
      accessRightName: "privateDataAbroad",
    },
    "hrForeign.email": { type: "input_text", label: "Ausland Email", accessRightName: "privateDataAbroad" },
    "hrForeign.country": {
      type: "select",
      options: countryList,
      label: "Ausland Land",
      accessRightName: "privateDataAbroad",
    },
  },
  machine: {
    startDate: { type: "date", label: "Start datum", accessRightName: "general" },
    description: { type: "input_text", label: "Beschreibung", accessRightName: "general" },
    machineNumber: { type: "input_text", label: "Maschinen-Nr", accessRightName: "general" },
    serialNumber: { type: "input_text", label: "Seriennummer", accessRightName: "general" },
    transportWeight: { type: "input_text", label: "Transportgewicht", accessRightName: "general" },
    transportWeight: { type: "input_text", label: "Betriebsgewicht", accessRightName: "general" },
    length: { type: "input_text", label: "Länge", accessRightName: "general" },
    minWidth: { type: "input_text", label: "Breite min", accessRightName: "general" },
    maxWidth: { type: "input_text", label: "Breite max", accessRightName: "general" },
    minHeight: { type: "input_text", label: "Höhe min", accessRightName: "general" },
    yearOfConstruction: { type: "input_text", label: "Baujahr", accessRightName: "general" },
    kilowatts: { type: "input_text", label: "KW", accessRightName: "general" },
    selectedServiceSpectrumList: {
      type: "model_type",
      label: "Leistungsspektrum",
      modelType: "global",
      modelId: "serviceSpectrum",
      valueType: "list",
      accessRightName: "general",
    },
    // selectedRHBList: [{ type: mongoose.Types.ObjectId, ref: 'RHB' }],
    // selectedSupplyList: [{ type: mongoose.Types.ObjectId, ref: 'Supply' }],
    selectedProductGroup1: {
      type: "product_group",
      label: "Warengruppe 1",
      modelType: "global",
      accessRightName: "general",
    },
    selectedProductGroup2: {
      type: "product_group",
      label: "Warengruppe 2",
      modelType: "global",
      accessRightName: "general",
    },
  },
  vehicle: {
    description: { type: "input_text", label: "Beschreibung", accessRightName: "general" },
    registration: { type: "input_text", label: "Kennzeichen", accessRightName: "general" },
    manufacturer: { type: "input_text", label: "Hersteller", accessRightName: "general" },
    model: { type: "input_text", label: "Modell", accessRightName: "general" },
    type: { type: "input_text", label: "Typ", accessRightName: "general" },
    numberSeats: { type: "input_number", label: "Anzahl Sitze", accessRightName: "general" },
    vehicleNumber: { type: "input_text", label: "Fahrzeugnummer", accessRightName: "general" },
    firstRegistration: { type: "input_text", label: "Erstzulassung", accessRightName: "general" },
    unladenWeight: { type: "input_text", label: "Leergewicht", accessRightName: "general" },
    grossVehicleWeight: { type: "input_text", label: "Zulässiges Gesamtgewicht", accessRightName: "general" },
    height: { type: "input_text", label: "Höhe", accessRightName: "general" },
    width: { type: "input_text", label: "Breite", accessRightName: "general" },
    length: { type: "input_text", label: "Länge", accessRightName: "general" },
    workload: { type: "input_text", label: "Nutzlast", accessRightName: "general" },
    requiredDrivingLicences: {
      type: "model_type",
      label: "Benötigte Führerscheine",
      modelType: "employee",
      modelId: "licence",
      valueType: "list",
      accessRightName: "general",
    },
    selectedProductGroup1: {
      type: "product_group",
      label: "Warengruppe 1",
      modelType: "vehicle",
      accessRightName: "general",
    },
    selectedProductGroup2: {
      type: "product_group",
      label: "Warengruppe 2",
      modelType: "vehicle",
      accessRightName: "general",
    },
  },
  rhb: {
    description: { type: "input_text", label: "Beschreibung", accessRightName: "general" },
    partNumber: { type: "input_text", label: "Teilenummer", accessRightName: "general" },
    height: { type: "input_text", label: "Länge", accessRightName: "general" },
    width: { type: "input_text", label: "Breite", accessRightName: "general" },
    length: { type: "input_text", label: "Höhe", accessRightName: "general" },
    weight: { type: "input_text", label: "Gewicht", accessRightName: "general" },
    selectedServiceSpectrumList: {
      type: "model_type",
      label: "Leistungsspektrum",
      modelType: "global",
      modelId: "serviceSpectrum",
      valueType: "list",
      accessRightName: "general",
    },
    selectedProductGroup1: {
      type: "product_group",
      label: "Warengruppe 1",
      modelType: "rhb",
      accessRightName: "general",
    },
    selectedProductGroup2: {
      type: "product_group",
      label: "Warengruppe 2",
      modelType: "rhb",
      accessRightName: "general",
    },
  },
};
