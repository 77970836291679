<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.administration.usergroup.index.administration")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            $t("src.components.administration.usergroup.index.benutzergruppenListe")
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="20">
        <div class="n-view-title">{{ $t("src.components.administration.usergroup.index.benutzergruppenListe") }}</div>
      </el-col>

      <el-col :span="4" class="text-right">
        <new-listitem-button v-if="$can('create', 'usergroup')" to="/administration/user-groups/profile/new">{{
          $t("src.components.administration.usergroup.index.neueBenutzergruppe")
        }}</new-listitem-button>
      </el-col>
    </el-row>

    <index-table
      authSubject="usergroup"
      :showViewButton="false"
      :propsToSearch="propsToSearch"
      modelType="user-groups"
      :extendedColumns="extendedColumns"
      :supressColumns="supressColumns"
      :suppressActiveButtons="true"
      :enabledActiveFilter="false"
      :defaultSort="{ prop: 'name', order: 'ascending' }"
    >
      <!-- NAME COLUMN -->
      <template v-slot:Name="props">
        <el-row type="flex" align="middle">
          <el-col>
            <router-link
              v-if="$can('read', 'usergroup')"
              :to="`/administration/user-groups/profile/view/${props.data.row.id}`"
            >
              {{ props.data.row.name }}
            </router-link>
            <span v-else>{{ props.data.row.name }}</span>
          </el-col>
        </el-row>
      </template>
      <template v-slot:Beschreibung="props">{{ props.data.row.description }}</template>
    </index-table>
  </div>
</template>

<script>
import { IndexTable, NewListItemButton } from "src/components/UIComponents";

export default {
  data() {
    return {
      supressColumns: ["pictures", "status", "dispo"],
      extendedColumns: [
        {
          label: "Name",
          key: "name",
          minWidth: "200",
        },
      ],
      propsToSearch: ["name"],
    };
  },
  components: {
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
  },
};
</script>

<style></style>
