import moment from "moment";
import 'moment/locale/de';
import { extendMoment } from "moment-range";

extendMoment(moment);

moment.locale('de');

/**
 * @exports moment.Moment
 */
export { moment };

