/**
 * Formats string with comma decimal separator to number
 * @param {string} n = string in german locale like "1,000,012.25"
 * @returns {number}
 */
export const stringToNumber = (n) => {
  if (n) {
    n = n.replace(/\./g, "").replace(",", ".");
    return parseFloat(n);
  } else {
    return 0;
  }
};
