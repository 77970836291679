<template>
  <div v-loading="loading" style="min-height: 100vh">
    <template v-if="permissionsLoaded">
      <router-view name="header"></router-view>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      <router-view name="footer"></router-view>
      <birthday-reminders />
      <granularity-settings />
      <pdf-viewer-dialog />
    </template>
    <div v-else>
      <div v-show="isVisible">
        <el-empty description="Du hast keine zugewiesenen Rechte" v-show="isVisible" />
        <div class="text-center">
          <el-button type="primary" @click.prevent="$keycloak.logout">Log out</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Loading some plugin css asynchronously
import("sweetalert2/dist/sweetalert2.css");
import("vue-material-design-icons/styles.css");
import "@bryntum/scheduler/scheduler.material.css";
import BirthdayReminders from "./components/BirthdayReminders";
import GranularitySettings from "./components/GranularitySettings";
import PdfViewerDialog from "./components/UIComponents/PdfViewer/PdfViewerDialog";
import { updateToken } from "./updateToken";
import { mapGetters, mapMutations, mapState } from "vuex";
import { Empty } from "element-ui";
// setup pdfjs service worker
import { GlobalWorkerOptions, version } from "pdfjs-dist";
// GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.mjs`;
// NOTE: PDF worker is hosted locally, so when updating pdfjs-dist make sure to also take the pdf.worker.mjs and put the updated content in "pdf-worker.js"
GlobalWorkerOptions.workerSrc = `${window.location.origin}/${process.env.BASE_URL}/static/pdf-worker.js`;
// GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

export default {
  components: {
    [PdfViewerDialog.name]: PdfViewerDialog,
    [BirthdayReminders.name]: BirthdayReminders,
    [GranularitySettings.name]: GranularitySettings,
    [Empty.name]: Empty,
  },
  data() {
    return { isVisible: false, loading: false };
  },
  created() {
    (async () => {
      try {
        this.loading = true;
        await this.axios.get("/api/access").then((result) => {
          this.$store.dispatch("account/setAccessRights", result.data);
        });
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    })();
  },
  async mounted() {
    await this.$store.dispatch("customerSettings/fetch");
    document.documentElement.style.setProperty("--project-progress-1", this.colorSettings.progress_1);
    document.documentElement.style.setProperty("--project-progress-2", this.colorSettings.progress_2);
    document.documentElement.style.setProperty("--project-progress-3", this.colorSettings.progress_3);

    setTimeout(() => {
      if (!this.permissionsLoaded) {
        this.showPlaceholder();
      }
    }, 3000);
  },
  methods: {
    ...mapMutations("versions", ["setServerVersion"]),
    showPlaceholder() {
      this.isVisible = true;
    },
    setRealmSettings(realmName) {
      return fetch(process.env.VUE_APP_FRONTDOOR_URL, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify({ name: realmName }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === "OK") {
            localStorage.setItem("realm", response.realm);
            localStorage.setItem("api", response.api);
            this.axios.defaults.baseURL = response.api;
          }
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  beforeMount() {
    const realmName = localStorage.getItem("realm");
    this.setRealmSettings(realmName);
    window.setRealmSettings = this.setRealmSettings;
  },
  computed: {
    ...mapState("account", ["status", "permissionsLoaded", "accessRightsLoaded"]),
    ...mapGetters("customerSettings", ["colorSettings"]),
  },
  watch: {
    $route() {
      updateToken();
    },
  },
};
</script>
<style>
body .vc-container {
  --white: #ffffff;
  --black: #000000;
  --alpha: 0.3;

  --gray-100: rgba(247, 250, 252, var(--alpha));
  --gray-200: rgba(237, 242, 247, var(--alpha));
  --gray-300: rgba(226, 232, 240, var(--alpha));
  --gray-400: rgba(203, 213, 224, var(--alpha));
  --gray-500: rgba(160, 174, 192, var(--alpha));
  --gray-600: rgba(113, 128, 150, 1);
  --gray-700: rgba(74, 85, 104, 1);
  --gray-800: rgba(45, 55, 72, 1);
  --gray-900: rgba(26, 32, 44, 1);

  --red-100: rgba(#fff5f5, var(--alpha));
  --red-200: rgba(#fed7d7, var(--alpha));
  --red-300: rgba(#feb2b2, var(--alpha));
  --red-400: rgba(#fc8181, var(--alpha));
  --red-500: rgba(#f56565, var(--alpha));
  --red-600: rgba(#e53e3e, var(--alpha));
  --red-700: rgba(#c53030, var(--alpha));
  --red-800: rgba(#9b2c2c, var(--alpha));
  --red-900: rgba(#742a2a, var(--alpha));

  --orange-100: rgba(#fffaf0, var(--alpha));
  --orange-200: rgba(#feebc8, var(--alpha));
  --orange-300: rgba(#fbd38d, var(--alpha));
  --orange-400: rgba(#f6ad55, var(--alpha));
  --orange-500: rgba(#ed8936, var(--alpha));
  --orange-600: rgba(#dd6b20, var(--alpha));
  --orange-700: rgba(#c05621, var(--alpha));
  --orange-800: rgba(#9c4221, var(--alpha));
  --orange-900: rgba(#7b341e, var(--alpha));

  --yellow-100: rgba(#fffff0, var(--alpha));
  --yellow-200: rgba(#fefcbf, var(--alpha));
  --yellow-300: rgba(#faf089, var(--alpha));
  --yellow-400: rgba(#f6e05e, var(--alpha));
  --yellow-500: rgba(#ecc94b, var(--alpha));
  --yellow-600: rgba(#d69e2e, var(--alpha));
  --yellow-700: rgba(#b7791f, var(--alpha));
  --yellow-800: rgba(#975a16, var(--alpha));
  --yellow-900: rgba(#744210, var(--alpha));

  --green-100: rgba(#f0fff4, var(--alpha));
  --green-200: rgba(#c6f6d5, var(--alpha));
  --green-300: rgba(#9ae6b4, var(--alpha));
  --green-400: rgba(#68d391, var(--alpha));
  --green-500: rgba(#48bb78, var(--alpha));
  --green-600: #38a169;
  --green-700: rgba(#2f855a, var(--alpha));
  --green-800: rgba(#276749, var(--alpha));
  --green-900: rgba(#22543d, var(--alpha));

  --teal-100: rgba(230, 255, 250, 0.8);
  --teal-200: rgba(178, 245, 234, 0.8);
  --teal-300: rgba(129, 230, 217, 0.8);
  --teal-400: rgba(75, 209, 195, 0.8);
  --teal-500: rgba(56, 178, 172, 0.8);
  --teal-600: rgba(49, 151, 149, 1);
  --teal-700: rgba(44, 122, 123, 1);
  --teal-800: rgba(40, 94, 97, 1);
  --teal-900: rgba(35, 78, 82, 1);

  --blue-100: rgba(20, 110, 213, var(--alpha));
  --blue-200: rgba(40, 93, 172, var(--alpha));
  --blue-300: rgba(26, 114, 196, var(--alpha));
  --blue-400: rgba(26, 87, 152, var(--alpha));
  --blue-500: rgba(26, 87, 152, var(--alpha));
  --blue-600: rgba(49, 130, 206, 1);
  --blue-700: rgba(43, 108, 176, 1);
  --blue-800: rgba(44, 82, 130, 1);
  --blue-900: rgba(42, 67, 101, 1);

  --indigo-100: rgba(#ebf4ff, var(--alpha));
  --indigo-200: rgba(#c3dafe, var(--alpha));
  --indigo-300: rgba(#a3bffa, var(--alpha));
  --indigo-400: rgba(#7f9cf5, var(--alpha));
  --indigo-500: rgba(#667eea, var(--alpha));
  --indigo-600: rgba(#5a67d8, var(--alpha));
  --indigo-700: rgba(#4c51bf, var(--alpha));
  --indigo-800: rgba(#434190, var(--alpha));
  --indigo-900: rgba(#3c366b, var(--alpha));

  --purple-100: rgba(250, 245, 255, var(--alpha));
  --purple-200: rgba(233, 216, 253, var(--alpha));
  --purple-300: rgba(214, 188, 250, var(--alpha));
  --purple-400: rgba(183, 148, 244, var(--alpha));
  --purple-500: rgba(159, 122, 234, var(--alpha));
  --purple-600: rgba(128, 90, 213, 1);
  --purple-700: rgba(107, 70, 193, 1);
  --purple-800: rgba(85, 60, 154, 1);
  --purple-900: rgba(68, 51, 122, 1);

  --pink-100: rgba(#fff5f7, var(--alpha));
  --pink-200: rgba(#fed7e2, var(--alpha));
  --pink-300: rgba(#fbb6ce, var(--alpha));
  --pink-400: rgba(#f687b3, var(--alpha));
  --pink-500: rgba(#ed64a6, var(--alpha));
  --pink-600: rgba(#d53f8c, var(--alpha));
  --pink-700: rgba(#b83280, var(--alpha));
  --pink-800: rgba(#97266d, var(--alpha));
  --pink-900: rgba(#702459, var(--alpha));
}
</style>
