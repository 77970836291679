<template>
  <div v-loading="loading" class="n-edit-wrapper">
    <!-- BREADCRUM TRAIL -->
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/project' }">{{
            $t("src.components.project.edit.projektListe")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ projecTitle }} </el-breadcrumb-item>
          <el-breadcrumb-item>{{ activeNameLabel }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex" align="center">
      <el-col :span="12" style="display: flex; align-items: center">
        <div class="n-view-title mr-2" style="display: inline-block">{{ projecTitle }}</div>
        <div class="n-view-title-meta" v-if="project.constructionPhase">BA{{ project.constructionPhase }}</div>
        <div class="n-view-title-meta" v-if="projectDateRange">{{ projectDateRange }}</div>
      </el-col>
      <el-col :span="12" class="profile-buttons-wrapper" style="display: flex; align-items: center">
        <cancel-button icon="el-icon-arrow-left" @click="handleBack">{{
          $t("src.components.project.edit.zurck")
        }}</cancel-button>
        <cancel-button v-if="canGoToProject" @click="handleGoToCalendar">{{
          $t("src.components.project.edit.goToProject")
        }}</cancel-button>
        <cancel-button :disabled="!isProjectRunning" v-if="canGoToProject" @click="handleGoToCalendarToday">{{
          $t("src.components.project.edit.goToProjectToday")
        }}</cancel-button>
        <template v-if="isEditMode">
          <cancel-button v-if="$route.params.id === 'new'" @click="cancelProfile()" />
          <cancel-button v-if="$route.params.id !== 'new'" @click="changeEditMode(false)">{{
            $t("src.components.project.edit.editierenBeenden")
          }}</cancel-button>
          <delete-button v-if="$route.params.id !== 'new'" @click="cancelProfile()" />
          <save-button @click="updateProjectWithConfiguration()" :loading="loading" data-testid="save_project" />
        </template>
        <template v-else>
          <edit-profile-button
            v-if="$can('update', 'project')"
            :editMode="isEditMode"
            @click="changeEditMode(!isEditMode)"
          />
        </template>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" @tab-click="tabClicked" tab-position="left" class="profile-tabs hide-disabled">
      <el-tab-pane
        :label="$t('src.components.project.edit.general')"
        name="general"
        v-show="projectAccess.specificAccess.general"
        :disabled="!projectAccess.specificAccess.general"
      >
        <ValidationObserver ref="validateObserver" slim>
          <perfect-scrollbar :options="{ suppressScrollX: true }" class="_profile-scrollbar">
            <project-profile
              :isEditMode="isEditMode"
              :isNew="isNew"
              :project="project"
              :allContacts="allContacts"
              v-on:updateProject="updateData"
              :projectConfigurationManager="projectConfigurationManager"
              :accessRights="projectAccess.specificAccess.general"
            />
          </perfect-scrollbar>
        </ValidationObserver>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.contacts')"
        name="contacts"
        style="height: 100%"
        lazy
        v-if="!isNew && projectAccess.specificAccess.contacts"
        :disabled="isNew || !projectAccess.specificAccess.contacts"
      >
        <contacts
          :isEditMode="isEditMode"
          :projectId="project.id"
          :allContacts="allContacts"
          ref="contacts"
          v-if="project.id"
          :accessRights="projectAccess.specificAccess.contacts"
        />
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.documents')"
        name="documents"
        lazy
        v-if="!isNew && projectAccess.specificAccess.documents"
        class="ps-no-save-button"
        :disabled="isNew || !projectAccess.specificAccess.documents"
      >
        <document-list
          :isEditMode="isEditMode"
          modelType="project"
          :modelID="project.id"
          :searchByRelative="true"
          @updateResource="updateProject"
          :projectsMeta="projectsMeta"
          :projectDateRange="project.dateRange"
          :showRelationColumn="true"
          relationColumnName="Projekt"
          relationColumnProp="modelId"
          :accessRights="projectAccess.specificAccess.documents"
        ></document-list>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.invoices')"
        name="invoices"
        v-if="!isNew && projectAccess.specificAccess.invoices"
        :disabled="isNew || !projectAccess.specificAccess.invoices"
        class="ps-no-save-button"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <project-invoices-new
            :projectId="$route.params.id"
            :projectDateRange="project.dateRange"
            :isEditMode="isEditMode"
            :projectsMeta="projectsMeta"
            @navigateToCalendar="navigateToCalendar"
            :accessRights="projectAccess.specificAccess.invoices"
          />
        </perfect-scrollbar>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.projectControlling')"
        name="projectControlling"
        :lazy="true"
        class="ps-no-save-button"
        v-if="!isNew && projectAccess.specificAccess.projectControlling"
        :disabled="isNew || !projectAccess.specificAccess.projectControlling"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <project-controlling
            v-if="activeName === 'projectControlling'"
            :projectsMeta="projectsMeta"
            :projectDateRange="project.dateRange"
            :isConstructionPhase="!!project.constructionPhase"
            :isEditMode="isEditMode"
            :projectId="$route.params.id"
            :accessRights="projectAccess.specificAccess.projectControlling"
          />
        </perfect-scrollbar>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.reports')"
        name="reports"
        :lazy="true"
        class="ps-no-save-button"
        v-if="!isNew && projectAccess.specificAccess.reports"
        :disabled="isNew || !projectAccess.specificAccess.reports"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <daily-report
            :isEditMode="isEditMode"
            :projectRange="project.dateRange"
            :projectId="project.id"
            :accessRights="projectAccess.specificAccess.reports"
          ></daily-report>
        </perfect-scrollbar>
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.pickAndPack')"
        name="pickAndPack"
        :disabled="isNew || projectConfigurationManager.loading || !projectAccess.specificAccess.pickAndPack"
        class="ps-no-save-button flex flex-column"
      >
        <pick-pack-tabs
          :project="project"
          :projectAccess="projectAccess"
          :isEditMode="isEditMode"
          :isNew="isNew"
          :isDirty="isDirty"
          :projectConfigurationManager="projectConfigurationManager"
          :refreshPickPack="refreshPickPack"
          :updateProjectWithConfiguration="updateProjectWithConfiguration"
          :updateSuppliesProjectData="updateSuppliesProjectData"
          :switchToPickAndPack="switchToPickAndPack"
        />
      </el-tab-pane>

      <el-tab-pane
        :label="$t('src.components.project.edit.downloadCenter')"
        name="downloadCenter"
        lazy
        class="ps-no-save-button"
        v-if="!isNew && projectAccess.specificAccess.downloadCenter"
        :disabled="isNew || !projectAccess.specificAccess.downloadCenter"
      >
        <perfect-scrollbar :options="{ suppressScrollX: true }">
          <download-center :project="project" :accessRights="projectAccess.specificAccess.downloadCenter" />
        </perfect-scrollbar>
      </el-tab-pane>

      <el-tab-pane
        name="todos"
        :lazy="true"
        class="ps-no-save-button"
        v-if="!isNew && canUseTodos"
        :disabled="isNew || !canUseTodos"
      >
        <template v-slot:label>
          <span>{{ $t("ToDos") }} <el-badge v-if="hasNewTodos" type="success" is-dot /></span>
        </template>
        <todo-list :projectId="project.id" />
      </el-tab-pane>
      <el-tab-pane
        name="notes"
        :lazy="true"
        class="ps-no-save-button"
        v-if="!isNew && canUseNotes"
        :disabled="isNew || !canUseNotes"
      >
        <template v-slot:label>
          <span>{{ $t("Notes") }} <el-badge v-if="hasNewNotes" type="success" is-dot /></span>
        </template>
        <notes-list :projectId="project.id" :projects-meta="projectsMeta" />
      </el-tab-pane>
    </el-tabs>
    <div v-show="false">
      <project-profile-settings />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ValidationObserver } from "vee-validate";
import ProfileSettings from "./ProfileSettings";
import DownloadCenter from "./DownloadCenter/DownloadCenter.vue";
import Contacts from "./Contacts/Contacts.vue";
import Profile from "./Profile.vue";
import Invoices from "./Invoices/Invoices";
import DailyReport from "./DailyReport/DailyReport.vue";
import ProjectControlling from "./ProjectControlling/ProjectControlling.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import Pencil from "vue-material-design-icons/Pencil";
import ProjectConfigurationManager from "src/components/FunctionalComponents/ProjectConfigurationManager";
import PickPackTabs from "./PickPackTabs.vue";
let ProjectConfigurationManagerClass = Vue.extend(ProjectConfigurationManager);
import { mapActions, mapState, mapGetters } from "vuex";
import { moment } from "src/config/moment";
import { Tabs, TabPane, Message, MessageBox, Badge } from "element-ui";
import { get, sortBy } from "lodash";
import { Documents } from "src/components/UIComponents";
import { unlink } from "src/utils/unlink";
import NotesList from "./ProjectNotes/NotesList.vue";
import TodoList from "./TodoList/TodoList.vue";

export default {
  name: "project-edit",
  components: {
    Badge,
    TodoList,
    NotesList,
    ValidationObserver,
    PerfectScrollbar,
    MessageBox,
    Message,
    PickPackTabs,
    [Pencil.name]: Pencil,
    [TabPane.name]: TabPane,
    [Tabs.name]: Tabs,
    [Documents.name]: Documents,
    [Profile.name]: Profile,
    [Invoices.name]: Invoices,
    [Contacts.name]: Contacts,
    [DailyReport.name]: DailyReport,
    [ProjectControlling.name]: ProjectControlling,
    [ProfileSettings.name]: ProfileSettings,
    [DownloadCenter.name]: DownloadCenter,
  },
  async mounted() {
    this.fetchAllContacts();
    this.setFirstTab(this.accessRights);

    try {
      this.loading = true;
      await this.fetchProjectMeta();

      const id = this.$route.params.id; //coming from routing parameter
      const identifier = this.$route.meta.identifier;
      //create or edit mode - edit possible
      if (identifier === "project_edit" || id === "new") {
        this.changeEditMode(true);
      } else {
        //view mode - no editing possible
        this.changeEditMode(false);
      }
      if (id === "new") {
        this.project.inital = false;
        setTimeout(() => {
          this.setPreservedData(this.project);
          this.checkDirtyFlag(this.project);
        }, 500);
      } else {
        const route = `/api/projects/${id}`;
        //get data from api REST call
        await this.axios
          .get(route)
          .then((result) => {
            //set user data loaded from database
            this.project = { ...result.data };
            this.originalData = unlink(result.data);
            //now is data available -> update dependent selections
            //product groups:

            let selectedProductGroups = {
              selectedProductGroup1: this.project.selectedProductGroup1,
              selectedProductGroup2: this.project.selectedProductGroup2,
            };
            this.setSelections(selectedProductGroups);
            this.setPreservedData(result.data);
            this.checkIfNotesIsAvailable(result.data.id);
          })
          .catch(function (error) {
            Message({
              message: error.message,
              type: "error",
            });
          });
        this.projectConfigurationManager.initialize(this.project, this.granularitySettings);
        if (this.$route.query.scrollTo) {
          this.scrollToSection(this.$route.query.scrollTo);
        }
      }

      //check if  we navigate directly to a tab pane
      if (this.$route.params.tab_pane) {
        this.activeName = this.$route.params.tab_pane;
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
    this.$nextTick(() => {
      this.setFirstTab(this.accessRights);
    });
  },
  beforeDestroy() {
    this.discardDirty();
    this.resetTab();
  },
  data() {
    return {
      allContacts: [],
      loading: false,
      activeName: "0",
      previousClickedTabName: "Projektdaten",
      originalData: {},
      allTabNames: [
        "general",
        "contacts",
        "configuration",
        "pickAndPack",
        "invoices",
        "downloadCenter",
        "documents",
        "reports",
        "projectControlling",
        "settings",
      ],
      project: {
        active: true,
        hrClient: {},
        hrWorksiteSupervisor: {},
        hrDivisionManager: {},
        inital: true,
        coreServices: [],
        constructionManagers: [],
        selectedBvDepartures: [],
        selectedSchweglerDepartures: [],
        selectedHazardousSubstances: [],
        workingSchedule: [],
      },
      projectConfigurationManager: new ProjectConfigurationManagerClass(),
      refreshPickPack: false,
      projectsMeta: [],
      isMemberOfProject: false,
    };
  },
  beforeRouteLeave(route, redirect, next) {
    let self = this;
    const isContactsDirty = this.$refs.contacts && this.$refs.contacts.getIsModified();
    if (
      (this.$route.meta.identifier === "project_edit" && isContactsDirty) ||
      this.isDirty ||
      this.projectConfigurationManager.deleteEvents.length ||
      this.projectConfigurationManager.createEvents.length
    ) {
      MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        distinguishCancelAndClose: true,
        confirmButtonClass: "el-button--success",
      })
        .then(async (action) => {
          if (isContactsDirty) {
            this.$refs.contacts.submitData();
          }
          await self.updateProjectWithConfiguration(undefined, undefined, { noRedirect: true });
          next();
        })
        .catch(function (action) {
          if (action === "close") {
            next(false);
          } else {
            next();
          }
        });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions({
      setPreservedData: "dirtyFlag/setPreservedData",
      checkDirtyFlag: "dirtyFlag/checkDirty",
      discardDirty: "dirtyFlag/discard",
      setSelections: "productGroupSettings/setSelections",
      initProjectRangeResolve: "projectRangeResolve/init",
      setTab: "currentTab/setTab",
      resetTab: "currentTab/reset",
    }),
    ...mapActions("profileEditMode", ["changeEditMode"]),
    async fetchAllContacts() {
      const response = await this.axios.get("/api/contacts");
      this.allContacts = response.data.map((item) => {
        let displayName;
        if (item.lastName) {
          displayName = `${item.lastName}, ${item.name}`;
        } else {
          displayName = `${item.name}`;
        }
        if (item.company) {
          displayName += ` | ${item.company}`;
        }
        if (item.nameSearch) {
          displayName += ` | ${item.nameSearch}`;
        }
        return { ...item, displayName };
      });
    },
    updateData(newData, { submitAfterUpdate } = {}) {
      const newProjectData = { ...this.project, ...newData };
      this.$set(this, "project", newProjectData);
      this.$nextTick(() => {
        // submit data only if project already exists
        if (submitAfterUpdate && !this.isNew) {
          this.updateProject(null, true);
        }
      });
    },
    switchToPickAndPack() {
      this.activeName = "Pick&Pack";
      this.refreshPickPack = !this.refreshPickPack;
    },
    async updateProjectWithConfiguration(updatedData, silent, options) {
      try {
        await this.bulkCreateEditEvents();
        // await this.deleteEventsForResources();
        // await this.createEventsForResources();
        await this.updateProject(updatedData, silent, options);
        this.projectConfigurationManager.initialize(unlink(this.project), this.granularitySettings);
      } catch (error) {
        throw error;
      }
    },
    async updateSuppliesProjectData(supplyId, supplyProjectData) {
      try {
        await this.axios.put("/api/supply/" + supplyId, { projectData: supplyProjectData });
      } catch (error) {
        throw error;
      }
    },
    async bulkCreateEditEvents() {
      try {
        // if (
        //   this.projectConfigurationManager.createEvents.some(({ dateRange }) => !dateRange || dateRange.length !== 2)
        // ) {
        //   Message.error("Bitte stellen Sie sicher, dass alle Daten in der Konfiguration ausgefüllt sind");
        //   return;
        // }
        const createEvents = this.projectConfigurationManager.createEvents.map(({ uid, ...evt }) => evt);
        const formBody = {
          deleteEvents: this.projectConfigurationManager.deleteEvents.map(({ eventId }) => eventId),
          createEvents: createEvents,
        };
        if (formBody.createEvents.length || formBody.deleteEvents.length) {
          await this.axios.post("/api/project-events/bulk-operation", formBody, {
            params: { mode: this.projectConfigurationManager.granularityMode },
          });
        }
        this.projectConfigurationManager.createEvents = [];
        this.projectConfigurationManager.deleteEvents = [];
      } catch (error) {
        const code = get(error, "response.data.code", 0);
        if (code === 102) {
          Message.error({
            message:
              "Bitte überprüfen Sie, dass sich die Ressourcenzuweisungen in der Konfiguration für dasselbe Projekt nicht überschneiden",
            duration: 5000,
            showClose: true,
          });
        } else {
          const message = get(error, "response.data.message", error.message);
          Message.error(message);
        }
        throw error;
      }
    },
    async createEventsForResources() {
      try {
        const formDataList = this.projectConfigurationManager.createEvents;
        await Promise.all(
          formDataList.map((formData) =>
            this.axios.post("/api/project-events", formData, {
              params: { mode: this.getGranularityMode(formData.resourceType) },
            })
          )
        );
        this.projectConfigurationManager.createEvents = [];
      } catch (error) {
        Message.error(get(error, "response.data.message", error.message));
        throw error;
      }
    },
    async deleteEventsForResources() {
      try {
        const self = this;
        const formDataList = this.projectConfigurationManager.deleteEvents;
        await Promise.all(formDataList.map((formData) => self.axios.delete(`/api/project-events/${formData.eventId}`)));
        this.projectConfigurationManager.deleteEvents = [];
      } catch (error) {
        throw error;
      }
    },
    async updateProject(updatedData, silent, options) {
      let isValid = true;
      if (this.$refs.validateObserver) {
        isValid = await this.$refs.validateObserver.validate();
      }
      const projectData = { ...this.project, ...updatedData };
      if (
        !projectData.dateRange ||
        !projectData.bvName ||
        !projectData.client ||
        !projectData.bvDescription ||
        !isValid
      ) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        console.log("this.$refs.validateObserver.errors", this.$refs.validateObserver.errors);
        return;
      }
      const { inital, ...entity } = projectData;
      const isNewProfile = !entity.id;
      const route = "/api/projects";
      try {
        let response;
        this.loading = true;
        if (isNewProfile) {
          response = await this.axios.post(route, entity);
          if (!options || !options.noRedirect) {
            this.$router.push({
              name: "Project Edit",
              params: { id: response.data.id },
            });
          }
        } else {
          response = await this.axios.put(`${route}/${entity.id}`, entity);
        }
        this.$refs.contacts && this.$refs.contacts.submitData();
        // this.project = { ...response.data };
        this.project = unlink(response.data);
        this.originalData = unlink(response.data);
        this.setPreservedData(response.data);
        this.checkDirtyFlag(response.data);
        await this.fetchProjectMeta();
        if (!silent) {
          Message({
            message: "Projekte Daten gespeichert",
            type: "success",
          });
        }
      } catch (error) {
        if (error.response) {
          Message.error(error.response.data.message.toString());
        } else {
          Message({
            message: error.message,
            type: "error",
          });
          throw error;
        }
      } finally {
        this.loading = false;
      }
    },
    async fetchProjectMeta() {
      const response = await this.axios.get("/api/projects/meta");
      this.projectsMeta = response.data;
    },
    tabClicked(clickedTab) {
      if (clickedTab.name === "Pick&Pack") {
        //use this flag to reload data on pick pack list
        this.refreshPickPack = !this.refreshPickPack;
        console.log("refreshPickPack", this.refreshPickPack);
      }

      //remember last tab
      this.previousClickedTabName = clickedTab.name;
    },
    cancelProfile() {
      const requestDelete = (releaseResources) => {
        if (this.project.id) {
          return this.axios.delete("/api/projects/" + this.project.id, { params: { releaseResources } });
        } else {
          return new Promise((res) => res());
        }
      };
      this.$confirmDelete().then(async () => {
        try {
          await requestDelete().then(() => {
            this.discardDirty();
            //directly go back to index page
            this.$router.push({
              name: "Project Index",
            });
          });
        } catch (error) {
          console.log("error", error);
          if (error.response && error.response.data && error.response.data.message === "HAS_ASSIGNED_RESOURCES") {
            MessageBox.confirm("Zugewiesene Ressourcen freigeben?", {
              confirmButtonText: "Ja",
              cancelButtonText: "Nein",
              type: "warning",
            })
              .then(() => requestDelete(true))
              .then(() => {
                //directly go back to index page
                this.$router.push({
                  name: "Project Index",
                });
              })
              .catch(() => {});
          }
        }
      });
    },
    handleBack() {
      this.$router.push({ name: "Project Index" });
    },
    navigateToCalendar(query) {
      this.$router.push({ name: "Project Calendar", query });
    },
    handleGoToCalendar() {
      const projectStartDate = this.project.dateRange && this.project.dateRange[0];
      const monday = moment(projectStartDate).startOf("week").toJSON();
      this.navigateToCalendar({ date: monday });
    },
    handleGoToCalendarToday() {
      const today = moment().startOf("day").toJSON();
      this.navigateToCalendar({ date: today });
    },
    scrollToSection(sectionName) {
      const profileScroll = document.querySelector("._profile-scrollbar");
      if (!profileScroll) {
        throw new Error('Perfect scrollbar with class "_profile-scrollbar" not found');
      }
      switch (sectionName) {
        case "hotel-section":
          const hotelSection = document.getElementById("hotel-section");
          if (hotelSection) {
            profileScroll.scrollTop = hotelSection.offsetTop;
          }
          break;
        default:
          break;
      }
    },
    async checkIfNotesIsAvailable(projectId) {
      try {
        const response = await this.axios.get(`/api/employees/is-member-of-project/${projectId}`);
        this.isMemberOfProject = response.data;
      } catch (error) {
        throw error;
      }
    },
    setFirstTab(accessRights) {
      if (!accessRights.project) {
        return;
      }
      if (this.activeName === "0") {
        const tabsAccessRights = accessRights.project.specificAccess;
        let firstTab = this.allTabNames.find((tabName) => !!tabsAccessRights[tabName]);
        if (!firstTab) {
          if (this.canUseNotes) {
            firstTab = "notes";
          } else if (this.canUseTodos) {
            firstTab = "todos";
          }
        }
        this.activeName = firstTab;
      }
    },
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    ...mapState("account", { accessRights: "accessRights" }),
    ...mapState("profileEditMode", ["isEditMode"]),
    ...mapGetters("notifications", { notificationsByProjectId: "mappedByProjectId" }),
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    ...mapGetters("granularitySettings", ["getGranularityMode"]),
    hasNewTodos() {
      const projectNotifications = get(this.notificationsByProjectId, this.project.id, []);
      return projectNotifications.some((item) => item.namespace === "TODOS");
    },
    hasNewNotes() {
      const projectNotifications = get(this.notificationsByProjectId, this.project.id, []);
      return projectNotifications.some((item) => item.namespace === "NOTES");
    },
    canGoToProject() {
      return (
        this.$route.params.id !== "new" &&
        this.hasAccessToCalendar &&
        this.project.dateRange &&
        this.project.dateRange.length
      );
    },
    isProjectRunning() {
      const today = moment();
      return today.isSameOrAfter(this.project.dateRange[0]) && today.isSameOrBefore(this.project.dateRange[1]);
    },
    activeNameLabel() {
      if (this.activeName === "0") {
        return "";
      }
      return this.$t(`src.components.project.edit.${this.activeName}`);
    },
    hasAccessToCalendar() {
      return !!get(this.accessRights, "calendar.generalAccess");
    },
    projectAccess() {
      return get(this.accessRights, "project", { specificAccess: {}, generalAccess: null });
    },
    activeTabName() {
      if (this.activeName === "notes") {
        return this.$t("Notes");
      } else if (this.activeName === "todos") {
        return this.$t("ToDos");
      } else {
        return this.activeName;
      }
    },
    projecTitle() {
      if (this.$route.params.id === "new") {
        return "Neues Projekt";
      }
      if (this.project.bvName) {
        if (this.project.constructionPhase) {
          return this.project.bvName;
        }
        return this.project.bvName;
      }
      return "";
    },
    projectDateRange() {
      if (!this.project.inital && this.project.dateRange && this.project.dateRange.length === 2) {
        return `(${this.project.dateRange.map((d) => moment(d).format("L")).join(" - ")})`;
      }
      return null;
    },
    isNew() {
      return this.$route.params.id === "new";
    },
    canUseNotes() {
      return (
        (this.isMemberOfProject && get(this.accessRights, "project.specificAccess.notes", false)) ||
        get(this.accessRights, "project_notes.isPrivileged", false)
      );
    },
    canUseTodos() {
      return (
        (this.isMemberOfProject && get(this.accessRights, "project.specificAccess.todos", false)) ||
        get(this.accessRights, "project_todos.isPrivileged", false)
      );
    },
  },
  watch: {
    "allContacts.length": function () {
      this.allContacts = sortBy(this.allContacts, "name");
    },
    accessRights(rights) {
      this.setFirstTab(rights);
    },
    activeName(newVal) {
      this.setTab(newVal);
    },
    project: {
      handler(newVal) {
        if (newVal && !newVal.inital) {
          this.checkDirtyFlag(newVal);
        }
      },
      deep: true,
    },
    isEditMode(newVal) {
      if (!newVal) {
        this.project = unlink(this.originalData);
        this.setPreservedData(this.project);
        this.discardDirty();
      }
    },
  },
};
</script>

<style>
.ps-no-save-button .ps {
  height: 100%;
}

.h100p {
  height: 100%;
}

.tab-content-h100p .el-tabs__content {
  height: 100%;
}
</style>
