import Vue from "vue";
import VueRouter from "vue-router";

import DashboardLayout from "../components/Dashboard/Layout/DashboardLayout.vue";
import WelcomePage from "../components/Dashboard/Layout/WelcomePage.vue";
import WebViewLayout from "../components/Dashboard/Layout/WebViewLayout.vue";
// GeneralViews
import NotFound from "../components/GeneralViews/NotFoundPage.vue";

//Employees
import EmployeeIndex from "src/components/Employees/Index.vue";
import EmployeeEdit from "src/components/Employees/Edit.vue";

//Vehicle
import VehicleIndex from "src/components/Vehicle/Index.vue";
import VehicleEdit from "src/components/Vehicle/Edit.vue";

//Verbrauchsmaterialien
import SupplyIndex from "src/components/Supply/Index.vue";
import SupplyEdit from "src/components/Supply/Edit.vue";

//RHB
import RHBIndex from "src/components/RHB/Index.vue";
import RHBEdit from "src/components/RHB/Edit.vue";

//Machine
import MachineIndex from "src/components/Machine/Index.vue";
import MachineEdit from "src/components/Machine/Edit.vue";

//Project
import ProjectIndex from "src/components/Project/Index.vue";
import ProjectEdit from "src/components/Project/Edit.vue";
import WorkshopEdit from "src/components/Project/WorkshopEdit.vue";

//Management
import CompanySettings from "src/components/Management/CompanySettings/Index.vue";
import Finance from "src/components/Management/Finance/Index.vue";
import Expenses from "src/components/Management/Expenses/Index.vue";
import ProjectSettings from "src/components/Management/ProjectSettings/ProjectSettings.vue";
import RequiredDocumentTypes from "src/components/Management/RequiredDocumentTypes/RequiredDocumentTypes.vue";

//Service Provider
import ServiceProviderIndex from "src/components/ServiceProvider/Index.vue";
import ServiceProviderEdit from "src/components/ServiceProvider/Edit.vue";

// Subcontractor
import SubcontractorIndex from "src/components/Subcontractor/Index.vue";
import SubcontractorEdit from "src/components/Subcontractor/Edit.vue";

// Dashboard pages
import Overview from "src/components/Dashboard/Views/Dashboard/Overview.vue";

// Pages
import Login from "src/components/Login.vue";
import Logout from "src/components/Dashboard/Views/Logout.vue";
import DownloadDocument from "src/components/DownloadDocument/DownloadDocument.vue";

//Administration
import UserIndex from "src/components/Administration/User/Index.vue";
import UserEdit from "src/components/Administration/User/Profile.vue";
import UserGroupIndex from "src/components/Administration/UserGroup/Index.vue";
import UserGroupEdit from "src/components/Administration/UserGroup/Profile.vue";
import ExcelSync from "src/components/Administration/ExcelSync/Index.vue";

//Purchases
import SuppliersGlobal from "src/components/Suppliers/SuppliersGlobal.vue";

//Contacts
import ContactsIndex from "src/components/Contacts/Index.vue";

// Bryntum Scheduler WIP
import BryntumScheduler from "src/components/Project/BryntumScheduler/BryntumScheduler.vue";

Vue.use(VueRouter);

let employeeMenu = {
  path: "/employee",
  component: DashboardLayout,
  redirect: "/employee/index",
  children: [
    {
      path: "index",
      name: "Employee Index",
      meta: {
        requiresAuth: true,
      },
      component: EmployeeIndex,
    },
    {
      path: "profile/:id",
      name: "Employee Edit",
      meta: {
        requiresAuth: true,
        identifier: "profile_edit",
      },
      component: EmployeeEdit,
    },
    {
      path: "profile/view/:id",
      name: "Employee View",
      meta: {
        requiresAuth: true,
        identifier: "profile_view",
      },
      component: EmployeeEdit,
    },
  ],
};

let vehicleMenu = {
  path: "/vehicle",
  component: DashboardLayout,
  redirect: "/vehicle/index",
  children: [
    {
      path: "index",
      name: "Vehicle Index",
      meta: {
        requiresAuth: true,
      },
      component: VehicleIndex,
    },
    {
      path: "profile/:id",
      name: "Vehicle Edit",
      meta: {
        identifier: "vehicle_edit",
        requiresAuth: true,
      },
      component: VehicleEdit,
    },
    {
      path: "profile/view/:id",
      name: "Vehicle View",
      meta: {
        identifier: "vehicle_view",
        requiresAuth: true,
      },
      component: VehicleEdit,
    },
  ],
};

let supplyMenu = {
  path: "/supply",
  component: DashboardLayout,
  redirect: "/supply/index",
  children: [
    {
      path: "index",
      name: "Supply Index",
      meta: {
        requiresAuth: true,
      },
      component: SupplyIndex,
    },
    {
      path: "profile/:id",
      name: "Supply Edit",
      meta: {
        requiresAuth: true,
        identifier: "supply_edit",
      },
      component: SupplyEdit,
    },
    {
      path: "profile/view/:id",
      name: "Supply View",
      meta: {
        requiresAuth: true,
        identifier: "supply_view",
      },
      component: SupplyEdit,
    },
  ],
};

let RHBMenu = {
  path: "/rhb",
  component: DashboardLayout,
  redirect: "/rhb/index",
  children: [
    {
      path: "index",
      name: "RHB Index",
      meta: {
        requiresAuth: true,
      },
      component: RHBIndex,
    },
    {
      path: "profile/:id",
      name: "RHB Edit",
      meta: {
        requiresAuth: true,
        identifier: "rhb_edit",
      },
      component: RHBEdit,
    },
    {
      path: "profile/view/:id",
      name: "RHB View",
      meta: {
        requiresAuth: true,
        identifier: "rhb_view",
      },
      component: RHBEdit,
    },
  ],
};

let machineMenu = {
  path: "/machine",
  component: DashboardLayout,
  redirect: "/machine/index",
  children: [
    {
      path: "index",
      name: "Machine Index",
      meta: {
        requiresAuth: true,
        identifier: "machine_view",
      },
      component: MachineIndex,
    },
    {
      path: "profile/:id",
      name: "Machine Edit",
      meta: {
        requiresAuth: true,
        identifier: "machine_edit",
      },
      component: MachineEdit,
    },
    {
      path: "profile/view/:id",
      name: "Machine View",
      meta: {
        requiresAuth: true,
        identifier: "machine_view",
      },
      component: MachineEdit,
    },
  ],
};

const projectCalendarMenu = {
  path: "/projectcalendar",
  component: DashboardLayout,
  redirect: "/projectcalendar/",
  children: [
    {
      path: "/",
      name: "Project Calendar",
      meta: {
        requiresAuth: true,
      },
      component: BryntumScheduler,
    },
  ],
};

let projectMenu = {
  path: "/project",
  component: DashboardLayout,
  redirect: "/project/index",
  children: [
    {
      path: "index",
      name: "Project Index",
      meta: {
        requiresAuth: true,
      },
      component: ProjectIndex,
    },
    {
      path: "profile/:id",
      name: "Project Edit",
      meta: {
        requiresAuth: true,
        identifier: "project_edit",
      },
      component: ProjectEdit,
    },
    {
      path: "profile/view/:id",
      name: "Project View",
      meta: {
        requiresAuth: true,
        identifier: "project_view",
      },
      component: ProjectEdit,
    },
    {
      path: "profile/workshop/:id",
      name: "Workshop View",
      meta: {
        requiresAuth: true,
        identifier: "workshop_view",
      },
      component: WorkshopEdit,
    },
  ],
};

let userMenu = {
  path: "/administration/users",
  component: DashboardLayout,
  redirect: "/administration/users/index",
  children: [
    {
      path: "index",
      name: "User Index",
      meta: {
        requiresAuth: true,
      },
      component: UserIndex,
    },
    {
      path: "profile/view/:id",
      name: "User View",
      meta: {
        requiresAuth: true,
        identifier: "user_view",
      },
      component: UserEdit,
    },
    {
      path: "profile/:id",
      name: "User Edit",
      meta: {
        requiresAuth: true,
        identifier: "user_edit",
      },
      component: UserEdit,
    },
  ],
};

let adminSettingsMenu = {
  path: "/administration",
  component: DashboardLayout,
  redirect: "/administration/company-settings",
  children: [
    {
      path: "company-settings",
      name: "Company settings",
      meta: {
        requiresAuth: true,
      },
      component: CompanySettings,
    },
    {
      path: "project-settings",
      name: "Projekt Einstellungen",
      meta: {
        requiresAuth: true,
        identifier: "project-settings",
      },
      component: ProjectSettings,
    },
  ],
};

let userGroupMenu = {
  path: "/administration/user-groups",
  component: DashboardLayout,
  redirect: "/administration/user-groups/index",
  children: [
    {
      path: "index",
      name: "UserGroup Index",
      meta: {
        requiresAuth: true,
      },
      component: UserGroupIndex,
    },
    {
      path: "profile/:id",
      name: "UserGroup Edit",
      meta: {
        requiresAuth: true,
        identifier: "userGroup_edit",
      },
      component: UserGroupEdit,
    },
    {
      path: "profile/view/:id",
      name: "UserGroup View",
      meta: {
        requiresAuth: true,
        identifier: "userGroup_view",
      },
      component: UserGroupEdit,
    },
  ],
};

let excelSyncMenu = {
  path: "/administration/excelSync",
  component: DashboardLayout,
  redirect: "/administration/excelSync/index",
  children: [
    {
      path: "index",
      name: "Excel Synchronisierung",
      meta: {
        requiresAuth: true,
      },
      component: ExcelSync,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

let downloadDocumentPage = {
  path: "/documents/*",
  name: "Download Document",
  component: DownloadDocument,
};

const managementMenu = {
  path: "/management",
  component: DashboardLayout,
  redirect: "/management/contacts",
  children: [
    {
      path: "contacts",
      name: "Contacts Index",
      meta: {
        requiresAuth: true,
      },
      component: ContactsIndex,
    },
    {
      path: "finance",
      name: "Finance",
      meta: {
        requiresAuth: true,
        identifier: "finance",
      },
      component: Finance,
    },
    {
      path: "expenses",
      name: "Expenses",
      meta: {
        requiresAuth: true,
        identifier: "expenses",
      },
      component: Expenses,
    },
    {
      path: "required-document-types",
      name: "Erforderliche Dokument Typen",
      meta: {
        requiresAuth: true,
        identifier: "required-document-types",
      },
      component: RequiredDocumentTypes,
    },
  ],
};

const purchasingMenu = {
  path: "/purchasing",
  component: DashboardLayout,
  name: "Purchasing",
  children: [
    {
      path: "suppliers",
      component: SuppliersGlobal,
      meta: {
        requiresAuth: true,
        identifier: "suppliers-global",
      },
    },
    {
      path: "serviceProvider/index",
      name: "ServiceProvider Index",
      meta: {
        requiresAuth: true,
      },
      component: ServiceProviderIndex,
    },
    {
      path: "serviceProvider/profile/:id",
      name: "ServiceProvider Edit",
      meta: {
        requiresAuth: true,
        identifier: "serviceProvider_edit",
      },
      component: ServiceProviderEdit,
    },
    {
      path: "serviceProvider/profile/view/:id",
      name: "ServiceProvider View",
      meta: {
        requiresAuth: true,
        identifier: "serviceProvider_view",
      },
      component: ServiceProviderEdit,
    },
    {
      path: "subcontractor",
      name: "Subcontractor Index",
      meta: {
        requiresAuth: true,
      },
      component: SubcontractorIndex,
    },
    {
      path: "subcontractor/profile/:id",
      name: "Subcontractor Edit",
      meta: {
        requiresAuth: true,
        identifier: "subcontractor_edit",
      },
      component: SubcontractorEdit,
    },
    {
      path: "subcontractor/profile/view/:id",
      name: "Subcontractor View",
      meta: {
        requiresAuth: true,
        identifier: "subcontractor_view",
      },
      component: SubcontractorEdit,
    },
  ],
};

const routes = [
  adminSettingsMenu,
  excelSyncMenu,
  userMenu,
  userGroupMenu,
  employeeMenu,
  projectCalendarMenu,
  projectMenu,
  machineMenu,
  RHBMenu,
  supplyMenu,
  vehicleMenu,
  managementMenu,
  purchasingMenu,
  loginPage,
  downloadDocumentPage,
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/webview/calendar",
    component: WebViewLayout,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/welcome",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          requiresAuth: true,
        },
        component: Overview,
      },
      {
        path: "welcome",
        name: "Welcome",
        meta: {
          requiresAuth: true,
        },
        component: WelcomePage,
      },
    ],
  },
  {
    path: "/",
    component: DashboardLayout,
    name: "Project Calendar Detached",
    children: [
      {
        path: "project-calendar",
        component: BryntumScheduler,
        meta: {
          requiresAuth: true,
          projectMode: true,
          identifier: "projectCalendar",
        },
      },
    ],
  },
  { path: "/doc" }, // swagger doc
  { path: "/doc-json" }, // swagger doc
  { path: "*", component: NotFound },
];

// configure router
export const router = new VueRouter({
  routes, // short for routes: routes
  base: "/app/",
  linkActiveClass: "active",
  mode: "history",
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/webview/calendar"];
  const basePath = window.location.toString();
  const authRequired = !publicPages.some((pathToExclude) => to.path.indexOf(pathToExclude) !== -1);
  const loggedIn = Vue.$keycloak.authenticated;
  if (authRequired && !loggedIn) {
    console.log("logging out for route ", to);
    Vue.$keycloak.logout();
  }

  next();
});
