<template>
  <div>{{ $t('src.components.management.expenses.index.expenses') }}</div>
</template>

<script>
export default {
  name: "expenses",
  data: function() {
    return {};
  },
  components: {}
};
</script>

<style></style>
