<template>
  <div v-if="isRange" class="c-time-picker c-range-picker">
    <vue-timepicker
      hide-clear-button
      format="HH:mm"
      :disabled="disabled"
      :minute-interval="minuteInterval"
      manual-input
      v-model="selectedTimeFrom"
      input-width="100%"
      ref="fromTimePicker"
      hide-dropdown
      :placeholder="$t('src.components.uicomponents.helper.timepicker.hhmm')"
      data-testid="from"
    ></vue-timepicker>
    <div class="delimiter">-</div>
    <vue-timepicker
      hide-clear-button
      format="HH:mm"
      :disabled="disabled"
      :minute-interval="minuteInterval"
      manual-input
      v-model="selectedTimeTo"
      input-width="100%"
      ref="toTimePicker"
      hide-dropdown
      data-testid="to"
      :placeholder="$t('src.components.uicomponents.helper.timepicker.hhmm')"
    ></vue-timepicker>
  </div>
  <div v-else class="c-time-picker" :style="styles">
    <vue-timepicker
      format="HH:mm"
      :placeholder="$t('src.components.uicomponents.helper.timepicker.hhmm')"
      :disabled="disabled"
      :minute-interval="minuteInterval"
      manual-input
      v-model="selectedTime"
      ref="timePicker"
      hide-dropdown
    ></vue-timepicker>
  </div>
</template>

<script>
import VueTimepicker from "./Vue2TimePicker.vue";
// import { moment } from "src/config/moment";

export default {
  name: "time-picker",
  components: {
    VueTimepicker,
  },
  props: {
    value: [Array, String],
    isRange: Boolean,
    disabled: Boolean,
    styles: Object,
    minuteInterval: { type: Number },
  },
  data() {
    return {
      selectedTime: "",
      selectedTimeFrom: "",
      selectedTimeTo: "",
    };
  },
  mounted() {
    if (this.value && this.value.length) {
      this.setDefaultValue(this.value);
    }
  },
  methods: {
    setDefaultValue(value) {
      if (Array.isArray(value)) {
        if (value.length === 2) {
          if (this.selectedTimeFrom !== value[0]) {
            this.selectedTimeFrom = value[0] || "";
          }
          if (this.selectedTimeTo !== value[1]) {
            this.selectedTimeTo = value[1] || "";
          }
        } else {
          this.selectedTimeFrom = "";
          this.selectedTimeTo = "";
        }
      } else {
        this.selectedTime = value || "";
        this.selectedTimeFrom = "";
        this.selectedTimeTo = "";
      }
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setDefaultValue(newVal);
      }
    },
    selectedTime(newVal, oldVal) {
      if (newVal && newVal.endsWith(":mm")) {
        this.selectedTime = newVal.replace("mm", "00");
      }
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
      // Hack: we need to manually call component's method to clear inputs when value is not set
      if (!newVal && this.$refs.timePicker) {
        this.$refs.timePicker.clearTime();
      }
    },
    selectedTimeFrom(newVal, oldVal) {
      let valueWithMinutes = newVal;
      // set to 00 minites initially
      if (newVal && newVal.endsWith(":mm")) {
        valueWithMinutes = newVal.replace("mm", "00");
        this.selectedTimeFrom = valueWithMinutes;
        this.$refs.fromTimePicker.minute = "00";
      }
      if (newVal !== oldVal && this.selectedTimeTo) {
        this.$emit("input", [valueWithMinutes, this.selectedTimeTo]);
      }
      // Hack: we need to manually call component's method to clear inputs when value is not set
      if (!newVal) {
        this.$refs.fromTimePicker.clearTime();
      }
    },
    selectedTimeTo(newVal, oldVal) {
      let valueWithMinutes = newVal;
      if (newVal && newVal.endsWith(":mm")) {
        valueWithMinutes = newVal.replace("mm", "00");
        this.selectedTimeTo = valueWithMinutes;
      }
      if (newVal !== oldVal && this.selectedTimeFrom) {
        this.$emit("input", [this.selectedTimeFrom, valueWithMinutes]);
      }
      // Hack: we need to manually call component's method to clear inputs when value is not set
      if (!newVal) {
        this.$refs.toTimePicker.clearTime();
      }
    },
  },
};
</script>

<style lang="scss">
.c-range-picker {
  display: inline-flex;
  align-items: center;
  width: 300px;
}
.vue__time-picker {
  min-width: 72px;
  width: 100px;
  input.display-time {
    // width: 100px;
    // text-align: center;
  }
}
.c-range-picker .delimiter {
  height: 40px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}
.c-time-picker .controls {
  display: none;
}
.c-time-picker .vue__time-picker .display-time {
  padding: 3px 10px;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  background-image: none;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
}
.c-time-picker .vue__time-picker .controls .char {
  line-height: 1.6;
}
.is-error .c-time-picker .vue__time-picker .display-time {
  border-color: #eb4747;
}
</style>
