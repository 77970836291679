<template>
  <el-dialog
    :title="$t('src.components.project.bryntumscheduler.quickprojectsetup.quickProjectSetup')"
    :visible="visible"
    center
    top="10px"
    :custom-class="activeClasses"
    @close="close"
  >
    <el-form
      ref="form"
      :rules="formDataRules"
      :model="formData"
      :label-position="isMobileView ? 'right' : 'top'"
      label-width="150px"
    >
      <el-form-item prop="bvName" :label="$t('src.components.project.bryntumscheduler.quickprojectsetup.projektname')">
        <el-input v-model="formData.bvName" />
      </el-form-item>
      <el-form-item prop="client" :label="$t('src.components.project.bryntumscheduler.quickprojectsetup.auftraggeber')">
        <el-input v-model="formData.client" />
      </el-form-item>
      <el-form-item prop="dateRange" :label="$t('src.components.project.bryntumscheduler.quickprojectsetup.zeitraum')">
        <pr-date-picker
          style="max-width: 100%; min-width: 100%"
          v-model="formData.dateRange"
          is-range
          :placeholder="$t('src.components.project.bryntumscheduler.quickprojectsetup.projektlaufzeit')"
        />
      </el-form-item>
      <el-form-item
        prop="bvDescription"
        :label="$t('src.components.project.bryntumscheduler.quickprojectsetup.kurzbeschreibung')"
      >
        <el-input v-model="formData.bvDescription" />
      </el-form-item>
      <el-form-item :label="$t('src.components.project.projectstatus.projektstatus')" prop="progress">
        <el-radio-group v-model="formData.progress">
          <el-radio label="progress_1">{{ $t("src.components.project.projectstatus.reserviert") }}</el-radio>
          <el-radio label="progress_2">{{ $t("src.components.project.projectstatus.auszufhrendeArbeit") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="text-right">
        <el-button type="primary" @click="submit" :loading="loading">{{
          $t("src.components.project.bryntumscheduler.quickprojectsetup.speichern")
        }}</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { Dialog, Form, FormItem, Button, Input, Message, RadioGroup, Radio } from "element-ui";
import { moment } from "src/config/moment";

export default {
  name: "quick-project-setup",
  props: {
    isMobileView: Boolean,
  },
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Message.name]: Message,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  data() {
    const validateDateRange = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum auswählen"));
      }
      return callback();
    };
    return {
      formDataRules: {
        bvName: {
          required: true,
          message: "Bitte Name auswählen",
          trigger: "change",
        },
        client: {
          required: true,
          message: "Bitte Auftraggeber auswählen",
          trigger: "change",
        },
        dateRange: {
          required: true,
          message: "Bitte Zeitraum auswählen",
          trigger: "change",
          validator: validateDateRange,
        },
        bvDescription: {
          required: true,
          message: "Bitte Kurzbeschreibung auswählen",
          trigger: "change",
        },
        progress: {
          required: true,
          message: "Bitte Auszuführende Arbeit auswählen",
          trigger: "change",
        },
      },
      formData: {
        bvName: null,
        client: null,
        dateRange: null,
        bvDescription: null,
        progress: "progress_1",
      },
      loading: false,
      visible: false,
    };
  },
  mounted() {
    this.$root.$on("openQuickProjectSetup", this.showModal);
  },
  beforeDestroy() {
    this.$root.$off("openQuickProjectSetup");
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.dismiss();
    },
    dismiss() {
      console.log("dismiss");
      this.$refs.form.resetFields();
      this.formData = {
        bvName: null,
        client: null,
        dateRange: null,
        bvDescription: null,
        progress: null,
      };
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          const formData = {
            ...this.formData,
            dateRange: [this.formData.dateRange[0], this.formData.dateRange[1]],
          };
          await this.axios.post("/api/projects/quick", formData);
          this.close();
          Message.success("Projekt erstellt");
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
  },
  computed: {
    activeClasses() {
      return this.isMobileView ? "mobile-qps-view" : "common-qps-view";
    },
  },
};
</script>

<style>
.common-qps-view.el-dialog {
  width: 500px;
}
.mobile-qps-view.el-dialog {
  width: 95%;
  height: 95%;
  max-width: 700px;
  max-height: 350px;
  overflow: auto;
  margin: 0 auto;
}
</style>
