/**
 * This module handles data change between received from back-end and edited by user, triggers "isDirty" flag
 * when data is changed.
 * 1. Use "setPreservedData" when load data from back-end to set the starting point
 * 2. Use "checkDirty" with updated object at observer (watcher function) to trigger check
 * 3. Use "discard" in "beforeDestroy" to return to initial state.
 */
import { debounce } from "lodash";
import { isDataChanged } from "src/utils/isDataChanged.js";

const state = {
  _preservedData: null,
  isDirty: false,
};

const mutations = {
  commitDirtyValue(state, value) {
    state.isDirty = value;
  },
  commitPreservedData(state, data) {
    state._preservedData = data;
  },
};

const actions = {
  checkDirty: debounce(({ commit, state }, newValue) => {
    if (!state._preservedData) {
      return;
    }
    // console.log("state._preservedData", JSON.stringify(state._preservedData, null, 2));
    // console.log("newValue", JSON.stringify(newValue, null, 2));
    const isChanged = isDataChanged(newValue, state._preservedData);
    commit("commitDirtyValue", isChanged);
  }, 300),
  setPreservedData({ commit }, value) {
    commit("commitPreservedData", JSON.parse(JSON.stringify(value)));
  },
  discard({ commit }) {
    commit("commitPreservedData", null);
    commit("commitDirtyValue", false);
  },
};

export const dirtyFlag = {
  namespaced: true,
  state,
  mutations,
  actions,
};
