import { orderBy } from "lodash";
import axios from "axios";

const state = {
  list: [],
  loading: false
};

const mutations = {
  setList(state, { payload }) {
    state.list = payload || [];
  },
  removeItem(state, { id }) {
    state = state.filter(item => item._id !== id);
  },
  startLoading(state) {
    state.loading = true;
  },
  endLoading(state) {
    state.loading = false;
  }
}

const actions = {
  async loadList({ commit }) {
    try {
      commit('startLoading');
      const response = await axios.get('/api/model-type', {
        params: {
          modelType: 'global',
          modelId: 'serviceSpectrum'
        }
      });
      commit('setList', { payload: orderBy(response.data, ['label'], ['asc']) });
    } catch (error) {
      throw error;
    } finally {
      commit('endLoading');
    }
  },
  async createItem({ commit }, payload) {
    try {
      commit('startLoading');
      const formData = {
        ...payload,
        model: 'global',
        modelID: 'serviceSpectrum'
      }
      await axios.post('/api/model-type', formData);
      commit('loadList');
    } catch (error) {
      throw error;
    } finally {
      commit('endLoading');
    }
  },
  async editItem({ commit }, payload) {
    try {
      commit('startLoading');
      const formData = {
        ...payload,
        model: 'serviceSpectrum',
        modelID: 'global'
      }
      await axios.put('/api/model-type', formData);
      commit('loadList');
    } catch (error) {
      throw error;
    } finally {
      commit('endLoading');
    }
  },
  async deleteItem({ commit }, id) {
    try {
      commit('startLoading');
      await axios.delete(`/api/model-type/${id}`);
      commit('removeItem', { id });
    } catch (error) {
      throw error;
    } finally {
      commit('endLoading');
    }
  }
}


export const serviceSpectrum = {
  namespaced: true,
  state,
  mutations,
  actions,
};