<template>
  <div>
    <div class="card card-user">
      <form>
        <perfect-scrollbar>
          <div class="card-header">
            <h3 class="n-profile-title">{{ tabName }}</h3>
          </div>

          <div class="card-body">
            <div class="row col-md-12">
              <div class="col-md-2">
                <div class="form-group">
                  <avatar-uploader
                    :isEditMode="isEditMode"
                    :actionURI="actionURI"
                    :avatar="subcontractor.picture"
                    v-on:avatar-changed="setPicture"
                    :name="'subcontractor_' + subcontractor.id + '_picture_0'"
                  />
                </div>
                <div class="form-group">
                  <profile-switch
                    :disabled="!isEditMode"
                    :title="$t('src.components.vehicle.profile.aktiv')"
                    v-model="subcontractor.active"
                    :active-text="$t('src.components.vehicle.profile.ja')"
                    :inactive-text="$t('src.components.vehicle.profile.nein')"
                    colorizeActive
                  />
                </div>
              </div>
            </div>

            <hr />

            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profile.allgemein") }}
            </div>
            <el-row class="n-profile-spacer">
              <el-col :span="4">
                <profile-input
                  name="Name"
                  rules="required"
                  required
                  v-model="subcontractor.name"
                  :label="$t('src.components.serviceprovider.profile.name')"
                  :editMode="isEditMode"
                />
              </el-col>
            </el-row>

            <!-- MAßE -->
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profile.adressdaten") }}
            </div>
            <el-row class="n-profile-spacer" :gutter="20">
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.address.street"
                  :label="$t('src.components.serviceprovider.profile.strae')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.address.no"
                  :label="$t('src.components.serviceprovider.profile.hausnummer')"
                  :editMode="isEditMode"
                />
              </el-col>

              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.address.zip"
                  :label="$t('src.components.serviceprovider.profile.plz')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.address.city"
                  :label="$t('src.components.serviceprovider.profile.stadt')"
                  :editMode="isEditMode"
                />
              </el-col>

              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.address.addressAddition"
                  :label="$t('src.components.serviceprovider.profile.adresszusatz')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-select
                  :multiple="false"
                  v-model="subcontractor.address.country"
                  :title="$t('country')"
                  label="name"
                  :editMode="isEditMode"
                  :items="countryList"
                  filterable
                />
              </el-col>
            </el-row>

            <hr />

            <!-- MAßE -->
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.serviceprovider.profile.kontaktdaten") }}
            </div>
            <el-row class="n-profile-spacer" :gutter="20">
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.hrContact.contactPerson"
                  :label="$t('src.components.serviceprovider.profile.contactPerson')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.hrContact.phone"
                  :label="$t('src.components.serviceprovider.profile.telefon')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.hrContact.mobile"
                  :label="$t('src.components.serviceprovider.profile.handy')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.hrContact.fax"
                  :label="$t('src.components.serviceprovider.profile.fax')"
                  :editMode="isEditMode"
                />
              </el-col>
              <el-col :span="4">
                <profile-input
                  v-model="subcontractor.hrContact.email"
                  name="emailfield"
                  type="email"
                  rules="email"
                  :label="$t('src.components.serviceprovider.profile.eMail')"
                  :editMode="isEditMode"
                />
              </el-col>
            </el-row>
          </div>
        </perfect-scrollbar>
      </form>
    </div>
  </div>
</template>

<script>
import { Message, RadioGroup, RadioButton, Switch } from "element-ui";
import { Avatar, Collapse, CollapseItem, Keyfigures } from "src/components/UIComponents";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import TypeSettings from "../UIComponents/Helper/TypeSettings.vue";
import countryList from "src/country-list.json";

export default {
  name: "subcontractor-profile",
  props: {
    subcontractor: Object,
    tabName: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PerfectScrollbar,
    TypeSettings,
    Message,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [Switch.name]: Switch,
    Collapse,
    CollapseItem,
    [Keyfigures.name]: Keyfigures,
  },
  data() {
    return {
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      countryList: countryList,
    };
  },
  watch: {
    subcontractor: {
      handler: function (val, oldVal) {
        if (!oldVal.inital && this.isEditMode) {
          this.$emit("dirtyFlagSet");
        }
      },
      deep: true,
    },
  },
  methods: {
    setPicture(pictureSettings) {
      this.subcontractor.picture = pictureSettings;
    },
    updateProfile(entity) {
      this.$emit("updateProfileCicked", this.subcontractor);
    },
  },
};
</script>

<style></style>
