<template>
  <el-row :gutter="20" v-loading="loading">
    <el-col :span="12">
      <profile-select
        filterable
        v-model="userGroup"
        label="name"
        :multiple="false"
        :editMode="isEditMode"
        :title="$t('src.components.administration.user.profile.benutzergruppen')"
        :items="allGroups"
        valueIdentifier="id"
      />
    </el-col>
  </el-row>
</template>

<script>
import { Message, Button } from "element-ui";
import { get } from "lodash";

export default {
  name: "user-groups-manager",
  components: {
    [Button.name]: Button,
    [Message.name]: Message,
  },
  props: {
    userId: { type: String, required: true },
    isEditMode: { type: Boolean },
  },
  data() {
    return {
      userGroup: null,
      assignedGroup: null,
      allGroups: [],
      loading: false,
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        const allGroups = await this.axios.get("/api/user-groups");
        const assignedGroups = await this.axios.get(`/api/users/${this.userId}/groups`);
        this.allGroups = allGroups.data || [];
        this.userGroup = get(assignedGroups.data, "id", null);
        this.assignedGroup = this.userGroup;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async joinGroup(groupId) {
      try {
        await this.axios.put(`/api/users/${this.userId}/groups/${groupId}`);
        this.assignedGroup = groupId;
        Message.success(this.$t("Usergroup changed"));
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
  },
  watch: {
    userGroup(newVal, oldVal) {
      if (newVal !== this.assignedGroup) {
        this.joinGroup(newVal);
      }
    },
  },
};
</script>

<style>
</style>