<template>
  <div class="card">
    <div class="card-body" style="overflow: hidden;">
        <el-tabs v-model="activeTab" class="stretch-height">
          <el-tab-pane name="employee" lazy :label="$t('src.components.management.requireddocumenttypes.requireddocumenttypes.mitarbeiter')">
            <document-type-matrix resourceType="employee" resourceName="Mitarbeiter" />
          </el-tab-pane>
          <el-tab-pane name="machine" lazy :label="$t('src.components.management.requireddocumenttypes.requireddocumenttypes.maschinen')">
            <document-type-matrix resourceType="machine" resourceName="Maschinen" />
          </el-tab-pane>
          <el-tab-pane name="vehicle" lazy :label="$t('src.components.management.requireddocumenttypes.requireddocumenttypes.kfz')">
            <document-type-matrix resourceType="vehicle" resourceName="KFZ" />
          </el-tab-pane>
          <el-tab-pane name="rhb" lazy :label="$t('src.components.management.requireddocumenttypes.requireddocumenttypes.rhb')">
            <document-type-matrix resourceType="rhb" resourceName="RHB" />
          </el-tab-pane>
          <el-tab-pane name="supply" lazy :label="$t('src.components.management.requireddocumenttypes.requireddocumenttypes.verbrauchsmaterialien')">
            <document-type-matrix resourceType="supply" resourceName="Verbrauchsmaterialien" />
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>
import { Tabs, TabPane } from "element-ui";
import DocumentTypeMatrix from "./DocumentTypeMatrix.vue";

export default {
  name: "required-document-types",
  components: {
    [DocumentTypeMatrix.name]: DocumentTypeMatrix,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane
  },
  data() {
    return {
      activeTab: "employee"
    };
  }
};
</script>

<style lang="scss">
.stretch-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-tabs__content {
    height: 100%;
  }
  .el-tab-pane {
    height: 100%;
  }
}
</style>
</style>
