<template>
  <widget-tab
    :title="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusRechnungsstellung')"
    :hasFilters="true"
  >
    <template #filters>
      <div class="filter-wrapper">
        <div class="form-group">
          <profile-input
            :editMode="true"
            clearable
            v-model="filterClient"
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.auftraggeber')"
            :placeholder="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.auftraggeber')"
          />
        </div>
        <div class="form-group">
          <profile-input
            :editMode="true"
            clearable
            v-model="filterManager"
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
            :placeholder="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
          />
        </div>
        <div class="form-group" style="width: 230px">
          <profile-select
            :multiple="false"
            :editMode="true"
            v-model="activeReport"
            :items="activeReportItems"
            :title="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusType')"
          />
        </div>
        <div class="form-group">
          <span class="n-profile-label-title">Filter:</span>
          <br />
          <el-checkbox-group v-model="runtimeFilter">
            <el-checkbox-button
              v-for="option in [
                { label: 'Laufende', value: 'RUNNING' },
                { label: 'Vergangene', value: 'PREVIOUS' },
              ]"
              :label="option.value"
              :key="option.value"
              class="icon-larger"
            >
              <filter-type-icon v-if="option.value !== 'ALL'" :type="option.value" />
              {{ option.label }}</el-checkbox-button
            >
          </el-checkbox-group>
        </div>
        <div class="form-group">
          <span class="n-profile-label-title">Filter:</span>
          <br />
          <el-checkbox-group v-model="activeFilter">
            <el-checkbox-button label="ACTIVE">Aktiv</el-checkbox-button>
            <el-checkbox-button label="INACTIVE">Inaktiv</el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div class="form-group" v-if="accessRights === 'full'" style="margin-left: 10px">
          <profile-switch
            v-model="filterVisible"
            :title="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.filterVisible')"
            :active-text="$t('src.components.employees.profile.ja')"
            :inactive-text="$t('src.components.employees.profile.nein')"
          />
        </div>
      </div>
    </template>
    <div v-loading="loading" style="height: 100%">
      <template v-if="activeReport === 'with_invoice_state_active'">
        <el-table
          :data="filteredData"
          max-height="auto"
          class="d-widget-table"
          v-loading="loading"
          :row-class-name="getRowClassName"
        >
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusType')"
            width="230"
          >
            <template v-slot="scope">
              <project-color :color="getProjectColor(scope.row)" />
              {{ statusTypeFormatter() }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektname')"
            width="400"
            prop="bvName"
            sortable
            :formatter="formatBvName"
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
            min-width="200"
            prop="currentManagerName"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.auftraggeber')"
            min-width="200"
            prop="client"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektlaufzeit')"
            prop="dateRange"
            width="200"
            :formatter="dateRangeFormatter"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.num')"
            prop="number"
            width="100"
            :formatter="invoiceNumFormatter"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.leistungszeitraum')"
            prop="invoiceDateRange"
            width="200"
            sortable
          >
            <template v-slot="scope">
              <span v-if="scope.row.invoiceDateRange">
                {{ scope.row.invoiceDateRange.join(" - ") }}
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.price')"
            prop="price"
            width="150"
            sortable
          >
            <template v-slot="scope">
              <span v-if="scope.row.price"> {{ scope.row.price }} &#8364; </span>
            </template>
          </el-table-column> -->
          <el-table-column prop="_id" width="50">
            <template v-slot="scope">
              <router-link :to="getLinkToInvoiceOpened(scope.row)" style="font-size: 1.4rem">
                <open-in-new-icon />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <el-table
          :data="filteredData"
          style="width: 100%"
          max-height="auto"
          class="d-widget-table"
          v-loading="loading"
          :row-class-name="getRowClassName"
        >
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusType')"
            width="230"
          >
            <template v-slot="scope">
              <project-color :color="getProjectColor(scope.row)" />
              {{ statusTypeFormatter() }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektname')"
            width="400"
            prop="bvName"
            :formatter="formatBvName"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.aktuellerBauleiter')"
            min-width="200"
            prop="currentManagerName"
            sortable
          >
          </el-table-column>
          <el-table-column
            v-if="activeReport === 'not_fully_covered' || activeReport === 'missing_final_invoice'"
            :label="$t('Daterange(s)')"
            max-width="200"
            prop="unInvoicedDateRanges"
          >
            <template slot-scope="scope" v-if="scope.row.unInvoicedDateRanges">
              <div
                :key="idx"
                v-for="(range, idx) in scope.row.unInvoicedDateRanges"
                style="margin: 2.5px 5px; display: inline-block"
              >
                <el-tag size="mini">{{ formatDateRange(range) }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activeReport === 'without_ar'"
            :label="$t('Month(s)')"
            max-width="200"
            prop="unInvoicedMonths"
          >
            <template slot-scope="scope" v-if="scope.row.unInvoicedMonths">
              <div
                :key="idx"
                v-for="(date, idx) in scope.row.unInvoicedMonths"
                style="margin: 2.5px 5px; display: inline-block"
              >
                <el-tag size="mini">{{ formatMonth(date) }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.auftraggeber')"
            min-width="200"
            prop="client"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.projektlaufzeit')"
            prop="dateRange"
            width="200"
            :formatter="dateRangeFormatter"
            sortable
          >
          </el-table-column>
          <el-table-column
            :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.tageSeitLetzterRechnung')"
            width="190"
            prop="daysWithoutInvoice"
            :formatter="daysSinceDate"
            sortable
          >
          </el-table-column>
          <el-table-column label="" prop="_id" width="130">
            <template v-slot="scope">
              <el-button v-if="allowedToHide(scope.row)" @click="hideField(scope.row)">
                {{ $t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.quittert") }}
              </el-button>
              <el-button v-if="allowedToDismissHide(scope.row)" @click="dismissHideField(scope.row)">
                {{ $t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.anzeigen") }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="_id" width="50" v-if="hasAccessToInvoices">
            <template v-slot="scope">
              <router-link :to="getLinkToInvoice(scope.row)" style="font-size: 1.4rem">
                <open-in-new-icon />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </widget-tab>
</template>

<script>
import {
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Button,
  Input,
  Message,
  Tag,
  CheckboxButton,
  CheckboxGroup,
} from "element-ui";
import { moment } from "src/config/moment";
import { debounce, last, get } from "lodash";
import OpenInNewIcon from "vue-material-design-icons/OpenInNew";
import WidgetTab from "./Widgets/WidgetTab.vue";
import ProjectColor from "src/components/Project/ProjectColor.vue";
import { mapState, mapActions } from "vuex";
import FilterTypeIcon from "src/components/UIComponents/Helper/FilterTypeIcon.vue";

export default {
  name: "invoice-state-widget",
  components: {
    FilterTypeIcon,
    ProjectColor,
    WidgetTab,
    OpenInNewIcon,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Button.name]: Button,
    [Input.name]: Input,
    [Message.name]: Message,
    [Tag.name]: Tag,
  },
  props: {
    accessRights: { type: String },
  },
  data() {
    return {
      activeReportItems: [
        {
          label: this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.activeAndFuture"),
          value: "active_and_future",
        },
        {
          label: this.$t(
            "src.components.dashboard.views.dashboard.widgets.invoicestatewidget.nichtVollstndigBerechnet"
          ),
          value: "not_fully_covered",
        },
        {
          label: this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.inaktivOhneRechnungen"),
          value: "no_invoices",
        },
        {
          label: this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.ohneInvoiceImVormonat"),
          value: "without_ar",
        },
        {
          label: this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.withInvoiceActive"),
          value: "with_invoice_state_active",
        },
        {
          label: this.$t("missingFinalInvoice"),
          value: "missing_final_invoice",
        },
      ],
      filterVisible: false,
      loading: false,
      filterClient: "",
      filterManager: "",
      activeReport: "active_and_future",
      noInvoicesReport: [],
      activeWithoutAR: [],
      notFullyCovered: [],
      withInvoiceStatusActive: [],
      missingFinalInvoice: [],
      activeAndFuture: [],
      filteredData: [],
      runtimeFilter: ["RUNNING", "PREVIOUS"],
      activeFilter: ["ACTIVE"],
    };
  },
  async mounted() {
    await this.fetchEmployeeMeta();
    this.fetchActiveAndFuture();
  },
  beforeUnmount() {
    // Reset the specific widget data this component was using
    this.resetWidget("invoiceStatus");
  },
  created() {
    this.debounceFilterEntries = debounce(this.filterEntries, 400);
  },
  methods: {
    ...mapActions("widget", ["widgetLoaded", "resetWidget"]),
    formatMonth(date) {
      return moment(date).format("MMM YY");
    },
    formatDateRange(range) {
      if (range) {
        return `${moment(range.start).format("L")}-${moment(range.end).format("L")}`;
      } else {
        return "";
      }
    },
    getRowClassName({ row }) {
      if (row.isHidden) {
        return "inactive-row";
      }
    },
    statusTypeFormatter() {
      return this.activeReportItems.find((item) => item.value === this.activeReport).label;
    },
    dateRangeFormatter(props) {
      const { dateRange } = props;
      if (dateRange && dateRange.length) {
        return `${moment(dateRange[0]).format("L")} - ${moment(dateRange[1]).format("L")}`;
      } else {
        return "";
      }
    },
    formatBvName(props) {
      const { bvName, constructionPhase } = props;
      let name = bvName;
      if (constructionPhase) {
        name += ` | BA ${constructionPhase}`;
      }
      return name;
    },
    invoiceDateRangeFormatter(props) {
      const { invoiceDateRange } = props;
      if (invoiceDateRange && invoiceDateRange.length) {
        return invoiceDateRange.join(" - ");
      } else {
        return "";
      }
    },
    invoiceNumFormatter(props) {
      const { number, shortname } = props;
      return `${shortname}${number ? ` #${number}` : ""}`;
    },
    daysSinceDate(props) {
      const { latestInvoiceDate, isFutureProject } = props;
      if (latestInvoiceDate) {
        return moment().diff(latestInvoiceDate, "days");
      } else if (isFutureProject) {
        return "noch nicht begonnen";
      } else {
        return "bisher keine Rechnung";
      }
    },
    getLinkToInvoice(record) {
      return {
        name: "Project View",
        params: { id: record._id, tab_pane: "invoices" },
      };
    },
    getLinkToInvoiceOpened(record) {
      return {
        name: "Project View",
        params: { id: record._id, tab_pane: "invoices" },
        query: { invoiceId: record.invoiceId },
      };
    },
    // allow to hide only if all invoices are invoiced ("berechnet")
    allowedToHide(rowData) {
      if (
        !rowData.isHidden &&
        rowData.invoiceHistory &&
        rowData.invoiceHistory.every((invoice) => invoice.status === "INVOICED")
      ) {
        return true;
      } else {
        return false;
      }
    },
    allowedToDismissHide(rowData) {
      return this.accessRights === "full" && rowData.isHidden;
    },
    async fetchEmployeeMeta() {
      try {
        const response = await this.axios.get("/api/employees/meta");
        this.employees = response.data;
      } catch (error) {
        throw error;
      }
    },
    async hideField(record) {
      try {
        this.loading = true;
        const formBody = { entityId: record._id, hash: record.hash };
        await this.axios.post("/api/hide-record", formBody);
        await this.fetchCurrentTab();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async dismissHideField(record) {
      try {
        this.loading = true;
        await this.axios.delete(`/api/hide-record/${record._id}`);
        await this.fetchCurrentTab();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchInactiveProjectsWithoutInvoices() {
      try {
        this.loading = true;
        const params = {};
        if (this.filterVisible) {
          params.showHidden = true;
        }
        const response = await this.axios.get("/api/project-invoices/reports/inactive-no-invoice", { params });
        const normalized = this.processConstructionManagers(response.data);
        this.noInvoicesReport = normalized;
        this.filteredData = this.noInvoicesReport;
        this.debounceFilterEntries();
        this.notifyTopWidget(
          this.noInvoicesReport,
          this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.inaktivOhneRechnungen")
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchActiveProjectsWithoutARForPrevMonth() {
      try {
        this.loading = true;
        const params = {};
        if (this.filterVisible) {
          params.showHidden = true;
        }
        const response = await this.axios.get("/api/project-invoices/reports/active-no-invoice", { params });
        const normalized = this.processConstructionManagers(response.data);
        this.activeWithoutAR = normalized;
        this.filteredData = this.activeWithoutAR;
        this.debounceFilterEntries();
        this.notifyTopWidget(
          this.activeWithoutAR,
          this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.ohneInvoiceImVormonat")
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchActiveProjectsNotFullyCovered() {
      try {
        this.loading = true;
        const params = {};
        if (this.filterVisible) {
          params.showHidden = true;
        }
        const response = await this.axios.get("/api/project-invoices/reports/active-not-fully-covered", { params });
        const normalized = this.processConstructionManagers(response.data);
        this.notFullyCovered = normalized;
        this.filteredData = this.notFullyCovered;
        this.debounceFilterEntries();
        this.notifyTopWidget(
          this.notFullyCovered,
          this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.nichtVollstndigBerechnet")
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchWithInvoiceStatusActive() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/project-invoices/reports/invoice-status-active");
        const normalized = this.processConstructionManagers(response.data);
        this.withInvoiceStatusActive = normalized;
        this.filteredData = this.withInvoiceStatusActive;
        this.debounceFilterEntries();
        this.notifyTopWidget(
          this.withInvoiceStatusActive,
          this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.withInvoiceActive")
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchMissingFinalInvoice() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/project-invoices/reports/missing-final-invoice");
        const normalized = this.processConstructionManagers(response.data);
        this.missingFinalInvoice = normalized;
        this.filteredData = this.missingFinalInvoice;
        this.debounceFilterEntries();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchActiveAndFuture() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/project-invoices/reports/active-and-future");
        const normalized = this.processConstructionManagers(response.data);
        this.activeAndFuture = normalized;
        this.filteredData = this.activeAndFuture;
        this.debounceFilterEntries();
        this.notifyTopWidget(
          this.activeAndFuture,
          this.$t("src.components.dashboard.views.dashboard.widgets.invoicestatewidget.activeAndFuture")
        );
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchCurrentTab() {
      switch (this.activeReport) {
        case "no_invoices":
          await this.fetchInactiveProjectsWithoutInvoices();
          break;
        case "without_ar":
          await this.fetchActiveProjectsWithoutARForPrevMonth();
          break;
        case "not_fully_covered":
          await this.fetchActiveProjectsNotFullyCovered();
          break;
        case "with_invoice_state_active":
          await this.fetchWithInvoiceStatusActive();
          break;
        case "missing_final_invoice":
          await this.fetchMissingFinalInvoice();
          break;
        case "active_and_future":
          await this.fetchActiveAndFuture();
          break;
        default:
          break;
      }
    },
    notifyTopWidget(data, label) {
      const record = {
        labelTemplate: label,
        color: "#BE3FB1",
        number: data.length,
      };
      //using vuex instead of event bus now
      this.widgetLoaded({ type: "invoiceStatus", payload: [record] });
    },
    filterEntries() {
      const filterClient = this.filterClient.toLowerCase();
      const filterManager = this.filterManager.toLowerCase();
      console.log("this.currentTabData", this.currentTabData);
      const today = moment();
      let filteredData = this.currentTabData.filter((item) => {
        let isShown = false;
        this.activeFilter.forEach((filter) => {
          if (filter === "ACTIVE") {
            isShown = item.active === true;
            return;
          }
          if (!isShown && filter === "INACTIVE") {
            isShown = item.active === false;
          }
        });
        return isShown;
      });
      filteredData = filteredData.filter((item) => {
        let isShown = false;
        if (!this.runtimeFilter.length) {
          return false;
        }
        this.runtimeFilter.forEach((filter) => {
          if (!isShown && filter === "RUNNING") {
            isShown = today.isBetween(item.dateRange[0], item.dateRange[1], "day", "[]");
          }
          if (!isShown && filter === "PREVIOUS") {
            isShown = today.isAfter(item.dateRange[1], "day");
          }
        });
        return isShown;
      });
      filteredData = filteredData.filter((item) => {
        let result = !filterClient && !filterManager;
        if (filterClient && item.client) {
          result = item.client.toLowerCase().indexOf(filterClient) !== -1;
        }
        if (filterManager && item.currentManagerName) {
          result = item.currentManagerName.toLowerCase().indexOf(filterManager) !== -1;
        }
        return result;
      });

      this.filteredData = filteredData;
    },
    // returns the data with name of current construction manager (if matches)
    processConstructionManagers(data) {
      const today = moment();
      return data.map((item) => {
        let currentManager;
        if (item.constructionManagers) {
          const managers = item.constructionManagers.filter((record) => record.position === "CONSTRUCTION_MANAGER");
          currentManager = managers.find((record) => today.within(moment.range(record.dateRange).snapTo("day")));
          if (!currentManager && managers.length) {
            currentManager = last(managers);
          }
        }
        if (currentManager) {
          let currentManagerName;
          const employee = this.employees.find((record) => record.id === currentManager.employee);
          if (employee) {
            currentManagerName = `${employee.firstName} ${employee.lastName}`;
          } else {
            currentManagerName = "";
          }
          item.currentManagerName = currentManagerName;
        }
        return item;
      });
    },
    getProjectColor(data) {
      if (moment(data.dateRange[0]).isBefore(new Date(), "day")) {
        return "#f42020";
      } else {
        return "transparent";
      }
    },
  },
  computed: {
    ...mapState("account", { appAccessRights: "accessRights" }),
    hasAccessToInvoices() {
      const projectAccess = !!get(this.appAccessRights, "project.generalAccess");
      if (!projectAccess) {
        return projectAccess;
      }
      return !!get(this.appAccessRights, "project.specificAccess.invoices");
    },
    currentTabData() {
      switch (this.activeReport) {
        case "no_invoices":
          return this.noInvoicesReport;
        case "without_ar":
          return this.activeWithoutAR;
        case "not_fully_covered":
          return this.notFullyCovered;
        case "with_invoice_state_active":
          return this.withInvoiceStatusActive;
        case "missing_final_invoice":
          return this.missingFinalInvoice;
        case "active_and_future":
          return this.activeAndFuture;
        default:
          return [];
      }
    },
  },
  watch: {
    activeReport(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchCurrentTab();
        // clear filter on tab change
        this.filterClient = "";
        this.filterManager = "";
      }
    },
    filterClient(newVal) {
      this.debounceFilterEntries();
    },
    runtimeFilter(newVal) {
      this.debounceFilterEntries();
    },
    activeFilter(newVal) {
      this.debounceFilterEntries();
    },
    filterManager(newVal) {
      this.debounceFilterEntries();
    },
    filterVisible() {
      this.fetchCurrentTab();
    },
  },
};
</script>

<style lang="scss">
.d-widget-table.el-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .el-table__header-wrapper {
    flex-shrink: 0;
  }
  .el-table__body-wrapper {
    height: 100%;
    overflow: auto;
  }
  th.el-table__cell > .cell {
    color: #8f94a3;
    font-size: 12px;
  }
  td.el-table__cell {
    border-bottom: 1px solid #edf0f7;
  }
}
</style>
