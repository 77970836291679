const state = {
  defaultOptions: [
    {
      value: "HOTEL",
      label: "Hotel",
    },
  ],
};

export const defaultServiceProviders = {
  namespaced: true,
  state,
};
