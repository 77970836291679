<template>
  <div>
    <div class="group-subtitle">
      {{ $t("Specific access") }}
      <div class="group-description">
        {{ $t("userGroupProfile.employeeSpecificDescription") }}
      </div>
    </div>
    <access-rights-table>
      <access-rights-row
        :maximumAccess="maximumAccess"
        :canEdit="canEdit"
        label="Geschäftsdaten"
        v-model="value.general"
        :description="$t('userGroupProfile.employee.general')"
      />
      <access-rights-row
        :maximumAccess="maximumAccess"
        :canEdit="canEdit"
        label="Private Daten"
        v-model="value.privateData"
        :description="$t('userGroupProfile.employee.privateData')"
      />
      <access-rights-row
        :maximumAccess="maximumAccess"
        :canEdit="canEdit"
        label="Private Daten Ausland"
        v-model="value.privateDataAbroad"
        :description="$t('userGroupProfile.employee.privateDataAbroad')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Status"
        v-model="value.status"
        :description="$t('userGroupProfile.employee.status')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Dokumente"
        v-model="value.documents"
        :description="$t('userGroupProfile.employee.documents')"
      />
      <access-rights-row
        :canEdit="canEdit"
        :maximumAccess="maximumAccess"
        label="Benutzer"
        v-model="value.user"
        :description="$t('userGroupProfile.employee.user')"
      />
    </access-rights-table>
  </div>
</template>

<script>
import AccessRightsRow from "./AccessRightsRow.vue";
import AccessRightsTable from "./AccessRightsTable.vue";

export default {
  name: "employee-profile-access",
  components: { AccessRightsRow, AccessRightsTable },
  props: {
    value: { type: Object, default: () => ({}) },
    maximumAccess: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    canEdit: Boolean,
  },
};
</script>

<style>
</style>