const state = {
    visible: false,
    calendarEvent: {},
    databaseEvents: [],
};

const mutations = {
    init(state, { calendarEvent, databaseEvents }) {
        state.visible = true;
        state.calendarEvent = calendarEvent;
        state.databaseEvents = databaseEvents;
    },
    hide(state) {
        state.visible = false;
        state.calendarEvent = {};
        state.databaseEvents = [];
    }
};

const actions = {
    init({ commit }, payload) {
        commit('init', payload);
    },
    hide({ commit }) {
        commit('hide');
    }
};

export const deleteCalendarEvent = {
    namespaced: true,
    state,
    mutations,
    actions,
}