<template>
  <el-drawer
    :withHeader="false"
    :visible="visible"
    direction="rtl"
    :destroy-on-close="true"
    size="500px"
    @close="close"
  >
    <div class="container" v-loading="loading">
      <div class="drawer-back" @click="close"><chevron-left-icon /> {{ projectName }}</div>
      <div class="drawer-info">
        <div class="drawer-name">{{ $t("src.components.project.pickpack.archivedreports.archiv") }}</div>
      </div>
      <perfect-scrollbar style="height: calc(100vh - 93px)" :options="{ suppressScrollX: true }">
        <div
          :class="['upload-file-item', reportTypeClass[report.reportType]]"
          v-for="report in reports"
          :key="report._id"
        >
          <div>
            <div class="upload-file-item__date">
              {{ report.createdAt | formatDate }} {{ report.dateRange | formatDateRange }}
            </div>
            <div class="flex align-items-center">
              <div class="upload-file-item__icon"><attachment-icon /></div>
              <div class="upload-file-item__name" style="top: 0" :title="report.name">
                {{ report.name }}
              </div>
            </div>
          </div>
          <div style="flex-shrink: 0">
            <div class="upload-file-item__action" @click="viewFile(report)"><eye-icon /></div>
            <div class="upload-file-item__action" @click="downloadFile(report)"><download-icon /></div>
            <div class="upload-file-item__action" v-if="isEditMode" @click="removeFile(report)">
              <delete-icon />
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </el-drawer>
</template>

<script>
import { Drawer, Message } from "element-ui";
import { moment } from "src/config/moment";
import AttachmentIcon from "vue-material-design-icons/Attachment";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft";
import DownloadIcon from "vue-material-design-icons/Download";
import DeleteIcon from "vue-material-design-icons/Delete";
import EyeIcon from "vue-material-design-icons/Eye";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapActions } from "vuex";

export default {
  name: "archived-reports",
  props: {
    visible: { type: Boolean },
    isEditMode: { type: Boolean },
    projectId: { type: String },
    projectName: { type: String },
  },
  components: {
    PerfectScrollbar,
    [Drawer.name]: Drawer,
    [ChevronLeftIcon.name]: ChevronLeftIcon,
    [DownloadIcon.name]: DownloadIcon,
    [DeleteIcon.name]: DeleteIcon,
    [EyeIcon.name]: EyeIcon,
    [AttachmentIcon.name]: AttachmentIcon,
  },
  data() {
    return {
      reportTypeClass: {
        project_list: "project-list",
        pick_pack: "pick-pack",
      },
      loading: false,
      reports: [],
    };
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    close() {
      this.$emit("close");
    },
    async fetchReports() {
      try {
        this.loading = true;
        const response = await this.axios.get(`/api/reports/generated/${this.projectId}`);
        this.reports = response.data;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    viewFile(report) {
      if (report.url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + report.url;
        this.openPdf({ url: completeUrl, fileName: report.name });
      } else {
        window.open(`${report.url}?view=true`, "_blank");
      }
    },
    downloadFile(report) {
      if (report.url) {
        window.open(report.url, "_blank");
      } else {
        console.warn("Could not parse report. Report object below.");
        console.log(JSON.stringify(report, null, 2));
      }
    },
    removeFile(report) {
      this.$confirmDelete().then(async () => {
        await this.axios.delete(`/api/reports/generated/${report._id}`);
        await this.fetchReports();
      });
    },
  },
  watch: {
    visible(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.fetchReports();
      } else if (!newVal && oldVal) {
        this.reports = [];
      }
    },
  },
  filters: {
    formatDate(dateStr) {
      if (dateStr) {
        return moment(dateStr).format("L LT");
      }
      return "";
    },
    formatDateRange(dateRange) {
      if (dateRange.length) {
        return `(${moment(dateRange[0]).format("L")}-${moment(dateRange[1]).format("L")})`;
      }
      return "";
    },
  },
};
</script>

<style>
.upload-file-item__date {
  font-size: 12px;
  color: #727272;
}
.project-list {
  border-radius: 0;
  box-shadow: inset 3px 0px 0px 0px #7575ff;
}
.pick-pack {
  border-radius: 0;
  box-shadow: inset 3px 0px 0px 0px #fb6060;
}
</style>
