<template>
  <div :class="['todo-assignments', { vertical: vertical }]">
    <template>
      <div class="todo-date-name mr-3">
        <div class="todo-name">{{ getCreatorName }}</div>
        <div class="todo-date">{{ $t("Created") }}: {{ data.createdAt | formatDateTime }}</div>
        <div class="todo-date" v-if="isWorkshop && data.workshopName && data.workshopName !== data.projectName">
          {{ $t("Project") }}: {{ data.projectName }}
        </div>
      </div>
      <div :class="['todo-progressbar', { vertical: vertical }]">
        <div :class="['todo-closed_at', leftCircleAndFillColorClass]">{{ getClosedAtText }}</div>
        <div :class="['progress-circle', leftCircleAndFillColorClass]"></div>
        <div class="progress-line">
          <div :class="['progress-line-fill', leftCircleAndFillColorClass]" :style="{ width: progressWidth }"></div>
        </div>
        <div :class="['progress-circle', rightCircleColorClass]"></div>
      </div>
      <div class="todo-date-name">
        <div class="todo-name">{{ getAssigneeName }}</div>
        <div class="todo-date" v-if="data.dueDate">{{ $t("Deadline") }}: {{ data.dueDate | formatDate }}</div>
        <div class="todo-date" v-if="isWorkshop && data.workshopFreeText">
          {{ $t("Assignee") }}: <span>{{ data.workshopFreeText }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { moment } from "src/config/moment";

export default {
  name: "todo-progressbar",
  props: {
    data: Object,
    vertical: Boolean,
    isWorkshop: Boolean,
  },
  data() {
    return {
      progressWidth: "0%",
      leftCircleAndFillColorClass: null,
      rightCircleColorClass: null,
    };
  },
  mounted() {
    this.setProgressWidth();
    this.setColorClasses();
  },
  methods: {
    setProgressWidth() {
      if (this.data.closedAt) {
        this.progressWidth = "100%";
      } else if (this.data.status !== "TODO") {
        this.progressWidth = this.data.progress || 100 + "%";
      }
    },
    setColorClasses() {
      this.rightCircleColorClass = null;
      let leftCircleAndFillColorClass = null;
      if (this.data.status === "TODO") {
        leftCircleAndFillColorClass = "todo";
        // return;
      }
      const endDate = moment(this.data.closedAt || new Date());
      const isOverdue = moment(endDate).isAfter(this.data.dueDate, "day");
      const isDue = this.data.dueDate && moment(endDate).isSame(this.data.dueDate, "day");
      if (isOverdue) {
        leftCircleAndFillColorClass = "overdue";
      }
      // should be yellow when finished on due date
      if (isDue) {
        leftCircleAndFillColorClass = "in-progress";
      }
      if (this.data.status === "IN_PROGRESS" && !isOverdue) {
        leftCircleAndFillColorClass = "in-progress";
        // fill right circle only if task is finished
      } else if (this.data.status === "DONE") {
        leftCircleAndFillColorClass ||= "done";
        this.rightCircleColorClass = leftCircleAndFillColorClass;
      }
      this.leftCircleAndFillColorClass = leftCircleAndFillColorClass;
    },
  },
  computed: {
    getCreatorName() {
      if (this.data.isNew) {
        return `${this.user.name} (Sie)`;
      } else {
        return this.data.creatorName;
      }
    },
    getAssigneeName() {
      if (this.data.isResponsible) {
        return `${this.data.responsibleName} (Sie)`;
      } else if (this.data.responsibleName) {
        return this.data.responsibleName;
      } else if (this.data.workshopName) {
        return `Dauer-Projekt: ${this.data.workshopName}`;
      } else {
        return "";
      }
    },
    getClosedAtText() {
      if (this.data.closedAt) {
        return ` ${this.$t("closed at")} ${moment(this.data.closedAt).format("L, HH:MM")}`;
      } else if (moment().isAfter(this.data.dueDate, "day")) {
        const today = moment();
        if (today.isAfter(this.data.dueDate, "day")) {
          const days = today.diff(this.data.dueDate, "days");
          return this.$tc("overdue", days, { count: days });
        }
        return "";
      }
    },
  },
  filters: {
    formatDateTime(dateString) {
      if (dateString) {
        return moment(dateString).format("LLL");
      } else {
        return "";
      }
    },
    formatDate(dateString) {
      if (dateString) {
        return moment(dateString).format("L");
      } else {
        return "";
      }
    },
  },
  watch: {
    "data.status": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setColorClasses();
        this.setProgressWidth();
      }
    },
    "data._id": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setColorClasses();
        this.setProgressWidth();
      }
    },
  },
};
</script>

<style></style>
