const state = {
    serverVersion: "none",
    clientVersion: "none",
};

const getters = {
    getServerVersion(state) {
        return state.serverVersion;
    },
    getClientVersion(state) {
        return state.clientVersion;
    },
};

const mutations = {
    setServerVersion(state, version) {
        state.serverVersion = version;
    },
    setClientVersion(state, version) {
        state.clientVersion = version;
    }
};

export const versions = {
    namespaced: true,
    state,
    getters,
    mutations
};