import Vue from "vue";
import "./pollyfills";
import { localize, extend } from "vee-validate";
import de from "vee-validate/dist/locale/de.json";
import lang from "element-ui/lib/locale/lang/de";
import locale from "element-ui/lib/locale";
import App from "./App.vue";
import Login from "./components/Login.vue";
import { Loading, Message, MessageBox, Notification } from "element-ui";
import { store } from "./store";
import { router } from "./routes";
import { sync } from "vuex-router-sync";
import "./keycloak";
import { updateToken } from "./updateToken";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import globalAxios from "./globalAxios";
import OneSignalVue from "onesignal-vue";

// library imports
import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/element_variables.scss";
import "./assets/sass/demo.scss";
import "./assets/sass/nadja-base.scss";
//load sidebar
import sidebarLinks from "./sidebarLinks";

import "./registerServiceWorker";

//translation
import i18n from "./i18n";
import { veeRules } from "./utils/veeRules";

// plugin setup
Vue.use(OneSignalVue);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(Loading.directive);
Vue.use(SideBar, { sidebarLinks: sidebarLinks });

localize("de", de);
localize({
  de: {
    names: {
      Email: "E-Mail",
      email: "E-Mail",
    },
    messages: {
      required: (field) => `Bitte ${field} eintragen`,
    },
  },
});
// apply vee-validate rules
Object.keys(veeRules).forEach((rule) => {
  extend(rule, veeRules[rule]);
});

Vue.use(globalAxios);

Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$confirmDelete = (message) =>
  MessageBox.confirm(message || "Sind Sie sicher?", "Löschen?", {
    confirmButtonText: "Ja",
    cancelButtonText: "Nein",
    type: "warning",
    confirmButtonClass: "el-button--danger",
    cancelButtonClass: "button-default",
  });
locale.use(lang);
// provides route props to vuex store
const unsync = sync(store, router);

const realm = localStorage.getItem("realm") || "";

if (!realm) {
  new Vue({
    i18n,
    router,
    render: (h) => h(Login),
  }).$mount("#app");
} else {
  // setTimeout - to handle uncaught error when invalid realm name is set.
  const errorTimeout = window.setTimeout(() => {
    const refreshCount = Number(sessionStorage.getItem("refreshCount") || 0);
    if (refreshCount >= 1) {
      localStorage.removeItem("realm");
      sessionStorage.removeItem("refreshCount");
      window.location.reload();
    } else {
      sessionStorage.setItem("refreshCount", 1);
      window.location.reload();
    }
  }, 1900);
  Vue.$keycloak
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      enableLogging: false,
    })
    .then((authenticated) => {
      clearTimeout(errorTimeout);
      new Vue({
        i18n,
        router,
        store,
        render: (h) => h(App),
        created: function () {
          if (authenticated) {
            const loginData = this.$keycloak.tokenParsed;
            loginData.token = this.$keycloak.token;
            loginData.refreshToken = this.$keycloak.refreshToken;
            // localStorage.setItem(TOKENS, JSON.stringify(loginData));
            store.commit("account/loginSuccess", loginData);
            let authorization;
            // const script = document.createElement("script");
            // script.type = "text/javascript";
            // script.src = `${process.env.VUE_APP_KEYCLOAK_URL}/js/keycloak-authz.js`;
            // script.addEventListener("load", () => {
            authorization = new KeycloakAuthorization(this.$keycloak);
            const queryParams = new URLSearchParams(window.location.search);
            const isKioskMode = queryParams.get("kiosk") === "true";
            const onSuccess = () =>
              authorization.entitlement("restapi-client").then((rpt) => {
                // Decode jwt to see permissions under "authorization.permissions"
                store.commit("account/setRPT", { token: rpt, isKioskMode });
              });
            const onError = (err) => {
              console.log("errored", err, authorization);
            };

            if (authorization.ready) {
              authorization.ready.then(onSuccess).catch(onError);
            } else {
              authorization.resolve = onSuccess;
              authorization.reject = onError;
            }
            // });
            // document.body.appendChild(script);
          } else {
            console.log("VUE: NOT AUTHENTICATED. Redirecting to login...");
            this.$keycloak.login({ redirectUri: window.location.href });
          }
        },
        destroyed() {
          unsync();
        },
      }).$mount("#app");
      window.onfocus = async () => {
        try {
          await updateToken();
        } catch (error) {
          Vue.$keycloak.logout();
        }
      };
      setInterval(async () => {
        try {
          await updateToken();
        } catch (error) {
          Vue.$keycloak.logout();
        }
      }, 60000);
    });
}
