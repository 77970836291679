<template>
    <el-button 
        type="danger"
        @click="handleClick">
            <i class="nc-icon el-icon-delete"></i> {{text}}
    </el-button> 
</template>

<script>
export default {
  name:'delete-button',
  props:{
      text: String
  },
  methods: {
      handleClick(evt) {
        this.$emit('click', evt);
      }
  }
};
</script>

<style>
</style>

