<template>
  <div style="margin-top: -25px" v-loading="loading">
    <h4 class="n-profile-subtitle" style="margin-left: 0px; padding-top: 20px">
      {{ $t("src.components.project.downloadcenter.documenttemplates.vorlagen")
      }}<template-variables-guide infoType="icon" />
    </h4>
    <profile-date-picker
      v-if="projectDateRange"
      :min-date="projectDateRange[0]"
      :max-date="projectDateRange[1]"
      v-model="dateSelection"
      style="max-width: 350px"
      :focusDate="today"
      required
      is-range
      :title="$t('src.components.project.pickpack.pickpacklist.zeitraum')"
      :placeholder="$t('src.components.project.pickpack.pickpacklist.zeitraum')"
    />
    <div v-for="projectType in projectTypes" :key="projectType._id">
      <h4 class="profile-title">{{ projectType.name }}</h4>
      <div
        class="document-template-item"
        v-for="documentTemplate in projectType.documentTypes"
        :key="documentTemplate._id"
      >
        <div>
          <div class="document-template-title">{{ documentTemplate.name }}</div>
          <div class="document-template-meta">{{ documentTemplate.template.name }}</div>
        </div>
        <div class="document-template-actions">
          <el-dropdown trigger="click" @command="handleTemplateCommand($event, documentTemplate)">
            <button class="btn btn-sm btn-ghost card-btn" @click.stop style="font-size: 1rem">
              <dots-vertical-icon />
            </button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="canGenerateTemplate(documentTemplate.extension)" command="generateTemplate">
                <file-plus-icon class="mr-2" />
                {{ $t("generateTemplate") }}
              </el-dropdown-item>
              <el-dropdown-item command="generateEditedTemplate">
                <upload-icon class="mr-2" />
                {{ $t("uploadTemplate") }}
              </el-dropdown-item>
              <el-dropdown-item command="downloadTemplate">
                <download-icon class="mr-2" />
                {{ $t("downloadTemplate") }}
              </el-dropdown-item>
              <el-dropdown-item v-if="canDownloadAsPdf(documentTemplate.extension)" command="downloadAsPdf">
                <file-pdf-box-icon class="mr-2" />
                {{ $t("downloadAsPdf") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <el-dialog :visible="dndModalVisible" @close="dismissDndModal" width="800px" destroy-on-close>
      <drawer-upload
        action=""
        :multiple="false"
        :limit="1"
        :on-remove="fileRemoved"
        :accept="currentExtension"
        :on-error="handleUploadError"
        :http-request="fileUploaded"
        :on-exceed="handleExceed"
        :file-list="dndModalFileList"
        class="mb-2"
        :allow-download="false"
      />
      <span v-if="showFileRequired" class="el-form-item__error">{{
        $t("src.components.project.downloadcenter.documenttemplates.bitteDateiAuswhlen")
      }}</span>
      <div class="flex justify-between">
        <el-button @click="dismissDndModal">{{
          $t("src.components.project.downloadcenter.documenttemplates.abbrechen")
        }}</el-button>
        <el-button type="primary" @click="handleGenerateEditedTemplate">{{
          $t("src.components.project.downloadcenter.documenttemplates.speichern")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message, MessageBox, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import DownloadIcon from "vue-material-design-icons/Download.vue";
import UploadIcon from "vue-material-design-icons/Upload.vue";
import FilePlusIcon from "vue-material-design-icons/FilePlus.vue";
import { saveAs } from "file-saver";
import DrawerUpload from "src/components/Project/Invoices/DrawerUpload.vue";
import TemplateVariablesGuide from "./TemplateVariablesGuide.vue";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import FilePdfBox from "vue-material-design-icons/FilePdfBox.vue";
import { moment } from "src/config/moment";

export default {
  name: "document-templates",
  components: {
    DownloadIcon,
    DrawerUpload,
    UploadIcon,
    FilePlusIcon,
    TemplateVariablesGuide,
    [DotsVertical.name]: DotsVertical,
    [FilePdfBox.name]: FilePdfBox,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Message.name]: Message,
  },
  props: {
    projectId: { type: String },
    projectDateRange: { type: Array },
    projectTypes: { type: Array },
    loading: { type: Boolean },
  },
  data() {
    return {
      today: new Date(),
      dndModalVisible: false,
      showFileRequired: false,
      dndModalPayload: null,
      dndModalFile: null,
      dateSelection: null,
    };
  },
  methods: {
    handleTemplateCommand(actionType, documentTemplate) {
      switch (actionType) {
        case "generateTemplate":
          this.handleGenerateTemplate(documentTemplate);
          break;
        case "generateEditedTemplate":
          this.showGenerateEditedTemplateModal(documentTemplate);
          break;
        case "downloadTemplate":
          this.downloadTemplate(documentTemplate);
          break;
        case "downloadAsPdf":
          this.downloadAsPdf(documentTemplate);
          break;

        default:
          console.warn("Unknown dropdown action: ", actionType);
          break;
      }
    },
    async handleGenerateTemplate(record) {
      try {
        if (!this.dateSelection || !this.dateSelection.length) {
          Message.error("Bitte wählen Sie einen Zeitraum aus");
          return;
        }
        const formData = new FormData();
        formData.append("projectId", this.projectId);
        formData.append("dateRange", [
          moment(this.dateSelection[0]).startOf("day").toISOString(),
          moment(this.dateSelection[1]).endOf("day").toISOString(),
        ]);
        formData.append("documentType", record._id);
        await this.axios.post("/api/project-documents", formData);
        Message.success("Datei wurde hinzugefügt");
        MessageBox.alert("Bitte passen Sie das Dokument entprechend den Spezifika dieses Projekts an", {
          confirmButtonText: "OK",
        });
        this.$emit("documentAdded");
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async showGenerateEditedTemplateModal(record) {
      this.dndModalVisible = true;
      this.dndModalPayload = { ...record };
    },
    async handleGenerateEditedTemplate() {
      if (!this.dndModalFile) {
        this.showFileRequired = true;
        return;
      }
      if (!this.dateSelection || !this.dateSelection.length) {
        Message.error("Bitte wählen Sie einen Zeitraum aus");
        return;
      }
      try {
        const formData = new FormData();
        formData.append("file", this.dndModalFile);
        formData.append("dateRange", [
          moment(this.dateSelection[0]).startOf("day").toISOString(),
          moment(this.dateSelection[1]).endOf("day").toISOString(),
        ]);
        formData.append("projectId", this.projectId);
        formData.append("documentType", this.dndModalPayload._id);
        await this.axios.post("/api/project-documents", formData);
        this.dismissDndModal();
        Message.success("Datei wurde hinzugefügt");
        this.$emit("documentAdded");
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async downloadAsPdf(record) {
      const loadingString = this.$t("loadingString");
      this.$message({
        type: null,
        dangerouslyUseHTMLString: true,
        message: `<i class="el-icon-loading"></i> ${loadingString}`,
      });
      try {
        const response = await this.axios.get(`/api/project-document-types/${record._id}/pdf`, {
          responseType: "blob",
        });
        const objectUrl = window.URL.createObjectURL(response.data);
        window.open(objectUrl, "_blank");
      } catch (error) {
        throw error;
      } finally {
        this.$message.close();
      }
    },
    downloadTemplate(record) {
      // const response = await this.axios.request({
      //   method: "POST",
      //   url: "/api/projects/hotel-report-pdf",
      //   data: formBody,
      //   responseType: "blob",
      // });
      window.open(record.template.url, "_blank");
    },
    dismissDndModal() {
      this.dndModalVisible = false;
      this.dndModalPayload = null;
      this.dndModalFile = null;
      this.showFileRequired = false;
    },
    fileRemoved() {
      this.dndModalFile = null;
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    fileUploaded({ data, file, filename }) {
      this.dndModalFile = file;
    },
    handleExceed(files, fileList) {
      this.fileUploaded({ file: files[0] });
    },
    canDownloadAsPdf(extension) {
      return extension.toLowerCase() !== ".pdf";
    },
    canGenerateTemplate(extension) {
      return true;
      if (extension === ".xlsx" || extension === ".docx") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    dndModalFileList() {
      if (this.dndModalFile) {
        return [this.dndModalFile];
      } else {
        return [];
      }
    },
    currentExtension() {
      if (this.dndModalPayload) {
        return this.dndModalPayload.extension;
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
.document-template-item {
  padding: 16px;
  border: 1px solid #edf0f7;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.document-template-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.document-template-meta {
  font-size: 12px;
  color: #455070;
}
.document-template-actions {
  flex-shrink: 0;
}
</style>
