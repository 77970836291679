<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <h1 class="n-view-title" style="margin-bottom: 10px">Dashboard</h1>
    <div class="mb-3">
      <widgets-area :accessRights="dashboardAccess.specificAccess" />
    </div>
    <el-tabs v-model="activeName" type="card" class="d-tabs">
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.birthdayswidget.geburtstage')"
        name="birthdays"
        v-if="dashboardAccess.specificAccess.birthdays"
      >
        <birthdays-widget />
      </el-tab-pane>
      <el-tab-pane
        :label="
          $t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.gltigkeitVonRessourcendokumenten')
        "
        name="documents_state"
        v-if="dashboardAccess.specificAccess.documentsValidity"
      >
        <documents-state-widget :accessRights="dashboardAccess.specificAccess.documentsValidity"
      /></el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.fehlendeDatenstze')"
        name="empty_fields"
        v-if="dashboardAccess.specificAccess.missingData"
      >
        <empty-fields-widget :accessRights="dashboardAccess.specificAccess.missingData"
      /></el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.unavailableResources.title')"
        name="unavailable_resources"
        v-if="dashboardAccess.specificAccess.unavailableResources"
        ><unavailable-resources-widget :accessRights="dashboardAccess.specificAccess.unavailableResources" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.financeReport.financeReport')"
        name="financeReport"
        v-if="dashboardAccess.specificAccess.financeReport"
        ><finance-report-widget :accessRights="dashboardAccess.specificAccess.financeReport" />
      </el-tab-pane>
      <el-tab-pane
        :label="$t('src.components.dashboard.views.dashboard.widgets.invoicestatewidget.statusRechnungsstellung')"
        name="invoices"
        v-if="dashboardAccess.specificAccess.invoiceReports"
        ><invoice-state-widget :accessRights="dashboardAccess.specificAccess.invoiceReports" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BirthdaysWidget from "./BirthdaysWidget";
import DocumentsStateWidget from "./DocumentsStateWidget";
import InvoiceStateWidget from "./InvoiceStateWidget";
import EmptyFieldsWidget from "./EmptyFieldsWidget";
import WidgetsArea from "./Widgets/WidgetsArea.vue";
import { Tabs, TabPane } from "element-ui";
import { mapState } from "vuex";
import { get } from "lodash";
import UnavailableResourcesWidget from "./UnavailableResourcesWidget.vue";
import FinanceReportWidget from "./FinanceReport/FinanceReportWidget.vue";

export default {
  name: "dashboard-overview",
  components: {
    WidgetsArea,
    UnavailableResourcesWidget,
    FinanceReportWidget,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [BirthdaysWidget.name]: BirthdaysWidget,
    [DocumentsStateWidget.name]: DocumentsStateWidget,
    [InvoiceStateWidget.name]: InvoiceStateWidget,
    [EmptyFieldsWidget.name]: EmptyFieldsWidget,
  },
  data() {
    return {
      activeName: "birthdays",
    };
  },
  mounted() {
    window.app = { /* createUsers: this.createUsers,*/ checkFiles: this.findNotFoundDocs };
    if (this.$route.params.tab_pane) {
      this.activeName = this.$route.params.tab_pane;
    }
  },
  beforeDestroy() {
    window.app = undefined;
  },
  methods: {
    async createUsers() {
      const response = await this.axios.request({
        method: "POST",
        url: "/api/employees/create-users",
        responseType: "blob",
      });
      const blob = response.data;
      const filename = `created-users.csv`;
      const file = new File([blob], filename, { type: "application/octet-stream" });
      const objectUrl = window.URL.createObjectURL(file);
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      // Create a link to the file
      downloadLink.href = objectUrl;
      // Setting the file name
      downloadLink.download = file.name;
      //triggering the function
      downloadLink.click();
      downloadLink.remove();
    },
    async findNotFoundDocs() {
      const response = await this.axios.request({
        method: "POST",
        url: "/api/documents/check-files",
        responseType: "blob",
        data: { origin: location.origin },
      });
      const blob = response.data;
      const filename = `missing-files.csv`;
      const file = new File([blob], filename, { type: "application/octet-stream" });
      const objectUrl = window.URL.createObjectURL(file);
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      // Create a link to the file
      downloadLink.href = objectUrl;
      // Setting the file name
      downloadLink.download = file.name;
      //triggering the function
      downloadLink.click();
      downloadLink.remove();
    },
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    dashboardAccess() {
      return get(this.accessRights, "dashboard", { specificAccess: {}, generalAccess: null });
    },
  },
};
</script>

<style>
.dashboard-wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
}
</style>
