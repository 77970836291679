<template>
  <div class="n-view-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{ $t("src.components.project.index.projekt") }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.project.index.projektListe") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row type="flex">
      <el-col :span="18">
        <div class="n-view-title">{{ $t("src.components.project.index.projektListe") }}</div>
      </el-col>

      <el-col :span="3" class="text-right">
        <new-listitem-button id="new_project" v-if="$can('create', 'project')" to="/project/profile/new">{{
          $t("src.components.project.index.neuesProjekt")
        }}</new-listitem-button>
      </el-col>

      <el-col :span="3" class="text-right" v-if="$can('create', 'project')">
        <el-button :loading="cloneLoading" type="primary" @click="openCloneProjectModal" id="new_constr_phase">
          <i class="fa fa-plus" style="margin-right: 5px"></i
          >{{ $t("src.components.project.index.neuerBauabschnitt") }}</el-button
        >
      </el-col>
    </el-row>

    <project-index-table
      authSubject="project"
      :propsToSearch="propsToSearch"
      modelType="project"
      :defaultSort="{ prop: 'dateRange', order: 'descending' }"
      :extendedColumns="extendedColumns"
      :supressColumns="supressColumns"
      :normalizeResponse="normalizeResponse"
      @tableFetched="setProjectOptions"
      @itemRemoved="removeProjectOption"
    >
      <template v-slot:Auftraggeber="props">
        <div>
          <project-color :color="props.data.row.color" />

          <el-row type="flex" align="middle">
            <el-col style="flex-grow: 1" :span="20">
              {{ props.data.row.client }}
            </el-col>
            <el-col class="d-flex" style="flex-shrink: 0; justify-content: flex-end" :span="4">
              <router-link
                v-if="hasNotificationTypeForProject(props.data.row.id, 'NOTES')"
                :to="{
                  name: 'Project View',
                  params: { id: props.data.row.id, tab_pane: 'notes' },
                }"
              >
                <notes-icon width="18" height="18" class="mr-1" />
              </router-link>
              <router-link
                v-if="hasNotificationTypeForProject(props.data.row.id, 'TODOS')"
                :to="{
                  name: 'Project View',
                  params: { id: props.data.row.id, tab_pane: 'todos' },
                }"
              >
                <todos-icon width="18" height="18" />
              </router-link>
            </el-col>
          </el-row>
        </div>
      </template>

      <template v-slot:BV-Name="props">
        <template v-if="props.data.row.isWorkshop">
          <router-link v-if="$can('read', 'workshop_projects')" :to="`/project/profile/workshop/${props.data.row.id}`">
            {{ props.data.row.bvName }}
          </router-link>
          <span v-else>{{ props.data.row.bvName }}</span>
        </template>
        <template v-else>
          <router-link v-if="$can('read', 'project')" :to="`/project/profile/view/${props.data.row.id}`">
            {{ props.data.row.bvName }}
          </router-link>
          <span v-else>{{ props.data.row.bvName }}</span>
        </template>
      </template>

      <template v-slot:Bauabschnitt="props">{{
        props.data.row.constructionPhase ? "BA " + props.data.row.constructionPhase : ""
      }}</template>

      <template v-slot:Bauleiter="props">{{ props.data.row.currentManagerName }}</template>

      <template v-slot:Zeitraum="props">{{ getDateRange(props.data.row) }}</template>
      <template v-slot:Projekt-Status="props">{{ getProgress(props.data.row) }}</template>
      <template v-slot:Auftragswert="props"
        ><div class="text-right">{{ getPrice(props.data.row) }}</div></template
      >

      <template v-slot:extended_actions="props">
        <a class="btn btn-sm btn-ghost" @click="handleGoToCalendar(props)">
          <calendar-icon />
        </a>
      </template>
    </project-index-table>
    <el-dialog
      :title="$t('src.components.project.index.neuerBauabschnitt')"
      width="500px"
      :visible.sync="cloneModalVisible"
      center
    >
      <el-form :model="formData" :rules="formDataRules" ref="copyProjectForm" label-position="top">
        <el-form-item :label="$t('src.components.project.index.projektAuswhlen')" prop="projectId">
          <v-select
            style="width: 100%"
            v-model="formData.projectId"
            :placeholder="$t('src.components.project.index.projektAuswhlen')"
            valueIdentifier="id"
            labelIdentifier="name"
            :options="projectPhaseOptions"
            filterable
          />
        </el-form-item>
        <el-form-item :label="$t('src.components.project.index.projektlaufzeitAuswhlen')" prop="dateRange">
          <pr-date-picker
            style="max-width: 100%; min-width: 100%"
            v-model="formData.dateRange"
            is-range
            :placeholder="$t('src.components.project.index.projektlaufzeit')"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item :label="$t('src.components.project.profile.bvBeschreibung')" prop="bvDescription">
          <profile-input
            type="textarea"
            :editMode="true"
            name="BV-Beschreibung"
            noLabel
            :autosize="{ minRows: 4 }"
            required
            rules="required"
            v-model="formData.bvDescription"
          ></profile-input>
        </el-form-item>
        <el-form-item :label="$t('src.components.project.projectstatus.projektstatus')" prop="progress">
          <el-radio-group v-model="formData.progress">
            <el-radio label="progress_1">{{ $t("src.components.project.projectstatus.reserviert") }}</el-radio>
            <el-radio label="progress_2">{{ $t("src.components.project.projectstatus.auszufhrendeArbeit") }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="text-right">
        <el-button type="primary" :loading="cloneLoading" @click="submitCloneProject">
          {{ $t("src.components.project.index.erstellen") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { groupBy, sortBy, last, get } from "lodash";
import { mapGetters } from "vuex";
import { IndexTable, NewListItemButton } from "src/components/UIComponents";
import { Message, Form, FormItem, DatePicker, RadioGroup, Radio } from "element-ui";
import { moment } from "src/config/moment";
import ProjectIndexTable from "../UIComponents/Helper/ProjectIndexTable.vue";
import ProjectColor from "./ProjectColor";
import Calendar from "vue-material-design-icons/Calendar";
import TodosIcon from "src/assets/icons/TodosIcon";
import NotesIcon from "src/assets/icons/NotesIcon";

export default {
  components: {
    TodosIcon,
    NotesIcon,
    ProjectIndexTable,
    [ProjectColor.name]: ProjectColor,
    [IndexTable.name]: IndexTable,
    [NewListItemButton.name]: NewListItemButton,
    [Message.name]: Message,
    [Radio.name]: Radio,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [DatePicker.name]: DatePicker,
    [Calendar.name]: Calendar,
  },
  data() {
    const validateDateRange = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum auswählen"));
      }
      return callback();
    };
    return {
      extendedColumns: [
        {
          label: "Auftraggeber",
          key: "client",
          minWidth: 250,
        },
        {
          label: "BV-Name",
          key: "bvName",
          minWidth: 250,
        },
        {
          label: "Bauabschnitt",
          key: "constructionPhase",
          minWidth: 150,
        },
        {
          label: "Bauleiter",
          key: "currentManagerName",
          minWidth: 150,
        },
        {
          label: "Zeitraum",
          key: "dateRange",
          minWidth: 200,
          sortMethod: this.projectsSortMethod,
        },
        {
          label: "Projekt-Status",
          key: "progress",
          minWidth: 200,
        },
        {
          label: "Auftragswert",
          key: "orderValue",
          width: 140,
        },
      ],
      supressColumns: ["pictures", "status", "dispo"],
      units: [],
      propsToSearch: ["client", "bvName", "constructionPhase", "currentManagerName"],
      cloneLoading: false,
      cloneModalVisible: false,
      selectedProjectId: "",
      projectPhaseOptions: [],
      formDataRules: {
        dateRange: {
          required: true,
          message: "Bitte Zeitraum auswählen",
          trigger: "change",
          validator: validateDateRange,
        },
        projectId: {
          required: true,
          message: "Bitte Projekt auswählen",
          trigger: "change",
        },
        progress: {
          required: true,
          message: "Bitte Projektstatus auswählen",
          trigger: "change",
        },
        bvDescription: {
          required: true,
          message: "Bitte BV-Beschreibung auswählen",
          trigger: "change",
        },
      },
      formData: {
        dateRange: null,
        projectId: null,
      },
    };
  },
  mounted() {
    this.axios.get("/api/projects/my");
  },
  methods: {
    projectsSortMethod(a, b) {
      if (a.isWorkshop && !b.isWorkshop) {
        return 1;
      }
      if (!a.isWorkshop && b.isWorkshop) {
        return -1;
      }
      let aVal = a.noDateRange ? new Date("1970-01-01") : new Date(a.dateRange[0]),
        bVal = b.noDateRange ? new Date("1970-01-01") : new Date(b.dateRange[0]);
      if (aVal > bVal) {
        return 1;
      } else if (aVal < bVal) {
        return -1;
      } else {
        return 1;
      }
    },
    getDateRange(project) {
      if (project) {
        if (project.noDateRange) {
          return "N/A";
        } else {
          return this.getFormatedDate(project.dateRange[0]) + " - " + this.getFormatedDate(project.dateRange[1]);
        }
      }
      return "";
    },
    getProgress(project) {
      if (project.isWorkshop) {
        return "";
      } else if (project.progress === "progress_1") {
        return "Reserviert";
      } else if (project.progress === "progress_2") {
        return "Auszuführende Arbeit";
      } else if (project.progress === "progress_3") {
        return "Abgeschlossen";
      }
      return "";
    },
    getPrice(data) {
      if (data.orderValue) {
        return (
          parseFloat(data.orderValue).toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
          " €"
        );
      } else {
        return undefined;
      }
    },
    getFormatedDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY");
      } else {
        return "N/A";
      }
    },
    setProjectOptions(projectList) {
      if (projectList) {
        const activeProjectsGroupedByClient = groupBy(
          projectList.filter((project) => project.active),
          (project) => project.relatedProjects && project.relatedProjects[0]
        );
        this.projectPhaseOptions = Object.values(activeProjectsGroupedByClient)
          // drop constuctionPhase projects - leave only last
          .reduce((array, currentList) => {
            if (currentList.length === 1) {
              array.push(currentList[0]);
              return array;
            }
            const latestConstructionPhase = last(sortBy(currentList, "constructionPhase"));
            array.push(latestConstructionPhase);
            return array;
          }, [])
          .map((project) => ({
            id: project.id,
            name: project.bvName,
          }));
      }
    },
    removeProjectOption(id) {
      const idx = this.projectPhaseOptions.findIndex((project) => project.id === id);
      if (idx !== -1) {
        this.projectPhaseOptions.splice(idx, 1);
      }
    },
    openCloneProjectModal() {
      this.cloneModalVisible = true;
    },
    submitCloneProject() {
      this.$refs.copyProjectForm.validate(async (valid) => {
        try {
          if (!valid) {
            return;
          }
          this.cloneLoading = true;
          const formBody = {
            projectId: this.formData.projectId,
            dateRange: this.formData.dateRange,
            bvDescription: this.formData.bvDescription,
            progress: this.formData.progress,
          };
          await this.axios.post("/api/projects/copy", formBody);
          this.$root.$emit("refetch_indexTable");
          this.cloneModalVisible = false;
        } catch (error) {
          Message.error(error.message);
        } finally {
          this.cloneLoading = false;
        }
      });
      if (!this.selectedProjectId) {
        return;
      }
    },
    handleGoToCalendar({ props }) {
      const projectStartDate = props.row.dateRange && props.row.dateRange[0];
      const monday = moment(projectStartDate).startOf("week").toJSON();
      this.navigateToCalendar({ date: monday });
    },
    navigateToCalendar(query) {
      this.$router.push({ name: "Project Calendar", query });
    },
    normalizeResponse(data) {
      const today = moment();
      return data.map((project) => {
        let statusColor;
        if (project.progress === "progress_1") {
          statusColor = "var(--project-progress-1)";
        } else if (project.progress === "progress_2") {
          statusColor = "var(--project-progress-2)";
        } else if (project.progress === "progress_3") {
          statusColor = "var(--project-progress-3)";
        }
        let currentManager;
        if (project.constructionManagers) {
          const managers = project.constructionManagers.filter((record) => record.position === "CONSTRUCTION_MANAGER");
          currentManager = managers.find((record) => today.within(moment.range(record.dateRange).snapTo("day")));
          if (!currentManager && managers.length) {
            currentManager = last(managers);
          }
        }
        let currentManagerName = "";
        if (currentManager) {
          if (currentManager.employee && currentManager.employee.name) {
            currentManagerName = currentManager.employee.name;
          } else {
            currentManagerName = currentManager.employee._id;
          }
        }
        return {
          ...project,
          statusColor,
          currentManagerName,
        };
      });
    },
    hasNotificationTypeForProject(projectId, namespace) {
      const notificationsForProject = get(this.notificationsByProjectId, projectId, []);
      return notificationsForProject.some((item) => item.namespace === namespace);
    },
  },
  computed: {
    ...mapGetters("notifications", { notificationsByProjectId: "mappedByProjectId" }),
  },
  watch: {
    cloneModalVisible(newVal, oldVal) {
      // reset form data when modal is closed
      if (oldVal && !newVal) {
        this.$refs.copyProjectForm.resetFields();
        this.formData.dateRange = null;
      }
    },
  },
};
</script>

<style>
.index-header {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
</style>
