<template>
  <div class="form-group">
    <h6 class="n-profile-label-title">{{ title }}:</h6>
    <el-dialog
      :visible="visible"
      @close="dismiss"
      width="680px"
      :title="title"
      :data-testid="`dialog-${title}`"
      destroy-on-close
    >
      <el-form ref="form" :model="formData" :rules="formRules">
        <div class="row">
          <div class="col-md-8">
            <el-form-item prop="departureDateRange">
              <profile-date-picker
                style="width: 100%"
                :projectDateRange="projectDateRange"
                :isEditMode="isEditing"
                v-model="formData.departureDateRange"
                is-range
                :title="$t('src.components.project.departurecontrol.zeitraum')"
                :placeholder="$t('src.components.project.departurecontrol.zeitraum')"
                value-format="YYYY-MM-DD"
                :focusDate="today"
                required
                :data-testid="`datepicker-${title}`"
              />
            </el-form-item>
          </div>
          <div class="col-md-4">
            <div style="margin-bottom: 19px">
              <span class="n-profile-select-title-red">*</span>
              <el-switch
                v-model="inputType"
                :disabled="!isEditing"
                active-value="time"
                inactive-value="text"
                active-color="#3c4b61"
                inactive-color="#3c4b61"
                :active-text="$t('src.components.project.departurecontrol.uhrzeit')"
                :inactive-text="$t('src.components.project.departurecontrol.tagesinfo')"
              ></el-switch>
            </div>
            <el-form-item prop="departureTime">
              <template v-if="inputType === 'time'">
                <template v-if="isEditing">
                  <time-picker
                    :styles="{ width: '100%', marginTop: '-1px' }"
                    v-model="formData.departureTime"
                    :disabled="!isEditMode"
                    :placeholder="$t('src.components.project.departurecontrol.uhrzeit')"
                  />
                </template>
                <div v-else>{{ formData.departureTime }}</div>
              </template>
              <template v-else>
                <template v-if="isEditing">
                  <el-input
                    :styles="{ width: '100%', marginTop: '-1px' }"
                    v-model="formData.departureTime"
                    :disabled="!isEditMode"
                    :placeholder="$t('src.components.project.departurecontrol.tagesinfo')"
                    maxlength="20"
                    show-word-limit
                  />
                </template>
                <div v-else>{{ formData.departureTime }}</div>
              </template>
            </el-form-item>
            <!-- 
            <el-form-item prop="departureTime">
              <span class="n-profile-select-title-red">*</span>
              <span class="n-profile-label-title">{{ $t('src.components.project.departurecontrol.uhrzeit') }}</span>
              <template v-if="isEditing">
                <time-picker
                  :styles="{ width: '100%', marginTop: '-1px' }"
                  v-model="formData.departureTime"
                  :disabled="!isEditMode"
                  :placeholder="$t('src.components.project.departurecontrol.uhrzeit')"
                />
              </template>
              <div v-else>{{ formData.departureTime }}</div>
            </el-form-item> -->
          </div>
          <div class="col-md-12">
            <div class="flex justify-between" v-if="isEditing">
              <el-button class="mr-2" @click.prevent="dismiss()">{{
                $t("src.components.project.departurecontrol.abbrechen")
              }}</el-button>
              <template v-if="isAddingNew">
                <el-button
                  type="primary"
                  class="mt-2"
                  @click.prevent="addDeparture()"
                  :data-testid="`submit-${title}`"
                  >{{ $t("src.components.project.departurecontrol.speichern") }}</el-button
                >
              </template>
              <template v-else>
                <save-button
                  class="mt-2"
                  :disabled="!formData.departureTime || !formData.departureDateRange"
                  @click.prevent="saveSelectedDeparture()"
                  :data-testid="`submit-${title}`"
                  >{{ $t("src.components.project.departurecontrol.speichern") }}</save-button
                >
              </template>
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <editable-tag
      v-for="departure in departures"
      :key="departure._id"
      @view="viewDeparture(departure)"
      @edit="initEditDeparture(departure)"
      @remove="removeDeparture(departure)"
      :isEditMode="isEditMode"
      :title="formatDepartureString(departure)"
      :record="departure"
    />
    <cancel-button v-if="isEditMode" @click.prevent="showAddDeparture()" :data-testid="`show-${title}`">
      <plus-icon class="mr-2" />{{ $t("src.components.project.departurecontrol.hinzufgenAbfahrtzeite") }}</cancel-button
    >
  </div>
</template>

<script>
import { Switch, DatePicker, Timeline, TimelineItem, MessageBox, Dialog, Form, FormItem } from "element-ui";
import { moment } from "src/config/moment";
import { TimePicker } from "src/components/UIComponents";
import PrDatePicker from "../UIComponents/Inputs/PrDatePicker.vue";
import PlusIcon from "vue-material-design-icons/Plus";
import { EditableTag } from "src/components/UIComponents";

export default {
  name: "departure-control",
  props: {
    title: { type: String, required: true },
    isEditMode: { type: Boolean, required: true },
    hideOld: { type: Boolean },
    selectedDepartures: { type: Array },
    projectDateRange: { type: Array, default: () => [] },
  },
  components: {
    PrDatePicker,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [PlusIcon.name]: PlusIcon,
    [EditableTag.name]: EditableTag,
    [MessageBox.name]: MessageBox,
  },
  data: function () {
    const validateDateRange = (rule, value, callback) => {
      if (!value || !value.length) {
        return callback(new Error("Bitte Zeitraum eintragen"));
      }
      const currentDateRange = moment.range(value);
      // find conflicting records to exclude currently editing (if editing) record from validation
      const conflictRecords = this.selectedDepartures.filter((item) => {
        if (item._id && item._id === this.selectedDeparture._id) {
          return false;
        }
        return moment.range(item.dateRange).overlaps(currentDateRange, { adjacent: true });
      });
      // some records overlap currently set daterange
      if (conflictRecords.length > 0) {
        return callback(new Error("Datumsbereich sollte sich nicht überschneiden"));
      }
      return callback();
    };
    return {
      today: new Date(),
      formRules: {
        departureTime: { required: true, message: "Dieses Feld wird benötigt" },
        departureDateRange: { required: true, validator: validateDateRange, trigger: "change" },
      },
      formData: {
        departureTime: null,
        departureDateRange: null,
      },
      inputType: "time",
      isAddingNew: false,
      visible: false,
      isEditing: false,
      selectedDeparture: {},
    };
  },
  methods: {
    addDeparture() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.selectedDepartures) {
          this.selectedDepartures = [];
        }
        this.selectedDepartures.push({
          dateRange: this.formData.departureDateRange.slice(),
          time: this.formData.departureTime,
        });
        this.selectedDepartures.sort((a, b) => new Date(a.dateRange[0]).getTime() - new Date(b.dateRange[0]).getTime());
        // this.$refs.form.resetFields();
        this.dismiss();
      });
    },
    viewDeparture(departure) {
      this.initEditDeparture(departure);
      this.isEditing = false;
    },
    initEditDeparture(departure) {
      this.visible = true;
      this.isEditing = true;
      this.selectedDeparture = departure;
      this.formData.departureTime = departure.time;
      this.formData.departureDateRange = departure.dateRange.slice();
    },
    dismiss() {
      this.$refs.form.resetFields();
      this.isEditing = false;
      this.visible = false;
      this.isAddingNew = false;
      this.formData.departureDateRange = null;
      this.selectedDeparture = {};
    },
    removeDeparture(departure) {
      MessageBox.confirm("Abfahrtszeit löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
      }).then(() => {
        let selectedDeparture = departure || this.selectedDeparture;
        const departureIdx = this.selectedDepartures.findIndex((item) => item._id === selectedDeparture._id);
        if (departureIdx !== -1) {
          this.selectedDepartures.splice(departureIdx, 1);
        }
        this.dismiss();
      });
    },
    saveSelectedDeparture() {
      const departureIdx = this.selectedDepartures.findIndex((item) => item._id === this.selectedDeparture._id);
      if (departureIdx !== -1) {
        const changedDeparture = {
          ...this.selectedDeparture,
          dateRange: this.formData.departureDateRange.slice(),
          time: this.formData.departureTime,
        };
        this.selectedDepartures.splice(departureIdx, 1, changedDeparture);
      }
      this.formData.departureDateRange = null;
      this.formData.departureTime = null;
      this.isEditing = false;
      this.dismiss();
      this.selectedDepartures.sort((a, b) => new Date(a.dateRange[0]).getTime() - new Date(b.dateRange[0]).getTime());
    },
    showAddDeparture() {
      this.visible = true;
      this.isEditing = true;
      this.isAddingNew = true;
    },
    formatDepartureString(departure) {
      const dateRangeString = this.$options.filters.formatDate(departure.dateRange);
      return `${dateRangeString} - ${departure.time}`;
    },
  },
  computed: {
    departures: function () {
      const today = moment();
      console.log("this.selectedDepartures", this.selectedDepartures);
      if (!this.selectedDepartures) {
        return [];
      }

      let departures = [];

      this.selectedDepartures.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      this.selectedDepartures.forEach((departure) => {
        if (this.hideOld) {
          if (today.isSameOrBefore(departure.dateRange[1], "day")) {
            departures.push(departure);
          }
        } else {
          departures.push(departure);
        }
      });
      return departures;
    },
  },
  filters: {
    formatDate: function (dateRange) {
      if (!dateRange || dateRange.length !== 2) {
        return "N/A";
      }
      if (moment(dateRange[0]).isSame(dateRange[1], "day")) {
        return moment(dateRange[0]).format("DD.MM.YYYY");
      }
      return dateRange.map((date) => moment(date).format("DD.MM.YYYY")).join("-");
    },
  },
  watch: {
    inputType(newVal, oldVal) {
      this.formData.departureTime = null;
      this.$refs.form.clearValidate("departureTime");
    },
  },
};
</script>

<style></style>
