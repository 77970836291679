<template>
  <el-dialog :title="$t('src.components.birthdayreminders.birthdayReminder')" :visible.sync="visible" center>
    <p>
      <b>{{ birthdayTitle }}</b>
    </p>
    <p>
      {{ $t("src.components.birthdayreminders.remindMeIn")
      }}<el-select v-model="remindHours" style="width: 70px; display: inline-block; margin: 0 5px">
        <el-option v-for="hour in hourOptions" :key="hour" :value="hour" :label="hour" /> </el-select
      >{{ $t("src.components.birthdayreminders.hours") }}
    </p>
    <template v-slot:footer>
      <span class="dialog-footer">
        <el-button @click="confirm" class="mr-2">OK</el-button>
        <el-button type="primary" @click="dismissReminder">nicht mehr erinnern</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { Message, Dialog, Select, Button } from "element-ui";
import { moment } from "src/config/moment";

export default {
  name: "birthday-reminders",
  components: {
    [Message.name]: Message,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      birthdayTitle: "",
      visible: false,
      hourOptions: [1, 2, 3, 4, 5, 6, 7, 8],
      reminders: [],
      loggedIn: false,
      remindHours: 1,
    };
  },
  mounted() {
    if (this.$keycloak.authenticated && !this.loggedIn) {
      this.loggedIn = true;
      this.fetchReminders();
      this.fetchRunner = window.setInterval(() => this.fetchReminders(), 1000 * 60 * 60); // 1 hour
    }
  },
  methods: {
    async fetchReminders() {
      try {
        const until = moment().startOf("day");
        const reminders = await this.axios
          .post("/api/employees/birthdays/reminders", { until })
          .then((res) => res && res.data)
          .catch((err) => {
            throw err;
          });
        if (reminders.length && reminders.some((item) => until.isSameOrAfter(item.birthDate.split("T")[0], "day"))) {
          this.birthdayTitle = "";
          this.visible = true;
          this.reminders = reminders;
        }
        const birthdayNames = reminders.map((item) => item.name).join(", ");
        // reminders.forEach((birthday, idx, array) => {
        //   // this.$notify({
        //   //   title: "Birthday reminder!",
        //   //   type: "info",
        //   //   duration: 0,
        //   //   dangerouslyUseHTMLString: true,
        //   //   message: `Today <b>${birthday.name}</b> has his ${yearsOld}th birthday! Have you prepared a present? :)`
        //   // });
        //   if (array.length > 1 && idx !== array.length - 1) {
        //     this.birthdayTitle += ", ";
        //   } else {
        //     this.birthdayTitle += "! ";
        //   }
        // });
        this.birthdayTitle += `${birthdayNames} ${reminders.length > 1 ? "haben" : "hat"} heute Geburtstag!`;
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async submitDelay(hours) {
      try {
        await this.axios.post("/api/employees/birthdays/delay-reminders", { hours: hours });
        this.visible = false;
        this.reminders = [];
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    confirm() {
      if (this.remindHours) {
        return this.submitDelay(this.remindHours);
      }
    },
    dismissReminder() {
      return this.submitDelay(20); // just delay until next day
    },
  },
  watch: {
    "$keycloak.authenticated": function (newVal, oldVal) {
      // run watcher only for logged in users
      if (newVal && !this.loggedIn) {
        this.loggedIn = true;
        this.fetchReminders();
        this.fetchRunner = window.setInterval(() => this.fetchReminders(), 3600000); // 1 hour
      }
    },
  },
  beforeDestroy() {
    window.clearInterval(this.fetchRunner);
  },
};
</script>

<style></style>
