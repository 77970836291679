<template>
  <div v-loading="loading" id="documents-list" style="height: 100%">
    <div>
      <div>
        <div class="top-actions">
          <div style="flex-grow: 1">
            <div class="n-profile-title">
              {{ $t("src.components.uicomponents.helper.documents.documents.dokumentenliste") }}
            </div>
          </div>

          <template>
            <!-- BUTTONS TOP RIGHT -->
            <div v-if="showRelationColumn">
              <!-- FILTER: nur relevante Dokumente -->
              <el-dropdown style="margin-top: 5px" trigger="click" @command="(cmd) => (hideRelatedDocuments = cmd)">
                <span class="n-profile-dropdown-value">
                  <span class="n-profile-label-title" style="padding-right: 5px">{{
                    $t("src.components.uicomponents.helper.documents.documents.projektfilter")
                  }}</span>
                  {{ hideRelatedDocuments ? "Spezifisch" : "Alle" }} <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="true">{{
                    $t("src.components.uicomponents.helper.documents.documents.spezifisch")
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="false">{{
                    $t("src.components.uicomponents.helper.documents.documents.alle")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="ml-2 mr-2">
              <!-- FILTER: Aktive/Inaktive Dokumente -->
              <el-dropdown style="margin-top: 5px" trigger="click" @command="(cmd) => (hideInactiveDocuments = cmd)">
                <span class="n-profile-dropdown-value">
                  <span class="n-profile-label-title" style="padding-right: 5px">{{
                    $t("src.components.uicomponents.helper.documents.documents.statusfilter")
                  }}</span>
                  {{ hideInactiveDocuments ? "nur Aktive" : "Alle" }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="true">{{
                    $t("src.components.uicomponents.helper.documents.documents.nurAktive")
                  }}</el-dropdown-item>
                  <el-dropdown-item :command="false">{{
                    $t("src.components.uicomponents.helper.documents.documents.alle")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <!-- NEW DOCUMENT BUTTON -->
            <template v-if="computedEditMode">
              <template v-if="isCopyMode">
                <copy-documents-dialog
                  @discard="discardCopy"
                  :currentResourceId="modelID"
                  :resourceType="modelType"
                  :selectedDocuments="selectedDocuments"
                />
              </template>
              <template v-else>
                <el-button type="primary" class="ml-2" @click="newDocument" data-testid="new_document">
                  <plus-icon />{{
                    $t("src.components.uicomponents.helper.documents.documents.neuesDokument")
                  }}</el-button
                >
                <cancel-button class="ml-2" @click.prevent="isCopyMode = true" data-testid="show_copy">{{
                  $t("src.components.machine.edit.kopieren")
                }}</cancel-button>
              </template>
            </template>
          </template>
        </div>
      </div>
      <div class="card-body">
        <el-row>
          <!-- SEARCH -->
          <el-col :span="5">
            <el-input
              class="n-search"
              :placeholder="$t('src.components.uicomponents.helper.documents.documents.sucheNachName')"
              clearable
              v-model="searchQuery"
              suffix-icon="el-icon-search"
            >
            </el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <!-- TABLE of documents -->
            <el-table
              :data="filteredDocuments"
              style="width: 100%"
              :row-class-name="tableRowClassName"
              :max-height="this.tableMaxHeight"
              :default-sort="{ prop: 'type', order: 'ascending' }"
              @selection-change="handleSelectionChange"
              ref="table"
            >
              <el-table-column v-if="isCopyMode" type="selection" width="55"></el-table-column>
              <el-table-column type="index" sortable width="60">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">#</span>
                </template>
              </el-table-column>
              <el-table-column
                min-width="250"
                sortable
                prop="type"
                :filter-method="filterHandler"
                :filters="typeFilters"
                :sort-method="typeSorter"
              >
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{
                    $t("src.components.uicomponents.helper.documents.documents.typ")
                  }}</span>
                </template>
                <!-- CONTENT -->
                <template v-slot="scope">
                  <document-type-cell :typeOptions="typeOptions" :typeId="scope.row.type"></document-type-cell>
                </template>
              </el-table-column>
              <el-table-column sortable min-width="200" prop="supplierName" v-if="withSuppliers">
                <template v-slot:header>
                  <span class="n-table-header">{{ $t("supplier") }}</span>
                </template>
                <template v-slot="scope">
                  <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                    <router-link v-if="$can('read', 'supplier')" :to="`/purchasing/suppliers?id=${scope.row.supplier}`">
                      {{ scope.row.supplierName }}
                    </router-link>
                    <span v-else>
                      {{ scope.row.supplierName }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="200" sortable prop="name">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{
                    $t("src.components.uicomponents.helper.documents.documents.name")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="200" sortable prop="document" :sort-method="filenameSorter">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{
                    $t("src.components.uicomponents.helper.documents.documents.dateiname")
                  }}</span>
                </template>
                <template v-slot="scope">
                  <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                    {{ getFilenameForDocument(scope.row) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                :sort-method="sortDateRange"
                :formatter="formatDateRange"
                prop="dateRange"
                min-width="150"
              >
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{
                    $t("src.components.uicomponents.helper.documents.documents.gltigkeit")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="showRelationColumn"
                min-width="200"
                sortable
                :prop="relationColumnProp"
                :formatter="getProjectName"
              >
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{ relationColumnName }}</span>
                </template>
              </el-table-column>

              <!-- TODO: extract "getProjectName" to parent method -->
              <el-table-column fixed="right" width="150">
                <template v-slot="scope">
                  <el-button class="btn btn-sm btn-ghost" @click="viewDocument(scope.row)" data-testid="view_document">
                    <eye-icon />
                  </el-button>
                  <el-button
                    class="btn btn-sm btn-ghost"
                    @click="downloadDocument(scope.row)"
                    data-testid="download_document"
                  >
                    <download-icon />
                  </el-button>
                  <span v-if="computedEditMode">
                    <!-- EDIT BUTTON -->
                    <el-button
                      class="btn btn-sm btn-ghost"
                      @click="handleEditRecord(scope.row, scope.$index)"
                      data-testid="edit_document"
                    >
                      <pencil-icon />
                    </el-button>
                    <!-- DELETE BUTTON -->
                    <el-button
                      class="btn btn-sm btn-ghost"
                      @click="handleDeleteRecord(scope.row._id)"
                      data-testid="remove_document"
                    >
                      <trash-can-outline-icon />
                    </el-button>
                  </span>
                </template>
              </el-table-column>

              <!-- SLOT EMPTY -->
              <template v-slot:empty>{{
                $t("src.components.uicomponents.helper.documents.documents.keineDokumenteVorhanden")
              }}</template>
            </el-table>
          </el-col>
        </el-row>
      </div>

      <!-- END OF CARD -->
    </div>

    <!-- DIALOG -->
    <el-dialog
      width="80%"
      :visible="isEditVisible"
      @close="closing"
      :modal="false"
      data-testid="document_modal"
      destroy-on-close
    >
      <!-- HEADER -->
      <div style="padding-left: 20px">
        <span class="n-profile-title-font">{{
          editingDocumentRecord.isNew ? "Neues Dokument" : "Dokument bearbeiten"
        }}</span>
      </div>
      <div class="card-body">
        <!-- dont show when we create the document -->
        <el-row v-if="!editingDocumentRecord.isNew">
          <!-- AKTIV/INAKTIV -->
          <profile-switch
            v-model="editingDocumentRecord.active"
            :title="$t('src.components.vehicle.profile.aktiv')"
            :active-text="$t('src.components.vehicle.profile.ja')"
            :inactive-text="$t('src.components.vehicle.profile.nein')"
            colorizeActive
          />
        </el-row>

        <el-row style="margin-top: 30px" :gutter="20">
          <!-- TYP -->
          <el-col :span="6">
            <ValidationObserver ref="validateDocsObserver">
              <profile-select
                :title="$t('src.components.uicomponents.helper.documents.documents.typ')"
                :hint="systemRelevant ? 'systemrelevant' : ''"
                :multiple="false"
                :items="activeTypeOptions"
                v-model="editingDocumentRecord.type"
                valueIdentifier="_id"
                :label="$t('src.components.uicomponents.helper.documents.documents.label')"
                :editMode="true"
                required
                rules="required"
                name="Dokument-Typ"
                customMessage="Bitte Dokument-Typ auswählen"
              />
            </ValidationObserver>
          </el-col>

          <!-- NAME -->
          <el-col :span="6">
            <profile-input
              v-model="editingDocumentRecord.name"
              size="large"
              :label="$t('src.components.uicomponents.helper.documents.documents.name')"
              :editMode="true"
              :required="systemRelevant"
            />
          </el-col>
          <el-col v-if="withSuppliers" :span="6">
            <profile-select
              v-model="editingDocumentRecord.supplier"
              :items="suppliers"
              :title="$t('supplier')"
              valueIdentifier="_id"
              label="name"
              :editMode="true"
              :multiple="false"
              filterable
              clearable
            />
          </el-col>
          <el-col :span="6">
            <profile-date-picker
              style="width: 100%"
              v-model="editingDocumentRecord.dateRange"
              is-range
              :title="$t('src.components.uicomponents.helper.documents.documents.gltigkeit')"
              value-format="YYYY-MM-DD"
              :required="systemRelevant"
              name="Dokumentlaufzeit"
              :isEditMode="true"
            ></profile-date-picker>
          </el-col>

          <el-col :span="13">
            <profile-input
              v-model="editingDocumentRecord.freeText"
              :label="$t('src.components.project.dailyreport.dailyreportitem.freiText')"
              :editMode="true"
              type="textarea"
              :autosize="{ minRows: 3 }"
            />
          </el-col>
        </el-row>

        <el-row style="margin-top: 20px" :gutter="20">
          <el-col :md="13">
            <div>
              <span class="n-profile-select-title-red">*</span>
              <span class="n-profile-label-title">{{
                $t("src.components.uicomponents.helper.documents.documents.dokument")
              }}</span>
            </div>
            <el-upload
              :headers="authHeader"
              style="display: inline-block; width: 100%; margin-top: 0"
              :action="actionURI"
              :data="{ metadata: getMetaData(editingDocumentRecord) }"
              :on-remove="fileRemoved"
              :multiple="false"
              :limit="1"
              :on-success="fileUploaded"
              :on-error="handleUploadError"
              :file-list="editingDocumentRecord.fileList"
              data-testid="upload"
              class="drawer-upload"
              drag
            >
              <div>
                <div class="upload-icon"><file-upload-outline-icon /></div>
                <div class="el-upload__text">
                  <b>{{ $t("src.components.project.invoices.drawerupload.whlenSieEineDatei") }}</b
                  >&nbsp;
                  <em style="color: #46a19c">{{
                    $t("src.components.project.invoices.drawerupload.vonIhremComputerAus")
                  }}</em>
                </div>
              </div>
              <template v-slot:tip>
                <div class="el-upload__tip">
                  Es werden alle Datei-Formate akzeptiert. Für die PDF-Generierung werden jedoch ausschließlich folgende
                  Formate unterstützt: pdf, png, jpg, jpeg, tiff, bmp, gif, msg
                </div>
              </template>
            </el-upload>
            <div v-if="showFileRequired" class="required-message">Bitte Dokument hochladen</div>
          </el-col>
        </el-row>
      </div>

      <!-- FOOTER BUTTONS -->
      <el-row v-if="computedEditMode">
        <div class="n-drawer-footer">
          <cancel-button @click="dismissChanges" data-testid="cancel_document" />
          <save-button @click="submitChanges" data-testid="save_document" />
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  Message,
  MessageBox,
  Switch,
  Table,
  TableColumn,
  Upload,
  Button,
  Form,
  FormItem,
  Select,
  Dialog,
  DatePicker,
} from "element-ui";
import { mapGetters, mapActions } from "vuex";
import DownloadIcon from "vue-material-design-icons/Download";
import EyeIcon from "vue-material-design-icons/Eye";
import { Collapse, CollapseItem, TypeSettings } from "src/components/UIComponents";
import { moment } from "src/config/moment";
import { filter, orderBy, debounce, sortBy, get } from "lodash";
import DocumentTypeCell from "./DocumentTypeCell";
import CopyDocumentsDialog from "./CopyDocumentsDialog.vue";
import { ValidationObserver } from "vee-validate";
import FileUploadOutlineIcon from "vue-material-design-icons/FileUploadOutline";

export default {
  name: "document-list",
  components: {
    ValidationObserver,
    MessageBox,
    Message,
    [TypeSettings.name]: TypeSettings,
    Collapse,
    FileUploadOutlineIcon,
    CollapseItem,
    CopyDocumentsDialog,
    [DownloadIcon.name]: DownloadIcon,
    [EyeIcon.name]: EyeIcon,
    [Switch.name]: Switch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Upload.name]: Upload,
    [Button.name]: Button,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [Select.name]: Select,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
    [DocumentTypeCell.name]: DocumentTypeCell,
  },
  props: {
    modelType: String, //e.g. "employee"
    modelID: String, //e.g. user.id
    // for additional query parameter that is used in request for documents
    searchByRelative: { type: Boolean },
    projectDateRange: { type: Array, default: () => [] },
    projectsMeta: { type: Array, default: () => [] },
    accessRights: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },

    withSuppliers: Boolean,
    showRelationColumn: Boolean,
    relationColumnName: String,
    relationColumnProp: String,
  },
  watch: {
    //we need to watch the modelid and lazy load its depending data!
    //modelid source is loaded after this component is created
    modelID: async function (newVal) {
      if (newVal) {
        await this.loadDataFromServer();
      }
    },
    documents(newVal) {
      if (newVal) {
        this.setFilteredDocuments();
      }
    },
    hideInactiveDocuments() {
      this.setFilteredDocuments();
    },
    hideRelatedDocuments() {
      this.setFilteredDocuments();
    },
    searchQuery(newVal) {
      this.debounceSetFilteredDocuments();
    },
    "editingDocumentRecord.type": function (documentTypeID, oldValue) {
      let documentType = this.typeOptions.find((x) => x._id === documentTypeID);
      this.systemRelevant = documentType && documentType.systemRelevant;
    },
  },
  data() {
    return {
      tableMaxHeight: null,
      systemRelevant: false,
      loading: false,
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      typeOptions: [], //data comes from api request
      documents: [],
      hideInactiveDocuments: true,
      hideRelatedDocuments: false,
      isEditVisible: false,
      editingIdx: null,
      editingDocumentRecord: {},
      editingRules: {
        name: [{ message: "Dieses Feld wird benötigt", trigger: "blur" }],
        type: [{ message: "Dieses Feld wird benötigt", trigger: "blur" }],
      },
      searchQuery: "",
      filteredDocuments: [],
      showFileRequired: false,
      removePreviousFileParams: null,
      selectedDocuments: [],
      isCopyMode: false,
      suppliers: [],
    };
  },
  mounted() {
    // typically this component is used in Profile view -> Documents tab and is not used each time so it's lazy-loaded
    // This event calls TypeSettings.vue to re-fetch and re-send sufficient typeOptions to this component
    this.$root.$emit("loadSettings", { modelid: this.modelType, modelType: "documents" });
    this.$root.$on("settingsChanged", async (data) => {
      //we are just interested in the document settings here
      if (data.modelType === "documents") {
        await this.fetchDocumentTypes();
        // this fetch method is called after documents model types are received because we need to be able to
        // sort by model type - so we extend documents list with model type label in next steps
        this.loadDataFromServer();
      }
    });

    this.debounceSetFilteredDocuments = debounce(this.setFilteredDocuments, 400);
    this.debounceSetMaxTableHeight = debounce(this.setMaxTableHeight, 300);

    // to stretch table on maximum parent height with fixed header
    window.addEventListener("resize", this.debounceSetMaxTableHeight);
    this.setMaxTableHeight();

    window.deleteDocument = (documentId) => this.axios.delete("/api/documents/" + documentId);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debounceSetMaxTableHeight);
    this.$root.$off("settingsChanged");
    window.deleteDocument = undefined;
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    activeTypeOptions() {
      return this.typeOptions.filter((i) => i.active);
    },
    typeFilters() {
      return this.typeOptions.map((item) => ({ text: item.label, value: item._id }));
    },
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    handleSelectionChange(val) {
      this.selectedDocuments = val;
    },
    discardCopy() {
      this.isCopyMode = false;
      this.selectedArticles = [];
      this.$refs.table.clearSelection();
    },
    async fetchDocumentTypes() {
      const modelType = this.modelType === "serviceProvider" ? "serviceProviderType" : this.modelType;
      const response = await this.axios.get("/api/documents/types", {
        params: { modelId: modelType },
      });
      this.typeOptions = orderBy(response.data, ["label"], ["asc"]);
    },
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    closing(a, b) {
      this.dismissChanges();
    },
    sortDateRange(a, b) {
      const aVal = a.dateRange;
      const bVal = b.dateRange;
      if (!aVal.length) {
        if (!bVal.length) {
          return 0;
        }
        return -1;
      }
      if (!bVal.length) {
        return 1;
      }
      const aDate = new Date(aVal[0]);
      const bDate = new Date(bVal[0]);
      if (aDate < bDate) {
        return -1;
      } else if (bDate < aDate) {
        return 1;
      } else {
        return 0;
      }
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    typeSorter(a, b) {
      const aModelLabel = a.modelLabel.toLowerCase(),
        bModelLabel = b.modelLabel.toLowerCase();
      if (aModelLabel < bModelLabel) {
        return -1;
      } else if (aModelLabel > bModelLabel) {
        return 1;
      } else {
        return 0;
      }
    },
    filenameSorter(a, b) {
      const aFilename = this.getFilenameForDocument(a),
        bFilename = this.getFilenameForDocument(b);
      if (aFilename < bFilename) {
        return -1;
      } else if (aFilename > bFilename) {
        return 1;
      } else {
        return 0;
      }
    },
    setFilteredDocuments() {
      let filteredDocuments = this.documents.slice();
      const propsModelId = this.modelID;
      const predicates = [];
      if (this.hideInactiveDocuments) {
        predicates.push((document) => document.active);
      }
      if (this.hideRelatedDocuments) {
        predicates.push((document) => document.modelId === propsModelId);
      }
      if (this.searchQuery && this.searchQuery.length) {
        const searchString = this.searchQuery.toLowerCase();
        predicates.push((doc) => {
          let matches = false;
          if (doc.name && doc.name.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          if (
            !matches &&
            doc.fileList &&
            doc.fileList[0] &&
            doc.fileList[0].name.toLowerCase().indexOf(searchString) !== -1
          ) {
            matches = true;
          }
          return matches;
        });
      }
      if (!predicates.length) {
        this.filteredDocuments = filteredDocuments;
      } else {
        this.filteredDocuments = filter(filteredDocuments, (doc) => predicates.every((func) => func(doc)));
      }
    },
    async loadDataFromServer() {
      this.loading = true;
      const params = {};

      if (!this.modelID) {
        //modelID is not yet loaded
        return;
      }

      // add query parameter in request if conditions are set. Basically it's used in Project to get relative documents
      if (this.searchByRelative) {
        params.relatedModelId = this.modelID;
      }

      //get document list from api REST call
      const modelType = this.modelType === "serviceProvider" ? "serviceProviderType" : this.modelType;

      if (this.withSuppliers) {
        const suppliers = await this.axios.get(`/api/suppliers/for-resource/${this.modelID}`);
        this.suppliers = suppliers.data;
      }

      await this.axios
        .get(`/api/documents/${modelType}/${this.modelID}`, { params })
        .then((result) => {
          return result.data.map((doc) => {
            // find modelType label for each document, or provide modeltype id as feedback (if it's deleted or smth)
            const modelType = this.typeOptions.find((option) => option._id === doc.type) || { label: doc.type };
            let supplierName;
            if (doc.supplier) {
              const found = this.suppliers.find(({ _id }) => _id === doc.supplier);
              supplierName = found && found.name;
            }
            return {
              ...doc,
              modelLabel: modelType.label,
              supplierName,
              filename: this.getFilenameForDocument(doc),
            };
          });
        })
        .then((data) => {
          const sortedByName = sortBy(data, [(r) => get(r, "fileList.0.name", "").toLocaleLowerCase()]);
          this.documents = sortBy(sortedByName, ["modelLabel"]);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        })
        // always executed
        .then(() => (this.loading = false));
    },
    getProjectName(document) {
      const project = this.projectsMeta.find((project) => project.id === document.modelId) || {};
      return project.bvName;
    },
    newDocument() {
      if (this.documents.some((item) => item.isNew)) {
        Message({
          message: "Bitte offenes Dokument abschließen",
          type: "warning",
        });
        return;
      }

      this.axios
        .post("/api/documents/empty", {
          model: this.modelType,
          modelId: this.modelID,
        })
        .then((response) => {
          this.editingDocumentRecord = { ...response.data, isNew: true };
          this.isEditVisible = true;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    cancelDocument(documentId) {
      this.axios.delete("/api/documents/" + documentId).then((response) => {
        const index = this.documents.findIndex((item) => item._id === documentId);
        if (index !== -1) {
          this.documents.splice(index, 1);
        }
      });
    },
    handleDeleteRecord(documentId) {
      MessageBox.confirm("wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      })
        .then(() => {
          this.axios
            .delete("/api/documents/" + documentId)
            .then((response) => {
              const index = this.documents.findIndex((item) => item._id === documentId);
              if (index !== -1) {
                this.$delete(this.documents, index);
              }
              Message({
                message: "Dokument gelöscht",
                type: "success",
              });
            })
            .catch((error) => {
              Message({
                message: error.message,
                type: "error",
              });
            });
        })
        .catch(() => {
          // do nothing
        });
    },
    handleEditRecord(storedRecord, index) {
      this.isEditVisible = true;
      this.editingIdx = index;
      this.editingDocumentRecord = {
        _id: storedRecord._id,
        name: storedRecord.name,
        active: storedRecord.active,
        type: storedRecord.type,
        supplier: storedRecord.supplier,
        dateRange: storedRecord.dateRange,
        fileList: storedRecord.fileList,
        freeText: storedRecord.freeText,
      };
    },
    viewDocument(record) {
      const url = this.getUrlForDocument(record);
      if (url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + url;
        this.openPdf({ url: completeUrl, fileName: record.fileList && record.fileList[0].name });
      } else {
        window.open(`${url}?view=true`, "_blank");
      }
    },
    downloadDocument(record) {
      const url = this.getUrlForDocument(record);
      window.open(url, "_blank");
    },
    async saveDocument(document, keepNewFlag) {
      try {
        let uri = "/api/documents/" + document._id;

        //disable edit type
        document.editForm = false;
        delete document.isNew;
        const response = await this.axios.put(uri, document);
        if (!keepNewFlag) {
          delete this.editingDocumentRecord.isNew;
        }
        const modelType = this.typeOptions.find((option) => option._id === response.data.type) || {
          label: response.data.type,
        };
        const docData = { ...response.data, modelLabel: modelType.label };
        const index = this.documents.findIndex((item) => item._id === document._id);
        if (index !== -1) {
          this.$set(this.documents, index, { ...docData });
        } else {
          this.documents.push(docData);
        }
        Message({
          message: "Dokument gespeichert",
          type: "success",
        });
        this.loadDataFromServer();
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    getMetaData(document) {
      const metadata = this.modelType + "_" + this.modelID + "_document_" + document._id;
      return metadata;
    },
    getUrlForDocument(record) {
      return record.fileList && record.fileList[0] ? record.fileList[0].url : undefined;
    },
    getFilenameForDocument(record) {
      return record.fileList && record.fileList[0] ? record.fileList[0].name : undefined;
    },
    fileUploaded(response, file, fileList) {
      if (response.length === 1 && this.editingDocumentRecord) {
        // NOTE: consider deleting old file before adding new one
        this.editingDocumentRecord.fileList = [];
        this.editingDocumentRecord.fileList.push({
          name: response[0].name,
          url: response[0].url,
        });
        this.saveDocument({ ...this.editingDocumentRecord }, true);
        this.showFileRequired = false;
      }
    },
    fileRemoved(file, fileList) {
      const metadata = this.getMetaData(this.editingDocumentRecord);
      this.removePreviousFileParams = {
        filename: file.name,
        metadata: metadata,
      };
      this.editingDocumentRecord.fileList = [];
      this.showFileRequired = true;
    },
    async removePreviousFile() {
      try {
        const params = { ...this.removePreviousFileParams };
        await this.axios.delete("/api/fileupload", {
          params,
        });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    formatDateRange(record) {
      if (!record.dateRange || !record.dateRange.length) {
        return "N/A";
      }
      return `${moment(record.dateRange[0]).format("L")} - ${moment(record.dateRange[1]).format("L")}`;
    },
    dismissChanges() {
      if (
        !this.editingDocumentRecord.isNew &&
        (!this.editingDocumentRecord.fileList || !this.editingDocumentRecord.fileList.length)
      ) {
        this.showFileRequired = true;
        return;
      } else {
        this.showFileRequired = false;
      }
      if (this.editingDocumentRecord.isNew) {
        this.cancelDocument(this.editingDocumentRecord._id);
      }
      this.isEditVisible = false;
      this.editingDocumentRecord = {};
      this.removePreviousFileParams = null;
      this.removePreviousFileParams = null;
    },
    async submitChanges() {
      try {
        if (this.removePreviousFileParams) {
          await this.removePreviousFile();
        }
        const isValid = await this.$refs.validateDocsObserver.validate();
        if (!isValid) {
          return;
        }
        const record = { ...this.editingDocumentRecord };
        if (this.systemRelevant && !record.name) {
          MessageBox.alert("Systemrelevante Dokumente benötigen eine Bezeichnung", "", {
            type: "error",
            showClose: false,
          });
          return;
        }

        if (this.systemRelevant && record.dateRange.length == 0) {
          MessageBox.alert("Systemrelevante Dokumente benötigen einen Gültigkeitszeitraum", "", {
            type: "error",
            showClose: false,
          });
          return;
        }
        if (!record.fileList || !record.fileList.length) {
          this.showFileRequired = true;
          return;
        }
        if (!record.type) {
          return;
        }
        await this.saveDocument(record);
        this.dismissChanges();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    tableRowClassName({ row }) {
      if (!row.active) {
        return "inactive-row";
      }
    },
    setMaxTableHeight() {
      const wrapper = document.getElementById("documents-list");
      const wrapperHeight = wrapper.clientHeight;
      this.tableMaxHeight = wrapperHeight - 120; // 120 - height of elements on top. Calculated by eye.
    },
  },
};
</script>

<style lang="scss">
.file-box {
  /* float: left; */
  width: 220px;
  display: inline-block;
  padding-top: 20px;
}

.file-box.edit {
  width: 390px;
}

.file {
  border: 1px solid #e7eaec;
  padding: 0;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
}

.corner {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  line-height: 0;
  border: 0.6em solid transparent;
  border-right: 0.6em solid #f1f1f1;
  border-bottom: 0.6em solid #f1f1f1;
  right: 0em;
  bottom: 0em;
}

.file .icon {
  padding: 15px 10px;
  text-align: center;
}

.file .icon,
.file .icon i {
  font-size: 70px;
  color: #dadada;
}

.file .file-name {
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #e7eaec;
}

.file .icon,
.img-fluid {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

.file-name small {
  color: #676a6c;
}

.file-name label {
  color: #9c9ea0;
  font-size: 80%;
  font-weight: 400;
  margin-bottom: -10px;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.file-header-bar {
  background-color: rgb(212, 212, 212);
  position: absolute;
  width: 100%;
  height: 22px;
  margin-top: -20px;
}

.file-header-bar-stripes {
  background: repeating-linear-gradient(45deg, #123456, #123456 10px, #5f97cf 10px, #5f97cf 20px);
  position: absolute;
  width: 100%;
  height: 22px;
  margin-top: -20px;
}

.required-message {
  margin-left: 10px;
  font-size: 0.9em;
  color: #dc0d0d;
}

.top-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-right: 20px;
}
</style>
