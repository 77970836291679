const INITIAL_DATA = {
  employee: "STANDARD",
  machine: "STANDARD",
  vehicle: "STANDARD",
  rhb: "STANDARD",
  supply: "CONCURRENT",
  subcontractor: "CONCURRENT",
};

const getGranularitySettings = () => {
  try {
    const json = localStorage.getItem("granularity_settings");
    if (!json) {
      return { ...INITIAL_DATA };
    }
    return { ...INITIAL_DATA, ...JSON.parse(json) };
  } catch (error) {
    return { ...INITIAL_DATA };
  }
};

const state = { data: getGranularitySettings(), visible: false };

const actions = {
  updateSetting({ commit }, payload) {
    commit("updateSetting", payload);
  },
  setVisible({ commit }, isVisible) {
    commit("setVisible", isVisible);
  },
  // getGranularityMode({ state }, resourceType) {
  //   return state.data[resourceType];
  // },
};

const mutations = {
  updateSetting(state, payload) {
    state.data = { ...payload };
    localStorage.setItem("granularity_settings", JSON.stringify(state.data));
  },
  setVisible(state, payload) {
    state.visible = payload;
  },
};

const getters = {
  data(state) {
    return state.data;
  },
  visible(state) {
    return state.visible;
  },
  getGranularityMode: (state) => (resourceType) => {
    return state.data[resourceType];
  },
};

export const granularitySettings = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
