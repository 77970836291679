<template>
  <!-- DIALOG -->
  <el-dialog title="Status Konfiguration" :visible.sync="dialogVisible" @close="discard">
    <el-form :model="formData" :rules="formRules" ref="typeSettingsForm" :disabled="!isEditMode">
      <el-form-item label="Aktiv" prop="active" :label-width="formData.labelwidth">
        <profile-switch v-model="formData.active" no-title></profile-switch>
      </el-form-item>
      <el-form-item label="Status" prop="selectedType" :label-width="formData.labelwidth">
        <el-input v-model="formData.selectedType" auto-complete="off"></el-input>
      </el-form-item>

      <el-form-item
        prop="symbol"
        :label="$t('src.components.uicomponents.helper.typesettings.symbol')"
        :label-width="formData.labelwidth"
      >
        <symbol-select v-model="formData.symbol" />
      </el-form-item>
      <el-form-item
        prop="color"
        :label="$t('src.components.uicomponents.helper.typesettings.symbolFarbe')"
        :label-width="formData.labelwidth"
      >
        <color-select v-model="formData.color" />
      </el-form-item>
      <el-form-item prop="filesAccessRights" :label="$t('filesAccessRights')" :label-width="formData.labelwidth">
        <el-select clearable multiple v-model="formData.filesAccessRights" :placeholder="$t('filesAccessRights')">
          <el-option v-for="item in accessGroupOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item prop="freeTextAccessRights" :label="$t('freeTextAccessRights')" :label-width="formData.labelwidth">
        <el-select clearable multiple v-model="formData.freeTextAccessRights" :placeholder="$t('freeTextAccessRights')">
          <el-option v-for="item in accessGroupOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('src.components.uicomponents.helper.typesettings.systemrelevanz')"
        :label-width="formData.labelwidth"
      >
        <el-tooltip
          content="Systemrelevante Status werden im u.a. Kalender verwendet"
          placement="bottom"
          effect="light"
        >
          <el-checkbox v-model="formData.systemRelevant" border>{{
            formData.systemRelevant ? "systemrelevant" : "ohne Auswirkung"
          }}</el-checkbox>
        </el-tooltip>
      </el-form-item>
      <el-form-item :label="$t('Info visible in kiosk mode')" :label-width="formData.labelwidth">
        <el-switch
          v-model="formData.kioskInfoVisible"
          active-color="#18ce68"
          inactive-color="#ff6868"
          style="margin-right: 5px"
          active-text="Ja"
          inactive-text="Nein"
        />
      </el-form-item>
      <el-form-item :label="$t('Attachement visible in kiosk mode')" :label-width="formData.labelwidth">
        <el-switch
          v-model="formData.kioskFilesVisible"
          active-color="#18ce68"
          inactive-color="#ff6868"
          style="margin-right: 5px"
          active-text="Ja"
          inactive-text="Nein"
        />
      </el-form-item>
      <el-form-item :label="$t('Info visible in SMA for relevant employee')" :label-width="formData.labelwidth">
        <el-switch
          v-model="formData.assigneeFreeTextVisible"
          active-color="#18ce68"
          inactive-color="#ff6868"
          style="margin-right: 5px"
          active-text="Ja"
          inactive-text="Nein"
        />
      </el-form-item>
      <el-form-item :label="$t('Attachment visible for relevant employee')" :label-width="formData.labelwidth">
        <el-switch
          v-model="formData.assigneeFilesVisible"
          active-color="#18ce68"
          inactive-color="#ff6868"
          style="margin-right: 5px"
          active-text="Ja"
          inactive-text="Nein"
        />
      </el-form-item>
      <el-form-item
        :label="$t('src.components.uicomponents.helper.typesettings.privat')"
        :label-width="formData.labelwidth"
      >
        <el-tooltip content="unterschiedlicher Status für Sonderrechte" effect="light">
          <el-switch
            v-model="formData.isPrivate"
            active-color="#18ce68"
            inactive-color="#ff6868"
            style="margin-right: 5px"
            active-text="Ja"
            inactive-text="Nein"
          />
        </el-tooltip>
      </el-form-item>
      <el-form-item
        prop="accessGroups"
        :label="$t('src.components.uicomponents.helper.typesettings.accessGroups')"
        :label-width="formData.labelwidth"
        v-if="formData.isPrivate"
      >
        <el-select
          clearable
          multiple
          v-model="formData.accessGroups"
          :placeholder="$t('src.components.uicomponents.helper.typesettings.accessGroups')"
        >
          <el-option v-for="item in accessGroupOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('src.components.uicomponents.helper.typesettings.publicName')"
        :label-width="formData.labelwidth"
        v-if="formData.isPrivate"
        prop="publicName"
      >
        <el-input v-model="formData.publicName" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item
        prop="symbol"
        :label="$t('src.components.uicomponents.helper.typesettings.publicIcon')"
        :label-width="formData.labelwidth"
        v-if="formData.isPrivate"
      >
        <symbol-select v-model="formData.publicIcon" />
      </el-form-item>
      <el-form-item
        prop="publicColor"
        :label="$t('src.components.uicomponents.helper.typesettings.publicColor')"
        :label-width="formData.labelwidth"
        v-if="formData.isPrivate"
      >
        <color-select v-model="formData.publicColor" />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <span class="dialog-footer">
        <cancel-button
          :text="$t('src.components.uicomponents.helper.typesettings.abbrechen')"
          @click.prevent="discard"
        ></cancel-button
        >̉

        <div class="text-right">
          <save-button
            v-if="dialogIsEditForm"
            :text="$t('src.components.uicomponents.helper.typesettings.speichern')"
            :loading="loading"
            @click.prevent="updateDialog"
          ></save-button>
          <save-button
            v-else
            :text="$t('src.components.uicomponents.helper.typesettings.speichern')"
            :loading="loading"
            @click.prevent="saveDialog"
          ></save-button>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import SymbolSelect from "./SymbolSelect.vue";
import ColorSelect from "./ColorSelect.vue";
import { Form, FormItem, Dialog, Message, Select, Option, Tooltip, Switch, Checkbox } from "element-ui";

export default {
  name: "status-settings-dialog",
  components: {
    SymbolSelect,
    ColorSelect,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [Checkbox.name]: Checkbox,
  },
  props: {
    resourceType: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      typeId: null,
      dialogVisible: false,
      dialogIsEditForm: false,
      accessGroupOptions: [],
      formData: {
        labelwidth: "300px",
        selectedType: "",
        accessGroups: [],
        symbol: "",
        color: "",
        resourceType: this.resourceType,
        systemRelevant: false,
        active: true,
        isPrivate: false,
        kioskInfoVisible: false,
        kioskFilesVisible: false,
        assigneeFreeTextVisible: false,
        assigneeFilesVisible: false,
        publicName: "",
        publicColor: "",
        publicIcon: "",
        filesAccessRights: [],
        freeTextAccessRights: [],
      },
      formRules: {
        selectedType: { required: true, message: "Dieses Feld wird benötigt", trigger: "change" },
        symbol: { required: true, message: "Dieses Feld wird benötigt", trigger: "change" },
        color: { required: true, message: "Dieses Feld wird benötigt", trigger: "change" },
        publicColor: { required: false, message: "Dieses Feld wird benötigt", trigger: "change" },
        publicIcon: { required: false, message: "Dieses Feld wird benötigt", trigger: "change" },
        publicName: { required: false, message: "Dieses Feld wird benötigt", trigger: "change" },
        accessGroups: { required: false, message: "Dieses Feld wird benötigt", trigger: "change" },
      },
    };
  },
  methods: {
    $open() {
      this.dialogVisible = true;
      if (this.$can("read", "usergroup")) {
        this.fetchAccessGroups();
      }
    },
    async $edit(typeId) {
      try {
        this.typeId = typeId;
        const response = await this.axios.get(`/api/model-type/${typeId}`);
        this.formData.selectedType = response.data.label;
        this.formData.symbol = response.data.icon;
        this.formData.color = response.data.color;
        this.formData.resourceType = this.resourceType;
        this.formData.systemRelevant = response.data.systemRelevant;
        this.formData.accessGroups = response.data.accessGroups;
        this.formData.active = response.data.active;
        this.formData.isPrivate = response.data.isPrivate;
        this.formData.publicName = response.data.publicName;
        this.formData.publicColor = response.data.publicColor;
        this.formData.publicIcon = response.data.publicIcon;
        this.formData.filesAccessRights = response.data.filesAccessRights;
        this.formData.freeTextAccessRights = response.data.freeTextAccessRights;
        this.formData.kioskInfoVisible = response.data.kioskInfoVisible;
        this.formData.kioskFilesVisible = response.data.kioskFilesVisible;
        this.formData.assigneeFreeTextVisible = response.data.assigneeFreeTextVisible;
        this.formData.assigneeFilesVisible = response.data.assigneeFilesVisible;

        this.$open();
        this.dialogIsEditForm = true;
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    //empty form data
    discard() {
      this.dialogVisible = false;
      this.dialogIsEditForm = false;
      this.formData.selectedType = "";
      this.formData.symbol = "";
      this.formData.color = "";
      this.formData.systemRelevant = false;
      this.formData.active = true;
      this.formData.kioskInfoVisible = false;
      this.formData.kioskFilesVisible = false;
      this.formData.assigneeFreeTextVisible = false;
      this.formData.assigneeFilesVisible = false;
      this.formData.accessGroups = [];
      this.formData.filesAccessRights = [];
      this.formData.freeTextAccessRights = [];
      this.$nextTick(() => {
        this.$refs.typeSettingsForm.clearValidate();
      });
    },
    async fetchAccessGroups() {
      try {
        const response = await this.axios.get("/api/user-groups");
        this.accessGroupOptions = response.data || [];
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    // TODO: emit event for update, unbind options list, maybe refetch whole list instead
    async updateDialog() {
      const isInvalid = this.$refs.typeSettingsForm.validate((valid) => !valid);
      if (isInvalid) {
        return;
      }
      try {
        this.loading = true;
        const entity = {
          active: this.formData.active,
          label: this.formData.selectedType,
          icon: this.formData.symbol,
          systemRelevant: this.formData.systemRelevant,
          accessGroups: this.formData.accessGroups,
          filesAccessRights: this.formData.filesAccessRights,
          freeTextAccessRights: this.formData.freeTextAccessRights,
          color: this.formData.color,
          model: "status",
          modelID: this.resourceType,
          isPrivate: this.formData.isPrivate,
          kioskInfoVisible: this.formData.kioskInfoVisible,
          kioskFilesVisible: this.formData.kioskFilesVisible,
          assigneeFreeTextVisible: this.formData.assigneeFreeTextVisible,
          assigneeFilesVisible: this.formData.assigneeFilesVisible,
          publicName: this.formData.publicName,
          publicColor: this.formData.publicColor,
          publicIcon: this.formData.publicIcon,
        };

        const response = await this.axios.put("/api/model-type/" + this.typeId, entity);
        this.$emit("updated", response.data);
        this.discard();
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      } finally {
        this.loading = false;
      }
    },
    saveDialog() {
      this.$refs.typeSettingsForm.validate(async (valid) => {
        if (valid) {
          if (this.formData.selectedType === "") {
            MessageBox.alert("Neuer Typ ist leer.", "", {
              type: "error",
              showClose: false,
            });
            return;
          }
          try {
            this.loading = true;

            const entity = {
              active: this.formData.active,
              label: this.formData.selectedType,
              icon: this.formData.symbol,
              systemRelevant: this.formData.systemRelevant,
              accessGroups: this.formData.accessGroups,
              filesAccessRights: this.formData.filesAccessRights,
              freeTextAccessRights: this.formData.freeTextAccessRights,
              color: this.formData.color,
              model: "status",
              modelID: this.resourceType,
              isPrivate: this.formData.isPrivate,
              kioskInfoVisible: this.formData.kioskInfoVisible,
              kioskFilesVisible: this.formData.kioskFilesVisible,
              assigneeFreeTextVisible: this.formData.assigneeFreeTextVisible,
              assigneeFilesVisible: this.formData.assigneeFilesVisible,
              publicName: this.formData.publicName,
              publicColor: this.formData.publicColor,
              publicIcon: this.formData.publicIcon,
            };

            const response = await this.axios.post("/api/model-type", entity);
            this.$emit("updated", response.data);
            this.discard();
          } catch (error) {
            if (error.response && error.response.data.message) {
              Message({
                message: error.response.data.message,
                type: "error",
              });
            } else {
              Message({
                message: error.message,
                type: "error",
              });
            }
            throw error;
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    "formData.isPrivate": function (newVal) {
      if (newVal) {
        this.formRules.publicColor.required = true;
        this.formRules.publicIcon.required = true;
        this.formRules.publicName.required = true;
        this.formRules.accessGroups.required = true;
      } else {
        this.formRules.publicIcon.required = false;
        this.formRules.publicColor.required = false;
        this.formRules.publicName.required = false;
        this.formRules.accessGroups.required = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
