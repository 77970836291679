export default [
  {
    name: "Dashboard",
    icon: "nc-icon nc-sound-wave",
    path: "/dashboard",
    auth: "dashboard",
    matchRegex: "/dashboard",
  },
  {
    name: "Projektliste",
    path: "/project/index",
    auth: "project",
    matchRegex: "/project/.*",
  },
  {
    name: "Kalender",
    path: "/projectcalendar",
    auth: "calendar",
    matchRegex: "/projectcalendar/",
  },
  {
    name: "Maschinen",
    icon: "nc-icon nc-headphones",
    auth: "machine",
    matchRegex: "/machine/|/rhb/|/supply/",
    children: [
      {
        name: "Maschinen",
        path: "/machine/index",
        auth: "machine",
        meta: "/machine/",
        matchRegex: "/machine/",
      },
      {
        name: "RHB",
        icon: "nc-icon nc-diamond",
        path: "/rhb/index",
        auth: "rhb",
        matchRegex: "/rhb/",
      },
      {
        name: "Verbrauchsmaterialien",
        icon: "nc-icon nc-box-2",
        path: "/supply/index",
        auth: "supply",
        matchRegex: "/supply/",
      },
    ],
  },
  {
    name: "KFZ",
    icon: "nc-icon nc-delivery-fast",
    path: "/vehicle/index",
    auth: "vehicle",
    matchRegex: "/vehicle/",
  },
  {
    name: "Mitarbeiter",
    icon: "nc-icon nc-badge",
    path: "/employee/index",
    auth: "employee",
    matchRegex: "/employee/",
  },
  {
    name: "Einkauf",
    icon: "nc-icon nc-tv-2",
    path: "/purchasing",
    auth: "",
    matchRegex: "/serviceProvider/|/suppliers/",
    children: [
      {
        name: "Dienstleister",
        icon: "nc-icon nc-tie-bow",
        path: "/purchasing/serviceProvider/index",
        auth: "service_provider",
        matchRegex: "/serviceProvider/",
      },
      {
        name: "Lieferanten",
        icon: "nc-icon nc-money-coins",
        path: "/purchasing/suppliers",
        auth: "supplier",
        matchRegex: "/suppliers/",
      },
      {
        name: "Nachunternehmer",
        icon: "nc-icon nc-money-coins",
        path: "/purchasing/subcontractor",
        auth: "subcontractor",
        matchRegex: "/subcontractor/",
      },
    ],
  },
  {
    name: "Management",
    icon: "nc-icon nc-tv-2",
    path: "/management/company-settings",
    auth: "",
    matchRegex: "/management/",
    children: [
      {
        name: "Kontakte",
        path: "/management/contacts",
        auth: "contacts",
        matchRegex: "/management/contacts",
      },
      {
        name: "Finanzen",
        icon: "nc-icon nc-money-coins",
        path: "/management/finance",
        auth: "finance",
        matchRegex: "/finance",
      },
      {
        name: "Personal",
        icon: "nc-icon nc-air-baloon",
        path: "/management/expenses",
        auth: "management",
        matchRegex: "/expenses",
      },
      // { TODO: wip: document types to edit globally
      //     name: 'Erforderliche Dokument Typen',
      //     path: '/management/required-document-types',
      //     auth: 'dashboard'
      // }
    ],
  },
  {
    name: "Administration",
    icon: "nc-icon nc-settings-gear-65",
    auth: "",
    path: "/administration",
    matchRegex: "/administration",
    children: [
      {
        name: "Firmeneinstellungen",
        path: "/administration/company-settings",
        auth: "company_settings",
        matchRegex: "/company-settings",
      },
      {
        name: "Projekt Einstellungen",
        path: "/administration/project-settings",
        auth: "project_settings",
        matchRegex: "/project-settings",
      },
      {
        name: "Benutzer",
        path: "/administration/users",
        auth: "user",
        matchRegex: "/users",
      },
      {
        name: "Benutzerguppen",
        icon: "nc-icon nc-diamond",
        path: "/administration/user-groups",
        auth: "usergroup",
        matchRegex: "/user-groups",
      },
      {
        name: "Excel Synchronisierung",
        icon: "nc-icon nc-box-2",
        path: "/administration/excelSync",
        auth: "excel_sync",
        matchRegex: "/excelSync",
      },
    ],
  },
];
