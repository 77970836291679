<template>
  <div class="notifications-wrap">
    <el-badge is-dot :hidden="isBadgeHidden">
      <el-popover popper-class="notifications-popover" placement="bottom-start" v-model="isVisible" trigger="click">
        <!-- <el-popover popper-class="notifications-popover" placement="bottom" :value="true" trigger="manual"> -->
        <template v-slot:reference>
          <div class="notifications-bell">
            <bell-outline-icon />
          </div>
        </template>
        <!-- <div v-if="isBadgeHidden">{{ $t("You have no new notifications") }}</div> -->
        <div style="max-height: 400px; width: 300px; overflow: auto">
          <div class="notification-title">
            Benachrichtigungen
            <el-button size="mini" v-if="data && data.length" @click.stop="deleteAllNotifications"
              >Alles löschen</el-button
            >
          </div>
          <ul class="notification-list">
            <li class="notification-item" v-for="item in data" :key="item._id">
              <div class="cross" @click.stop="closeNotification(item)">&#x2715;</div>
              <div class="notification-name" v-if="item.projectName">
                Projekt: {{ item.fromProjectName || item.projectName }}
              </div>
              <div class="notification-text" v-html="getNoteText(item)"></div>
              <div class="notification-date">{{ item.createdAt | dateFilter }}</div>
              <el-button @click="handleNotificationClick(item)">{{ $t("To Project") }}</el-button>
            </li>
            <li class="notification-item text-center">
              <div class="notification-text mb-0 text-center" v-if="data.length">
                {{ $t("no more news") }}
              </div>
              <div class="notification-text mb-0 text-center" v-else>
                {{ $t("There are currently no messages available") }}
              </div>
            </li>
          </ul>
        </div>
      </el-popover>
    </el-badge>
  </div>
</template>

<script>
import { Popover, Badge } from "element-ui";
import BellOutline from "vue-material-design-icons/BellOutline.vue";
import { io } from "socket.io-client";
import { mapGetters, mapState, mapActions } from "vuex";
import { moment } from "src/config/moment";

const NOTIFICATION_NAMESPACES = {
  NOTES: "Note",
  TODOS: "To-Do",
};

const NOTIFICATION_ACTION_TYPES = {
  NOTES: {
    ITEM_ADDED: "commented",
    ITEM_CHANGED: "changed",
    ITEM_DELETED: "deleted",
  },
  TODOS: {
    ITEM_ADDED: "assigned on you",
    ITEM_CHANGED: "commented",
    ITEM_DELETED: "deleted",
  },
};

export default {
  name: "header-notifications",
  components: {
    [Badge.name]: Badge,
    [Popover.name]: Popover,
    [BellOutline.name]: BellOutline,
  },
  data() {
    return {
      socket: null,
      isBadgeHidden: true,
      isVisible: false,
      isInNotesPage: false,
      isInTodosPage: false,
    };
  },
  mounted() {
    this.socket = io(`${this.axios.defaults.baseURL}/notifications`, { auth: { token: this.authToken } });
    this.socket.on("message", (payload) => {
      try {
        const receivedNotification = JSON.parse(payload);
        console.log("receivedNotification.type", receivedNotification.type);
        switch (receivedNotification.type) {
          case "new_notification":
            // if (this.isInNotesPage || this.isInTodosPage) {
            //   this.$root.$emit("refetchNotes");
            //   this.$root.$emit("refetchTodos");
            // } else {
            this.new(receivedNotification.entry);
            this.isBadgeHidden = false;
            break;
          case "remove_notification":
          case "record_deleted":
            this.remove(receivedNotification.entry);
            this.isBadgeHidden = this.isBadgeHidden || !this.data.length;
            break;
          case "refetch_notifications":
            this.fetchNotifications();
            break;
          default:
            console.log("switch-case: default. payload:", payload);
            break;
        }
      } catch (error) {
        console.log("payload", payload);
        throw error;
      }
    });
    this.fetchNotifications();
    // this.$OneSignal.init({ appId: "ec7ad298-e27a-4226-9323-c7544a488380" }).then(() => {
    //   const userId = this.userId;
    //   console.log("userId", userId);
    //   if (userId) {
    //     this.$OneSignal.showSlidedownPrompt().then(() => {
    //       this.$OneSignal.setExternalUserId(userId);
    //     });
    //   }
    // });
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  methods: {
    ...mapActions("notifications", ["set", "new", "remove", "close"]),
    showNotifications() {
      this.isVisible = true;
    },
    async fetchNotifications() {
      try {
        const response = await this.axios.get("/api/notifications");
        if (response.data && response.data.entries && response.data.entries.length) {
          this.set(response.data.entries);
          this.isBadgeHidden = false;
        }
      } catch (error) {
        throw error;
      }
    },
    handleNotificationClick(item) {
      if (item.namespace === "NOTES") {
        if (item.isWorkshop) {
          this.$router.push({
            name: "Workshop View",
            params: { id: item.projectId, tab_pane: "notes", noteId: item.entryId },
          });
        } else {
          this.$router.push({
            name: "Project View",
            params: { id: item.projectId, tab_pane: "notes", noteId: item.entryId },
          });
        }
      } else if (item.namespace === "TODOS") {
        if (item.isWorkshop) {
          this.$router.push({
            name: "Workshop View",
            params: { id: item.projectId, tab_pane: "todos", todoId: item.entryId },
          });
        } else {
          this.$router.push({
            name: "Project View",
            params: { id: item.projectId, tab_pane: "todos", todoId: item.entryId },
          });
        }
      } else {
        throw new Error(`unknown namespace "${item.namespace}"`);
      }
      this.closeNotification(item);
      this.isVisible = false;
    },
    getNoteText(item) {
      const namespace = this.getNamespaceLocalized(item.namespace);
      const action = this.getActionLocalized(item.namespace, item.notificationType);
      let recipient;
      if (item.isWorkshop) {
        recipient = item.projectName;
      } else if (item.responsibleName) {
        recipient = item.responsibleName;
      }
      let msg;
      if (recipient) {
        msg = `<b>${item.whoDid || this.$t("Somebody")}</b> hat ein ${namespace} <b>${recipient}</b> zugewiesen`;
      } else {
        msg = `<b>${item.whoDid || this.$t("Somebody")}</b> hat eine <b>${namespace}</b> ${action}`;
      }
      return msg;
    },
    getNamespaceLocalized(namespace) {
      return this.$t(NOTIFICATION_NAMESPACES[namespace]);
    },
    getActionLocalized(namespace, actionType) {
      return this.$t(NOTIFICATION_ACTION_TYPES[namespace][actionType] || actionType);
    },
    closeNotification(item) {
      this.close(item);
      this.socket.emit("dismiss-notification", JSON.stringify({ notificationId: item._id }));
    },
    deleteAllNotifications() {
      this.data.forEach(this.closeNotification);
    },
  },
  computed: {
    ...mapGetters("account", ["authToken", "userId"]),
    ...mapState("currentTab", { currentTab: "tab" }),
    ...mapState("notifications", ["data"]),
  },
  filters: {
    dateFilter(dateString) {
      if (dateString) {
        return moment(dateString).format("lll");
      }
      return "N/A";
    },
  },
  watch: {
    isVisible(newVal, oldVal) {
      if (newVal) {
        this.isBadgeHidden = true;
      }
    },
    currentTab(newVal) {
      if (newVal === "notes") {
        this.isInNotesPage = true;
      } else {
        this.isInNotesPage = false;
      }
      if (newVal === "todos") {
        this.isInTodosPage = true;
      } else {
        this.isInTodosPage = false;
      }
    },
  },
};
</script>

<style></style>
