<template>
  <v-date-picker
    locale="de"
    show-iso-weeknumbers
    v-if="visible"
    v-bind="$attrs"
    v-model="dateInput"
    :is-range="isRange"
    @dayclick="onDayClick"
    :attributes="computedAttributes.concat(previousValueAttribute)"
    ref="calendar"
    @popoverDidShow="handleFocusDateRange"
    @popoverDidHide="handleDismissSelected"
    :popover="{ positionFixed: true, keepVisibleOnInput: true, visibility: 'focus' }"
  >
    <template v-slot="{ inputValue, inputEvents, isDragging }" v-if="isRange">
      <div class="v-date-range-wrap" :data-testid="dataTestid">
        <!-- <button @click.prevent="togglePopover">tgl</button> -->
        <div :class="['v-date-input-wrap', sizeClass]">
          <calendar-week-icon class="v-date-input-icon" />
          <input
            class="v-date-input"
            :class="isDragging ? 'grey' : ''"
            :value="inputValue.start"
            v-on="inputEvents.start"
            @focusin="handleFocus('start', $event, inputEvents.start.focusin)"
            @click="clickInput($event, inputEvents.start.click, inputValue.start)"
            :placeholder="$t('src.components.project.bryntumscheduler.montagsplanung.startDatum')"
            :disabled="disabled"
          />
        </div>
        <span class="v-date-range-arrow">
          <arrow-right-icon />
        </span>
        <div :class="['v-date-input-wrap', sizeClass]">
          <calendar-week-icon class="v-date-input-icon" />
          <input
            class="v-date-input"
            :class="isDragging ? 'grey' : ''"
            :value="inputValue.end"
            v-on="inputEvents.end"
            @focusin="handleFocus('end', $event, inputEvents.end.focusin)"
            @click="clickInput($event, inputEvents.end.click, inputValue.end)"
            :placeholder="$t('src.components.project.bryntumscheduler.montagsplanung.endeDatum')"
            :disabled="disabled"
          />
          <!-- @change="logEvent('change', $event, inputEvents.end.change)"
            @click="logEvent('click', $event, inputEvents.end.click)"
            @input="logEvent('input', $event, inputEvents.end.input)"
            @keyup="logEvent('keyup', $event, inputEvents.end.keyup)" -->
          <!-- v-on="inputEvents.end" -->
          <!-- @mouseleave="logEvent('mouseleave', $event, inputEvents.end.mouseleave)"
            @mousemove="logEvent('mousemove', $event, inputEvents.end.mousemove)" -->
          <i
            v-if="clearable"
            @click="clearInput"
            class="close-icon el-select__caret el-input__icon el-icon-circle-close"
          ></i>
        </div>
      </div>
    </template>
    <template v-else v-slot="{ inputValue, inputEvents }">
      <div :class="['v-date-input-wrap', sizeClass]" :data-testid="dataTestid">
        <calendar-week-icon class="v-date-input-icon" />
        <input
          class="v-date-input"
          :value="inputValue"
          v-on="inputEvents"
          @click="clickInput($event, inputEvents.click, inputValue)"
          :disabled="disabled"
        />
        <i
          v-if="clearable"
          @click="clearInput"
          class="close-icon el-select__caret el-input__icon el-icon-circle-close"
        ></i>
      </div>
    </template>
    <template #footer>
      <div style="padding: 5px 0" class="p-2 d-flex justify-content-between">
        <div>
          <el-button size="small" type="primary" @click="moveToToday">{{ $t("heute") }}</el-button>
        </div>
        <div>
          <el-button size="small" @click="cancel"><close-icon /></el-button>
          <el-button
            size="small"
            :data-testid="`submit_${dataTestid || 'datepicker'}`"
            type="primary"
            @click="submit"
            v-if="isRange"
            ><check-icon
          /></el-button>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { moment } from "src/config/moment";
import { MessageBox } from "element-ui";
import CalendarWeek from "vue-material-design-icons/CalendarWeek.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import { unlink } from "src/utils/unlink";
import { isDataChanged } from "src/utils/isDataChanged";
import { get, isEmpty } from "lodash";
import CloseIcon from "vue-material-design-icons/Close";
import CheckIcon from "vue-material-design-icons/Check";

const sizeClasses = {
  small: "sm",
  mini: "mini",
};

export default {
  name: "pr-date-picker",
  components: {
    CloseIcon,
    CheckIcon,
    [CalendarWeek.name]: CalendarWeek,
    [ArrowRight.name]: ArrowRight,
    [MessageBox.name]: MessageBox,
    "v-date-picker": DatePicker,
  },
  props: {
    value: { type: [String, Array, Date] },
    focusDate: { type: Date },
    markedDay: { type: Date },
    projectDateRange: { type: Array },
    attributes: { type: Array },
    isRange: { type: Boolean },
    clearable: { type: Boolean, default: true },
    pickerOptions: { type: Object },
    valueFormat: { type: String },
    placeholder: { type: String },
    dataTestid: { type: String },
    size: { type: String },
    disabled: { type: Boolean },
    /* To implement an additional custom MessageBox. Argument is the changed date(-range). */
    customValidationFn: { type: Function },
  },
  data() {
    return {
      visible: true, // is used to re-initialize datepicker with new cell classnames when project daterange changes
      previousValue: null, // to return to previous value if prompt was rejected
      localValue: null,
      startDateFocused: false,
      endDateFocused: false,
      clickWithinPopup: false,
      forcePopupVisible: false,
    };
  },
  mounted() {
    if (this.value) {
      if (this.isRange && !this.value.length) {
        return;
      }
      this.setValueToDate(this.value, "localValue");
      this.setValueToDate(this.value, "previousValue");
    }
  },
  methods: {
    resetPreviousValue() {
      this.previousValue = null;
    },
    onDayClick(day) {
      if (this.clickWithinPopup && this.value && this.value.length) {
        if (this.startDateFocused) {
          this.localValue.start = day.date.toISOString();
          this.$refs.calendar.dragValue = null;
          this.$refs.calendar.updateValue({ ...this.localValue });
        } else if (this.endDateFocused) {
          this.localValue.end = day.date.toISOString();
          this.$refs.calendar.dragValue = null;
          this.$refs.calendar.updateValue({ ...this.localValue });
        }
      }
      if (!this.isRange) {
        this.$nextTick(() => {
          this.submit();
        });
      }
    },
    handleFocus(name, event, fn) {
      if (name === "start") {
        this.startDateFocused = true;
        this.endDateFocused = false;
      } else if (name === "end") {
        this.endDateFocused = true;
        this.startDateFocused = false;
      }
      document.addEventListener("click", this.calendarClickListener, false);
      fn(event);
      event.preventDefault();
    },
    async clickInput(event, fn, value) {
      if (value) {
        await this.$refs.calendar.move(moment(value, "L").toDate(), { transition: "none" });
      }
      fn(event);
    },
    dismissClickListener() {
      document.removeEventListener("click", this.calendarClickListener);
    },
    calendarClickListener(event) {
      const container = document.querySelector(".vc-container");
      if ((container && container.contains(event.target)) || event.target.classList.contains("v-date-input")) {
        this.clickWithinPopup = true;
      } else {
        this.clickWithinPopup = false;
        this.dismissClickListener();
      }
    },
    cancel() {
      this.localValue = unlink(this.previousValue);
      const popoverRef = get(this.$refs.calendar, "$refs.popover");
      this.dismissClickListener();
      if (popoverRef) {
        popoverRef.hide();
      }
    },
    submit() {
      if (this.isRange) {
        this.setValueToDate([this.localValue.start, this.localValue.end], "previousValue");
      } else {
        this.setValueToDate(this.localValue, "previousValue");
      }
      this.sendValue(this.localValue);
    },
    handleFocusDateRange() {
      if (this.focusDate) {
        this.$refs.calendar.move(this.focusDate);
      } else if (!this.value && this.projectDateRange && this.projectDateRange.length) {
        this.$refs.calendar.move(moment(this.projectDateRange[0]).toDate());
      }
    },
    moveToDay(day) {
      this.$refs.calendar.move(day);
    },
    handleDismissSelected() {
      if (this.forcePopupVisible) {
        if (isDataChanged(this.localValue, this.previousValue)) {
          this.localValue = unlink(this.previousValue);
        }
      }
    },
    handleDateChange(val) {
      this.forcePopupVisible = true;
      if (!this.projectDateRange) {
        return;
      }
      const _projectRange = moment.range(this.projectDateRange).snapTo("day");
      if (this.isRange) {
        // return if daterange is within project range
        if (_projectRange.contains(moment.range(val.start, val.end))) {
          this.forcePopupVisible = false;
          return;
        }
      } else {
        // return if date is within project range
        if (moment(val).hours(12).within(_projectRange)) {
          this.forcePopupVisible = false;
          return;
        }
      }
      // if date/daterange is not withing project daterange - prompt for change
      MessageBox.confirm("Außerhalb des Projektzeitraums. Fortsetzen?", "Warnung", {
        center: true,
      })
        .then(() => {
          this.$refs.calendar.showPopover();
          this.localValue = val;
          this.submit();
        })
        .catch((err) => {
          this.resetToPreviousValue();
        })
        .finally(() => {
          this.forcePopupVisible = false;
        });
    },
    resetToPreviousValue() {
      this.$refs.calendar.showPopover();
      // if canceled - return to previous value
      console.log("this.previousValue", this.previousValue);
      if (this.previousValue) {
        this.localValue = unlink(this.previousValue);
      } else {
        this.localValue = null;
      }
      this.submit();
    },
    sendValue(val) {
      if (!val) {
        this.$emit("input", null);
        this.$emit("change", null);
        return;
      }
      let newValue;
      if (this.isRange) {
        if (this.valueFormat) {
          newValue = [moment(val.start).format(this.valueFormat), moment(val.end).format(this.valueFormat)];
        } else {
          newValue = [val.start, val.end];
        }
      } else {
        if (this.valueFormat) {
          newValue = moment(val).format(this.valueFormat);
        } else {
          newValue = val;
        }
      }
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    },
    setValueToDate(val, propToSet) {
      if (Array.isArray(val) && val.length === 2) {
        if (propToSet === "localValue") {
          this.localValue = {
            start: moment(val[0], this.valueFormat).toDate(),
            end: moment(val[1], this.valueFormat).toDate(),
          };
        } else if (propToSet === "previousValue") {
          this.previousValue = {
            start: moment(val[0], this.valueFormat).toDate(),
            end: moment(val[1], this.valueFormat).toDate(),
          };
        } else {
          this[propToSet] = {
            start: moment(val[0], this.valueFormat).toDate(),
            end: moment(val[1], this.valueFormat).toDate(),
          };
        }
      } else {
        const normalizedValue = val === null ? val : moment(val, this.valueFormat).toDate();
        if (propToSet === "localValue") {
          this.localValue = normalizedValue;
        } else if (propToSet === "previousValue") {
          this.previousValue = normalizedValue;
        }
      }
      this.visible = false;
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    clearInput() {
      this.localValue = null;
      this.$emit("input", null);
      this.$emit("change", null);
    },
    moveToToday() {
      this.$refs.calendar.move(new Date());
    },
  },
  computed: {
    sizeClass() {
      return sizeClasses[this.size];
    },
    dateInput: {
      get: function () {
        return this.localValue;
      },
      set: function (newValue) {
        if (newValue && isDataChanged(newValue, this.localValue) && isDataChanged(newValue, this.previousValue)) {
          this.handleDateChange(newValue);
        }
        if (this.$props.customValidationFn) {
          this.forcePopupVisible = true;
          this.$props
            .customValidationFn(newValue)
            .then(() => {
              this.$refs.calendar.showPopover();
              this.localValue = newValue;
              this.submit();
            })
            .catch((err) => {
              this.resetToPreviousValue();
            })
            .finally(() => {
              this.forcePopupVisible = false;
            });
        }
        this.localValue = newValue;
      },
    },
    previousValueAttribute() {
      if (this.previousValue && !isEmpty(this.previousValue)) {
        let previousDateHighlight = {
          key: "previous-value",
          highlight: "gray",
          fillMode: "solid",
          dates: this.previousValue,
        };
        return [previousDateHighlight];
      } else {
        return [];
      }
    },
    computedAttributes() {
      const attrs = [
        { key: "today", dot: { color: "green" }, dates: new Date(), order: 1, popover: { label: "Heute" } },
      ];
      if (this.attributes) {
        attrs.push(...this.attributes);
      }
      if (this.projectDateRange && this.projectDateRange.length) {
        attrs.push({
          key: "project-range",
          highlight: {
            start: { color: "teal", fillMode: "light" },
            base: { color: "teal", fillMode: "light" },
            end: { color: "teal", fillMode: "light" },
          },
          // dates: {
          //   start: moment(this.projectDateRange[0]).subtract(5, "y").toDate(),
          //   end: moment(this.projectDateRange[1]).add(5, "y").toDate(),
          // },
          // excludeDates: {
          dates: {
            start: moment(this.projectDateRange[0]).toDate(),
            end: moment(this.projectDateRange[1]).toDate(),
          },
        });
      }
      if (this.markedDay) {
        attrs.push({
          key: "marked_day",
          fillMode: "light",
          highlight: "gray",
          dates: this.markedDay,
        });
      }
      return attrs;
    },
  },
  watch: {
    projectDateRange(newVal) {
      if (newVal) {
        this._projectRange = newVal.slice();
        this.visible = false;
        this.$nextTick(() => {
          this.visible = true;
        });
      }
    },
    value: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal) {
            this.setValueToDate(newVal, "localValue");
          } else {
            this.localValue = null;
          }
          if (!this.previousValue && newVal) {
            this.setValueToDate(newVal, "previousValue");
          }
        }
      },
    },
  },
};
</script>

<style>
.outside-project {
  background-color: #f5f7fa !important;
  color: #c0c4cc !important;
}
</style>
