<template>
  <div :class="['notes-item', { focused: isFocused }]" @click="handleSelectNote">
    <div class="note-editor-header">
      <div class="note-avatar">
        <lazy-image :src="`/api/employees/${data.authorEmployeeId}/picture`" />
      </div>
      <div>
        <div class="note-author">
          {{ getAuthorName }}
          <new-badge :unread="data.unread" />
          <el-badge
            :type="data.active ? 'success' : 'danger'"
            :value="data.active ? $t('Active') : $t('Inactive')"
            class="self-standing"
          >
          </el-badge>
        </div>
        <div class="note-date">{{ data.updatedAt | formatDate }}</div>
      </div>
      <div class="ml-auto" v-if="data.isOwner || isPrivilegedUser || data.canMarkAsUnread">
        <el-dropdown @command="handleItemCommand">
          <button class="btn btn-sm btn-ghost card-btn" @click.stop style="font-size: 1rem">
            <dots-vertical-icon />
          </button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="data.canMarkAsUnread && !data.unread" :command="{ action: 'unread' }">
              Als ungelesen markieren
            </el-dropdown-item>
            <el-dropdown-item
              v-if="data.active && (data.isOwner || isPrivilegedUser)"
              :command="{ action: 'active', data: false }"
            >
              <el-badge type="danger" style="padding-top: 9px; padding-left: 6px; padding-right: 4px" is-dot />
              {{ $t("Deactivate") }}
            </el-dropdown-item>
            <el-dropdown-item v-else-if="data.isOwner || isPrivilegedUser" :command="{ action: 'active', data: true }">
              <el-badge type="success" style="padding-top: 9px; padding-left: 6px; padding-right: 4px" is-dot />{{
                $t("Activate")
              }}</el-dropdown-item
            >
            <el-dropdown-item :command="{ action: 'export_pdf' }" v-if="isPrivilegedUser">
              <el-tooltip
                content="Anzeigbare Dateiformate: png, jpg, jpeg,
                tiff, bmp, gif, msg."
                placement="left"
                ><span> <download-icon class="mr-1" />{{ $t("Export as PDF") }} </span>
              </el-tooltip>
            </el-dropdown-item>
            <!-- <el-dropdown-item :command="{ action: 'edit' }">
              <pencil-icon />{{ $t("src.components.project.invoices.invoices.bearbeiten") }}</el-dropdown-item
            > -->
            <el-dropdown-item v-if="data.isOwner || isPrivilegedUser" :command="{ action: 'delete' }">
              <trash-can-outline-icon />{{ $t("src.components.project.invoices.invoices.lschen") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="note-date" v-if="isWorkshop && data.projectName">{{ `${$t("Project")}: ${data.projectName}` }}</div>
    <div class="note-date" v-if="!isWorkshop && data.workshopName">{{ `${$t("Workshop")}: ${data.workshopName}` }}</div>
    <div class="note-title" :title="data.title">{{ data.title }}</div>
    <div class="note-text" :title="data.snippet">{{ data.snippet }}</div>
    <div class="note-attachments" v-if="data.pictures.length || data.childNotes.length">
      <div class="note-attachment" v-if="data.pictures.length">
        <attachment-icon class="mr-1" /> {{ data.pictures.length }}
      </div>
      <div class="note-attachment" v-if="data.childNotes.length">
        <file-document-outline-icon class="mr-1" /> {{ data.childNotes.length }}
      </div>
    </div>
  </div>
</template>

<script>
import Attachment from "vue-material-design-icons/Attachment";
import FileDocumentOutline from "vue-material-design-icons/FileDocumentOutline.vue";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import { moment } from "src/config/moment";
import LazyImage from "../../UIComponents/Image/LazyImage.vue";
import { Dropdown, DropdownMenu, DropdownItem, Tooltip } from "element-ui";
import DownloadIcon from "vue-material-design-icons/Download.vue";
import NewBadge from "../TodoList/NewBadge.vue";

export default {
  name: "notes-item",
  components: {
    NewBadge,
    LazyImage,
    [Attachment.name]: Attachment,
    [FileDocumentOutline.name]: FileDocumentOutline,
    [DotsVertical.name]: DotsVertical,
    [DownloadIcon.name]: DownloadIcon,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },
  props: {
    data: { type: Object },
    isWorkshop: { type: Boolean },
    isFocused: { type: Boolean },
    isPrivilegedUser: { type: Boolean },
  },
  data() {
    return {};
  },
  methods: {
    handleItemCommand(payload) {
      switch (payload.action) {
        case "active":
          this.$emit("setActive", { id: this.data._id, active: payload.data });
          break;
        case "delete":
          this.$emit("delete", this.data._id);
          break;
        case "export_pdf":
          this.$emit("exportPdf", this.data._id);
          break;
        case "unread":
          this.$emit("unread", this.data._id);
          break;
        default:
          break;
      }
    },
    handleSelectNote() {
      this.$emit("select", this.data);
    },
  },
  filters: {
    formatDate(dateStr) {
      return moment(dateStr).format("lll");
    },
  },
  computed: {
    getAuthorName() {
      let authorName = this.data.authorName;
      if (this.data.isOwner) {
        authorName += ` (Sie)`;
      }
      return authorName;
    },
  },
};
</script>

<style></style>
