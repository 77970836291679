<template>
  <div>
    <div class="n-profile-section-font n-profile-section">
      {{ $t("src.components.supply.bestsuppliers.kennzahlenBesterAnbieter") }}
    </div>
    <el-row class="n-profile-spacer" :gutter="20">
      <el-col :span="6">
        <div class="statistics-label">{{ $t("src.components.supply.bestsuppliers.kleinsteVerpackungseinheit") }}</div>
        <div class="statistics-unit">{{ smallestPackagingUnit.value || "N/A" }}</div>
        <div class="statistics">
          <div class="statistics-name" v-for="(name, idx) in smallestPackagingUnit.suppliers" :key="idx">
            {{ name }}
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="statistics-label">{{ $t("src.components.supply.bestsuppliers.hchsterRabatt") }}</div>
        <div class="statistics-unit">{{ highestDiscount.value || "N/A" }}</div>
        <div class="statistics-name" v-for="(name, idx) in highestDiscount.suppliers" :key="idx">{{ name }}</div>
      </el-col>
      <el-col :span="6">
        <div class="statistics-label">
          {{ $t("src.components.supply.bestsuppliers.niedrigsterEinkaufspreisProStck") }}
        </div>
        <div class="statistics-unit">{{ lowestPurchasePriceAcc.value || "N/A" }}</div>
        <div class="statistics-name" v-for="(name, idx) in lowestPurchasePriceAcc.suppliers" :key="idx">{{ name }}</div>
      </el-col>
      <el-col :span="6">
        <div class="statistics-label">
          {{ $t("src.components.supply.bestsuppliers.niedrigsterEinkaufspreisProEinheit") }}
        </div>
        <div class="statistics-unit">{{ lowestPurchasePriceMeas.value || "N/A" }}</div>
        <div class="statistics-name" v-for="(name, idx) in lowestPurchasePriceMeas.suppliers" :key="idx">
          {{ name }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { last, groupBy, get } from "lodash";

export default {
  name: "best-suppliers",
  components: {},
  props: {
    units: { type: Array, default: () => [] },
  },
  data() {
    return {
      // Map of supplier articles { [_id: string]: SupplierArticle }
      supplierArticleData: {},
      // Smallest packaging unit: Supplier name | XXX <unit>
      smallestPackagingUnit: { value: "", suppliers: [] },
      // Highest discount: Supplier name | %%,%%,
      highestDiscount: { value: "", suppliers: [] },
      // Lowest purchase price / accounting unit: Supplier name | €€€€€,€€
      lowestPurchasePriceAcc: { value: "", suppliers: [] },
      // Lowest purchase price / measurement unit: Supplier name | €€€€€,€€
      lowestPurchasePriceMeas: { value: "", suppliers: [] },
    };
  },
  mounted() {
    this.$root.$on("supplierArticlesFetched", (data) => {
      const articles = data
        // consider only active records
        .filter((item) => item.active)
        .map((item) => ({
          ...item,
          price: last(item.price), // pick last item in prices considering that the last element is always the newest
        }));
      this.supplierArticleData = articles.reduce((obj, article) => {
        obj[article._id] = article;
        return obj;
      }, {});
    });
  },
  beforeDestroy() {
    this.$root.$off("supplierArticlesFetched");
  },
  methods: {
    parseSuplierData() {
      // NOTE: initialData keys correspond to keys in data()
      const initialData = {
        smallestPackagingUnit: { articleId: [], value: null, bundleUnit: "" }, // array of articleId to show list all with the same result
        highestDiscount: { articleId: "", value: null },
        lowestPurchasePriceAcc: { articleId: "", value: null },
        lowestPurchasePriceMeas: { articleId: "", value: null },
      };
      const result = Object.values(this.supplierArticleData).reduce((data, article, idx) => {
        // Smallest packaging unit

        if (
          (!data.smallestPackagingUnit.value && article.bundleAmount) || // for initial value
          article.bundleAmount < data.smallestPackagingUnit.value // if current is smaller than previous
        ) {
          data.smallestPackagingUnit = {
            value: article.bundleAmount,
            articleId: [article._id],
            bundleUnit: article.bundleUnit,
          };
        } else if (
          article.bundleAmount === data.smallestPackagingUnit.value &&
          data.smallestPackagingUnit.bundleUnit === article.bundleUnit
        ) {
          data.smallestPackagingUnit.articleId.push(article._id);
        }
        // Highest discount
        const discount = get(article, "price.discount");
        if (
          (!data.highestDiscount.value && typeof discount === "number") || // for initial value
          discount > data.highestDiscount.value // compare if current is higher than previous
        ) {
          data.highestDiscount = { value: discount, articleId: article._id };
        }
        // Lowest purchase price / accountingAmount
        const purchasePrice = get(article, "price.purchasingPrice");
        if (
          (!data.lowestPurchasePriceAcc.value && typeof purchasePrice === "number") || // for initial value
          purchasePrice < data.lowestPurchasePriceAcc.value // compare if current is smaller than previous
        ) {
          data.lowestPurchasePriceAcc = { value: purchasePrice, articleId: article._id };
        }
        // Lowest purchase price / measurementAmount
        const purchasePriceMeas =
          purchasePrice && article.measurementAmount ? purchasePrice / article.measurementAmount : undefined;
        if (
          (!data.lowestPurchasePriceMeas.value && typeof purchasePriceMeas === "number") || // for initial value
          purchasePriceMeas < data.lowestPurchasePriceMeas.value // compare if current is smaller than previous
        ) {
          data.lowestPurchasePriceMeas = { value: purchasePriceMeas, articleId: article._id };
        }
        return data;
      }, initialData);

      // Smallest packaging unit: Supplier name | XXX <unit>
      if (result.smallestPackagingUnit.value) {
        const supplierArticleDatas = result.smallestPackagingUnit.articleId.map((id) => this.supplierArticleData[id]);
        const supplierNames = supplierArticleDatas.map((data) => get(data, "supplier.name")).filter(Boolean);
        console.log("supplierNames", supplierNames);
        const bundleUnit = result.smallestPackagingUnit.bundleUnit;
        const bundleUnitName = get(this.units.find((unit) => unit._id === bundleUnit) || {}, "label", "?");
        this.smallestPackagingUnit = {
          value: `${result.smallestPackagingUnit.value} ${bundleUnitName}`,
          suppliers: supplierNames,
        };
      } else {
        this.smallestPackagingUnit = { value: "", suppliers: [] };
      }
      // Highest discount: Supplier name | %%,%%,
      if (result.highestDiscount.value) {
        const supplierArticleData = this.supplierArticleData[result.highestDiscount.articleId];
        const supplierName = get(supplierArticleData, "supplier.name");
        const prettyDiscount = Number(result.highestDiscount.value).toLocaleString("de-DE", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        this.highestDiscount = { value: `${prettyDiscount}%`, suppliers: [supplierName] };
      } else {
        this.highestDiscount = { value: "", suppliers: [] };
      }
      // Lowest purchase price / accounting unit: Supplier name | €€€€€,€€
      if (result.lowestPurchasePriceAcc.value) {
        const supplierArticleData = this.supplierArticleData[result.lowestPurchasePriceAcc.articleId];
        const supplierName = get(supplierArticleData, "supplier.name");
        const accountingUnit = supplierArticleData.accountingUnit;
        const accountingUnitName = get(this.units.find((unit) => unit._id === accountingUnit) || {}, "label", "?");
        const prettyPrice = Number(result.lowestPurchasePriceAcc.value).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
        });
        this.lowestPurchasePriceAcc = { value: `${prettyPrice}€ / ${accountingUnitName}`, suppliers: [supplierName] };
      } else {
        this.lowestPurchasePriceAcc = { value: "", suppliers: [] };
      }
      // Lowest purchase price / measurement unit: Supplier name | €€€€€,€€
      // Lowest purchase price / accounting unit: Supplier name | €€€€€,€€
      if (result.lowestPurchasePriceMeas.value) {
        const supplierArticleData = this.supplierArticleData[result.lowestPurchasePriceMeas.articleId];
        const supplierName = get(supplierArticleData, "supplier.name");
        const measurementUnit = supplierArticleData.measurementUnit;
        const measurementUnitName = get(this.units.find((unit) => unit._id === measurementUnit) || {}, "label", "?");
        const prettyPrice = Number(result.lowestPurchasePriceMeas.value).toLocaleString("de-DE", {
          minimumFractionDigits: 2,
        });
        this.lowestPurchasePriceMeas = {
          value: `${prettyPrice}€ / ${measurementUnitName}`,
          suppliers: [supplierName],
        };
      } else {
        this.lowestPurchasePriceMeas = { value: "", suppliers: [] };
      }
    },
  },
  watch: {
    supplierArticleData() {
      this.parseSuplierData();
    },
    units() {
      this.parseSuplierData();
    },
  },
};
</script>

<style>
.statistics-label {
  font-size: 12px;
  color: #727272;
  margin-bottom: 8px;
}
.statistics-unit {
  padding: 4px 8px;
  font-size: 12px;
  background-color: #e6ffdd;
  display: inline-block;
  margin-bottom: 8px;
}
.statistics-name {
  font-size: 14px;
  white-space: pre-line;
  margin-bottom: 8px;
}
.statistics .statistics-name:not(:first-child) {
  padding-top: 6px;
  border-top: 1px solid #ebebeb;
}
</style>
