import axios from "axios";
import VueAxios from "vue-axios";
import { store } from "src/store/index";
import { updateToken } from "./updateToken";
let HOST;
let PROTOCOL;
let ORIGIN;
if (process.env.NODE_ENV === "development") {
  PROTOCOL = "http";
  HOST = "localhost:3100";
  ORIGIN = `${PROTOCOL}://${HOST}`;
} else if (process.env.NODE_ENV === "production") {
  ORIGIN = localStorage.getItem("api");
}
ORIGIN = localStorage.getItem("api");

export { ORIGIN };
let LOGOUT_CALLED = false;
export default {
  install(Vue) {
    axios.interceptors.request.use(async (config) => {
      const token = await updateToken();
        if (!config.headers) {
          config.headers = {};
        }
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    });

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log("error in response interceptor", error);
        if (!error) {
          console.log("No error in axios interceptor, just return...");
          return;
        }
        if (error.response && error.response.status === 401) {
          if (!LOGOUT_CALLED) {
            store.commit("account/logout");
            LOGOUT_CALLED = true;
            Vue.$keycloak.logout();
            throw error;
          }
        }
        if (error.response && error.response.status === 413) {
          console.log("intercepted 413 error");
          Vue.$message.error(
            "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
          );
        }

        return Promise.reject(error);
      }
    );

    axios.defaults.baseURL = ORIGIN;
    axios.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";

    Vue.use(VueAxios, axios);

    window._axios = axios;
  },
};
