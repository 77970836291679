<template>
  <el-drawer
    :withHeader="false"
    :visible.sync="visible"
    direction="rtl"
    :wrapperClosable="false"
    :destroy-on-close="true"
    :before-close="handleClose"
    @closed="discard"
    size="630px"
    ref="drawer"
  >
    <ValidationObserver ref="validateObserver">
      <dropzone :onDrop="handleDropVcard">
        <perfect-scrollbar style="height: calc(100vh - 50px)" :options="{ suppressScrollX: true }">
          <div class="container" style="padding: 0 30px" v-loading="loading">
            <div class="drawer-back" @click="closeDrawer">
              <chevron-left-icon />{{ $t("src.components.contacts.contacts") }}
            </div>
            <div class="drawer-info">
              <h5 class="drawer-name">{{ contactTitle }}</h5>
              <edit-profile-button
                v-if="$can('update', 'management') && !localEditMode"
                @click="localEditMode = true"
                >{{ $t("src.components.contacts.contactdetails.bearbeiten") }}</edit-profile-button
              >
            </div>
            <el-form label-position="top" :model="localContact" ref="form" class="drawer-form" :rules="formDataRules">
              <div class="drawer-subtitle">{{ $t("src.components.contacts.contactdetails.allgemein") }}</div>
              <div style="display: flex; flex-wrap: nowrap">
                <el-form-item prop="active" style="margin-right: 10px">
                  <profile-switch
                    :title="$t('src.components.suppliers.supplierdetails.aktiv')"
                    :disabled="!localEditMode"
                    v-model="localContact.active"
                    :active-text="$t('src.components.suppliers.supplierdetails.ja')"
                    :inactive-text="$t('src.components.suppliers.supplierdetails.nein')"
                    colorizeActive
                  />
                </el-form-item>
                <div style="flex-shrink: 0; margin-right: 10px; margin-top: 20px">
                  <avatar-uploader
                    :isEditMode="localEditMode"
                    :actionURI="avatarActionURI"
                    :avatar="localContact.picture"
                    v-on:avatar-changed="setAvatar"
                    @change="handleChange"
                    @remove="handleRemove"
                    :autoUpload="false"
                    ref="avatarUploader"
                    :name="avatarName"
                    :small="true"
                  />
                </div>
                <div style="flex-grow: 1">
                  <el-row :gutter="10" v-if="contactType === 'client'">
                    <el-col :span="24">
                      <el-form-item prop="name" style="margin-right: 10px">
                        <profile-input
                          v-model="localContact.name"
                          rules="required"
                          :required="true"
                          :label="$t('company')"
                          :name="$t('company')"
                          :editMode="localEditMode"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item prop="nameAddition1" style="margin-right: 10px">
                        <profile-input
                          v-model="localContact.nameAddition1"
                          rules="required"
                          :required="true"
                          :label="$t('companyAddition1')"
                          :name="$t('companyAddition1')"
                          :editMode="localEditMode"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item prop="nameAddition2" style="margin-right: 10px">
                        <profile-input
                          v-model="localContact.nameAddition2"
                          rules="required"
                          :required="true"
                          :label="$t('companyAddition2')"
                          :name="$t('companyAddition2')"
                          :editMode="localEditMode"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item prop="nameSearch" style="margin-right: 10px">
                        <profile-input
                          v-model="localContact.nameSearch"
                          rules="required"
                          :required="true"
                          :label="$t('companySearch')"
                          :name="$t('companySearch')"
                          :editMode="localEditMode"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" v-else>
                    <el-col :span="12">
                      <el-form-item prop="name" style="margin-right: 10px">
                        <profile-input
                          v-model="localContact.name"
                          rules="required"
                          :required="true"
                          label="Vorname"
                          name="Vorname"
                          :editMode="localEditMode"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item prop="lastName">
                        <profile-input
                          v-model="localContact.lastName"
                          rules="required"
                          :required="true"
                          label="Nachname"
                          name="Nachname"
                          :editMode="localEditMode"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <!-- <el-col :span="12"> </el-col> -->
                <el-col :span="12" v-show="contactType !== 'client'">
                  <el-form-item prop="company">
                    <profile-input
                      v-model="localContact.company"
                      :label="$t('src.components.contacts.company')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-show="contactType !== 'client'">
                  <el-form-item prop="position">
                    <profile-input v-model="localContact.position" :label="$t('Position')" :editMode="localEditMode" />
                  </el-form-item>
                </el-col>

                <el-col :span="12" v-show="contactType !== 'client'">
                  <el-form-item prop="mobile">
                    <profile-input
                      v-model="localContact.mobile"
                      :label="$t('src.components.contacts.contactdetails.handy')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item prop="email">
                    <profile-input
                      v-model="localContact.email"
                      :name="$t('src.components.contacts.contactdetails.eMail')"
                      type="email"
                      rules="email"
                      :label="$t('src.components.contacts.contactdetails.eMail')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12" v-show="contactType === 'client'">
                  <el-form-item prop="invoiceEmail">
                    <profile-input
                      v-model="localContact.invoiceEmail"
                      :name="$t('invoiceEmail')"
                      type="email"
                      rules="email"
                      :label="$t('invoiceEmail')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="phone">
                    <profile-input
                      v-model="localContact.phone"
                      :label="$t('src.components.contacts.contactdetails.telefon')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="fax">
                    <profile-input
                      v-model="localContact.fax"
                      :label="$t('src.components.contacts.contactdetails.fax')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <hr />

              <div class="drawer-subtitle">{{ $t("src.components.contacts.contactdetails.adressdaten") }}</div>
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <el-col :span="12">
                  <el-form-item prop="street">
                    <profile-input
                      v-model="localContact.street"
                      :label="$t('src.components.contacts.contactdetails.strae')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="no">
                    <profile-input
                      v-model="localContact.no"
                      :label="$t('src.components.contacts.contactdetails.hausnummer')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="zip">
                    <profile-input
                      v-model="localContact.zip"
                      :label="$t('src.components.contacts.contactdetails.plz')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="city">
                    <profile-input
                      v-model="localContact.city"
                      :label="$t('src.components.contacts.contactdetails.stadt')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="addressAddition">
                    <profile-input
                      v-model="localContact.addressAddition"
                      :label="$t('src.components.contacts.contactdetails.adresszusatz')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </perfect-scrollbar>
        <div class="drawer-footer" v-if="localEditMode">
          <el-button @click="closeDrawer">{{ $t("src.components.contacts.contactdetails.abbrechen") }}</el-button>
          <el-button type="primary" @click.prevent="submit">{{
            $t("src.components.contacts.contactdetails.speichern")
          }}</el-button>
        </div>
      </dropzone>
    </ValidationObserver>
  </el-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { isEmpty, get } from "lodash";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Drawer, Input, Button, Form, FormItem, MessageBox, Message } from "element-ui";
import Pencil from "vue-material-design-icons/Pencil";
import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import ProfileInput from "../UIComponents/Inputs/ProfileInput.vue";
import EditProfileButton from "../UIComponents/Buttons/EditProfileButton.vue";
import { ValidationObserver } from "vee-validate";
import { Avatar } from "src/components/UIComponents";
import Dropzone from "./Dropzone.vue";

const INITIAL_CONTACT = () => Object.create({}, {});

export default {
  name: "contact-details",
  props: {
    contactData: { type: Object },
    deleteContact: { type: Function },
    isEditMode: { type: Boolean },
    isNewContact: { type: Boolean },
    contactType: { type: String },
  },
  components: {
    ValidationObserver,
    PerfectScrollbar,
    ProfileInput,
    Dropzone,
    EditProfileButton,
    [Avatar.name]: Avatar,
    [Pencil.name]: Pencil,
    [ChevronLeft.name]: ChevronLeft,
    [Drawer.name]: Drawer,
    [Input.name]: Input,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Message.name]: Message,
    [MessageBox.name]: MessageBox,
  },
  data() {
    return {
      avatarActionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      formDataRules: {
        resourceTypes: { message: "Bitte auswählen", required: true },
      },
      localEditMode: false,
      visible: false,
      localContact: INITIAL_CONTACT(),
      loading: false,
    };
  },
  methods: {
    ...mapActions("dirtyFlag", {
      setPreservedData: "setPreservedData",
      checkDirtyFlag: "checkDirty",
      discardDirty: "discard",
    }),
    async handleDropVcard(files) {
      try {
        if (files.length !== 1 || (files[0].type !== "text/vcard" && !files[0].name.endsWith(".msg"))) {
          return false;
        }
        this.loading = true;
        const formBody = new FormData();
        formBody.append("file", files[0]);
        const response = await this.axios.post("/api/contacts/parse-vcf", formBody);

        if (response.data) {
          this.$emit("setFromData", response.data);
          return true;
        }
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    setAvatar(avatarSettings) {
      this.localContact.picture = avatarSettings;
      // when picture is uploaded for new contact - send PUT to update data
      if (this.isNewContact) {
        this.isNewContact = false;
        this.$nextTick(this.submit);
      }
    },
    handleChange(file, fileList) {
      if (!this.isNewContact) {
        this.$refs.avatarUploader.submitUpload();
      }
    },
    handleRemove() {},
    closeDrawer() {
      this.$refs.drawer.closeDrawer();
    },
    handleClose(done) {
      if (this.isDirty) {
        MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonClass: "el-button--success",
        })
          .then((action) => {
            this.submit();
            done();
          })
          .catch((action) => {
            if (action === "close") {
              return;
            } else {
              done();
            }
          });

        // MessageBox.confirm("Sie haben noch nicht gespeicherte Daten, fahren Sie fort?", {
        //   type: "warning",
        // })
        //   .then((_) => {
        //     done();
        //     return _;
        //   })
        //   .catch((_) => {});
      } else {
        Object.assign(this, {
          localEditMode: false,
          visible: false,
          localContact: INITIAL_CONTACT(),
        });
      }
    },
    discard() {
      Object.assign(this, {
        localEditMode: false,
        visible: false,
        localContact: INITIAL_CONTACT(),
      });
      this.$emit("closed");
    },
    async submit(saveAnyway) {
      const isValid = await this.$refs.validateObserver.validate();
      if (!isValid) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        return;
      }

      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        try {
          this.loading = true;
          const formData = {
            ...this.localContact,
            type: this.contactType,
          };
          let response;
          if (this.isNewContact) {
            response = await this.axios.post(`/api/contacts`, formData, {
              params: { saveAnyway: saveAnyway === true },
            });
            this.$refs.avatarUploader.submitUpload();
            this.localContact = { ...response.data };
          } else {
            response = await this.axios.put(`/api/contacts/${formData._id}`, formData, {
              params: { saveAnyway: saveAnyway === true },
            });
          }
          Message.success("Kontakt gespeichert");
          this.$emit("onContactSave", response.data);
          this.discard();
        } catch (error) {
          const errorMessage = get(error, "response.data.message");
          const errorStatus = get(error, "response.status");
          if (errorStatus === 400 && errorMessage === "CONTACT_EXISTS") {
            const message = `Ein Kontakt mit ${[
              this.localContact.name || this.localContactData.firstName,
              this.localContact.lastName,
            ].join(" ")}${
              this.localContact.company ? ", " + this.localContact.company : ""
            } besteht schon. Trotzdem Speichern?`;
            MessageBox.confirm(message, {
              confirmButtonText: "Ja",
              cancelButtonText: "Nein",
              type: "warning",
              confirmButtonClass: "el-button--success",
            })
              .then(() => {
                this.submit(true);
              })
              .catch(() => {});
          }
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    contactTitle() {
      if (!isEmpty(this.contactData)) {
        return this.contactData.name || "N/A";
      } else {
        return "Neuer Kontakt";
      }
    },
    avatarName() {
      if (this.contactData && this.contactData._id) {
        return "contact_" + this.contactData._id + "_picture_0";
      }
      return "";
    },
  },
  watch: {
    contactData(newVal, oldVal) {
      if (newVal) {
        this.visible = true;
        if (isEmpty(newVal)) {
          this.localContact = INITIAL_CONTACT();
        } else {
          this.localContact = { ...newVal };
        }
        setTimeout(() => {
          this.setPreservedData(this.localContact);
          this.checkDirtyFlag(this.localContact);
        }, 200);
      } else {
        this.visible = false;
        this.localContact = INITIAL_CONTACT();
      }
    },
    localContact: {
      handler: function (newVal, oldVal) {
        this.checkDirtyFlag(newVal);
      },
      deep: true,
    },
    isEditMode(newVal) {
      this.localEditMode = newVal;
    },
  },
};
</script>

<style></style>
