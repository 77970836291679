<template>
  <widget-tab
    :title="$t('src.components.dashboard.views.dashboard.widgets.birthdayswidget.geburtstage')"
    :hasFilters="true"
  >
    <template #filters>
      <div>
        <profile-input-number
          :label="$t('src.components.dashboard.views.dashboard.widgets.documentsstatewidget.nchstenWochen')"
          v-model="nextWeeks"
          :min="1"
          :max="52"
        ></profile-input-number>
      </div>
    </template>
    <div style="padding: 20px 20px 0" v-loading="loading">
      <el-row :gutter="20">
        <el-col :lg="6" :md="8" :key="idx" v-for="(birthday, idx) in birthdays">
          <div class="birthday-item">
            <div class="birthday-top">
              <div class="birthday-name" :title="birthday.name">
                <div v-if="birthday.isCelebrating" class="birthday-icon-wrap">
                  <img src="/img/icons/party-popper.svg" class="birthday-icon" />
                </div>
                {{ birthday.name }}
              </div>
              <el-button v-if="birthday.isCelebrating" @click="hideField(birthday._id)">{{
                $t("src.components.dashboard.views.dashboard.widgets.birthdayswidget.quittert")
              }}</el-button>
            </div>
            <div class="birthday-bot">
              {{ birthday.birthDate | formatDate }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </widget-tab>
</template>

<script>
import { Table, TableColumn, Slider, Message } from "element-ui";
import { moment } from "src/config/moment";
import { debounce } from "lodash";
import WidgetTab from "./Widgets/WidgetTab.vue";
import ProfileInputNumber from "src/components/UIComponents/Inputs/ProfileInputNumber";

import { mapActions } from "vuex";

export default {
  name: "birthdays-widget",
  components: {
    WidgetTab,
    ProfileInputNumber,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Slider.name]: Slider,
    [Message.name]: Message,
  },
  data() {
    return {
      birthdays: [],
      nextWeeks: 4,
      loading: false,
    };
  },
  created() {
    this.fetchDebounce = debounce(this.fetchBirthdays, 400);
  },
  mounted() {
    this.fetchBirthdays();
  },
  beforeUnmount() {    
    // Reset the specific widget data this component was using
    this.resetWidget('birthdays');    
  },
  methods: {
    ...mapActions("widget", ["widgetLoaded","resetWidget"]),
    async fetchBirthdays() {
      try {
        this.loading = true;
        const birthdaysUntil = moment().add(this.nextWeeks, "weeks");
        const response = await this.axios.post("/api/employees/birthdays", {
          until: birthdaysUntil,
        });

        const today = moment().startOf("day");
        this.birthdays = response.data.map((item) => {
          const diff = today.diff(moment(item.birthDate.split("T")[0]).startOf("day"), "days");
          const isCelebrating = diff < 8 && diff >= 0;
          return {
            ...item,
            isCelebrating,
          };
        });
        const diff = moment("2024-04-17T07:06:24.360Z").diff("2024-04-18T00:00:00.000Z".split("T")[0], "days");
        console.log(`diff: ${diff}, ${diff < 8 && diff >= 0}`);
        this.notifyTopWidget(response.data);
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    notifyTopWidget(data) {
      const record = {
        labelTemplate: `in den nächsten {variable} wochen`,
        color: "#594cee",
        variable: this.nextWeeks,
        number: data.length,
      };
      //using vuex instead of event bus now
      this.widgetLoaded({ type: "birthdays", payload: [record] });
    },
    async hideField(employeeId) {
      await this.axios.post("/api/employees/birthdays/hide", null, { params: { employeeId } });
      this.fetchBirthdays();
    },
    birthdayDate(row, col, cellValue) {
      return moment(cellValue).format("L");
    },
    yearsOld(row, col, cellValue) {
      const currentYear = moment().year();
      const birthdayYear = moment(new Date(cellValue)).year();
      return currentYear - birthdayYear;
    },
    rowClassName({ row }) {
      if (row.isCelebrating) {
        return "yellow-row";
      }
    },
  },
  watch: {
    nextWeeks() {
      this.fetchDebounce();
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("L");
      }
      return "-";
    },
  },
};
</script>

<style>
.birthday-icon {
  width: 30px;
  height: 30px;
  position: relative;
}
.birthday-icon-wrap {
  padding: 5px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin-right: 8px;
  display: inline-block;
}
.el-table tr.yellow-row {
  background-color: #faf27e;
}

.birthday-item {
  padding: 15px;
  border: 1px solid #edf0f7;
  background-color: #fff;
  margin-bottom: 20px;
}
.birthday-top {
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.birthday-name {
  overflow: hidden;
  font-size: 14px;
  color: #455070;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.birthday-bot {
  margin-top: 12px;
  font-size: 12px;
}
</style>
