<template>
  <div style="padding: 15px">
    <p class="table-title">{{ title }}</p>
    <el-upload
      class="drawer-upload mb-3"
      drag
      multiple
      :action="actionURI"
      :data="{ metadata: getMetadata() }"
      :headers="authHeader"
      :on-progress="handleProgress"
      :class="disabled && 'upload-disabled'"
      :before-upload="handleBeforeUpload"
      :show-file-list="false"
      :on-success="fileUploaded"
      :on-error="handleUploadError"
      :disabled="!editMode"
      v-if="editMode"
      accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
    >
      <div class="upload-icon"><file-image-icon /></div>
      <div class="el-upload__text" v-show="!disabled">
        <b>{{ $t("src.components.project.pickpack.pickpackimagedrop.whlenSieEineDatei") }}</b
        >&nbsp;
        <em style="color: #46a19c">{{
          $t("src.components.project.pickpack.pickpackimagedrop.vonIhremComputerAus")
        }}</em>
      </div>
    </el-upload>
    <draggable
      tag="ul"
      :group="{ name: pictureType, put: false, pull: false }"
      :list="localPictures"
      class="dnd-list"
      handle=".handle"
      @end="onEnd"
      v-bind="dragOptions"
    >
      <transition-group type="transition" name="flip-list">
        <li v-for="file in localPictures" :key="file._id || file.uid" class="mb-1">
          <div class="upload-file-item">
            <span>
              <div class="handle" v-if="editMode"><drag-icon /></div>
              <div class="upload-file-item__icon"><attachment-icon /></div>
              <div class="upload-file-item__name">
                {{ file.name }}
              </div>
              <div v-if="file.title" class="upload-file-item__name">({{ file.title }})</div>
              <span> <i v-if="progressStore[file.uid]" class="el-icon-loading ml-2"></i></span>
            </span>
            <span>
              <el-dropdown trigger="click" @command="handleCommand(file, $event)">
                <el-button type="text" style="font-size: 20px; line-height: 14px; padding: 0">
                  <dots-vertical-icon />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="download"
                    ><download-icon class="mr-2" />{{
                      $t("src.components.project.pickpack.pickpackimagedrop.herunterladen")
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item command="edit" v-if="editMode"
                    ><pencil-icon class="mr-2" />{{
                      $t("src.components.project.pickpack.pickpackimagedrop.titelBearbeiten")
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item command="view"
                    ><eye-outline-icon class="mr-2" />{{
                      $t("src.components.project.pickpack.pickpackimagedrop.ansehen")
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item command="remove" v-if="editMode"
                    ><trash-can-outline-icon class="mr-2" />{{
                      $t("src.components.project.pickpack.pickpackimagedrop.lschen")
                    }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </li>
      </transition-group>
    </draggable>

    <el-dialog :visible.sync="dialogVisible" center>
      <div style="text-align: center">
        <lazy-image :src="dialogImage" key="cover">
          <template v-slot:placeholder>
            <div class="image-slot">
              <span style="font-size: 20px"
                >{{ $t("src.components.project.pickpack.pickpackimagedrop.lade")
                }}<span class="dot">{{ $t("src.components.project.pickpack.pickpackimagedrop.418") }}</span>
              </span>
            </div>
          </template>
          <template v-slot:error>
            <div class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </template>
        </lazy-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Upload, MessageBox, Dialog, Image, Dropdown, DropdownItem, Message } from "element-ui";
import { mapGetters } from "vuex";
import { debounce, isEmpty } from "lodash";
import Pencil from "vue-material-design-icons/Pencil";
import EyeIcon from "vue-material-design-icons/EyeOutline";
import DownloadIcon from "vue-material-design-icons/Download";
import FileImageIcon from "vue-material-design-icons/FileImage";
import DragIcon from "vue-material-design-icons/Drag";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import AttachmentIcon from "vue-material-design-icons/Attachment";
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical";
import Draggable from "vuedraggable";
import { LazyImage } from "src/components/UIComponents";
import { resizeImage } from "src/utils/resizeImage";
import { dataURLtoFile } from "src/utils/dataURLtoFile";

export default {
  name: "pick-pack-image-drop",
  components: {
    LazyImage,
    MessageBox,
    [EyeIcon.name]: EyeIcon,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Image.name]: Image,
    [Draggable.name]: Draggable,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    AttachmentIcon,
    DragIcon,
    Pencil,
    DownloadIcon,
    FileImageIcon,
    DotsVerticalIcon,
    TrashCanOutline,
  },
  props: {
    editMode: Boolean,
    projectId: { type: String, required: true },
    pictureType: { type: String, required: true },
    title: { type: String, required: true },
    pictures: { type: Array, default: () => [] },
  },
  data() {
    return {
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      progressStore: {},
      errorStore: {},
      localPictures: [],
      dialogVisible: false,
      dialogImage: "",
    };
  },
  created() {
    this.debounceSetPictures = debounce(this.setPictures, 200);
  },
  mounted() {
    this.setPictures(this.pictures);
  },
  methods: {
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    handleCommand(file, command) {
      switch (command) {
        case "edit":
          this.editFileTitle(file);
          break;
        case "view":
          this.previewFile(file);
          break;
        case "download":
          this.downloadFile(file);
          break;
        case "remove":
          this.removeFile(file);
          break;
        default:
          break;
      }
    },
    async beforeRemoveFile(file) {
      return await MessageBox.confirm(file.name + " löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).catch((_) => {
        throw "ABORTED";
      });
    },
    async removeFile(file) {
      try {
        if (this.errorStore[file.uid]) {
          this.errorStore[file.uid] = undefined;
        } else {
          await this.beforeRemoveFile(file);
          await this.removeFileRequest(file);
        }
      } catch (error) {
        console.log("error", error);
        if (error !== "ABORTED") {
          throw error;
        }
      }
    },
    async removeFileRequest(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        await this.axios.delete(`/api/projects/${this.projectId}/pictures/${this.pictureType}/${file._id}`, {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.localPictures = this.localPictures.filter((item) => item._id !== file._id);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    downloadFile(file) {
      if (file.response && file.response[0]) {
        window.open(file.response[0].url, "_blank");
      } else if (file.url) {
        window.open(file.url, "_blank");
      } else {
        console.warn("Could not parse file. File object below.");
        console.log(JSON.stringify(file, null, 2));
      }
    },
    editFileTitle(file) {
      MessageBox.prompt("Bitte geben Sie den Titel ein", "Bildtitel", {
        confirmButtonText: "Speichern",
        cancelButtonText: "Abbrechen",
        inputValue: file.title,
      })
        .then(({ value }) => {
          this.editFile({ ...file, title: value });
        })
        .catch((err) => err);
    },
    editFile(file) {
      const fileIdx = this.localPictures.findIndex((i) => i._id === file._id);
      if (fileIdx !== -1) {
        this.$set(this.localPictures, fileIdx, file);
        this.updatePicture(file);
      }
    },
    previewFile(file) {
      if (file.url) {
        this.dialogImage = this.axios.defaults.baseURL + file.url;
        this.dialogVisible = true;
      }
    },
    async fileUploaded(response, file, fileList) {
      const fileIdx = this.localPictures.findIndex((item) => item.uid === file.uid);
      if (fileIdx !== -1) {
        this.localPictures.splice(fileIdx, 1, response[0]);
      }
      delete this.progressStore[file.uid];
      await this.savePicture(response[0]);
    },
    async savePicture(fileModel) {
      try {
        await this.axios.post(`/api/projects/${this.projectId}/pictures/${this.pictureType}`, fileModel);
      } catch (error) {
        throw error;
      }
    },
    async updatePicture(fileModel) {
      try {
        await this.axios.put(
          `/api/projects/${this.projectId}/pictures/${this.pictureType}/${fileModel._id}`,
          fileModel
        );
      } catch (error) {
        throw error;
      }
    },
    async handleBeforeUpload(file) {
      try {
        const isGt2M = file.size / 1024 / 1024 > 2;
        console.log("isGt2M", isGt2M);
        if (isGt2M) {
          const objectUrl = URL.createObjectURL(file);
          const img = await resizeImage(objectUrl);
          const newFile = dataURLtoFile(img.url, file.name);
          return newFile;
        }
        return file;
      } catch (error) {
        console.log("error.message", error.message);
        throw error;
      }
    },
    handleProgress(evt, file, fileList) {
      if (!file) {
        return;
      }
      if (typeof this.progressStore[file.uid] !== "number") {
        this.localPictures.push({
          name: file.name,
          uid: file.uid,
        });
      }
      this.progressStore[file.uid] = evt.percent;
    },
    handleError(err, file) {
      this.errorStore[file.uid] = err.message;
    },
    getMetadata() {
      return `project_${this.projectId}_pictures_${this.pictureType}`;
    },
    setPictures(newVal) {
      this.localPictures = newVal;
    },
    onEnd(change) {
      const formData = this.localPictures.map(({ _id }, index) => ({ _id, index }));
      this.axios.put(`/api/projects/${this.projectId}/pictures/reorder/${this.pictureType}`, formData);
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    disabled() {
      return this.$attrs.disabled;
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  filters: {
    formatProgress(progress) {
      if (progress) {
        return `(...${Math.floor(progress)}%)`;
      } else {
        return "";
      }
    },
  },
  watch: {
    pictures(newVal) {
      if (newVal) {
        this.debounceSetPictures(newVal);
      }
    },
  },
};
</script>

<style>
.dnd-list {
  margin: 0;
  list-style: none;
  padding: 0;
}

.handle {
  margin-right: 5px;
  opacity: 0.5;
  display: inline-block;
  cursor: move;
  font-size: 20px;
}

.flip-list-move {
  transition: transform 0.3s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
