<template>
  <el-dialog
    :visible="isVisible"
    @close="() => setVisible(false)"
    title="Granularität für weitere Ressourcen kopieren"
    width="700px"
  >
    <div>Sie können die neue Einstellung der Granularität für folgende Ressourcen Typen übernehmen.</div>
    <div class="mb-4">Wählen Sie bitte die entsprechenden dafür Zeilen aus.</div>
    <div class="flex justify-content-center">
      <table class="n-table">
        <thead>
          <tr>
            <th>Übernehmen</th>
            <th>Ressource</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><profile-switch no-title v-model="selectAll" /></td>
            <td>Alle</td>
          </tr>
          <tr>
            <td><profile-switch no-title v-model="granularity.employee" /></td>
            <td>Mitarbeiter</td>
          </tr>
          <tr>
            <td><profile-switch no-title v-model="granularity.vehicle" /></td>
            <td>KFZ</td>
          </tr>
          <tr>
            <td><profile-switch no-title v-model="granularity.machine" /></td>
            <td>Maschinen</td>
          </tr>
          <tr>
            <td><profile-switch no-title v-model="granularity.rhb" /></td>
            <td>RHB</td>
          </tr>
          <tr>
            <td><profile-switch no-title v-model="granularity.supply" /></td>
            <td>Verbrauchsmaterial</td>
          </tr>
          <tr>
            <td><profile-switch no-title v-model="granularity.subcontractor" /></td>
            <td>Nachunternehmer</td>
          </tr>
        </tbody>
      </table>
    </div>

    <template v-slot:footer>
      <div class="dialog-footer">
        <el-button @click="setVisible(false)">{{
          $t("src.components.project.projectcontrolling.costs.abbrechen")
        }}</el-button>
        <el-button type="primary" @click.prevent="saveChanges">{{
          $t("src.components.project.projectcontrolling.costs.speichern")
        }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { Dialog, Switch } from "element-ui";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "granularity-settings",
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      granularity: {
        employee: false,
        machine: false,
        vehicle: false,
        rhb: false,
        supply: false,
        subcontractor: false,
      },
    };
  },
  methods: {
    ...mapActions("granularitySettings", ["setVisible", "updateSetting"]),
    saveChanges() {
      const payload = Object.entries(this.granularity).reduce((obj, [key, isConcurrent]) => {
        obj[key] = isConcurrent ? "CONCURRENT" : "STANDARD";
        return obj;
      }, {});
      this.updateSetting(payload);
      this.setVisible(false);
    },
  },
  computed: {
    ...mapGetters("granularitySettings", { isVisible: "visible", granularitySettings: "data" }),
    selectAll: {
      get() {
        return Object.values(this.granularity).every(Boolean);
      },
      set(newValue) {
        Object.keys(this.granularity).forEach((key) => {
          this.granularity[key] = !!newValue;
        });
      },
    },
  },
  watch: {
    isVisible(isTrue) {
      if (isTrue) {
        Object.entries(this.granularitySettings).forEach(([key, value]) => {
          this.granularity[key] = value === "CONCURRENT";
        });
      }
    },
  },
};
</script>
