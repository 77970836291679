class AuthImage extends HTMLElement {
  static get observedAttributes() {
    return ["src", "alt", "width", "height", "class", "loading"];
  }

  constructor() {
    super();
    this.img = document.createElement("img");
    this.appendChild(this.img);
  }

  connectedCallback() {
    this.updateImgAttributes();
  }

  updateImgAttributes() {
    for (const attr of this.attributes) {
      if (AuthImage.observedAttributes.includes(attr.name)) {
        // NOTE: in case update of attributes is required - compare values and apply changes if needed
        if (!this.img.getAttribute(attr.name)) {
          this.img.setAttribute(attr.name, attr.value);
        }
      }
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "src" && newValue) {
      const oUrl = imagesStore.get(newValue);
      if (oUrl) {
        this.img.src = oUrl;
      } else {
        this.loadImage(newValue);
      }
      const pictureUrl = this.dataset.picture;
      this.img.onmouseover = () => window.postMessage({ type: "SHOW_RESOURCE_PICTURE", payload: pictureUrl });
      this.img.onmouseleave = () => window.postMessage({ type: "HIDE_RESOURCE_PICTURE" });
    } else {
      this.removeAttribute(name);
      this.img.setAttribute(name, newValue);
    }
  }

  async loadImage(src) {
    try {
      const apiUrl = localStorage.getItem("api");
      const token = sessionStorage.getItem("token");
      const fetchUrl = apiUrl + src;
      const response = await fetch(fetchUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        imagesStore.add(src, url);
        this.img.src = url;
      } else {
        this.img.classList.add("placeholder");
        const placeholderUrl = "/img/icons/image-not-found.jpg";
        this.img.src = placeholderUrl;
        imagesStore.add(src, placeholderUrl);
      }
    } catch (error) {
      console.error("There has been a problem with your fetch operation:", error);
    }
  }
}

class ImagesStore {
  constructor() {
    this.map = new Map();
  }

  add(key, objectUrl) {
    this.map.set(key, objectUrl);
  }

  remove(key) {
    const oUrl = this.get(key);
    if (oUrl) {
      URL.revokeObjectURL(oUrl);
    }
    this.map.delete(key);
  }

  get(key) {
    return this.map.get(key);
  }

  reset() {
    this.map.forEach((v) => {
      URL.revokeObjectURL(v);
    });
    this.map.clear();
  }
}

export const imagesStore = new ImagesStore();

customElements.define("auth-img", AuthImage);
