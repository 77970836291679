<template>
  <div class="n-edit-wrapper">
    <!-- BREADCRUM TRAIL -->
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("src.components.administration.user.profile.administration")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/administration/users/index' }">{{
            $t("src.components.administration.user.profile.benutzerListe")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ profileName }} </el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title">{{ profileName }}</div>
      </el-col>
    </el-row>
    <div style="width: 100%">
      <div class="card card-user">
        <form>
          <div :class="{ 'card-body': true, deactivated: !user.enabled }">
            <h6 class="title">{{ $t("src.components.administration.user.profile.allgemein") }}</h6>
            <div class="form-group row">
              <div class="col-md-3">
                <profile-input
                  type="text"
                  :label="$t('src.components.administration.user.profile.vollstndigerName')"
                  :placeholder="$t('src.components.administration.user.profile.vollstndigerName')"
                  disabled
                  :value="fullName"
                ></profile-input>
              </div>
              <div class="col-md-3">
                <profile-input
                  type="text"
                  :label="$t('src.components.administration.user.profile.benutzername')"
                  :placeholder="$t('src.components.administration.user.profile.benutzername')"
                  disabled
                  :value="user.username"
                ></profile-input>
              </div>

              <div class="col-md-3">
                <label class="col-md-10" style="padding-left: 0; text-align: left">{{
                  $t("src.components.administration.user.profile.erforderlicheBenutzeraktionen")
                }}</label>
                <div style="display: inline-flex">
                  <el-tooltip :content="action.tooltip" v-for="action in availableActions" :key="action.value">
                    <el-button @click="handleAction(action.value)" :disabled="isNewProfile">
                      {{ action.label }}
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </div>

            <hr />
            <div v-if="!isNewProfile && user.id">
              <user-groups-manager :isEditMode="isEditMode" :userId="user.id" />

              <div class="text-center" v-if="isEditMode">
                <button type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="updateProfile()">
                  {{ $t("src.components.administration.user.profile.speichern") }}
                </button>
                <span v-if="$route.params.id !== 'new' && $can('update', 'administration')">
                  <button
                    v-if="user.enabled"
                    type="submit"
                    class="btn btn-default btn-fill btn-wd"
                    @click.prevent="cancelProfile()"
                  >
                    {{ $t("src.components.administration.user.profile.deaktivieren") }}
                  </button>
                  <button
                    v-else
                    type="submit"
                    class="btn btn-default btn-fill btn-wd"
                    @click.prevent="activateProfile()"
                  >
                    {{ $t("src.components.administration.user.profile.aktivieren") }}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Message, Button, Tooltip, MessageBox } from "element-ui";
import UserGroupManager from "./UserGroupsManager.vue";

export default {
  name: "user-profile",
  components: {
    Message,
    [Button.name]: Button,
    [Tooltip.name]: Tooltip,
    [UserGroupManager.name]: UserGroupManager,
  },
  data() {
    return {
      user: {
        enabled: true,
        firstName: "",
        lastName: "",
        username: "",
        requiredActions: [],
      },
      availableActions: [
        {
          label: "Update password",
          value: "UPDATE_PASSWORD",
          tooltip: "Der Benutzer muss ein neues Passwort eingeben",
          confirmText: "Soll der Benutzer wirklich ein neues Passwort eingeben?",
        },
        // {
        //   label: "Update profile",
        //   value: "UPDATE_PROFILE",
        //   tooltip: "Der Benutzer muss sein Benutzerprofil aktualisieren",
        //   confirmText: "Soll der Benutzer wirklich sein Benutzerprofil aktualisieren?"
        // },
        {
          label: "Verify email",
          value: "VERIFY_EMAIL",
          tooltip: "Der Benutzer muss seine E-Mail Adress verifzieren",
          confirmText: "Der Benutzer muss seine E-Mail Adress verifzieren",
        },
      ],
      loading: false,
    };
  },
  async mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const id = this.$route.params.id; //coming from routing parameter
        if (id !== "new") {
          const response = await this.axios.get("/api/users/" + id);
          this.user = response.data;
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async updateProfile() {
      try {
        const route = "/api/users";
        if (this.isNewProfile) {
          const response = await this.axios.post(route, this.user);
          this.user = response.data;
          this.$router.push({
            name: "User Edit",
            params: { id: response.data.id },
          });
        } else {
          const response = await this.axios.put(`${route}/${this.user.id}`, this.user);
          this.user = response.data;
        }
        Message({
          message: "Benuzter gespeichert",
          type: "success",
        });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    async cancelProfile() {
      const response = await this.axios.put(`/api/users/${this.user.id}/deactivate`);
      this.user = response.data;
      Message.success("Benutzer wurde deaktiviert");
    },
    activateProfile() {
      this.axios.put(`/api/users/${this.user.id}/activate`);
      Message.success("Benutzer wurde aktiviert");
    },
    handleAction(actionType) {
      const actionData = this.availableActions.find((item) => item.value === actionType);
      MessageBox.confirm(actionData.confirmText)
        .then(async () => {
          try {
            await this.axios.put(`/api/users/${this.user.id}/reset-credentials`, [actionType]);
            Message.success("Anfrage wurde gesendet");
          } catch (error) {
            Message.error(error.message);
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    fullName() {
      return `${this.user.lastName || ""} ${this.user.firstName || ""}`;
    },
    profileName() {
      return this.$route.params.id === "new" ? "Neuer Benutzer" : this.fullName;
    },
    isNewProfile() {
      return this.$route.params.id === "new";
    },
    isEditMode() {
      return this.$route.name === "User Edit";
    },
  },
};
</script>

<style>
.deactivated {
  background: repeating-linear-gradient(
    -55deg,
    rgb(255, 255, 255),
    rgb(255, 255, 255) 10px,
    rgb(219, 219, 219) 10px,
    rgb(219, 219, 219) 20px
  );
}
</style>
