<template>
  <!-- <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }" slim> -->
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors }" slim>
    <div
      ref="editorWrap"
      tabindex="0"
      class="d-flex flex-column flex-grow-1"
      @focus="focusEditor"
      :style="errors[0] ? 'box-shadow: inset 0 0 0px 2px #EB4747' : undefined"
    >
      <quill-editor
        ref="editor"
        v-model="localValue"
        @input="handleChange"
        :options="editorOptions"
      />
      <!-- <div style="position: relative">
        <transition name="slide">
          <div v-show="errors[0]" class="el-form-item__error">{{ errors[0] }}</div>
        </transition>
      </div> -->
    </div>
  </ValidationProvider>
</template>

<script>
import { Message } from "element-ui";
import { quillEditor, Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);
import { ValidationProvider } from "vee-validate";

export default {
  name: "todo-wysiwyg",
  components: {
    quillEditor,
    ValidationProvider,
    Message,
  },
  props: {
    value: String,
    name: String,
    rules: String,
  },
  data() {
    return {
      localValue: "",
      editorOptions: {
        modules: {
          imageDropAndPaste: {
            handler: () => {
              Message.warning(
                this.$t("Files cannot be added by copy & paste. Please use the Attachment function instead")
              );
              return false;
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote", "link", { list: "ordered" }, { list: "bullet" }],
            // [{ color: [] }, { background: [] }],
            ["clean"],
          ],
        },
        bounds: ".todo-editor-body",
      },
    };
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    handleChange(newValue) {
      this.localValue = newValue;
      this.$emit("input", newValue);
    },
    focusEditor() {
      this.$refs.editor.quill.focus();
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal || "";
    },
  },
};
</script>

<style></style>
