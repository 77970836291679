<template>
  <el-row :gutter="20" style="margin-bottom: 20px; align-items: stretch" type="flex">
    <el-col :lg="6" :md="8">
      <profile-date-picker
        v-model="localDateOfEntering"
        :title="
          resourceType === 'employee' ? $t('src.components.employees.profile.eintrittsdatum') : 'Aktivierungsdatum'
        "
        :name="
          resourceType === 'employee' ? $t('src.components.employees.profile.eintrittsdatum') : 'Aktivierungsdatum'
        "
        :isEditMode="isEditMode"
        :clearable="false"
        required
        rules="required"
      />
    </el-col>

    <el-col :lg="6" :md="8" v-if="!isNewResource">
      <profile-date-picker
        v-model="localDateOfLeaving"
        :title="
          resourceType === 'employee' ? $t('src.components.employees.profile.austrittsdatum') : 'Deaktivierungsdatum'
        "
        :isEditMode="isEditMode"
      />
    </el-col>
    <el-col :md="1" v-if="isEditMode && !isNewResource">
      <cancel-button @click="showAddDate" style="margin-top: 21px">
        <plus-icon />
      </cancel-button>
    </el-col>
    <el-col :lg="12" :md="8" v-if="dateHistory">
      <div class="ml-4">
        <el-button
          @click="showHistory = !showHistory"
          :icon="showHistory ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          type="text"
          size="small"
          class="outline-none"
          >{{ $t("src.components.generic.supplierarticles.supplierarticledetails.history") }}</el-button
        >
      </div>
      <el-collapse-transition>
        <div v-show="showHistory">
          <el-timeline reverse>
            <el-timeline-item v-for="(record, index) in dateHistory" :key="index" :timestamp="formatDate(record)">
              <div class="flex justify-between">
                <div>
                  {{ getTimelineLabel(record) }}
                </div>
                <div style="flex-shrink: 0" class="flex" v-if="isEditMode">
                  <div class="info-action" @click="deleteRecord(index)"><trash-icon /></div>
                  <div class="info-action" @click="editRecord(index)"><pencil-icon /></div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-collapse-transition>
    </el-col>
    <el-dialog width="400px" :visible.sync="isVisible" @closed="discard" :title="dialogTitle">
      <profile-radio-group class="mb-2" v-model="selectedDateType" :items="dateTypeItems" />
      <profile-date-picker v-model="selectedDate" :title="$t('Date')" />
      <div class="text-right">
        <el-button type="primary" :loading="isLoading" @click="submitDate">Speichern</el-button>
      </div>
    </el-dialog>
    <el-dialog width="400px" :visible.sync="isEditVisible" @closed="discardEdit" title="Datum bearbeiten">
      <profile-date-picker
        v-model="editingRecord.dateOfEntering"
        :title="$t('src.components.employees.profile.eintrittsdatum')"
      />
      <profile-date-picker
        v-model="editingRecord.dateOfLeaving"
        :title="$t('src.components.employees.profile.austrittsdatum')"
      />
      <div class="text-right">
        <el-button type="primary" :loading="isLoading" @click="submitEdit">Speichern</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import { Timeline, TimelineItem, Button, Dialog, Message } from "element-ui";
import { moment } from "src/config/moment";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import TrashIcon from "vue-material-design-icons/TrashCanOutline.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";

export default {
  name: "date-history",
  components: {
    TrashIcon,
    [CollapseTransition.name]: CollapseTransition,
    [Message.name]: Message,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
  },
  props: {
    resourceType: { type: String, required: true },
    resourceId: { type: String },
    isEditMode: { type: Boolean },
    dateHistory: { type: Array },
    dateOfEntering: { type: [String, Date] },
    dateOfLeaving: { type: [String, Date] },
  },
  data() {
    return {
      isLoading: false,
      isVisible: false,
      showHistory: false,
      localDateOfEntering: null,
      localDateOfLeaving: null,
      selectedDate: null,
      isEditVisible: false,
      editingRecord: {},
      selectedDateType: "dateOfEntering",
      resourceTypeToUrl: {
        employee: "/api/employees",
        machine: "/api/machines",
        rhb: "/api/rhb",
        vehicle: "/api/vehicles",
        supply: "/api/supply",
      },
    };
  },
  mounted() {
    this.localDateOfEntering = this.dateOfEntering ? moment(this.dateOfEntering).startOf("day").toISOString() : null;
    this.localDateOfLeaving = this.dateOfLeaving ? moment(this.dateOfLeaving).startOf("day").toISOString() : null;
  },
  methods: {
    formatDate(record) {
      return record.createdAt ? moment(record.createdAt).format("LLL") : "N/A";
    },
    getTimelineLabel(record) {
      const label = [];
      if (record.dateOfEntering) {
        label.push(
          `${this.$t("src.components.employees.profile.eintrittsdatum")} - ${moment(record.dateOfEntering).format("L")}`
        );
      }
      if (record.dateOfLeaving) {
        label.push(
          `${this.$t("src.components.employees.profile.austrittsdatum")} - ${moment(record.dateOfLeaving).format("L")}`
        );
      }
      return label.join(", ");
    },
    showAddDate() {
      this.isVisible = true;

      if (this.dateOfLeaving || !this.dateOfEntering) {
        this.selectedDateType = "dateOfEntering";
      } else {
        this.selectedDateType = "dateOfLeaving";
      }
    },
    discard() {
      this.isVisible = false;
      this.selectedDate = null;
    },
    async submitDate() {
      try {
        this.isLoading = true;
        const response = await this.axios.put(
          `${this.resourceTypeToUrl[this.resourceType]}/${this.resourceId}/date-history`,
          {
            [this.selectedDateType]: moment(this.selectedDate).format("YYYY-MM-DD"),
          }
        );
        this.$emit("setProfileData", response.data);
        this.discard();
        Message.success("Datum gespeichert");
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    deleteRecord(index) {
      const record = this.dateHistory[index];
      this.$confirmDelete().then(async () => {
        try {
          const response = await this.axios.delete(
            `${this.resourceTypeToUrl[this.resourceType]}/${this.resourceId}/date-history/${record._id}`
          );
          this.$emit("setProfileData", response.data);
          Message.success("Datum gespeichert");
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    editRecord(index) {
      const record = this.dateHistory[index];
      this.editingRecord = { ...record };
      this.isEditVisible = true;
    },
    async submitEdit() {
      try {
        this.isLoading = true;
        const response = await this.axios.put(
          `${this.resourceTypeToUrl[this.resourceType]}/${this.resourceId}/date-history/${this.editingRecord._id}`,
          { ...this.editingRecord }
        );
        this.$emit("setProfileData", response.data);
        Message.success("Datum gespeichert");
        this.discardEdit();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    discardEdit() {
      this.isEditVisible = false;
      this.editingRecord = {};
    },
  },
  computed: {
    dialogTitle() {
      return `Neues ${
        this.resourceType === "employee"
          ? this.$t("src.components.employees.profile.eintrittsdatum")
          : "Aktivierungsdatum"
      } hinzufügen`;
    },
    isNewResource() {
      return !this.resourceId;
    },
    dateTypeItems() {
      const isDateEnteringNotSet = !this.dateOfEntering;
      return [
        {
          label:
            this.resourceType === "employee"
              ? this.$t("src.components.employees.profile.eintrittsdatum")
              : "Aktivierungsdatum",
          disabled: !!this.dateOfEntering,
          value: "dateOfEntering",
        },
        {
          label:
            this.resourceType === "employee"
              ? this.$t("src.components.employees.profile.austrittsdatum")
              : "Deaktivierungsdatum",
          value: "dateOfLeaving",
          // should be disabled if adding new history record - because always start from dateOfEntering
          disabled: isDateEnteringNotSet || !!(this.dateOfEntering && this.dateOfLeaving),
        },
      ];
    },
  },
  watch: {
    dateOfEntering(newVal, oldVal) {
      if (!oldVal || newVal !== oldVal) {
        this.localDateOfEntering = moment(newVal).startOf("day").toISOString();
      }
    },
    dateOfLeaving(newVal, oldVal) {
      if (!oldVal || newVal !== oldVal) {
        this.localDateOfLeaving = moment(newVal).startOf("day").toISOString();
      }
    },
    localDateOfEntering(newVal) {
      this.$emit("saveDateHistory", { dateOfEntering: newVal });
    },
    localDateOfLeaving(newVal) {
      this.$emit("saveDateHistory", { dateOfLeaving: newVal });
    },
  },
};
</script>

<style></style>
