<template>
  <div>
    <form>
      <div class="card-body">
        <el-row>
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.project.profilesettings.projektdaten") }}
            </div>
          </el-col>
        </el-row>
        <div v-show="false">
          <type-settings :isEditMode="false" modelid="coreServices" modelType="project" type="Kernleistungen" />
          <project-type-settings :isEditMode="false" />
        </div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="12">
            <type-settings
              modelType="project"
              modelid="hazardousSubstances"
              type="Gefahrstoffe"
              :isEditMode="isEditMode"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </el-col>
        </el-row>

        <hr />

        <el-row>
          <el-col>
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.project.profilesettings.leistungsspektrum") }}
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 20px">
          <el-col :span="12">
            <type-settings
              :isEditMode="isEditMode"
              modelid="serviceSpectrum"
              modelType="global"
              type="Leistungsspektrum"
            />
          </el-col>
        </el-row>

        <hr />

        <el-row>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.project.profilesettings.dokumente") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <el-col :span="12">
            <!-- Dokument Settings -->
            <type-settings
              :isEditMode="isEditMode"
              modelid="project"
              modelType="documents"
              type="Dokument"
              :useInSma="true"
              :useSystemRelevant="true"
              :useAccessGroups="true"
              :forAdmin="true"
            />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="n-profile-section-font n-profile-section">
              {{ $t("src.components.project.edit.projectControlling") }}
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-bottom: 20px">
          <el-col :span="12">
            <!-- Kosten-Verursacher -->
            <type-settings
              :isEditMode="isEditMode"
              modelid="costsPosition"
              modelType="project"
              type="Kosten-Verursacher"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </el-col>
        </el-row>
      </div>
    </form>
  </div>
</template>

<script>
import { TypeSettings, ProductGroups } from "src/components/UIComponents";
import ProjectTypeSettings from "src/components/Management/ProjectSettings/ProjectTypeSettings.vue";

export default {
  name: "project-profile-settings",
  components: {
    [ProjectTypeSettings.name]: ProjectTypeSettings,
    [TypeSettings.name]: TypeSettings,
    [ProductGroups.name]: ProductGroups,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
