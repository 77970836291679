<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container" style="z-index: 100">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <card type="login">
                <template v-slot:header>
                  <h3 class="header text-center" style="margin-bottom: 0">
                    {{ $t("src.components.login.nameDerFirma") }}
                  </h3>
                </template>

                <el-form ref="form" :rules="formRules" :model="formData">
                  <el-form-item prop="realmName" style="margin-bottom: 10px">
                    <el-input
                      v-model="formData.realmName"
                      prefix-icon="nc-icon nc-single-02"
                      :placeholder="$t('src.components.login.mandant')"
                      @keyup.enter="handleSubmit"
                      autofocus
                      name="realmName"
                      autocapitalize="none"
                      auto-complete="one"
                    ></el-input>
                  </el-form-item>
                  <el-alert v-if="errorMessage" :title="errorMessage" type="error" show-icon> </el-alert>
                  <button class="btn btn-info btn-fill btn-wd" style="width: 100%" type="submit" @click="handleSubmit">
                    {{ $t("src.components.login.login") }}
                  </button>
                </el-form>
              </card>
            </div>
          </div>
        </div>
        <div class="full-page-background" style="background-image: url(/static/img/background/david-marcu.jpg)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert, Card, Input, Form, FormItem, Message } from "element-ui";
import AppNavbar from "./Dashboard/Views/Pages/Layout/AppNavbar";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    Card,
    [Message.name]: Message,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    AppNavbar,
  },
  data() {
    return {
      formRules: {
        realmName: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      errorMessage: "",
      formData: {
        realmName: "",
      },
    };
  },
  computed: {
    ...mapState("account", ["status", "realm"]),
    ...mapState({ alert: (state) => state.alert }),
  },
  methods: {
    ...mapMutations("account", { setRealm: "setRealm" }),
    ...mapActions({ clearAlert: "alert/clear" }),
    handleLogin() {
      if (this.$keycloak.authenticated) {
        this.$router.replace("/welcome");
      } else if (this.realm) {
        const searchParams = new URLSearchParams(window.location.search);
        let redirectUri;
        if (searchParams.has("from")) {
          redirectUri = searchParams.get("from");
        } else {
          redirectUri = window.location.href.replace("login", "welcome");
        }
        this.$keycloak.login({ redirectUri: redirectUri });
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.errorMessage = "";
            const formBody = { name: this.formData.realmName };
            const response = await fetch(process.env.VUE_APP_FRONTDOOR_URL, {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              body: JSON.stringify(formBody),
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            })
              .then((res) => res.json())
              .catch((err) => {
                throw err;
              });
            if (response.status === "OK") {
              localStorage.setItem("realm", response.realm);
              localStorage.setItem("api", response.api);
              window.location.reload();
            } else {
              this.errorMessage = "ungültiger Firmenname";
            }
          } catch (err) {
            Message.error(err.message);
            throw err;
          }
        }
      });
    },
  },
  created() {
    const savedRealm = localStorage.getItem("realm") || "";
    this.realmName = savedRealm;
    // this.handleLogin();
    // this.clearAlert();
  },
};
</script>

<style></style>
