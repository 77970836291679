<template>
  <div class="webview-content"></div>
</template>
<script>
export default {
  components: {},
  name: "webview-content",
  data() {
    return {
      token: "",
      isLoggedIn: false,
    };
  },
  mounted() {
    // window.addEventListener("message", this.listenMessages, false);
    // this.notifyInitialized();
  },
  beforeDestroy() {
    // window.removeEventListener("message", this.listenMessages);
  },
  methods: {},
};
</script>
<style lang="scss">
.webview-content {
  height: 100vh;
  position: relative;
  > div {
    height: 100%;
  }
  .topbar-filters {
    padding: 10px 10px 0;
  }
}
</style>