<template>
  <div>
    <div class="form-group row">
      <div class="col-md-2">
        <label>{{ $t('src.components.serviceprovider.hotel.stars') }}</label>
        <br />
        <el-rate
          :max="4"
          :disabled="!isEditMode"
          style="margin-top:10px"
          v-model="serviceProvider.extendedProps.stars"
        />
      </div>
    </div>
    <div class="row form-group">
      <div class="col-md-6">
        <label>{{ $t('src.components.serviceprovider.hotel.kommentar') }}</label>
        <el-input
          type="textarea"
          :disabled="!isEditMode"
          :rows="5"
          :placeholder="$t('src.components.serviceprovider.hotel.kommentar')"
          v-model="serviceProvider.extendedProps.starComment"
        >
        </el-input>
      </div>
      <div class="col-md-6">
        <label>{{ $t('src.components.serviceprovider.hotel.freitext') }}</label>
        <el-input
          type="textarea"
          :disabled="!isEditMode"
          :rows="5"
          :placeholder="$t('src.components.serviceprovider.hotel.freitext')"
          v-model="serviceProvider.extendedProps.freeText"
        >
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>

import { Rate } from "element-ui";

export default {
  name: "hotel",
  props: {
    serviceProvider: Object,
    isEditMode: {
      type: Boolean,
      default: true
    }
  },
  components: {
    [Rate.name]: Rate
  },
  data() {
    return {};
  },
  methods: {
    getExtendedProps: function() {
      return this.hotel;
    }
  }
};
</script>

<style></style>
