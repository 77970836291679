<template>
  <widget-tab
    :title="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.fehlendeDatenstze')"
    :hasFilters="true"
    :hasSorter="true"
    @sortOrderChange="changeSort"
  >
    <template #filters>
      <el-form :inline="true">
        <el-form-item>
          <profile-select
            :editMode="true"
            :multiple="false"
            v-model="activeTab"
            :title="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.group')"
            style="width: 150px"
            :items="tabOptions"
          />
        </el-form-item>
        <el-form-item>
          <profile-input
            :editMode="true"
            clearable
            v-model="filterQuery"
            :label="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.filterNachName')"
          />
        </el-form-item>
        <el-form-item>
          <profile-switch
            v-model="filterVisible"
            :title="$t('src.components.dashboard.views.dashboard.widgets.emptyfieldswidget.filterVisible')"
            :active-text="$t('src.components.employees.profile.ja')"
            :inactive-text="$t('src.components.employees.profile.nein')"
          />
        </el-form-item>
      </el-form>
    </template>
    <div v-loading="loading" style="height: 100%">
      <div class="p-3 text-center" v-if="noRightsMessage">
        {{ noRightsMessage }}
      </div>
      <empty-fields-table
        v-else
        ref="widgetTable"
        :recordsUpdated="recordsUpdated"
        @saveChanges="saveChanges"
        @onHandleUpdates="recordsUpdateHandled"
        @onMarkHiddenFields="checkHiddenFields"
        @onDismissHiddenFields="dismissHiddenFields"
        :records="displayedRecords"
        :modelType="activeTab"
        :filterVisible="filterVisible"
        :accessRights="accessRights"
      />
    </div>
  </widget-tab>
</template>

<script>
import { mapState } from "vuex";
import { linksMap, emptyFieldsSchema } from "./emptyFieldsWidgetConfig";
import { Select, Form, FormItem, Input, Message } from "element-ui";
import EmptyFieldsTable from "./EmptyFieldsTable.vue";
import { set, get, orderBy, groupBy } from "lodash";
import { unlink } from "src/utils/unlink";
import WidgetTab from "./Widgets/WidgetTab.vue";
import ProfileSwitch from "../../../UIComponents/Inputs/ProfileSwitch.vue";

export default {
  name: "empty-fields-widget",
  components: {
    WidgetTab,
    ProfileSwitch,
    [Message.name]: Message,
    [Select.name]: Select,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [EmptyFieldsTable.name]: EmptyFieldsTable,
  },
  props: {
    accessRights: { type: String },
  },
  data() {
    return {
      tabOptions: [
        { value: "employee", label: "Personal" },
        { value: "machine", label: "Maschinen" },
        { value: "vehicle", label: "KFZ" },
        { value: "rhb", label: "RHB" },
      ],
      activeTab: "employee",
      loading: false,
      emptyFieldsRecords: [],
      resourcesDb: [],
      sortOrder: "ASC",
      filterQuery: "",
      noRightsMessage: "",
      filterVisible: false,
      recordsUpdated: false, // used to trigger fetch call of model types when records are updated
    };
  },
  mounted() {
    this.fetchTab(this.activeTab);
  },
  methods: {
    async fetchTab(tabName) {
      try {
        const isAdmin = this.accessRights === "full";
        const hasAccessToResource = this.checkAccessToResource(tabName);
        if (!hasAccessToResource) {
          const resourceLabel = this.tabOptions.find((i) => i.value === tabName).label;
          this.noRightsMessage = `Sie haben keine Berechtigung, ${resourceLabel} Daten zu bearbeiten`;
        } else {
          this.noRightsMessage = "";
        }
        this.loading = true;
        const isEmployee = tabName === "employee";
        const url = linksMap[tabName];
        const emptyFieldsMap = unlink(emptyFieldsSchema[tabName]);
        // receive list of hidden fields for each record and group it in map-like object
        const hiddenFieldsMap = await this.fetchHiddenFields().then((data) => groupBy(data, "resourceId"));

        const resources = await this.axios.get(url).then((res) => res.data.filter((item) => item.active));
        this.resourcesDb = resources;

        const resourceAccessRights = this.appAccessRights[tabName] || {};
        const specificAccessRights = resourceAccessRights.specificAccess || {};
        const recordsWithEmptyFields = resources.reduce((arr, record) => {
          // fields that should not be displayed in widget
          const hiddenFields = hiddenFieldsMap[record.id];
          let recordName;
          if (record.firstName) {
            recordName = `${record.lastName}, ${record.firstName}`;
          } else {
            recordName = record.name;
          }
          const missingFieldsRecord = { id: record.id, name: recordName, fields: [] };
          let hasEmptyFields = false;

          Object.entries(emptyFieldsMap).forEach((field) => {
            const fieldCopy = unlink(field);
            const [key, fieldInfo] = fieldCopy;
            // check access rights for each field and hide fields where user doesn't have access
            if (fieldInfo.accessRightName && !specificAccessRights[fieldInfo.accessRightName]) {
              return arr;
            }
            if (specificAccessRights[fieldInfo.accessRightName] === "readonly") {
              fieldInfo.disabled = true;
            }
            // if field is in list of hidden fields - break;
            if (hiddenFields) {
              const hiddenFieldRecord = hiddenFields.find((item) => item.fieldName === key);
              if (hiddenFieldRecord) {
                if (this.filterVisible) {
                  if (hiddenFieldRecord.hideForAll) {
                    if (isAdmin) {
                      fieldInfo.isHidden = true;
                      fieldInfo.hiddenFieldRecordId = hiddenFieldRecord._id;
                    } else {
                      return arr;
                    }
                  } else {
                    fieldInfo.isHidden = true;
                    fieldInfo.hiddenFieldRecordId = hiddenFieldRecord._id;
                  }
                } else {
                  fieldInfo.isHidden = undefined;
                  fieldInfo.hiddenFieldRecordId = undefined;
                  return arr;
                }
              }
            }
            // dont show this field for active employees
            if (isEmployee && record.active && key === "dateOfLeaving") {
              return arr;
            }
            // otherwise check if field is empty or not
            let comparator, defaultValue;
            if (fieldInfo.valueType === "list") {
              comparator = (arr) => arr && arr.length;
              defaultValue = [];
            } else {
              comparator = Boolean;
              defaultValue = "";
            }
            if (!comparator(get(record, key))) {
              hasEmptyFields = true;
              missingFieldsRecord.fields.push({ key, ...fieldInfo, value: defaultValue });
            }
          });
          if (hasEmptyFields) {
            arr.push(missingFieldsRecord);
          }
          return arr;
        }, []);
        this.emptyFieldsRecords = recordsWithEmptyFields;
        this.$nextTick(() => {
          this.recordsUpdated = true;
        });
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    checkAccessToResource(resourceType) {
      return !!get(this.appAccessRights, `${resourceType}.generalAccess`);
    },
    async fetchHiddenFields() {
      try {
        const response = await this.axios.get("/api/hidden-empty-fields", {
          params: { resourceType: this.activeTab },
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async checkHiddenFields(data) {
      try {
        const formData = {
          resourceType: this.activeTab,
          resourceId: data.resourceId,
          fieldNames: data.fieldNames,
          hideForAll: data.hideForAll,
        };
        await this.axios.post("/api/hidden-empty-fields", formData);
        this.fetchTab(this.activeTab);
      } catch (error) {
        throw error;
      }
    },
    async dismissHiddenFields(payload) {
      try {
        await this.axios.post("/api/hidden-empty-fields/remove", payload);
        this.fetchTab(this.activeTab);
      } catch (error) {
        throw error;
      }
    },
    async saveChanges(rowData) {
      try {
        const hasChanges = rowData.fields.some((field) => !!field.value);
        if (!hasChanges) {
          return;
        }
        const changedFields = rowData.fields.reduce((object, field) => {
          if (field.value) {
            set(object, field.key, field.value);
          }
          return object;
        }, {});
        const record = this.resourcesDb.find((item) => item.id === rowData.id);
        const formData = { ...record, ...changedFields };
        const url = linksMap[this.activeTab] + `/${rowData.id}`;
        await this.axios.put(url, formData);
        this.fetchTab(this.activeTab);
        this.$refs.widgetTable.closeModal();
        Message.success("Ressource gespeichert");
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    recordsUpdateHandled() {
      this.recordsUpdated = false;
    },
    changeSort(sortOrder) {
      this.sortOrder = sortOrder;
    },
    // goToDocuments(resourceId) {
    //   const resolved = this.$router.resolve({
    //     path: `/${this.activeTab}/profile/${resourceId}`,
    //     query: {
    //       tab_pane: "Dokumente",
    //     },
    //   });
    //   window.open(resolved.href, "_blank");
    // },
  },
  computed: {
    ...mapState("account", { appAccessRights: "accessRights" }),
    displayedRecords() {
      const sorted = orderBy(this.emptyFieldsRecords.slice(), ["name"], [this.sortOrder.toLowerCase()]);
      if (this.filterQuery) {
        const filterQuery = this.filterQuery.toLowerCase();
        return sorted.filter((item) => item.name && item.name.toLowerCase().indexOf(filterQuery) !== -1);
      }
      return sorted;
    },
  },
  watch: {
    filterVisible(newVal, oldVal) {
      this.fetchTab(this.activeTab);
    },
    activeTab(newVal, oldVal) {
      this.fetchTab(newVal);
      this.filterQuery = "";
      // this.sortOrder = "ASC";
    },
  },
};
// resourceType
// resourceId
// propertyName
</script>

<style></style>
