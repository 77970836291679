export function getFilenameFromResponse(response) {
  let filename;
  const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];
  if (contentDisposition) {
    filename = contentDisposition.match('filename="([^"]*)"')[1];
  } else {
    filename = `undefined`;
  }
  return filename;
}
