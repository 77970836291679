<template>
  <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%;">
    <el-table :data="tableData" v-loading="loading" style="width: 100%">
      <el-table-column :label="resourceName" prop="name"></el-table-column>
      <el-table-column
        v-for="docType in documentTypes"
        :key="docType._id"
        :label="docType.label"
        :data-doctype-id="docType._id"
      >
        <template v-slot="scope">
          <el-checkbox
            :checked="isCheckedDocType(scope.row, docType._id)"
            class="_doc-checkbox"
            :data-model-id="docType._id"
          />
        </template>
      </el-table-column>
    </el-table>
  </perfect-scrollbar>
</template>

<script>
import { Table, TableColumn, Checkbox, Message } from "element-ui";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  name: "document-type-matrix",
  components: {
    PerfectScrollbar,
    [Checkbox.name]: Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  props: {
    resourceType: { type: String, required: true },
    resourceName: { type: String, required: true }
  },
  data() {
    return {
      loading: false,
      documentTypes: [],
      resources: [],
      tableData: []
    };
  },
  mounted() {
    this.initTableData();
  },
  methods: {
    async fetchDocumentTypes() {
      try {
        const params = {
          modelType: "documents",
          modelId: this.resourceType
        };
        const documentTypes = await this.axios.get(`/api/model-type`, { params }).then(res => res.data);
        this.documentTypes = documentTypes;
        return documentTypes;
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async fetchResources() {
      try {
        const resourceTypeToUrl = {
          employee: "employees",
          machine: "machines",
          vehicle: "vehicles",
          rhb: "rhb",
          supply: "supply"
        };
        return await this.axios.get(`/api/${resourceTypeToUrl[this.resourceType]}`).then(res => res.data);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    convertToTableData(resources, documentTypes) {
      // create initial map of all documentTypes that are set to false
      const initialDcumentTypes = documentTypes.reduce((obj, current) => {
        obj[current._id] = false;
        return obj;
      }, {});
      const result = resources.map(resource => {
        if (resource.requiredDocumentTypes) {
          // set map of documentTypes with "true" for entries that are found in resource.requiredDocumentTypes list
          const documentTypes = resource.requiredDocumentTypes.reduce(
            (obj, docTypeId) => {
              obj[docTypeId] = true;
              return obj;
            },
            { ...initialDcumentTypes }
          );
          return {
            ...resource,
            documentTypes
          };
        } else {
          return {
            ...resource,
            documentTypes: { ...initialDcumentTypes }
          };
        }
      });
      return result;
    },
    async initTableData() {
      try {
        this.loading = true;
        const [resources, documentTypes] = await Promise.all([this.fetchResources(), this.fetchDocumentTypes()]);
        this.tableData = this.convertToTableData(resources, documentTypes);
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    isCheckedDocType(record) {
      return false;
    },
    handleCheckboxClick(record, evt) {
      // bind event listender to document, find _doc-checkbox => data-id and do checks/computations
      alert("TODO: implement me");
      return false;
    }
  }
};
</script>

<style></style>
