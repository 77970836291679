<template>
  <div>
    <template v-if="mode === 'checkbox'">
      <el-checkbox-group v-bind="$attrs" v-model="listInput">
        <el-checkbox-button v-for="day of weekdaysList" :label="day" :key="day">
          {{ weekDaysMap[day] }}
        </el-checkbox-button>
      </el-checkbox-group>
    </template>
    <template v-else>
      <el-select v-bind="$attrs" multiple v-model="listInput">
        <el-option v-for="day of weekdaysList" :key="day" :value="day" :label="weekDaysMap[day]"> </el-option>
      </el-select>
    </template>
  </div>
</template>

<script>
import { CheckboxGroup, CheckboxButton } from "element-ui";

export default {
  name: "weekday-select",
  components: {
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  props: {
    mode: { type: String, default: "checkbox", enum: ["checkbox", "select"] },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      weekdaysList: [1, 2, 3, 4, 5, 6, 7],
      weekDaysMap: {
        1: "Mo",
        2: "Di",
        3: "Mi",
        4: "Do",
        5: "Fr",
        6: "Sa",
        7: "So",
      },
    };
  },
  computed: {
    listInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        const sorted = newValue.sort();
        this.$emit("input", sorted);
      },
    },
  },
};
</script>

<style></style>
