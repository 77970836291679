<template>
  <div class="d-widget-item">
    <div class="d-widget-name">
      {{ title }}
    </div>
    <div class="d-widget-stat" style="justify-content: space-between">
      <div class="d-widget-stat" v-for="(item, idx) in data" :key="idx">
        <div class="d-widget-num" :style="{ background: item.color }">{{ item.number }}</div>
        <div class="d-widget-text">{{ formatText(item) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "widget-area-item",
  props: {
    title: { type: String, required: true },
    data: { type: Array },
  },
  methods: {
    formatText(record) {
      const VARIABLE = `{variable}`;
      const hasVariables = record.labelTemplate.indexOf(VARIABLE) !== -1;
      if (hasVariables) {
        const label = record.labelTemplate.replace(VARIABLE, record.variable);
        return label;
      } else {
        return record.labelTemplate;
      }
    },
  },
};
</script>

<style>
</style>