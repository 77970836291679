<template>
  <div ref="editorWrap" tabindex="0" class="notes-editor d-flex flex-column flex-grow-1" @focus="focusEditor">
    <quill-editor
      ref="editor"
      v-model="localValue"
      @input="handleChange"
      :options="editorOptions"
    />
  </div>
</template>

<script>
// import { VueEditor, Quill } from "vue2-editor";
import { Message } from "element-ui";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import { quillEditor, Quill } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

export default {
  name: "note-wysiwyg",
  components: {
    quillEditor,
    // VueEditor,
    Message,
  },
  props: {
    value: String,
  },
  data() {
    return {
      localValue: "<p></p>",
      editorOptions: {
        modules: {
          imageDropAndPaste: {
            handler: () => {
              Message.warning(
                this.$t("Files cannot be added by copy & paste. Please use the Attachment function instead")
              );
              return false;
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote", "link", { list: "ordered" }, { list: "bullet" }],
            // [{ color: [] }, { background: [] }],
            ["clean"],
          ],
        },
        bounds: ".notes-editor",
      },
    };
  },
  mounted() {
    this.localValue = this.value;
  },
  methods: {
    handleChange(newValue) {
      this.localValue = newValue;
      this.$emit("input", newValue);
    },
    focusEditor() {
      this.$refs.editor.quill.focus();
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal || "";
    },
  },
};
</script>

<style></style>
