<template>
  <el-button v-bind="$attrs" :class="{ 'cancel-btn': true, active: active }" @click="(evt) => $emit('click', evt)">
    <slot>{{ $t("src.components.uicomponents.buttons.cancelprofilebutton.abbrechen") }}</slot>
  </el-button>
</template>

<script>
export default {
  name: "cancel-button",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    active: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style>
.cancel-btn.active {
  background: #EFF1F6;
}
</style>
