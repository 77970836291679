import { Widget, DomHelper } from "@bryntum/scheduler";
import { ObjectHelper } from "@bryntum/scheduler";
const fullConfigKeys = ["items", "type", "widgets", "html", "listeners"];

export class ChecklistWidget extends Widget {
  static payload = [];
  static onSelect = null;
  static currentList = null;
  static currentListItemIndex = null;
  static checkedItems = null;
  static lastCheckedItem = null;

  static get type() {
    return "checklist_widget";
  }
  static get $name() {
    return "checklist_widget";
  }

  static get configurable() {
    return {
      id: null,
      cls: null,
      text: null,
      payload: this.payload,
      pressed: false,
      toggleable: false,
      menu: {
        $config: ["lazy", "nullify"],
        value: null,
      },
      menuDefaults: {
        type: "menu",
        autoShow: false,
        autoClose: true,
        floating: true,
        scrollAction: "realign",
        align: "t0-b0",
      },
      checklistItems: [],
      checkedItems: [],
      lastCheckedItem: null,
      selectedChecklistItemIndex: null,
    };
  }

  handleSelectChecklist(item, restProps) {
    item.checked = true;
    this.currentList = { id: item.id, name: item.text };
    this.checklistItems = this.payload.find((i) => i._id === item.id).items || [];

    if (this.checklistItems.length) {
      const itemsLeft = this.checklistItems.filter(
        (item) => !this.checkedItems.some((checked) => checked._id === item._id)
      );
      if (itemsLeft.length) {
        this.checklistItems = itemsLeft;
        this.checklistItemsControl.hidden = false;
        this.handleSelectChecklistItem(this.currentListItemIndex || 0);
        this.checklistItemsControl.hidden = false;
      } else {
        this.onSelect(null);
        document.getElementById("checklist_name").textContent = "Checkliste komplett";
      }
    }
  }

  setData(data) {
    if (data && Array.isArray(data) && data.length) {
      this.buttonElement.classList.remove("b-disabled");
      this.payload = data.slice();
      this.menu = {
        items: data.map((i) => ({
          id: i._id,
          text: i.name,
          toggleGroup: "checklist_group",
          checked: false,
        })),
        onItem: ({ item, ...restProps }) => this.handleSelectChecklist(item, restProps),
      };
    }
  }

  compose() {
    const { cls, text, payload, pressed, onSelect, id } = this; // collect all relevant configs properties (for auto-detection)
    return {
      tag: "div",
      class: { "checklist-widget": true, [cls]: true },
      payload: payload,
      onSelect: onSelect,
      children: [
        {
          tag: "button",
          reference: "buttonElement",
          listeners: {
            click: "onInternalClick",
          },
          class: {
            "b-disabled": true,
            "b-button": true,
            "b-icon-align-start": true,
            "b-pressed": pressed,
            "b-has-menu": true,
          },
          children: { iconElement: { tag: "i", class: "b-icon b-fa-clipboard-list" } },
        },
        {
          tag: "div",
          className: "b-widget b-container b-buttongroup b-outer b-content-element b-auto-container b-flex-row",
          reference: "checklistItemsControl",
          hidden: true,
          children: {
            closeBtn: {
              tag: "button",
              reference: "closeBtnRef",
              className: "b-widget b-button b-icon-align-start",
              listeners: {
                click: "handleClose",
              },
              children: {
                icon: {
                  className: "b-fa b-fa-times",
                  tag: "i",
                },
              },
            },
            undoBtn: {
              tag: "button",
              reference: "undoBtnRef",
              className: "b-widget b-button b-icon-align-start",
              listeners: {
                click: "handleUndoCheckItem",
              },
              disabled: true,
              children: {
                icon: {
                  className: "b-fa b-fa-undo",
                  tag: "i",
                },
              },
            },
            checkBtn: {
              tag: "button",
              reference: "checkBtnRef",
              className: "b-widget b-button b-icon-align-start mr-2",
              listeners: {
                click: "handleCheckItem",
              },
              children: {
                icon: {
                  className: "b-fa b-fa-check",
                  tag: "i",
                },
              },
            },
            prevBtn: {
              tag: "button",
              reference: "prevBtnRef",
              className: "b-widget b-button b-icon-align-start",
              children: {
                icon: {
                  className: "b-fa b-fa-chevron-left",
                  tag: "i",
                },
              },
              listeners: {
                click: "handlePrevClick",
              },
            },
            nextBtn: {
              tag: "button",
              reference: "nextBtnRef",
              className: "b-widget b-button b-icon-align-start",
              listeners: {
                click: "handleNextClick",
              },
              children: {
                icon: {
                  className: "b-fa b-fa-chevron-right",
                  tag: "i",
                },
              },
            },
            itemName: {
              tag: "div",
              id: "checklist_name",
              className: "checklist-item-name",
            },
          },
        },
      ],
    };
  }

  handlePrevClick(e) {
    const nextIdx = this.selectedChecklistItemIndex - 1;
    if (this.checklistItems[nextIdx]) {
      this.handleSelectChecklistItem(nextIdx);
    }
  }

  handleNextClick(e) {
    const nextIdx = this.selectedChecklistItemIndex + 1;
    if (this.checklistItems[nextIdx]) {
      this.handleSelectChecklistItem(nextIdx);
    }
  }

  handleClose = (e) => {
    this.checklistItemsControl.hidden = true;
    this.selectedChecklist = null;
    this.checkedItems = [];
    this.lastCheckedItem = null;
    this.selectedChecklistItemIndex = null;
    this.currentList = null;
    this.checklistItems = [];
    this.currentListItemIndex = null; // #2620 - dismiss selected item on close
    this.onSelect(null);
    this.menu.items.forEach((item) => {
      item.checked = false;
    });
  };

  handleUndoCheckItem = (e) => {
    if (this.lastCheckedItem) {
      const idx = Math.min(this.lastCheckedItem.idx, this.checkedItems.length);
      this.checkedItems.pop();
      this.checklistItems.splice(idx, 0, this.lastCheckedItem.value);
      this.handleSelectChecklistItem(idx);
      this.lastCheckedItem = null;
    }
  };

  handleCheckItem = (e) => {
    const checklistItem = this.checklistItems[this.selectedChecklistItemIndex];
    if (!checklistItem) {
      return;
    }
    this.byRef.nextBtn.disabled = false;
    this.lastCheckedItem = { idx: this.selectedChecklistItemIndex, value: checklistItem };
    this.checkedItems.push(checklistItem);
    this.checklistItems.splice(this.selectedChecklistItemIndex, 1);
    if (this.checklistItems.length) {
      this.selectedChecklistItemIndex = Math.min(this.selectedChecklistItemIndex, this.checklistItems.length - 1);
      this.handleSelectChecklistItem(this.selectedChecklistItemIndex);
    } else {
      this.selectedChecklistItemIndex = null;
      this.onSelect(null);
      document.getElementById("checklist_name").textContent = "Checkliste komplett";
      this.byRef.nextBtn.disabled = true;
    }
    this.byRef.undoBtn.disabled = false;
    this.byRef.undoBtn.classList.remove("b-disabled");
  };

  handleSelectChecklistItem = (idx) => {
    this.selectedChecklistItemIndex = idx;
    this.currentListItemIndex = idx;
    if (!this.lastCheckedItem) {
      this.byRef.undoBtn.disabled = true;
      this.byRef.undoBtn.classList.add("b-disabled");
    }
    if (idx === 0) {
      this.byRef.prevBtn.disabled = true;
      this.byRef.prevBtn.classList.add("b-disabled");

      this.byRef.nextBtn.disabled = false;
      this.byRef.nextBtn.classList.remove("b-disabled");
    } else if (idx === this.checklistItems.length - 1) {
      this.byRef.prevBtn.disabled = false;
      this.byRef.prevBtn.classList.remove("b-disabled");

      this.byRef.nextBtn.disabled = true;
      this.byRef.nextBtn.classList.add("b-disabled");
    } else {
      this.byRef.prevBtn.disabled = false;
      this.byRef.prevBtn.classList.remove("b-disabled");

      this.byRef.nextBtn.disabled = false;
      this.byRef.nextBtn.classList.remove("b-disabled");
    }
    if (this.checklistItems[idx]) {
      this.byRef.itemName.innerText = this.checklistItems[idx].name;
      this.onSelect({ ...this.checklistItems[idx] });
    } else {
      console.warn('Checklist item returned "undefined"!');
      console.log("idx", idx);
      console.log("checklistItems", this.checklistItems);
      this.onSelect(null);
    }
  };

  onInternalClick(event) {
    const me = this,
      bryntumEvent = { event };

    if (me.toggleable) {
      // Clicking the pressed button in a toggle group should do nothing
      if (me.toggleGroup && me.pressed) {
        return;
      }

      me.toggle(!me.pressed);

      // Edge case in dragfromgrid demo, where toggling mode destroys the Scheduler and thus destroys the toolbar
      // and the button in it
      if (me.isDestroyed) {
        return;
      }
    }

    /**
     * Fires when the button is clicked
     * @event click
     * @param {Core.widget.Button} source The button
     * @param {Event} event DOM event
     */
    me.trigger("click", bryntumEvent);

    /**
     * Fires when the default action is performed (the button is clicked)
     * @event action
     * @param {Core.widget.Button} source The button
     * @param {Event} event DOM event
     */
    // A handler may have resulted in destruction.
    me.trigger("action", bryntumEvent);
    // since Widget has Events mixed in configured with 'callOnFunctions' this will also call onClick and onAction

    // stop the event since it has been handled
    event.preventDefault();
    event.stopPropagation();
  }

  updateElement(element, oldElement) {
    const { constructor } = this,
      result = super.updateElement(element, oldElement),
      menu = this.peekConfig("menu"),
      role = menu
        ? menu.isWidget
          ? menu.role
          : constructor.resolveType(menu.type)?.configurable?.role ||
            constructor.configurable.menuDefaults?.type ||
            "menu"
        : false;

    this.ariaHasPopup = role;
    return result;
  }

  toggle(pressed = !this.pressed) {
    this.pressed = pressed;
  }

  updateElement(element, oldElement) {
    const { constructor } = this,
      result = super.updateElement(element, oldElement),
      menu = this.peekConfig("menu"),
      role = menu
        ? menu.isWidget
          ? menu.role
          : constructor.resolveType(menu.type)?.configurable?.role ||
            constructor.configurable.menuDefaults?.type ||
            "menu"
        : false;

    this.ariaHasPopup = role;
    return result;
  }

  async restoreState(state) {
    console.log("restoreState state", state);
    // if (state.payload) {
    //   this.setData(this.payload);
    // }
    if (state.checkedItems) {
      this.checkedItems = [].concat(state.checkedItems);
    }
    if (state.currentListItem) {
      this.currentListItemIndex = state.currentListItem;
      this.selectedChecklistItemIndex = state.currentListItem;
    }
    if (state.currentList) {
      this.currentList = state.currentList;
      const menuItem = this.menu.items.find((item) => item.id === state.currentList.id);
      if (menuItem) {
        this.handleSelectChecklist(menuItem);
      }
    }
  }

  get currentState() {
    return {
      currentList: this.currentList,
      currentListItem: this.currentListItemIndex,
      checkedItems: this.checkedItems.slice(),
      payload: this.payload.slice(),
    };
  }

  get childItems() {
    return this._menu && [this.menu];
  }

  //region Getters/Setters

  get focusElement() {
    return this.element;
  }

  changeText(text) {
    return text == null ? "" : text;
  }

  changeToggleable(toggleable) {
    if (toggleable === false) {
      return this.toggleGroup || this.config.menu;
    }

    return toggleable;
  }

  changeMenu(menu, oldMenu) {
    const me = this,
      { element: forElement } = me;

    if (menu) {
      if (menu.isWidget) {
        menu.forElement = forElement;
        menu.owner = me;
      } else {
        // This covers both Array and Object which are valid items config formats.
        // menu could be { itemRef : { text : 'sub item 1 } }. But if it has
        // child items or html property in it, it's the main config
        if (typeof menu === "object" && !fullConfigKeys.some((key) => key in menu)) {
          menu = {
            lazyItems: menu,
          };
        }

        menu = Widget.reconfigure(
          oldMenu,
          menu
            ? ObjectHelper.merge(
                {
                  owner: me,
                  constrainTo: me.rootElement,
                  forElement,
                },
                me.menuDefaults,
                menu
              )
            : null,
          me
        );
      }
      menu.on({
        hide: "onMenuHide",
        show: "onMenuShow",
        thisObj: this,
      });
    } else {
      oldMenu?.destroy();
    }

    return menu;
  }

  onMenuShow() {
    this.ariaElement.setAttribute("aria-expanded", true);
  }

  onMenuHide() {
    this.ariaElement.setAttribute("aria-expanded", false);
    // We must react to its state change to hidden by becoming unpressed.
    // If we just hid it in the toggle method, this will be a no-op.
    this.toggle(false);
  }

  updateMenu(menu) {
    // We are toggleable if there's a menu.
    // Pressed means menu visible, not pressed means menu hidden.
    this.toggleable = Boolean(menu);
  }

  updatePressed(pressed) {
    const me = this;

    if (!me.toggleable || me.isConfiguring) {
      return;
    }

    const { menu } = me;

    if (pressed) {
      DomHelper.forEachSelector(me.rootElement, `button[data-group=${me.toggleGroup}]`, (btnEl) => {
        if (btnEl !== me.element) {
          Widget.getById(btnEl.id).pressed = false;
        }
      });
    }

    if (menu) {
      if (!menu.initialConfig.minWidth) {
        menu.minWidth = me.width;
      }

      // Menu will shrink and fit inside a 10px inset of viewport.
      // Rectangle.alignTo prioritizes alignment if the target edge is closer to
      // the constrainTo edge than this in order to produce visually correct results.
      menu.align.constrainTo = globalThis;
      menu.align.constrainPadding = 10;

      // The presence of a number indicates to the align constraining algorithm
      // that it is *willing* to shrink in that dimension. It will never end up this small.
      // Use the properties because the getter will return 0 if not set.
      menu.align.minHeight = menu._minHeight ?? 100;
      menu.align.minWidth = menu._minWidth ?? 100;

      menu[pressed ? "show" : "hide"]();
    }

    /**
     * Fires when the button is toggled via a UI interaction (the {@link #property-pressed} state is changed). If the button is part of a
     * {@link #config-toggleGroup} and you need to process the pressed button only, consider using
     * {@link #event-click} event or {@link #event-action} event.
     * @event toggle
     * @param {Core.widget.Button} source Toggled button
     * @param {Boolean} pressed New pressed state
     * @param {Event} event DOM event
     */
    me.trigger("toggle", { pressed });
  }

  doDestroy() {
    super.doDestroy();
  }
}

ChecklistWidget.initClass();
