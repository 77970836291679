import { orderBy } from "lodash";
import axios from "axios";

// used in project
function isSelected(productGroup) {
  if (!productGroup) {
    return false;
  }

  if (productGroup == "") {
    return false;
  }

  return true;
}

function ordered(list) {
  return orderBy(list, ["label"], ["asc"]);
}

const state = {
  selectedProductGroup1: "",
  selectedProductGroup2: "",
  productGroupList: [],
  productGroupRoot: {},
  modelType: "",
  rootModelType: "",
};

// Mutations Must Be Synchronous
const mutations = {
  //must be called to reset selections from other entities
  init(state) {
    state.selectedProductGroup1 = "";
    state.selectedProductGroup2 = "";
    state.productGroupRoot = {};
  },
  selectionLoaded(state, selectedProductGroups) {
    state.selectedProductGroup1 = selectedProductGroups.selectedProductGroup1;
    state.selectedProductGroup2 = selectedProductGroups.selectedProductGroup2;
  },
  updateSelectedProductGroup_1(state, newSelection) {
    state.selectedProductGroup1 = newSelection;
  },
  updateSelectedProductGroup_2(state, newSelection) {
    state.selectedProductGroup2 = newSelection;
  },
  updateProductGroup(state, newList) {
    state.productGroupList = newList;
  },
  updateProductGroupRoot(state, newRoot) {
    state.productGroupRoot = newRoot;
  },
  updateModelType(state, newModelType) {
    state.modelType = newModelType;
  },
};

// Actions can contain arbitrary asynchronous operations
const actions = {
  async initAsync({ commit, state }, params) {
    //store modelType
    const { modelType } = params;
    //append root by convention
    state.rootModelType = modelType + "_root";

    //init selection
    commit("init");

    //store modelType
    commit("updateModelType", modelType);

    // load all productgroups
    const [productgroups, root] = await Promise.all([
      axios.get("/api/product-groups", { params: params }),
      axios.get("/api/product-groups", { params: { modelType: state.rootModelType } }),
    ]);
    //store complete product group list from database
    commit("updateProductGroup", productgroups.data);

    // load or create root element for tree
    if (root.data.length === 1) {
      commit("updateProductGroupRoot", root.data[0]);
    } else if (!root.data.length) {
      // create missing root
      let rootItem = {
        label: "root",
        model: state.rootModelType,
        children: [],
      };
      const response = await axios.post(`/api/product-groups`, rootItem);
      commit("updateProductGroupRoot", response.data);
    } else {
      commit("updateProductGroupRoot", root.data.filter((i) => i.children.length)[0]);
      console.error("More than one productgroup root found!");
    }
  },
  async reloadProductGroupsAsync({ commit, state }) {
    // load all productgroups
    let response = await axios.get("/api/product-groups", { params: { modelType: state.modelType } });

    //init selection
    commit("init");

    //store complete product group list from database
    commit("updateProductGroup", response.data);

    // reload base also
    response = await axios.get("/api/product-groups", { params: { modelType: state.rootModelType } });
    if (response.data.length == 1) {
      commit("updateProductGroupRoot", response.data[0]);
    }
  },
  async updateProductGroup({ dispatch }, productGroup) {
    try {
      await axios.put(`/api/product-groups/${productGroup._id}`, productGroup);
      dispatch("reloadProductGroupsAsync");
    } catch (e) {
      throw e;
    }
  },
  async deleteProductGroup({ dispatch }, productGroup) {
    try {
      await axios.delete(`/api/product-groups/${productGroup._id}`);
      dispatch("reloadProductGroupsAsync");
    } catch (e) {
      throw e;
    }
  },
  setSelections({ commit }, selectedProductGroups) {
    commit("selectionLoaded", selectedProductGroups);
  },
};

const getters = {
  productGroupList: (state) => {
    //find for all productgroups except
    return state.productGroupList.filter((x) => x.model !== state.rootModel);
  },
  selectedProductGroup1: (state) => {
    return state.selectedProductGroup1;
  },
  productGroupTree_root: (state) => {
    // get the level 0 or root entity for the tree view
    if (!state.productGroupRoot.children) {
      state.productGroupRoot.children = [];
    }
    return state.productGroupRoot;
  },
  productgroup_1_ordered: (state) => {
    let root = getters.productGroupTree_root(state);
    // are there any productGroup elements?
    if (!root.children || root.children.length == 0) {
      return [];
    }

    //filter for all productgroups with level 1
    let productgroup_1 = state.productGroupList.filter((element) => {
      const exists = root.children.some((id) => id === element._id);
      return exists;
    });

    return ordered(productgroup_1);
  },
  productgroup_2_ordered: (state) => {
    //filter for all productgroups with level 2 (contain a parent)
    const productgroup_1 = state.productGroupList.filter((element) => {
      const exists = state.productGroupList.find((productGroup) => {
        if (productGroup._id === element._id) {
          return false;
        }
        // children contains just IDs:
        const existsInner = productGroup.children && productGroup.children.find((x) => x === element._id);
        return existsInner;
      });
      return !!exists;
    });
    return ordered(productgroup_1);
  },
  //only productgroup 2 placeholder is required - productgroup 1 is not needed
  productgroup_2_placeholder: (state) => {
    let productGroup_2_list = getters.productGroup_2_Computed(state);
    return productGroup_2_list.length + " verfügbar";
  },
  //filter product group 2 for product group 1 selected.
  productGroup_2_Computed: (state) => {
    //is product group 1 selected?
    if (!state.selectedProductGroup1) {
      return [];
    }

    //get product group 1 elements (defined by tree)
    let productGroup_1_list = getters.productgroup_1_ordered(state);

    if (productGroup_1_list && productGroup_1_list.length > 0) {
      //get selected product group 1
      let productGroup_1 = productGroup_1_list.find((x) => x._id === state.selectedProductGroup1);
      return ordered(productGroup_1.children.map((pgId) => state.productGroupList.find((item) => item._id === pgId)));
    } else {
      return [];
    }
  },
};

export const productGroupSettings = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
