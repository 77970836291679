<template>
  <div class="d-flex">
    <cancel-button @click.prevent="$emit('discard')">{{
      $t("src.components.contacts.contactdetails.abbrechen")
    }}</cancel-button>
    <save-button
      :disabled="!selectedDocuments.length"
      @click.prevent="isVisible = true"
      :text="$t('selectResources')"
    />
    <el-dialog :visible.sync="isVisible" :title="$t('selectResources')">
      <el-form label-position="top" :model="formData" ref="form" :rules="formDataRules">
        <el-form-item>
          <profile-switch no-title v-model="showActive" active-text="aktiv" inactive-text="alle" colorizeActive />
        </el-form-item>
        <el-form-item prop="selectedResources">
          <div class="flex align-items-end">
            <profile-select
              :filterable="true"
              style="margin-top: 4px; flex-grow: 1"
              :required="true"
              :multiple="true"
              v-model="formData.selectedResources"
              :title="$t('selectResources')"
              :editMode="true"
              :hideInactive="false"
              :disableInactive="false"
              :reserve-keyword="true"
              :items="shownOptions"
            /><el-button style="flex-shrink: 0; margin-left: 10px" @click="selectAll">{{ $t("selectAll") }}</el-button>
          </div>
        </el-form-item>
        <div class="text-right">
          <el-button @click="closeDialog">{{ $t("src.components.contacts.contactdetails.abbrechen") }}</el-button>
          <el-button type="primary" @click="submitData">{{ $t("submit") }}</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { Message, Button, Dialog, Form, FormItem } from "element-ui";

export default {
  name: "copy-documents-dialog",
  components: {
    [Message.name]: Message,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    currentResourceId: { type: String, required: true },
    serviceProviderType: { type: String },
    resourceType: { type: String, required: true },
    selectedDocuments: { type: Array, required: true },
  },
  data() {
    return {
      isVisible: false,
      showActive: true,
      resourceOptions: [],
      formDataRules: {
        selectedResources: { message: "Bitte auswählen", required: true },
      },
      formData: {
        selectedResources: [],
      },
    };
  },
  mounted() {
    this.fetchResources();
  },
  methods: {
    async fetchResources() {
      try {
        const resourceTypeToUrl = {
          machine: `/api/machines/meta`,
          vehicle: `/api/vehicles/meta`,
          rhb: `/api/rhb/meta`,
          supply: `/api/supply/meta`,
          employee: `/api/employees/meta`,
          project: `/api/projects/meta`,
          serviceProvider: `/api/service-providers`,
        };
        this.resourceOptions = await this.axios.get(resourceTypeToUrl[this.resourceType]).then((res) => {
          let list;
          if (this.resourceType === "serviceProvider") {
            list = res.data.filter((item) => item.serviceProviderType === this.serviceProviderType);
          } else {
            list = res.data;
          }
          return list
            .map((item) => ({
              label: item.name || item.bvName || `${item.firstName} ${item.lastName}`,
              value: item.id || item._id,
              active: item.active,
            }))
            .filter((item) => item.value !== this.currentResourceId);
        });
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    closeDialog() {
      this.isVisible = false;
      this.$refs.form.resetFields();
    },
    submitData() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          const formData = {
            documentIds: this.selectedDocuments.map((item) => item._id),
            resourceType: this.resourceType,
            resourceIds: this.formData.selectedResources,
          };
          await this.axios.post("/api/documents/copy-selected", formData);
          Message.success("Kopiert!");
          this.$emit("discard");
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    selectAll() {
      this.formData.selectedResources = this.resourceOptions.map((item) => item.value);
    },
  },
  computed: {
    shownOptions() {
      if (this.showActive) {
        return this.resourceOptions.filter((i) => i.active);
      } else {
        return this.resourceOptions;
      }
    },
  },
};
</script>

<style></style>
