import { lookup } from "mime-types";

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const bstr = atob(arr[arr.length - 1]);
  const mime = lookup(arr[0].match(/:(.*?);/)[1]);
  let n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
