<template>
  <div>
    <form>
      <span class="n-profile-title">{{ tabName }}</span>

      <div class="card-body">
        <el-row :gutter="20">
          <template v-if="computedEditMode">
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-edit-avatar"
                :isEditMode="computedEditMode"
                :actionURI="actionURI"
                :avatar="supply.picture"
                @avatar-changed="setPicture"
                :name="'supply_' + supply.id + '_picture_0'"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <el-row>
                <el-col>
                  <!-- NAME -->
                  <profile-input
                    required
                    v-model="supply.name"
                    rules="required"
                    :name="$t('src.components.supply.profile.name')"
                    :label="$t('src.components.supply.profile.name')"
                    :editMode="computedEditMode"
                  />
                </el-col>
                <!-- <el-col style="width: 140px">
                  <profile-switch
                    :title="$t('src.components.supply.profile.aktiv')"
                    :disabled="!computedEditMode"
                    v-model="supply.active"
                    :active-text="$t('src.components.supply.profile.ja')"
                    :inactive-text="$t('src.components.supply.profile.nein')"
                    colorizeActive
                  />
                </el-col> -->
                <el-col style="width: 50px">
                  <!-- COLOR PICKER -->
                  <span class="n-profile-label-title">{{ $t("src.components.supply.profile.farbe") }}</span
                  ><br />
                  <el-color-picker
                    size="medium"
                    :disabled="!computedEditMode"
                    v-model="supply.color"
                    :predefine="predefinedColors"
                  ></el-color-picker>
                </el-col>
              </el-row>
            </el-col>
          </template>
          <template v-else>
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-view-avatar n-grid-container-view"
                :isEditMode="computedEditMode"
                :actionURI="actionURI"
                :avatar="supply.picture"
                @avatar-changed="setPicture"
                :name="'supply_' + supply.id + '_picture_0'"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <div class="n-profile-name">
                <span class="n-profile-label-title">{{ $t("src.components.supply.profile.name") }}</span
                ><br />
                {{ supply.name }}
              </div>
              <div style="display: flex">
                <!-- AKTIV/INAKTIV -->
                <div style="display: flex; align-items: center; width: 80px" class="n-profile-name-light">
                  <el-badge class="profile" :type="supply.active ? 'success' : 'danger'" is-dot />
                  {{ supply.active ? "Aktiv" : "Inaktiv" }}
                </div>
                <!-- COLOR PICKER -->
                <div style="width: 60px; display: inline-block">
                  <span class="n-profile-label-title">{{ $t("src.components.supply.profile.farbe") }}</span
                  ><br />
                  <div
                    v-bind:style="{
                      border: '1px solid black',
                      width: '30px',
                      height: '30px',
                      backgroundColor: supply.color,
                    }"
                  />
                </div>
              </div>
            </el-col>
          </template>

          <el-col :lg="5" :md="8">
            <!-- Warengruppe 1 DROPDOWN -->
            <product-group-select
              :editMode="computedEditMode"
              productGroupType="pg1"
              v-model="supply.selectedProductGroup1"
            />
          </el-col>

          <el-col :lg="5" :md="8">
            <!--  Warengruppe 2 DROPDOWN -->
            <product-group-select
              :editMode="computedEditMode"
              productGroupType="pg2"
              v-model="supply.selectedProductGroup2"
            />
          </el-col>
          <el-col :lg="5" :md="8">
            <!-- TEAM -->
            <profile-input v-model="supply.team" :label="$t('team')" :editMode="computedEditMode" />
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="computedEditMode">
          <el-col style="width: 140px">
            <!-- AKTIV/INAKTIV -->
            <profile-switch
              :title="$t('src.components.supply.profile.aktiv')"
              v-model="supply.active"
              :active-text="$t('src.components.supply.profile.ja')"
              :inactive-text="$t('src.components.supply.profile.nein')"
              colorizeActive
            />
          </el-col>
        </el-row>
        <hr />
        <!-- ALLGEMEIN -->
        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.supply.profile.allgemein") }}</div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col style="width: 140px">
            <profile-switch
              :title="$t('src.components.supply.profile.dispoRelevant')"
              :disabled="!computedEditMode"
              v-model="supply.projectRelevant"
              :active-text="$t('src.components.supply.profile.ja')"
              :inactive-text="$t('src.components.supply.profile.nein')"
            />
          </el-col>
          <el-col :span="6">
            <profile-input
              v-model="supply.description"
              :label="$t('src.components.supply.profile.beschreibung')"
              :editMode="computedEditMode"
              type="textarea"
              :autosize="{ minRows: 5 }"
            />
          </el-col>
        </el-row>
        <hr />
        <!-- Best suppliers -->
        <best-suppliers :units="units" />
        <hr />
        <date-history
          :isEditMode="computedEditMode"
          :dateHistory="supply.dateHistory"
          :dateOfEntering="supply.dateOfEntering"
          :dateOfLeaving="supply.dateOfLeaving"
          resourceType="supply"
          :resourceId="supply.id"
          @setProfileData="setProfileData"
          @saveDateHistory="saveDateHistory"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { Message, MessageBox, RadioGroup, RadioButton, Tabs, TabPane, Switch, ColorPicker } from "element-ui";
import { mapActions } from "vuex";

import { Avatar, Collapse, CollapseItem, Keyfigures } from "src/components/UIComponents";
import BestSuppliers from "./BestSuppliers.vue";
import DateHistory from "../Generic/DateHistory.vue";

export default {
  name: "supply-profile",
  props: {
    supply: Object,
    tabName: String,
    accessRights: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    units: { type: Array, required: true },
  },
  components: {
    Message,
    MessageBox,
    Collapse,
    CollapseItem,
    BestSuppliers,
    DateHistory,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Switch.name]: Switch,
    [Keyfigures.name]: Keyfigures,
    [ColorPicker.name]: ColorPicker,
  },
  data() {
    return {
      predefinedColors: [
        "#581713",
        "#a94b43",
        "#fbd283",
        "#62733f",
        "#034f73",
        "#96e3ff",
        "#255385",
        "#09111e",
        "#181238",
        "#d9d6cd",
        "#3d3936",
        "#837e7a",
        "#8d8d95",
        "#b5c4d7",
        "#dcba9f",
        "#d2cdca",
        "#fee0a0",
        "#84a0b5",
        "#bddc79",
        "#0ed145",
      ],

      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
    };
  },
  watch: {
    "$props.supply": {
      handler: function (newVal, oldVal) {
        this.checkDirty(newVal);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("dirtyFlag", { checkDirty: "checkDirty" }),
    setProfileData(data) {
      this.$emit("setProfileData", data);
    },
    saveDateHistory(data) {
      this.$emit("savePartialProfileData", data);
    },
    setPicture(pictureSettings) {
      this.supply.picture = pictureSettings;
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-with-select .el-input-group__append {
  width: 100px;

  background-color: #fff;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #8b8181;
}

.profile .el-badge__content.is-dot {
  padding: 0px 0px 0px 0px; /* padding: top right bottom left */
}

.n-grid-container-view {
  display: grid;
  grid-template-columns: 0px 100px 200px 100px;
  grid-template-areas:
    "avatar avatar content content"
    "avatar avatar content content";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
  text-align: left;
}

.n-grid-container-view-avatar {
  grid-area: avatar;
}
.n-grid-container-view-content {
  grid-area: content;
}

.n-grid-container-edit {
  display: grid;
  grid-template-columns: 0px 100px 100px 100px;
  grid-template-areas:
    "avatar avatar firstname firstname"
    "avatar avatar state empty";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
}

.n-grid-container-edit > div {
  text-align: left;
}

.n-grid-container-edit-avatar {
  grid-area: avatar;
}
.n-grid-container-edit-firstname {
  grid-area: firstname;
}

.n-grid-container-edit-state {
  margin-top: 20px;
  grid-area: state;
}
</style>
