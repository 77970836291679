<template>
  <!-- <el-button v-if="!editMode" class="n-top-right-green-button" :disabled="editMode" @click="(evt) => $emit('click', evt)"> -->
  <el-button type="primary" @click="evt => $emit('click', evt)">
    <pencil-icon class="mr-2" />
    <slot>{{ $t('src.components.uicomponents.buttons.editprofilebutton.profilBearbeiten') }}</slot>
  </el-button>
</template>

<script>
export default {
  name: "edit-profile-button",
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
