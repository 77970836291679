<template>
  <div>
    <span class="n-profile-title">{{ $t("src.components.employees.userprofile.benutzer") }}</span>
    <div class="card-body">
      <el-row :gutter="20">
        <el-col :span="4">
          <profile-input
            :editMode="false"
            :value="user.email"
            :label="$t('src.components.employees.userprofile.email')"
          />
        </el-col>
        <el-col :span="4">
          <profile-input
            :editMode="false"
            :value="user.username"
            :label="$t('src.components.employees.userprofile.username')"
          />
        </el-col>
        <el-col :span="4">
          <profile-input
            :editMode="false"
            :value="user.enabled ? 'Ja' : 'Nein'"
            :label="$t('src.components.employees.userprofile.aktiv')"
          />
        </el-col>
        <el-col :span="4">
          <profile-input
            :class="{ 'red-border': !userGroups.length }"
            :editMode="false"
            :value="userGroups"
            :label="$t('src.components.administration.user.profile.benutzergruppen')"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";

export default {
  name: "user-profile",
  props: {
    employeeId: { type: String },
  },
  components: {
    Message,
  },
  data() {
    return {
      user: {},
      userGroups: "",
    };
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    async fetchUser() {
      try {
        const userResponse = await this.axios.get(`/api/users/${this.employeeId}`);
        this.user = userResponse.data;
        const usergroupsResponse = await this.axios.get(`/api/users/${this.user.id}/groups`);
        if (usergroupsResponse.data) {
          this.userGroups = usergroupsResponse.data.name;
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
  },
};
</script>

<style>
.red-border {
  border: 1px solid #cb1010;
  padding: 0 4px;
}
</style>