/**
 * This module handles data change between received from back-end and edited by user, triggers "isDirty" flag
 * when data is changed. 
 * 1. Use "setPreservedData" when load data from back-end to set the starting point
 * 2. Use "checkDirty" with updated object at observer (watcher function) to trigger check
 * 3. Use "discard" in "beforeDestroy" to return to initial state.
 */
import hashSum from "hash-sum";

// calculate object hashes to check difference between objects
export function isDataChanged(newVal, oldVal) {
  const newHash = hashSum(JSON.stringify(newVal));
  const oldHash = hashSum(JSON.stringify(oldVal));
  return newHash !== oldHash;
}