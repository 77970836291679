<template>
  <div class="card">
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane :label="$t('src.components.management.companysettings.index.companyLogo')" name="companyLogo">
        <div class="p-2">
          <div class="form-group">
            <label style="display: block">{{
              $t("src.components.management.companysettings.index.companyLogo")
            }}</label>
            <avatar-uploader
              :isEditMode="isEditMode"
              :actionURI="actionURI"
              :avatar="companyLogo"
              v-on:avatar-changed="setCompanyLogo"
              :name="'company-settings_' + '0' + '_picture_0'"
            />
            <!-- NOTE: exact "name" prop value is also used in back-end to find customer logo -->
          </div>
          <div class="form-group" style="display: flex; align-items: flex-end">
            <div>
              <label style="display: block">{{
                $t("src.components.management.companysettings.index.startdatumFrAuswertungen")
              }}</label>
              <pr-date-picker :disabled="!isEditMode" v-model="startDate" style="width: 100%" />
            </div>
            <el-button
              :loading="loading"
              style="margin: 0 0 0 5px"
              @click.prevent="save"
              type="primary"
              v-if="isEditMode"
              >{{ $t("src.components.management.companysettings.index.speichern") }}</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('src.components.management.companysettings.colorSettings')" name="colorSettings">
        <div class="p-2">
          <color-settings />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Einheit" name="units">
        <div class="p-2">
          <div>
            <type-settings
              modelType="global"
              modelid="unit"
              type="Einheit"
              :isEditMode="isEditMode"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </div>
          <el-divider></el-divider>
          <div>
            <type-settings
              modelType="global"
              modelid="deliveryConditions"
              type="Lieferbedingungen"
              :isEditMode="isEditMode"
              :useSymbol="false"
              :useColors="false"
              :useAccessGroups="false"
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('src.components.management.companysettings.workshopprojects.werkstatt')" name="workshop">
        <workshop-projects :isEditMode="isEditMode" />
      </el-tab-pane>
      <el-tab-pane :label="$t('publicHolidays.publicHolidays')" name="holidays">
        <public-holidays :isEditMode="isEditMode" />
      </el-tab-pane>
      <el-tab-pane :label="$t('src.components.management.companysettings.settings')" name="settings">
        <settings-management :isEditMode="isEditMode" />
      </el-tab-pane>
    </el-tabs>
    <!-- <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%">
    </perfect-scrollbar> -->
  </div>
</template>

<script>
import { DatePicker, Button, Message, Divider, Tabs, TabPane } from "element-ui";
import { Avatar } from "src/components/UIComponents";
import WorkshopProjects from "./WorkshopProjects";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { TypeSettings } from "src/components/UIComponents";
import ColorSettings from "./ColorSettings.vue";
import { mapState } from "vuex";
import PublicHolidays from "./PublicHolidays.vue";
import SettingsManagement from "./SettingsManagement.vue";

export default {
  name: "main-tab",
  components: {
    TypeSettings,
    PerfectScrollbar,
    PublicHolidays,
    SettingsManagement,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [ColorSettings.name]: ColorSettings,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
    [WorkshopProjects.name]: WorkshopProjects,
    [Divider.name]: Divider,
    [Avatar.name]: Avatar,
  },
  data: function () {
    return {
      activeTab: "companyLogo",
      startDate: null,
      loading: false,
      actionURI: this.axios.defaults.baseURL + "/api/avatars",
      companyLogo: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.startDate = this.customerSettings.date;
      this.companyLogo = this.customerSettings.logo ? { ...this.customerSettings.logo } : null;
    },
    async save() {
      try {
        this.loading = true;
        await this.$store.dispatch("customerSettings/saveDate", this.startDate);
        Message.success("Datum geändert");
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async setCompanyLogo(logoData) {
      this.companyLogo = logoData;
      await this.$store.dispatch("customerSettings/saveLogo", logoData);
    },
  },
  computed: {
    ...mapState(["customerSettings"]),
    isEditMode() {
      return this.$can("update", "company_settings");
    },
  },
  watch: {
    "customerSettings.loaded": function (newVal, oldVal) {
      // if loaded changed from false to true
      if (!oldVal && newVal) {
        this.init();
      }
    },
  },
};
</script>

<style></style>
