const state = {
  tab: "",
};

const actions = {
  setTab({ commit }, tabName) {
    commit("setTab", tabName);
  },
  reset({ commit }) {
    commit("reset");
  },
};

const mutations = {
  setTab(state, tabName) {
    state.tab = tabName;
  },
  reset(state) {
    state.tab = "";
  },
};

export const currentTab = {
  namespaced: true,
  state,
  actions,
  mutations,
};
