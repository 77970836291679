<template>
  <div class="note-child-notes">
    <div class="child-note" v-for="(note, idx) in data" :key="idx">
      <div class="note-editor-header">
        <div class="note-avatar">
          <lazy-image :src="`/api/employees/${note.authorEmployeeId}/picture`" />
        </div>
        <div>
          <div class="note-author">{{ getAuthorName(note) }}</div>
          <div class="note-date">{{ note.updatedAt | formatDate }}</div>
        </div>
        <div class="note-header-actions">
          <span @click="handleEditNote(note)" v-if="note.isOwner"><pencil-icon /></span>
          <span v-if="isPrivilegedUser || note.isOwner" @click="handleDeleteNote(note)"
            ><trash-can-outline-icon
          /></span>
        </div>
      </div>
      <div v-html="note.body" class="mb-2" />
      <drawer-upload
        :file-list="note.pictures"
        :data="{ metadata: getMetadata() }"
        :pictureList="true"
        :on-remove="removeFile"
        :on-success="fileUploaded"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import DrawerUpload from "../Invoices/DrawerUpload.vue";
import { Message } from "element-ui";
import { moment } from "src/config/moment";
import LazyImage from "../../UIComponents/Image/LazyImage.vue";

export default {
  name: "note-child-notes",
  components: {
    LazyImage,
    DrawerUpload: DrawerUpload,
    [Message.name]: Message,
  },
  props: {
    isPrivilegedUser: { type: Boolean },
    data: { type: Array, default: () => [] },
    projectId: { type: String, required: true },
  },
  methods: {
    getMetadata() {
      return `project_${this.data.projectId}_notes_0`;
    },
    handleEditNote(note) {
      this.$emit("editNote", note);
    },
    handleDeleteNote(note) {
      this.$emit("deleteNote", note._id);
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.data.pictures = this.data.pictures.filter((item) => item._id !== file._id);
        // this.updateFiles();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    fileUploaded(response, file, fileList) {
      this.data.pictures = this.data.pictures.concat(response);
      // this.updateFiles();
    },
    getAuthorName(note) {
      let authorName = note.authorName;
      if (note.isOwner) {
        authorName += ` (Sie)`;
      }
      return authorName;
    },
  },
  filters: {
    formatDate(dateStr) {
      return moment(dateStr).format("lll");
    },
  },
};
</script>

<style>
</style>