<template>
  <el-radio-group v-model="localValue" :class="['n-radio-group', { light: light }]">
    <!-- <el-radio-button :label="$t('src.components.uicomponents.inputs.profileradiogroup.newYork')"></el-radio-button>
    <el-radio-button :label="$t('src.components.uicomponents.inputs.profileradiogroup.washington')"></el-radio-button> -->
    <el-radio-button v-for="item in items" :label="item.value" :key="item.value" :disabled="item.disabled">{{
      item.label
    }}</el-radio-button>
  </el-radio-group>
</template>

<script>
import { isUndefined } from "lodash";
import { RadioGroup, RadioButton } from "element-ui";

export default {
  name: "profile-radio-group",
  components: {
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
  },
  props: {
    light: Boolean,
    value: [String, Number, Boolean],
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      localValue: null,
    };
  },
  mounted() {
    this.localValue = isUndefined(this.value) ? null : this.value;
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== this.localValue) {
        this.localValue = newVal;
      }
    },
    localValue(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style lang="scss">
.n-radio-group {
  border: 1px solid #ebebeb;
  padding: 4px 5px;
  background: #f8f8f8;
  label {
    margin: 0;
  }
  .el-radio-button .el-radio-button__inner {
    border-radius: 0;
    color: #727272;
    font-weight: normal;
    border: none !important;
    background: transparent;
    padding: 7px 12px;
    line-height: 16px;
    font-size: 12px;
  }
  .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
    background-color: #ced9ea;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #455070;
    color: #fff;
    font-weight: normal;
    box-shadow: none;
  }
  &.light .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #fff;
    color: #000;
    font-weight: bold;
  }
}
</style>
